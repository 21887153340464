import {
  FETCH_GAZETTEER_RESULTS,
  FETCH_GAZETTEER_RESULTS_FAILURE,
  FETCH_GAZETTEER_RESULTS_SUCCESS,
} from '@/actions';

const INITIAL_STATE = {
  isLoading: false,
  error: null,
  results: [],
};

export default function gazetteerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_GAZETTEER_RESULTS:
      return { ...state, results: [], isLoading: true };
    case FETCH_GAZETTEER_RESULTS_SUCCESS:
      return {
        ...state,
        results: [...state.results, ...action.payload],
        isLoading: false,
      };
    case FETCH_GAZETTEER_RESULTS_FAILURE:
      return { ...state, results: [], isLoading: false, error: action.payload };

    default:
      return state;
  }
}

import { api } from '@/apis';
import { encodeParams } from '@/utils';
import { options, useDallasKeys } from '@/utils/config';
import { useQuery } from '@tanstack/react-query';

export function getValues(object, path) {
  const segments = path.split('.');
  if (segments[0] in object) {
    const child = object[segments[0]];
    if (segments.length === 1) {
      if (child instanceof Array) {
        return child;
      } else {
        return [child];
      }
    } else if (child instanceof Array) {
      return [].concat(
        ...child.map((entry) => getValues(entry, segments.slice(1).join('.'))),
      );
    } else {
      return getValues(child, segments.slice(1).join('.'));
    }
  } else {
    return [];
  }
}

export function getAllValues(array, path) {
  const rawValues = Array.from(
    new Set([].concat(...array.map((object) => getValues(object, path)))),
  ).sort();

  return rawValues;
}

export function useResourceOptions() {
  return useQuery({
    queryKey: ['resourceOptions'],
    queryFn: async () => {
      const vehicleArray = await api
        .get('vehicles', {
          searchParams: encodeParams({
            projection: options.vehicle.reduce(function (acc, field) {
              acc[field.name] = true;
              return acc;
            }, {}),
          }),
        })
        .json();

      const personArray = await api
        .get('people', {
          searchParams: encodeParams({
            projection: options.person.reduce(function (acc, field) {
              acc[field.name] = true;
              return acc;
            }, {}),
          }),
        })
        .json();

      const locationArray = await api
        .get('locations', {
          searchParams: encodeParams({
            projection: options.location.reduce(function (acc, field) {
              acc[field.name] = true;
              return acc;
            }, {}),
          }),
        })
        .json();

      const objectiveArray = await api
        .get('objectives', {
          searchParams: encodeParams({
            projection: options.objective.reduce(function (acc, field) {
              acc[field.name] = true;
              return acc;
            }, {}),
          }),
        })
        .json();

      const rfidCardArray = await api
        .get('rfidCards', {
          searchParams: encodeParams({
            projection: options.rfidCard.reduce(function (acc, field) {
              acc[field.name] = true;
              return acc;
            }, {}),
          }),
        })
        .json();

      const personFields = options.person.reduce(
        (accumulator, { name, label }) => ({
          ...accumulator,
          [name]: {
            label,
            values: getAllValues(personArray, name).map((value) => ({
              label: value,
              value,
            })),
          },
        }),
        {},
      );

      const value = {
        vehicle: {
          label: 'Vehicle',
          fields: options.vehicle.reduce(
            (accumulator, { name, label }) => ({
              ...accumulator,
              [name]: {
                label,
                values: getAllValues(vehicleArray, name).map((value) => ({
                  label: value,
                  value,
                })),
              },
            }),
            {},
          ),
        },
        person: {
          label: 'Person',
          fields: personFields,
        },
        driver: {
          label: 'Driver',
          fields: personFields,
        },
        people: {
          label: 'People',
          fields: personFields,
        },
        lastDriver: {
          label: 'Driver',
          fields: personFields,
        },
        location: {
          label: 'Location',
          fields: options.location.reduce(
            (accumulator, { name, label }) => ({
              ...accumulator,
              [name]: {
                label,
                values: getAllValues(locationArray, name).map((value) => ({
                  label: value,
                  value,
                })),
              },
            }),
            {},
          ),
        },
        objective: {
          label: 'Objective',
          fields: options.objective.reduce(
            (accumulator, { name, label }) => ({
              ...accumulator,
              [name]: {
                label,
                values: getAllValues(objectiveArray, name).map((value) => ({
                  label: value,
                  value,
                })),
              },
            }),
            {},
          ),
        },
        rfidCard: {
          label: useDallasKeys ? 'Dallas Key' : 'RFID Card',
          fields: options.rfidCard.reduce(
            (accumulator, { name, label }) => ({
              ...accumulator,
              [name]: {
                label,
                values: getAllValues(rfidCardArray, name).map((value) => ({
                  label: value,
                  value,
                })),
              },
            }),
            {},
          ),
        },
        incident: {
          label: 'Incident',
          fields: {
            reference: {
              label: 'Number',
              values: [...Array(1000).keys()].map((value) => ({
                label: (value + 1).toString(),
                value: value + 1,
              })),
            },
            grade: {
              label: 'Grade',
              values: [...Array(6).keys()].map((value) => ({
                label: (value + 1).toString(),
                value: value + 1,
              })),
            },
          },
        },
      };

      return value;
    },
    placeholderData: {},
    staleTime: 8 * 60 * 60 * 1000,
  });
}

import { briefingApi } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertBrief() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (json) => {
      if (json.code) {
        return briefingApi.patch(`briefs/${json.code}`, { json }).json();
      } else {
        return briefingApi.post('briefs', { json }).json();
      }
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['briefs'] }),
  });
}

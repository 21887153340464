import {
  EventPreviewMap,
  Parameters,
  Table,
  TablePagination,
} from '@/components/controls';
import { useAggregate, useDocumentTitle } from '@/hooks';
import { downloadCSV, getFilenameForDownload, isEmpty } from '@/utils';
import { events, rowsPerPageOptions } from '@/utils/config';
import { GetApp as GetAppIcon } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { format, getMilliseconds } from 'date-fns';
import { atom, useAtom } from 'jotai';
import { useMemo, useState } from 'react';
import { AccelerometerChart } from '../AccelerometerChart';
import { downloadPipelineFn, headers, pipelineFn } from './utils';

const {
  eventFilters: { accelerometerEvents: eventFilters },
} = events;

export function DownloadAccelerometerEventPollsLink({ entry }) {
  const handleDownloadDataClick = (sourceData) => () => {
    const filename = getFilenameForDownload('Accelerometer Event Data', 'csv');
    const data = (sourceData || []).map(
      ({ position: { coordinates }, headingDegrees, time, ...event }) => ({
        ...event,
        time: format(new Date(time), 'yyyy-MM-dd HH:mm:ss.S'),
        headingDegrees:
          getMilliseconds(new Date(time)) === 0 ? headingDegrees : '',
        longitude: getMilliseconds(new Date(time)) === 0 ? coordinates[0] : '',
        latitude: getMilliseconds(new Date(time)) === 0 ? coordinates[1] : '',
      }),
    );

    downloadCSV(data, filename, eventHeaders);
  };

  return (
    <Tooltip title="Download accelerometer data">
      <Box component="span">
        <IconButton
          disabled={entry.hasData === 'No'}
          onClick={handleDownloadDataClick(entry.data)}
          size="large"
        >
          <GetAppIcon />
        </IconButton>
      </Box>
    </Tooltip>
  );
}

const eventHeaders = [
  { label: 'Longitude', key: 'longitude' },
  { label: 'Latitude', key: 'latitude' },
  { label: 'Heading (degrees)', key: 'headingDegrees' },
  { label: 'Speed (mph)', key: 'speedMilesPerHour' },
  { label: 'Time', key: 'time' },
  { label: 'Horizontal Force (g)', key: 'horizontalForce' },
  { label: 'Vertical Force (g)', key: 'verticalForce' },
  { label: 'Lateral Force (g)', key: 'lateralForce' },
];

function Event({ entry }) {
  const [hoveredChartPoint, setHoveredChartPoint] = useState(null);

  return (
    <Box sx={{ display: 'flex', px: 2, pb: 2 }}>
      <Box sx={{ width: 320, height: 320 }}>
        <EventPreviewMap
          path={entry.path}
          point={
            entry.accelerometerData?.[entry.deviceProperties.triggerPoint * 10]
              ?.position
          }
          hoveredChartPoint={hoveredChartPoint}
          mapType="accelerometerEvents"
        />
      </Box>
      <Box sx={{ flex: 1, minWidth: 320, height: 320 }}>
        {entry.accelerometerData ? (
          <AccelerometerChart
            data={entry.accelerometerData}
            triggerPoint={entry.deviceProperties.triggerPoint}
            onChartHover={setHoveredChartPoint}
          />
        ) : (
          <Box
            sx={{
              height: 320,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box>
              <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                No data
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

const stateAtom = atom({
  orderBy: 'startTime',
  order: 'asc',
  page: 0,
  rowsPerPage: rowsPerPageOptions[0],
  query: {},
  parameters: {},
});

export function AccelerometerEvents() {
  useDocumentTitle('IR3 | Accelerometer Events');
  const [{ orderBy, order, page, rowsPerPage, query, parameters }, setState] =
    useAtom(stateAtom);
  const collection = 'accelerometerEvents';
  const pipeline = useMemo(
    () => pipelineFn(orderBy, order, page, rowsPerPage, query),
    [orderBy, order, page, rowsPerPage, query],
  );
  const totalsPipeline = [
    { $match: query },
    {
      $group: {
        _id: null,
        total: { $sum: 1 },
        horizontal: { $max: '$maximumForces.horizontal' },
        vertical: { $max: '$maximumForces.vertical' },
        lateral: { $max: '$maximumForces.lateral' },
      },
    },
    {
      $project: {
        _id: false,
        total: true,
        maximumForces: {
          horizontal: { $round: ['$horizontal', 2] },
          vertical: { $round: ['$vertical', 2] },
          lateral: { $round: ['$lateral', 2] },
        },
      },
    },
  ];
  const { data, isFetching, cancel } = useAggregate(
    collection,
    pipeline,
    !isEmpty(query),
  );
  const {
    data: aggregated,
    isFetching: totalsFetching,
    cancel: cancelTotals,
  } = useAggregate(collection, totalsPipeline, !isEmpty(query), true);
  const { total, ...totals } = aggregated[0] || {};
  const csvColumns = headers.map(({ label, key }) => ({
    header: label,
    key: key,
  }));

  function handleCancel() {
    cancel();
    cancelTotals();
  }

  const handleStateChange = (name) => (value) => {
    setState((state) => ({ ...state, [name]: value }));
  };

  const handleStateChangeWithEvent = (name) => (event, value) => {
    setState((state) => ({ ...state, [name]: value }));
  };

  function handleRowsPerPageChange(event) {
    setState((state) => ({
      ...state,
      rowsPerPage: event.target.value,
      page: 0,
    }));
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 48px)',
        overflow: 'hidden',
        backgroundColor: 'background.default',
      }}
    >
      <Parameters
        collection={collection}
        onFetch={handleStateChangeWithEvent('query')}
        onCancel={handleCancel}
        isFetching={isFetching || totalsFetching}
        value={parameters}
        onChange={handleStateChange('parameters')}
        sx={{ mt: 1, width: 264 }}
        vehicle
        driver
        eventFilters={eventFilters}
        pipelineFn={downloadPipelineFn}
        columns={csvColumns}
      />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            Accelerometer Events
          </Typography>
        </Toolbar>
        <Paper sx={{ m: [0, 1, 1], minWidth: 240 }}>
          <Table
            styles={{
              tableContainer: {
                height: 'calc(100vh - 172px)',
                overflowY: 'scroll',
              },
              table: {
                minWidth: 750,
              },
            }}
            data={data}
            headers={[
              {
                label: '',
                key: 'expand',
                type: 'expand',
                component: Event,
              },
              ...headers,
              {
                label: '',
                key: 'polls',
                type: 'component',
                component: DownloadAccelerometerEventPollsLink,
              },
            ]}
            rowsPerPage={rowsPerPage}
            page={0} //{filter.page} // server-side pagination will fake the page we're on
            keyName="identifier"
            order={order}
            orderBy={orderBy}
            onOrderChange={handleStateChange('order')}
            onOrderByChange={handleStateChange('orderBy')}
            totals={totals}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={total ?? 0} //{filteredStops.length}
            rowsPerPage={rowsPerPage}
            page={total ? page : 0}
            onPageChange={handleStateChangeWithEvent('page')}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </Box>
    </Box>
  );
}

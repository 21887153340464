import {
  FETCH_PERSON_DAILY_ACTIVITY,
  FETCH_PERSON_DAILY_ACTIVITY_CANCELLED,
  FILTER_PERSON_DAILY_ACTIVITY,
  UPDATE_PERSON_DAILY_ACTIVITY_PARAMETERS,
} from '@/actions';
import {
  CustomTooltip,
  Parameters,
  SelectMultiple,
  Table,
  TablePagination,
  ToggleList,
} from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import {
  downloadCSV,
  getFilenameForDownload,
  getKeyLabel,
  getTextWidth,
} from '@/utils';
import { baseType, personGroups, rowsPerPageOptions } from '@/utils/config';
import {
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
  BarChart as GroupByIcon,
  Sort as SortIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  CardContent,
  Collapse,
  FormControl,
  FormControlLabel,
  IconButton,
  LinearProgress,
  ListSubheader,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  brown,
  cyan,
  green,
  indigo,
  orange,
  purple,
  red,
  teal,
} from '@mui/material/colors';
import { format } from 'date-fns';
import { dequal } from 'dequal';
import { enqueueSnackbar } from 'notistack';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Bar,
  BarChart,
  Brush,
  Tooltip as ChartTooltip,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

const hours = [...Array(24).keys()];
const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

const bars = [
  { name: 'onRadio', colour: green[500] },
  { name: 'driving', colour: purple[500] },
  { name: 'inBase', colour: brown[500] },
  { name: 'inHomeWard', colour: orange[500] },
  { name: 'respondingToIncidents', colour: red[500] },
  { name: 'attendingObjectives', colour: teal[500] },
  { name: 'doubleCrewing', colour: cyan[500] },
];

const measureTypes = ['total', 'average', 'daily'];
const chartTypes = ['hours', 'percentage'];

const labels = new Map([
  ['onRadio', 'On Radio'],
  ['driving', 'Driving'],
  ['inBase', `In ${baseType.label}`],
  ['inHomeWard', 'In Home Ward'],
  ['respondingToIncidents', 'Responding to Incidents'],
  ['attendingObjectives', 'Attending Objectives'],
  ['doubleCrewing', 'Double Crewing'],
  ['totalDrivingMileage', 'Total Driving Mileage'],
  ['averageDrivingMileage', 'Average Driving Mileage'],
  ['dailyDrivingMileage', 'Daily Driving '],
  ['totalBaseVisits', `Total ${baseType.label} Visits`],
  ['averageBaseVisits', `Average ${baseType.label} Visits`],
  ['dailyBaseVisits', `Daily ${baseType.label} Visits`],
  ['hours', 'Hours'],
  ['percentage', 'Percentage'],
]);

export function DailyActivity() {
  useDocumentTitle('IR3 | Daily Activity');
  const dispatch = useDispatch();
  const data = useSelector((state) => state.activity.daily.data, dequal);
  const { groupBy, orderBy, order, filter, chartType, query, parameters } =
    useSelector((state) => state.activity.daily);
  const isLoading = useSelector((state) => state.activity.daily.isLoading);
  const isFiltering = useSelector((state) => state.activity.daily.isFiltering);
  const error = useSelector((state) => state.activity.daily.error);
  const groupByValues = useSelector(
    (state) => state.activity.daily.groupByValues,
    dequal,
  );
  const filterValues = useSelector(
    (state) => state.activity.daily.filterValues,
    dequal,
  );
  const orderByValues = useSelector(
    (state) => state.activity.daily.orderByValues,
    dequal,
  );
  const [showFilter, setShowFilter] = useState(false);
  const [hiddenBars, setHiddenBars] = useState([]);
  const [sortMenuAnchor, setSortMenuAnchor] = useState(null);
  const [viewMenuAnchor, setViewMenuAnchor] = useState(null);
  const [mileageType, setMileageType] = useState('totalDrivingMileage');
  const [baseVisitCountType, setBaseVisitCountType] =
    useState('totalBaseVisits');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const unit = chartType === 'percentage' ? '%' : 'HPD';

  function formatGroup(value) {
    switch (groupBy) {
      case 'date':
        return format(new Date(value), 'dd/MM/yyyy');
      case 'month':
        return format(new Date(value), 'MMM yyyy');
      default:
        return value;
    }
  }

  const headers = [
    {
      label: `${getKeyLabel(groupBy)} (Count)`,
      key: 'group',
      type: 'text',
    },
    {
      label: `On Radio (${unit})`,
      key: 'onRadio',
      type: 'number',
    },
    {
      label: `In ${baseType.label} (${unit})`,
      key: 'inBase',
      type: 'number',
    },
    {
      label: `Responding to Incidents (${unit})`,
      key: 'respondingToIncidents',
      type: 'number',
    },
    {
      label: `Attending Objectives (${unit})`,
      key: 'attendingObjectives',
      type: 'number',
    },
    {
      label: `Driving (${unit})`,
      key: 'driving',
      type: 'number',
    },
    {
      label: `In Home Ward (${unit})`,
      key: 'inHomeWard',
      type: 'number',
    },
    {
      label: `Double Crewing (${unit})`,
      key: 'doubleCrewing',
      type: 'number',
    },
    {
      label: 'Total Driving Mileage',
      key: 'totalDrivingMileage',
      type: 'number',
    },
    {
      label: 'Average Driving Mileage',
      key: 'averageDrivingMileage',
      type: 'number',
    },
    {
      label: 'Daily Driving Mileage',
      key: 'dailyDrivingMileage',
      type: 'number',
    },
    {
      label: `Total ${baseType.label} Visits`,
      key: 'totalBaseVisits',
      type: 'number',
    },
    {
      label: `Average ${baseType.label} Visits`,
      key: 'averageBaseVisits',
      type: 'number',
    },
    {
      label: `Daily ${baseType.label} Visits`,
      key: 'dailyBaseVisits',
      type: 'number',
    },
  ];

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error]);

  function handleFilterToggle() {
    setShowFilter(!showFilter);
  }

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_PERSON_DAILY_ACTIVITY,
      payload: {
        query,
        filter,
        groupBy,
        orderBy,
        order,
        chartType,
      },
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_PERSON_DAILY_ACTIVITY_CANCELLED,
    });
  }

  const handleGroupByFieldChanged = (groupBy) => () => {
    updateTransformOptions({ groupBy });
    setViewMenuAnchor(null);
  };

  const handlePercentOrHoursClick = (value) => () => {
    updateTransformOptions({ chartType: value });
    setViewMenuAnchor(null);
  };

  const handleFilterFieldChanged = (name) => (values) => {
    const newFilter =
      name in filter
        ? {
            ...filter,
            [name]: values || [],
          }
        : {
            ...filter,
            groups: {
              ...filter.groups,
              [name]: values || [],
            },
          };

    updateTransformOptions({ filter: newFilter });
  };

  function handleMileageTypeFieldChanged(event) {
    setMileageType(event.target.value);
  }

  function handleBaseVisitCountTypeFieldChanged(event) {
    setBaseVisitCountType(event.target.value);
  }

  const handleLegendClick = (selectedBar) => () => {
    const index = hiddenBars.indexOf(selectedBar);

    if (index === -1) {
      setHiddenBars(hiddenBars.concat(selectedBar));
    } else {
      setHiddenBars(
        hiddenBars.slice(0, index).concat(hiddenBars.slice(index + 1)),
      );
    }
  };

  function handleGroupMenuOpen(event) {
    setViewMenuAnchor(event.target);
  }

  function handleGroupMenuClose() {
    setViewMenuAnchor(null);
  }

  function handleSortMenuOpen(event) {
    setSortMenuAnchor(event.target);
  }

  function handleSortMenuClose() {
    setSortMenuAnchor(null);
  }

  function updateTransformOptions(optionChanges) {
    dispatch({
      type: FILTER_PERSON_DAILY_ACTIVITY,
      payload: {
        query,
        filter,
        orderBy,
        order,
        groupBy,
        chartType,
        ...optionChanges,
      },
    });
  }

  const handleSortItemClick = (newOrderBy) => () => {
    // tell the epic how does it feel about changing the sort order
    let newOrder = order;
    if (newOrderBy === orderBy) {
      newOrder = newOrder === 'asc' ? 'desc' : 'asc';
    }

    updateTransformOptions({ orderBy: newOrderBy, order: newOrder });
    setSortMenuAnchor(null);
  };

  function handlePageChange(event, newPage) {
    setPage(newPage);
  }

  function handleRowsPerPageChange(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleOrderChange(order) {
    updateTransformOptions({ order });
  }

  function handleOrderByChange(orderBy) {
    updateTransformOptions({ orderBy, order: 'asc' });
  }

  function handleParametersChange(parameters) {
    dispatch({
      type: UPDATE_PERSON_DAILY_ACTIVITY_PARAMETERS,
      payload: parameters,
    });
  }

  async function handleDownloadClick() {
    const filename = getFilenameForDownload(
      'Person Daily Activity',
      'csv',
      parameters?.startTime,
      parameters?.endTime,
    );

    downloadCSV(data, filename, headers);
  }

  const xAxisHeight =
    Math.max(
      ...data.map((item) =>
        getTextWidth(formatGroup(item.group), '12px Roboto'),
      ),
    ) + 16;

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 48px)',
        overflow: 'hidden',
        backgroundColor: 'background.default',
      }}
    >
      {isFiltering && (
        <LinearProgress
          sx={{ position: 'absolute', width: 1, height: 8 }}
          color="secondary"
        />
      )}
      <Parameters
        collection="personDailySummaries"
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading && !isFiltering}
        value={parameters}
        onChange={handleParametersChange}
        sx={{ mt: 1, width: 264 }}
        pointEvent
        dateOnly
        person
      />
      <Box
        sx={{
          width: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
          '& > :last-child': {
            mb: 1,
          },
        }}
      >
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography variant="subtitle1">Daily Activity</Typography>
          {/* <TextField
            size="small"
            select
            className={classes.groupByField}
            name="groupBy"
            value={groupBy}
            onChange={(event) => handleGroupByFieldChanged(event.target.value)}
          >
            <MenuItem value="all">All</MenuItem>
            {Object.values(groupByValues).map((value) => (
              <MenuItem key={value} value={value}>
                {getKeyLabel(value)}
              </MenuItem>
            ))}
          </TextField> */}
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title={showFilter ? 'Hide filter' : 'Show filter'}>
            <IconButton onClick={handleFilterToggle}>
              <FilterListIcon color={showFilter ? 'primary' : 'inherit'} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Change view">
            <IconButton
              aria-owns={viewMenuAnchor ? 'date-menu' : undefined}
              aria-haspopup="true"
              onClick={handleGroupMenuOpen}
            >
              <GroupByIcon />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={viewMenuAnchor}
            open={Boolean(viewMenuAnchor)}
            onClose={handleGroupMenuClose}
          >
            <ListSubheader disableSticky>Group by</ListSubheader>
            {Object.values(groupByValues).map((value) => (
              <MenuItem
                key={value}
                value={value}
                selected={value === groupBy}
                onClick={handleGroupByFieldChanged(value)}
              >
                {getKeyLabel(value)}
              </MenuItem>
            ))}
            <ListSubheader disableSticky>View as</ListSubheader>
            {chartTypes.map((value) => (
              <MenuItem
                key={value}
                value={value}
                selected={value === chartType}
                onClick={handlePercentOrHoursClick(value)}
              >
                {labels.get(value)}
              </MenuItem>
            ))}
          </Menu>
          <Tooltip title="Sort by">
            <IconButton
              aria-owns={sortMenuAnchor ? 'date-menu' : undefined}
              aria-haspopup="true"
              onClick={handleSortMenuOpen}
            >
              <SortIcon />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={sortMenuAnchor}
            open={Boolean(sortMenuAnchor)}
            onClose={handleSortMenuClose}
          >
            <ListSubheader disableSticky>Sort by</ListSubheader>

            {groupBy !== 'all' ? (
              <MenuItem
                size="small"
                key={orderBy}
                selected={orderBy === 'group'}
                onClick={handleSortItemClick('group')}
              >
                {getKeyLabel(groupBy)}
              </MenuItem>
            ) : (
              []
            )}
            {orderByValues
              .concat([mileageType, baseVisitCountType])
              .map((key) => (
                <MenuItem
                  key={key}
                  onClick={handleSortItemClick(key)}
                  selected={key === orderBy}
                >
                  {labels.get(key)}
                </MenuItem>
              ))}
          </Menu>
          <Tooltip title="Download data">
            <Box component="span">
              <IconButton
                disabled={data.length === 0}
                onClick={handleDownloadClick}
              >
                <GetAppIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </Toolbar>
        <Collapse in={showFilter} timeout="auto">
          <Stack spacing={1} sx={{ flex: 1, p: 1 }}>
            <SelectMultiple
              disabled={isFiltering}
              label="Name"
              placeholder="Select..."
              value={filter.name}
              labelValue
              onChange={handleFilterFieldChanged('name')}
              suggestions={filterValues.name.map((value) => ({
                label: value,
                value,
              }))}
            />
            <SelectMultiple
              disabled={isFiltering}
              label="Role"
              placeholder="Select..."
              value={filter.role}
              labelValue
              onChange={handleFilterFieldChanged('role')}
              suggestions={filterValues.role.map((value) => ({
                label: value,
                value,
              }))}
            />
            {Object.entries(personGroups).map(([key, { label, values }]) => {
              return (
                <SelectMultiple
                  key={key}
                  label={label}
                  placeholder="Select..."
                  value={filter.groups[key] || []}
                  labelValue
                  onChange={handleFilterFieldChanged(key)}
                  suggestions={values}
                />
              );
            })}
            <ToggleList
              value={filter.hour}
              onChange={handleFilterFieldChanged('hour')}
              disabled={isFiltering}
              values={hours}
              label={'Hours'}
            />
            <ToggleList
              value={filter.day}
              onChange={handleFilterFieldChanged('day')}
              disabled={isFiltering}
              values={days}
              label={'Days'}
            />
          </Stack>
        </Collapse>
        {data.length > 0 && (
          <Fragment>
            <Paper sx={{ m: [0, 1], minWidth: 240, fontSize: 12 }}>
              <CardContent sx={{ p: 0, pt: 4 }}>
                <Box
                  sx={{
                    pl: 8,
                    pr: 2,
                    pb: 1,
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                  }}
                >
                  {bars.map((bar) => (
                    <Box
                      key={bar.name}
                      sx={{
                        p: 0.5,
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={handleLegendClick(bar.name)}
                    >
                      <Avatar
                        sx={{
                          width: 12,
                          height: 12,
                          mr: 0.5,
                          bgcolor: !hiddenBars.includes(bar.name) && bar.colour,
                        }}
                      >
                        <Fragment />
                      </Avatar>
                      <Typography variant="caption">
                        {labels.get(bar.name)}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <ResponsiveContainer width="99%" height={520}>
                  <BarChart
                    data={data}
                    margin={{ top: 0, right: 16, left: 0, bottom: 32 }}
                    barGap={0}
                    barCategoryGap={16}
                  >
                    <XAxis
                      dataKey="group"
                      tickFormatter={formatGroup}
                      angle={-90}
                      textAnchor="end"
                      interval={0}
                      height={xAxisHeight}
                    >
                      <Label
                        value={getKeyLabel(groupBy)}
                        offset={36}
                        position="bottom"
                      />
                    </XAxis>
                    <YAxis>
                      <Label
                        value={
                          chartType === 'percentage' ? 'Percentage' : 'Hours'
                        }
                        angle={-90}
                        position="left"
                        offset={-24}
                      />
                    </YAxis>
                    <ChartTooltip
                      cursor={data.length > 0}
                      content={
                        <CustomTooltip
                          unit={chartType === 'percentage' ? '%' : 'hours'}
                          labelFormatter={formatGroup}
                        />
                      }
                    />
                    {data.length > 0 && <Brush dataKey="Date" height={24} />}
                    {bars.map((bar) => (
                      <Bar
                        key={bar.name}
                        dataKey={bar.name}
                        name={labels.get(bar.name)}
                        fill={bar.colour}
                        hide={hiddenBars.includes(bar.name)}
                      />
                    ))}
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Paper>
            <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="mileage chart type"
                  name="mileage chart type"
                  value={mileageType}
                  onChange={handleMileageTypeFieldChanged}
                >
                  {measureTypes.map((measureType) => (
                    <FormControlLabel
                      key={measureType}
                      value={`${measureType}DrivingMileage`}
                      control={<Radio />}
                      label={labels.get(`${measureType}DrivingMileage`)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Toolbar>
            <Paper sx={{ m: [0, 1], minWidth: 240, fontSize: 12 }}>
              <CardContent sx={{ p: 0, pt: 4 }}>
                <ResponsiveContainer width="99%" height={520}>
                  <BarChart
                    data={data}
                    margin={{ top: 0, right: 16, left: 8, bottom: 32 }}
                    barGap={0}
                    barCategoryGap={4}
                  >
                    <XAxis
                      dataKey="group"
                      tickFormatter={formatGroup}
                      angle={-90}
                      textAnchor="end"
                      interval={0}
                      height={xAxisHeight}
                    >
                      <Label
                        value={getKeyLabel(groupBy)}
                        offset={36}
                        position="bottom"
                      />
                    </XAxis>
                    <YAxis>
                      <Label
                        value="Miles"
                        angle={-90}
                        position="left"
                        offset={-16}
                      />
                    </YAxis>
                    <ChartTooltip
                      cursor={data.length > 0}
                      content={<CustomTooltip labelFormatter={formatGroup} />}
                    />
                    {data.length > 0 && <Brush dataKey="Name" height={24} />}
                    {measureTypes.map((measureType) => (
                      <Bar
                        key={measureType}
                        dataKey={`${measureType}DrivingMileage`}
                        name={labels.get(`${measureType}DrivingMileage`)}
                        fill={indigo[800]}
                        hide={mileageType !== `${measureType}DrivingMileage`}
                      />
                    ))}
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Paper>
            <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="trip chart type"
                  name="trip chart type"
                  value={baseVisitCountType}
                  onChange={handleBaseVisitCountTypeFieldChanged}
                >
                  {measureTypes.map((measureType) => (
                    <FormControlLabel
                      key={measureType}
                      value={`${measureType}BaseVisits`}
                      control={<Radio />}
                      label={labels.get(`${measureType}BaseVisits`)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Toolbar>
            <Paper sx={{ m: [0, 1], minWidth: 240, fontSize: 12 }}>
              {/* <CardHeader subheader="Total by Name" /> */}
              <CardContent sx={{ p: 0, pt: 4 }}>
                <ResponsiveContainer width="99%" height={520}>
                  <BarChart
                    data={data}
                    margin={{ top: 0, right: 16, left: 0, bottom: 32 }}
                    barCategoryGap={4}
                  >
                    <XAxis
                      dataKey="group"
                      angle={-90}
                      textAnchor="end"
                      interval={0}
                      height={xAxisHeight}
                    >
                      <Label
                        value={getKeyLabel(groupBy)}
                        offset={36}
                        position="bottom"
                      />
                    </XAxis>
                    <YAxis>
                      <Label
                        value="Visits"
                        angle={-90}
                        position="left"
                        offset={-24}
                      />
                    </YAxis>
                    <ChartTooltip
                      cursor={data.length > 0}
                      content={<CustomTooltip labelFormatter={formatGroup} />}
                    />
                    {data.length > 0 && <Brush dataKey="Name" height={24} />}
                    {measureTypes.map((measureType) => (
                      <Bar
                        key={measureType}
                        dataKey={`${measureType}BaseVisits`}
                        name={labels.get(`${measureType}BaseVisits`)}
                        fill={indigo[800]}
                        hide={baseVisitCountType !== `${measureType}BaseVisits`}
                      />
                    ))}
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Paper>
            <Toolbar
              variant="dense"
              disableGutters
              sx={{ justifyContent: 'space-between', p: 1, pb: 0 }}
            >
              <Typography variant="subtitle1">Daily Activity</Typography>
              <Typography variant="subtitle2">(HPD) = hours per day</Typography>
            </Toolbar>
            <Paper sx={{ m: [0, 1], minWidth: 240, fontSize: 12 }}>
              <Table
                styles={{
                  tableContainer: {
                    height: 'calc(100vh - 164px)',
                    overflowY: 'scroll',
                  },
                  table: {
                    minWidth: 750,
                  },
                }}
                data={data}
                headers={headers}
                rowsPerPage={rowsPerPage}
                page={page}
                order={order}
                orderBy={orderBy}
                onOrderChange={handleOrderChange}
                onOrderByChange={handleOrderByChange}
              />
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </Paper>
          </Fragment>
        )}
      </Box>
    </Box>
  );
}

import { TableRow, TableCell, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useAssociatedVehicle } from '@/hooks';

export function AssociatedVehicle({ occurrenceCode }) {
  const { data: vehicle } = useAssociatedVehicle(occurrenceCode);
  return (
    <Fragment>
      {vehicle && vehicle.associatedVehicle?.id && (
        <Fragment>
          <TableRow>
            <TableCell>
              <Typography variant="caption" color="textSecondary">
                Vehicle Id
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="caption">
                {vehicle.associatedVehicle?.id}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="caption" color="textSecondary">
                Vehicle Details
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="caption">
                {`Id: ${vehicle.associatedVehicle?.id}, Make: ${vehicle.associatedVehicle?.make}, Model: ${vehicle.associatedVehicle?.model}, VRM: ${vehicle.associatedVehicle?.registrationNumber}, Colour: ${vehicle.associatedVehicle?.color}`}
              </Typography>
            </TableCell>
          </TableRow>
        </Fragment>
      )}
    </Fragment>
  );
}

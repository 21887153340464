import { styles } from '@/utils/config';
import { getYear } from 'date-fns';
import { Fragment } from 'react';
import { AttributionControl } from 'react-map-gl/maplibre';

export function Attribution({ style }) {
  const selectedStyle = styles.find((s) => s.path === style);
  switch (selectedStyle.source) {
    case 'ORDNANCE_SURVEY':
      return (
        <Fragment>
          <AttributionControl
            key="os"
            customAttribution={`Contains OS data &copy; Crown copyright and database rights ${getYear(
              new Date(),
            )}`}
          />
          <a
            href="https://www.ordnancesurvey.co.uk"
            style={{
              position: 'absolute',
              left: 16,
              bottom: 40,
              zIndex: 999,
            }}
          >
            <img
              src="https://labs.os.uk/public/os-api-branding/v0.3.1/img/os-logo-maps.svg"
              alt="Ordnance Survey logo"
            />
          </a>
        </Fragment>
      );
    case 'MAPTILER':
      return (
        <a
          href="https://www.maptiler.com"
          style={{ position: 'absolute', left: 16, bottom: 40, zIndex: 999 }}
        >
          <img
            src="https://api.maptiler.com/resources/logo.svg"
            alt="MapTiler logo"
          />
        </a>
      );
    case 'OPENSTREETMAP':
      return (
        <AttributionControl
          key="osm"
          customAttribution="© OpenStreetMap contributors"
        />
      );
    default:
      return null;
  }
}

import { spin } from '@/utils';
import { Autorenew as AutorenewIcon } from '@mui/icons-material';
import { Button } from '@mui/material';

export function FetchButton({ isFetching, ...props }) {
  return (
    <Button
      variant="contained"
      color={isFetching ? 'error' : 'primary'}
      endIcon={
        <AutorenewIcon
          sx={
            isFetching ? { animation: `${spin} 2s linear infinite` } : undefined
          }
        />
      }
      {...props}
    >
      {isFetching ? 'Cancel' : 'Fetch'}
    </Button>
  );
}

import { UPDATE_LIVE_FILTER_OVERRIDE } from '@/actions';
import { useDocumentTitle, usePrevious } from '@/hooks';
import { liveFilters } from '@/utils/constants';
import {
  FilterCenterFocus as FocusIcon,
  GpsFixed as FollowAllIcon,
} from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip,
  // CardMedia,
  Typography,
} from '@mui/material';
import { dequal } from 'dequal';
import { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IncidentLiveListItem } from './IncidentLiveListItem';
import { PeopleLiveListItem } from './PeopleLiveListItem';
import { VehicleLiveListItem } from './VehicleLiveListItem';
import { ItemAvatar } from './ItemAvatar';
import { omit } from '@/utils';

// TODO refactor as it's shared with livelist index.js
const typeToListItemMap = {
  vehicles: VehicleLiveListItem,
  people: PeopleLiveListItem,
  incidents: IncidentLiveListItem,
};

export function CallSignDetail({
  item: callSign,
  onSubItemClick,
  onSubItemHover,
  onFollowToggle,
  onFollowBulk,
  followedIdsByType,
  hoveredId,
}) {
  useDocumentTitle(`IR3 | Live | Call Signs | ${callSign?.id}`);

  // need access to all the resources for any that have this tag
  const state = useSelector((state) => state.live);

  const filterOverride = useSelector(
    (state) => state.live.filteredInIdsByTypeOverride,
  );

  const dispatch = useDispatch();

  const idsByType = {};
  Object.keys(callSign.itemsByType).forEach((type) => {
    const idsOfType = Object.keys(callSign.itemsByType[type]);
    if (idsOfType.length > 0) {
      idsByType[type] = idsOfType;
    }
  });

  // if the tag overrides the filter it'll be the owner
  const callSignOverridesFilter = filterOverride?.owner === callSign.id;

  const override = useMemo(
    () => ({
      // make every type filtered out
      ...Object.fromEntries(Object.keys(liveFilters).map((k) => [k, {}])),
      // except for this call sign's items and this call sign
      ...callSign.itemsByType,
      callSigns: { [callSign.id]: true },
      // and set the owner of the override (when we nav away etc.)
      owner: callSign.id,
    }),
    [callSign],
  );

  const allFollowed = Object.keys(override)
    .filter((type) => !['owner', 'callSigns'].includes(type))
    .every((type) =>
      Object.keys(override[type]).every((id) => followedIdsByType[type]?.[id]),
    );

  function handleFilterToggle() {
    dispatch({
      type: UPDATE_LIVE_FILTER_OVERRIDE,
      payload: callSignOverridesFilter ? null : override,
    });
  }

  const handleFollowAllToggle = () => {
    const newFollow = !allFollowed;

    onFollowBulk(omit(override, ['owner', 'callSigns']), newFollow);
  };

  // if the tagged items change (e.g. they hadn't fully loaded yet)
  // re-dispatch the overrides if necessary
  const prevOverride = usePrevious(override);
  useEffect(() => {
    if (!dequal(override, prevOverride)) {
      if (callSignOverridesFilter) {
        dispatch({
          type: UPDATE_LIVE_FILTER_OVERRIDE,
          payload: override,
        });
      }
    }
  }, [dispatch, prevOverride, override, callSignOverridesFilter]);

  const orderedTypes = {
    incidents: 'Incident',
    people: 'People',
    vehicles: 'Vehicles',
  };

  return (
    <Card sx={{ m: 1 }}>
      <CardHeader
        avatar={<ItemAvatar item={callSign} type="callSigns" />}
        title={callSign.id}
        subheader={callSign.status}
        action={
          <Fragment>
            <Tooltip title="Focus on call sign">
              <IconButton onClick={handleFilterToggle} size="large">
                <FocusIcon
                  fontSize="small"
                  color={callSignOverridesFilter ? 'primary' : 'inherit'}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Toggle follow call sign">
              <IconButton
                aria-label="Toggle follow call sign"
                onClick={handleFollowAllToggle}
                size="large"
              >
                <FollowAllIcon
                  fontSize="small"
                  color={allFollowed ? 'primary' : 'inherit'}
                />
              </IconButton>
            </Tooltip>
          </Fragment>
        }
      />
      <CardContent>
        {Object.keys(orderedTypes)
          .filter(
            (type) => Object.keys(callSign.itemsByType[type] || {}).length > 0,
          )
          .map((type) => (
            <Fragment key={type}>
              <Typography variant="subtitle2" color="textSecondary">
                {orderedTypes[type]}
              </Typography>
              {Object.keys(callSign.itemsByType[type]).map((id) => {
                // try to find the item
                let item = state[type]?.[id];

                // if it's an incident it may be too old to have been retrieved
                // use a placeholder to lazy-load it
                if (!item && type === 'incidents') {
                  item = { id };
                }

                const ListComponent = typeToListItemMap[type];

                return (
                  <ListComponent
                    key={id}
                    onClick={onSubItemClick}
                    highlighted={hoveredId === id}
                    onHoverItem={onSubItemHover}
                    onFollowToggle={onFollowToggle}
                    followedIdsByType={followedIdsByType}
                    item={item}
                    // hideFollow={true}
                  />
                );
              })}
            </Fragment>
          ))}
      </CardContent>
    </Card>
  );
}

import { useBrief, useDocumentTitle, useSections } from '@/hooks';
import { Box, Drawer, Step, StepButton, Stepper, Toolbar } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Section } from './Section';

export function Brief() {
  const { code } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const page = searchParams.get('page');
  const { data: brief } = useBrief(code);
  useDocumentTitle(`IR3 | Briefs | ${brief?.title}`);
  const { data: sections } = useSections();
  const [completed, setCompleted] = useState([]);
  const entries = brief
    ? Object.entries(sections || {}).filter(
        ([key]) => !brief.excludedSections.includes(key),
      )
    : [];

  const handleStep = (page) => () => {
    setSearchParams({
      page,
    });
  };

  const handleComplete = (page) => () => {
    const incompletePages = [...Array(entries.length).keys()].filter(
      (p) => !completed.includes(p),
    );
    if (incompletePages.length === 1) {
      navigate('../');
    } else {
      setCompleted((existingValue) => existingValue.concat(+page));
      setSearchParams({
        page:
          incompletePages.filter((ip) => ip > +page && ip !== +page)[0] ??
          incompletePages[0] ??
          0,
      });
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        variant="permanent"
        sx={{
          width: 240,
          flexShrink: 0,
          ['& .MuiDrawer-paper']: {
            width: 240,
            boxSizing: 'border-box',
          },
        }}
      >
        <Toolbar variant="dense" />
        <Box sx={{ overflow: 'auto', p: 1 }}>
          <Stepper nonLinear activeStep={+page ?? 0} orientation="vertical">
            {entries.map(([key, label], index) => (
              <Step key={key} completed={completed.includes(index)}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          p: 1,
          height: 'calc(100vh - 48px)',
          width: 'calc(100vw - 240px)',
          backgroundColor: 'background.paper',
        }}
      >
        {entries.length > 0 && (
          <Section
            id={entries[page] ? entries[page][0] : ''}
            groupCodes={brief.groupCodes}
            onComplete={handleComplete(page)}
          />
        )}
      </Box>
    </Box>
  );
}

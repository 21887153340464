import { Stack } from '@mui/material';
import { UncheckedVehiclesCard } from '../UncheckedVehiclesCard';
import { AverageInStationTimeCard } from './AverageInStationTimeCard';
import { BoxAssignmentsCard } from './BoxAssignmentsCard';
import { CardAssignmentsCard } from './CardAssignmentsCard';
import { ExcessiveAccelerometerReadingsCard } from './ExcessiveAccelerometerReadingsCard';
import { GroupComparisonCard } from './GroupComparisonCard';
import { NonPollingVehiclesCard } from './NonPollingVehiclesCard';
import { useDocumentTitle } from '@/hooks';

export function Fleet() {
  useDocumentTitle('IR3 | Highlights | Fleet');

  return (
    <Stack spacing={1} sx={{ p: 1 }} direction="row" alignItems="baseline">
      <Stack spacing={1}>
        <Stack spacing={1} direction="row">
          <CardAssignmentsCard />
          <BoxAssignmentsCard />
        </Stack>
        <NonPollingVehiclesCard />
        <UncheckedVehiclesCard />
      </Stack>
      <Stack spacing={1} sx={{ flexGrow: 1 }}>
        <GroupComparisonCard />
        <AverageInStationTimeCard />
        <ExcessiveAccelerometerReadingsCard />
      </Stack>
    </Stack>
  );
}

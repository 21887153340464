import { WeeklySchedulePicker } from '@/components/controls';
import { FormControl, FormHelperText } from '@mui/material';
import { useController } from 'react-hook-form';

export function WeeklySchedulePickerController({
  name,
  control,
  disabled,
  rules,
  sx,
}) {
  const {
    field: { value, disabled: isDisabled, onChange },
    fieldState: { error, invalid },
  } = useController({
    name,
    control,
    rules,
    defaultValue: Array(7)
      .fill()
      .map(() => Array(24).fill(false)),
    disabled,
  });

  return (
    <FormControl
      error={invalid}
      variant="standard"
      disabled={isDisabled}
      sx={sx}
    >
      <WeeklySchedulePicker
        value={value}
        onChange={onChange}
        disabled={isDisabled}
      />
      <FormHelperText sx={{ pl: 1 }}>{error?.message}</FormHelperText>
    </FormControl>
  );
}

import { commonRegEx } from '@/utils/config';
import { Box, TextField as MuiTextField } from '@mui/material';
import { createRef } from 'react';

export function CounterTextField({
  input,
  meta,
  maxLength,
  upperCase,
  validatorRegEx,
  ...props
}) {
  const validCharsRegEx = validatorRegEx
    ? validatorRegEx instanceof RegExp
      ? validatorRegEx
      : commonRegEx[validatorRegEx]
    : null;

  function handleOnChange(event) {
    const isValid = validCharsRegEx
      ? validCharsRegEx.test(event.target.value)
      : true;
    if (upperCase) event.target.value = event.target.value.toUpperCase();
    isValid && input.onChange(event);
  }

  /*
  SDB work in progress, I want to be able to add to the parent objects validators if minLength is configured in defaultJS
  minLength validtor has been implemented in the VehicleKeyFields currently - to allow enforcement of VIN 17 characters

  function checkMinimumLength(value) {
    if (minLength && value.length < minLength)
      return `Minimum Length is ${minLength}`;
  }

  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      );
  */

  const ref = createRef();
  return (
    <MuiTextField
      ref={ref}
      size="small"
      error={meta.invalid}
      {...input}
      {...props}
      onChange={(event) => {
        handleOnChange(event);
      }}
      inputProps={{
        maxLength: maxLength,
        sx: upperCase ? { textTransform: 'uppercase' } : undefined,
      }}
      helperText={
        <Box
          component="span"
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <span>{meta.error ?? meta.submitError}</span>
          {meta.active && (
            <span>
              {input.value ? `${input.value.length} / ${maxLength}` : ''}
            </span>
          )}
        </Box>
      }
    />
  );
}

import {
  useEnRoutePrisoners,
  useCustodyRecords,
  useNichePersons,
  useScenes,
} from '@/hooks';
import { Add as AddIcon, Done as DoneIcon } from '@mui/icons-material';
import {
  Avatar,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { Fragment, useState } from 'react';
import { PrisonerEditor } from './PrisonerEditor';
import { SceneEditor } from './SceneEditor';

const enRouteColumns = [
  {
    header: 'Custody Unit',
    accessorKey: 'custodyUnit',
  },
  {
    header: 'Occurrence',
    accessorKey: 'occurrenceCode',
  },
  {
    header: 'Hospital',
    accessorKey: 'hospital',
  },
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: 'Offence',
    accessorKey: 'offence',
  },
  {
    header: 'Arresting Officer',
    accessorKey: 'arrestingOfficerCode',
  },
  {
    header: 'Investigating Officer',
    accessorKey: 'investigatingOfficerCode',
  },
  {
    header: 'OIC',
    accessorKey: 'officerInCaseCode',
  },
  {
    header: 'OIC Team',
    accessorKey: 'owningTeam',
  },
];

const sceneColumns = [
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: 'Incident',
    accessorKey: 'attributes.incidentCode',
  },
  {
    header: 'Occurrence',
    accessorKey: 'attributes.occurrenceCode',
  },
  {
    header: 'Start Time',
    key: 'startTime',
    accessorFn: (row) =>
      row.startTime?.toLocaleString('en-GB', {
        dateStyle: 'short',
        timeStyle: 'short',
      }),
  },
  {
    header: 'End Time',
    key: 'endTime',
    accessorFn: (row) =>
      row.endTime?.toLocaleString('en-GB', {
        dateStyle: 'short',
        timeStyle: 'short',
      }),
  },
  {
    header: 'Groups',
    accessorFn: (row) => (row.groups ?? []).sort().join(', '),
    key: 'groups',
  },
  {
    header: 'Required Attendance',
    accessorKey: 'attributes.requiredAttendance',
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
  },
  {
    header: 'Current Attendance',
    accessorKey: 'currentAttendanceCount',
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
  },
];

export function Watches({ groupCodes, onComplete }) {
  const { data: enRoutePrisoners } = useEnRoutePrisoners({
    codes: groupCodes,
  });

  const { data: custodyRecords } = useCustodyRecords();

  const enRoutePrisonerCodes = enRoutePrisoners.map(
    (prisoner) => prisoner.personCode,
  );

  // get all the custody records where observationLevelCode not LV3 or LV4:
  //observationLevelCode:LV1, LV2, LV3, LV4, etc
  const prisonersWithoutObservation = custodyRecords?.filter(
    (custody) =>
      custody.observationLevelCode !== 'LV3' &&
      custody.observationLevelCode !== 'LV4',
  );

  // get all the person records in niche for codes in ir3
  const {
    data: enRoutePrisonersInNiche,
    isLoading: prisonersLoading,
    isFetching: prisonersFetching,
  } = useNichePersons({
    codes: enRoutePrisonerCodes,
  });

  // store enroute prisoners name with person id as a key
  const niheEnroutePrisonersName = {};
  if (enRoutePrisonersInNiche && enRoutePrisonersInNiche.length > 0) {
    enRoutePrisonersInNiche.map((prisoner) => {
      const { nominalId, firstName, surname } = prisoner.person;
      niheEnroutePrisonersName[nominalId] = `${firstName} ${surname}`;
    });
  }

  // get the enroutes already in custody
  const enRoutesExistsInCustody = enRoutePrisonersInNiche?.filter((enroute) =>
    prisonersWithoutObservation?.find(
      (custody) => enroute.person.id === custody.personId,
    ),
  );

  // exclude enroutes already in custody without observationCode LV3 or LV4
  const filteredEnRoutes = enRoutePrisoners.filter((enRoute) => {
    return !enRoutesExistsInCustody?.find(
      (niche) => niche.person.nominalId === enRoute.personCode,
    );
  });

  // add/update names
  filteredEnRoutes.map((prisoner) => {
    if (prisoner.personCode) {
      prisoner.name = niheEnroutePrisonersName[prisoner.personCode];
    }
    return prisoner;
  });

  const {
    data: scenes,
    isLoading,
    isFetching,
  } = useScenes({ groups: groupCodes });

  const [editEnrouteCode, setEditEnrouteCode] = useState(null);
  const [editSceneCode, setEditSceneCode] = useState(null);

  function handleCompleteClick() {
    onComplete();
  }

  function handleEnRouteAddClick() {
    setEditEnrouteCode('new');
  }

  function onEditorClose() {
    setEditEnrouteCode(null);
  }

  function handleSceneAddClick() {
    setEditSceneCode('new');
  }

  function onSceneEditorClose() {
    setEditSceneCode(null);
  }

  const handleEnrouteRowClick = (code) => () => {
    setEditEnrouteCode(code);
  };

  const handleSceneRowClick = (code) => () => {
    setEditSceneCode(code);
  };

  return (
    <Fragment>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{
          position: 'sticky',
          top: 48,
          backgroundColor: 'background.default',
          zIndex: 700,
          pl: 1,
          py: 1,
        }}
      >
        <Typography variant="h6">Watches</Typography>
        <Box>
          <Tooltip title="Done" placement="right">
            <IconButton onClick={handleCompleteClick}>
              <DoneIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
      <Stack spacing={1} sx={{ pb: 1 }}>
        <MaterialReactTable
          muiTablePaperProps={{ variant: 'outlined', elevation: 0 }}
          defaultColumn={{ size: 0, enableEditing: false }}
          columns={enRouteColumns}
          data={filteredEnRoutes}
          enableGrouping
          enableBottomToolbar={false}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enablePagination={false}
          state={{ isLoading: prisonersLoading || prisonersFetching }}
          initialState={{
            density: 'compact',
            expanded: true,
          }}
          renderTopToolbarCustomActions={() => {
            return (
              <Stack
                width={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle1">
                  En Route and Hospital
                </Typography>
                <Box>
                  <Tooltip title="Add En Route Prisoner" placement="left">
                    <IconButton size="small" onClick={handleEnRouteAddClick}>
                      <Avatar
                        sx={{
                          color: 'secondary.contrastText',
                          bgcolor: 'secondary.main',
                          width: 24,
                          height: 24,
                          fontSize: 16,
                          m: 0.25,
                        }}
                      >
                        <AddIcon fontSize="inherit" />
                      </Avatar>
                    </IconButton>
                  </Tooltip>
                </Box>
              </Stack>
            );
          }}
          muiTableBodyRowProps={({ row }) => ({
            onClick: handleEnrouteRowClick(row.original?.code),
            sx: {
              cursor: 'pointer',
              '&:last-child td, &:last-child th': { border: 0 },
            },
          })}
          muiSearchTextFieldProps={{ variant: 'outlined', size: 'small' }}
        />
        <MaterialReactTable
          muiTablePaperProps={{ variant: 'outlined', elevation: 0 }}
          defaultColumn={{ size: 0, enableEditing: false }}
          columns={sceneColumns}
          data={scenes}
          enableBottomToolbar={false}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enablePagination={false}
          state={{ isLoading: isLoading || isFetching }}
          initialState={{
            density: 'compact',
            expanded: true,
            sorting: [{ id: 'name', desc: false }],
            columnVisibility: { remarks: false },
          }}
          renderTopToolbarCustomActions={() => {
            return (
              <Stack
                width={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle1">Scenes</Typography>
                <Box>
                  <Tooltip title="Add Scene" placement="left">
                    <IconButton size="small" onClick={handleSceneAddClick}>
                      <Avatar
                        sx={{
                          color: 'secondary.contrastText',
                          bgcolor: 'secondary.main',
                          width: 24,
                          height: 24,
                          fontSize: 16,
                          m: 0.25,
                        }}
                      >
                        <AddIcon fontSize="inherit" />
                      </Avatar>
                    </IconButton>
                  </Tooltip>
                </Box>
              </Stack>
            );
          }}
          muiTableBodyRowProps={({ row }) => ({
            onClick: handleSceneRowClick(row.original?.code),
            sx: {
              cursor: 'pointer',
              '&:last-child td, &:last-child th': { border: 0 },
            },
          })}
          muiSearchTextFieldProps={{ variant: 'outlined', size: 'small' }}
        />
      </Stack>
      <PrisonerEditor
        code={editEnrouteCode}
        isOpen={Boolean(editEnrouteCode)}
        onClose={onEditorClose}
        groupCodes={groupCodes}
      />
      <SceneEditor
        code={editSceneCode}
        isOpen={Boolean(editSceneCode)}
        onClose={onSceneEditorClose}
        groupCodes={groupCodes}
      />
    </Fragment>
  );
}

import { useEffect, useRef } from 'react';

// export function useEffectOnce(effect) {
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   useEffect(effect, []);
// }

export function useEffectOnce(effect) {
  const hasRun = useRef(false);
  useEffect(() => {
    if (hasRun.current) {
      return;
    }
    hasRun.current = true;
    return effect();
  });
}

import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';
import { startOfToday, subDays } from 'date-fns';

export function useAverageInStationTime(groupType, days) {
  return useQuery({
    queryKey: ['inStationTime', groupType, days],
    queryFn: () => {
      const json = [
        {
          $match: {
            time: {
              $gte: subDays(startOfToday(), days),
              $lt: startOfToday(),
            },
          },
        },
        { $unwind: `$vehicle.groups.${groupType}` },
        {
          $group: {
            _id: `$vehicle.groups.${groupType}`,
            stoppedBaseSeconds: {
              $avg: '$stoppedBaseSeconds',
            },
          },
        },
        {
          $project: {
            _id: false,
            group: '$_id',
            hours: { $divide: ['$stoppedBaseSeconds', 3600] },
          },
        },
        { $sort: { hours: -1 } },
      ];

      return api.post('pipeline/vehicleDailySummaries', { json }).json();
    },
    placeholderData: [],
  });
}

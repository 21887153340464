import { shortHumanizer } from '@/utils';

export const pipelineFn = (orderBy, order, page, rowsPerPage, query) => [
  { $match: query },
  {
    $project: {
      identifier: true,
      startTime: true,
      endTime: true,
      durationSeconds: true,
      startLocations: true,
      endLocations: true,
      person: true,
    },
  },
  { $sort: { [orderBy]: order === 'desc' ? -1 : 1 } },
  { $skip: page * rowsPerPage },
  { $limit: rowsPerPage },
];

export const downloadPipelineFn = (query) => [
  { $match: query },
  {
    $project: {
      identifier: true,
      startTime: true,
      endTime: true,
      durationSeconds: { $divide: ['$durationSeconds', 86400] },
      startLocations: true,
      endLocations: true,
      person: true,
    },
  },
];

export const headers = [
  {
    label: 'Staff ID',
    key: 'person.code',
    type: 'text',
  },
  {
    label: 'Forenames',
    key: 'person.forenames',
    type: 'text',
  },
  {
    label: 'Surname',
    key: 'person.surname',
    type: 'text',
  },
  { label: 'Role', key: 'person.role', type: 'text' },
  {
    label: 'Collar Number',
    key: 'person.collarNumber',
    type: 'text',
  },
  {
    label: 'Rank',
    key: 'person.rank.code',
    type: 'text',
  },
  {
    label: 'Start Location Type',
    key: 'startLocations.0.type',
    type: 'text',
  },
  {
    label: 'Start Location Name',
    key: 'startLocations.0.name',
    type: 'text',
  },
  {
    label: 'End Location Type',
    key: 'endLocations.0.type',
    type: 'text',
  },
  {
    label: 'End Location Name',
    key: 'endLocations.0.name',
    type: 'text',
  },
  { label: 'Start Time', key: 'startTime', type: 'date' },
  { label: 'End Time', key: 'endTime', type: 'date' },
  {
    label: 'Duration',
    key: 'durationSeconds',
    format: (value) => shortHumanizer(value * 1000),
    type: 'text',
    align: 'right',
  },
];

import { api } from '@/apis';
import { log } from '@/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpdateTrip() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, values }) => {
      log('Update', 'Trip', { id, values });

      return api.patch(`trips/${id}`, {
        headers: { 'content-type': 'application/merge-patch+json' },
        json: values,
      });
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['trips'] }),
  });
}

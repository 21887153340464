import {
  AddCircle as AddIcon,
  RemoveCircle as RemoveIcon,
} from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Field } from './Field';
import { SelectField } from './SelectField';

export function TypesField({ input, types, disabled, readOnly }) {
  function handleAddClick() {
    input.onChange([...(input.value || []), {}]);
  }

  const handleRemoveClick = (index) => () => {
    input.onChange(
      input.value.slice(0, index).concat(input.value.slice(index + 1)),
    );
  };

  return (
    <Box>
      {(input.value || []).map((entry, index) => {
        const values =
          input.value[index].type in types
            ? types[input.value[index].type].values
            : [];

        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }} key={index}>
            <Field
              name={`${input.name}[${index}].type`}
              component={SelectField}
              sx={{ mr: 1, pb: 1, width: 200 }}
              label="Type"
              values={
                Object.entries(types).map((type) => ({
                  label: type[1].label,
                  value: type[0],
                })) || []
              }
              disabled={disabled}
              InputProps={{
                readOnly,
              }}
            />
            <Field
              name={`${input.name}[${index}].name`}
              component={SelectField}
              sx={{ mr: 1, pb: 1, width: 200 }}
              label="Value"
              values={values}
              disabled={disabled}
              InputProps={{
                readOnly,
              }}
            />
            {!readOnly && !disabled && (
              <Tooltip title="Remove">
                <IconButton
                  size="small"
                  onClick={handleRemoveClick(index)}
                  sx={{ mb: 1 }}
                >
                  <RemoveIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      })}
      {!readOnly && !disabled && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <Box sx={{ width: 424 }} />
          <Tooltip title="Add">
            <IconButton size="small" onClick={handleAddClick}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
}

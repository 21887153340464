import { useUncheckedVehicles } from '.';

export function useGroupedUncheckedVehicles(groupType) {
  const { data, isSuccess } = useUncheckedVehicles();

  const counts = {};
  for (const vehicle of data) {
    const groups = vehicle?.groups?.[groupType] ?? ['None'];
    for (const group of groups) {
      counts[group] = [...(counts[group] ?? []), vehicle];
    }
  }
  for (const group in counts) {
    counts[group] = { group, count: counts[group].length };
  }

  return { data: counts, isSuccess };
}

import {
  Avatar,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';

export function TimelinePanel({ entries }) {
  return (
    <Stepper
      orientation="vertical"
      sx={{
        ['& .MuiStepLabel-root']: {
          py: 0,
        },
        pb: 1,
        overflow: 'auto',
      }}
    >
      {entries.map(
        ({ label, time, color, bgcolor, description, Icon }, index) => (
          <Step active key={index}>
            <StepLabel
              icon={
                <Avatar
                  sx={{
                    width: 24,
                    height: 24,
                    fontSize: 16,
                    color,
                    bgcolor,
                  }}
                >
                  <Icon fontSize="inherit" />
                </Avatar>
              }
              optional={
                <Typography variant="subtitle2" color="text.disabled">
                  {format(time, 'dd/MM/yyyy HH:mm:ss')}
                </Typography>
              }
            >
              {label}
            </StepLabel>
            <StepContent>
              <Typography variant="caption">{description}</Typography>
            </StepContent>
          </Step>
        ),
      )}
    </Stepper>
  );
}

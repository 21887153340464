import { highlightsStateAtom } from '@/atoms';
import { Stack } from '@mui/material';
import { useAtom } from 'jotai';
import { CurrentObjectivesCard } from './CurrentObjectivesCard';
import { DailyActivityCard } from './DailyActivityCard';
import { IncidentsByGradeCard } from './IncidentsByGradeCard';
import { TotalAttendedIncidentsCard } from './TotalAttendedIncidentsCard';
import { TotalIncidentsCard } from './TotalIncidentsCard';
import { useDocumentTitle } from '@/hooks';

export function Team() {
  useDocumentTitle('IR3 | Highlights | Team');
  const [{ sector, team }] = useAtom(highlightsStateAtom);
  return (
    <Stack spacing={1} sx={{ p: 1 }} direction="row" alignItems="baseline">
      <Stack spacing={1}>
        <CurrentObjectivesCard groupType="team" groupId={team} />
        <TotalIncidentsCard
          groupType="sector"
          groupId={sector}
          subgroupType="ward"
        />
      </Stack>
      <Stack spacing={1} sx={{ flexGrow: 1 }}>
        <DailyActivityCard
          groupType="team"
          groupId={team}
          subgroupType="collarNumber"
        />
        <IncidentsByGradeCard groupType="sector" groupId={sector} />
        <TotalAttendedIncidentsCard
          groupType="sector"
          groupId={sector}
          subgroupType="team"
        />
      </Stack>
    </Stack>
  );
}

import { Box, InputAdornment, Menu, MenuItem, Tooltip } from '@mui/material';
import { Fragment, useState } from 'react';

export function Adornment({ value, position, values, onChange, sx }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { label, title } =
    values.find((item) => item.value === value) || values[0];

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const handleItemClick = (value) => () => {
    onChange(value);
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Tooltip title={title} placement="right">
        <InputAdornment
          position={position}
          sx={{ cursor: 'pointer', ...sx }}
          onClick={handleClick}
        >
          {title ? <Box component="span">{label}</Box> : label}
        </InputAdornment>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {(values || []).map((item) => (
          <Tooltip
            key={item.value || item}
            title={item.title}
            placement="right"
          >
            <MenuItem
              value={item.value || item}
              onClick={handleItemClick(item.value || item)}
            >
              {item.title ? (
                <Box component="span">{item.label}</Box>
              ) : (
                item.label || item
              )}
            </MenuItem>
          </Tooltip>
        ))}
      </Menu>
    </Fragment>
  );
}

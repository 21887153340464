import { Stack, Typography } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useArrests, useCustodyUnits, useOfficers } from '@/hooks';
import { filterByPastDays, getOfficerTeamFromPeople } from '@/utils';
import { PastDaysFilter } from '@/components/controls';

const columns = [
  { header: 'Name', accessorKey: 'name' },
  { header: 'Custody Number', accessorKey: 'custodyNumber' },
  { header: 'Occurrence Number', accessorKey: 'number' },
  { header: 'Offence', accessorKey: 'type' },
  { header: 'Offence Outcome', accessorKey: 'arrestReason' },
  { header: 'Arresting Officer', accessorKey: 'arrestingOfficerCode' },
  { header: 'Investigating Officer', accessorKey: 'investigatingOfficerCode' },
  { header: 'OIC', accessorKey: 'officerInCaseCode' },
  { header: 'OIC Team', accessorKey: 'owningTeam' },
  { header: 'Date of Arrest', accessorKey: 'arrestedTime' },
  { header: 'Custody Unit', accessorKey: 'custodyUnit' },
];

export function Arrests({ groupCodes }) {
  const [pastDays, setPastDays] = useState(120);
  const { data, isLoading, isFetching } = useArrests({
    codes: groupCodes,
  });

  const { data: officers } = useOfficers();
  const { data: custodyUnits } = useCustodyUnits();

  const arrests = useMemo(
    () =>
      data.map((arrest) => ({
        ...arrest,
        owningTeam: getOfficerTeamFromPeople(
          officers,
          arrest?.officerInCaseCode,
          arrest?.owningTeam,
        ),
        custodyUnit: arrest?.custodyUnitId
          ? custodyUnits[arrest.custodyUnitId]
          : undefined,
      })),
    [data],
  );

  const [filteredData, setFilteredData] = useState(arrests);
  useEffect(() => {
    const result = filterByPastDays(pastDays, arrests);
    setFilteredData(result);
  }, [pastDays, arrests]);

  function onFilterChange(value) {
    setPastDays(value);
  }

  return (
    <Fragment>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{
          position: 'sticky',
          top: 48,
          backgroundColor: 'background.default',
          zIndex: 700,
          pl: 1,
          py: 1,
        }}
      >
        <Typography variant="h6">Arrests</Typography>
        <PastDaysFilter days={pastDays} onFilterChange={onFilterChange} />
      </Stack>
      <Stack spacing={1} sx={{ pb: 1.5 }}>
        <MaterialReactTable
          muiTablePaperProps={{ variant: 'outlined', elevation: 0 }}
          defaultColumn={{ size: 0 }}
          columns={columns}
          data={filteredData}
          enableGrouping
          enableBottomToolbar={false}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enablePagination={false}
          state={{ isLoading: isLoading || isFetching }}
          initialState={{
            density: 'compact',
            expanded: true,
            sorting: [{ id: 'number', desc: false }],
          }}
          muiTableBodyRowProps={{
            sx: { '&:last-child td, &:last-child th': { border: 0 } },
          }}
          muiSearchTextFieldProps={{ variant: 'outlined', size: 'small' }}
        />
      </Stack>
    </Fragment>
  );
}

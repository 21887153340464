import { sideNavOpenAtom } from '@/atoms';
import { Menu as MenuIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useAtom } from 'jotai';

export function MenuButton(props) {
  const [, setIsOpen] = useAtom(sideNavOpenAtom);

  function handleClick() {
    setIsOpen(true);
  }

  return (
    <IconButton onClick={handleClick} size="large" {...props}>
      <MenuIcon />
    </IconButton>
  );
}

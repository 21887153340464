import { useMember } from '@/hooks';
import { KeyboardDoubleArrowUp as KeyboardDoubleArrowUpIcon } from '@mui/icons-material';
import { Stack, Tooltip, Typography } from '@mui/material';

export function OfficerInCharge({ code }) {
  const { data: officer } = useMember(code);

  return (
    officer && (
      <Tooltip title="Officer in Charge" placement="left-start">
        <Stack direction="row">
          <KeyboardDoubleArrowUpIcon fontSize="small" />
          <Typography variant="caption" color="text.secondary" gutterBottom>
            {officer.collarNumber}
          </Typography>
        </Stack>
      </Tooltip>
    )
  );
}

import { api } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useAddOptionValue() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ name, value, entry }) => {
      return api
        .patch(`options/${name}-${value}/values/${entry}`, {
          headers: { 'content-type': 'application/merge-patch+json' },
        })
        .json();
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['options'] }),
  });
}

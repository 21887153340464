import { TableCell, TableRow, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

export function ItemRows(labelValuePairs) {
  return labelValuePairs.map((labelValuePair) => (
    <TableRow
      key={labelValuePair.label + labelValuePair.value}
      style={{ backgroundColor: labelValuePair.disabled ? grey[300] : '' }}
    >
      <TableCell>
        <Typography
          style={labelValuePair.labelStyle}
          variant="caption"
          color="textSecondary"
        >
          {labelValuePair.label}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography style={labelValuePair.valueStyle} variant="caption">
          {labelValuePair.value}
        </Typography>
      </TableCell>
    </TableRow>
  ));
}

import {
  FETCH_VEHICLE_AVAILABILITY,
  FETCH_VEHICLE_AVAILABILITY_CANCELLED,
  FILTER_VEHICLE_AVAILABILITY,
  UPDATE_FILTER_END_TIME,
  UPDATE_FILTER_START_TIME,
  UPDATE_VEHICLE_AVAILABILITY_HOME_ONLY,
} from '@/actions';
import {
  DatePicker,
  SelectMultiple,
  Table,
  TablePagination,
} from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import { downloadCSV, getFilenameForDownload, round } from '@/utils';
import { rowsPerPageOptions } from '@/utils/config';
import {
  Autorenew as AutorenewIcon,
  Close as CloseIcon,
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
  Remove as RemoveIcon,
} from '@mui/icons-material';
import {
  Box,
  Checkbox,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Switch,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { teal } from '@mui/material/colors';
import { addHours, format, getDayOfYear } from 'date-fns';
import { dequal } from 'dequal';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Bar,
  BarChart,
  Tooltip as ChartTooltip,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

const CustomAvailabilityTooltip = ({ active, payload }) => {
  if (active && payload) {
    const { hour, count } = payload[0].payload;
    const hourString = format(hour, 'dd/MM/yyyy HH:mm');
    const nextHour = addHours(hour, 1);
    const nextHourString = format(
      nextHour,
      getDayOfYear(hour) === getDayOfYear(nextHour)
        ? 'HH:mm'
        : 'dd/MM/yyyy HH:mm',
    );

    return (
      <Box
        className="recharts-default-tooltip"
        style={{
          margin: '0px',
          padding: '10px',
          backgroundColor: 'rgb(255, 255, 255)',
          border: '1px solid rgb(204, 204, 204)',
          whiteSpace: 'nowrap',
        }}
      >
        <p className="recharts-tooltip-label" style={{ margin: '0px' }}>
          {`${count} vehicle${
            count === 1 ? '' : 's'
          } between ${hourString} and ${nextHourString}`}
        </p>
      </Box>
    );
  }

  return null;
};

export function VehicleAvailability() {
  useDocumentTitle('IR3 | Vehicle Availability');
  const dispatch = useDispatch();
  const homeOnly = useSelector(
    (state) => state.reports.vehicleAvailability.homeOnly,
  );
  const data = useSelector(
    (state) => state.reports.vehicleAvailability.filteredData,
    dequal,
  );
  const isLoading = useSelector(
    (state) => state.reports.vehicleAvailability.isLoading,
  );
  const error = useSelector((state) => state.reports.vehicleAvailability.error);
  const filter = useSelector(
    (state) => state.reports.vehicleAvailability.filter,
    dequal,
  );
  const filterOptions = useSelector(
    (state) => state.reports.vehicleAvailability.filterOptions,
    dequal,
  );
  const startTime = useSelector((state) => state.filters.startTime, dequal);
  const endTime = useSelector((state) => state.filters.endTime, dequal);
  const [showFilter, setShowFilter] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('Group');
  const [customConfidence, setCustomConfidence] = useState({
    error: false,
    curr: 0,
    next: 0,
  });
  const [timeDescendingChart, setTimeDescendingChart] = useState(true);

  function percentValue(value) {
    return round(100 * value, 2);
  }

  function percentString(value) {
    return `${percentValue(value)}%`;
  }

  function handleGraphSwitch() {
    setTimeDescendingChart(!timeDescendingChart);
  }

  function DrillDown({ entry: { histogram, availabilities } }) {
    let mainChartData = histogram;

    if (timeDescendingChart) {
      // do a descending subtractive sum, so instead of
      // 0 for 1 hour, 1 for 3 hours, 2 for 1 hour
      // we get
      // 0 or more for 5 hours, 1 or more for 4 hours, 2 or more for 1 hour
      // turn it into a percentage altogether
      // 0 or more 100% (5/5), 1 or more 80% (4/5), 2 or more 20% (1/5)
      const overallTotal = histogram.reduce((total, h) => total + h.hours, 0);
      // remove the one for 0 as it's redundant...
      mainChartData = histogram
        .filter(({ count }) => count - 0 !== 0)
        .map(({ count }) => ({
          count,
          hours: percentValue(
            histogram.reduce(
              (total, h) => total + h.hours * (h.count - count >= 0),
              0,
            ) / overallTotal,
          ),
        }));
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', p: [0, 2, 2, 2] }}>
        <Typography component="div" sx={{ ml: 1 }}>
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>Availability percent</Grid>
            <Grid item>
              <Switch
                checked={!timeDescendingChart}
                onChange={handleGraphSwitch}
              />
            </Grid>
            <Grid item>Histogram</Grid>
          </Grid>
        </Typography>
        {/* <Typography variant="subtitle1">
          Histogram
        </Typography>
        <Switch checked={timeDescendingChart} onChange={handleGraphSwitch} /> */}
        <ResponsiveContainer width="99%" height={400}>
          <BarChart
            data={mainChartData}
            margin={{ top: 0, right: 16, left: 0, bottom: 32 }}
            barCategoryGap={4}
          >
            <XAxis
              dataKey="count"
              // angle={-90}
              // textAnchor="end"
              interval={0}
            >
              <Label value="Vehicles available" offset={6} position="bottom" />
            </XAxis>
            <YAxis>
              <Label
                value={timeDescendingChart ? 'Percent' : 'Total Hours'}
                angle={-90}
                position="left"
                offset={-24}
              />
            </YAxis>
            <ChartTooltip
              cursor={false}
              content={<CustomHistogramTooltip data={histogram} />}
            />
            <Bar dataKey="hours" fill={teal[500]} isAnimationActive={false} />
          </BarChart>
        </ResponsiveContainer>
        <ResponsiveContainer width="99%" height={200}>
          <BarChart
            data={availabilities}
            margin={{ top: 0, right: 16, left: 0, bottom: 32 }}
            // barCategoryGap={4}
          >
            <XAxis
              dataKey="hour"
              // angle={-90}
              textAnchor="end"
              // interval={0}
            >
              <Label value="Time" offset={-6} position="bottom" />
            </XAxis>
            <YAxis allowDecimals={false}>
              <Label
                value="Vehicles"
                angle={-90}
                position="left"
                offset={-24}
              />
            </YAxis>
            <ChartTooltip
              cursor={false}
              content={<CustomAvailabilityTooltip />}
            />
            <Bar dataKey="count" fill={teal[500]} isAnimationActive={false} />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    );
  }

  const CustomHistogramTooltip = ({ active, label, data }) => {
    if (active) {
      const totalHours = data.reduce((total, el) => total + el.hours, 0);
      const atLeast = data.reduce(
        (total, el) => total + (el.count - label >= 0 ? el.hours : 0),
        0,
      );
      const value = data.find((d) => d.count - label === 0)?.hours || 0;
      const more =
        label - 0 !== Math.max(...data.map((d) => parseInt(d.count)));

      return (
        <Box
          className="recharts-default-tooltip"
          style={{
            margin: '0px',
            padding: '10px',
            backgroundColor: 'rgb(255, 255, 255)',
            border: '1px solid rgb(204, 204, 204)',
            whiteSpace: 'nowrap',
          }}
        >
          {!timeDescendingChart ? (
            <p className="recharts-tooltip-label" style={{ margin: '0px' }}>
              {`Exactly ${label} vehicle${label === '1' ? ' was' : 's were'}
              available for ${value} out of ${totalHours} hours or 
              ${percentString(value / totalHours)} of the time.`}
            </p>
          ) : (
            <p className="recharts-tooltip-label" style={{ margin: '0px' }}>
              {`${label} ${
                more ? 'or more' : ''
              } vehicles were available ${percentString(
                atLeast / totalHours,
              )} of the time`}
            </p>
          )}
        </Box>
      );
    }

    return null;
  };

  const dropdownHeader = [
    {
      label: '',
      key: 'expand',
      type: 'expand',
      component: DrillDown,
    },
  ];

  const headers = [
    {
      label: 'Location',
      key: 'location',
      type: 'text',
    },
    // {
    //   label: 'Location Type',
    //   key: 'locationType',
    //   type: 'text',
    // },
    {
      label: 'Role',
      key: 'grouping',
      type: 'text',
    },
    customConfidence.curr !== 0 && {
      label: `${customConfidence.curr}%`,
      key: 'pCustom',
      type: 'number',
    },
    {
      label: '95%',
      key: 'p95',
      type: 'number',
    },
    {
      label: '97.5%',
      key: 'p975',
      type: 'number',
    },
    {
      label: '99%',
      key: 'p99',
      type: 'number',
    },
    {
      label: 'Mean',
      key: 'mean',
      type: 'number',
    },
    {
      label: 'Deviation',
      key: 'std',
      type: 'number',
    },
  ].filter(Boolean);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error]);

  function handleFilterToggle() {
    setShowFilter(!showFilter);
  }

  function handleRefreshClick() {
    if (isLoading) {
      dispatch({
        type: FETCH_VEHICLE_AVAILABILITY_CANCELLED,
      });
    } else {
      dispatch({
        type: FETCH_VEHICLE_AVAILABILITY,
        payload: {
          startTime,
          endTime,
          filter,
          homeOnly,
          customConfidence: customConfidence.next,
        },
      });

      setCustomConfidence({
        ...customConfidence,
        curr: customConfidence.next,
      });
    }
  }

  function handleFilterFieldChanged(name, value) {
    const newFilter =
      name in filter
        ? {
            ...filter,
            [name]: value || [],
          }
        : {
            ...filter,
            groups: {
              ...filter.groups,
              [name]: value || [],
            },
          };

    dispatch({
      type: FILTER_VEHICLE_AVAILABILITY,
      payload: { filter: newFilter },
    });
  }

  function handlePageChange(event, newPage) {
    setPage(newPage);
  }

  function handleRowsPerPageChange(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleOrderChange(order) {
    setOrder(order);
  }

  function handleOrderByChange(orderBy) {
    setOrderBy(orderBy);
    setOrder('asc');
  }

  async function handleDownloadClick() {
    const filename = getFilenameForDownload(
      'Vehicle Availability',
      'csv',
      startTime,
      endTime,
    );

    downloadCSV(data, filename, headers);
  }

  // const xAxisHeight = 16;
  // data.length === 0
  //   ? 0
  //   : getTextWidth(
  //       data.map((item) => item.Group).sort((a, b) => b.length - a.length)[0],
  //       '12px Roboto'
  //     ) + 16;

  function handleCustomConfidenceChange(event) {
    const string = event.target.value;
    const value = /^-?[\d]*(\.[\d]+)?$/g.test(string) && parseFloat(string);

    if (value && 0 < value && value < 100) {
      setCustomConfidence({
        error: false,
        next: value,
        curr: customConfidence.curr,
      });
    } else {
      setCustomConfidence({
        error: !!string, // if it's blank don't show an error
        next: 0,
        curr: customConfidence.curr,
      });
    }
  }

  function handleHomeOnlyChanged(event) {
    dispatch({
      type: UPDATE_VEHICLE_AVAILABILITY_HOME_ONLY,
      payload: event.target.checked,
    });
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 48px)',
        overflow: 'hidden',
        backgroundColor: 'background.default',
      }}
    >
      <Box
        sx={{
          width: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Toolbar>
          <Typography variant="subtitle1">Custom Confidence</Typography>
          <TextField
            size="small"
            type="number"
            error={customConfidence.error}
            onChange={handleCustomConfidenceChange}
            sx={{ width: 100, mr: 2, ml: 1 }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          <Checkbox
            checked={homeOnly}
            onChange={handleHomeOnlyChanged}
            datacy="homeStationOnly"
          />
          <Typography variant="subtitle1">Home Station only</Typography>
          <Box sx={{ flexGrow: 1 }} />
          {isLoading && (
            <CircularProgress sx={{ m: 1 }} size={16} thickness={6} />
          )}
          <DatePicker
            value={startTime}
            onChange={(date) =>
              dispatch({
                type: UPDATE_FILTER_START_TIME,
                payload: date,
              })
            }
            clearable
            maxDate={endTime || new Date('2100-01-01')}
            datacy="start"
            sx={{ width: 172 }}
          />
          <RemoveIcon sx={{ px: 0.5 }} />
          <DatePicker
            value={endTime}
            onChange={(date) =>
              dispatch({
                type: UPDATE_FILTER_END_TIME,
                payload: date,
              })
            }
            clearable
            minDate={startTime || new Date('1900-01-01')}
            disableFuture
            datacy="end"
            sx={{ width: 172 }}
          />
          <Tooltip title={isLoading ? 'Cancel' : 'Fetch'}>
            <IconButton onClick={handleRefreshClick} datacy="fetch">
              {isLoading ? <CloseIcon color="error" /> : <AutorenewIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title={showFilter ? 'Hide filter' : 'Show filter'}>
            <IconButton onClick={handleFilterToggle}>
              <FilterListIcon color={showFilter ? 'primary' : 'inherit'} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download data">
            <Box component="span">
              <IconButton
                disabled={data.length === 0}
                onClick={handleDownloadClick}
              >
                <GetAppIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </Toolbar>
        <Collapse in={showFilter} timeout="auto">
          <Stack spacing={1} sx={{ flex: 1, p: 1 }}>
            <SelectMultiple
              label="Locations"
              placeholder="Select..."
              value={filter.location ?? []}
              onChange={(value) => handleFilterFieldChanged('location', value)}
              suggestions={filterOptions.location?.map((value) => ({
                label: value,
                value,
              }))}
              labelValue
            />
            {/* <SelectMultiple
              fullWidth
              label="Location Types"
              placeholder="Select..."
              value={filter.locationType?.map((value) => ({
                label: value,
                value,
              }))}
              onChange={(value) =>
                handleFilterFieldChanged('locationType', value)
              }
              suggestions={filterOptions.locationType?.map((value) => ({
                label: value,
                value,
              }))}
            /> */}
            <SelectMultiple
              label={'Roles'}
              placeholder="Select..."
              value={filter.grouping ?? []}
              onChange={(value) => handleFilterFieldChanged('grouping', value)}
              suggestions={filterOptions.grouping?.map((value) => ({
                label: value,
                value,
              }))}
              labelValue
            />
          </Stack>
        </Collapse>
        <Paper sx={{ m: 1, minWidth: 240, fontSize: 12 }}>
          <Table
            styles={{
              tableContainer: {
                height: 'calc(100vh - 182px)',
                overflowY: 'scroll',
              },
            }}
            data={data}
            headers={[...dropdownHeader, ...headers]}
            rowsPerPage={rowsPerPage}
            page={page}
            keyName="stopKey"
            dense={true}
            order={order}
            orderBy={orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </Box>
    </Box>
  );
}

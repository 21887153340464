import { shortHumanizer } from '@/utils';
import { useDallasKeys } from '@/utils/config';

export const pipelineFn = (orderBy, order, page, rowsPerPage, query) => [
  { $match: query },
  {
    $project: {
      identifier: true,
      startTime: true,
      endTime: true,
      durationSeconds: true,
      point: true,
      lastDriver: true,
      vehicle: true,
      lastRfidCard: true,
      locations: {
        $ifNull: ['$locations', [{ name: 'Elsewhere', type: 'None' }]],
      },
    },
  },
  { $sort: { [orderBy]: order === 'desc' ? -1 : 1 } },
  { $skip: page * rowsPerPage },
  { $limit: rowsPerPage },
];

export const downloadPipelineFn = (query) => [
  { $match: query },
  {
    $project: {
      identifier: true,
      startTime: true,
      endTime: true,
      durationSeconds: { $divide: ['$durationSeconds', 86400] },
      lastDriver: true,
      vehicle: true,
      lastRfidCard: true,
      locations: {
        $ifNull: ['$locations', [{ name: 'Elsewhere', type: 'None' }]],
      },
    },
  },
];

export const headers = [
  {
    label: 'Registration',
    key: 'vehicle.registrationNumber',
    type: 'text',
  },
  {
    label: 'Fleet Number',
    key: 'vehicle.fleetNumber',
    type: 'text',
  },
  {
    label: 'Role',
    key: 'vehicle.role',
    type: 'text',
  },
  {
    label: 'Division',
    key: 'vehicle.groups.division',
    type: 'text',
  },
  {
    label: 'Type',
    key: 'vehicle.type',
    type: 'text',
  },
  {
    label: 'Staff ID',
    key: 'lastDriver.code',
    type: 'text',
  },
  {
    label: 'Forenames',
    key: 'lastDriver.forenames',
    type: 'text',
  },
  {
    label: 'Surname',
    key: 'lastDriver.surname',
    type: 'text',
  },
  {
    label: 'Collar Number',
    key: 'lastDriver.collarNumber',
    type: 'text',
  },
  {
    label: 'Driver Role',
    key: 'lastDriver.role',
    type: 'text',
  },
  {
    label: 'IMEI',
    key: 'vehicle.telematicsBoxImei',
    type: 'text',
  },
  {
    label: useDallasKeys ? 'Dallas Key' : 'RFID Card',
    key: 'lastRfidCard.reference',
    type: 'text',
  },
  {
    label: `${useDallasKeys ? 'Dallas Key' : 'RFID Card'} Type`,
    key: 'lastRfidCard.type',
    type: 'text',
  },
  {
    label: `${useDallasKeys ? 'Dallas Key' : 'RFID Card'} Label`,
    key: 'lastRfidCard.label',
    type: 'text',
  },
  {
    label: 'Start Time',
    key: 'startTime',
    type: 'date',
  },
  {
    label: 'End Time',
    key: 'endTime',
    type: 'date',
  },
  {
    label: 'Duration',
    key: 'durationSeconds',
    format: (value) => shortHumanizer(value * 1000),
    type: 'text',
    align: 'right',
  },
  {
    label: 'Home Location',
    key: 'vehicle.homeStation',
    type: 'text',
  },
  {
    label: 'Location Name',
    key: 'locations.0.name',
    type: 'text',
  },
  {
    label: 'Location Type',
    key: 'locations.0.type',
    type: 'text',
  },
];

import {
  FETCH_USER_IDS,
  FETCH_USER_IDS_FAILURE,
  FETCH_USER_IDS_SUCCESS,
  UPDATE_AUDIT_LOG_FILTER,
  UPDATE_FILTER_CARD_USED,
  UPDATE_FILTER_EMERGENCY_EQUIPMENT_USED,
  UPDATE_FILTER_END_TIME,
  UPDATE_FILTER_LOCATION_TYPE,
  UPDATE_FILTER_START_TIME,
  UPDATE_FILTER_USER_ID,
} from '@/actions';
import { rowsPerPageOptions } from '@/utils/config';
import { startOfDay, subDays } from 'date-fns';

const INITIAL_STATE = {
  incidents: null,
  crimes: null,
  intelligence: null,
  stopChecks: null,
  startTime: subDays(startOfDay(new Date(), 'day'), 2),
  endTime: startOfDay(new Date(), 'day'),
  // startTime: new Date('2020-05-01'),
  // endTime: new Date('2020-05-30'),
  locationType: '',
  cardUsed: 2,
  emergencyEquipmentUsed: 2,
  userIds: [],
  userId: null,
  isLoading: false,
  error: null,
  auditLog: {
    startTime: subDays(startOfDay(new Date(), 'day'), 2),
    endTime: startOfDay(new Date(), 'day'),
    page: 0,
    rowsPerPage: rowsPerPageOptions[0],
    order: 'asc',
    orderBy: 'time',
    userId: null,
    dataType: null,
    action: null,
  },
};

export default function filtersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_USER_IDS:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_USER_IDS_SUCCESS:
      return {
        ...state,
        userIds: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_USER_IDS_FAILURE:
      return { ...state, userIds: [], isLoading: false, error: action.payload };
    case UPDATE_FILTER_START_TIME:
      return {
        ...state,
        startTime: action.payload ? startOfDay(new Date(action.payload)) : null,
      };
    case UPDATE_FILTER_END_TIME:
      return {
        ...state,
        endTime: action.payload ? startOfDay(new Date(action.payload)) : null,
      };
    case UPDATE_FILTER_LOCATION_TYPE:
      return { ...state, locationType: action.payload };
    case UPDATE_FILTER_CARD_USED:
      return { ...state, cardUsed: action.payload };
    case UPDATE_FILTER_EMERGENCY_EQUIPMENT_USED:
      return { ...state, emergencyEquipmentUsed: action.payload };
    case UPDATE_FILTER_USER_ID:
      return { ...state, userId: action.payload };
    case UPDATE_AUDIT_LOG_FILTER:
      return { ...state, auditLog: action.payload };
    default:
      return state;
  }
}

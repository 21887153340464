import { CustomTooltip } from '@/components/controls';
import { useTotalAttendedIncidents } from '@/hooks';
import { getTextWidth, randomHsl, sortByKey, startCase } from '@/utils';
import { highlights } from '@/utils/config';
import { Avatar, Box, Card, CardContent, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import {
  Bar,
  BarChart,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const {
  totalAttendedIncidents: { lookbackDays },
} = highlights;

export function TotalAttendedIncidentsCard({
  groupType,
  groupId,
  subgroupType,
}) {
  const [hiddenGrades, setHiddenGrades] = useState([]);
  const {
    data: { counts, grades },
  } = useTotalAttendedIncidents(groupType, groupId, subgroupType, lookbackDays);

  const bars = Array.from(grades.keys()).sort();
  const values = Object.entries(counts)
    .map(([group, values]) => ({
      group,
      ...values,
    }))
    .sort(sortByKey('group'));

  const xAxisHeight =
    counts.length > 0
      ? Math.ceil(
          Math.max(
            ...Object.keys(counts).map((item) =>
              getTextWidth(item.group ?? '', '12px Roboto'),
            ),
          ),
        )
      : 30;

  const handleLegendClick = (selectedBar) => () => {
    const index = hiddenGrades.indexOf(selectedBar);

    if (index === -1) {
      setHiddenGrades(hiddenGrades.concat(selectedBar));
    } else {
      setHiddenGrades(
        hiddenGrades.slice(0, index).concat(hiddenGrades.slice(index + 1)),
      );
    }
  };

  return (
    <Card variant="outlined" sx={{ flexGrow: 1 }}>
      <CardContent>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          Attended Incidents
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{ width: 1, textAlign: 'center' }}
            variant="caption"
            color="text.secondary"
          >
            Grade
          </Typography>
          {bars.sort().map((grade, index) => {
            const count = Array.from(grades.keys()).length;
            const colour = randomHsl(index + 1, count);

            return (
              <Box
                key={grade}
                sx={{
                  p: 0.5,
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={handleLegendClick(grade)}
              >
                <Avatar
                  sx={{
                    width: 12,
                    height: 12,
                    mr: 0.5,
                    bgcolor: !hiddenGrades.includes(grade) && colour,
                  }}
                >
                  <Fragment />
                </Avatar>
                <Typography variant="caption">{grade}</Typography>
              </Box>
            );
          })}
        </Box>
        <Box sx={{ pt: 1, fontSize: 12 }}>
          <ResponsiveContainer width="99%" height={520}>
            <BarChart
              data={values}
              margin={{ right: 16, bottom: xAxisHeight + 24, left: 16 }}
            >
              <XAxis
                dataKey="group"
                angle={-90}
                textAnchor="end"
                interval={0}
                height={xAxisHeight}
              >
                <Label
                  value={startCase(subgroupType)}
                  offset={xAxisHeight + 16}
                  position="bottom"
                />
              </XAxis>
              <YAxis>
                <Label value="Count" angle={-90} position="left" />
              </YAxis>
              <Tooltip
                wrapperStyle={{ outline: 'none' }} // TODO: This is temporary due to a bug in recharts https://github.com/recharts/recharts/issues/2920
                cursor={false}
                content={<CustomTooltip unit="incidents" variant="outlined" />}
              />
              {bars.map((grade, index) => {
                const count = bars.length;
                const colour = randomHsl(index + 1, count);

                return (
                  <Bar
                    key={grade}
                    dataKey={grade}
                    stackId="a"
                    name={`Grade ${grade}`}
                    hide={hiddenGrades.includes(grade)}
                    fill={colour}
                  />
                );
              })}
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
}

import { api } from '@/apis';
import { encodeParams, log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useOfficers() {
  return useQuery({
    queryKey: ['officers'],
    queryFn: () => {
      const searchParams = encodeParams({
        pipeline: [
          {
            $match: {
              deleted: { $ne: true },
              $or: [
                { leavingDate: { $exists: false } },
                { leavingDate: { $gte: new Date() } },
              ],
            },
          },
          {
            $graphLookup: {
              from: 'groups',
              startWith: '$groupCodes',
              connectFromField: 'parentCodes',
              connectToField: 'code',
              as: 'groups',
              depthField: 'depth',
            },
          },
          {
            $project: {
              _id: false,
              code: true,
              forenames: true,
              surname: true,
              collarNumber: true,
              team: {
                $first: {
                  $map: {
                    input: {
                      $sortArray: {
                        input: {
                          $filter: {
                            input: '$groups',
                            cond: {
                              $eq: ['$$group.type', 'TEAM'],
                            },
                            as: 'group',
                          },
                        },
                        sortBy: {
                          depth: 1,
                        },
                      },
                    },
                    as: 'group',
                    in: {
                      name: '$$group.name',
                      type: '$$group.type',
                    },
                  },
                },
              },
            },
          },
        ],
      });

      log('Read', 'Officers');

      return api.get('people', { searchParams }).json();
    },
    staleTime: 1380 * (60 * 1000), // only refetch every 23 hour
    cacheTime: 1440 * (60 * 1000), // keep data in cache for 24 hours
  });
}

import { useGroups, useSections } from '@/hooks';
import { listGroupLabels } from '@/utils';
import { Done as DoneIcon } from '@mui/icons-material';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Fragment } from 'react';

export function Placeholder({ id, groupCodes, onComplete }) {
  const { data: groups } = useGroups({ type: ['SECTOR', 'TEAM'] });
  const { data: sections } = useSections();

  function handleClick() {
    onComplete();
  }

  return (
    <Fragment>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{
          position: 'sticky',
          top: 48,
          backgroundColor: 'background.default',
          zIndex: 700,
          pl: 1,
          py: 1,
        }}
      >
        <Typography variant="h6">{sections[id]}</Typography>
        <Box>
          <Tooltip title="Done" placement="right">
            <IconButton onClick={handleClick}>
              <DoneIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
      <Stack spacing={1} sx={{ pb: 1 }}>
        <Typography sx={{ p: 1 }}>
          This is a placeholder for section {sections[id]} for{' '}
          {groups && listGroupLabels('Group', groupCodes, groups)}
        </Typography>
      </Stack>
    </Fragment>
  );
}

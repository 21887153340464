import { briefingApi } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertNotice() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (json) => {
      if (json.code) {
        return briefingApi.patch(`notices/${json.code}`, { json }).json();
      } else {
        return briefingApi.post('notices', { json }).json();
      }
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['notices'] }),
  });
}

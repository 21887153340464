import { useAuth } from '@/hooks';
import { userQueryTitleDoesNotExist } from '@/utils';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TextFieldController } from '../controllers';

export function SaveDialog({
  open,
  onClose,
  collection,
  value,
  enableInterval,
}) {
  const auth = useAuth();
  const {
    control,
    formState: { isSubmitting },
    getValues,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      title: value.title ?? '',
      interval: value.interval ?? '',
    },
  });

  useEffect(() => {
    reset({
      title: value.title ?? '',
      interval: value.interval ?? '',
    });
  }, [reset, value]);

  function handleClose() {
    onClose();
  }

  function submitCallback(values) {
    onClose(values);
  }

  function titleDoesNotExist(title) {
    const _id = getValues('_id');

    return userQueryTitleDoesNotExist(
      _id,
      title,
      collection,
      auth.getUsername(),
    );
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form id="user-query-form" onSubmit={handleSubmit(submitCallback)}>
        <DialogTitle id="dialog-title">Save query</DialogTitle>
        <DialogContent>
          <Stack spacing={1} sx={{ pt: 0.75 }}>
            <TextFieldController
              name="title"
              label="Title"
              control={control}
              defaultValue=""
              rules={{ required: 'Required', validate: titleDoesNotExist }}
              fullWidth
            />
            {enableInterval && (
              <TextFieldController
                name="interval"
                label="Email Frequency"
                control={control}
                defaultValue=""
                helperText="Choose weekly or daily if you wish to have the report emailed to you"
                options={[
                  { label: 'Never', value: '' },
                  { label: 'Daily', value: 'daily' },
                  { label: 'Weekly', value: 'weekly' },
                  { label: 'Monthly', value: 'monthly' },
                ]}
                fullWidth
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="primary" disabled={isSubmitting} onClick={handleClose}>
            Cancel
          </Button>
          <Button color="primary" type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

import { alertsAtom } from '@/atoms';
import { useAuth, useNotificationSocket, useNotifications } from '@/hooks';
import { startCase } from '@/utils';
import {
  eventNotifications,
  showAdGroups,
  showClearCacheButton,
} from '@/utils/config';
import {
  AccountCircle as AccountCircleIcon,
  Whatshot as WhatshotIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useAtom } from 'jotai';
import { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { NotificationList } from './NotificationList';

export function LoginAvatar() {
  const navigate = useNavigate();
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const profile = auth.getProfile();
  const open = Boolean(anchorEl);
  const id = open ? 'login-popover' : undefined;
  const { data: notifications } = useNotifications(
    auth.isAuthenticated() && Object.keys(eventNotifications).length > 0,
  );
  useNotificationSocket(auth.isAuthenticated());
  const [alerts] = useAtom(alertsAtom);
  const queryClient = useQueryClient();

  function handleAvatarClick(event) {
    if (auth.isAuthenticated()) {
      setAnchorEl(event.currentTarget);
    } else {
      auth.login();
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleProfileClick() {
    navigate(`/resources/people/${profile.code}`);
    handleClose();
  }

  function handleLogoutClick() {
    auth.logout();
    handleClose();
  }

  function handleClearCacheClick() {
    queryClient.clear();
  }

  return (
    <Fragment>
      <IconButton
        sx={{ p: 0 }}
        aria-describedby={id}
        onClick={handleAvatarClick}
        id="login"
        size="large"
      >
        <Badge
          badgeContent={notifications?.length + alerts?.length || 0}
          color="error"
          overlap="circular"
        >
          <Avatar
            sx={{
              width: 36,
              height: 36,
              fontSize: 16,
              bgcolor: 'primary.dark',
            }}
            src={auth.isAuthenticated() ? profile.picture : undefined}
          >
            {auth.isAuthenticated() ? (
              `${profile.forenames ? profile.forenames[0] : ''}${
                profile.surname ? profile.surname[0] : ''
              }` || <AccountCircleIcon color="secondary" />
            ) : (
              <AccountCircleIcon />
            )}
          </Avatar>
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="caption">
            {'Logged in as '}
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              {auth.getUsername()}
            </Box>
          </Typography>
        </Box>
        {profile.code && (
          <Fragment>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="subtitle2">{`${profile.forenames} ${profile.surname}`}</Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {profile.collarNumber}
                </Typography>
              </Box>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        Rank
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">
                        {(profile.rank || {}).name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        Role
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">{profile.role}</Typography>
                    </TableCell>
                  </TableRow>
                  {Object.entries(profile.groups || {}).map(
                    ([type, values], index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Typography variant="caption" color="textSecondary">
                            {startCase(type)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption">
                            {(values || []).join(', ')}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              </Table>
            </Box>
          </Fragment>
        )}
        {alerts?.length > 0 && (
          <Fragment>
            <Divider />
            <Box sx={{ padding: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  sx={{ pb: 1 }}
                  variant="subtitle2"
                  color="textSecondary"
                >
                  Accelerometer Alerts
                </Typography>
                <Chip size="small" color="error" label={alerts.length} />
              </Box>
              <List dense disablePadding sx={{ maxWidth: 400 }}>
                {alerts
                  .sort((a, b) => new Date(b.time) - new Date(a.time))
                  .map((alert) => (
                    <ListItemButton
                      dense
                      key={alert.identifier}
                      component={Link}
                      to={`/live/events/${alert.identifier}`}
                    >
                      <ListItemText
                        primary={`${
                          alert.vehicle.driver
                            ? `${alert.driver.forenames} ${alert.driver.surname} (${alert.driver.collarNumber})`
                            : 'Unknown driver'
                        } - ${alert.vehicle.registrationNumber} (${
                          alert.vehicle.fleetNumber
                        })`}
                        secondary={format(
                          new Date(alert.time),
                          'dd/MM/yyyy HH:mm:ss',
                        )}
                      />
                    </ListItemButton>
                  ))}
              </List>
            </Box>
          </Fragment>
        )}
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography sx={{ pb: 1 }} variant="subtitle2" color="textSecondary">
            Authorisation Groups
          </Typography>
          {auth.getGroupNames().map((name) => (
            <Box key={name}>
              <Typography variant="caption" color="textSecondary">
                {name}
              </Typography>
            </Box>
          ))}
        </Box>
        {showAdGroups && (
          <Fragment>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Typography
                sx={{ pb: 1 }}
                variant="subtitle2"
                color="textSecondary"
              >
                Active Directory Groups
              </Typography>
              {auth.getDirectoryGroupIdentifiers().map((identifier) => (
                <Box key={identifier}>
                  <Typography variant="caption" color="textSecondary">
                    {identifier}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Fragment>
        )}
        {notifications?.length > 0 && (
          <Fragment>
            <Divider />
            <Box sx={{ padding: 2 }}>
              <NotificationList notifications={notifications} />
            </Box>
          </Fragment>
        )}
        <Stack
          spacing={1}
          direction="row"
          sx={{ justifyContent: 'flex-end', p: 1 }}
        >
          {showClearCacheButton && (
            <Tooltip title="Clear cache">
              <IconButton
                size="small"
                color="warning"
                onClick={handleClearCacheClick}
              >
                <WhatshotIcon />
              </IconButton>
            </Tooltip>
          )}
          <Box sx={{ flexGrow: 1 }} />
          {profile.code && (
            <Button
              size="small"
              variant="outlined"
              onClick={handleProfileClick}
            >
              Profile
            </Button>
          )}
          <Button size="small" variant="outlined" onClick={handleLogoutClick}>
            Logout
          </Button>
        </Stack>
      </Popover>
    </Fragment>
  );
}

import { useDocumentTitle } from '@/hooks';
import { maintenance } from '@/utils/config';
import { Build as BuildIcon } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

export function Maintenance() {
  useDocumentTitle('IR3 | Down for maintenance');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 96px)',
        flex: 1,
      }}
    >
      <Box sx={{ mt: 8, mb: 2 }}>
        <Box sx={{ maxWidth: 600, m: [0, 'auto'], p: [8, 0, 6] }}>
          <Typography align="center" color="textSecondary">
            <BuildIcon style={{ fontSize: 200 }} />
          </Typography>
          <Typography variant="h6" align="center" component="p">
            {maintenance}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

import { briefingApi } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useDeletePrisoner() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (code) => {
      if (code) {
        return briefingApi.delete(`prisoners/${code}`);
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['enRoutePrisoners'],
      }),
  });
}

import { Typography } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';

export function DataGrid({ initialState, ...props }) {
  return (
    <MaterialReactTable
      enableTopToolbar={false}
      enableBottomToolbar={false}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={false}
      enableSorting={false}
      enableFullScreenToggle={false}
      enableDensityToggle={false}
      muiTablePaperProps={{ variant: 'outlined', elevation: 0 }}
      muiTableBodyRowProps={{
        sx: { '&:last-child td, &:last-child th': { border: 0 } },
      }}
      initialState={{
        density: 'compact',
        pagination: {
          pageSize: 5,
        },
        ...initialState,
      }}
      renderEmptyRowsFallback={() => (
        <Typography variant="caption" sx={{ m: 1 }}>
          No data
        </Typography>
      )}
      {...props}
    />
  );
}

import {
  FETCH_VEHICLE_IN_BASE_TIME,
  FETCH_VEHICLE_IN_BASE_TIME_CANCELLED,
  LOAD_VEHICLE_IN_BASE_TIME,
  UPDATE_VEHICLE_IN_BASE_TIME_PARAMETERS,
} from '@/actions';
import {
  CustomTooltip,
  Parameters,
  SelectMultiple,
} from '@/components/controls';
import { db } from '@/data';
import { useDocumentTitle } from '@/hooks';
import {
  downloadCSV,
  formatGroups,
  getFilenameForDownload,
  randomHsl,
  round,
  startCase,
} from '@/utils';
import { vehicleGroups } from '@/utils/config';
import {
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  CardContent,
  Collapse,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { dequal } from 'dequal';
import _ from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Area,
  AreaChart,
  Brush,
  Tooltip as ChartTooltip,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

const headers = [
  { label: 'VIN', key: 'identificationNumber' },
  { label: 'Registration', key: 'registrationNumber' },
  { label: 'Fleet Number', key: 'fleetNumber' },
  { label: 'Vehicle Role', key: 'role' },
  { label: 'Location Name', key: 'locationName' },
  { label: 'Location Type', key: 'locationType' },
  { label: 'Hour', key: 'hour' },
  { label: 'Duration (hours)', key: 'durationHours' },
];

export function HourlyInLocations() {
  useDocumentTitle('IR3 | Hourly in Location');
  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.reports.vehicleInBaseTime.data,
    dequal,
  );
  const parameters = useSelector(
    (state) => state.reports.vehicleInBaseTime.parameters,
    dequal,
  );
  const isLoading = useSelector(
    (state) => state.reports.vehicleInBaseTime.isLoading,
  );
  const error = useSelector((state) => state.reports.vehicleInBaseTime.error);
  const filter = useSelector(
    (state) => state.reports.vehicleInBaseTime.filter,
    dequal,
  );
  const filterValues = useSelector(
    (state) => state.reports.vehicleInBaseTime.filterValues,
    dequal,
  );
  const [showFilter, setShowFilter] = useState(false);
  const [hiddenLines, setHiddenLines] = useState([]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error]);

  useEffect(() => {
    dispatch({
      type: LOAD_VEHICLE_IN_BASE_TIME,
      payload: filter,
    });
  }, [filter, dispatch]);

  function handleFilterToggle() {
    setShowFilter(!showFilter);
  }

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_VEHICLE_IN_BASE_TIME,
      payload: { query, filter },
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_VEHICLE_IN_BASE_TIME_CANCELLED,
    });
  }

  const handleFilterFieldChanged = (name) => (values) => {
    const newFilter =
      name in filter
        ? {
            ...filter,
            [name]: values || [],
          }
        : {
            ...filter,
            groups: {
              ...filter.groups,
              [name]: values || [],
            },
          };

    dispatch({
      type: LOAD_VEHICLE_IN_BASE_TIME,
      payload: newFilter,
    });
  };

  const handleLegendClick = (selectedLine) => () => {
    const index = hiddenLines.indexOf(selectedLine);

    if (index === -1) {
      setHiddenLines(hiddenLines.concat(selectedLine));
    } else {
      setHiddenLines(
        hiddenLines.slice(0, index).concat(hiddenLines.slice(index + 1)),
      );
    }
  };

  function getGroupsHeaders() {
    return Object.keys(filterValues.groups).map((key) => ({
      label: startCase(key),
      key,
    }));
  }

  function handleParametersChange(parameters) {
    dispatch({
      type: UPDATE_VEHICLE_IN_BASE_TIME_PARAMETERS,
      payload: parameters,
    });
  }

  async function handleDownloadClick() {
    const allHeaders = headers.concat(getGroupsHeaders());
    const filename = getFilenameForDownload(
      'Hourly In Locations',
      'csv',
      parameters?.startTime,
      parameters?.endTime,
    );
    const dataArray = await db.vehicleInBaseTime.toArray();
    const data = dataArray[0].map(
      ({ hour, groups, durationSeconds, ...record }) => ({
        ...record,
        time: format(new Date(hour), 'yyyy-MM-dd HH:mm:ss'),
        durationHours: round(durationSeconds / 3600, 2),
        ...formatGroups(groups),
      }),
    );

    downloadCSV(data, filename, allHeaders);
  }

  function getTableHeaders() {
    const headers = [];
    for (let i = 0; i < 24; i++) {
      const key = `${i.toString().padStart(2, '0')}:00`;
      headers.push(key);
    }
    return headers;
  }

  function buildDataForTable(data) {
    const result = {};
    for (let i = 0; i < 24; i++) {
      const key = `${i.toString().padStart(2, '0')}:00`;
      const hourObj = data[i];
      const objWithoutHour = _.omit(hourObj, ['Hour']);
      result[key] = objWithoutHour;
    }
    return result;
  }

  const tableData = buildDataForTable(data);
  const uniqueLocations = _.union(
    _.flatten(_.map(data, (e) => _.keys(e))),
  ).filter((key) => key !== 'Hour');

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 48px)',
        overflow: 'hidden',
        backgroundColor: 'background.default',
      }}
    >
      {isLoading && (
        <LinearProgress
          sx={{ position: 'absolute', width: 1, height: 8 }}
          color="secondary"
        />
      )}
      <Parameters
        collection="intersections"
        onFetch={handleFetch}
        onCancel={handleCancel}
        value={parameters}
        onChange={handleParametersChange}
        isFetching={isLoading}
        sx={{ mt: 1, width: 264 }}
        dateOnly
        vehicle
        location
      />
      <Box
        sx={{
          width: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            Average Vehicles in Location by Hour
          </Typography>
          <Tooltip title={showFilter ? 'Hide filter' : 'Show filter'}>
            <IconButton onClick={handleFilterToggle}>
              <FilterListIcon color={showFilter ? 'primary' : 'inherit'} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download data">
            <Box component="span">
              <IconButton
                disabled={data.length === 0}
                onClick={handleDownloadClick}
              >
                <GetAppIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </Toolbar>
        <Collapse in={showFilter} timeout="auto">
          <Stack spacing={1} sx={{ flex: 1, p: 1 }}>
            <SelectMultiple
              label="Registration Number"
              placeholder="Select..."
              value={filter.registrationNumber}
              labelValue
              onChange={handleFilterFieldChanged('registrationNumber')}
              suggestions={filterValues.registrationNumber.map((value) => ({
                label: value,
                value,
              }))}
            />
            <SelectMultiple
              label="Fleet Number"
              placeholder="Select..."
              value={filter.fleetNumber}
              labelValue
              onChange={handleFilterFieldChanged('fleetNumber')}
              suggestions={filterValues.fleetNumber.map((value) => ({
                label: value,
                value,
              }))}
            />
            <SelectMultiple
              label="Role"
              placeholder="Select..."
              value={filter.role}
              labelValue
              onChange={handleFilterFieldChanged('role')}
              suggestions={filterValues.role.map((value) => ({
                label: value,
                value,
              }))}
            />
            <SelectMultiple
              label="Location Name"
              placeholder="Select..."
              value={filter.locationName}
              labelValue
              onChange={handleFilterFieldChanged('locationName')}
              suggestions={filterValues.locationName.map((value) => ({
                label: value,
                value,
              }))}
            />
            <SelectMultiple
              label="Location Type"
              placeholder="Select..."
              value={filter.locationType}
              labelValue
              onChange={handleFilterFieldChanged('locationType')}
              suggestions={filterValues.locationType.map((value) => ({
                label: value,
                value,
              }))}
            />
            {Object.entries(vehicleGroups).map(([key, { label, values }]) => {
              return (
                <SelectMultiple
                  key={key}
                  label={label}
                  placeholder="Select..."
                  value={filter.groups[key] || []}
                  labelValue
                  onChange={handleFilterFieldChanged(key)}
                  suggestions={values}
                />
              );
            })}
          </Stack>
        </Collapse>
        {Object.keys(data).length > 1 && (
          <Fragment>
            <Paper sx={{ m: [0, 1, 1], minWidth: 240, fontSize: 12 }}>
              <CardContent sx={{ p: 0, pt: 4 }}>
                <Box
                  sx={{
                    pl: 8,
                    pr: 2,
                    pb: 1,
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                  }}
                >
                  {Object.keys(data).length > 1 &&
                    Object.keys(data[0])
                      .filter((key) => key !== 'Hour')
                      .map((locationName, index) => {
                        const count = Object.keys(data[0]).length - 1;
                        const colour = randomHsl(index, count);
                        return (
                          <Box
                            key={locationName}
                            sx={{
                              p: 0.5,
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                            onClick={handleLegendClick(locationName)}
                          >
                            <Avatar
                              sx={{
                                width: 12,
                                height: 12,
                                mr: 0.5,
                                bgcolor:
                                  !hiddenLines.includes(locationName) && colour,
                              }}
                            >
                              <Fragment />
                            </Avatar>
                            <Typography variant="caption">
                              {locationName}
                            </Typography>
                          </Box>
                        );
                      })}
                </Box>
                <ResponsiveContainer width="99%" height={600}>
                  <AreaChart
                    data={Object.values(data)}
                    margin={{ top: 0, right: 16, left: 0, bottom: 32 }}
                  >
                    <XAxis dataKey="Hour" interval={0}>
                      <Label value="Hour" offset={36} position="bottom" />
                    </XAxis>
                    <YAxis>
                      <Label
                        value="Count"
                        angle={-90}
                        position="left"
                        offset={-24}
                      />
                    </YAxis>
                    {Object.keys(data).length > 1 && (
                      <ChartTooltip
                        cursor={false}
                        content={
                          <CustomTooltip
                            unit="vehicles"
                            height={400}
                            captureMouseMove
                          />
                        }
                        wrapperStyle={{ pointerEvents: 'auto' }}
                      />
                    )}
                    {Object.keys(data).length > 1 && (
                      <Brush dataKey="Hour" height={24} />
                    )}
                    {Object.keys(data).length > 1 &&
                      Object.keys(data[0])
                        .filter((key) => key !== 'Hour')
                        .reverse()
                        .map((locationName, index) => {
                          const count = Object.keys(data[0]).length - 1;
                          const colour = randomHsl(index, count);
                          return (
                            <Area
                              key={locationName}
                              type="monotone"
                              stackId="1"
                              dataKey={locationName}
                              hide={hiddenLines.includes(locationName)}
                              stroke={colour}
                              fill={colour}
                            />
                          );
                        })}
                  </AreaChart>
                </ResponsiveContainer>
              </CardContent>
            </Paper>
            <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
              <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
                Hourly in Location
              </Typography>
            </Toolbar>
            <Paper sx={{ m: [0, 1, 1], minWidth: 240, fontSize: 12 }}>
              <TableContainer sx={{ overflowX: 'auto' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Location</TableCell>
                      {getTableHeaders().map((h) => (
                        <TableCell key={h}>{h}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {uniqueLocations.map((key) => (
                      <TableRow key={key}>
                        <TableCell component="th" scope="row">
                          {key}
                        </TableCell>
                        {getTableHeaders().map((header, index) => (
                          <TableCell key={index}>
                            {tableData[header][key]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Fragment>
        )}
      </Box>
    </Box>
  );
}

import {
  LocationOn as LocationOnIcon,
  KeyboardDoubleArrowUp as KeyboardDoubleArrowUpIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import defaultPersonImage from '@/img/defaultPhotoImage.jpeg';
import { useOccurrenceOic, useOfficer } from '@/hooks';
import { priorities } from '@/utils/constants';
import { getOfficerTeam } from '@/utils';

export function MissingPerson({ missingPerson, onShowLocationClick }) {
  const { data: oicAndOel } = useOccurrenceOic(missingPerson.occurrence.number);
  const { data: officer } = useOfficer(oicAndOel?.oic);

  return (
    <Card variant="outlined" sx={{ minWidth: 240 }}>
      <CardHeader
        avatar={<RiskAvatar risk={missingPerson?.person?.riskLevel} />}
        action={
          <Tooltip title="Missing From">
            <IconButton onClick={onShowLocationClick}>
              <LocationOnIcon />
            </IconButton>
          </Tooltip>
        }
        title={missingPerson.person.name}
        /* subheader={missingPerson.person.dateOfBirth} */
      />

      {missingPerson.person.image ? (
        <CardMedia
          component="img"
          // height="140"
          //sx={{ padding: '1em 1em 0 1em' }}
          image={missingPerson.person.image}
          alt={missingPerson.person.firstName}
        />
      ) : (
        <CardMedia
          component="img"
          sx={{ maxWidth: 240 }}
          src={defaultPersonImage}
        />
      )}
      <CardContent>
        <Stack direction="column">
          <Typography variant="h6">
            {missingPerson.person.firstName} {missingPerson.person.surname}
          </Typography>
          <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
            {missingPerson.person.dateOfBirth}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          sx={{ mb: 1 }}
        >
          <Tooltip title="Occurrence Number" placement="left-start">
            <Typography
              variant="body3"
              color="text.secondary"
              gutterBottom
              sx={{ flexGrow: 1 }}
            >
              {missingPerson.occurrence?.number}
            </Typography>
          </Tooltip>
          {oicAndOel && oicAndOel.oic && (
            <Tooltip
              title={getOfficerTeam(officer, oicAndOel?.owningTeam)}
              placement="left-start"
            >
              <Stack direction="row">
                <KeyboardDoubleArrowUpIcon fontSize="small" />
                <Typography
                  variant="caption"
                  color="text.secondary"
                  gutterBottom
                >
                  {oicAndOel?.oic}
                </Typography>
              </Stack>
            </Tooltip>
          )}
        </Stack>
        <Stack direction="column">
          <Tooltip title="Reported missing" placement="left-start">
            <Typography
              variant="caption"
              color="text.secondary"
              gutterBottom
              sx={{ flexGrow: 1 }}
            >
              Reported: {missingPerson.occurrence?.reportedTime}
            </Typography>
          </Tooltip>
          <Tooltip title="Return interview completed" placement="left-start">
            <Typography
              variant="caption"
              color="text.secondary"
              gutterBottom
              sx={{ flexGrow: 1 }}
            >
              Return interview completed:{' '}
              {missingPerson.person?.returnInterviewCompleted}
            </Typography>
          </Tooltip>
          <Tooltip
            title="Supervisor questions set completed"
            placement="left-start"
          >
            <Typography
              variant="caption"
              color="text.secondary"
              gutterBottom
              sx={{ flexGrow: 1 }}
            >
              Supervisor questions completed:{' '}
              {missingPerson.person?.returnInterviewCompleted}
            </Typography>
          </Tooltip>
          <Tooltip title="Last OEL entry" placement="left-start">
            <Typography
              variant="caption"
              color="text.secondary"
              gutterBottom
              sx={{ flexGrow: 1 }}
            >
              Last OEL entry:{' '}
              {oicAndOel?.lastOelEntry
                ? format(
                    new Date(oicAndOel?.lastOelEntry),
                    'dd-MM-yyyy HH:mm:ss',
                  )
                : ''}
            </Typography>
          </Tooltip>
          <Tooltip title="Next review is due" placement="left-start">
            <Typography
              variant="caption"
              color="text.secondary"
              gutterBottom
              sx={{ flexGrow: 1 }}
            >
              Next review: {missingPerson.person?.nextReview}
            </Typography>
          </Tooltip>
        </Stack>
        {missingPerson.person.riskLevel === 'Hi' && (
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Tooltip title="Sector" placement="left-start">
              <Typography
                variant="caption"
                color="text.secondary"
                gutterBottom
                sx={{ flexGrow: 1 }}
              >
                {missingPerson.occurrence?.sector}
              </Typography>
            </Tooltip>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}

function getRiskLevel(risk) {
  switch (risk) {
    case 'Hi':
      return 1;
    case 'Med':
      return 2;
    case 'Low':
      return 3;
    default:
      return 4;
  }
}

function RiskAvatar({ risk }) {
  const riskLevel = getRiskLevel(risk);
  const { label, color, Icon } = priorities[riskLevel];

  return (
    <Tooltip title={`${label} Risk`}>
      <Avatar
        sx={{
          bgcolor: color,
          color: (theme) => theme.palette.getContrastText(color),
        }}
      >
        <Icon />
      </Avatar>
    </Tooltip>
  );
}

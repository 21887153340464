import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';

export function DatePicker({
  value,
  minDate,
  maxDate,
  error,
  helperText,
  className,
  sx,
  ...props
}) {
  return (
    <MuiDatePicker
      {...props}
      value={value ? new Date(value) : null}
      minDate={minDate ? new Date(minDate) : null}
      maxDate={maxDate ? new Date(maxDate) : null}
      className={className}
      sx={sx}
      views={['year', 'month', 'day']}
      slotProps={{ textField: { size: 'small', error, helperText } }}
    />
  );
}

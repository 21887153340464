import {
  FETCH_VEHICLE_DAILY_UTILISATION,
  FETCH_VEHICLE_DAILY_UTILISATION_CANCELLED,
  FILTER_VEHICLE_DAILY_UTILISATION,
  UPDATE_VEHICLE_DAILY_UTILISATION_PARAMETERS,
} from '@/actions';
import {
  CustomTooltip,
  Parameters,
  SelectMultiple,
  Table,
  TablePagination,
  ToggleList,
} from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import {
  downloadCSV,
  getFilenameForDownload,
  getKeyLabel,
  getTextWidth,
} from '@/utils';
import {
  homeOtherSplit,
  rowsPerPageOptions,
  vehicleGroups,
} from '@/utils/config';
import {
  BarChart as BarChartIcon,
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
  Sort as SortIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  CardContent,
  Collapse,
  FormControl,
  FormControlLabel,
  IconButton,
  LinearProgress,
  ListSubheader,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  green,
  indigo,
  lightBlue,
  orange,
  purple,
  red,
  teal,
} from '@mui/material/colors';
import { format } from 'date-fns';
import { dequal } from 'dequal';
import { enqueueSnackbar } from 'notistack';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Bar,
  BarChart,
  Brush,
  Tooltip as ChartTooltip,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

const hours = [...Array(24).keys()];
const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

const commonLabels = [
  ['unaccountable', 'Unaccounted'],
  ['totalMileage', 'Total Mileage'],
  ['averageMileage', 'Average Mileage'],
  ['dailyMileage', 'Daily Mileage'],
  ['totalTrips', 'Total Trips'],
  ['averageTrips', 'Average Trips'],
  ['dailyTrips', 'Daily Trips'],
  ['hours', 'Hours'],
  ['percentage', 'Percentage'],
  ['activity', 'Activity'],
  ['status', 'Status'],
];

const getClassificationSettings = (unit) => {
  return {
    activity: {
      bars: [
        ...(homeOtherSplit
          ? [
              { name: 'stoppedHomeBase', colour: red[800] },
              { name: 'stoppedOtherBase', colour: red[500] },
              { name: 'idleHomeBase', colour: purple[700] },
              { name: 'idleOtherBase', colour: purple[300] },
            ]
          : [
              { name: 'stoppedBase', colour: red[800] },
              { name: 'idleBase', colour: red[500] },
            ]),
        { name: 'stoppedWorkshop', colour: orange[800] },
        { name: 'idleWorkshop', colour: orange[500] },
        { name: 'stoppedElsewhere', colour: teal[800] },
        { name: 'idleElsewhere', colour: teal[500] },
        { name: 'moving', colour: green[500] },
        { name: 'unaccountable', colour: lightBlue[400] },
      ],
      labels: new Map([
        ...(homeOtherSplit
          ? [
              ['stoppedHomeBase', 'Stopped @ Home Base'],
              ['stoppedOtherBase', 'Stopped @ Other Base'],
              ['idleHomeBase', 'Idle @ Home Base'],
              ['idleOtherBase', 'Idle @ Other Base'],
            ]
          : [
              ['stoppedBase', 'Stopped @ Base'],
              ['idleBase', 'Idle @ Base'],
            ]),
        ['stoppedWorkshop', 'Stopped @ Workshop'],
        ['idleWorkshop', 'Idle @ Workshop'],
        ['stoppedElsewhere', 'Stopped Elsewhere'],
        ['idleElsewhere', 'Idle Elsewhere'],
        ['moving', 'Moving'],
        ...commonLabels,
      ]),
      classificationHeaders: [
        {
          label: `Moving (${unit})`,
          key: 'moving',
          type: 'number',
        },
        ...(homeOtherSplit
          ? [
              {
                label: `Stopped @ Home Base (${unit})`,
                key: 'stoppedHomeBase',
                type: 'number',
              },
              {
                label: `Stopped @ Other Base (${unit})`,
                key: 'stoppedOtherBase',
                type: 'number',
              },
            ]
          : [
              {
                label: `Stopped @ Base (${unit})`,
                key: 'stoppedBase',
                type: 'number',
              },
            ]),
        {
          label: `Stopped @ Workshop (${unit})`,
          key: 'stoppedWorkshop',
          type: 'number',
        },
        {
          label: `Stopped Elsewhere (${unit})`,
          key: 'stoppedElsewhere',
          type: 'number',
        },
        ...(homeOtherSplit
          ? [
              {
                label: `Idle @ Home Base (${unit})`,
                key: 'idleHomeBase',
                type: 'number',
              },
              {
                label: `Idle @ Other Base (${unit})`,
                key: 'idleOtherBase',
                type: 'number',
              },
            ]
          : [
              {
                label: `Idle @ Base (${unit})`,
                key: 'idleBase',
                type: 'number',
              },
            ]),
        {
          label: `Idle @ Workshop (${unit})`,
          key: 'idleWorkshop',
          type: 'number',
        },
        {
          label: `Idle Elsewhere (${unit})`,
          key: 'idleElsewhere',
          type: 'number',
        },
        {
          label: `Unaccounted (${unit})`,
          key: 'unaccountable',
          type: 'number',
        },
      ],
      orderByValues: [
        ...(homeOtherSplit
          ? [
              'stoppedHomeBase',
              'stoppedOtherBase',
              'idleHomeBase',
              'idleOtherBase',
            ]
          : ['stoppedBase', 'idleBase']),
        'stoppedWorkshop',
        'idleWorkshop',
        'stoppedElsewhere',
        'idleElsewhere',
        'moving',
        'unaccountable',
      ],
    },
    status: {
      bars: [
        { name: 'unused', colour: red[800] },
        { name: 'unavailable', colour: orange[800] },
        { name: 'used', colour: green[500] },
        { name: 'unaccountable', colour: lightBlue[400] },
      ],
      labels: new Map([
        ['unused', 'Unused'],
        ['unavailable', 'Unavailable'],
        ['used', 'Used'],
        ...commonLabels,
      ]),
      classificationHeaders: [
        {
          label: `Unused (${unit})`,
          key: 'unused',
          type: 'number',
        },
        {
          label: `Unavailable (${unit})`,
          key: 'unavailable',
          type: 'number',
        },
        {
          label: `Used (${unit})`,
          key: 'used',
          type: 'number',
        },
        {
          label: `Unaccounted (${unit})`,
          key: 'unaccountable',
          type: 'number',
        },
      ],
      orderByValues: ['unused', 'unavailable', 'used', 'unaccountable'],
    },
  };
};

const measureTypes = ['total', 'average', 'daily'];
const chartTypes = ['hours', 'percentage'];

export function DailyUtilisation() {
  useDocumentTitle('IR3 | Daily Utilisation');
  const dispatch = useDispatch();
  const data = useSelector((state) => state.utilisation.daily.data, dequal);
  const {
    groupBy,
    orderBy,
    order,
    filter,
    classifyBy,
    chartType,
    query,
    parameters,
  } = useSelector((state) => state.utilisation.daily);
  const isLoading = useSelector((state) => state.utilisation.daily.isLoading);
  const isFiltering = useSelector(
    (state) => state.utilisation.daily.isFiltering,
  );
  const error = useSelector((state) => state.utilisation.daily.error);
  const groupByValues = useSelector(
    (state) => state.utilisation.daily.groupByValues,
    dequal,
  );
  const filterValues = useSelector(
    (state) => state.utilisation.daily.filterValues,
    dequal,
  );
  const [showFilter, setShowFilter] = useState(false);
  const [hiddenBars, setHiddenBars] = useState([]);
  const [sortMenuAnchor, setSortMenuAnchor] = useState(null);
  const [viewMenuAnchor, setViewMenuAnchor] = useState(null);
  const [mileageType, setMileageType] = useState('totalMileage');
  const [tripCountType, setTripCountType] = useState('totalTrips');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const unit = chartType === 'percentage' ? '%' : 'HPD';
  const classificationSettings = getClassificationSettings(unit);
  const { bars, labels, classificationHeaders, orderByValues } =
    classificationSettings[classifyBy];

  function formatGroup(value) {
    switch (groupBy) {
      case 'date':
        return format(value, 'dd/MM/yyyy');
      case 'month':
        return format(value, 'MM/yyyy');
      default:
        return value;
    }
  }

  const headers = [
    {
      label: `${getKeyLabel(groupBy)}`,
      key: 'group',
      type: groupBy === 'date' ? 'date' : 'text',
      format: formatGroup,
    },
    {
      label: 'Vehicle Count',
      key: 'count',
      type: 'number',
    },
    ...classificationHeaders,
    {
      label: 'Total Mileage',
      key: 'totalMileage',
      type: 'number',
    },
    {
      label: 'Average Mileage',
      key: 'averageMileage',
      type: 'number',
    },
    {
      label: 'Daily Mileage',
      key: 'dailyMileage',
      type: 'number',
    },
    {
      label: 'Total Trips',
      key: 'totalTrips',
      type: 'number',
    },
    {
      label: 'Average Trips',
      key: 'averageTrips',
      type: 'number',
    },
    {
      label: 'Daily Trips',
      key: 'dailyTrips',
      type: 'number',
    },
  ];

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error]);

  function handleFilterToggle() {
    setShowFilter(!showFilter);
  }

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_VEHICLE_DAILY_UTILISATION,
      payload: {
        query,
        filter,
        groupBy,
        orderBy,
        order,
        classifyBy,
        chartType,
      },
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_VEHICLE_DAILY_UTILISATION_CANCELLED,
    });
  }

  const handleGroupByFieldChanged = (groupBy) => () => {
    updateTransformOptions({ groupBy });
    setViewMenuAnchor(null);
  };

  const handlePercentOrHoursClick = (value) => () => {
    setViewMenuAnchor(null);
    updateTransformOptions({ chartType: value });
  };

  const handleClassificationClick = (value) => () => {
    setViewMenuAnchor(null);
    updateTransformOptions({ classifyBy: value });
  };

  const handleFilterFieldChanged = (name) => (values) => {
    const newFilter =
      name in filter
        ? {
            ...filter,
            [name]: values || [],
          }
        : {
            ...filter,
            groups: {
              ...filter.groups,
              [name]: values || [],
            },
          };

    updateTransformOptions({ filter: newFilter });
  };

  function handleMileageTypeFieldChanged(event) {
    setMileageType(event.target.value);
  }

  function handleTripCountTypeFieldChanged(event) {
    setTripCountType(event.target.value);
  }

  const handleLegendClick = (selectedBar) => () => {
    const index = hiddenBars.indexOf(selectedBar);

    if (index === -1) {
      setHiddenBars(hiddenBars.concat(selectedBar));
    } else {
      setHiddenBars(
        hiddenBars.slice(0, index).concat(hiddenBars.slice(index + 1)),
      );
    }
  };

  function handleViewMenuOpen(event) {
    setViewMenuAnchor(event.target);
  }

  function handleViewMenuClose() {
    setViewMenuAnchor(null);
  }

  function handleSortMenuOpen(event) {
    setSortMenuAnchor(event.target);
  }

  function handleSortMenuClose() {
    setSortMenuAnchor(null);
  }

  function updateTransformOptions(optionChanges) {
    dispatch({
      type: FILTER_VEHICLE_DAILY_UTILISATION,
      payload: {
        query,
        filter,
        orderBy,
        order,
        groupBy,
        classifyBy,
        chartType,
        ...optionChanges,
      },
    });
  }

  const handleSortItemClick = (newOrderBy) => () => {
    // tell the epic how does it feel about changing the sort order
    let newOrder = order;
    if (newOrderBy === orderBy) {
      newOrder = newOrder === 'asc' ? 'desc' : 'asc';
    }

    updateTransformOptions({ orderBy: newOrderBy, order: newOrder });
    setSortMenuAnchor(null);
  };

  function handlePageChange(event, newPage) {
    setPage(newPage);
  }

  function handleRowsPerPageChange(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleOrderChange(order) {
    updateTransformOptions({ order });
  }

  function handleOrderByChange(orderBy) {
    updateTransformOptions({ orderBy, order: 'asc' });
  }

  function handleParametersChange(parameters) {
    dispatch({
      type: UPDATE_VEHICLE_DAILY_UTILISATION_PARAMETERS,
      payload: parameters,
    });
  }

  async function handleDownloadClick() {
    const filename = getFilenameForDownload(
      'Vehicle Daily Utilisation',
      'csv',
      parameters?.startTime,
      parameters?.endTime,
    );

    downloadCSV(
      data.map(({ group, ...record }) => ({
        group: formatGroup(group),
        ...record,
      })),
      filename,
      headers,
    );
  }

  const xAxisHeight =
    Math.max(
      ...data.map((item) =>
        getTextWidth(formatGroup(item.group), '12px Roboto'),
      ),
    ) + 16;

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 48px)',
        overflow: 'hidden',
        backgroundColor: 'background.default',
      }}
    >
      {isFiltering && (
        <LinearProgress
          sx={{ position: 'absolute', width: 1, height: 8 }}
          color="secondary"
        />
      )}
      <Parameters
        collection="vehicleDailySummaries"
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading && !isFiltering}
        value={parameters}
        onChange={handleParametersChange}
        sx={{ mt: 1, width: 264 }}
        pointEvent
        dateOnly
        vehicle
      />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
          '& > :last-child': {
            mb: 1,
          },
        }}
      >
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography variant="subtitle1">Daily Utilisation</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title={showFilter ? 'Hide filter' : 'Show filter'}>
            <IconButton onClick={handleFilterToggle}>
              <FilterListIcon color={showFilter ? 'primary' : 'inherit'} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Change view">
            <IconButton
              aria-owns={viewMenuAnchor ? 'date-menu' : undefined}
              aria-haspopup="true"
              onClick={handleViewMenuOpen}
            >
              <BarChartIcon />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={viewMenuAnchor}
            open={Boolean(viewMenuAnchor)}
            onClose={handleViewMenuClose}
          >
            <ListSubheader disableSticky>Group by</ListSubheader>
            {Object.values(groupByValues).map((value) => (
              <MenuItem
                key={value}
                value={value}
                selected={value === groupBy}
                onClick={handleGroupByFieldChanged(value)}
              >
                {getKeyLabel(value)}
              </MenuItem>
            ))}
            <ListSubheader disableSticky>View as</ListSubheader>
            {chartTypes.map((value) => (
              <MenuItem
                key={value}
                value={value}
                selected={value === chartType}
                onClick={handlePercentOrHoursClick(value)}
              >
                {labels.get(value)}
              </MenuItem>
            ))}
            <ListSubheader disableSticky>Classify by</ListSubheader>
            {Object.keys(classificationSettings).map((value) => (
              <MenuItem
                key={value}
                value={value}
                selected={value === classifyBy}
                onClick={handleClassificationClick(value)}
              >
                {labels.get(value)}
              </MenuItem>
            ))}
          </Menu>
          <Tooltip title="Sort by">
            <IconButton
              aria-owns={sortMenuAnchor ? 'date-menu' : undefined}
              aria-haspopup="true"
              onClick={handleSortMenuOpen}
            >
              <SortIcon />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={sortMenuAnchor}
            open={Boolean(sortMenuAnchor)}
            onClose={handleSortMenuClose}
          >
            <ListSubheader disableSticky>Sort by</ListSubheader>
            {groupBy !== 'all' ? (
              <MenuItem
                onClick={handleSortItemClick('group')}
                selected={'group' === orderBy}
              >
                {getKeyLabel(groupBy)}
              </MenuItem>
            ) : (
              []
            )}
            {orderByValues.concat([mileageType, tripCountType]).map((value) => (
              <MenuItem
                key={value}
                onClick={handleSortItemClick(value)}
                selected={value === orderBy}
              >
                {labels.get(value)}
              </MenuItem>
            ))}
          </Menu>
          <Tooltip title="Download data">
            <Box component="span">
              <IconButton
                disabled={data.length === 0}
                onClick={handleDownloadClick}
              >
                <GetAppIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </Toolbar>
        <Collapse in={showFilter} timeout="auto">
          <Stack spacing={1} sx={{ flex: 1, p: 1 }}>
            <SelectMultiple
              disabled={isFiltering}
              label="Registration Number"
              placeholder="Select..."
              value={filter.registrationNumber}
              labelValue
              onChange={handleFilterFieldChanged('registrationNumber')}
              suggestions={filterValues.registrationNumber.map((value) => ({
                label: value,
                value,
              }))}
            />
            <SelectMultiple
              disabled={isFiltering}
              label="Fleet Number"
              placeholder="Select..."
              value={filter.fleetNumber}
              labelValue
              onChange={handleFilterFieldChanged('fleetNumber')}
              suggestions={filterValues.fleetNumber.map((value) => ({
                label: value,
                value,
              }))}
            />
            <SelectMultiple
              disabled={isFiltering}
              label="Role"
              placeholder="Select..."
              value={filter.role}
              labelValue
              onChange={handleFilterFieldChanged('role')}
              suggestions={filterValues.role.map((value) => ({
                label: value,
                value,
              }))}
            />
            {Object.entries(vehicleGroups).map(([key, { label, values }]) => {
              return (
                <SelectMultiple
                  disabled={isFiltering}
                  key={key}
                  label={label}
                  placeholder="Select..."
                  value={filter.groups[key] || []}
                  labelValue
                  onChange={handleFilterFieldChanged(key)}
                  suggestions={values}
                />
              );
            })}
            <ToggleList
              disabled={isFiltering}
              value={filter.hour}
              onChange={handleFilterFieldChanged('hour')}
              values={hours}
              label={'Hours'}
            />
            <ToggleList
              disabled={isFiltering}
              value={filter.day}
              onChange={handleFilterFieldChanged('day')}
              values={days}
              label={'Days'}
            />
          </Stack>
        </Collapse>
        {data.length > 0 && (
          <Fragment>
            <Paper sx={{ m: [0, 1], minWidth: 240, fontSize: 12 }}>
              <CardContent sx={{ p: 0, pt: 4 }}>
                <Box
                  sx={{
                    pl: 8,
                    pr: 2,
                    pb: 1,
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                  }}
                >
                  {bars.map((bar) => (
                    <Box
                      key={bar.name}
                      sx={{
                        p: 0.5,
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={handleLegendClick(bar.name)}
                    >
                      <Avatar
                        sx={{
                          width: 12,
                          height: 12,
                          mr: 0.5,
                          bgcolor: !hiddenBars.includes(bar.name) && bar.colour,
                        }}
                      >
                        <Fragment />
                      </Avatar>
                      <Typography variant="caption">
                        {labels.get(bar.name)}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <ResponsiveContainer width="99%" height={520}>
                  <BarChart
                    data={data}
                    margin={{ top: 0, right: 16, left: 0, bottom: 32 }}
                    barCategoryGap={4}
                  >
                    <XAxis
                      dataKey="group"
                      tickFormatter={formatGroup}
                      angle={-90}
                      textAnchor="end"
                      interval={0}
                      height={xAxisHeight}
                    >
                      <Label
                        value={getKeyLabel(groupBy)}
                        offset={36}
                        position="bottom"
                      />
                    </XAxis>
                    <YAxis>
                      <Label
                        value={
                          chartType === 'percentage' ? 'Percentage' : 'Hours'
                        }
                        angle={-90}
                        position="left"
                        offset={-24}
                      />
                    </YAxis>
                    <ChartTooltip
                      cursor={false}
                      content={
                        <CustomTooltip
                          unit={unit}
                          labelFormatter={formatGroup}
                        />
                      }
                    />
                    {data.length > 0 && <Brush dataKey="group" height={24} />}
                    {bars.map((bar) => (
                      <Bar
                        key={bar.name}
                        dataKey={bar.name}
                        name={labels.get(bar.name)}
                        stackId="a"
                        fill={bar.colour}
                        hide={hiddenBars.includes(bar.name)}
                      />
                    ))}
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Paper>
            <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="mileage chart type"
                  name="mileage chart type"
                  value={mileageType}
                  onChange={handleMileageTypeFieldChanged}
                >
                  {measureTypes.map((measureType) => (
                    <FormControlLabel
                      key={measureType}
                      value={`${measureType}Mileage`}
                      control={<Radio />}
                      label={labels.get(`${measureType}Mileage`)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Toolbar>
            <Paper sx={{ m: [0, 1], minWidth: 240, fontSize: 12 }}>
              <CardContent sx={{ p: 0, pt: 4 }}>
                <ResponsiveContainer width="99%" height={520}>
                  <BarChart
                    data={data}
                    margin={{ top: 32, right: 16, left: 32, bottom: 32 }}
                    barCategoryGap={4}
                  >
                    <XAxis
                      dataKey="group"
                      tickFormatter={formatGroup}
                      angle={-90}
                      textAnchor="end"
                      interval={0}
                      height={xAxisHeight}
                    >
                      <Label
                        value={getKeyLabel(groupBy)}
                        offset={36}
                        position="bottom"
                      />
                    </XAxis>
                    <YAxis>
                      <Label
                        value="Miles"
                        angle={-90}
                        position="left"
                        offset={8}
                      />
                    </YAxis>
                    <ChartTooltip
                      cursor={false}
                      content={<CustomTooltip labelFormatter={formatGroup} />}
                    />
                    {data.length > 0 && <Brush dataKey="group" height={24} />}
                    {measureTypes.map((measureType) => (
                      <Bar
                        key={measureType}
                        dataKey={`${measureType}Mileage`}
                        name={labels.get(`${measureType}Mileage`)}
                        fill={indigo[800]}
                        hide={mileageType !== `${measureType}Mileage`}
                      />
                    ))}
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Paper>
            <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="trip chart type"
                  name="trip chart type"
                  value={tripCountType}
                  onChange={handleTripCountTypeFieldChanged}
                >
                  {measureTypes.map((measureType) => (
                    <FormControlLabel
                      key={measureType}
                      value={`${measureType}Trips`}
                      control={<Radio />}
                      label={labels.get(`${measureType}Trips`)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Toolbar>
            <Paper sx={{ m: [0, 1], minWidth: 240, fontSize: 12 }}>
              <CardContent sx={{ p: 0, pt: 4 }}>
                <ResponsiveContainer width="99%" height={520}>
                  <BarChart
                    data={data}
                    margin={{ top: 32, right: 16, left: 32, bottom: 32 }}
                    barCategoryGap={4}
                  >
                    <XAxis
                      dataKey="group"
                      tickFormatter={formatGroup}
                      angle={-90}
                      textAnchor="end"
                      interval={0}
                      height={xAxisHeight}
                    >
                      <Label
                        value={getKeyLabel(groupBy)}
                        offset={36}
                        position="bottom"
                      />
                    </XAxis>
                    <YAxis>
                      <Label
                        value="Trips"
                        angle={-90}
                        position="left"
                        offset={8}
                      />
                    </YAxis>
                    <ChartTooltip
                      cursor={false}
                      content={<CustomTooltip labelFormatter={formatGroup} />}
                    />
                    {data.length > 0 && <Brush dataKey="group" height={24} />}
                    {measureTypes.map((measureType) => (
                      <Bar
                        key={measureType}
                        dataKey={`${measureType}Trips`}
                        name={labels.get(`${measureType}Trips`)}
                        fill={indigo[800]}
                        hide={tripCountType !== `${measureType}Trips`}
                      />
                    ))}
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Paper>
            <Toolbar
              variant="dense"
              disableGutters
              sx={{ justifyContent: 'space-between', p: 1, pb: 0 }}
            >
              <Typography variant="subtitle1">Daily Utilisation</Typography>
              <Typography variant="subtitle2">(HPD) = hours per day</Typography>
            </Toolbar>
            <Paper sx={{ m: [0, 1], minWidth: 240, fontSize: 12 }}>
              <Table
                data={data}
                headers={headers}
                rowsPerPage={rowsPerPage}
                page={page}
                dense={true}
                order={order}
                orderBy={orderBy}
                onOrderChange={handleOrderChange}
                onOrderByChange={handleOrderByChange}
              />
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </Paper>
          </Fragment>
        )}
      </Box>
    </Box>
  );
}

import { acceptedAtom, sideNavOpenAtom } from '@/atoms';
import { useAuth } from '@/hooks';
import ir3Logo from '@/svg/white-logo.svg';
import { log } from '@/utils';
import { autoLogin, maintenance, promptText } from '@/utils/config';
import { Menu as MenuIcon } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Snackbar as MuiSnackbar,
  Toolbar,
  Typography,
} from '@mui/material';
import { useAtom } from 'jotai';
import { Fragment, useEffect } from 'react';
import { Outlet, useRoutes, useSearchParams } from 'react-router-dom';
import { Maintenance } from './Maintenance';
import { SideNav } from './SideNav';
import { LoginAvatar } from './controls';
import { titleRoutes, toolbarRoutes } from '@/routing';

export function App() {
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const [isSideNavOpen, setIsSideNavOpen] = useAtom(sideNavOpenAtom);
  const [accepted, setAccepted] = useAtom(acceptedAtom);
  const title = useRoutes(titleRoutes);
  const toolbar = useRoutes(toolbarRoutes);

  function toggleSideNav() {
    setIsSideNavOpen((value) => !value);
  }

  useEffect(() => {
    if (searchParams.has('error')) {
      return;
    } else if (searchParams.has('code')) {
      auth.handleAuthentication();
    } else {
      if (autoLogin && !auth.isAuthenticated()) {
        auth.login();
      }
    }
  }, [auth, searchParams]);

  function handleAccept() {
    localStorage.setItem('accepted', true);
    setAccepted(true);

    log('Accept', 'Prompt');
  }

  if (maintenance) {
    return <Maintenance />;
  }

  return (
    <Fragment>
      {title && ( // get rid of this when live and retrospective are handled properly
        <Fragment>
          <AppBar
            position="fixed"
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              borderBottom: 1,
              borderBottomStyle: 'solid',
              borderBottomColor: (theme) => theme.palette.divider,
            }}
          >
            <Toolbar variant="dense">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 1 }}
                onClick={toggleSideNav}
              >
                <MenuIcon />
              </IconButton>
              <img src={ir3Logo} alt="IR3 Logo" style={{ height: 24 }} />
              <Typography variant="h6" sx={{ pl: 0.5, fontWeight: 'bold' }}>
                IR3
              </Typography>
              <Typography variant="h6" sx={{ px: 0.5 }}>
                |
              </Typography>
              {title}
              <LoginAvatar />
            </Toolbar>
            {toolbar}
          </AppBar>
          <Toolbar variant="dense" />
        </Fragment>
      )}
      {toolbar && <Toolbar variant="dense" />}
      <SideNav isOpen={isSideNavOpen} onClose={toggleSideNav} />
      <Outlet />
      {auth.isAuthenticated() && promptText && (
        <MuiSnackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={!accepted}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={
            <Box component="span" id="message-id">
              {promptText}
            </Box>
          }
          action={
            <Button color="secondary" size="small" onClick={handleAccept}>
              ACCEPT
            </Button>
          }
        />
      )}
    </Fragment>
  );
}

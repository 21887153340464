import { FETCH_RETROSPECTIVES } from '@/actions';
import { SearchBox } from '@/components/controls';
import { ConfirmationDialog } from '@/components/dialogs';
import { get, getComparator, stableSort } from '@/utils';
import {
  ArrowUpward as ArrowUpwardIcon,
  Delete as DeleteIcon,
  History as HistoryIcon,
  Sort as SortIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { format } from 'date-fns';
import { dequal } from 'dequal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const sortOptions = [
  { label: 'Title', value: 'title' },
  { label: 'Last Edit', value: 'lastEdit.time' },
  { label: 'Created', value: 'created.time' },
];

export function OpenDialog({ open, onClose, onDelete }) {
  const dispatch = useDispatch();
  const retrospectives = useSelector(
    (state) => state.retrospectives.retrospectives,
    dequal,
  );
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState('');
  const [selectedIdentifier, setSelectedIdentifier] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('title');
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    dispatch({
      type: FETCH_RETROSPECTIVES,
    });
  }, [dispatch]);

  function handleClose() {
    onClose();
  }

  function handleOpenClick() {
    onClose(selectedIdentifier);
  }

  function handleListItemClick(event) {
    setSelectedIdentifier(event.currentTarget.dataset.identifier);
  }

  function handleListItemDoubleClick(event) {
    onClose(event.currentTarget.dataset.identifier);
  }

  function handleFilterTextChange(event) {
    setFilterText(event.target.value);
  }

  function handleDeleteClick(event) {
    const title = retrospectives.find(
      (retrospective) =>
        retrospective.identifier === event.currentTarget.dataset.identifier,
    ).title;

    setSelectedIdentifier(event.currentTarget.dataset.identifier);
    setSelectedTitle(title);
    setDeleteOpen(true);
  }

  function handleDelete() {
    onDelete(selectedIdentifier);

    setSelectedIdentifier(null);
    setSelectedTitle('');
    setDeleteOpen(false);
  }

  function handleSortClick(event) {
    setAnchorEl(event.currentTarget);
  }

  const handleSortMenuItemClick = (newOrderBy) => () => {
    if (newOrderBy === orderBy) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(newOrderBy);
      setOrder('asc');
    }
  };

  function handleSortClose() {
    setAnchorEl(null);
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <SearchBox
        value={filterText}
        onChange={handleFilterTextChange}
        sx={{ width: 300, p: 1 }}
      />
      <DialogContent sx={{ p: 0 }}>
        <List disablePadding>
          {stableSort(
            retrospectives.filter((retrospective) =>
              retrospective.title.toLowerCase().includes(filterText),
            ),
            getComparator(order, orderBy),
          ).map((retrospective) => (
            <ListItemButton
              data-identifier={retrospective.identifier}
              onClick={handleListItemClick}
              onDoubleClick={handleListItemDoubleClick}
              selected={selectedIdentifier === retrospective.identifier}
              key={retrospective.identifier}
            >
              <ListItemAvatar>
                <Avatar>
                  <HistoryIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={retrospective.title}
                secondary={
                  orderBy !== 'title' &&
                  get(retrospective, orderBy) &&
                  format(
                    new Date(
                      get(retrospective, orderBy, retrospective.created.time),
                    ),
                    'dd/MM/yyyy HH:mm',
                  )
                }
              />
              <ListItemSecondaryAction>
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="Delete"
                    data-identifier={retrospective.identifier}
                    onClick={handleDeleteClick}
                    color="error"
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItemButton>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Tooltip title="Sort">
          <IconButton aria-label="Sort" size="small" onClick={handleSortClick}>
            <SortIcon />
          </IconButton>
        </Tooltip>

        <Menu
          id="sort-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleSortClose}
        >
          {sortOptions.map((option) => (
            <MenuItem
              key={option.value}
              onClick={handleSortMenuItemClick(option.value)}
            >
              {option.label}
              {orderBy === option.value && (
                <ListItemSecondaryAction>
                  <ArrowUpwardIcon
                    color="action"
                    fontSize="small"
                    sx={(theme) => ({
                      transform:
                        order === 'desc' ? 'rotate(180deg)' : 'rotate(0deg)',
                      // marginLeft: 'auto',
                      transition: theme.transitions.create('transform', {
                        duration: theme.transitions.duration.shortest,
                      }),
                    })}
                  />
                </ListItemSecondaryAction>
              )}
            </MenuItem>
          ))}
        </Menu>
        <div style={{ flex: 1 }} />
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleOpenClick}
          color="primary"
          disabled={!selectedIdentifier}
        >
          Open
        </Button>
      </DialogActions>
      <ConfirmationDialog
        action="Delete"
        open={deleteOpen}
        itemId={selectedTitle}
        onOk={handleDelete}
        onCancel={() => setDeleteOpen(false)}
      />
    </Dialog>
  );
}

import { MapButton } from '@/components/controls/MapButton';
import { Layers as LayersIcon } from '@mui/icons-material';
import { Box, Menu } from '@mui/material';
import {
  Group as LayerGroup,
  Image as ImageLayer,
  Tile as TileLayer,
} from 'ol/layer';
import { useState } from 'react';
import { MapLayersList } from './MapLayerList';

function getMapLayers(mapRef) {
  return (
    mapRef.current
      ?.getLayers()
      ?.getArray()
      ?.filter(
        (layer) =>
          layer instanceof TileLayer ||
          layer instanceof ImageLayer ||
          layer instanceof LayerGroup,
      )
      ?.filter((layer) => layer.get('title')) ?? []
  );
}

export function MapLayerSwitcher({ mapRef }) {
  const [layerAnchorEl, setLayerAnchorEl] = useState(null);
  const [mapStyle, setMapStyle] = useState();
  const mapLayers = getMapLayers(mapRef);

  function handleLayerMenuClick(event) {
    setLayerAnchorEl(event.currentTarget);
  }

  function handleLayerMenuClose() {
    setLayerAnchorEl(null);
  }

  return (
    <Box>
      <Menu
        open={Boolean(layerAnchorEl)}
        anchorEl={layerAnchorEl}
        onClose={handleLayerMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box>
          <MapLayersList
            mapRef={mapRef}
            mapLayers={mapLayers}
            mapStyle={mapStyle}
            onMapStyleChange={setMapStyle}
          />
        </Box>
      </Menu>
      <Box
        sx={{
          position: 'absolute',
          bottom: 8,
          left: 8,
        }}
      >
        {mapLayers.length > 1 && (
          <MapButton onClick={handleLayerMenuClick} title="Layers">
            <LayersIcon />
          </MapButton>
        )}
      </Box>
    </Box>
  );
}

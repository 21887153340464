import { useIntelligenceItems } from '@/hooks';
import { Add as AddIcon, Done as DoneIcon } from '@mui/icons-material';
import {
  Avatar,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { IntelligenceItem } from './IntelligenceItem';
import { IntelligenceItemEditor } from './IntelligenceItemEditor';

export function PriorityIntelligence({ groupCodes, onComplete }) {
  const { data: items } = useIntelligenceItems({ groups: groupCodes });
  const [editCode, setEditCode] = useState(null);

  function handleSettingsClick(code) {
    return function () {
      setEditCode(code);
    };
  }

  function handleAddClick() {
    setEditCode('new');
  }

  function onEditorClose() {
    setEditCode(null);
  }

  function handleCompleteClick() {
    onComplete();
  }

  return (
    <Fragment>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{
          position: 'sticky',
          top: 48,
          backgroundColor: 'background.default',
          zIndex: 700,
          pl: 1,
          py: 1,
        }}
      >
        <Typography variant="h6">Priority Intelligence</Typography>
        <Box>
          <Tooltip title="Done" placement="right">
            <IconButton onClick={handleCompleteClick}>
              <DoneIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <Tooltip title="Add">
            <IconButton size="small" onClick={handleAddClick}>
              <Avatar
                sx={{
                  color: 'secondary.contrastText',
                  bgcolor: 'secondary.main',
                  width: 32,
                  height: 32,
                  fontSize: 24,
                }}
              >
                <AddIcon fontSize="inherit" />
              </Avatar>
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
      <Stack spacing={1} sx={{ pb: 1 }}>
        {items.map((item) => (
          <IntelligenceItem
            key={item.code}
            item={item}
            onSettingsClick={handleSettingsClick(item.code)}
          />
        ))}
      </Stack>
      <IntelligenceItemEditor
        code={editCode}
        isOpen={Boolean(editCode)}
        onClose={onEditorClose}
        groupCodes={groupCodes}
      />
    </Fragment>
  );
}

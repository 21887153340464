import { FETCH_VEHICLES } from '@/actions';
import { vehiclesStateAtom } from '@/atoms';
import { NavLink, SearchBox, SortField } from '@/components/controls';
import { useAuth, useDocumentTitle, useGroups, useOptions } from '@/hooks';
import {
  checkLuhn,
  checkVin,
  downloadCSV,
  downloadJSON,
  get,
  getFilenameForDownload,
  getVehicles,
  getVehiclesAndHeaders,
  isStale,
} from '@/utils';
import {
  Add as AddIcon,
  DirectionsCar as CarIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useVirtualizer } from '@tanstack/react-virtual';
import { format, isBefore } from 'date-fns';
import { dequal } from 'dequal';
import { useAtom } from 'jotai';
import _ from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useParams } from 'react-router-dom';
import { CommissioningDialogProvider } from '../telematics-boxes';

export function Vehicles() {
  useDocumentTitle('IR3 | Vehicles');
  const { id } = useParams();
  const [{ searchText, sortBy, sortDesc, filter, showSettings }, setState] =
    useAtom(vehiclesStateAtom);
  const dispatch = useDispatch();
  const vehicles = useSelector((state) => state.vehicles.vehicles, dequal);
  const { data: groups } = useGroups();
  const { data: typeOptions } = useOptions('groupType');
  const parentRef = useRef();
  const auth = useAuth();
  const canCreate = auth.isAuthorised('vehicles', true);
  const validPastDate = '2099-01-01';

  const sortOptions = [
    { label: 'Registration', value: 'registrationNumber' },
    { label: 'Fleet Number', value: 'fleetNumber' },
    { label: 'Last Poll Time', value: 'lastPollTime' },
    { label: 'VIN', value: 'identificationNumber' },
  ];

  const filterOptions = {
    imei: [
      { label: 'None', value: 'none' },
      { label: 'Invalid', value: 'invalid' },
    ],
    vin: [{ label: 'Invalid', value: 'invalid' }],
    disposed: [
      { label: 'Hide', value: false },
      { label: 'Show', value: true },
    ],
    stale: [
      { label: 'Show stale', value: 'stale' },
      { label: 'Show non stale', value: 'nonStale' },
    ],
  };

  useEffect(() => {
    dispatch({
      type: FETCH_VEHICLES,
    });
  }, [dispatch]);

  const filteredList = useMemo(() => {
    return _.orderBy(
      vehicles
        .filter(
          (item) =>
            !filter.ancestor ||
            item.groupAncestorCodes.includes(filter.ancestor.value),
        )
        .filter(
          (item) =>
            item.searchString.indexOf(searchText.toLowerCase()) > -1 ||
            searchText === '',
        )
        .filter(
          (item) =>
            !filter.imei ||
            (filter.imei === 'none' && !item.telematicsBoxImei) ||
            (filter.imei === 'invalid' &&
              item.telematicsBoxImei &&
              !checkLuhn(item.telematicsBoxImei)),
        )
        .filter(
          (item) =>
            !filter.vin ||
            (filter.vin === 'invalid' &&
              item.identificationNumber &&
              !checkVin(item.identificationNumber)),
        )
        .filter(
          (item) =>
            filter.disposed ||
            !isBefore(new Date(item.disposalDate ?? validPastDate), new Date()),
        )
        .filter(
          (item) =>
            !filter.stale ||
            (filter.stale === 'stale' && isStale(item, 'vehicles')) ||
            (filter.stale === 'nonStale' && !isStale(item, 'vehicles')),
        ),
      [sortBy],
      [sortDesc ? 'desc' : 'asc'],
    );
  }, [filter, searchText, sortBy, sortDesc, vehicles]);
  const ancestors = useMemo(
    () =>
      groups
        .map((group) => ({
          label: group.name,
          value: group.code,
          type: typeOptions[group.type] ?? '',
        }))
        .sort(
          (a, b) =>
            a.type.localeCompare(b.type) || a.label.localeCompare(b.label),
        ),
    [groups, typeOptions],
  );

  const rowVirtualizer = useVirtualizer({
    count: filteredList.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 56,
    overscan: 10,
  });

  function handleSearchChange(event) {
    setState((state) => ({
      ...state,
      searchText: event.target.value,
    }));
  }

  function handleSettingsToggle() {
    setState((state) => ({
      ...state,
      showSettings: !state.showSettings,
    }));
  }

  function handleSortByChange(event) {
    setState((state) => ({
      ...state,
      sortBy: event.target.value,
    }));
  }

  function handleSortToggle() {
    setState((state) => ({
      ...state,
      sortDesc: !state.sortDesc,
    }));
  }

  const handleFilterChange = (field) => (event) => {
    setState((state) => ({
      ...state,
      filter: {
        ...state.filter,
        [field]: event.target.value,
      },
    }));
  };

  function handleAncestorFilterChange(event, option) {
    setState((state) => ({
      ...state,
      filter: {
        ...state.filter,
        ancestor: option,
      },
    }));
  }

  function getFilteredDataForDownload(data) {
    const filteredData = data.filter((o1) =>
      filteredList.some(
        (o2) => o1.identificationNumber === o2.identificationNumber,
      ),
    );

    return filteredData;
  }

  async function handleCsvClick() {
    const data = await getVehiclesAndHeaders();
    const filteredData = getFilteredDataForDownload(data.vehicles);
    const filename = getFilenameForDownload('vehicles', 'csv');
    downloadCSV(filteredData, filename, data.headers);
  }

  async function handleJsonClick() {
    const data = await getVehicles();
    const filteredData = getFilteredDataForDownload(data);
    const filename = getFilenameForDownload('vehicles', 'json');
    downloadJSON(filteredData, filename);
  }

  return (
    <CommissioningDialogProvider>
      <Box sx={{ display: 'flex', height: 'calc(100vh - 48px)' }}>
        <Stack
          sx={{
            width: 280,
            borderRight: 1,
            borderColor: 'divider',
            flexShrink: 0,
            height: 1,
          }}
        >
          <Box>
            <Stack
              direction="row"
              spacing={1}
              sx={{ p: 1, alignItems: 'center' }}
            >
              <SearchBox
                value={searchText}
                onChange={handleSearchChange}
                count={`${filteredList.length}/${
                  vehicles.filter(
                    (item) =>
                      filter.disposed ||
                      !isBefore(
                        new Date(item.disposalDate ?? validPastDate),
                        new Date(),
                      ),
                  ).length
                }`}
                sx={{ flexGrow: 1 }}
              />
              <Tooltip title={showSettings ? 'Hide settings' : 'Show settings'}>
                <IconButton size="small" onClick={handleSettingsToggle}>
                  <SettingsIcon
                    fontSize="inherit"
                    color={showSettings ? 'primary' : 'inherit'}
                  />
                </IconButton>
              </Tooltip>
              {canCreate && (
                <Tooltip title="Add new">
                  <IconButton component={Link} to="new" size="small">
                    <Avatar
                      sx={{
                        color: 'secondary.contrastText',
                        backgroundColor: 'secondary.main',
                        width: 24,
                        height: 24,
                        fontSize: 16,
                      }}
                    >
                      <AddIcon fontSize="inherit" />
                    </Avatar>
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
            <Collapse in={showSettings} unmountOnExit>
              <Stack sx={{ p: 1 }} spacing={1}>
                <SortField
                  sortBy={sortBy}
                  onSortByChange={handleSortByChange}
                  sortDesc={sortDesc}
                  onSortDescToggle={handleSortToggle}
                  options={sortOptions}
                />
              </Stack>
              <Stack sx={{ px: 1, pb: 1 }} spacing={1}>
                <Divider>
                  <Typography variant="caption" color="textSecondary">
                    Filters
                  </Typography>
                </Divider>
                <TextField
                  size="small"
                  fullWidth
                  select
                  label="IMEI"
                  value={filter.imei}
                  onChange={handleFilterChange('imei')}
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {filterOptions.imei.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  size="small"
                  fullWidth
                  select
                  label="VIN"
                  value={filter.vin}
                  onChange={handleFilterChange('vin')}
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {filterOptions.vin.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  size="small"
                  fullWidth
                  select
                  label="Disposed"
                  value={filter.disposed}
                  onChange={handleFilterChange('disposed')}
                >
                  {filterOptions.disposed.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  size="small"
                  fullWidth
                  select
                  label="Stale"
                  value={filter.stale}
                  onChange={handleFilterChange('stale')}
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {filterOptions.stale.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </TextField>
                <Autocomplete
                  value={filter.ancestor}
                  onChange={handleAncestorFilterChange}
                  options={ancestors}
                  groupBy={(option) => option.type}
                  getOptionLabel={(option) => option?.label ?? option}
                  sx={{ minWidth: 200 }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" label="Group | Area" />
                  )}
                />
                <Divider>
                  <Typography variant="caption" color="textSecondary">
                    Export
                  </Typography>
                </Divider>
                <Stack
                  direction="row"
                  // sx={{ p: 1 }}
                  spacing={1}
                  justifyContent="center"
                >
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={handleCsvClick}
                  >
                    CSV
                  </Button>
                  <Button
                    fullWidth
                    color="secondary"
                    variant="contained"
                    disableElevation
                    onClick={handleJsonClick}
                  >
                    JSON
                  </Button>
                </Stack>
              </Stack>
            </Collapse>
          </Box>
          <Divider />
          <Box ref={parentRef} sx={{ overflow: 'auto', height: 1 }}>
            <Box
              sx={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: '100%',
                position: 'relative',
              }}
            >
              {rowVirtualizer
                .getVirtualItems()
                .map(({ index, size, start }) => {
                  const item = filteredList[index];

                  function getSecondaryText() {
                    if (!get(item, sortBy)) {
                      return '';
                    }

                    switch (sortBy) {
                      case 'registrationNumber':
                      case 'fleetNumber':
                        return item.fleetNumber;
                      case 'lastPollTime':
                        return format(
                          new Date(item.lastPollTime),
                          'dd/MM/yyyy HH:mm:ss',
                        );
                      default:
                        return get(item, sortBy);
                    }
                  }

                  return (
                    <ListItemButton
                      dense
                      key={index}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: `${size}px`,
                        transform: `translateY(${start}px)`,
                      }}
                      component={NavLink}
                      to={`/resources/vehicles/${encodeURIComponent(
                        item.identificationNumber,
                      )}`}
                      selected={id === item.identificationNumber}
                    >
                      <ListItemAvatar>
                        <Avatar src={item.picture}>
                          <CarIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={item.registrationNumber}
                        secondary={getSecondaryText()}
                        primaryTypographyProps={{ noWrap: true }}
                        secondaryTypographyProps={{ noWrap: true }}
                      />
                    </ListItemButton>
                  );
                })}
            </Box>
          </Box>
        </Stack>
        <Box sx={{ overflow: 'auto', flex: 1 }}>
          <Outlet />
        </Box>
      </Box>
    </CommissioningDialogProvider>
  );
}

import {
  CLEAR_RETROSPECTIVE,
  CREATE_RETROSPECTIVE,
  CREATE_RETROSPECTIVE_FAILURE,
  CREATE_RETROSPECTIVE_SUCCESS,
  DELETE_RETROSPECTIVE,
  DELETE_RETROSPECTIVE_FAILURE,
  DELETE_RETROSPECTIVE_SUCCESS,
  ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT,
  ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_FAILURE,
  ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_SUCCESS,
  FETCH_RETROSPECTIVE,
  FETCH_RETROSPECTIVES,
  FETCH_RETROSPECTIVES_FAILURE,
  FETCH_RETROSPECTIVES_SUCCESS,
  FETCH_RETROSPECTIVE_FAILURE,
  FETCH_RETROSPECTIVE_ITEM,
  FETCH_RETROSPECTIVE_ITEM_FAILURE,
  FETCH_RETROSPECTIVE_ITEM_SUCCESS,
  FETCH_RETROSPECTIVE_LAYER,
  FETCH_RETROSPECTIVE_LAYER_BOUNDARY,
  FETCH_RETROSPECTIVE_LAYER_BOUNDARY_FAILURE,
  FETCH_RETROSPECTIVE_LAYER_BOUNDARY_SUCCESS,
  FETCH_RETROSPECTIVE_LAYER_CANCELLED,
  FETCH_RETROSPECTIVE_LAYER_FAILURE,
  FETCH_RETROSPECTIVE_LAYER_SUCCESS,
  FETCH_RETROSPECTIVE_SUBITEM,
  FETCH_RETROSPECTIVE_SUBITEM_FAILURE,
  FETCH_RETROSPECTIVE_SUBITEM_SUCCESS,
  FETCH_RETROSPECTIVE_SUCCESS,
  SYNC_RETROSPECTIVE_FORM,
  UPDATE_RETROSPECTIVE,
  UPDATE_RETROSPECTIVE_FAILURE,
  UPDATE_RETROSPECTIVE_LAYER_FILE,
  UPDATE_RETROSPECTIVE_LAYER_VIRTUALIZATION,
  UPDATE_RETROSPECTIVE_SUCCESS,
} from '@/actions';
import { reviveDate } from '@/utils';
import { GeoJSON } from 'ol/format';
import KML from 'ol/format/KML';

const INITIAL_STATE = {
  retrospectives: [],
  retrospective: { title: '', layers: [] },
  item: null,
  subItem: null,
  isLoading: false,
  loadingLayers: [],
  estimatingLayers: [],
  error: null,
};

export default function retrospectivesReducer(state = INITIAL_STATE, action) {
  let index = -1;

  switch (action.type) {
    case FETCH_RETROSPECTIVES:
      return { ...state, isLoading: true, error: null };
    case FETCH_RETROSPECTIVES_SUCCESS:
      return {
        ...state,
        retrospectives: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_RETROSPECTIVES_FAILURE:
      return {
        ...state,
        retrospectives: [],
        isLoading: false,
        error: action.payload,
      };
    case FETCH_RETROSPECTIVE:
      return { ...state, isLoading: true, error: null };
    case FETCH_RETROSPECTIVE_SUCCESS:
      return {
        ...state,
        retrospective: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_RETROSPECTIVE_FAILURE:
      return {
        ...state,
        // retrospective: null, otherwise it'll crash here
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_RETROSPECTIVE:
      return { ...state, isLoading: true, error: null };
    case UPDATE_RETROSPECTIVE_SUCCESS:
      index = state.retrospectives.findIndex(
        (retrospective) =>
          retrospective.identifier === action.payload.identifier,
      );
      return {
        ...state,
        retrospectives: state.retrospectives
          .slice(0, index)
          .concat(action.payload)
          .concat(state.retrospectives.slice(index + 1)),
        retrospective: action.payload,
      };
    case UPDATE_RETROSPECTIVE_FAILURE:
      return {
        ...state,
        // retrospective: null, otherwise it'll crash here
        isLoading: false,
        error: action.payload,
      };
    case SYNC_RETROSPECTIVE_FORM:
      return {
        ...state,
        retrospective: { ...action.payload, title: state.retrospective.title },
      };
    case CREATE_RETROSPECTIVE:
      return { ...state, isLoading: true, error: null };
    case CREATE_RETROSPECTIVE_SUCCESS:
      return {
        ...state,
        retrospectives: state.retrospectives.concat(action.payload),
        retrospective: action.payload,
      };
    case CREATE_RETROSPECTIVE_FAILURE:
      return {
        ...state,
        // retrospective: null, otherwise it'll crash here
        isLoading: false,
        error: action.payload,
      };
    case DELETE_RETROSPECTIVE:
      return { ...state, isLoading: true, error: null };
    case DELETE_RETROSPECTIVE_SUCCESS:
      index = state.retrospectives.findIndex(
        (retrospective) => retrospective.identifier === action.payload,
      );
      return {
        ...state,
        retrospectives: state.retrospectives
          .slice(0, index)
          .concat(state.retrospectives.slice(index + 1)),
        retrospective:
          state.retrospective.identifier === action.payload
            ? { title: '', layers: [] }
            : state.retrospective,
      };
    case DELETE_RETROSPECTIVE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT:
      return {
        ...state,
        estimatingLayers: [...state.estimatingLayers, action.payload.index],
        error: null,
      };
    case ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_SUCCESS:
      return {
        ...state,
        retrospective: {
          ...state.retrospective,
          layers: state.retrospective.layers.map((layer, index) =>
            index === action.payload.index
              ? {
                  ...layer,
                  ...action.payload,
                  abortedDueToLimitExceeded: false,
                }
              : layer,
          ),
        },
        estimatingLayers: state.estimatingLayers.filter(
          (index) => index !== action.payload.index,
        ),
      };
    case ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_FAILURE:
      return {
        ...state,
        retrospective: {
          ...state.retrospective,
          layers: state.retrospective.layers.map((layer, index) =>
            index === action.payload.index
              ? {
                  ...layer,
                  estimate: undefined,
                  abortedDueToLimitExceeded: false,
                }
              : layer,
          ),
        },
        estimatingLayers: state.estimatingLayers.filter(
          (index) => index !== action.payload.index,
        ),
        error: action.payload.message,
      };
    case FETCH_RETROSPECTIVE_LAYER:
    case FETCH_RETROSPECTIVE_LAYER_BOUNDARY:
      return {
        ...state,
        loadingLayers: [...state.loadingLayers, action.payload.index],
        error: null,
      };
    case FETCH_RETROSPECTIVE_LAYER_SUCCESS:
    case FETCH_RETROSPECTIVE_LAYER_BOUNDARY_SUCCESS:
      return {
        ...state,
        retrospective: {
          ...state.retrospective,
          layers: state.retrospective.layers.map((layer, index) =>
            index === action.payload.index ? action.payload.layer : layer,
          ),
        },
        loadingLayers: state.loadingLayers.filter(
          (index) => index !== action.payload.index,
        ),
      };
    case FETCH_RETROSPECTIVE_LAYER_FAILURE:
      return {
        ...state,
        loadingLayers: state.loadingLayers.filter(
          (index) => index !== action.payload.index,
        ),
        error: action.payload.message,
      };
    case FETCH_RETROSPECTIVE_LAYER_CANCELLED:
      return {
        ...state,
        loadingLayers: state.loadingLayers.filter(
          (index) => index !== action.payload,
        ),
      };
    case FETCH_RETROSPECTIVE_LAYER_BOUNDARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case CLEAR_RETROSPECTIVE:
      return { ...state, retrospective: { title: '', layers: [] } };
    case FETCH_RETROSPECTIVE_ITEM:
      return { ...state, item: {}, isLoading: true, error: null };
    case FETCH_RETROSPECTIVE_ITEM_SUCCESS:
      return {
        ...state,
        item: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_RETROSPECTIVE_ITEM_FAILURE:
      return {
        ...state,
        item: null,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_RETROSPECTIVE_SUBITEM:
      return { ...state, subItem: {}, isLoading: true, error: null };
    case FETCH_RETROSPECTIVE_SUBITEM_SUCCESS:
      return {
        ...state,
        subItem: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_RETROSPECTIVE_SUBITEM_FAILURE:
      return {
        ...state,
        subItem: null,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_RETROSPECTIVE_LAYER_VIRTUALIZATION:
      return {
        ...state,
        retrospective: {
          ...state.retrospective,
          layers: state.retrospective.layers.map((layer, index) =>
            index === action.payload.index
              ? {
                  ...layer,
                  virtualize: action.payload.virtualize,
                  window: action.payload.window || layer.window,
                }
              : layer,
          ),
        },
      };
    case UPDATE_RETROSPECTIVE_LAYER_FILE: {
      // eslint-disable-next-line no-inner-declarations
      function convertFile(type, fileContent) {
        if (!fileContent) {
          return undefined;
        }

        let parsedFile;
        if (type === 'kml') {
          const features = new KML().readFeatures(fileContent);
          const writeAsGeoJSONObject = new GeoJSON().writeFeaturesObject(
            features,
          );
          parsedFile = writeAsGeoJSONObject;
        } else {
          parsedFile = JSON.parse(fileContent, reviveDate);
        }

        return {
          ...parsedFile,
          features: parsedFile.features.map((item) => ({
            ...item,
            properties: {
              fileProperties: item.properties,
            },
          })),
        };
      }

      return {
        ...state,
        retrospective: {
          ...state.retrospective,
          layers: state.retrospective.layers.map((layer, index) => {
            if (index === action.payload.index) {
              return {
                ...layer,
                file: {
                  ...action.payload.file,
                  content: convertFile(
                    layer.source,
                    action.payload.file.content,
                  ),
                },
                featureCollection: null,
                primaryItemKey: null,
                secondaryItemKey: null,
              };
            } else {
              return layer;
            }
          }),
        },
      };
    }
    default:
      return state;
  }
}

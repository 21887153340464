import { api } from '@/apis';
import { log } from '@/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useDeleteUserQuery() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => {
      if (id) {
        log('Delete', 'User Query', { id });

        return api.delete(`userQueries/${id}`);
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['userQueries'] }),
  });
}

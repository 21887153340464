import { RetrospectiveTypeIcon } from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import { startCase } from '@/utils';
import { Error as ErrorIcon } from '@mui/icons-material';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { Fragment } from 'react';

export function PersonPoll({
  color,
  background,
  ssi,
  time,
  emergencyButtonOn,
  person = {},
}) {
  const name =
    !!person.forenames || !!person.surname
      ? `${person.forenames || ''}  ${person.surname || ''}`
      : ssi;
  useDocumentTitle(`IR3 | Poll | ${name}`);

  return (
    <Card sx={{ m: [1, 0.5, 1, 1] }}>
      <CardHeader
        avatar={
          <Tooltip title="Person Poll">
            <Avatar
              style={{
                background,
                color,
              }}
            >
              <RetrospectiveTypeIcon type="personPolls" />
            </Avatar>
          </Tooltip>
        }
        title={name}
        subheader={person.collarNumber}
      />
      <CardContent>
        {person && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Person
            </Typography>
            <Table size="small" sx={{ mt: 1, mb: 2 }}>
              <TableBody>
                {person.role && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        Role
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">{person.role}</Typography>
                    </TableCell>
                  </TableRow>
                )}
                {person.homeStation && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        Home Station
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">
                        {person.homeStation}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {Object.entries(person.groups ?? {}).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        {startCase(key)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">
                        {(value ?? []).join(', ')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>
                    <Typography variant="caption" color="textSecondary">
                      Radio SSI
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">{person.radioSsi}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Fragment>
        )}
        <Typography variant="subtitle2" color="textSecondary">
          Status
        </Typography>
        <Grid
          container
          spacing={2}
          justifyContent="space-around"
          alignItems="center"
          sx={{ mt: 1 }}
        >
          <Grid item xs={4} sx={{ textAlign: 'center' }}>
            <Typography variant="caption">
              {format(new Date(time), 'dd/MM/yyyy HH:mm:ss')}
            </Typography>
          </Grid>
          {emergencyButtonOn !== undefined && (
            <Grid item xs={4}>
              <Tooltip title="Emergency">
                <ErrorIcon color={emergencyButtonOn ? 'error' : 'disabled'} />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

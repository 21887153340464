export const pipelineFn = (orderBy, order, page, rowsPerPage, query) => [
  { $match: query },
  {
    $project: {
      identifier: true,
      area: true,
      class: true,
      code: true,
      time: true,
      isConfirmed: true,
      description: true,
      vehicle: true,
    },
  },
  { $sort: { [orderBy]: order === 'desc' ? -1 : 1 } },
  { $skip: page * rowsPerPage },
  { $limit: rowsPerPage },
];

export const downloadPipelineFn = (query) => [
  { $match: query },
  {
    $project: {
      identifier: true,
      area: true,
      class: true,
      code: true,
      time: true,
      isConfirmed: { $cond: ['$isConfirmed', 'Yes', 'No'] },
      description: true,
      vehicle: true,
    },
  },
];

export const headers = [
  {
    label: 'Registration',
    key: 'vehicle.registrationNumber',
    type: 'text',
  },
  {
    label: 'Fleet Number',
    key: 'vehicle.fleetNumber',
    type: 'text',
  },
  {
    label: 'Role',
    key: 'vehicle.role',
    type: 'text',
  },
  {
    label: 'Division',
    key: 'vehicle.groups.division',
    type: 'text',
  },
  { label: 'Type', key: 'vehicle.type', type: 'text' },
  { label: 'Time', key: 'time', type: 'date' },
  { label: 'Confirmed', key: 'isConfirmed', type: 'boolean' },
  { label: 'Class', key: 'class', type: 'text' },
  { label: 'Code', key: 'code', type: 'text' },
  { label: 'Area', key: 'area', type: 'text' },
  { label: 'Description', key: 'description', type: 'text' },
];

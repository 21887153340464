import { api } from '@/apis';
import { log } from '@/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertRetention() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ _id, vehicle: _, ...json }) => {
      if (_id) {
        log('Update', 'Data Retention', json);

        return api
          .put(`telematicsBoxRetentions/${json.identifier}`, { json })
          .json();
      } else {
        log('Create', 'Data Retention', { json });

        return api.post('telematicsBoxRetentions', { json }).json();
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['retentions'] }),
  });
}

import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useUserIds() {
  return useQuery({
    queryKey: ['userIds'],
    queryFn: async () => {
      const userIds = await api.get('audits/fields/user/values').json();

      userIds.sort();

      return userIds;
    },
    placeholderData: [],
  });
}

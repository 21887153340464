import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useTrip(id) {
  return useQuery({
    queryKey: ['trips', id],
    queryFn: async () => {
      const json = [
        { $match: { identifier: id } },
        {
          $project: {
            _id: false,
            identifier: true,
            driver: { code: true },
            vehicle: { identificationNumber: true },
            rfidCard: true,
            startTime: true,
            endTime: true,
            durationSeconds: { $divide: ['$durationSeconds', 86400] },
            distanceMiles: {
              $round: [{ $multiply: ['$distanceKilometres', 0.62137119] }, 2],
            },
            maxSpeedMilesPerHour: {
              $round: [
                { $multiply: ['$maxSpeedKilometresPerHour', 0.62137119] },
                2,
              ],
            },
            startLocations: true,
            endLocations: true,
            classification: true,
            occupants: true,
          },
        },
      ];

      const trips = await api.post('pipeline/trips', { json }).json();

      log('Read', 'Trip', { id });

      return trips[0] ?? null;
    },
    staleTime: 30 * 60 * 1000,
    enabled: !!id,
  });
}

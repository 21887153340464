import { DatePicker } from '@/components/controls';

export function DateField({ input, meta, ...props }) {
  return (
    <DatePicker
      helperText={meta.error}
      error={meta.invalid}
      {...input}
      {...props}
    />
  );
}

import {
  FETCH_AGGREGATED_DRIVING_SCORES,
  FETCH_DRIVER_TRIPS_BY_DRIVER_CODE,
  FETCH_DRIVING_SCORES,
  LOAD_AGGREGATED_DRIVING_SCORES,
  UPDATE_AGGREGATED_DRIVING_SCORE_FILTER,
  UPDATE_FILTER_EMERGENCY_EQUIPMENT_USED,
  UPDATE_FILTER_TIME_AGGREGATION,
  UPDATE_SELECTED_AGGREGATED_TRIPS_DRIVER,
} from '@/actions';
import { SelectMultiple, Table, TablePagination } from '@/components/controls';
import { useDocumentTitle, usePrevious } from '@/hooks';
import {
  downloadCSV,
  formatGroups,
  getFilenameForDownload,
  getVehiclePolls,
  round,
  startCase,
  telematicsBoxPollHeaders,
} from '@/utils';
import { driverBehaviourOptions, personGroups } from '@/utils/config';
import {
  ArrowBack as BackIcon,
  CallMissedOutgoing as CallMissedOutgoingIcon,
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
  Person as PersonIcon,
  PlayArrow as PlayArrowIcon,
  Replay as ReplayIcon,
  Star as StarIcon,
  TrendingUp as TrendingUpIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { green, grey, orange, red } from '@mui/material/colors';
import { useVirtualizer } from '@tanstack/react-virtual';
import { add, differenceInSeconds, format, startOfYear } from 'date-fns';
import { dequal } from 'dequal';
import _ from 'lodash';
import {
  CarBrakeAlert as CarBrakeAlertIcon,
  CarTractionControl as CarTractionControlIcon,
  Speedometer as SpeedometerIcon,
  Steering as SteeringIcon,
} from 'mdi-material-ui';
import { enqueueSnackbar } from 'notistack';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Bar,
  BarChart,
  Cell,
  Tooltip as ChartTooltip,
  ComposedChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

const {
  goodTripScoreThreshold: goodThreshold = 95,
  okTripScoreThreshold: okThreshold = 85,
} = driverBehaviourOptions;

function ReplayLink({ entry }) {
  const navigate = useNavigate();

  const handleClick = (identifier) => (event) => {
    event.stopPropagation();

    navigate(`/eventreplay/trips/${identifier}`);
  };

  return entry.tripId ? (
    <Tooltip title="View">
      <IconButton
        size="small"
        aria-label="View"
        onClick={handleClick(entry.tripId)}
      >
        <PlayArrowIcon />
      </IconButton>
    </Tooltip>
  ) : (
    '-'
  );
}

function DownloadPollsLink({ entry }) {
  const handleClick = (imei, startTime, endTime) => async (event) => {
    event.stopPropagation();

    const polls = await getVehiclePolls(imei, startTime, endTime);

    const data = polls.map((poll) => ({
      identificationNumber: entry.identificationNumber,
      registrationNumber: entry.registrationNumber,
      fleetNumber: entry.fleetNumber,
      ...poll,
    }));

    const filename = getFilenameForDownload(
      'Trip Polls',
      'csv',
      startTime,
      endTime,
    );

    downloadCSV(data, filename, telematicsBoxPollHeaders);
  };

  return (
    <Tooltip title="Download trip polls">
      <IconButton
        size="small"
        onClick={handleClick(entry.imei, entry.startTime, entry.endTime)}
      >
        <GetAppIcon />
      </IconButton>
    </Tooltip>
  );
}

function scoreColour(score) {
  if (!score || score === 0) {
    return '#000';
  }

  return score > goodThreshold
    ? green[500]
    : score < okThreshold
    ? red[500]
    : orange[700];
}

const preAreaHeaders = [
  { label: 'Name', key: 'name' },
  { label: 'Collar Number', key: 'collarNumber' },
  { label: 'Role', key: 'role' },
];

const postAreaHeaders = [
  { label: 'Average Score (%)', key: 'averageScore' },
  { label: 'Lowest Score (%)', key: 'lowestScore' },
  { label: 'Highest Score (%)', key: 'highestScore' },
];

function getDriverDownloadHeaders(filterAreas) {
  return preAreaHeaders
    .concat(
      Object.entries(filterAreas).map(([key]) => ({
        label: startCase(key),
        key,
      })),
    )
    .concat(postAreaHeaders);
}

function formatSeconds(seconds) {
  return `${((seconds || 0) / 3600).toFixed(seconds > 86400 ? 0 : 2)} hours`;
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <Box
        className="recharts-default-tooltip"
        style={{
          margin: '0px',
          padding: '10px',
          backgroundColor: 'rgb(255, 255, 255)',
          border: '1px solid rgb(204, 204, 204)',
          whiteSpace: 'nowrap',
        }}
      >
        <p className="recharts-tooltip-label" style={{ margin: '0px' }}>
          {label}
        </p>
        {(payload || []).map(({ name, value }) => (
          <p key={name} style={{ color: scoreColour(value) }}>
            {`${name}: ${round(value, 2)}%`}
          </p>
        ))}
      </Box>
    );
  }

  return null;
};

const yAxisStep = 10;
const yAxisDomain = [
  (dataMin) => {
    // console.log('min', dataMin);
    if (!isFinite(dataMin)) {
      return dataMin;
    }

    // round dataMin down to nearest yAxisStep
    dataMin =
      Math.floor(Math.max(0, dataMin) / yAxisStep, yAxisStep) * yAxisStep;
    return dataMin === 100 ? 90 : dataMin;
  },
  () => 100,
];

export function DriverBehaviourDashboard() {
  useDocumentTitle('IR3 | Driver Behaviour');
  const aggregatedTripHeaders = [
    { key: 'label', accessor: 'label', label: 'Time', type: 'text' },
    {
      key: 'tripsDistanceMileage',
      label: 'Distance',
      align: 'right',
      accessor: 'tripsDistanceMileage',
      type: 'number',
      format: (value) => `${round(value, 2)} miles`,
    },
    {
      key: 'tripsDurationSeconds',
      label: 'Driving',
      align: 'right',
      accessor: 'tripsDurationSeconds',
      type: 'number',
      format: (value) => formatSeconds(value),
    },
    {
      key: 'excessAccelerationRatio',
      label: 'Acceleration',
      align: 'right',
      accessor: 'excessAccelerationRatio',
      type: 'number',
      format: (value) => `${round(value * 100, 2)}%`,
    },
    {
      key: 'excessBrakingRatio',
      label: 'Braking',
      align: 'right',
      accessor: 'excessBrakingRatio',
      type: 'number',
      format: (value) => `${round(value * 100, 2)}%`,
    },
    {
      key: 'excessCorneringRatio',
      label: 'Cornering',
      accessor: 'excessCorneringRatio',
      type: 'number',
      format: (value) => `${round(value * 100, 2)}%`,
    },
    {
      key: 'speedInfractionRatio',
      label: 'Speeding',
      align: 'right',
      accessor: 'speedInfractionRatio',
      type: 'number',
      format: (value) => `${round(value * 100, 2)}%`,
    },
    {
      key: 'score',
      label: 'Score',
      align: 'right',
      accessor: 'score',
      type: 'number',
      format: (value) => (
        <span
          style={{
            color: scoreColour(value),
          }}
        >
          {`${round(value, 2)}%`}
        </span>
      ),
    },
  ];

  const dateFormat = 'dd/MM/yyyy HH:mm:ss';

  // trip columns
  const individualTripsHeaders = [
    {
      key: 'driverId',
      accessor: 'driverId',
      label: 'Driver',
      type: 'text',
    },
    {
      key: 'startTime',
      accessor: 'startTime',
      label: 'Start Time',
      type: 'date',
      format: (value) => format(value, dateFormat),
    },
    {
      key: 'endTime',
      accessor: 'endTime',
      label: 'End Time',
      type: 'date',
      format: (value) => format(value, dateFormat),
    },
    {
      key: 'fleetNumber',
      accessor: 'fleetNumber',
      label: 'Fleet Number',
      type: 'text',
    },
    {
      key: 'registrationNumber',
      accessor: 'registrationNumber',
      label: 'Registration Number',
      type: 'text',
    },
    // test { key: 'vehicleImei', accessor: 'vehicleImei', label: 'IMEI'},
    {
      key: 'durationSeconds',
      accessor: 'durationSeconds',
      label: 'Duration',
      align: 'right',
      // format: (value) => shortHumanizer(value * 1000, { largest: 2 }),
      type: 'number',
      format: (value) => formatSeconds(value),
    },
    {
      key: 'mileage',
      accessor: 'mileage',
      label: 'Distance',
      align: 'right',
      type: 'number',
      format: (value) => `${round(value, 2)} miles`,
    },
    {
      key: 'excessAcceleration',
      label: 'Acceleration',
      align: 'right',
      accessor: 'excessAcceleration',
      type: 'number',
      format: (value) => `${round(value * 100, 2)}%`,
    },
    {
      key: 'excessBraking',
      label: 'Braking',
      align: 'right',
      accessor: 'excessBraking',
      type: 'number',
      format: (value) => `${round(value * 100, 2)}%`,
    },
    {
      key: 'excessCornering',
      accessor: 'excessCornering',
      label: 'Cornering',
      align: 'right',
      type: 'number',
      format: (value) => `${round(value * 100, 2)}%`,
    },
    {
      key: 'speedInfraction',
      label: 'Speeding',
      align: 'right',
      accessor: 'speedInfraction',
      type: 'number',
      format: (value) => `${round(value * 100, 2)}%`,
    },
    {
      key: 'score',
      accessor: 'score',
      label: 'Score',
      align: 'right',
      type: 'number',
      format: (value) => (
        <span
          style={{
            color: scoreColour(value),
          }}
        >
          {`${round(value, 2)}%`}
        </span>
      ),
    },
    {
      key: 'tripId',
      accessor: 'tripId',
      label: 'View',
      align: 'right',
      type: 'component',
      component: ReplayLink,
    },
    {
      key: 'downloadAccelPolls',
      label: 'Polls',
      type: 'component',
      component: DownloadPollsLink,
      sortable: false,
    },
  ].filter(Boolean);

  const [driverPage, setDriverPage] = useState(0);
  const [tripsPage, setTripsPage] = useState(0);
  const rowsPerDriverPage = 5;
  const rowsPerTripsPage = 10;

  const [driverOrder, setDriverOrder] = useState('asc');
  const [driverOrderBy, setDriverOrderBy] = useState('Group');

  const [tripOrder, setTripOrder] = useState('asc');
  const [tripOrderBy, setTripOrderBy] = useState('Group');

  function handleChangeDriverPage(_, newPage) {
    setDriverPage(newPage);
  }

  function handleChangeTripsPage(_, newPage) {
    setTripsPage(newPage);
  }

  function handleDriverOrderChange(order) {
    setDriverOrder(order);
  }

  function handleDriverOrderByChange(orderBy) {
    setDriverOrderBy(orderBy);
    setDriverOrder('asc');
  }

  function handleTripOrderChange(order) {
    setTripOrder(order);
  }

  function handleTripOrderByChange(orderBy) {
    setTripOrderBy(orderBy);
    setTripOrder('asc');
  }

  function setDates(startTime, endTime, timeAggregation) {
    if (timeAggregation === 'years') {
      startTime = startOfYear(add(new Date(), { years: -10 }));
      endTime = startOfYear(add(new Date(), { years: 1 }));
    }

    deselectAggregatedTrips();

    dispatch({
      type: UPDATE_FILTER_TIME_AGGREGATION,
      payload: {
        startTime,
        endTime,
        timeAggregation,
      },
    });

    fetchAggregatedDrivingScores(startTime, endTime, timeAggregation);
  }

  function handleBarClick(props) {
    if (isLoading || !props || !props.activePayload) {
      return;
    }

    const { activePayload } = props;
    const { date: selectedStartTime } = activePayload[0].payload;

    if (timeAggregation === 'years') {
      // if the timeAggregation is currently years, drilling in is a year of months
      setDates(
        selectedStartTime,
        add(selectedStartTime, { years: 1 }),
        'months',
      );
    } else if (timeAggregation === 'months') {
      // if the timeAggregation is currently months, drilling in is a month of days
      setDates(
        selectedStartTime,
        add(selectedStartTime, { months: 1 }),
        'days',
      );
    }

    // don't do anything for clicking on a day
  }

  function mergeTrips(vehicleTrips, driverTrips) {
    return vehicleTrips.map((vehicleTrip) => {
      // match on start time
      const vehicleTripStart = new Date(vehicleTrip.startTime);
      const matchingDriverTrip = (driverTrips || []).find((d) => {
        const secondsDifference = differenceInSeconds(
          vehicleTripStart,
          new Date(d.startTime),
        );
        return (
          vehicleTrip.identificationNumber === d.identificationNumber &&
          Math.abs(secondsDifference) < 10
        );
      });

      const tripId = matchingDriverTrip?.identifier;
      const imei = matchingDriverTrip?.vehicle?.telematicsBoxImei;
      const speedPercent =
        vehicleTrip.durationSeconds > 0
          ? 100 *
            ((matchingDriverTrip?.speedingSeconds || 0) /
              vehicleTrip.durationSeconds)
          : 0;
      // a vehicleTrip score already has accel subtracted, now take away speeding %
      const score = vehicleTrip.score - speedPercent;

      // if the collarNumber is unknown, use the name which might have driver id
      const { collarNumber, name } = vehicleTrip;

      let driverId = collarNumber;
      if (collarNumber === 'Unknown' && !!name) {
        driverId = name;
      }

      return { ...vehicleTrip, tripId, score, imei, driverId };
    });
  }

  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.reports.aggregatedDrivingScores.data,
    dequal,
  );
  const isLoading = useSelector(
    (state) => state.reports.aggregatedDrivingScores.isLoading,
  );
  const error = useSelector(
    (state) => state.reports.aggregatedDrivingScores.error,
  );
  const filter = useSelector(
    (state) => state.reports.aggregatedDrivingScores.filter,
    dequal,
  );
  const filterValues = useSelector(
    (state) => state.reports.aggregatedDrivingScores.filterValues,
    dequal,
  );

  const individualData = useSelector(
    (state) => state.reports.drivingScores.data,
  );
  const individualIsLoading = useSelector(
    (state) => state.reports.drivingScores.isLoading,
  );
  const individualError = useSelector(
    (state) => state.reports.drivingScores.error,
  );
  const driverTrips = useSelector((state) => state.reports.driverTrips);

  const emergencyEquipmentUsed = useSelector(
    (state) => state.filters.emergencyEquipmentUsed,
    dequal,
  );
  const startTime = useSelector(
    (state) => state.reports.aggregatedDrivingScores.startTime,
    dequal,
  );
  const endTime = useSelector(
    (state) => state.reports.aggregatedDrivingScores.endTime,
    dequal,
  );
  const timeAggregation = useSelector(
    (state) => state.reports.aggregatedDrivingScores.timeAggregation || 'years',
  );

  const selectedDriverCode = useSelector(
    (state) => state.reports.aggregatedDrivingScores.selectedDriverCode,
  );
  const selectedDriverTripsKey = useSelector(
    (state) => state.reports.aggregatedDrivingScores.selectedDriverTripsKey,
  );

  const [showFilter, setShowFilter] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState();
  const [selectedAggregatedTrips, setSelectedAggregatedTrips] = useState();
  const listRef = useRef();

  // aggregated data is split by emergency equipment, values are 0, 1, and 2
  // for no, yes, yes & no. These correspond to the following keys so for example
  // if equipment of yes was selected, index 1 gets the key "statsWithEmergencyEquipment"
  const subTotalKeys = [
    'statsWithoutEmergencyEquipment',
    'statsWithEmergencyEquipment',
    'statsAll',
  ];
  const subTotalIndex =
    emergencyEquipmentUsed < subTotalKeys.length ? emergencyEquipmentUsed : 2;
  const subTotalKey = subTotalKeys[subTotalIndex];

  const totals = data[subTotalKey]?.totals;
  const trend = data[subTotalKey]?.trend;
  const drivers = data[subTotalKey]?.drivers;
  // const trips = data[subTotalKey]?.trips;

  // try to merge the actual trips with the accel summaries
  // i.e. ignition/swipe trips vs ignition only trips
  const individualTrips = selectedAggregatedTrips
    ? mergeTrips(individualData[subTotalKey]?.trips || [], driverTrips)
    : [];

  // try to reselect the driver and the trips if user comes back from replay
  // or they filter
  const [sortedDrivers, setSortedDrivers] = useState([]);
  const previousTrend = usePrevious(trend);
  useEffect(() => {
    if (previousTrend !== trend) {
      const newSortedDrivers = _.orderBy(
        Object.values(drivers),
        'averageScore',
        'desc',
      ).map((driver) => ({
        ...driver,
        ...formatGroups(driver.groups || {}),
      }));

      setSortedDrivers(newSortedDrivers);

      // the driver data have changed but if we selected one, try to reselect
      if (selectedDriverCode) {
        const driverInNewData = drivers[selectedDriverCode];
        if (driverInNewData) {
          setSelectedDriver(driverInNewData);

          // is the time selected for that driver available
          if (selectedDriverTripsKey) {
            const aggregatedTripsInNewDriver =
              driverInNewData.aggregatedTrips.find(
                (t) => t.startTime.toISOString() === selectedDriverTripsKey,
              );
            if (aggregatedTripsInNewDriver) {
              setSelectedAggregatedTrips(aggregatedTripsInNewDriver);
            } else {
              setSelectedAggregatedTrips(undefined);
            }
          }
        } else {
          setSelectedDriver(undefined);
          setSelectedAggregatedTrips(undefined);
        }
      }

      // if this has been loaded we'll have to reset the start, end and timeAgg
    }
  }, [
    trend,
    previousTrend,
    drivers,
    selectedDriverCode,
    selectedDriverTripsKey,
  ]);

  // no need to do this anymore
  // const previousEmergencyEquipment = usePrevious(emergencyEquipmentUsed);
  // useEffect(() => {
  //   if (
  //     previousEmergencyEquipment !== emergencyEquipmentUsed &&
  //     selectedAggregatedTrips
  //   ) {
  //     // refetch officer trips
  //     setSelectedAggregatedTrips({ ...selectedAggregatedTrips });
  //   }
  // }, [
  //   emergencyEquipmentUsed,
  //   previousEmergencyEquipment,
  //   selectedAggregatedTrips,
  // ]);

  const previousSortedDrivers = usePrevious(sortedDrivers);
  useEffect(() => {
    if (previousSortedDrivers && previousSortedDrivers !== sortedDrivers) {
      if (selectedDriverCode) {
        const index = sortedDrivers.findIndex(
          (d) => d.code === selectedDriverCode,
        );
        if (index > 0) {
          listRef.current?.scrollToItem(index, 'start');
        }
      }
    }
  }, [previousSortedDrivers, selectedDriverCode, sortedDrivers]);

  const trendVsDriver = selectedDriver
    ? trend.map((t) => ({
        date: t.date,
        label: t.label,
        overallAverage: t.Average,
        driverAverage:
          selectedDriver.aggregatedTrips.find((d) => d.label === t.label)
            ?.score || 0,
      }))
    : undefined;

  useEffect(() => {
    if (error || individualError) {
      enqueueSnackbar(error || individualError, { variant: 'error' });
    }
  }, [error, individualError]);

  // any time the filters change reload (and refilter)
  const haveData = Object.keys(drivers).length !== 0;
  const prevFilter = usePrevious(filter);
  useEffect(() => {
    if (prevFilter && prevFilter !== filter) {
      dispatch({
        type: LOAD_AGGREGATED_DRIVING_SCORES,
        payload: filter,
      });
    }
  }, [prevFilter, dispatch, filter]);

  // when it starts for the first time, fetch
  useEffect(() => {
    if (!haveData) {
      dispatch({
        type: FETCH_AGGREGATED_DRIVING_SCORES,
        payload: { startTime, endTime, timeAggregation, filter },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parentRef = useRef(null);
  const rowVirtualizer = useVirtualizer({
    count: sortedDrivers.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 56,
    overscan: 20,
  });

  function handleFilterToggle() {
    setShowFilter(!showFilter);
  }

  function handleEmergencyEquipmentUsedChanged({ target: { value } }) {
    dispatch({
      type: UPDATE_FILTER_EMERGENCY_EQUIPMENT_USED,
      payload: parseInt(value),
    });
  }

  function handleFilterFieldChanged(name, value) {
    const newFilter =
      name in filter
        ? {
            ...filter,
            [name]: value || [],
          }
        : {
            ...filter,
            groups: {
              ...filter.groups,
              [name]: value || [],
            },
          };

    dispatch({
      type: UPDATE_AGGREGATED_DRIVING_SCORE_FILTER,
      payload: newFilter,
    });
  }

  function getBreadcrumbs() {
    let parts = [];

    if (timeAggregation === 'days') {
      parts = ['yyyy', 'MMM'];
    } else if (timeAggregation === 'months') {
      parts = ['yyyy'];
    }

    return parts.map((part) => (
      <Button
        key={part}
        color="primary"
        disabled={isLoading}
        onClick={() => {
          if (part === 'yyyy') {
            // timeAgg of 'years' will automatically change start and end to last decade...
            setDates(startTime, endTime, 'years');
          } else if (part === 'MMM') {
            setDates(
              startOfYear(startTime),
              add(startOfYear(startTime), { years: 1 }),
              'months',
            );
          }
        }}
      >
        {format(startTime, part)}
      </Button>
    ));
  }

  // just use the day when the year and month are already visible in the breadcrumb
  function tickFormatter(tickItem) {
    if (timeAggregation === 'days' && tickItem) {
      const dayPart = parseInt(tickItem.substring(0, 2));
      if (!isNaN(dayPart)) {
        return dayPart;
      }
    }

    return tickItem;
  }

  const handleClickAggregatedTrips = (row) => {
    !isLoading && setSelectedAggregatedTrips(row);
  };

  const previousSelectedAggregatedTrips = usePrevious(selectedAggregatedTrips);
  useEffect(() => {
    if (
      previousSelectedAggregatedTrips !== selectedAggregatedTrips &&
      selectedAggregatedTrips
    ) {
      dispatch({
        type: UPDATE_SELECTED_AGGREGATED_TRIPS_DRIVER,
        payload: {
          selectedDriverCode: selectedDriver.code,
          selectedDriverTripsKey:
            selectedAggregatedTrips.startTime.toISOString(),
        },
      });

      // JL perf: I don't use the end date until here
      // so just calculate it here to save a second in the epic
      const start = selectedAggregatedTrips.startTime;
      const end = add(selectedAggregatedTrips.startTime, {
        [timeAggregation]: 1,
      });

      dispatch({
        type: FETCH_DRIVER_TRIPS_BY_DRIVER_CODE,
        payload: {
          startTime: start,
          endTime: end,
          driverCode: selectedDriver.code,
          excludeExempt: true,
        },
      });

      dispatch({
        type: FETCH_DRIVING_SCORES,
        payload: {
          startTime: start,
          endTime: end,
          driverCode: selectedDriver.code,
          // emergencyEquipmentUsed,
          // collarNumber: selectedDriver.collarNumber,
          filter: {
            code: [],
            name: [],
            collarNumber: [],
            role: [],
            groups: {},
          },
          // addDayToEndTime: false,
        },
      });
    }
  }, [
    dispatch,
    // emergencyEquipmentUsed,
    selectedAggregatedTrips,
    previousSelectedAggregatedTrips,
    selectedDriver,
    timeAggregation,
  ]);

  function ScoreColourCell(score, index) {
    return (
      <Cell
        key={`cell-${index}`}
        stroke={scoreColour(score)}
        fill={scoreColour(score)}
        style={{
          cursor: timeAggregation === 'days' ? 'not-allowed' : 'pointer',
        }}
      />
    );
  }

  function deselectAggregatedTrips() {
    setSelectedAggregatedTrips(undefined);

    dispatch({
      type: UPDATE_SELECTED_AGGREGATED_TRIPS_DRIVER,
      payload: {
        selectedDriverCode: selectedDriver?.code,
        selectedDriverTripsKey: undefined,
      },
    });
  }

  const handleSelectedDriverChange = (index) => () => {
    const driver = sortedDrivers[index];
    setSelectedDriver(driver);
    setSelectedAggregatedTrips(undefined);

    dispatch({
      type: UPDATE_SELECTED_AGGREGATED_TRIPS_DRIVER,
      payload: {
        selectedDriverCode: driver.code,
        selectedDriverTripsKey: undefined,
      },
    });
  };

  function fetchAggregatedDrivingScores(startTime, endTime, timeAggregation) {
    dispatch({
      type: FETCH_AGGREGATED_DRIVING_SCORES,
      payload: { startTime, endTime, timeAggregation, filter },
    });
  }

  function showReloadButton() {
    return (
      <Fragment>
        <Typography
          variant="h6"
          sx={{
            mt: 17,
            top: 0,
            left: 0,
            // zIndex: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Network error retrieving data...
        </Typography>
        <IconButton
          color="secondary"
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            width: 0.4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 'medium',
            borderRadius: 1,
          }}
          onClick={() =>
            fetchAggregatedDrivingScores(
              startTime,
              endTime,
              timeAggregation,
              filter,
            )
          }
        >
          <ReplayIcon sx={{ mr: 1 }} />
          Click here to try again
        </IconButton>
      </Fragment>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 48px)',
        overflow: 'hidden',
        backgroundColor: 'background.default',
      }}
    >
      <Box
        sx={{
          width: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Box sx={{ p: 1 }}>
          <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 1 }}>
            <Typography variant="subtitle1">Driver Behaviour</Typography>
            <Box sx={{ flexGrow: 1 }} />
            <FormLabel component="legend">Emergency Equipment</FormLabel>
            <FormControl component="fieldset" sx={{ pr: 2, pl: 1 }}>
              <Select
                size="small"
                labelId="workaround" // otherwise it uses rechart's Box!!
                name="emergencyEquipment"
                value={emergencyEquipmentUsed}
                onChange={handleEmergencyEquipmentUsedChanged}
              >
                <MenuItem value={0}>No</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={2}>Yes & No</MenuItem>
              </Select>
            </FormControl>
            <Tooltip title={showFilter ? 'Hide filter' : 'Show filter'}>
              <IconButton onClick={() => handleFilterToggle()}>
                <FilterListIcon color={showFilter ? 'primary' : 'inherit'} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download data">
              <Box component="span">
                <IconButton
                  disabled={sortedDrivers.length === 0}
                  onClick={() =>
                    downloadCSV(
                      sortedDrivers,
                      'Driver Scores.csv',
                      getDriverDownloadHeaders(filterValues.areas),
                    )
                  }
                >
                  <GetAppIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Toolbar>
          <Collapse in={showFilter} timeout="auto">
            <Stack spacing={1} sx={{ flex: 1, p: 1 }}>
              <SelectMultiple
                label="Name"
                placeholder="Select..."
                value={filter.name}
                labelValue
                onChange={(value) => handleFilterFieldChanged('name', value)}
                suggestions={filterValues.name.map((value) => ({
                  label: value,
                  value,
                }))}
              />
              <SelectMultiple
                label="Collar Number"
                placeholder="Select..."
                value={filter.collarNumber}
                labelValue
                onChange={(value) =>
                  handleFilterFieldChanged('collarNumber', value)
                }
                suggestions={filterValues.collarNumber.map((value) => ({
                  label: value,
                  value,
                }))}
              />
              <SelectMultiple
                label="Role"
                placeholder="Select..."
                value={filter.role}
                labelValue
                onChange={(value) => handleFilterFieldChanged('role', value)}
                suggestions={filterValues.role.map((value) => ({
                  label: value,
                  value,
                }))}
              />
              {Object.entries(personGroups).map(([key, { label, values }]) => {
                return (
                  <SelectMultiple
                    key={key}
                    label={label}
                    placeholder="Select..."
                    value={filter.groups[key] || []}
                    labelValue
                    onChange={(value) => handleFilterFieldChanged(key, value)}
                    suggestions={values}
                  />
                );
              })}
            </Stack>
          </Collapse>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={4} sm={2}>
                <Card sx={{ flex: 1 }}>
                  <CardHeader
                    avatar={
                      <Avatar>
                        <StarIcon />
                      </Avatar>
                    }
                    title="Score"
                  />
                  <CardContent>
                    <Typography
                      component="h1"
                      variant="h4"
                      align="right"
                      style={{
                        color: scoreColour(totals.averageScore),
                      }}
                    >
                      {`${round(totals.averageScore, 2)}%`}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4} sm={2}>
                <Card sx={{ flex: 1 }}>
                  <CardHeader
                    avatar={
                      <Avatar>
                        <SteeringIcon />
                      </Avatar>
                    }
                    title="Driving"
                  />
                  <CardContent>
                    <Typography component="h1" variant="h4" align="right">
                      {formatSeconds(totals.drivingSeconds)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4} sm={2}>
                <Card sx={{ flex: 1 }}>
                  <CardHeader
                    avatar={
                      <Avatar>
                        <SpeedometerIcon />
                      </Avatar>
                    }
                    title="Harsh Acceleration"
                  />
                  <CardContent>
                    <Typography component="h1" variant="h4" align="right">
                      {formatSeconds(totals.harshAccelerationSeconds)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4} sm={2}>
                <Card sx={{ flex: 1 }}>
                  <CardHeader
                    avatar={
                      <Avatar>
                        <CarBrakeAlertIcon />
                      </Avatar>
                    }
                    title="Harsh Braking"
                  />
                  <CardContent>
                    <Typography component="h1" variant="h4" align="right">
                      {formatSeconds(totals.harshBrakingSeconds)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4} sm={2}>
                <Card sx={{ flex: 1 }}>
                  <CardHeader
                    avatar={
                      <Avatar>
                        <CallMissedOutgoingIcon />
                      </Avatar>
                    }
                    title="Harsh Cornering"
                  />
                  <CardContent>
                    <Typography component="h1" variant="h4" align="right">
                      {formatSeconds(totals.harshCorneringSeconds)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4} sm={2}>
                <Card sx={{ flex: 1 }}>
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          borderWidth: 5,
                          borderColor: grey[400],
                          borderStyle: 'solid',
                          bgcolor: 'transparent',
                          color: 'common.black',
                        }}
                      >
                        60
                      </Avatar>
                    }
                    title="Speeding"
                  />
                  <CardContent>
                    <Typography component="h1" variant="h4" align="right">
                      {formatSeconds(totals.speedInfractionDurationSeconds)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card sx={{ minHeight: 480, flex: 1 }}>
                  <CardHeader
                    avatar={
                      <Avatar>
                        <TrendingUpIcon />
                      </Avatar>
                    }
                    title="Score Trend"
                    subheader={<Breadcrumbs>{getBreadcrumbs()}</Breadcrumbs>}
                  />
                  <CardContent sx={{ position: 'relative' }}>
                    {isLoading && (
                      <Box
                        sx={{
                          position: 'absolute',
                          width: 1,
                          height: 1,
                          top: 0,
                          left: 0,
                          backgroundColor: 'background.paper',
                          zIndex: 20,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <CircularProgress
                          sx={{ m: 1 }}
                          size={32}
                          thickness={6}
                        />
                      </Box>
                    )}
                    {error || individualError ? (
                      showReloadButton()
                    ) : (
                      <ResponsiveContainer width="100%" height={400}>
                        <BarChart data={trend} onClick={handleBarClick}>
                          <XAxis
                            dataKey="label"
                            tickFormatter={tickFormatter}
                          />
                          {/* <YAxis domain={['auto', 100]}/> */}
                          {/* <YAxis domain={['auto', 100]}/> */}
                          <YAxis domain={yAxisDomain} />
                          <ChartTooltip
                            content={<CustomTooltip />}
                            formatter={(value) => `${round(value, 2)}%`}
                          />
                          <Bar dataKey="Average" name="Average score">
                            {trend.map((entry, index) =>
                              ScoreColourCell(entry.Average, index),
                            )}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    )}
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Card sx={{ height: 767 }}>
                  <CardHeader
                    avatar={
                      <Avatar>
                        <PersonIcon />
                      </Avatar>
                    }
                    title="Drivers"
                  />
                  <Box
                    ref={parentRef}
                    sx={{ overflow: 'auto', height: 'calc(100% - 72px)' }}
                  >
                    <Box
                      sx={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                        width: '100%',
                        position: 'relative',
                      }}
                    >
                      {rowVirtualizer
                        .getVirtualItems()
                        .map(({ index, size, start }) => {
                          const item = sortedDrivers[index];

                          return (
                            <ListItemButton
                              selected={selectedDriver === item}
                              dense
                              key={index}
                              sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: `${size}px`,
                                transform: `translateY(${start}px)`,
                              }}
                              onClick={handleSelectedDriverChange(index)}
                            >
                              <ListItemAvatar>
                                <Avatar src={item.picture}>
                                  <PersonIcon />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={item.name}
                                secondary={item.collarNumber}
                                primaryTypographyProps={{ noWrap: true }}
                              />
                              <Typography
                                align="right"
                                style={{
                                  color: scoreColour(item.averageScore),
                                }}
                              >
                                {`${round(item.averageScore, 2)}%`}
                              </Typography>
                            </ListItemButton>
                          );
                        })}
                    </Box>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} sm={8}>
                {selectedAggregatedTrips ? (
                  <Card>
                    <CardHeader
                      avatar={
                        <IconButton
                          onClick={() => deselectAggregatedTrips()}
                          size="large"
                        >
                          <BackIcon />
                        </IconButton>
                      }
                      title={`${selectedAggregatedTrips.label} Trips`}
                    />
                    <CardContent>
                      {individualIsLoading ||
                      (individualTrips.length > 0 &&
                        individualTrips[0].code !== selectedDriver.code) ? (
                        <CircularProgress
                          sx={{ m: 1 }}
                          size={16}
                          thickness={6}
                        />
                      ) : (
                        <Fragment>
                          <Table
                            data={individualTrips}
                            headers={individualTripsHeaders}
                            defaultPageSize={10}
                            className="-highlight"
                            sx={{ bgcolor: 'background.paper' }}
                            rowsPerPage={rowsPerTripsPage}
                            page={tripsPage}
                            order={tripOrder}
                            orderBy={tripOrderBy}
                            onOrderChange={handleTripOrderChange}
                            onOrderByChange={handleTripOrderByChange}
                          />
                          <TablePagination
                            rowsPerPageOptions={[rowsPerTripsPage]}
                            component="div"
                            count={individualTrips.length}
                            rowsPerPage={rowsPerTripsPage}
                            page={tripsPage}
                            onPageChange={handleChangeTripsPage}
                          />
                        </Fragment>
                      )}
                    </CardContent>
                  </Card>
                ) : (
                  <Card sx={{ height: 767 }}>
                    <CardHeader
                      avatar={
                        <Avatar>
                          <CarTractionControlIcon />
                        </Avatar>
                      }
                      title={
                        selectedDriver
                          ? `${selectedDriver.name}, ${selectedDriver.collarNumber}`
                          : 'Behaviour Breakdown'
                      }
                      subheader={<Breadcrumbs>{getBreadcrumbs()}</Breadcrumbs>}
                    />
                    <CardContent sx={{ position: 'relative' }}>
                      {trendVsDriver && (
                        <Fragment>
                          {isLoading ? (
                            <Box
                              sx={{
                                position: 'absolute',
                                width: 1,
                                height: 1,
                                top: 0,
                                left: 0,
                                backgroundColor: 'background.paper',
                                zIndex: 20,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <CircularProgress
                                sx={{ m: 1 }}
                                size={32}
                                thickness={6}
                              />
                            </Box>
                          ) : (
                            ''
                          )}
                          <ResponsiveContainer width="100%" height={200}>
                            <ComposedChart
                              data={trendVsDriver}
                              onClick={handleBarClick}
                              style={{ cursor: isLoading ? 'wait' : '' }}
                            >
                              <XAxis
                                dataKey="label"
                                tickFormatter={tickFormatter}
                                scale="band"
                              />
                              <YAxis domain={yAxisDomain} />
                              <Tooltip
                                content={<CustomTooltip />}
                                formatter={(value) => `${round(value, 2)}%`}
                              />
                              <Bar dataKey="driverAverage" name="Driver score">
                                {trendVsDriver.map((entry, index) =>
                                  ScoreColourCell(entry.driverAverage, index),
                                )}
                              </Bar>
                              <Line
                                dataKey="overallAverage"
                                name="Average score"
                                stroke="rgba(85, 85, 85, .625)"
                                strokeWidth={1}
                                dot={false}
                              />
                            </ComposedChart>
                          </ResponsiveContainer>
                        </Fragment>
                      )}
                      {selectedDriver && (
                        <Fragment>
                          <Table
                            data={selectedDriver.aggregatedTrips.sort(
                              (a, b) => a.startTime - b.startTime,
                            )}
                            headers={aggregatedTripHeaders}
                            defaultPageSize={10}
                            className="-highlight"
                            sx={{ bgcolor: 'background.paper' }}
                            onSelectClick={handleClickAggregatedTrips}
                            selectMode="single"
                            rowsPerPage={rowsPerDriverPage}
                            page={driverPage}
                            order={driverOrder}
                            orderBy={driverOrderBy}
                            onOrderChange={handleDriverOrderChange}
                            onOrderByChange={handleDriverOrderByChange}
                          />
                          <TablePagination
                            rowsPerPageOptions={[rowsPerDriverPage]}
                            component="div"
                            count={selectedDriver.aggregatedTrips.length}
                            rowsPerPage={rowsPerDriverPage}
                            page={driverPage}
                            onPageChange={handleChangeDriverPage}
                          />
                        </Fragment>
                      )}
                    </CardContent>
                  </Card>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

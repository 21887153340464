import { BoundaryEditorController, BoundaryMap } from '@/components/controls';
import { useBoundary } from '@/hooks';
import { Fragment } from 'react';
import { MicrobeatsController } from './MicrobeatsController';

function Boundary({ type, id }) {
  const { data: boundary } = useBoundary(type, id);

  return boundary ? <BoundaryMap boundary={boundary} /> : <Fragment />;
}

export function BoundaryViewer({ control, type, id, disabled }) {
  switch (type) {
    case 'Location':
      return <Boundary type={type} id={id} />;
    case 'Microbeats':
      return (
        <MicrobeatsController
          name="microbeats"
          control={control}
          disabled={disabled}
          rules={{ required: 'Required' }}
        />
      );
    case 'Custom':
      return (
        <BoundaryEditorController
          name="boundary"
          control={control}
          disabled={disabled}
        />
      );
    default:
      return <Fragment />;
  }
}

import { Box, List, MenuItem } from '@mui/material';
import _ from 'lodash';
import {
  Group as LayerGroup,
  Image as ImageLayer,
  Tile as TileLayer,
} from 'ol/layer';

function getMapLayers(mapRef) {
  return (
    mapRef.current
      ?.getLayers()
      ?.getArray()
      ?.filter(
        (layer) =>
          layer instanceof TileLayer ||
          layer instanceof ImageLayer ||
          layer instanceof LayerGroup,
      )
      ?.filter((layer) => layer.get('title')) ?? []
  );
}

export function MapLayersList({
  mapRef,
  mapLayers,
  mapStyle,
  onMapStyleChange,
}) {
  mapLayers = mapLayers ?? getMapLayers(mapRef);

  const handleMapStyleChange = (newStyle) => {
    onMapStyleChange(newStyle);

    mapLayers.forEach((element) => {
      let baseLayerTitle = element.get('title');
      element.setVisible(baseLayerTitle === newStyle);
    });
  };

  mapStyle =
    mapStyle ?? _.find(mapLayers, (layer) => layer.getVisible())?.get('title');

  return (
    <Box>
      {mapLayers.length > 1 && (
        <List>
          {mapLayers.map((tileLayer) => {
            const title = tileLayer.get('title');

            return (
              <MenuItem
                key={title}
                selected={title === mapStyle}
                onClick={() => handleMapStyleChange(title)}
              >
                {title}
              </MenuItem>
            );
          })}
        </List>
      )}
    </Box>
  );
}

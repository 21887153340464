import { Place as PlaceIcon } from '@mui/icons-material';
import { Avatar, Card, CardHeader, Skeleton, useTheme } from '@mui/material';
import { AccelerometerEvent } from './AccelerometerEvent';
import { Area } from './Area';
import { Crime } from './Crime';
import { Cluster } from './Cluster';
import { FileItemDetails } from './FileItemDetails';
import { Incident } from './Incident';
import { Location } from './Location';
import { PersonPoll } from './PersonPoll';
import { PersonTrail } from './PersonTrail';
import { PersonVisit } from './PersonVisit';
import { StopCheck } from './StopCheck';
import { SpeedInfraction } from './SpeedInfraction';
import { VehicleIdle } from './VehicleIdle';
import { VehiclePoll } from './VehiclePoll';
import { VehicleStop } from './VehicleStop';
import { VehicleTrip } from './VehicleTrip';
import { VehicleVisit } from './VehicleVisit';
import { useDocumentTitle } from '@/hooks';

export function Item({
  item,
  colors,
  isLoading,
  primaryItemKey,
  secondaryItemKey,
}) {
  useDocumentTitle(`IR3 | Item${item?.id ? ` | ${item.id}` : ''}`);
  const theme = useTheme();

  const background =
    colors.length > 1
      ? `linear-gradient(${colors.join()})`
      : colors[0] || theme.palette.grey[500];
  const color = theme.palette.getContrastText(
    colors[Math.floor(colors.length / 2)] || theme.palette.grey[500],
  );
  const props = {
    ...item,
    background,
    color,
  };
  if (isLoading) {
    return (
      <Card sx={{ margin: [1, 0.5, 1, 1] }}>
        <CardHeader
          avatar={<Skeleton variant="circular" width={40} height={40} />}
          title={<Skeleton height={12} width="60%" />}
          subheader={<Skeleton height={12} width="40%" />}
        />
      </Card>
    );
  }

  switch (props.itemType) {
    case 'area':
      return <Area {...props} />;
    case 'incident':
      return <Incident {...props} />;
    case 'personPoll':
      return <PersonPoll {...props} />;
    case 'clusters':
      return <Cluster {...props} />;
    case 'personTrail':
      return <PersonTrail {...props} />;
    case 'personVisit':
    case 'personCustomVisit':
      return <PersonVisit {...props} />;
    case 'vehicleIdle':
      return <VehicleIdle {...props} />;
    case 'vehiclePoll':
      return <VehiclePoll {...props} />;
    case 'vehicleStop':
      return <VehicleStop {...props} />;
    case 'vehicleTrip':
      return <VehicleTrip {...props} />;
    case 'speedInfraction':
      return <SpeedInfraction {...props} />;
    case 'vehicleCustomVisit':
    case 'vehicleVisit':
      return <VehicleVisit {...props} />;
    case 'accelerometerEvent':
      return <AccelerometerEvent {...props} />;
    case 'location':
      return <Location {...props} />;
    case 'crime':
      return <Crime {...props} />;
    case 'stopCheck':
      return <StopCheck {...props} />;
    case 'file':
      return (
        <FileItemDetails
          primaryItemKey={primaryItemKey}
          secondaryItemKey={secondaryItemKey}
          itemData={item.itemData}
        />
      );
    default:
      return (
        <Card sx={{ margin: [1, 0.5, 1, 1] }}>
          <CardHeader
            avatar={
              <Avatar
                style={{
                  background,
                  color,
                }}
              >
                <PlaceIcon />
              </Avatar>
            }
            title={item ? item.id : ''}
          />
        </Card>
      );
  }
}

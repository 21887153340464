import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useBurglaries(options) {
  const searchParams = JSON.parse(JSON.stringify(options ?? {}));

  return useQuery({
    queryKey: ['burglaries', searchParams],
    queryFn: () => {
      return briefingApi
        .get('niche/occurrences/burglaries', {
          searchParams,
          timeout: false,
        })
        .json();
    },
    placeholderData: [],
    staleTime: 55 * (60 * 1000),
    cacheTime: 60 * (60 * 1000),
  });
}

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

export function CustomTooltip({
  active,
  payload,
  label,
  unit,
  height,
  variant,
  labelFormatter,
  valueFormatter,
  captureMouseMove,
  sx,
}) {
  function handleMouseMove(event) {
    if (captureMouseMove) {
      event.stopPropagation();
    }
  }

  if (active) {
    return (
      <Paper
        onMouseMove={handleMouseMove}
        sx={{ height, overflowY: height ? 'auto' : undefined, ...sx }}
        variant={variant}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                {labelFormatter ? labelFormatter(label) : label}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...(payload ?? [])].map((row) => (
              <TableRow key={row.name}>
                <TableCell>
                  <Typography
                    variant="caption"
                    sx={{ color: row.stroke || row.fill }}
                  >
                    {row.name}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    color: row.stroke || row.fill,
                    textAlign: 'right',
                  }}
                >
                  <Typography variant="caption">{`${
                    valueFormatter ? valueFormatter(row.value) : row.value
                  }${
                    unit
                      ? ` ${row.value === 1 ? unit.replace(/s+$/, '') : unit}`
                      : ''
                  }`}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }

  return null;
}

import {
  EventPreviewMap,
  Parameters,
  Table,
  TablePagination,
} from '@/components/controls';
import { useAggregate, useDocumentTitle } from '@/hooks';
import { isEmpty } from '@/utils';
import { events, rowsPerPageOptions } from '@/utils/config';
import { Box, Paper, Toolbar, Typography } from '@mui/material';
import { atom, useAtom } from 'jotai';
import { useMemo } from 'react';
import { downloadPipelineFn, headers, pipelineFn } from './utils';

const {
  eventFilters: { idles: eventFilters },
} = events;

function Event({ entry }) {
  return (
    <Box sx={{ display: 'flex', px: 2, pb: 2 }}>
      <Box sx={{ width: 320, height: 320 }}>
        {entry.point && <EventPreviewMap point={entry.point} mapType="idles" />}
      </Box>
    </Box>
  );
}

const stateAtom = atom({
  orderBy: 'startTime',
  order: 'asc',
  page: 0,
  rowsPerPage: rowsPerPageOptions[0],
  query: {},
  parameters: {},
});

export function Idles() {
  useDocumentTitle('IR3 | Idles');
  const [{ orderBy, order, page, rowsPerPage, query, parameters }, setState] =
    useAtom(stateAtom);
  const collection = 'idles';
  const pipeline = useMemo(
    () => pipelineFn(orderBy, order, page, rowsPerPage, query),
    [orderBy, order, page, rowsPerPage, query],
  );
  const totalsPipeline = [
    { $match: query },
    {
      $group: {
        _id: null,
        total: { $sum: 1 },
        durationSeconds: { $sum: '$durationSeconds' },
      },
    },
    { $unset: '_id' },
  ];
  const { data, isFetching, cancel } = useAggregate(
    collection,
    pipeline,
    !isEmpty(query),
  );
  const {
    data: aggregated,
    isFetching: totalsFetching,
    cancel: cancelTotals,
  } = useAggregate(collection, totalsPipeline, !isEmpty(query), true);
  const { total, ...totals } = aggregated[0] || {};
  const csvColumns = headers.map(({ label, key }) => ({
    header: label,
    key: key,
  }));

  function handleCancel() {
    cancel();
    cancelTotals();
  }

  const handleStateChange = (name) => (value) => {
    setState((state) => ({ ...state, [name]: value }));
  };

  const handleStateChangeWithEvent = (name) => (event, value) => {
    setState((state) => ({ ...state, [name]: value }));
  };

  function handleRowsPerPageChange(event) {
    setState((state) => ({
      ...state,
      rowsPerPage: event.target.value,
      page: 0,
    }));
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 48px)',
        overflow: 'hidden',
        backgroundColor: 'background.default',
      }}
    >
      <Parameters
        collection={collection}
        onFetch={handleStateChangeWithEvent('query')}
        onCancel={handleCancel}
        isFetching={isFetching || totalsFetching}
        value={parameters}
        onChange={handleStateChange('parameters')}
        sx={{ mt: 1, width: 264 }}
        vehicle
        driver
        eventFilters={eventFilters}
        pipelineFn={downloadPipelineFn}
        columns={csvColumns}
      />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            Idles
          </Typography>
        </Toolbar>
        <Paper sx={{ m: [0, 1, 1], minWidth: 240 }}>
          <Box sx={isFetching ? { opacity: 0.5 } : undefined}>
            <Table
              styles={{
                tableContainer: {
                  height: 'calc(100vh - 172px)',
                  overflowY: 'scroll',
                },
                table: {
                  minWidth: 750,
                },
              }}
              data={data}
              headers={[
                {
                  label: '',
                  key: 'expand',
                  type: 'expand',
                  component: Event,
                },
                ...headers,
              ]}
              rowsPerPage={rowsPerPage}
              page={0} //{filter.page} // server-side pagination will fake the page we're on
              keyName="identifier"
              order={order}
              orderBy={orderBy}
              onOrderChange={handleStateChange('order')}
              onOrderByChange={handleStateChange('orderBy')}
              totals={totals}
            />
          </Box>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={total ?? 0}
            rowsPerPage={rowsPerPage}
            page={total ? page : 0}
            onPageChange={handleStateChangeWithEvent('page')}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </Box>
    </Box>
  );
}

import { Typography, Box, Divider } from '@mui/material';
import { Fragment } from 'react';
import { DateTimeField, Field, DebouncedTextField } from '../fields';

export function InteCrimeSourceFields({ name, value }) {
  return (
    <Fragment>
      <Typography variant="subtitle2" color="textSecondary">
        Reported Time
      </Typography>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'spaceAround',
            flexDirection: 'row',
            mt: 1,
            pr: 1,
          }}
        >
          <Box>
            <Field
              label="Start"
              name={`${name}.crimeSourceFields.reportedStartTime`}
              component={DateTimeField}
              maxDate={value.crime?.reportedStartTime || '2100-01-01'}
            />
          </Box>
          <Box sx={{ mx: 1 }}>
            <Field
              label="End"
              name={`${name}.crimeSourceFields.reportedEndTime`}
              component={DateTimeField}
              minDate={value.crime?.reportedEndTime || '1900-01-01'}
            />
          </Box>
        </Box>
        <Divider style={{ marginTop: 16, marginBottom: 8 }} />
        <Field
          fullWidth
          margin="dense"
          label="Ward"
          name={`${name}.crimeSourceFields.ward`}
          component={DebouncedTextField}
        />
        <Field
          fullWidth
          margin="dense"
          label="Beat"
          name={`${name}.crimeSourceFields.beat`}
          component={DebouncedTextField}
        />
        <Field
          fullWidth
          margin="dense"
          label="Sector"
          name={`${name}.crimeSourceFields.sector`}
          component={DebouncedTextField}
        />
        <Field
          fullWidth
          margin="dense"
          label="Section"
          name={`${name}.crimeSourceFields.section`}
          component={DebouncedTextField}
        />
        <Field
          fullWidth
          margin="dense"
          label="BCU"
          name={`${name}.crimeSourceFields.bcu`}
          component={DebouncedTextField}
        />
        <Field
          fullWidth
          margin="dense"
          label="Force"
          name={`${name}.crimeSourceFields.force`}
          component={DebouncedTextField}
        />
        <Field
          fullWidth
          margin="dense"
          label="Local Authority"
          name={`${name}.crimeSourceFields.localAuthority`}
          component={DebouncedTextField}
        />
      </Box>
    </Fragment>
  );
}

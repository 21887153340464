import { useDocumentTitle } from '@/hooks';
import { Box, Typography } from '@mui/material';

export function Unauthorised() {
  useDocumentTitle('IR3 | Unauthorised');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 96px)',
        flex: 1,
      }}
    >
      <Box sx={{ mt: 8, mb: 2 }}>
        <Box sx={{ maxWidth: 600, margin: [0, 'auto'], p: [8, 0, 6] }}>
          <Typography
            variant="h6"
            align="center"
            color="textSecondary"
            component="p"
          >
            You have been logged out due to inactivity.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

import {
  FETCH_PERSON_DAILY_ACTIVITY,
  FETCH_PERSON_DAILY_ACTIVITY_CANCELLED,
  FETCH_PERSON_DAILY_ACTIVITY_FAILURE,
  FETCH_PERSON_DAILY_ACTIVITY_SUCCESS,
  FETCH_PERSON_HOURLY_ACTIVITY,
  FETCH_PERSON_HOURLY_ACTIVITY_CANCELLED,
  FETCH_PERSON_HOURLY_ACTIVITY_FAILURE,
  FETCH_PERSON_HOURLY_ACTIVITY_SUCCESS,
  FILTER_PERSON_DAILY_ACTIVITY,
  LOAD_PERSON_DAILY_ACTIVITY_QUERY_FAILURE,
  LOAD_PERSON_DAILY_ACTIVITY_QUERY_SUCCESS,
  LOAD_PERSON_HOURLY_ACTIVITY,
  LOAD_PERSON_HOURLY_ACTIVITY_FAILURE,
  LOAD_PERSON_HOURLY_ACTIVITY_SUCCESS,
  UPDATE_PERSON_DAILY_ACTIVITY_PARAMETERS,
  UPDATE_PERSON_HOURLY_ACTIVITY_PARAMETERS,
} from '@/actions';
import { reports } from '@/utils/config';

const hours = [...Array(24).keys()];
const days = [...Array(7).keys()];

const INITIAL_STATE = {
  daily: {
    data: [],
    groupBy: 'all',
    groupByValues: ['all', 'date'],
    orderBy: 'group',
    orderByValues: [
      'onRadio',
      'driving',
      'inBase',
      'inHomeWard',
      'respondingToIncidents',
      'attendingObjectives',
      'doubleCrewing',
    ],
    chartType:
      reports?.vehicles?.daily?.defaultChartType ??
      reports?.people?.daily?.defaultChartType ??
      'hours',
    filter: {
      code: [],
      name: [],
      role: [],
      groups: {},
      hour: hours,
      day: days,
    },
    parameters: {},
    query: {},
    filterValues: {
      code: [],
      name: [],
      role: [],
      groups: {},
    },
    isLoading: false,
    isFiltering: false,
    error: null,
  },
  hourly: {
    data: [],
    filter: {
      code: [],
      name: [],
      role: [],
      groups: {},
    },
    parameters: {},
    query: {},
    filterValues: {
      code: [],
      name: [],
      role: [],
      groups: {},
      hour: hours,
      day: days,
    },
    isLoading: false,
    error: null,
  },
};

export default function reportsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PERSON_DAILY_ACTIVITY:
    case FILTER_PERSON_DAILY_ACTIVITY:
      return {
        ...state,
        daily: {
          ...state.daily,
          isLoading: action.type === FETCH_PERSON_DAILY_ACTIVITY,
          isFiltering: action.type === FILTER_PERSON_DAILY_ACTIVITY,
          error: null,
        },
      };
    case FETCH_PERSON_DAILY_ACTIVITY_SUCCESS:
    case LOAD_PERSON_DAILY_ACTIVITY_QUERY_SUCCESS:
      return {
        ...state,
        daily: {
          ...state.daily,
          ...action.payload,
          isLoading: false,
          isFiltering: false,
          error: null,
        },
      };
    case FETCH_PERSON_DAILY_ACTIVITY_FAILURE:
    case LOAD_PERSON_DAILY_ACTIVITY_QUERY_FAILURE:
      return {
        ...state,
        daily: {
          ...state.daily,
          isLoading: false,
          isFiltering: false,
          error: action.payload,
        },
      };
    case FETCH_PERSON_DAILY_ACTIVITY_CANCELLED:
      return {
        ...state,
        daily: {
          ...state.daily,
          isLoading: false,
        },
      };
    case UPDATE_PERSON_DAILY_ACTIVITY_PARAMETERS:
      return {
        ...state,
        daily: {
          ...state.daily,
          parameters: action.payload,
        },
      };
    case FETCH_PERSON_HOURLY_ACTIVITY:
    case LOAD_PERSON_HOURLY_ACTIVITY:
      return {
        ...state,
        hourly: {
          ...state.hourly,
          isLoading: true,
          error: null,
        },
      };
    case FETCH_PERSON_HOURLY_ACTIVITY_SUCCESS:
    case LOAD_PERSON_HOURLY_ACTIVITY_SUCCESS:
      return {
        ...state,
        hourly: { ...action.payload, isLoading: false, error: null },
      };
    case FETCH_PERSON_HOURLY_ACTIVITY_FAILURE:
    case LOAD_PERSON_HOURLY_ACTIVITY_FAILURE:
      return {
        ...state,
        hourly: {
          ...state.hourly,
          isLoading: false,
          error: action.payload,
        },
      };
    case FETCH_PERSON_HOURLY_ACTIVITY_CANCELLED:
      return {
        ...state,
        hourly: {
          ...state.hourly,
          isLoading: false,
        },
      };
    case UPDATE_PERSON_HOURLY_ACTIVITY_PARAMETERS:
      return {
        ...state,
        hourly: {
          ...state.hourly,
          parameters: action.payload,
        },
      };
    default:
      return state;
  }
}

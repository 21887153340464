import { DateTimePicker } from '@mui/x-date-pickers';
import { useController } from 'react-hook-form';

export function DateTimePickerController({
  name,
  control,
  disabled,
  rules,
  label,
  minDate,
  maxDate,
  sx,
}) {
  const {
    field: { value, disabled: isDisabled, onChange, onBlur, ref },
    fieldState: { error, invalid },
  } = useController({
    name,
    control,
    rules,
    defaultValue: null,
    disabled,
  });

  return (
    <DateTimePicker
      inputRef={ref}
      value={value}
      onChange={onChange}
      disabled={isDisabled}
      label={label}
      minDate={minDate}
      maxDate={maxDate}
      ampm={false}
      sx={{ minWidth: 240, ...sx }}
      slotProps={{
        textField: {
          size: 'small',
          error: invalid,
          helperText: error?.message,
          onBlur,
        },
      }}
    />
  );
}

import { briefingApi } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertUrgentAction() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (json) => {
      if (json.code) {
        return briefingApi.patch(`urgentActions/${json.code}`, { json }).json();
      } else {
        return briefingApi.post('urgentActions', { json }).json();
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['urgentActions'] }),
  });
}

import { useMap } from 'react-map-gl/maplibre';

export function MapImage({ name, url, sdf }) {
  const { current: map } = useMap();

  if (!map.hasImage(name)) {
    map.loadImage(url, (error, image) => {
      if (error) {
        throw error;
      }
      if (!map.hasImage(name)) {
        map.addImage(name, image, { sdf });
      }
    });
  }

  return null;
}

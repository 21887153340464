import { Avatar, TableCell, TableRow, Typography } from '@mui/material';
import defaultPersonImage from '@/img/defaultPhotoImage.jpeg';
import { useAssociatedPerson } from '@/hooks';
import { Fragment } from 'react';

export function CrimePerson({ person }) {
  const { data: associatedPersonWithImage } = useAssociatedPerson(person?.id);

  if (
    associatedPersonWithImage &&
    associatedPersonWithImage.length > 0 &&
    associatedPersonWithImage[0]?.person?.image
  ) {
    person.imageId = associatedPersonWithImage[0]?.person?.imageId;
    person.imageType = associatedPersonWithImage[0].person?.imageType;
    person.image = associatedPersonWithImage[0].person?.image;
  }

  return (
    <TableRow key={person.id}>
      <TableCell>
        {person.image && (
          <Avatar
            sx={{
              width: 150,
              height: 200,
            }}
            alt="photo"
            src={person.image}
          />
        )}
        {!person.image && (
          <Fragment>
            <Avatar
              sx={{
                width: 150,
                height: 150,
              }}
              alt="Image not available"
              src={defaultPersonImage}
            />
          </Fragment>
        )}
      </TableCell>
      <TableCell>
        <Typography variant="caption">
          {`Name: ${person?.firstName}  ${person?.surname},  status: ${person.classificatioDescription}`}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

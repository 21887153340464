import { Dialog, DialogContent, DialogContentText } from '@mui/material';

export function LoadingDialog({ isOpen }) {
  return (
    <Dialog open={isOpen}>
      <DialogContent>
        <DialogContentText>Loading...</DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

import { del, get, set } from 'idb-keyval';

export function createIDBPersister(idbValidKey = 'reactQuery') {
  return {
    persistClient: async (client) => {
      set(idbValidKey, client);
    },
    restoreClient: async () => {
      return await get(idbValidKey);
    },
    removeClient: async () => {
      await del(idbValidKey);
    },
  };
}

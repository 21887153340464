import { api } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useRemoveOptionValue() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ name, value, entry }) => {
      return api.delete(`options/${name}-${value}/values/${entry}`).json();
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['options'] }),
  });
}

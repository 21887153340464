import { ExportPicker, RetrospectiveTypeIcon } from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import {
  getPersonPolls,
  radioPollHeaders,
  shortPersonHeaders,
  startCase,
} from '@/utils';
import {
  Flag as FlagIcon,
  OutlinedFlag as OutlinedFlagIcon,
  PlayArrow as PlayArrowIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import humanizeDuration from 'humanize-duration';
import { Fragment } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getPrimaryText, getSecondaryText } from '../constants';
import { LocationTypeIcon } from './LocationTypeIcon';

export function PersonVisit({
  color,
  background,
  identifier,
  person,
  visitLocation,
  startTime,
  endTime,
  trails,
}) {
  const navigate = useNavigate();
  const { id, layerIndex, itemIndex } = useParams();

  function handleViewClick() {
    navigate(`/eventreplay/personLocationIntersections/${identifier}`);
  }

  const csvHeaders = [...shortPersonHeaders, ...radioPollHeaders];

  async function fetchDataToConvert() {
    const polls = await getPersonPolls(person.radioSsi, startTime, endTime);

    const data = polls.map((feature) => ({
      ...feature,
      name: person.forenames + ' ' + person.surname,
      personRole: person.role,
      collarNumber: person.collarNumber,
      time: new Date(feature.time),
      locationType: visitLocation.type,
      locationName: visitLocation.name,
      radioSsi: feature.ssi,
      longitude: feature.position ? feature.position.coordinates[0] : 0,
      latitude: feature.position ? feature.position.coordinates[1] : 0,
    }));

    return data;
  }

  const cardHeaderButtons = (
    <Box>
      {identifier && (
        <Tooltip title="View Replay">
          <IconButton onClick={handleViewClick} size="large">
            <PlayArrowIcon />
          </IconButton>
        </Tooltip>
      )}
      <ExportPicker
        fetchDataToConvert={fetchDataToConvert}
        filename="Person Visit"
        headers={csvHeaders}
      />
    </Box>
  );

  const { title, subheader } = person?.unassociated
    ? {
        title: 'Unassociated SSI',
        subheader: person?.radioSsi,
      }
    : {
        title: `${person?.forenames || ''}  ${person?.surname || ''}`,
        subheader: person?.collarNumber,
      };
  useDocumentTitle(`IR3 | Person Visit | ${title}`);

  return (
    <Card sx={{ m: [1, 0.5, 1, 1] }}>
      <CardHeader
        avatar={
          <Tooltip title="Person Visit">
            <Avatar
              style={{
                background,
                color,
              }}
            >
              <RetrospectiveTypeIcon type="personVisits" />
            </Avatar>
          </Tooltip>
        }
        title={title}
        subheader={subheader}
        action={cardHeaderButtons}
      />
      <CardContent>
        {!!person.code && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Person
            </Typography>
            <Table size="small" sx={{ mt: 1, mb: 2 }}>
              <TableBody>
                {!!person.role && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        Role
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">{person.role}</Typography>
                    </TableCell>
                  </TableRow>
                )}
                {person.homeStation && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        Home Station
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">
                        {person.homeStation}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {Object.entries(person.groups ?? {}).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        {startCase(key)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">
                        {(value ?? []).join(', ')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Fragment>
        )}
        {visitLocation.name !== '<custom>' && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Location
            </Typography>
            <ListItem component="div" sx={{ my: 1 }}>
              <ListItemAvatar>
                <Tooltip title={visitLocation.type}>
                  <Avatar>
                    <LocationTypeIcon type={visitLocation.type} />
                  </Avatar>
                </Tooltip>
              </ListItemAvatar>
              <ListItemText
                primary={visitLocation.name}
                secondary={visitLocation.type}
              />
            </ListItem>
          </Fragment>
        )}
        <Typography variant="subtitle2" color="textSecondary">
          Timeline
        </Typography>
        <Stepper orientation="vertical" sx={{ mt: 2, ml: 2 }}>
          <Step active>
            <StepLabel
              icon={
                <Avatar sx={{ width: 30, height: 30 }}>
                  <FlagIcon fontSize="inherit" />
                </Avatar>
              }
              optional={
                <Typography variant="caption">
                  {format(new Date(startTime), 'dd/MM/yyyy HH:mm:ss')}
                </Typography>
              }
            >
              Start
            </StepLabel>
            <StepContent />
          </Step>
          <Step active>
            <StepLabel
              icon={
                <Avatar sx={{ width: 30, height: 30 }}>
                  <OutlinedFlagIcon fontSize="inherit" />
                </Avatar>
              }
              optional={
                <Typography variant="caption">
                  {format(new Date(endTime), 'dd/MM/yyyy HH:mm:ss')}
                </Typography>
              }
            >
              End
            </StepLabel>
            <StepContent />
          </Step>
        </Stepper>
        {trails?.length ? (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Trails
            </Typography>
            <List>
              {trails.map((trail, index) => {
                const feature = {
                  properties: {
                    ...trail,
                    source: 'personTrails',
                  },
                };

                return (
                  <ListItemButton
                    dense
                    key={index}
                    component={Link}
                    to={`/retrospective/${
                      id || 'untitled'
                    }/${layerIndex}/${itemIndex}/${index}`}
                  >
                    <ListItemAvatar>
                      <Avatar
                        style={{
                          background,
                          color,
                        }}
                      >
                        <RetrospectiveTypeIcon type={'personTrails'} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={getPrimaryText(feature)}
                      secondary={getSecondaryText(feature)}
                    />
                  </ListItemButton>
                );
              })}
            </List>
          </Fragment>
        ) : (
          <Fragment />
        )}
        <Typography variant="subtitle2" color="textSecondary">
          Metrics
        </Typography>
        <Table size="small" sx={{ mt: 1, mb: 2 }}>
          <TableBody>
            {/* <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Distance
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {`${round(distanceKilometres * 0.62137119, 2)} miles`}
                </Typography>
              </TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Duration
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {humanizeDuration(new Date(endTime) - new Date(startTime))}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

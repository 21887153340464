import { useVehicles } from '@/hooks';
import { getBackgroundColor, getHoverBackgroundColor } from '@/utils';
import { Done as DoneIcon } from '@mui/icons-material';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { MaterialReactTable } from 'material-react-table';

const columns = [
  {
    accessorKey: 'keyNumber',
    header: 'Key',
    size: 0,
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
  },
  { accessorKey: 'registration', header: 'Registration', size: 0 },
  { accessorKey: 'vehicleClass', header: 'Class', size: 0 },
  {
    accessorKey: 'lastWeeklyCheck',
    header: 'Last Weekly Check',
    size: 0,
    Cell: ({ cell }) =>
      cell.getValue()
        ? format(new Date(cell.getValue()), 'dd/MM/yyyy')
        : cell.getValue(),
  },
  { accessorKey: 'lastDriver', header: 'Last Driver', size: 0 },
  { accessorKey: 'remarks', header: 'Remarks', size: 0 },
  { accessorKey: 'defects', header: 'Defects', size: 0 },
];

export function Vehicles({ groupCodes, onComplete }) {
  const {
    data: vehicles,
    isLoading,
    isFetching,
  } = useVehicles({ groups: groupCodes });

  function getRowStyle(status) {
    switch (status) {
      case 'scheduled':
        return {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
          '&:hover': {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
              ),
          },
          '&:last-child td, &:last-child th': { border: 0 },
        };
      case 'workshop':
        return {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.error.main, theme.palette.mode),
          '&:hover': {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
              ),
          },
          '&:last-child td, &:last-child th': { border: 0 },
        };
    }
  }

  function handleCompleteClick() {
    onComplete();
  }

  return (
    <MaterialReactTable
      renderTopToolbarCustomActions={() => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h6" sx={{ pl: 1 }}>
              Vehicles
            </Typography>
            <Box>
              <Tooltip title="Done" placement="right">
                <IconButton onClick={handleCompleteClick}>
                  <DoneIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
        );
      }}
      enableDensityToggle={false}
      enableFullScreenToggle={false}
      enablePagination={false}
      enableBottomToolbar={false}
      columns={columns}
      data={vehicles}
      state={{ isLoading: isLoading || isFetching }}
      muiTablePaperProps={{ variant: 'outlined', elevation: 0 }}
      muiTableBodyRowProps={({ row }) => ({
        hover: !row.original.status,
        sx: getRowStyle(row.original.status),
      })}
      muiSearchTextFieldProps={{
        variant: 'outlined',
        size: 'small',
      }}
      initialState={{
        density: 'compact',
      }}
      muiTableContainerProps={{ sx: { maxHeight: 'calc(100vh - 124px)' } }}
    />
  );
}

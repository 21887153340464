import { parseIfJSON } from '@/utils';
import { Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Popup } from 'react-map-gl/maplibre';
import { dateTimeSecondsFormat } from '..';
import { Status } from './Status';

export function AccelerometerEventPopup({
  hoverInfo: {
    longitude,
    latitude,
    feature: { properties },
  },
}) {
  const vehicle = properties.vehicle ? parseIfJSON(properties.vehicle) : null;

  return (
    <Popup
      longitude={longitude}
      latitude={latitude}
      offset={[0, -16]}
      closeButton={false}
    >
      <Stack spacing={1}>
        <Typography variant="subtitle2">Accelerometer Event</Typography>
        <Typography variant="caption">
          {vehicle?.registrationNumber ?? properties.imei}
        </Typography>
        <Typography variant="caption">
          {format(new Date(properties.startTime), dateTimeSecondsFormat)}
        </Typography>
        <Status status={properties.status} />
      </Stack>
    </Popup>
  );
}

import { useResponseTimesByHour } from '@/hooks';
import { humanizeHours, randomHsl } from '@/utils';
import { highlights } from '@/utils/config';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Fragment, useState } from 'react';
import {
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CustomTooltip } from '../controls';

const {
  responseTimeByHour: { lookbackDays },
} = highlights;

export function ResponseTimeByHourCard({ groupType, groupId, subgroupType }) {
  const {
    data: { grouped, groups, grades },
  } = useResponseTimesByHour(groupType, groupId, subgroupType, lookbackDays);
  const [hiddenGroups, setHiddenGroups] = useState([]);
  const [grade, setGrade] = useState(null);
  const values =
    grades.length > 0
      ? Object.entries(
          grouped[grades.includes(grade) ? grade : grades[0]] ?? {},
        ).map(([hour, groups]) => ({ hour, ...groups }))
      : [];

  function handleGradeChange(event) {
    setGrade(event.target.value);
  }

  const handleLegendClick = (selectedGroup) => () => {
    const index = hiddenGroups.indexOf(selectedGroup);

    if (index === -1) {
      setHiddenGroups(hiddenGroups.concat(selectedGroup));
    } else {
      setHiddenGroups(
        hiddenGroups.slice(0, index).concat(hiddenGroups.slice(index + 1)),
      );
    }
  };

  return (
    <Card variant="outlined" sx={{ flexGrow: 1 }}>
      <CardContent>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            Incident Response Time by Hour
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {`in the last ${lookbackDays} days`}
          </Typography>
        </Stack>
        <Box
          sx={{
            pb: 1,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {groups.length > 0 &&
            groups.map((group, index) => {
              const count = groups.length;
              const colour = randomHsl(index + 1, count);
              return (
                <Box
                  key={group}
                  sx={{
                    p: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={handleLegendClick(group)}
                >
                  <Avatar
                    sx={{
                      width: 12,
                      height: 12,
                      mr: 0.5,
                      bgcolor: !hiddenGroups.includes(group) && colour,
                    }}
                  >
                    <Fragment />
                  </Avatar>
                  <Typography variant="caption">{group}</Typography>
                </Box>
              );
            })}
        </Box>
        <Box sx={{ pt: 1, fontSize: 12 }}>
          <ResponsiveContainer width="99%" height={520}>
            <LineChart
              data={values}
              margin={{ right: 16, bottom: 32, top: 8, left: 16 }}
            >
              <XAxis dataKey="hour" interval={0}>
                <Label value="Hour" position="bottom" />
              </XAxis>
              <YAxis>
                <Label value="Hours" angle={-90} position="left" offset={8} />
              </YAxis>
              <Tooltip
                wrapperStyle={{ outline: 'none', zIndex: 1000 }} // TODO: This is temporary due to a bug in recharts https://github.com/recharts/recharts/issues/2920
                cursor={false}
                content={
                  <CustomTooltip
                    variant="outlined"
                    valueFormatter={humanizeHours}
                    labelFormatter={(value) => `${value}:00`}
                  />
                }
              />
              {groups.length > 0 &&
                groups.map((group, index) => {
                  const count = groups.length;
                  const colour = randomHsl(index + 1, count);
                  return (
                    <Line
                      key={group}
                      type="monotone"
                      dataKey={group}
                      hide={hiddenGroups.includes(group)}
                      stroke={colour}
                      fill={colour}
                    />
                  );
                })}
            </LineChart>
          </ResponsiveContainer>
        </Box>
        <Stack
          spacing={2}
          direction="row"
          sx={{ w: 1, display: 'flex', justifyContent: 'center' }}
        >
          <TextField
            sx={{ width: 240 }}
            size="small"
            select
            label="Grade"
            value={grades.includes(grade) ? grade : grades[0] ?? ''}
            onChange={handleGradeChange}
          >
            {grades.map((grade) => (
              <MenuItem key={grade} value={grade}>
                {grade}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </CardContent>
    </Card>
  );
}

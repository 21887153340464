import { Place as PlaceIcon } from '@mui/icons-material';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';

function RecursiveTable({ data }) {
  if (typeof data === 'object' && !(data instanceof Date)) {
    if (Array.isArray(data)) {
      return (
        <Typography variant="caption">
          {data.map((item) => item.toString()).join(', ')}
        </Typography>
      );
    }

    return (
      <Table size="small">
        <TableBody>
          {Object.entries(data).map(([key, value]) => {
            return (
              <TableRow key={key}>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    {key}
                  </Typography>
                </TableCell>
                <TableCell>
                  <RecursiveTable data={value} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }

  if (data instanceof Date) {
    return (
      <Typography variant="caption">
        {format(data, 'dd/MM/yyyy HH:mm')}
      </Typography>
    );
  }

  return <Typography variant="caption">{data}</Typography>;
}

export function FileItemDetails({
  primaryItemKey,
  secondaryItemKey,
  itemData,
}) {
  return (
    <Card sx={{ m: [1, 0.5, 1, 1], fontSize: 12 }}>
      <CardHeader
        avatar={
          <Tooltip title="Item">
            <Avatar>
              <PlaceIcon />
            </Avatar>
          </Tooltip>
        }
        title={itemData[primaryItemKey]}
        subheader={itemData[secondaryItemKey]}
      />
      <CardContent>
        <RecursiveTable data={itemData} />
      </CardContent>
    </Card>
  );
}

import { MapButton, MapLayerSwitcher } from '@/components/controls';
import { mapLayers, mapView } from '@/utils/config';
import {
  accelerometerGlyph,
  eventGlyph,
  idleGlyph,
  vehicleStopGlyph,
} from '@/utils/constants';
import { getBaseLayers } from '@/utils/mapStyles';
import {
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { Collection, Feature, Map, View } from 'ol';
import { defaults as defaultControls } from 'ol/control';
import { altKeyOnly } from 'ol/events/condition';
import { GeoJSON } from 'ol/format';
import { Point } from 'ol/geom';
import {
  MouseWheelZoom as MouseWheelZoomInteraction,
  defaults as defaultInteractions,
} from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { Vector as VectorSource } from 'ol/source';
import { Circle, Fill, Icon, Stroke, Style } from 'ol/style';
import { useEffect, useRef, useState } from 'react';

const { maxZoom } = mapView;

const defaultStyle = (mapType) => (feature) => {
  const stroke = new Stroke({
    color: 'rgba(0,0,0)',
    width: 1,
  });

  switch (feature.get('name')) {
    case 'path': {
      const coordinates = feature.getGeometry().getCoordinates();

      const lineStyle = new Style({
        stroke: new Stroke({
          color: 'rgba(0,0,0,1)',
          width: 2,
        }),
      });
      let styles = [lineStyle];

      const startStyle = new Style({
        image: new Circle({
          radius: 5,
          fill: new Fill({
            color: 'rgba(76,175,80,1)',
          }),
        }),
        geometry: new Point(coordinates[0]),
        // zIndex: schema.zindex + 1 || 101
      });

      styles.push(startStyle);

      const endStyle = new Style({
        image: new Circle({
          radius: 5,
          fill: new Fill({
            color: 'rgba(244,67,54,1)',
          }),
        }),
        geometry: new Point(coordinates[coordinates.length - 1]),
        // zIndex: schema.zindex + 1 || 101
      });

      styles.push(endStyle);

      return styles;
    }
    case 'point':
      return [
        new Style({
          image: new Circle({
            radius: 8,
            fill: new Fill({
              color: 'rgb(33,150,243)',
            }),
          }),
        }),
        new Style({
          image: new Icon({
            src: iconType(mapType),
            scale: 0.5,
          }),
        }),
      ];
    case 'hoveredChartPoint':
      return [
        new Style({
          image: new Circle({
            radius: 4,
            stroke: stroke,
            fill: new Fill({
              color: 'rgb(255,255,0)',
            }),
          }),
        }),
      ];
    default:
      return [];
  }
};

const iconType = (mapType) => {
  switch (mapType) {
    case 'accelerometerEvents':
      return accelerometerGlyph;
    case 'stops':
      return vehicleStopGlyph;
    case 'idles':
      return idleGlyph;
    default:
      return eventGlyph;
  }
};

export function EventPreviewMap({ hoveredChartPoint, point, path, mapType }) {
  const [zoomInDisabled, setZoomInDisabled] = useState(false);
  const [zoomOutDisabled, setZoomOutDisabled] = useState(false);
  // need this to rerender MapLayerSwitcher
  const [mapAvailable, setMapAvailable] = useState(false);

  const mapDiv = useRef(null);
  const vectorSource = useRef(null);
  const map = useRef(null);

  function resetMapControls() {
    setZoomInDisabled(
      map.current.getView().getZoom() === map.current.getView().getMaxZoom(),
    );
    setZoomOutDisabled(
      map.current.getView().getZoom() === map.current.getView().getMinZoom(),
    );
  }

  useEffect(() => {
    if (!map.current) {
      vectorSource.current = new VectorSource({
        features: new Collection([]),
      });

      const baseLayers = getBaseLayers(mapLayers);

      map.current = new Map({
        target: mapDiv.current,
        layers: [
          ...baseLayers,
          new VectorLayer({
            source: vectorSource.current,
            style: defaultStyle(mapType),
          }),
        ],
        interactions: defaultInteractions({
          mouseWheelZoom: false,
          pinchRotate: false,
          altShiftDragRotate: false,
        }).extend([
          new MouseWheelZoomInteraction({
            condition: altKeyOnly,
          }),
        ]),
        view: new View({
          center: [0, 0],
          zoom: 2,
        }),
        controls: defaultControls({
          attribution: false,
          rotate: false,
          zoom: false,
        }),
      });

      setMapAvailable(true);
    }
  }, [mapType]);

  useEffect(() => {
    vectorSource.current.clear();

    if (path) {
      vectorSource.current.addFeature(
        new Feature({
          geometry: new GeoJSON().readGeometry(path, {
            featureProjection: 'EPSG:3857',
          }),
          name: 'path',
        }),
      );
    }

    if (point) {
      vectorSource.current.addFeature(
        new Feature({
          geometry: new GeoJSON().readGeometry(point, {
            featureProjection: 'EPSG:3857',
          }),
          name: 'point',
        }),
      );
    }

    if (hoveredChartPoint) {
      vectorSource.current.addFeature(
        new Feature({
          geometry: new GeoJSON().readGeometry(hoveredChartPoint, {
            featureProjection: 'EPSG:3857',
          }),
          name: 'hoveredChartPoint',
        }),
      );
    }
  }, [path, point, hoveredChartPoint]);

  useEffect(() => {
    const geometry = new GeoJSON().readGeometry(path || point, {
      featureProjection: 'EPSG:3857',
    });
    map.current.getView().fit(geometry, { maxZoom: maxZoom });
    resetMapControls();
  }, [path, point]);

  function handleZoomInClick() {
    if (map.current) {
      map.current.getView().setZoom(map.current.getView().getZoom() + 1);
      resetMapControls();
    }
  }

  function handleZoomOutClick() {
    if (map.current) {
      map.current.getView().setZoom(map.current.getView().getZoom() - 1);
      resetMapControls();
    }
  }

  function handleFitClick() {
    if (vectorSource.current && vectorSource.current.getFeatures().length > 0) {
      map.current
        .getView()
        .fit(vectorSource.current.getExtent(), { maxZoom: maxZoom });
      resetMapControls();
    }
  }

  return (
    <Box sx={{ position: 'relative', width: 1, height: 1 }}>
      <Box id="map" sx={{ width: 1, height: 1 }} ref={mapDiv} />
      <Stack sx={{ position: 'absolute', top: 8, left: 8 }}>
        <MapButton
          title="Zoom In"
          disabled={zoomInDisabled}
          onClick={handleZoomInClick}
        >
          <ZoomInIcon />
        </MapButton>
        <MapButton
          title="Zoom Out"
          disabled={zoomOutDisabled}
          onClick={handleZoomOutClick}
        >
          <ZoomOutIcon />
        </MapButton>
        <MapButton title="Fit" onClick={handleFitClick}>
          <ZoomOutMapIcon />
        </MapButton>
      </Stack>
      <MapLayerSwitcher mapRef={map} mapAvailable={mapAvailable} />
    </Box>
  );
}

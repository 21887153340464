import { isOccurrenceCode, isPersonCode } from '@/utils';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Fragment, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useOfficers, useCustodyUnits, useHospitals } from '@/hooks';

export function PrisonerEditorDialog({
  prisoner,
  isOpen,
  onClose,
  onDelete,
  // groupCodes,
}) {
  const { control, handleSubmit, reset } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: prisoner,
  });

  const { data: custodyUnits } = useCustodyUnits();
  const custodyUnitsOptions = Object.values(custodyUnits ?? {});
  const { data: officers } = useOfficers();
  const { data: hospitals } = useHospitals();

  useEffect(() => {
    reset(prisoner);
  }, [reset, prisoner, isOpen]);

  function handleClose() {
    onClose();
  }

  return (
    <form id="prisoner-editor-form" onSubmit={handleSubmit(onClose)}>
      <Dialog open={isOpen && !!prisoner} onClose={handleClose}>
        <DialogTitle>{`${
          prisoner.code ? 'Edit' : 'Add'
        } Person Watch/Guard`}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText gutterBottom>
              Please fill in the form below to add an person watch/guard. If
              groups are not specified then the prisoner will be visible to all.
            </DialogContentText>
            <Controller
              name="personCode"
              control={control}
              rules={{
                required: 'Required',
                validate: async (value) =>
                  !value || (await isPersonCode(value)),
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  size="small"
                  label="Niche Person ID"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="occurrenceCode"
              control={control}
              rules={{
                validate: async (value) =>
                  !value || (await isOccurrenceCode(value)),
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  size="small"
                  label="Occurrence"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  size="small"
                  label="Name"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="offence"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  size="small"
                  label="offence"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="arrestingOfficerCode"
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  select
                  size="small"
                  label="Arresting Officer"
                  error={!!error}
                  helperText={error?.message}
                  sx={{ minWidth: 200 }}
                >
                  <MenuItem key="" value="">
                    None
                  </MenuItem>
                  {officers?.map((officer) => (
                    <MenuItem key={officer.code} value={officer.collarNumber}>
                      {officer.collarNumber}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name="investigatingOfficerCode"
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  select
                  size="small"
                  label="Investigating Officer"
                  error={!!error}
                  helperText={error?.message}
                  sx={{ minWidth: 200 }}
                >
                  <MenuItem key="" value="">
                    None
                  </MenuItem>
                  {officers?.map((officer) => (
                    <MenuItem key={officer.code} value={officer.collarNumber}>
                      {officer.collarNumber}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name="officerInCaseCode"
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  select
                  size="small"
                  label="OIC"
                  error={!!error}
                  helperText={error?.message}
                  sx={{ minWidth: 200 }}
                >
                  <MenuItem key="" value="">
                    None
                  </MenuItem>
                  {officers?.map((officer) => (
                    <MenuItem key={officer.code} value={officer.collarNumber}>
                      {officer.collarNumber}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name="custodyUnit"
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  select
                  size="small"
                  label="Custody Unit"
                  error={!!error}
                  helperText={error?.message}
                  sx={{ minWidth: 200 }}
                >
                  <MenuItem key="" value="">
                    None
                  </MenuItem>
                  {custodyUnitsOptions?.map((unit) => (
                    <MenuItem key={unit} value={unit}>
                      {unit}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name="hospital"
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  select
                  size="small"
                  label="Hospital"
                  error={!!error}
                  helperText={error?.message}
                  sx={{ minWidth: 200 }}
                  defaultValue="None"
                >
                  <MenuItem key="" value="">
                    None
                  </MenuItem>
                  {hospitals?.map((hospital) => (
                    <MenuItem key={hospital.code} value={hospital.name}>
                      {hospital.name}
                    </MenuItem>
                  ))}
                  <MenuItem key="Other" value="Other">
                    Other
                  </MenuItem>
                </TextField>
              )}
            />
            <Controller
              name="arrivalTime"
              control={control}
              defaultValue={null}
              render={({ field, fieldState: { error } }) => (
                <DateTimePicker
                  {...field}
                  ampm={false}
                  size="small"
                  label="Arrival Time"
                  error={!!error}
                  helperText={error?.message}
                  sx={{ minWidth: 200 }}
                  format="dd/MM/yyyy hh:mm"
                  slotProps={{ textField: { size: 'small' } }}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          {!!prisoner.code && (
            <Fragment>
              <Button color="error" onClick={onDelete}>
                Delete
              </Button>
              <Box sx={{ flexGrow: 1 }} />
            </Fragment>
          )}
          <Button onClick={handleClose}>Cancel</Button>
          <Button form="prisoner-editor-form" type="submit">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}

import { shortHumanizer } from '@/utils';

export const pipelineFn = (orderBy, order, page, rowsPerPage, query) => [
  { $match: query },
  {
    $project: {
      identifier: true,
      startTime: true,
      endTime: true,
      durationSeconds: true,
      distanceMiles: { $multiply: ['$distanceKilometres', 0.62137119] },
      startLocations: {
        $ifNull: ['$startLocations', [{ name: 'Elsewhere', type: 'None' }]],
      },
      endLocations: {
        $ifNull: ['$endLocations', [{ name: 'Elsewhere', type: 'None' }]],
      },
      vehicle: true,
    },
  },
  { $sort: { [orderBy]: order === 'desc' ? -1 : 1 } },
  { $skip: page * rowsPerPage },
  { $limit: rowsPerPage },
];

export const downloadPipelineFn = (query) => [
  { $match: query },
  {
    $project: {
      identifier: true,
      startTime: true,
      endTime: true,
      durationSeconds: { $divide: ['$durationSeconds', 86400] },
      distanceMiles: {
        $round: [{ $multiply: ['$distanceKilometres', 0.62137119] }, 2],
      },
      startLocations: {
        $ifNull: ['$startLocations', [{ name: 'Elsewhere', type: 'None' }]],
      },
      endLocations: {
        $ifNull: ['$endLocations', [{ name: 'Elsewhere', type: 'None' }]],
      },
      vehicle: true,
    },
  },
];

export const headers = [
  {
    label: 'IMEI',
    key: 'vehicle.telematicsBoxImei',
    type: 'text',
  },
  {
    label: 'Registration',
    key: 'vehicle.registrationNumber',
    type: 'text',
  },
  {
    label: 'Fleet Number',
    key: 'vehicle.fleetNumber',
    type: 'text',
  },
  { label: 'Role', key: 'vehicle.role', type: 'text', filter: true },
  {
    label: 'Start Location Type',
    key: 'startLocations.0.type',
    type: 'text',
  },
  {
    label: 'Start Location Name',
    key: 'startLocations.0.name',
    type: 'text',
  },
  {
    label: 'End Location Type',
    key: 'endLocations.0.type',
    type: 'text',
  },
  {
    label: 'End Location Name',
    key: 'endLocations.0.name',
    type: 'text',
  },
  { label: 'Start Time', key: 'startTime', type: 'date' },
  { label: 'End Time', key: 'endTime', type: 'date' },
  {
    label: 'Duration',
    key: 'durationSeconds',
    format: (value) => shortHumanizer(value * 1000),
    type: 'text',
    align: 'right',
  },
  {
    label: 'Distance (miles)',
    key: 'distanceMiles',
    type: 'number',
    align: 'right',
  },
];

import {
  FormControl,
  FormHelperText,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useController } from 'react-hook-form';

export function ToggleButtonGroupController({
  name,
  control,
  rules,
  disabled,
  onChange: callback,
  values,
}) {
  const {
    field: { value, disabled: isDisabled, onChange, ref },
    fieldState: { error, invalid },
  } = useController({
    name,
    control,
    rules,
    disabled,
  });

  function handleChange(event, value) {
    if (value !== null) {
      onChange(value);
      if (callback) {
        callback(value);
      }
    }
  }

  return (
    <FormControl error={invalid} variant="standard" disabled={isDisabled}>
      <ToggleButtonGroup
        ref={ref}
        value={value}
        onChange={handleChange}
        color="primary"
        size="small"
        exclusive
      >
        {values.map(({ label, value }) => (
          <ToggleButton key={value} value={value}>
            {label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <FormHelperText sx={{ pl: 1 }}>{error?.message}</FormHelperText>
    </FormControl>
  );
}

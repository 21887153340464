import { RetrospectiveTypeIcon } from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import { startCase } from '@/utils';
import {
  Error as ErrorIcon,
  Input as InputIcon,
  Navigation as NavigationIcon,
  Person as PersonIcon,
  VolumeUp as VolumeUpIcon,
  VpnKey as VpnKeyIcon,
  Warning as WarningIcon,
  WbIncandescent as WbIncandescentIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { amber } from '@mui/material/colors';
import { format } from 'date-fns';
import {
  AlarmLight as AlarmLightIcon,
  CarSide as CarSideIcon,
  Engine as EngineIcon,
  RadioHandheld as RadioHandheldIcon,
} from 'mdi-material-ui';
import { Fragment, cloneElement } from 'react';

export function VehiclePoll({
  color,
  background,
  vehicle,
  imei,
  time,
  headingDegrees,
  speedKilometresPerHour,
  malfunctionIndicatorLightOn,
  accelerometerAlert,
  ignitionOn,
  driver,
  sirensOn,
  beaconsOn,
  rearBlueLightsOn,
  rearRedLightsOn,
  strikeButtonOn,
  frontPWEOn,
  rearPWEOn,
  airwaveOn,
  ancillaryEquipmentOn,
}) {
  const theme = useTheme();

  const { title, subheader } = vehicle?.unassociated
    ? {
        title: 'Unassociated IMEI',
        subheader: imei,
      }
    : {
        title: vehicle?.registrationNumber,
        subheader: vehicle?.fleetNumber,
      };
  useDocumentTitle(`IR3 | Poll | ${title}`);

  return (
    <Card sx={{ m: [1, 0.5, 1, 1] }}>
      <CardHeader
        avatar={
          <Tooltip title="Vehicle Poll">
            <Avatar
              style={{
                background,
                color,
              }}
            >
              <RetrospectiveTypeIcon type="vehiclePolls" />
            </Avatar>
          </Tooltip>
        }
        title={title}
        subheader={subheader}
      />
      <CardContent>
        {vehicle && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Vehicle
            </Typography>
            <Table size="small" sx={{ mt: 1, mb: 2 }}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography variant="caption" color="textSecondary">
                      Role
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">{vehicle.role}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="caption" color="textSecondary">
                      Home Station
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">
                      {vehicle.homeStation}
                    </Typography>
                  </TableCell>
                </TableRow>
                {Object.entries(vehicle.groups ?? {}).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        {startCase(key)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">
                        {(value ?? []).join(', ')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Fragment>
        )}
        {driver && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Driver
            </Typography>
            <ListItem component="div">
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${driver.forenames || ''}  ${driver.surname || ''}`}
                secondary={driver.collarNumber}
              />
            </ListItem>
          </Fragment>
        )}
        <Typography variant="subtitle2" color="textSecondary">
          Status
        </Typography>
        <Grid
          container
          spacing={2}
          justifyContent="space-around"
          alignItems="center"
          sx={{ mt: 1, mb: 2 }}
        >
          <Grid item xs={4} sx={{ textAlign: 'center' }}>
            <Typography variant="caption">
              {format(new Date(time), 'dd/MM/yyyy HH:mm:ss')}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: 'center' }}>
            <Typography variant="caption">
              {`${Math.round(speedKilometresPerHour * 0.62137119)} MPH`}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: 'center' }}>
            <Tooltip title={`Heading ${headingDegrees}°`}>
              <NavigationIcon
                style={{
                  transform: `rotate(${headingDegrees}deg)`,
                  transformOrigin: ['50%', '50%'],
                }}
                color="action"
              />
            </Tooltip>
          </Grid>
          {ignitionOn !== undefined && (
            <Grid item xs={2} sx={{ textAlign: 'center' }}>
              <Tooltip title="Ignition">
                <VpnKeyIcon
                  htmlColor={
                    ignitionOn ? amber[700] : theme.palette.action.disabled
                  }
                />
              </Tooltip>
            </Grid>
          )}
          {malfunctionIndicatorLightOn !== undefined && (
            <Grid item xs={2}>
              <Tooltip title="Malfunction Indicator Light">
                <EngineIcon
                  htmlColor={
                    malfunctionIndicatorLightOn
                      ? amber[700]
                      : theme.palette.action.disabled
                  }
                />
              </Tooltip>
            </Grid>
          )}
          {accelerometerAlert !== undefined && (
            <Grid item xs={2}>
              <Tooltip title="Accelerometer">
                <WarningIcon
                  htmlColor={
                    accelerometerAlert
                      ? amber[700]
                      : theme.palette.action.disabled
                  }
                />
              </Tooltip>
            </Grid>
          )}
          {sirensOn !== undefined && (
            <Grid item xs={2}>
              <Tooltip title="Sirens">
                <VolumeUpIcon
                  htmlColor={
                    sirensOn ? amber[700] : theme.palette.action.disabled
                  }
                />
              </Tooltip>
            </Grid>
          )}
          {beaconsOn !== undefined && (
            <Grid item xs={2}>
              <Tooltip title="Beacon">
                <AlarmLightIcon
                  htmlColor={
                    beaconsOn ? amber[700] : theme.palette.action.disabled
                  }
                />
              </Tooltip>
            </Grid>
          )}
          {rearBlueLightsOn !== undefined && (
            <Grid item xs={2}>
              <Tooltip title="Rear Blues">
                <WbIncandescentIcon
                  color={rearBlueLightsOn ? 'primary' : 'disabled'}
                />
              </Tooltip>
            </Grid>
          )}
          {rearRedLightsOn !== undefined && (
            <Grid item xs={2}>
              <Tooltip title="Rear Reds">
                <WbIncandescentIcon
                  color={rearRedLightsOn ? 'error' : 'disabled'}
                />
              </Tooltip>
            </Grid>
          )}
          {strikeButtonOn !== undefined && (
            <Grid item xs={2}>
              <Tooltip title="Strike Button">
                <ErrorIcon
                  htmlColor={
                    strikeButtonOn ? amber[700] : theme.palette.action.disabled
                  }
                />
              </Tooltip>
            </Grid>
          )}
          {(frontPWEOn !== undefined || rearPWEOn !== undefined) && (
            <Grid item xs={2}>
              <Tooltip
                title={`PWE: front ${frontPWEOn ? 'on' : 'off'}, rear ${
                  rearPWEOn ? 'on' : 'off'
                }`}
              >
                <CarSideIcon
                  component={(svgProps) => {
                    return (
                      <svg {...svgProps}>
                        <defs>
                          <linearGradient id="gradient1">
                            <stop
                              offset="50%"
                              stopColor={
                                rearPWEOn
                                  ? theme.palette.primary.dark
                                  : theme.palette.action.disabled
                              }
                            />
                            <stop
                              offset="50%"
                              stopColor={
                                frontPWEOn
                                  ? theme.palette.primary.dark
                                  : theme.palette.action.disabled
                              }
                            />
                          </linearGradient>
                        </defs>
                        {cloneElement(svgProps.children[0], {
                          fill: 'url(#gradient1)',
                        })}
                      </svg>
                    );
                  }}
                />
              </Tooltip>
            </Grid>
          )}
          {airwaveOn !== undefined && (
            <Grid item xs={2}>
              <Tooltip title="Airwave Radio">
                <RadioHandheldIcon
                  htmlColor={
                    airwaveOn ? amber[700] : theme.palette.action.disabled
                  }
                />
              </Tooltip>
            </Grid>
          )}
          {ancillaryEquipmentOn !== undefined && (
            <Grid item xs={2}>
              <Tooltip title="Ancillary Equipment">
                <InputIcon
                  htmlColor={
                    ancillaryEquipmentOn
                      ? amber[700]
                      : theme.palette.action.disabled
                  }
                />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

import _ from 'lodash';
import { useSelector } from 'react-redux';

// Hook
export function useLatestPoll(imei) {
  const polls = useSelector(
    (state) => state.telematicsBoxes.boxesByImei[imei]?.polls,
  );
  const ordered = _.orderBy(polls || [], ['time'], ['desc']); // sort by time descending
  const latestPoll = ordered[0]; // the top one is the most recent

  return latestPoll;
}

import { useAuth, useIsAuthenticated } from '@/hooks';
import { isAuthorised } from '@/routing';
import { useLocation } from 'react-router-dom';
import { Unauthorised } from './Unauthorised';

export function SecureRoute({ children }) {
  const auth = useAuth();
  const location = useLocation();
  const { data: isAuthenticated } = useIsAuthenticated();

  if (isAuthenticated && isAuthorised(location.pathname, auth)) {
    return children;
  } else {
    return <Unauthorised />;
  }
}

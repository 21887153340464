import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useIntelligenceItems(options) {
  const searchParams = JSON.parse(JSON.stringify(options ?? {}));

  return useQuery({
    queryKey: ['intelligence', searchParams],
    queryFn: () => {
      return briefingApi.get('intelligence', { searchParams }).json();
    },
    placeholderData: [],
  });
}

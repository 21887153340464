import { parseSort, shortHumanizer } from '@/utils';
import {
  baseType,
  incidentGrades,
  isFleet,
  locationGroups,
  locationTypes,
  personGroups,
  personRanks,
  personRoles,
  retrospective,
  showIntelCrime,
  interCrimeFilters,
  useDallasKeys,
  vehicleGroups,
  vehicleRoles,
  vehicleTypes,
} from '@/utils/config';
import { incidentFilters } from '@/utils/constants';
import { format, isValid } from 'date-fns';
import _ from 'lodash';
import nanomemoize from 'nano-memoize';

const { visitLocationTypes } = retrospective;

const visitLocationTypesOptions = visitLocationTypes.map((label) => ({
  label,
  value: label,
}));

const incidentFilterTypes = Object.entries(incidentFilters.types)
  .map((entry) => ({
    label: entry[1],
    value: entry[0],
  }))
  .sort(compareLabels);

const incidentFilterCategories = incidentFilters.categories
  .map((value) => ({
    label: value,
    value,
  }))
  .sort(compareLabels);

const incidentFilterStatuses = incidentFilters.status
  .map((value) => ({
    label: value,
    value,
  }))
  .sort(compareLabels);

const incidentFilterClosingCodes = Object.entries(incidentFilters.closingCodes)
  .map((entry) => ({
    label: entry[1],
    value: entry[0],
  }))
  .sort(compareLabels);

function getInteCrimeSource(showCrimeSource) {
  const inteCrimeSource = [
    { label: 'Crimes', value: 'crimes' },
    { label: 'Stop Checks', value: 'stopChecks' },
  ];
  return showCrimeSource ? inteCrimeSource : [];
}

export const sources = isFleet
  ? {
      area: [
        { label: 'Vehicle Stop Count', value: 'vehicleStopCount' },
        { label: 'Vehicle Idle Count', value: 'vehicleIdleCount' },
        { label: 'Vehicle Visit Count', value: 'vehicleVisitCount' },
        { label: 'Vehicle Time', value: 'vehicleTime' },
        // { label: 'Accelerometer Alert Count', value: 'accelerometerAlertCount' },
      ],
      bubble: [
        { label: 'Vehicle Stops', value: 'vehicleStops' },
        { label: 'Vehicle Idles', value: 'vehicleIdles' },
        // { label: 'Accelerometer Alerts', value: 'accelerometerAlerts' },
      ],
      heat: [
        { label: 'Vehicle Stops', value: 'vehicleStops' },
        { label: 'Vehicle Idles', value: 'vehicleIdles' },
        { label: 'Vehicle Polls', value: 'vehiclePolls' },
        // { label: 'Accelerometer Alerts', value: 'accelerometerAlerts' },
      ],
      shape: [
        { label: 'Vehicle Stops', value: 'vehicleStops' },
        { label: 'Vehicle Idles', value: 'vehicleIdles' },
        { label: 'Vehicle Trips', value: 'vehicleTrips' },
        { label: 'Vehicle Speed Infractions', value: 'speedInfractions' },
        { label: 'Vehicle Known Visits', value: 'vehicleVisits' },
        { label: 'Vehicle Custom Visits', value: 'vehicleCustomVisits' },
        { label: 'Accelerometer Events', value: 'accelerometerEvents' },
        { label: 'Locations', value: 'locations' },
      ],
      file: [
        { label: 'GeoJSON', value: 'geojson' },
        { label: 'KML', value: 'kml' },
      ].filter(Boolean),
    }
  : {
      area: [
        { label: 'Vehicle Stop Count', value: 'vehicleStopCount' },
        { label: 'Vehicle Idle Count', value: 'vehicleIdleCount' },
        { label: 'Vehicle Visit Count', value: 'vehicleVisitCount' },
        { label: 'Vehicle Time', value: 'vehicleTime' },
        { label: 'Person Visit Count', value: 'personVisitCount' },
        { label: 'Person Time', value: 'personTime' },
        { label: 'Incident Count', value: 'incidentCount' },
        // { label: 'Accelerometer Alert Count', value: 'accelerometerAlertCount' },
      ].filter(Boolean),
      bubble: [
        { label: 'Vehicle Stops', value: 'vehicleStops' },
        { label: 'Vehicle Idles', value: 'vehicleIdles' },
        {
          label: 'Incidents',
          value: 'incidents',
        },
        ...getInteCrimeSource(showIntelCrime),
      ].filter(Boolean),
      heat: [
        { label: 'Vehicle Stops', value: 'vehicleStops' },
        { label: 'Vehicle Idles', value: 'vehicleIdles' },
        { label: 'Vehicle Polls', value: 'vehiclePolls' },
        // { label: 'Accelerometer Alerts', value: 'accelerometerAlerts' },
        { label: 'Person Polls', value: 'personPolls' },
        {
          label: 'Incidents',
          value: 'incidents',
        },
        ...getInteCrimeSource(showIntelCrime),
      ].filter(Boolean),
      shape: [
        { label: 'Vehicle Stops', value: 'vehicleStops' },
        { label: 'Vehicle Idles', value: 'vehicleIdles' },
        { label: 'Vehicle Trips', value: 'vehicleTrips' },
        { label: 'Vehicle Known Visits', value: 'vehicleVisits' },
        { label: 'Vehicle Custom Visits', value: 'vehicleCustomVisits' },
        { label: 'Vehicle Speed Infractions', value: 'speedInfractions' },
        { label: 'Person Trails', value: 'personTrails' },
        { label: 'Person Known Visits', value: 'personVisits' },
        { label: 'Person Custom Visits', value: 'personCustomVisits' },
        {
          label: 'Incidents',
          value: 'incidents',
        },
        { label: 'Locations', value: 'locations' },
        { label: 'Accelerometer Events', value: 'accelerometerEvents' },
        ...getInteCrimeSource(showIntelCrime),
      ].filter(Boolean),
      file: [
        { label: 'GeoJSON', value: 'geojson' },
        { label: 'KML', value: 'kml' },
      ].filter(Boolean),
    };

export function compareLabels(a, b) {
  return a.label.localeCompare(b.label);
}

export function getBoundaries(state) {
  return {
    Location: state.locations.locations.reduce((accumulator, location) => {
      if (!accumulator[location.type]) {
        accumulator[location.type] = [];
      }
      accumulator[location.type].push({
        label: location.name,
        value: location.code,
      });

      return accumulator;
    }, {}),
    Objective: state.objectives.objectives.map((objective) => ({
      label: objective.title,
      value: objective.identifier,
    })),
  };
}

function getGroupsFilters(groups) {
  let groupsFilters = {};
  Object.keys(groups).forEach((group) => {
    const filter = {
      [`groups.${group}`]: {
        label: groups[group].label,
        type: 'multiselect',
        values: groups[group].values.sort(compareLabels),
      },
    };
    groupsFilters = { ...groupsFilters, ...filter };
  });

  return groupsFilters;
}

const filterMapSort =
  (labelProperty, valueProperty, customFilter) =>
  (collection = []) => {
    const result = collection
      .filter(customFilter ?? (() => true))
      .filter((i) => i[labelProperty])
      .map((i) => ({
        label: i[labelProperty],
        value: i[valueProperty] ?? i[labelProperty],
      }))
      .sort(compareLabels);

    // temp for testing, amp up the results
    // if (true)  // this will give me a warning in
    //   return Array(100).fill(result).flatMap(a => a);

    return result;
  };

const vehicleOptionTransforms = {
  identificationNumber: nanomemoize(filterMapSort('identificationNumber')),
  registrationNumber: nanomemoize(filterMapSort('registrationNumber')),
  fleetNumber: nanomemoize(filterMapSort('fleetNumber')),
};

const telematicsBoxOptionTransforms = {
  imei: nanomemoize(filterMapSort('imei')),
};

const radioTransforms = {
  ssi: nanomemoize(filterMapSort('ssi')),
};

const rfidCardTransforms = {
  reference: nanomemoize(filterMapSort('reference')),
};

function onlyVisitLocations(location) {
  return !visitLocationTypes || visitLocationTypes.includes(location.type);
}

const locationOptionTransforms = {
  homeStationNames: nanomemoize(filterMapSort('name', 'code')),
  locationNameValues: nanomemoize(filterMapSort('name')),
  locationCodeValues: nanomemoize(filterMapSort('code')),
  visitLocationNameValues: nanomemoize(
    filterMapSort('name', 'name', onlyVisitLocations),
  ),
  visitLocationCodeValues: nanomemoize(
    filterMapSort('code', 'code', onlyVisitLocations),
  ),
};

const peopleOptionTransforms = {
  forenames: nanomemoize(filterMapSort('forenames')),
  surname: nanomemoize(filterMapSort('surname')),
  code: nanomemoize(filterMapSort('code')),
  collarNumber: nanomemoize(filterMapSort('collarNumber')),
  radioSsi: nanomemoize(filterMapSort('radioSsi')),
  rfids: nanomemoize((people) => {
    let rfidDict = {};
    people.forEach((p) => {
      p.rfidCards?.forEach((rfidCard) => (rfidDict[rfidCard.reference] = true));
    });
    return Object.keys(rfidDict)
      .sort()
      .map((value) => ({ label: value, value }));
  }),
};

export function getFilters(state) {
  const identificationNumber = {
    label: 'VIN',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: vehicleOptionTransforms.identificationNumber(
      state.vehicles.vehicles,
    ),
  };
  const registrationNumber = {
    label: 'Registration',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: vehicleOptionTransforms.registrationNumber(state.vehicles.vehicles),
  };
  const fleetNumber = {
    label: 'Fleet Number',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: vehicleOptionTransforms.fleetNumber(state.vehicles.vehicles),
  };
  const type = {
    label: 'Type',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: vehicleTypes.sort(compareLabels),
  };
  const role = {
    label: 'Role',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: vehicleRoles.sort(compareLabels),
  };
  const telematicsBoxImei = {
    label: 'IMEI',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: telematicsBoxOptionTransforms.imei(
      state.telematicsBoxes.telematicsBoxes,
    ),
  };

  const homeStation = {
    label: `Home ${baseType.label}`,
    type: 'multiselect',
    showNonExistantAsError: true,
    values: locationOptionTransforms.homeStationNames(
      state.locations.homeStationNames,
    ),
  };

  const forenames = {
    label: 'Forenames',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: peopleOptionTransforms.forenames(state.people.people),
  };
  const surname = {
    label: 'Surname',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: peopleOptionTransforms.surname(state.people.people),
  };
  const code = {
    label: 'Payroll Number',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: peopleOptionTransforms.code(state.people.people),
  };
  const collarNumber = {
    label: 'Collar Number',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: peopleOptionTransforms.collarNumber(state.people.people),
  };
  const radioSsi = {
    label: 'Radio SSI',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: radioTransforms.ssi(state.radios.radios),
  };
  const identificationReference = {
    label: useDallasKeys ? 'Dallas Key' : 'RFID Card',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: rfidCardTransforms.reference(state.rfidCards.rfidCards),
  };
  const rank = {
    label: 'Rank',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: personRanks
      .map(({ name, code }) => ({
        label: name,
        value: code,
      }))
      .sort(compareLabels),
  };
  const roleP = {
    label: 'Role',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: personRoles.sort(compareLabels),
  };

  const locationNameValues = locationOptionTransforms.locationNameValues(
    state.locations.locations,
  );

  const wardNameValues = locationOptionTransforms.locationNameValues(
    state.locations.locations.filter((location) => location.type === 'Ward'),
  );

  const codeL = {
    label: 'Code',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: locationOptionTransforms.locationCodeValues(
      state.locations.locations,
    ),
  };
  const name = {
    label: 'Name',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: locationNameValues,
  };
  const typeL = {
    label: 'Type',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: Object.values(locationTypes).sort(compareLabels),
  };
  const subtype = {
    label: 'Subtype',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: Object.values(locationTypes)
      .map((type) => type.subtypes)
      .flat()
      .sort(compareLabels),
  };

  // intersections have visits precalculated but only for a select few types
  const visitLocationCode = {
    label: 'Code',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: locationOptionTransforms.visitLocationCodeValues(
      state.locations.locations,
    ),
  };
  const visitLocationName = {
    label: 'Name',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: locationOptionTransforms.visitLocationNameValues(
      state.locations.locations,
    ),
  };
  const visitLocationType = {
    label: 'Type',
    type: 'multiselect',
    showNonExistantAsError: true,
    values:
      visitLocationTypesOptions ||
      Object.values(locationTypes).sort(compareLabels),
  };

  const durationSeconds = {
    label: 'Duration',
    type: 'duration',
  };

  const startTime = {
    label: 'Start Time',
    type: 'date',
  };

  const endTime = {
    label: 'End Time',
    type: 'date',
  };

  const maxSpeedKilometresPerHour = {
    label: 'Max Speed',
    type: 'miles',
    unit: 'mph',
  };
  const startLocationName = {
    label: 'Start Location Name',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: locationNameValues,
  };
  const startLocationType = {
    label: 'Start Location Type',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: Object.values(locationTypes).sort(compareLabels),
  };
  const endLocationName = {
    label: 'End Location Name',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: locationNameValues,
  };
  const endLocationType = {
    label: 'End Location Type',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: Object.values(locationTypes).sort(compareLabels),
  };
  const locationName = {
    label: 'Location Name',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: locationNameValues,
  };
  const locationType = {
    label: 'Location Type',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: Object.values(locationTypes).sort(compareLabels),
  };

  const reference = {
    label: 'Incident Number',
    type: 'number',
    onlyEqual: true,
    // parse: (x) => x?.toString() || '',
  };
  const date = {
    label: 'Date',
    type: 'date',
    onlyEqual: true,
    parse: (x) => {
      const date = new Date(x);
      if (isValid(date)) {
        return format(date, 'yyyy-MM-dd');
      }
      return '';
    },
  };
  const typeI = {
    label: 'Type',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: incidentFilterTypes,
  };
  const responseCategory = {
    label: 'Response Category',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: incidentFilterCategories,
  };
  const responseSeconds = {
    label: 'Response Duration',
    type: 'duration',
  };
  const wardName = {
    label: 'Ward',
    type: 'multiselect',
    values: wardNameValues,
  };
  const wardType = {
    label: 'Ward Type',
    type: 'select',
    values: locationTypes.wards?.subtypes ?? [],
  };
  const grade = {
    label: 'Grade',
    type: 'multiselect',
    showNonExistantAsError: true,
    values:
      incidentGrades ??
      Array(6)
        .fill()
        .map((_, i) => ({
          label: `${i + 1}`,
          value: i + 1,
        })),
  };
  const status = {
    label: 'Status',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: incidentFilterStatuses,
  };
  const closingCode = {
    label: 'Closing Code',
    type: 'multiselect',
    showNonExistantAsError: true,
    values: incidentFilterClosingCodes,
  };

  const location = {
    code: codeL,
    name,
    type: typeL,
    subtype,
    ...getGroupsFilters(locationGroups),
  };

  const visitLocation = {
    code: visitLocationCode,
    name: visitLocationName,
    type: visitLocationType,
  };

  const filters = {
    vehicle: {
      identificationNumber,
      registrationNumber,
      fleetNumber,
      role,
      type,
      homeStation,
      telematicsBoxImei,
      ...getGroupsFilters(vehicleGroups),
    },
    person: {
      forenames,
      surname,
      code,
      collarNumber,
      radioSsi,
      'rank.code': rank,
      role: roleP,
      homeStation,
      ...getGroupsFilters(personGroups),
    },
    location,
    visitLocation,
    incident: {
      // number,
      reference,
      date,
      'type.code': typeI,
      'responseCategory.code': responseCategory,
      responseSeconds,
      grade,
      status,
      'closingCodes.code': closingCode,
      'locations.name': locationName,
      'locations.type': locationType,
      'ward.name': wardName,
      'ward.type': wardType,
    },
    trip: {
      startTime,
      endTime,
      durationSeconds,
      maxSpeedKilometresPerHour,
      'startLocations.name': startLocationName,
      'startLocations.type': startLocationType,
      'endLocations.name': endLocationName,
      'endLocations.type': endLocationType,
    },
    personVisit: {
      startTime,
      endTime,
      durationSeconds,
    },
    accelerometerEvent: {
      startTime,
      endTime,
    },
    stop: {
      durationSeconds,
      'locations.name': locationName,
      'locations.type': locationType,
    },
    crime: {
      dispatchType: {
        label: 'Dispatch Type',
        type: 'select',
        values: interCrimeFilters?.dispatchType,
      },
      classifications: {
        label: 'Local Qualifier',
        type: 'select',
        values: interCrimeFilters?.classifications,
      },
      type: {
        label: 'Occurrence type',
        type: 'select',
        values: interCrimeFilters?.type,
      },
      concluded: {
        label: 'Finalised',
        type: 'select',
        values: interCrimeFilters?.concluded,
      },
      status: {
        label: 'Status',
        type: 'select',
        values: interCrimeFilters?.status,
      },
    },
    stopCheck: {
      classifications: {
        label: 'Classification',
        type: 'select',
        values: interCrimeFilters?.classifications,
      },
      sourceReliability: {
        label: 'Source Reliability',
        type: 'select',
        values: interCrimeFilters?.sourceReliability,
      },
    },
  };

  const driver = {
    ..._.omit(filters.person, 'radioSsi'),
    identificationReference,
  };
  const lastDriver = driver;

  return {
    ...filters,
    driver,
    lastDriver,
  };
}

export function getClientFilters(features) {
  const properties = features
    .map(({ properties }) => ({
      ...properties,
      sort: properties.startTime ?? properties.openedTime ?? properties.time,
    }))
    .sort((a, b) => a.sort - b.sort);

  function uniqueValues(entity, key) {
    const values = [
      ...new Set(
        properties
          .map(({ [entity]: { [key]: value } = {}, [key]: altValue }) =>
            value === undefined ? altValue : value,
          )
          .filter(Boolean)
          .sort(),
      ),
    ];

    return (values.length === 0 ? ['none'] : values).map((value) => ({
      label: value,
      value,
    }));
  }

  function uniqueLocationsValues(keyInLocation) {
    const values = [
      ...new Set(
        properties.flatMap((property) =>
          (property.locations ?? [])
            .map((location) => location[keyInLocation])
            .filter(Boolean),
        ),
      ),
    ].sort();

    return (values.length === 0 ? ['none'] : values).map((value) => ({
      label: value,
      value,
    }));
  }

  function fileValues(key) {
    const values = [
      ...new Set(
        properties
          .map(({ fileProperties }) => fileProperties || {})
          .map(({ [key]: value = {} }) => value)
          .filter(Boolean),
      ),
    ].map((item) => ({
      label: item.toString(),
      value: item,
    }));

    return values;
  }

  const file = Object.entries(properties[0].fileProperties || {})
    .map(([key]) => ({
      [key]: {
        label: key,
        type: 'multiselect',
        values: fileValues(key),
      },
    }))
    .reduce((acc, item) => ({ ...acc, ...item }), {});

  // a person can have several aliases so need to try them all
  function uniquePersonValues(property) {
    const values = [
      ...new Set(
        properties
          .map(
            ({
              person: { [property]: value } = {},
              driver: { [property]: otherValue } = {},
              lastDriver: { [property]: anotherValue } = {},
            }) => value || otherValue || anotherValue,
          )
          .filter(Boolean)
          .sort(),
      ),
    ];

    return (values.length === 0 ? ['none'] : values).map((value) => ({
      label: value,
      value,
    }));
  }

  const location = {
    code: {
      label: 'Code',
      type: 'multiselect',
      values: uniqueValues('location', 'code'),
    },
    name: {
      label: 'Name',
      type: 'multiselect',
      values: uniqueValues('location', 'name'),
    },
    type: {
      label: 'Type',
      type: 'multiselect',
      values: uniqueValues('location', 'type'),
    },
    subtype: {
      label: 'Subtype',
      type: 'multiselect',
      values: uniqueValues('location', 'subtype'),
    },
  };

  // visitLocations have fewer types but as this is on the client side,
  // the filtering has already been done so these are the same
  const visitLocation = location;

  const person = {
    // code: {
    //   label: 'Payroll Number',
    //   values: state.people.people.map(({ code }) => ({
    //     label: code,
    //     value: code
    //   }))
    // },
    collarNumber: {
      label: 'Collar Number',
      type: 'multiselect',
      values: uniquePersonValues('collarNumber'),
    },
    radioSsi: {
      label: 'Radio SSI',
      type: 'multiselect',
      values: uniquePersonValues('radioSsi'),
    },
    'rank.code': {
      label: 'Rank',
      type: 'multiselect',
      values: _.uniqBy(
        properties.map(({ person, driver, lastDriver }) => ({
          label: (person || driver || lastDriver)?.rank?.name,
          value: (person || driver || lastDriver)?.rank?.code,
        })),
        'value',
      ).filter((v) => v.value),
    },
    role: {
      label: 'Role',
      type: 'multiselect',
      values: uniquePersonValues('role'),
    },
    homeStation: {
      label: `Home ${baseType.label}`,
      type: 'multiselect',
      values: uniquePersonValues('homeStation'),
    },
  };
  const clientPerson = _.omit(person, 'radioSsi');
  const driver = {
    ...clientPerson,
    identificationReference: {
      label: useDallasKeys ? 'Dallas Key' : 'RFID Card',
      type: 'multiselect',
      values: uniquePersonValues('identificationReference'),
    },
  };
  const lastDriver = driver;

  return {
    vehicle: {
      identificationNumber: {
        label: 'VIN',
        type: 'multiselect',
        values: uniqueValues('vehicle', 'identificationNumber'),
      },
      registrationNumber: {
        label: 'Registration',
        type: 'multiselect',
        values: uniqueValues('vehicle', 'registrationNumber'),
      },
      fleetNumber: {
        label: 'Fleet Number',
        type: 'multiselect',
        values: uniqueValues('vehicle', 'fleetNumber'),
      },
      role: {
        label: 'Role',
        type: 'multiselect',
        values: uniqueValues('vehicle', 'role'),
      },
      type: {
        label: 'Type',
        type: 'multiselect',
        values: uniqueValues('vehicle', 'type'),
      },
      homeStation: {
        label: `Home ${baseType.label}`,
        type: 'multiselect',
        values: uniqueValues('vehicle', 'homeStation'),
      },
      // 'areas@name': getAreaValues(vehicleGroups)
    },
    person,
    clientPerson,
    driver,
    lastDriver,
    location,
    visitLocation,
    incident: {
      openedTime: {
        label: 'Opened Time',
        type: 'date',
      },
      'locations.type': {
        label: 'Location Type',
        type: 'multiselect',
        values: uniqueLocationsValues('type'),
      },
      'locations.name': {
        label: 'Location',
        type: 'multiselect',
        values: uniqueLocationsValues('name'),
      },
      date: {
        label: 'Date',
        type: 'date',
        onlyEqual: true,
        parse: (x) => {
          const date = new Date(x);
          if (isValid(date)) {
            return format(date, 'yyyy-MM-dd');
          }
          return '';
        },
      },
      reference: {
        label: 'Incident Number',
        value: 'reference',
        type: 'number',
        onlyEqual: true,
        // parse: (x) => x?.toString() || '',
      },
      'type.code': {
        label: 'Type',
        type: 'multiselect',
        values: uniqueValues('type', 'code'),
      },
      'responseCategory.code': {
        label: 'Response Category',
        type: 'multiselect',
        values: uniqueValues('responseCategory', 'code'),
      },
      responseSeconds: {
        label: 'Response Duration',
        type: 'duration',
      },
      grade: {
        label: 'Grade',
        type: 'multiselect',
        values:
          incidentGrades ??
          Array(6)
            .fill()
            .map((_, i) => ({
              label: `${i + 1}`,
              value: i + 1,
            })),
      },
      status: {
        label: 'Status',
        type: 'multiselect',
        values: uniqueValues('incident', 'status'),
      },
      'ward.name': {
        label: 'Ward',
        type: 'multiselect',
        values: uniqueValues('incident', 'ward.name'),
      },
      'ward.type': {
        label: 'Ward Type',
        type: 'select',
        values: uniqueValues('incident', 'ward.type'),
      },
      // 'closingCodes@code': {
      //   label: 'Closing Code',
      //   type: 'autocomplete',
      //   values: [
      //     ...new Set(
      //       features.map(
      //         ({
      //           properties: {
      //             closingCodes: { code: value }
      //           }
      //         }) => value
      //       )
      //     )
      //   ].map(value => ({
      //     label: value,
      //     value
      //   }))
      // }
    },
    trip: {
      startTime: {
        label: 'Start Time',
        type: 'date',
      },
      endTime: {
        label: 'End Time',
        type: 'date',
      },
      durationSeconds: {
        label: 'Duration',
        type: 'duration',
      },
      maxSpeedKilometresPerHour: {
        label: 'Max Speed',
        type: 'miles',
        unit: 'mph',
      },
      // 'startLocations@name': {
      //   label: 'Start Location Name',
      //   type: 'autocomplete',
      //   values: state.locations.locations
      //     .filter(location => location.name)
      //     .map(({ name }) => ({
      //       label: name,
      //       value: name
      //     }))
      // },
      // 'startLocations@type': {
      //   label: 'Start Location Type',
      //   type: 'select',
      //   values: Object.values(locationTypes).sort(compareLabels)
      // },
      // 'endLocations@name': {
      //   label: 'End Location Name',
      //   type: 'autocomplete',
      //   values: state.locations.locations
      //     .filter(location => location.name)
      //     .map(({ name }) => ({
      //       label: name,
      //       value: name
      //     }))
      // },
      // 'endLocations@type': {
      //   label: 'End Location Type',
      //   type: 'select',
      //   values: Object.values(locationTypes).sort(compareLabels)
      // }
    },
    personTrail: {
      startTime: {
        label: 'Start Time',
        type: 'date',
      },
      endTime: {
        label: 'End Time',
        type: 'date',
      },
    },
    personPoll: {
      time: {
        label: 'Time',
        type: 'date',
      },
    },
    stop: {
      startTime: {
        label: 'Start Time',
        type: 'date',
      },
      endTime: {
        label: 'End Time',
        type: 'date',
      },
      durationSeconds: {
        label: 'Duration',
        type: 'duration',
      },
      // 'locations@name': {
      //   label: 'Location Name',
      //   type: 'autocomplete',
      //   values: state.locations.locations
      //     .filter(location => location.name)
      //     .map(({ name }) => ({
      //       label: name,
      //       value: name
      //     }))
      // },
      // 'locations@type': {
      //   label: 'Location Type',
      //   type: 'select',
      //   values: Object.values(locationTypes).sort(compareLabels)
      // }
    },
    file,
    aggregate: {
      count: {
        label: 'Count',
        type: 'number',
      },
    },
    crime: {
      Occurrence__ClassificationG: {
        label: 'Classifications',
        type: 'select',
        values: uniqueValues('crime', 'Occurrence__ClassificationG'),
      },
      Occurrence__UCRClearanceStatusG: {
        label: 'Incident status',
        type: 'select',
        values: uniqueValues('crime', 'Occurrence__UCRClearanceStatusG'),
      },
      Occurrence__UCRClearanceStatus: {
        label: 'Incident grade',
        type: 'select',
        values: uniqueValues('crime', 'Occurrence__UCRClearanceStatus'),
      },
      Occurrence__DispatchOccType: {
        label: 'Dispatch type',
        type: 'select',
        values: uniqueValues('crime', 'Occurrence__DispatchOccType'),
      },
      Occurrence__Concluded: {
        label: 'Finalised',
        type: 'select',
        values: uniqueValues('crime', 'Occurrence__Concluded'),
      },

      Occurrence__ESAreaLevel1: {
        label: 'Ward',
        type: 'select',
        values: uniqueValues('crime', 'Occurrence__ESAreaLevel1'),
      },
      Occurrence__ESAreaLevel2: {
        label: 'Beat',
        type: 'select',
        values: uniqueValues('crime', 'Occurrence__ESAreaLevel2'),
      },
      Occurrence__ESAreaLevel3: {
        label: 'Sector',
        type: 'select',
        values: uniqueValues('crime', 'Occurrence__ESAreaLevel3'),
      },
      Occurrence__ESAreaLevel4: {
        label: 'Section',
        type: 'select',
        values: uniqueValues('crime', 'Occurrence__ESAreaLevel4'),
      },
      Occurrence__ESAreaLevel5: {
        label: 'BCU',
        type: 'select',
        values: uniqueValues('crime', 'Occurrence__ESAreaLevel5'),
      },
      Occurrence__ESAreaLevel6: {
        label: 'Force',
        type: 'select',
        values: uniqueValues('crime', 'Occurrence__ESAreaLevel6'),
      },
      Occurrence__ESAreaLevel7: {
        label: 'Local authority',
        type: 'select',
        values: uniqueValues('crime', 'Occurrence__ESAreaLevel7'),
      },
    },
    stopCheck: {
      SCOccurrence__CreTime: {
        label: 'Created Time',
        type: 'date',
      },
    },
  };
}

export const searchFilter =
  (searchText) =>
  ({ properties: { vehicle, person, driver, location, ...event } }) => {
    if (!searchText) {
      return true;
    } else {
      const isMatch = Object.values({
        ...vehicle,
        ...person,
        ...driver,
        ...location,
        ...event,
      }).some((value) =>
        value
          ?.toString()
          ?.toLowerCase()
          ?.includes((searchText || '').toLowerCase()),
      );

      return isMatch;
    }
  };

export function match(value, condition, filterValue) {
  switch (condition) {
    case '$eq':
      return value === filterValue;
    case '$ne':
      return value !== filterValue;
    case '$gt':
      return value > filterValue;
    case '$lt':
      return value < filterValue;
    case '$gte':
      return value >= filterValue;
    case '$lte':
      return value <= filterValue;
    case '$in':
      return filterValue.length === 0 || filterValue.includes?.(value) || false;
    default:
      return false;
  }
}

export const fieldFilter = (filters) => (feature) => {
  for (let filter of filters) {
    const fieldName = Object.keys(filter)[0];
    const value = fieldName.split('.').reduce((o, i) => {
      if (Array.isArray(o)) {
        return o.map((elementInArray) => elementInArray[i]);
      } else {
        return o?.[i];
      }
    }, feature.properties.fileProperties || feature.properties);
    const [condition, filterValue] = Object.entries(filter[fieldName])[0];
    let isMatch;
    if (Array.isArray(value)) {
      isMatch = value.some((e) => match(e, condition, filterValue));
    } else {
      isMatch = match(value, condition, filterValue);
    }
    if (!isMatch) {
      return false;
    }
  }

  return true;
};

export function mongoizeFilters(filters = {}) {
  const units = {
    s: 1,
    m: 60,
    h: 3600,
    d: 86400,
  };

  let mongoizedFilters = [];
  Object.keys(filters).forEach((group) => {
    filters[group]
      .filter(({ value }) =>
        Array.isArray(value)
          ? value.length > 0
          : value !== undefined && value !== '',
      )
      .forEach(({ field, condition, value, unit }) => {
        if (Array.isArray(value)) {
          condition = '$in';
        }

        mongoizedFilters.push({
          [group === 'event' || group === 'file' ? field : `${group}.${field}`]:
            {
              [condition]: unit ? value * units[unit] : value,
            },
        });
      });
  });

  return mongoizedFilters;
}

// Temporary functions
export function getPrimaryText(feature) {
  const props = feature.properties;

  switch (props.source) {
    case 'speedInfractions':
    case 'vehicleTrips':
    case 'vehicleStops':
    case 'vehicleCustomVisits':
    case 'vehicleVisits':
    case 'vehicleIdles':
      return (
        (props.vehicle || props)?.registrationNumber ||
        (props.vehicle || props)?.fleetNumber ||
        (props.vehicle || props)?.telematicsBoxImei
      );
    case 'accelerometerEvents':
    case 'accelerometerAlerts':
    case 'vehiclePolls':
      return (
        (props.vehicle || props)?.registrationNumber ||
        (props.vehicle || props)?.fleetNumber ||
        props?.imei
      );
    case 'incidents':
      return `Incident ${props.reference}, ${props.date
        .split('-')
        .reverse()
        .join('/')}`;
    case 'personPolls':
    case 'personTrails':
    case 'personCustomVisits':
    case 'personVisits':
      return (
        (props.person ?? props)?.collarNumber ||
        (props.person ?? props)?.code ||
        (props.person ?? props)?.personCode ||
        props.person?.radioSsi ||
        props.ssi
      );
    case 'clusters':
      return props.id;
    case 'areas':
    case 'groups':
    case 'locations':
      return props.name;
    case 'crimes':
      return props.Occurrence__OccurrenceFileNoG || props.id;
    case 'stopChecks':
      return props.SCOccurrence__OccurrenceFileNoG || props.id;
    default:
      return '';
  }
}

export function getSecondaryText(feature) {
  const props = feature.properties;

  switch (feature.properties.source) {
    case 'speedInfractions':
    case 'vehicleTrips':
    case 'vehicleStops':
    case 'vehicleCustomVisits':
    case 'vehicleVisits':
    case 'vehicleIdles':
    case 'personTrails':
    case 'personCustomVisits':
    case 'personVisits':
    case 'accelerometerEvents':
      return `${format(
        new Date(props.startTime),
        'dd/MM/yyyy HH:mm',
      )} - ${format(new Date(props.endTime), 'dd/MM/yyyy HH:mm')}`;
    case 'accelerometerAlerts':
    case 'vehiclePolls':
    case 'personPolls':
      return format(
        new Date(props.time ?? props.startTime),
        'dd/MM/yyyy HH:mm',
      );
    case 'incidents':
      return `${props.type?.code ?? ''} ${props.type?.name ?? ''}`;
    case 'areas':
    case 'groups':
      if (props.measure !== undefined && props.measure.includes('Time')) {
        return shortHumanizer(props.count);
      } else {
        return `${props.count} ${_.lowerCase(
          _.replace(props.measure, 'Count', ''),
        )}s`;
      }
    case 'locations':
      return props.code;
    case 'clusters':
      return '';
    case 'crimes':
      return props.Occurrence__OccurrenceType;
    case 'stopChecks':
      return props.SCOccurrence__Type1G;
    default:
      return '';
  }
}

export function tooManyMapItems(layer) {
  const mapItems = (layer.featureCollection?.features || []).reduce(
    (total, feature) =>
      total +
      (feature.geometry?.coordinates || []).reduce(
        (subtotal, coordinateArray) =>
          subtotal +
          (Array.isArray(coordinateArray) ? coordinateArray.length : 0),
        0,
      ),
    0,
  );
  const virtualizeAt = retrospective?.virtualizeAt?.mapFeaturesSize || 20000;

  return mapItems > virtualizeAt;
}

export function orderAndFilterFeatures(layer) {
  const filters = mongoizeFilters(layer?.clientFilters || {});
  const { searchText, sort } = layer;
  const orderBy = parseSort(sort);

  return _.orderBy(
    (layer?.featureCollection?.features || [])
      .filter(searchFilter(searchText))
      .filter(fieldFilter(filters)),
    orderBy.fields,
    orderBy.directions,
  );
}

const limits = retrospective?.limits;
export function getLimits(layer) {
  const defaultLimit = limits?.default;
  const typeDefault = limits?.[layer.type]?.default;
  const typeAndSourceLimit = limits?.[layer.type]?.[layer.source];

  // if these are all undefined limit will be {} not undefined
  return _.merge(defaultLimit, typeDefault, typeAndSourceLimit);
}

export function exceedsLimits(layer) {
  const limit = getLimits(layer);

  // if limit has no keys there's no limit; reach for the sky
  if (Object.keys(limit).length === 0) {
    return false;
  }

  let brokenLimits = {};
  Object.keys(limit).forEach((key) => {
    if (limit[key] < layer.estimate?.[key]) {
      brokenLimits[key] = {
        limit: limit[key],
        estimate: layer.estimate?.[key],
      };
    }
  });

  // if nothing is broken return false, else return the broken limits
  return Object.keys(brokenLimits).length > 0 && brokenLimits;
}

const totalLimitKey = 'mapFeaturesSize'; // we only care about this for the total
export function totalExceedsLimits(layers) {
  const allEstimates = layers.map((layer) => layer.estimate);
  const totalEstimate = allEstimates.reduce(
    (total, estimate) =>
      _.mergeWith(
        total,
        estimate,
        (totalValue = 0, estimateValue = 0) => totalValue + estimateValue,
      ),
    {},
  );

  const totalLimitsExceeded = exceedsLimits({ estimate: totalEstimate });
  if (totalLimitsExceeded?.[totalLimitKey]) {
    return {
      limitsExceeded: _.pick(totalLimitsExceeded, totalLimitKey),
      totalEstimate,
    };
  }

  return false;
}

export const defaultLayerValues = {
  blur: 10,
  radius: 10,
  precision: 3,
  distance: 7,
};

import { useDebounce } from '@/hooks';
import { useEffect, useState } from 'react';
import { TextField } from './TextField';

export function DebouncedTextField({
  input: { onChange, value, ...input },
  ...props
}) {
  const [text, setText] = useState(value);
  const debouncedText = useDebounce(text, 300);

  useEffect(() => {
    onChange(debouncedText);
  }, [debouncedText, onChange]);

  return (
    <TextField
      {...props}
      {...input}
      onChange={({ target: { value } }) => {
        setText(value);
      }}
      value={text}
    />
  );
}

import { DataGrid } from '@/components/controls';
import { useUncheckedVehicles } from '@/hooks';
import { Box, Card, CardContent, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';

const columns = [
  {
    accessorKey: 'registrationNumber',
    header: 'Registration',
    size: 0,
    Cell: ({ row: { original } }) => (
      <Tooltip
        title={
          <table>
            <tbody>
              <tr>
                <td>Fleet Number</td>
                <td>{original.fleetNumber}</td>
              </tr>
              <tr>
                <td>VIN</td>
                <td>{original.identificationNumber}</td>
              </tr>
            </tbody>
          </table>
        }
        placement="right"
      >
        <Box component="span">{original.registrationNumber}</Box>
      </Tooltip>
    ),
  },
  {
    accessorKey: 'homeStation.code',
    header: 'Home Station',
    size: 0,
  },
  {
    accessorKey: 'lastPollTime',
    header: 'Last Poll Date',
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
    size: 0,
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy'),
  },
  {
    accessorKey: 'lastCheck.time',
    header: 'Last Check Date',
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
    size: 0,
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy'),
  },
];

export function UncheckedVehiclesCard({ groupType, groupId }) {
  const { data } = useUncheckedVehicles(groupType, groupId);

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          Unchecked Vehicles
        </Typography>
        <DataGrid
          enableSorting={true}
          enableBottomToolbar={true}
          enablePagination={true}
          data={data}
          columns={columns}
        />
      </CardContent>
    </Card>
  );
}

import { TextFieldController } from '@/components/controls';
import { useLocations } from '@/hooks';
import { locationTypes } from '@/utils/config';
import { Fragment } from 'react';

export function LocationPicker({ control, subtype, setValue, disabled }) {
  const { data: locations } = useLocations(subtype);

  function handleSubtypeChange() {
    setValue('boundaryIdentifier', '');
  }

  return (
    <Fragment>
      <TextFieldController
        name="boundarySubtype"
        control={control}
        label="Subtype"
        options={Object.values(locationTypes)}
        rules={{ required: 'Required' }}
        disabled={disabled}
        onChange={handleSubtypeChange}
      />
      <TextFieldController
        name="boundaryIdentifier"
        control={control}
        label="Name"
        options={locations.map(({ code, name }) => ({
          label: name,
          value: code,
        }))}
        rules={{ required: 'Required' }}
        disabled={disabled}
      />
    </Fragment>
  );
}

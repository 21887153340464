import { api } from '@/apis';
import { db } from '@/data';
import { getAuthorization } from '@/utils';
import {
  apiRootUrl,
  authenticationRootUrl,
  authenticationScheme,
  authorizationOverrides,
  clientId,
  resource,
  sentry,
  tenantId,
} from '@/utils/config';
import { setUser } from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import ky from 'ky';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const redirectUri = `${window.location.origin}`;

export function useAuth() {
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();

  useEffect(() => {
    window.addEventListener('storage', storageChange);

    return () => {
      window.removeEventListener('storage', storageChange);
    };
  }, []);

  function storageChange(event) {
    if (event.key === 'access_token') {
      setToken(event.newValue);
    }
  }

  function login() {
    const state = Math.floor(Math.random() * 10000);
    localStorage.setItem('state', state);
    const authUrl = `${authenticationRootUrl}/${tenantId}/${authenticationScheme}/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(
      redirectUri,
    )}&response_mode=query&state=${state}&resource=${encodeURIComponent(
      resource,
    )}`;
    window.location.href = authUrl;
  }

  function getAccessToken() {
    return ky
      .post(`${apiRootUrl}/${authenticationScheme}/token`, {
        body: new URLSearchParams({
          grant_type: 'authorization_code',
          client_id: clientId,
          code: searchParams.get('code'),
          redirect_uri: redirectUri,
          resource,
        }),
      })
      .json();
  }

  function getUserInfo() {
    return api
      .get('userinfo', { headers: { 'cache-control': 'no-store' } })
      .json();
  }

  async function handleAuthentication() {
    if (searchParams.get('state') === localStorage.getItem('state')) {
      const authResult = await getAccessToken();
      if (
        authResult &&
        authResult.access_token &&
        authResult.id_token &&
        authResult.refresh_token
      ) {
        const expiresAt = JSON.stringify(
          authResult.expires_in * 1000 + new Date().getTime(),
        );
        localStorage.setItem('access_token', authResult.access_token);
        localStorage.setItem('id_token', authResult.id_token);
        localStorage.setItem('refresh_token', authResult.refresh_token);
        localStorage.setItem('expires_at', expiresAt);

        const userInfo = await getUserInfo();
        localStorage.setItem('emailAddress', userInfo.emailAddress);
        localStorage.setItem('surname', userInfo.surname);
        localStorage.setItem('forenames', userInfo.forenames);
        localStorage.setItem('displayName', userInfo.displayName);
        localStorage.setItem('username', userInfo.username);
        localStorage.setItem('identifiers', JSON.stringify(userInfo.groups));
        localStorage.setItem(
          'authorisation',
          JSON.stringify(userInfo.authorisation),
        );
        localStorage.setItem('person', JSON.stringify(userInfo.person));

        if (sentry.enable) {
          setUser({
            username: userInfo.username,
            email: userInfo.emailAddress,
          });
        }

        navigate('/', { replace: true });
      }
    }
    localStorage.removeItem('state');
  }

  async function logout() {
    const authorization = await getAuthorization();
    await ky.delete(`${apiRootUrl}/${authenticationScheme}/logout`, {
      headers: { authorization },
    });

    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('expires_at');

    localStorage.removeItem('emailAddress');
    localStorage.removeItem('surname');
    localStorage.removeItem('forenames');
    localStorage.removeItem('displayName');
    localStorage.removeItem('username');
    localStorage.removeItem('identifiers');
    localStorage.removeItem('authorisation');
    localStorage.removeItem('person');

    db.delete();

    queryClient.clear();

    window.location.href = `${authenticationRootUrl}/${tenantId}/${authenticationScheme}/logout`;
  }

  function isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  function getAuthorisation() {
    return {
      ...(JSON.parse(localStorage.getItem('authorisation')) || {}),
      ...(authorizationOverrides || {}),
    };
  }

  function isAuthorised(type, canEdit) {
    const authorisation = getAuthorisation();

    if (type === 'resources') {
      return canEdit
        ? (authorisation.vehicles || {}).write ||
            (authorisation.people || {}).write ||
            (authorisation.vehicles || {}).write ||
            (authorisation.telematicsBoxes || {}).write
        : (authorisation.vehicles || {}).read ||
            (authorisation.people || {}).read ||
            (authorisation.vehicles || {}).read ||
            (authorisation.telematicsBoxes || {}).read;
    } else {
      return canEdit
        ? (authorisation[type] || {}).write
        : (authorisation[type] || {}).read;
    }
  }

  function getUsername() {
    return localStorage.getItem('username');
  }

  function getDirectoryGroupIdentifiers() {
    const identifiers = JSON.parse(localStorage.getItem('identifiers'));

    return identifiers || [];
  }

  function getGroupNames() {
    return getAuthorisation().names || [];
  }

  function getProfile() {
    const person = JSON.parse(localStorage.getItem('person'));

    return person || {};
  }

  return {
    login,
    handleAuthentication,
    logout,
    isAuthenticated,
    isAuthorised,
    getUsername,
    getDirectoryGroupIdentifiers,
    getGroupNames,
    getProfile,
    getAuthorisation,
    token,
  };
}

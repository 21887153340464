import { mapStyleAtom } from '@/atoms';
import { Attribution, MapButton } from '@/components/controls';
import {
  getFeatureCollectionFromMicrobeats,
  getMicrobeatFromLngLat,
  getMicrobeatFromText,
  transformRequest,
} from '@/utils';
import { maxBounds, maxZoom, minZoom, styles } from '@/utils/config';
import {
  Layers as LayersIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  FormHelperText,
  ListSubheader,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Stack,
  TextField,
  useTheme,
} from '@mui/material';
import bbox from '@turf/bbox';
import { useAtom } from 'jotai';
import 'maplibre-gl/dist/maplibre-gl.css';
import { Fragment, useMemo, useRef, useState } from 'react';
import { useController } from 'react-hook-form';
import { Layer, Map, ScaleControl, Source } from 'react-map-gl/maplibre';

export function MicrobeatsController({ name, control, disabled, rules }) {
  const {
    field: { value, disabled: isDisabled, onChange },
    fieldState: { error, invalid },
  } = useController({
    name,
    control,
    disabled,
    rules,
    defaultValue: [],
  });

  return (
    <MicrobeatsPicker
      value={value}
      onChange={onChange}
      disabled={isDisabled}
      error={error}
      invalid={invalid}
    />
  );
}

function MicrobeatsPicker({ value, onChange, disabled, error, invalid }) {
  const [style, setStyle] = useAtom(mapStyleAtom);
  const mapRef = useRef();
  const [styleMenuAnchorEl, setStyleMenuAnchorEl] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const theme = useTheme();
  const data = useMemo(
    () => getFeatureCollectionFromMicrobeats(value || []),
    [value],
  );
  const bounds = useMemo(
    () => (data.features.length > 0 ? bbox(data) : null),
    [data],
  );
  const [viewState, setViewState] = useState({
    minZoom,
    maxZoom,
    maxBounds,
    initialViewState: {
      bounds,
      fitBoundsOptions: { padding: 40 },
    },
  });
  const [isLoaded, setIsLoaded] = useState(false);

  function handleMove(event) {
    setViewState(event.viewState);
  }

  function handleStyleClick(event) {
    setStyleMenuAnchorEl(event.currentTarget);
  }

  function handleStyleMenuClose() {
    setStyleMenuAnchorEl(null);
  }

  const handleStyleMenuClick = (path) => () => {
    setStyle(path);
    setStyleMenuAnchorEl(null);
  };

  function handleZoomInClick() {
    mapRef.current.zoomIn();
  }

  function handleZoomOutClick() {
    mapRef.current.zoomOut();
  }

  function handleFitClick() {
    mapRef.current.fitBounds(bounds, { padding: 40, duration: 1000 });
  }

  function handleClick(event) {
    const selections = value || [];
    const microbeat = getMicrobeatFromLngLat(event.lngLat);

    if (selections.includes(microbeat)) {
      onChange(selections.filter((id) => id !== microbeat));
    } else {
      onChange(selections.concat(microbeat));
    }
  }

  function handleChange(event, value, reason) {
    if (reason === 'createOption') {
      if (inputValue.length === 12) {
        const microbeat = getMicrobeatFromText(inputValue);

        if (!value.includes(microbeat)) {
          onChange([...value, microbeat]);
        }
      }
    } else {
      onChange(value);
    }
  }

  function handleInputChange(event, value, reason) {
    if (reason === 'reset' && inputValue.length === 12) {
      setInputValue(value);
    } else {
      if (
        reason === 'input' &&
        (value === '' || /^[0-9]+$/.test(value)) &&
        value.length < 13
      ) {
        setInputValue(value);
      }
    }
  }

  function handleLoad() {
    setIsLoaded(true);
  }

  return (
    <Fragment>
      <Autocomplete
        fullWidth
        size="small"
        multiple
        disabled={disabled}
        freeSolo
        value={value || []}
        options={[]}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        isOptionEqualToValue={() => false}
        renderInput={(params) => (
          <TextField
            label="Codes"
            {...params}
            helperText={error}
            error={invalid}
          />
        )}
      />
      <Paper
        elevation={0}
        variant="outlined"
        sx={{
          position: 'relative',
          width: 1,
          minWidth: 240,
          height: 'calc(100vh - 254px)',
          borderColor: error && 'error.main',
        }}
      >
        <Map
          {...viewState}
          mapStyle={style}
          onMove={handleMove}
          transformRequest={transformRequest(style)}
          onClick={!disabled && handleClick}
          cooperativeGestures={true}
          cursor="auto"
          ref={mapRef}
          onLoad={handleLoad}
          style={{ borderRadius: 3 }}
        >
          <ScaleControl />
          <Stack sx={{ position: 'absolute', top: 8, left: 8 }}>
            <MapButton
              title="Zoom in"
              onClick={handleZoomInClick}
              disabled={
                mapRef.current &&
                mapRef.current.getZoom() === mapRef.current.getMaxZoom()
              }
            >
              <ZoomInIcon />
            </MapButton>
            <MapButton
              title="Zoom out"
              onClick={handleZoomOutClick}
              disabled={
                mapRef.current &&
                mapRef.current.getZoom() === mapRef.current.getMinZoom()
              }
            >
              <ZoomOutIcon />
            </MapButton>
            <MapButton
              title="Fit map"
              onClick={handleFitClick}
              disabled={(value || []).length === 0}
            >
              <ZoomOutMapIcon />
            </MapButton>
            <MapButton title="Change map style" onClick={handleStyleClick}>
              <LayersIcon />
            </MapButton>
            <Menu
              anchorEl={styleMenuAnchorEl}
              open={Boolean(styleMenuAnchorEl)}
              onClose={handleStyleMenuClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <MenuList
                disablePadding
                dense
                subheader={<ListSubheader disableSticky>Type</ListSubheader>}
              >
                {styles.map((layer) => (
                  <MenuItem
                    key={layer.path}
                    onClick={handleStyleMenuClick(layer.path)}
                    selected={layer.path === style}
                  >
                    {layer.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Stack>
          {invalid && (
            <FormHelperText
              sx={{ position: 'absolute', bottom: 40, left: 16, zIndex: 1 }}
              error={invalid}
            >
              {error}
            </FormHelperText>
          )}
          {(value || []).length > 0 && (
            <Source id="microbeats" type="geojson" data={data}>
              <Layer
                type="fill"
                paint={{
                  'fill-color': theme.palette.secondary.main,
                  'fill-opacity': 0.4,
                  'fill-outline-color': theme.palette.secondary.dark,
                }}
              />
            </Source>
          )}
          <Attribution style={style} />
        </Map>
        <Backdrop
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            position: 'absolute',
          }}
          open={!isLoaded}
        >
          <CircularProgress />
        </Backdrop>
      </Paper>
    </Fragment>
  );
}

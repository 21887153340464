import { PlayArrow as PlayArrowIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export function ReplayLink({ entry }) {
  const navigate = useNavigate();

  const handleClick = (identifier) => (event) => {
    event.stopPropagation();

    navigate(`/eventreplay/trips/${identifier}`);
  };

  return (
    <Tooltip title="View replay">
      <IconButton size="small" onClick={handleClick(entry.identifier)}>
        <PlayArrowIcon />
      </IconButton>
    </Tooltip>
  );
}

import { briefingApi } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useAddUrgentActionUpdate() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ code, message }) => {
      return briefingApi
        .post(`urgentActions/${code}/update`, { body: message })
        .json();
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['urgentActions'] }),
  });
}

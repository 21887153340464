import {
  FETCH_USER_IDS,
  FETCH_USER_IDS_FAILURE,
  FETCH_USER_IDS_SUCCESS,
} from '@/actions';
import { api } from '@/apis';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

async function fetchUserIdsRequest() {
  const response = await api.get('audits/fields/user/values').json();

  const userIds = response;
  userIds.sort();

  return userIds;
}

export function fetchUserIdsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_USER_IDS),
    mergeMap(() =>
      from(fetchUserIdsRequest()).pipe(
        map((payload) => ({
          type: FETCH_USER_IDS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_USER_IDS_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

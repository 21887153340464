import { api } from '@/apis';
import { encodeParams } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useBoundary(type, id) {
  return useQuery({
    queryKey: [type, id],
    queryFn: async () => {
      switch (type) {
        case 'Location': {
          const searchParams = encodeParams({ projection: { boundary: true } });
          const location = await api
            .get(`locations/${id}`, { searchParams })
            .json();

          return location?.boundary;
        }
        default:
          return null;
      }
    },
    enabled: !!type && !!id,
  });
}

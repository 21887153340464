import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';
import { startOfDay, startOfToday, subDays } from 'date-fns';

export function useSubgroupActivityAverages(
  groupType,
  groupId,
  subgroupType,
  days,
) {
  return useQuery({
    queryKey: [
      'subgroupActivityAverages',
      groupType,
      groupId,
      subgroupType,
      days,
    ],
    queryFn: () => {
      const start = subDays(startOfDay(new Date()), days);
      const json = [
        {
          $match: {
            time: { $gte: start, $lt: startOfToday() },
            [`person.groups.${groupType}`]: groupId,
          },
        },
        subgroupType !== 'collarNumber' && {
          $unwind: {
            path: `$person.groups.${subgroupType}`,
            preserveNullAndEmptyArrays: true,
          },
        },
        {
          $group: {
            _id: {
              $ifNull: [
                subgroupType === 'collarNumber'
                  ? '$person.collarNumber'
                  : `$person.groups.${subgroupType}`,
                'Unknown',
              ],
            },
            loggedInSeconds: { $avg: '$loggedInSeconds' },
            inBaseSeconds: { $avg: '$inBaseSeconds' },
            respondingToIncidentSeconds: {
              $avg: '$respondingToIncidentSeconds',
            },
            attendingObjectiveSeconds: { $avg: '$attendingObjectiveSeconds' },
            tripsDurationSeconds: { $avg: '$tripsDurationSeconds' },
            inHomeWardSeconds: { $avg: '$inHomeWardSeconds' },
            doubleCrewingSeconds: { $avg: '$doubleCrewingSeconds' },
          },
        },
        {
          $project: {
            _id: false,
            identifier: '$_id',
            loggedInHours: { $divide: ['$loggedInSeconds', 3600] },
            inBaseHours: { $divide: ['$inBaseSeconds', 3600] },
            respondingToIncidentHours: {
              $divide: ['$respondingToIncidentSeconds', 3600],
            },
            attendingObjectiveHours: {
              $divide: ['$attendingObjectiveSeconds', 3600],
            },
            tripsDurationHours: { $divide: ['$tripsDurationSeconds', 3600] },
            inHomeWardHours: { $divide: ['$inHomeWardSeconds', 3600] },
            doubleCrewingHours: { $divide: ['$doubleCrewingSeconds', 3600] },
          },
        },
        { $sort: { inBaseSeconds: 1 } },
      ].filter(Boolean);

      return api.post('pipeline/personDailySummaries', { json }).json();
    },
    placeholderData: [],
  });
}

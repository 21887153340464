import {
  AutocompleteController,
  ContentEditorController,
  DatePickerController,
  ToggleButtonGroupController,
} from '@/components//controls';
import { SwitchController, TextFieldController } from '@/components/controls';
import { ConfirmationDialog } from '@/components/dialogs';
import {
  useAuth,
  useDeleteObjective,
  useDocumentTitle,
  useObjective,
  usePeople,
  useUpsertObjective,
} from '@/hooks';
import { isNotTooBig, startCase } from '@/utils';
import {
  activeDirectoryGroups,
  objectiveForm,
  occurrenceNumberOnObjectives,
  useRestricted,
} from '@/utils/config';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormGroup,
  FormLabel,
  Stack,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { ApplicableToCodesController } from './ApplicableToCodesController';
import { BoundaryViewer } from './BoundaryViewer';
import { LocationPicker } from './LocationPicker';
import { WeeklySchedulePickerController } from './WeeklySchedulePickerController';

export function Objective() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: objective } = useObjective(id);
  useDocumentTitle(
    `IR3 | Objective | ${objective?._id ? objective.title : 'New Objective'}`,
  );
  const { mutate: upsertObjective } = useUpsertObjective();
  const { mutate: deleteObjective } = useDeleteObjective();
  const { data: people } = usePeople();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const auth = useAuth();
  const canEdit = auth.isAuthorised('objectives', true);
  const emailAddresses = useMemo(
    () =>
      [...new Set(people.map((item) => item.emailAddress).filter(Boolean))]
        .sort()
        .map((item) => ({ label: item, value: item })),
    [people],
  );
  const {
    control,
    formState: { isDirty, isSubmitting },
    getValues,
    handleSubmit,
    reset,
    resetField,
    setValue,
    watch,
  } = useForm({ defaultValues: objective });

  useEffect(() => {
    reset(objective);
  }, [reset, objective]);

  function handleCancel() {
    reset(objective);
  }

  function toggleDeleteDialog() {
    setDeleteOpen((prev) => !prev);
  }

  function handleDelete() {
    deleteObjective(id, {
      onSuccess: () => {
        navigate('..', { replace: true });
      },
    });
  }

  function submitCallback(values) {
    upsertObjective(values, {
      onSuccess: (data) => {
        navigate(`../${data.identifier}`, { replace: true });
      },
    });
  }

  function isDisabled(fieldName) {
    return !(
      canEdit &&
      (objectiveForm[fieldName]?.editable || !objective?._id)
    );
  }

  function handleResourceTypeChange() {
    resetField('personCodes');
    resetField('vehicleIdentificationNumbers');
    resetField('personGroupCodes');
    resetField('vehicleGroupCodes');
  }

  return (
    <Box sx={{ flexGrow: 1, p: 1 }}>
      <form id="objective-form" onSubmit={handleSubmit(submitCallback)}>
        <Stack spacing={1}>
          <Stack spacing={1} direction="row" justifyContent="space-between">
            <Typography variant="h6" sx={{ p: 1 }}>
              {objective?._id ? objective.title : 'New Objective'}
            </Typography>
            <Stack>
              {watch('created') && (
                <Typography variant="caption" textAlign="right">
                  {`Created by ${getValues('created').userId} ${format(
                    getValues('created').time,
                    'dd/MM/yyyy HH:mm',
                  )}`}
                </Typography>
              )}
              {watch('lastEdit') && (
                <Typography variant="caption" textAlign="right">
                  {`Last edited by ${getValues('lastEdit').userId} ${format(
                    getValues('lastEdit').time,
                    'dd/MM/yyyy HH:mm',
                  )}`}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Stack spacing={1} direction="row" flexWrap="wrap">
            <TextFieldController
              name="title"
              label="Title"
              control={control}
              disabled={isDisabled('title')}
              rules={{ required: 'Required', maxLength: 50 }}
            />
            {occurrenceNumberOnObjectives && (
              <TextFieldController
                name="occurrenceNumber"
                label="Occurrence Number"
                control={control}
                disabled={isDisabled('occurenceNumber')}
                rules={{ maxLength: 30 }}
              />
            )}
            {useRestricted && (
              <SwitchController
                name="visibleTo"
                label="Restricted"
                control={control}
                disabled={isDisabled('visibleTo')}
                parse={(value) => !!value}
                format={(value) => (value ? [] : null)}
              />
            )}
          </Stack>
          {watch('visibleTo') && (
            <Fragment>
              <Divider>
                <Typography variant="caption" color="textSecondary">
                  Visible to
                </Typography>
              </Divider>
              <AutocompleteController
                name="visibleTo"
                control={control}
                fullWidth
                options={activeDirectoryGroups}
                disabled={isDisabled('visibleTo')}
              />
            </Fragment>
          )}
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Description
            </Typography>
          </Divider>
          <ContentEditorController
            name="description"
            placeholder="Write something..."
            control={control}
            disabled={isDisabled('description')}
            rules={{ required: 'Required', validate: isNotTooBig }}
          />
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Active Time
            </Typography>
          </Divider>
          <Stack spacing={1} direction="row" flexWrap="wrap">
            <DatePickerController
              name="startTime"
              label="Start Date"
              control={control}
              disabled={isDisabled('startTime')}
              rules={{ required: 'Required' }}
              minDate={watch('startTime') ?? new Date()}
              maxDate={watch('endTime') ?? new Date('2100-01-01')}
            />
            <DatePickerController
              name="endTime"
              label="End Date"
              control={control}
              disabled={isDisabled('endTime')}
              rules={{ required: 'Required' }}
              minDate={watch('startTime') ?? new Date()}
              maxDate={watch('endTime') ?? new Date('2100-01-01')}
            />
          </Stack>
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Weekly Schedule
            </Typography>
          </Divider>
          <WeeklySchedulePickerController
            name="schedule"
            control={control}
            disabled={isDisabled('schedule')}
            rules={{ required: 'Required' }}
          />
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Requirements
            </Typography>
          </Divider>
          <Stack spacing={1} direction="row" flexWrap="wrap">
            <TextFieldController
              name="requiredVisits"
              label="Number of Visits"
              control={control}
              valueAsNumber
              disabled={isDisabled('requiredVisits')}
            />
            <TextFieldController
              name="requiredFrequency"
              label="Frequency"
              control={control}
              options={['total', 'daily', 'hourly'].map((item) => ({
                label: startCase(item),
                value: item,
              }))}
              disabled={isDisabled('requiredFrequency')}
            />
            <Divider orientation="vertical" flexItem />
            <TextFieldController
              name="complianceSeconds"
              label="Compliant Minutes"
              control={control}
              valueAsNumber
              disabled={isDisabled('complianceSeconds')}
              parse={(value) =>
                value == null || value === '' ? '' : value / 60
              }
              format={(value) => (value === '' ? '' : value * 60)}
            />
          </Stack>
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Applicable to
            </Typography>
          </Divider>
          <Stack spacing={1}>
            <ToggleButtonGroupController
              name="resourceType"
              control={control}
              rules={{ required: 'Required' }}
              disabled={isDisabled('applicableTo')}
              values={[
                { label: 'People', value: 'people' },
                { label: 'Vehicles', value: 'vehicles' },
                { label: 'Groups of People', value: 'personGroups' },
                { label: 'Groups of Vehicles', value: 'vehicleGroups' },
              ]}
              onChange={handleResourceTypeChange}
            />
            <ApplicableToCodesController
              control={control}
              resourceType={watch('resourceType')}
              isDisabled={isDisabled}
            />
          </Stack>
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Notifications
            </Typography>
          </Divider>
          <Stack spacing={1}>
            <FormControl
              sx={{ pl: 1.5 }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend">
                <Typography variant="caption">Type</Typography>
              </FormLabel>
              <FormGroup row>
                <SwitchController
                  name="notifications.onEntry"
                  label="Entry"
                  control={control}
                />
                <SwitchController
                  name="notifications.onExit"
                  label="Exit"
                  control={control}
                />
              </FormGroup>
            </FormControl>
            {(watch('notifications.onEntry') ||
              watch('notifications.onExit')) && (
              <AutocompleteController
                name="notifications.recipients"
                control={control}
                label="Recipients"
                fullWidth
                options={emailAddresses}
                disabled={!canEdit}
              />
            )}
          </Stack>
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Boundary
            </Typography>
          </Divider>
          <Stack spacing={1} direction="row" flexWrap="wrap">
            <TextFieldController
              name="boundaryType"
              control={control}
              label="Type"
              options={['Location', 'Microbeats', 'Custom']}
              disabled={isDisabled('boundary')}
              rules={{ required: 'Required' }}
              onChange={() => {
                setValue('boundary', null);
                setValue('microbeats', []);
                setValue('boundaryIdentifier', '');
                setValue('boundarySubtype', '');
              }}
            />
            {watch('boundaryType') === 'Location' && (
              <LocationPicker
                control={control}
                subtype={watch('boundarySubtype')}
                setValue={setValue}
                disabled={isDisabled('boundary')}
              />
            )}
          </Stack>
          <BoundaryViewer
            control={control}
            type={watch('boundaryType')}
            id={watch('boundaryIdentifier')}
            disabled={isDisabled('boundary')}
          />
          {canEdit && (
            <Stack spacing={1} direction="row">
              <Button
                color="primary"
                type="submit"
                disabled={!isDirty || isSubmitting}
              >
                Save
              </Button>
              <Button
                color="primary"
                disabled={!isDirty || isSubmitting}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                color="error"
                onClick={toggleDeleteDialog}
                disabled={!watch('_id')}
              >
                Delete
              </Button>
            </Stack>
          )}
        </Stack>
      </form>
      <ConfirmationDialog
        action="Delete"
        open={deleteOpen}
        itemId={objective?.title || objective?.identifier}
        onOk={handleDelete}
        onCancel={toggleDeleteDialog}
      />
    </Box>
  );
}

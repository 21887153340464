import { api } from '@/apis';
import { encodeParams } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import { startOfDay, subDays, subMonths } from 'date-fns';

function getCounts(vehicles, today) {
  const sixMonths = vehicles.filter(
    (vehicle) => vehicle.lastPollTime < subMonths(today, 6),
  ).length;
  const oneMonth =
    vehicles.filter((vehicle) => vehicle.lastPollTime < subMonths(today, 1))
      .length - sixMonths;
  const twoWeeks = vehicles.length - oneMonth - sixMonths;

  return {
    twoWeeks,
    oneMonth,
    sixMonths,
  };
}

export function useNonPollingVehicles(groupType) {
  return useQuery({
    queryKey: ['nonPollingVehicles', groupType],
    queryFn: async () => {
      const now = new Date();
      const today = startOfDay(now);
      const twoWeeksAgo = subDays(today, 14);

      const searchParams = encodeParams({
        query: {
          lastPollTime: { $lt: twoWeeksAgo },
          $or: [
            { disposalDate: { $exists: false } },
            { disposalDate: { $gte: now } },
          ],
        },
        projection: {
          identificationNumber: true,
          registrationNumber: true,
          fleetNumber: true,
          homeStation: true,
          lastPollTime: true,
          groups: true,
        },
        sort: { lastPollTime: 1 },
      });

      const vehicles = await api.get('vehicles', { searchParams }).json();
      const counts = {};
      if (groupType) {
        for (const vehicle of vehicles) {
          const groups = vehicle?.groups?.[groupType] ?? ['None'];
          for (const group of groups) {
            counts[group] = [...(counts[group] ?? []), vehicle];
          }
        }
        for (const group in counts) {
          counts[group] = { group, ...getCounts(counts[group], today) };
        }
      }

      return {
        vehicles,
        ...getCounts(vehicles, today),
        counts,
      };
    },
    placeholderData: {
      vehicles: [],
      twoWeeks: 0,
      oneMonth: 0,
      sixMonths: 0,
      counts: {},
    },
  });
}

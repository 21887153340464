import { GroupsPicker } from '@/components/controls';

export function GroupsField({ input: { value, onChange }, groups, disabled }) {
  return (
    <GroupsPicker
      value={value}
      onChange={onChange}
      groups={groups}
      disabled={disabled}
    />
  );
}

import { api } from '@/apis';
import { encodeParams, log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

// TODO: Find out what this is for
const excludeList = [
  'Vehicles',
  'People',
  'Locations',
  'Features',
  'Objectives',
  'Telematics Boxes',
  'Retrospectives',
  'Telematics Box Polls',
];

export function useAuditLogEntries(startTime, endTime, userId) {
  return useQuery({
    queryKey: ['auditLogEntries', startTime, endTime, userId],
    queryFn: async () => {
      const searchParams = encodeParams({
        query: {
          time: { $gte: startTime, $lt: endTime },
          user: userId ? userId : undefined,
        },
        projection: {
          user: true,
          dataType: true,
          time: true,
          action: true,
          parameters: true,
        },
        sort: { time: -1 },
      });

      log('Read', 'Audit Log Entries', {
        startTime,
        endTime,
        userId,
      });

      const entries = await api.get('audits', { searchParams }).json();

      return entries
        .filter((entry) => !excludeList.includes(entry.dataType))
        .map(({ user, ...entry }) => {
          const parameters = entry.parameters || {};

          return {
            ...entry,
            userId: user,
            itemId:
              parameters.id ||
              parameters.identifier ||
              parameters.code ||
              parameters.identificationNumber ||
              null,
            startTime:
              parameters.startTime || parameters.startTime
                ? new Date(parameters.startTime || parameters.startTime)
                : null,
            endTime:
              parameters.endTime || parameters.endTime
                ? new Date(parameters.endTime || parameters.endTime)
                : null,
          };
        });
    },
    placeholderData: [],
    enabled: false,
  });
}

import { useDocumentTitle } from '@/hooks';
import ir3Logo from '@/svg/ir3Logo.svg';
import lbaLogo from '@/svg/lbaLogo.svg';
import { announcement } from '@/utils/config';
import { Box, Divider, Stack, Typography } from '@mui/material';

export function Home() {
  useDocumentTitle('IR3 | Home');

  return (
    <Stack sx={{ height: 'calc(100vh - 48px)' }}>
      <Stack
        sx={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}
      >
        <img src={ir3Logo} alt="IR3 Logo" style={{ height: 200 }} />
        <Typography
          component="h1"
          sx={{
            fontWeight: 'bold',
          }}
          variant="h1"
          align="center"
          color="primary"
          gutterBottom
        >
          IR3
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="textSecondary"
          component="p"
          maxWidth={600}
        >
          IR3 records a complete picture of resource behaviour in response to
          demand. It uses spatial analysis algorithms to classify resource
          activity in real time and retrospectively. IR3 can inform strategic
          and tactical decision making to optimise resource allocation and
          productivity against prioritised demand.
        </Typography>
        {announcement && (
          <Box sx={{ p: [2, 6] }}>
            <Typography variant="subtitle1" sx={{ color: 'error.main' }}>
              Announcement
            </Typography>
            <Typography variant="body1">{announcement}</Typography>
          </Box>
        )}
      </Stack>
      <Divider />
      <Stack direction="row" spacing={1} justifyContent="center" sx={{ p: 2 }}>
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          component="p"
        >
          {`© ${new Date().getFullYear()} `}
        </Typography>
        <img
          src={lbaLogo}
          alt="Lightbulb Analytics"
          style={{ height: 30, verticalAlign: 'middle' }}
        />
      </Stack>
    </Stack>
  );
}

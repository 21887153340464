import { useUser } from '@/hooks';
import { ListItem, ListItemText, Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';

export function UrgentActionUpdate({ update }) {
  const { data: user } = useUser(update.userCode);

  return (
    <ListItem disablePadding>
      <ListItemText
        primary={
          <Typography variant="caption" color="text.secondary">
            {user
              ? `${user.name} - ${formatDistanceToNow(
                  new Date(update.time),
                )} ago`
              : '-'}
          </Typography>
        }
        secondary={
          <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
            {update.message}
          </Typography>
        }
      />
    </ListItem>
  );
}

import { api } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertScene() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ _id, groupAncestors: _, ...json }) => {
      if (_id) {
        return api.put(`locations/${json.code}`, { json }).json();
      } else {
        return api.post('locations', { json }).json();
      }
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['scenes'] }),
  });
}

import {
  FETCH_GAZETTEER_RESULTS,
  FETCH_GAZETTEER_RESULTS_FAILURE,
  FETCH_GAZETTEER_RESULTS_SUCCESS,
} from '@/actions';
import { log } from '@/utils';
import { nominatimRootUrl } from '@/utils/config';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators';

export function fetchGazetteerResultsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_GAZETTEER_RESULTS),
    debounceTime(300),
    mergeMap(({ payload: search }) =>
      // could get the shape too and show on the map with &polygon_geojson=1
      ajax({
        url: `${nominatimRootUrl}/search?q=${search}&format=json`,
        method: 'GET',
      }).pipe(
        map(({ response }) => {
          log('Read', 'Gazetteer', { search });

          // console.log(response);
          function addType(result) {
            result.display_name += ` (${result.type})`;
          }

          // add the type to any duplicate names e.g. two results might be "Cardiff"
          // change it so these read "Cardiff (city)" and "Cardiff (administrative)"
          let names = {};
          response.forEach((result, index) => {
            if (result.display_name in names) {
              // don't add type multiple times to the first one
              if (names[result.display_name] !== -1) {
                addType(response[names[result.display_name]]);
                names[result.display_name] = -1;
              }

              addType(result);
            } else {
              names[result.display_name] = index;
            }
          });

          return {
            type: FETCH_GAZETTEER_RESULTS_SUCCESS,
            payload: response,
          };
        }),
        catchError((error) => {
          console.error(error);
          return of({
            type: FETCH_GAZETTEER_RESULTS_FAILURE,
            payload: error,
          });
        }),
      ),
    ),
  );
}

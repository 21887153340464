import { mapStyleAtom } from '@/atoms';
import { transformRequest } from '@/utils';
import { styles } from '@/utils/config';
import {
  Layers as LayersIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListSubheader,
  Menu,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
} from '@mui/material';
import bbox from '@turf/bbox';
import { useAtom } from 'jotai';
import 'maplibre-gl/dist/maplibre-gl.css';
import { useRef, useState } from 'react';
import Map, {
  Layer,
  Marker,
  ScaleControl,
  Source,
} from 'react-map-gl/maplibre';
import { Attribution } from './Attribution';
import { MapButton } from './MapButton';
import { usePlace } from '@/hooks';

export function LocationMapDialog({
  title,
  postCode,
  address,
  isOpen,
  onClose,
}) {
  const mapRef = useRef();
  const [style, setStyle] = useAtom(mapStyleAtom);
  const [styleMenuAnchorEl, setStyleMenuAnchorEl] = useState(null);
  const { data: place } = usePlace(postCode);
  const location = place?.features[0];
  const geojson = place
    ? {
        type: 'FeatureCollection',
        features: [{ type: 'Feature', geometry: location?.geometry }],
      }
    : null;
  const boundingBox = place
    ? bbox({
        type: 'Feature',
        geometry: location?.geometry,
      })
    : null;

  function handleStyleClick(event) {
    setStyleMenuAnchorEl(event.currentTarget);
  }

  function handleStyleMenuClose() {
    setStyleMenuAnchorEl(null);
  }

  const handleStyleMenuClick = (path) => () => {
    setStyle(path);
    setStyleMenuAnchorEl(null);
  };

  function handleZoomInClick() {
    mapRef.current.zoomIn();
  }

  function handleZoomOutClick() {
    mapRef.current.zoomOut();
  }
  function handleFitClick() {
    mapRef.current.fitBounds(bbox(geojson), {
      maxZoom: 16,
      padding: 32,
      duration: 1000,
    });
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={false}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {place ? (
          <Box sx={{ paddingBottom: 1, width: 640, height: 480 }}>
            <Map
              ref={mapRef}
              initialViewState={{
                bounds: [boundingBox.slice(0, 2), boundingBox.slice(2, 4)],
                fitBoundsOptions: { maxZoom: 16, padding: 32 },
              }}
              mapStyle={style}
              transformRequest={transformRequest(style)}
              style={{ borderRadius: 4 }}
            >
              <Stack sx={{ position: 'absolute', top: 8, left: 8 }}>
                <MapButton
                  title="Zoom in"
                  onClick={handleZoomInClick}
                  disabled={
                    mapRef.current &&
                    mapRef.current.getZoom() === mapRef.current.getMaxZoom()
                  }
                >
                  <ZoomInIcon />
                </MapButton>
                <MapButton
                  title="Zoom out"
                  onClick={handleZoomOutClick}
                  disabled={
                    mapRef.current &&
                    mapRef.current.getZoom() === mapRef.current.getMinZoom()
                  }
                >
                  <ZoomOutIcon />
                </MapButton>
                <MapButton
                  title="Fit map"
                  onClick={handleFitClick}
                  disabled={!geojson}
                >
                  <ZoomOutMapIcon />
                </MapButton>
                <MapButton title="Change map style" onClick={handleStyleClick}>
                  <LayersIcon />
                </MapButton>
                <Menu
                  anchorEl={styleMenuAnchorEl}
                  open={Boolean(styleMenuAnchorEl)}
                  onClose={handleStyleMenuClose}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                  <MenuList
                    disablePadding
                    dense
                    subheader={
                      <ListSubheader disableSticky>Type</ListSubheader>
                    }
                  >
                    {styles.map((layer) => (
                      <MenuItem
                        key={layer.path}
                        onClick={handleStyleMenuClick(layer.path)}
                        selected={layer.path === style}
                      >
                        {layer.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Stack>
              <ScaleControl />
              <Source id="location" type="geojson" data={geojson}>
                <TypedLayer type={location?.geometry.type} />
              </Source>
              <MapMarker geometry={location?.geometry} />
              <Attribution style={style} />
            </Map>
          </Box>
        ) : (
          <Skeleton variant="rectangular" width={640} height={480} />
        )}
        <DialogContentText>{address}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

function TypedLayer({ type }) {
  const pointLayer = {
    id: 'point',
    type: 'circle',
    source: 'location',
    paint: {
      'circle-radius': 10,
      'circle-color': 'rgb(0, 0, 0)',
    },
  };

  const polygonLayer = {
    id: 'polygon',
    type: 'fill',
    source: 'location',
    paint: {
      'fill-color': 'rgba(0, 0, 0, 0.5)',
    },
  };

  const lineLayer = {
    id: 'line',
    type: 'line',
    source: 'location',
    paint: {
      'line-color': 'rgb(0, 0, 0)',
      'line-dasharray': [4, 4],
      'line-width': 2,
    },
  };

  switch (type) {
    case 'Point':
      return <Layer {...pointLayer} />;
    case 'LineString':
      return <Layer {...lineLayer} />;
    case 'Polygon':
    default:
      return <Layer {...polygonLayer} />;
  }
}

function MapMarker({ geometry }) {
  return (
    <Marker
      longitude={geometry.coordinates[0]}
      latitude={geometry.coordinates[1]}
      anchor="center"
    >
      <svg viewBox="0 0 36 36" width="24" height="24">
        <circle style={{ fill: 'rgb(255, 255, 255)' }} cx="18" cy="18" r="18" />
        <circle style={{ fill: 'rgb(0, 0, 0)' }} cx="18" cy="18" r="16" />
        <g style={{ fill: 'rgb(255, 255, 255)' }} transform="translate(6 6)">
          <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
        </g>
      </svg>
    </Marker>
  );
}

import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';
import { startOfDay, subMonths, subYears } from 'date-fns';

export function useIncidentCountByGrade(groupType, groupId) {
  return useQuery({
    queryKey: ['incidentCountByGrade', groupType, groupId],
    queryFn: () => {
      const today = startOfDay(new Date());
      const oneYearAgo = import.meta.env.DEV
        ? new Date('2020-01-01')
        : subYears(startOfDay(today), 1);
      const sixMonthsAgo = import.meta.env.DEV
        ? new Date('2021-01-01')
        : subMonths(startOfDay(today), 6);
      const oneMonthAgo = import.meta.env.DEV
        ? new Date('2023-01-01')
        : subMonths(startOfDay(today), 1);
      const json = [
        {
          $match: {
            openedTime: { $gt: oneYearAgo },
            ...(import.meta.env.DEV
              ? {}
              : { [`ward.groups.${groupType}`]: groupId }),
          },
        },
        {
          $group: {
            _id: '$grade',
            oneYear: { $sum: 1 },
            sixMonths: {
              $sum: {
                $cond: [{ $gte: ['$openedTime', sixMonthsAgo] }, 1, 0],
              },
            },
            oneMonth: {
              $sum: { $cond: [{ $gte: ['$openedTime', oneMonthAgo] }, 1, 0] },
            },
          },
        },
        {
          $project: {
            _id: false,
            grade: '$_id',
            oneYear: true,
            sixMonths: true,
            oneMonth: true,
          },
        },
        { $sort: { grade: 1 } },
      ];

      return api.post('pipeline/incidents', { json }).json();
    },
    placeholderData: [],
  });
}

import { AntisocialBehaviour } from './AntisocialBehaviour';
import { Arrests } from './Arrests/Arrests';
import { Burgalaries } from './Burglaries';
import { InspectorsNoticeBoard } from './InspectorsNoticeBoard';
import { MentalHealthIncidents } from './MentalHealthIncidents/MentalHealthIncidents';
import { MissingPersons } from './MissingPersons';
import { Placeholder } from './Placeholder';
import { PriorityIntelligence } from './PriorityIntelligence';
import { Prisoners } from './Prisoners';
import { PrisonRecalls } from './PrisonerRecalls/PrisonRecalls';
import { SexualOffences } from './SexualOffences';
import { SuddenDeaths } from './SuddenDeaths/SuddenDeaths';
import { Suspects } from './Suspects';
import { Teams } from './Teams';
import { UrgentActions } from './UrgentActions';
import { Vehicles } from './Vehicles';
import { ViolentCrimes } from './ViolentCrimes';
import { Vulnerables } from './Vulnerables';
import { Watches } from './Watches';

export function Section({ id, ...props }) {
  switch (id) {
    case 'teams':
      return <Teams {...props} />;
    case 'vehicles':
      return <Vehicles {...props} />;
    case 'inspectorsNoticeBoard':
      return <InspectorsNoticeBoard {...props} />;
    case 'urgentActions':
      return <UrgentActions {...props} />;
    case 'missingPersons':
      return <MissingPersons {...props} />;
    case 'priorityIntelligence':
      return <PriorityIntelligence {...props} />;
    case 'suspects':
      return <Suspects {...props} />;
    case 'prisoners':
      return <Prisoners {...props} />;
    case 'watches':
      return <Watches {...props} />;
    case 'antisocialBehaviour':
      return <AntisocialBehaviour {...props} />;
    case 'sexualOffences':
      return <SexualOffences {...props} />;
    case 'burglaries':
      return <Burgalaries {...props} />;
    case 'vulnerable':
      return <Vulnerables {...props} />;
    case 'arrests':
      return <Arrests {...props} />;
    case 'suddenDeaths':
      return <SuddenDeaths {...props} />;
    case 'mentalHealthIncidents':
      return <MentalHealthIncidents {...props} />;
    case 'violentCrimes':
      return <ViolentCrimes {...props} />;
    case 'prisonRecalls':
      return <PrisonRecalls {...props} />;
    case 'bailChecks':
    case 'returns':
    case 'community':
    case 'outstandingSuspects':
    case 'outstandingWarrants':
    case 'firearmsIncidents':
    case 'pursuits':
    case 'seriousCollisions':
    case 'domesticAbuseStalkingHarassment':
    case 'crimesAgainstStaff':
    case 'repeatVictims':
    case 'hateCrimes':
    case 'useOfForceOutstanding':
    case 'oldOccurrences':
    case 'liveCaseFiles':
    default:
      return <Placeholder id={id} {...props} />;
  }
}

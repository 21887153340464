import { DataGrid } from '@/components/controls';
import { Typography } from '@mui/material';
import { Fragment } from 'react';

const columns = [
  {
    accessorKey: 'objective',
    header: 'Objective Id',
  },
  {
    accessorKey: 'requiredFrequency',
    header: 'Frequency',
  },
  {
    accessorKey: 'compliancePercent',
    header: 'Compliance',
    Cell: ({ cell }) => cell.getValue().toFixed(2) + '%',
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
  },
];

export function ObjectivesTable({ data, groupByKey }) {
  return (
    data && (
      <Fragment>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          {`${groupByKey} Objectives`}
        </Typography>
        <DataGrid
          enableSorting={true}
          enableBottomToolbar={true}
          enablePagination={true}
          columns={columns}
          data={data}
        />
      </Fragment>
    )
  );
}

import { highlightsStateAtom } from '@/atoms';
import { personGroups } from '@/utils/config';
import { Settings as SettingsIcon } from '@mui/icons-material';
import {
  Box,
  Drawer,
  IconButton,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { useAtom } from 'jotai';
import { Fragment, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const tabs = [
  {
    label: 'BCU',
    value: '/highlights/basiccommandunit',
    to: 'basiccommandunit',
  },
  { label: 'Sector', value: '/highlights/sector', to: 'sector' },
  { label: 'Team', value: '/highlights/team', to: 'team' },
  { label: 'Fleet', value: '/highlights/fleet', to: 'fleet' },
];

export function HighlightsToolbar() {
  const { pathname } = useLocation();
  const [showSettings, setShowSettings] = useState(false);
  const [{ basicCommandUnit, sector, team }, setState] =
    useAtom(highlightsStateAtom);

  const handleSettingChange = (key) => (event) => {
    setState((state) => ({ ...state, [key]: event.target.value }));
  };

  function handleSettingsClick() {
    setShowSettings(true);
  }

  function handleSettingsClose() {
    setShowSettings(false);
  }

  return (
    <Fragment>
      <Toolbar variant="dense" disableGutters>
        <Tabs
          textColor="inherit"
          indicatorColor="secondary"
          value={pathname === '/highlights' ? tabs[0].value : pathname}
        >
          {tabs.map(({ label, value, to }) => (
            <Tab
              key={to}
              label={label}
              value={value}
              to={to}
              component={Link}
            />
          ))}
        </Tabs>
        <Box sx={{ flex: 1 }} />
        <Tooltip title="Settings">
          <IconButton
            color="inherit"
            sx={{ mr: 2 }}
            onClick={handleSettingsClick}
          >
            <SettingsIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <Drawer
        open={showSettings}
        onClose={handleSettingsClose}
        anchor="right"
        sx={{
          width: 320,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 320,
          },
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
      >
        <Stack spacing={1} sx={{ p: 2 }}>
          <Typography variant="subtitle2" color="text.secondary">
            Settings
          </Typography>
          <TextField
            label="BCU"
            size="small"
            select
            value={basicCommandUnit}
            onChange={handleSettingChange('basicCommandUnit')}
          >
            {(personGroups?.bcu.values ?? []).map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Sector"
            size="small"
            select
            value={sector}
            onChange={handleSettingChange('sector')}
          >
            {(personGroups?.sector.values ?? []).map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Team"
            size="small"
            select
            value={team}
            onChange={handleSettingChange('team')}
          >
            {(personGroups?.team.values ?? []).map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </Drawer>
    </Fragment>
  );
}

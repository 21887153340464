import { useAggregate } from '@/hooks';
import { shortHumanizer } from '@/utils';
import { Box, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { MaterialReactTable } from 'material-react-table';

const equipmentLabels = {
  BEACONSON: 'Beacons',
  HEADLIGHTSFLASHON: 'Headlights Flash',
  SIRENSON: 'Sirens',
};

const defaultColumns = [
  {
    header: 'Start Time',
    id: 'startTime',
    key: 'startTime',
    accessorFn: ({ startTime }) =>
      startTime ? format(startTime, 'dd/MM/yyyy HH:mm:ss') : '',
  },
  {
    header: 'End Time',
    id: 'endTime',
    key: 'endTime',
    accessorFn: ({ endTime }) =>
      endTime ? format(endTime, 'dd/MM/yyyy HH:mm:ss') : '',
  },
  {
    header: 'Duration',
    id: 'durationSeconds',
    key: 'durationSeconds',
    accessorFn: ({ durationSeconds }) =>
      durationSeconds ? shortHumanizer(durationSeconds * 1000) : '',
  },
];

const equipmentActivationColumns = [
  {
    header: 'Equipment',
    id: 'eventSubtype',
    key: 'eventSubtype',
    accessorFn: ({ eventSubtype }) =>
      equipmentLabels[eventSubtype] ?? eventSubtype,
  },
  ...defaultColumns,
];

const responseColumns = [
  {
    header: 'Number',
    id: 'incident',
    key: 'incident',
    accessorFn: ({ incident }) => incident?.reference,
  },
  {
    header: 'Date',
    id: 'incident',
    key: 'incident',
    accessorFn: ({ incident }) =>
      incident?.date ? format(incident.date, 'dd/MM/yyyy HH:mm:ss') : '',
  },
  {
    header: 'Assigned Time',
    id: 'startTime',
    key: 'startTime',
    accessorFn: ({ startTime }) =>
      startTime ? format(startTime, 'dd/MM/yyyy HH:mm:ss') : '',
  },
  {
    header: 'Attended Time',
    id: 'attendedTime',
    key: 'attendedTime',
    accessorFn: ({ attendedTime }) =>
      attendedTime ? format(attendedTime, 'dd/MM/yyyy HH:mm:ss') : '',
  },
  {
    header: 'Unassigned Time',
    id: 'endTime',
    key: 'endTime',
    accessorFn: ({ endTime }) =>
      endTime ? format(endTime, 'dd/MM/yyyy HH:mm:ss') : '',
  },
];

function Table({ columns, data, title }) {
  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      enableBottomToolbar={false}
      enableColumnActions={false}
      enableColumnFilters={false}
      renderTopToolbar={() => (
        <Box sx={{ pl: 1, pt: 0.5 }}>
          <Typography variant="subtitle2" color="textSecondary">
            {title}
          </Typography>
        </Box>
      )}
      defaultColumn={{ size: 0 }}
      initialState={{
        density: 'compact',
      }}
      muiTableBodyRowProps={{
        sx: { '&:last-child td, &:last-child th': { border: 0 } },
      }}
      renderEmptyRowsFallback={() => (
        <Box sx={{ p: 1 }}>
          <Typography variant="caption" color="textSecondary">
            No events
          </Typography>
        </Box>
      )}
    />
  );
}

export function TripDetail({ trip }) {
  const { data: equipmentActivations } = useAggregate(
    'vehicleEquipmentActivations',
    [
      { $match: { 'parentEvent.identifier': trip.identifier } },
      {
        $project: {
          _id: false,
          startTime: true,
          endTime: true,
          eventSubtype: true,
          durationSeconds: true,
        },
      },
    ],
    !!trip.identifier,
  );
  const { data: idles } = useAggregate(
    'idles',
    [
      { $match: { 'parentEvent.identifier': trip.identifier } },
      {
        $project: {
          _id: false,
          startTime: true,
          endTime: true,
          durationSeconds: true,
        },
      },
    ],
    !!trip.identifier,
  );
  const { data: responses } = useAggregate(
    'personIncidentResponses',
    [
      {
        $match: {
          'person.code': trip.driver.code,
          startTime: { $lt: trip.endTime },
          endTime: { $gt: trip.startTime },
        },
      },
      {
        $project: {
          incident: {
            reference: true,
            date: true,
          },
          startTime: true,
          attendedTime: true,
          endTime: true,
        },
      },
    ],
    !!trip?.driver?.code,
  );

  return (
    <Stack spacing={2} direction="row">
      {responses?.length > 0 && (
        <Table
          columns={responseColumns}
          data={responses}
          title="Incident Responses"
        />
      )}
      {equipmentActivations?.length > 0 && (
        <Table
          columns={equipmentActivationColumns}
          data={equipmentActivations}
          title="Equipment Activations"
        />
      )}
      {idles?.length > 0 && (
        <Table columns={defaultColumns} data={idles} title="Idles" />
      )}
    </Stack>
  );
}

import {
  CREATE_PERSON,
  CREATE_PERSON_FAILURE,
  CREATE_PERSON_SUCCESS,
  DELETE_PERSON,
  DELETE_PERSON_FAILURE,
  DELETE_PERSON_SUCCESS,
  FETCH_PEOPLE,
  FETCH_PEOPLE_FAILURE,
  FETCH_PEOPLE_SUCCESS,
  FETCH_PERSON,
  FETCH_PERSON_FAILURE,
  FETCH_PERSON_SUCCESS,
  FETCH_POST_HIERARCHY,
  FETCH_POST_HIERARCHY_FAILURE,
  FETCH_POST_HIERARCHY_SUCCESS,
  UPDATE_PERSON,
  UPDATE_PERSON_FAILURE,
  UPDATE_PERSON_SUCCESS,
} from '@/actions';
import { fromAjax } from '@/apis';
import { getHeaders, log } from '@/utils';
import { personRanks } from '@/utils/config';
import _ from 'lodash';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

export function fetchPeopleEpic(action$) {
  return action$.pipe(
    ofType(FETCH_PEOPLE),
    mergeMap(() => from(getHeaders())),
    mergeMap((headers) =>
      fromAjax('/people', {
        params: {
          pipeline: [
            {
              $graphLookup: {
                from: 'groups',
                startWith: '$groupCodes',
                connectFromField: 'parentCodes',
                connectToField: 'code',
                as: 'groupAncestorCodes',
              },
            },
            {
              $project: {
                code: true,
                picture: true,
                forenames: true,
                surname: true,
                collarNumber: true,
                radioSsi: true,
                lastPollTime: true,
                rfidCards: true,
                role: true,
                homeStation: true,
                leavingDate: true,
                assignments: true,
                emailAddress: true,
                mobileNumber: true,
                groupAncestorCodes: {
                  $map: {
                    input: '$groupAncestorCodes',
                    as: 'group',
                    in: '$$group.code',
                  },
                },
              },
            },
          ],
        },
        headers,
      }).pipe(
        map(({ response }) => {
          const payload = (response || []).map((person) => {
            return {
              ...person,
              searchString: (
                `${person.forenames} ${person.surname}+` +
                `${_.join(
                  (person.rfidCards || []).map((card) => card.reference),
                  '+',
                )}+` +
                `${_.join(
                  _.values(
                    _.omit(person, [
                      'forenames',
                      'surname',
                      'rfidCards',
                      'picture',
                    ]),
                  ),
                  '+',
                )}`
              ).toLowerCase(),
            };
          });

          log('Read', 'People');

          return {
            type: FETCH_PEOPLE_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_PEOPLE_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function fetchPersonEpic(action$) {
  return action$.pipe(
    ofType(FETCH_PERSON),
    mergeMap(({ payload }) =>
      from(getHeaders().then((headers) => ({ payload, headers }))),
    ),
    mergeMap(({ payload: id, headers }) =>
      fromAjax('/people', {
        params: {
          pipeline: [
            { $match: { code: id } },
            {
              $graphLookup: {
                from: 'groups',
                startWith: '$groupCodes',
                connectFromField: 'parentCodes',
                connectToField: 'code',
                as: 'groupAncestors',
                depthField: 'depth',
              },
            },
            {
              $project: {
                code: true,
                picture: true,
                forenames: true,
                surname: true,
                collarNumber: true,
                emailAddress: true,
                mobileNumber: true,
                supervisorCode: true,
                postNumber: true,
                rank: true,
                role: true,
                homeStation: true,
                wards: true,
                skills: true,
                radioSsi: true,
                lastPollTime: true,
                rfidCards: true,
                ward: true,
                supervisorCodes: true,
                leavingDate: true,
                groups: true,
                assignments: true,
                visibleTo: true,
                attributes: true,
                groupCodes: true,
                groupAncestors: {
                  $map: {
                    input: {
                      $sortArray: {
                        input: {
                          $filter: {
                            input: '$groupAncestors',
                            cond: {
                              $not: [
                                { $in: ['$$ancestor.code', '$groupCodes'] },
                              ],
                            },
                            as: 'ancestor',
                          },
                        },
                        sortBy: { depth: -1, type: 1, name: 1 },
                      },
                    },
                    as: 'group',
                    in: {
                      code: '$$group.code',
                      name: '$$group.name',
                      type: '$$group.type',
                    },
                  },
                },
              },
            },
          ],
        },
        headers,
      }).pipe(
        map(({ response: [payload] }) => {
          log('Read', 'Person', { id });

          return {
            type: FETCH_PERSON_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_PERSON_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function createPersonEpic(action$) {
  return action$.pipe(
    ofType(CREATE_PERSON),
    mergeMap(({ payload, navigate }) =>
      from(getHeaders().then((headers) => ({ payload, navigate, headers }))),
    ),
    mergeMap(
      ({ payload: { groupAncestors: _, ...payload }, navigate, headers }) =>
        fromAjax('/people', {
          body: {
            ...payload,
            rank: payload.rank
              ? personRanks.find((item) => item.code === payload.rank.code)
              : undefined,
          },
          method: 'POST',
          headers: { ...headers, 'content-type': 'application/json' },
        }).pipe(
          map(({ response: payload }) => {
            log('Create', 'Person', payload);

            navigate(`../${payload.code}`, {
              replace: true,
              state: { created: true },
            });

            return {
              type: CREATE_PERSON_SUCCESS,
              payload,
            };
          }),
          catchError(({ message: payload }) =>
            of({
              type: CREATE_PERSON_FAILURE,
              payload,
            }),
          ),
        ),
    ),
  );
}

export function updatePersonEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_PERSON),
    mergeMap(({ payload }) =>
      from(getHeaders().then((headers) => ({ payload, headers }))),
    ),
    mergeMap(({ payload, headers }) =>
      fromAjax(`/people/${payload.code}`, {
        body: {
          ...payload,
          rank: payload.rank
            ? personRanks.find((item) => item.code === payload.rank.code)
            : undefined,
        },
        method: 'PATCH',
        headers: {
          ...headers,
          'content-type': 'application/merge-patch+json',
        },
      }).pipe(
        map(({ response: payload }) => {
          log('Update', 'Person', payload);

          return {
            type: UPDATE_PERSON_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_PERSON_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function deletePersonEpic(action$) {
  return action$.pipe(
    ofType(DELETE_PERSON),
    mergeMap(({ payload, navigate }) =>
      from(getHeaders().then((headers) => ({ payload, navigate, headers }))),
    ),
    mergeMap(({ payload: id, navigate, headers }) =>
      fromAjax(`/people/${id}`, {
        method: 'DELETE',
        headers,
      }).pipe(
        map(({ response }) => {
          log('Delete', 'Person', { id });

          navigate('..', { replace: true });

          return {
            type: DELETE_PERSON_SUCCESS,
            payload: response.code,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_PERSON_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function fetchPostHierarchyEpic(action$) {
  return action$.pipe(
    ofType(FETCH_POST_HIERARCHY),
    mergeMap(() => from(getHeaders())),
    mergeMap((headers) =>
      fromAjax('/groups/POSTS', {
        params: {
          query: {
            fields: ['data'],
          },
        },
        headers,
      }).pipe(
        map(({ response }) => {
          return {
            type: FETCH_POST_HIERARCHY_SUCCESS,
            payload: response.data,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_POST_HIERARCHY_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

import {
  CREATE_RFID_CARD,
  CREATE_RFID_CARD_FAILURE,
  CREATE_RFID_CARD_SUCCESS,
  DELETE_RFID_CARD,
  DELETE_RFID_CARD_FAILURE,
  DELETE_RFID_CARD_SUCCESS,
  FETCH_RFID_CARD,
  FETCH_RFID_CARDS,
  FETCH_RFID_CARDS_FAILURE,
  FETCH_RFID_CARDS_SUCCESS,
  FETCH_RFID_CARD_FAILURE,
  FETCH_RFID_CARD_SUCCESS,
  REMOVE_RFID_CARD,
  REMOVE_RFID_CARD_FAILURE,
  REMOVE_RFID_CARD_SUCCESS,
  UPDATE_RFID_CARD,
  UPDATE_RFID_CARD_FAILURE,
  UPDATE_RFID_CARD_SUCCESS,
} from '@/actions';

const INITIAL_STATE = {
  rfidCards: [],
  rfidCard: null,
  isLoading: false,
  error: null,
};

export default function rfidCardsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_RFID_CARDS:
      return { ...state, isLoading: true, error: null };
    case FETCH_RFID_CARDS_SUCCESS:
      return {
        ...state,
        rfidCards: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_RFID_CARDS_FAILURE:
      return {
        ...state,
        rfidCards: [],
        isLoading: false,
        error: action.payload,
      };
    case FETCH_RFID_CARD:
      return { ...state, isLoading: true, error: null };
    case FETCH_RFID_CARD_SUCCESS:
      return {
        ...state,
        rfidCard: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_RFID_CARD_FAILURE:
      return {
        ...state,
        rfidCard: null,
        isLoading: false,
        error: action.payload,
      };
    case CREATE_RFID_CARD:
      return { ...state, isLoading: true, error: null };
    case CREATE_RFID_CARD_SUCCESS:
      return {
        ...state,
        rfidCard: { people: [], ...action.payload },
        rfidCards: state.rfidCards.concat({
          assignmentCount: 0,
          ...action.payload,
        }),
        isLoading: false,
        error: null,
      };
    case CREATE_RFID_CARD_FAILURE:
      return {
        ...state,
        rfidCard: null,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_RFID_CARD:
      return { ...state, isLoading: true, error: null };
    case DELETE_RFID_CARD_SUCCESS:
      return {
        ...state,
        rfidCard: null,
        rfidCards: state.rfidCards.filter(
          (rfidCard) => rfidCard.reference !== action.payload,
        ),
        isLoading: false,
        error: null,
      };
    case DELETE_RFID_CARD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_RFID_CARD:
      return { ...state, isLoading: true, error: null };
    case UPDATE_RFID_CARD_SUCCESS: {
      const { people, ...payload } = action.payload;

      return {
        ...state,
        rfidCard: action.payload,
        rfidCards: state.rfidCards.map((rfidCard) =>
          rfidCard.reference === payload.reference
            ? {
                assignmentCount: people.length,
                ...payload,
              }
            : rfidCard,
        ),
        isLoading: false,
        error: null,
      };
    }
    case UPDATE_RFID_CARD_FAILURE:
      return {
        ...state,
        rfidCard: null,
        isLoading: false,
        error: action.payload,
      };
    case REMOVE_RFID_CARD:
      return { ...state, isLoading: true, error: null };
    case REMOVE_RFID_CARD_SUCCESS:
      return {
        ...state,
        rfidCard: {
          ...state.rfidCard,
          people: state.rfidCard.people.filter(
            (person) => person.code !== action.payload.code,
          ),
        },
        rfidCards: state.rfidCards.map((rfidCard) => ({
          ...rfidCard,
          assignmentCount:
            rfidCard.reference === action.payload.id
              ? rfidCard.assignmentCount - 1
              : rfidCard.assignmentCount,
        })),
        isLoading: false,
        error: null,
      };
    case REMOVE_RFID_CARD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

import { Avatar, Box, Checkbox, FormControl, InputLabel } from '@mui/material';

export function ToggleList({ value = [], onChange, values, ...props }) {
  function handleChange(event) {
    if (event.target.checked) {
      onChange([...value, parseInt(event.target.value, 10)]);
    } else {
      const index = value.indexOf(parseInt(event.target.value, 10));
      if (index !== -1) {
        onChange(value.slice(0, index).concat(value.slice(index + 1)));
      }
    }
  }

  return (
    <Box>
      <FormControl sx={{ pl: 1, width: 1 }} disabled={props.disabled}>
        <InputLabel sx={{ pl: 0.2, pt: 0.2 }} shrink>
          {props.label}
        </InputLabel>
        <Box sx={{ pt: 1, pb: 1 }}>
          {values.map((label, index) => (
            <Checkbox
              key={index}
              checked={value.includes(index)}
              onChange={handleChange}
              value={index.toString()}
              sx={{ p: 0.5 }}
              icon={
                <Avatar
                  sx={{
                    color: 'primary.contrastText',
                    width: 24,
                    height: 24,
                    fontSize: 12,
                    opacity: props.disabled && 0.5,
                  }}
                >
                  {label}
                </Avatar>
              }
              checkedIcon={
                <Avatar
                  sx={{
                    color: 'primary.contrastText',
                    bgcolor: 'primary.main',
                    width: 24,
                    height: 24,
                    fontSize: 12,
                    opacity: props.disabled && 0.5,
                  }}
                >
                  {label}
                </Avatar>
              }
            />
          ))}
        </Box>
      </FormControl>
    </Box>
  );
}

import { useNichePerson } from '@/hooks';
import {
  Avatar,
  CardMedia,
  ImageListItem,
  ImageListItemBar,
} from '@mui/material';
import { Fragment } from 'react';
import defaultPersonImage from '@/img/defaultPhotoImage.jpeg';

export function PersonPreview({ code }) {
  const { data: nichePerson } = useNichePerson(code);

  return (
    !!nichePerson &&
    nichePerson.length > 0 && (
      <Fragment>
        <ImageListItem>
          {nichePerson[0].person?.image ? (
            <CardMedia component="img" image={nichePerson[0].person?.image} />
          ) : (
            <CardMedia
              component="img"
              sx={{ maxWidth: 240 }}
              src={defaultPersonImage}
            />
          )}
          <ImageListItemBar
            title={`${nichePerson[0].person?.firstName} ${nichePerson[0].person?.surname}`}
            subtitle={nichePerson[0].person?.nominalId}
            //position={!nichePerson[0].person?.image ? 'below' : 'bottom'}
          />
        </ImageListItem>
      </Fragment>
    )
  );
}

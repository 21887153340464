import { useQuery } from '@tanstack/react-query';
import ky from 'ky';

export function usePlace(postCode) {
  return useQuery({
    queryKey: ['places', postCode],
    queryFn: () => {
      return ky
        .get(
          //`https://nominatim.ir3.dev/details?place_id=${id}&polygon_geojson=1&format=json`,
          `https://nominatim.ir3.dev/search?q=${postCode}&format=geojson&polygon_geojson=1`,
        )
        .json();
    },
    enabled: !!postCode,
    staleTime: 1000 * 60 * 60 * 24,
  });
}

import { Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material';
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';

export function SearchBox({ sx, count, value, onChange, ...props }) {
  function handleClearClick() {
    onChange({ target: { value: '' } });
  }

  return (
    <TextField
      value={value}
      onChange={onChange}
      size="small"
      placeholder="Search..."
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="inherit" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            position="end"
            disableTypography
            sx={{ alignItems: 'center' }}
          >
            {value && (
              <IconButton
                size="small"
                sx={{ visibility: value ? 'visible' : 'hidden' }}
                onClick={handleClearClick}
              >
                <ClearIcon fontSize="inherit" />
              </IconButton>
            )}
            {count && (
              <Typography variant="caption" color="textSecondary">
                {count}
              </Typography>
            )}
          </InputAdornment>
        ),
      }}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& > fieldset': {
            border: 'none',
            bgcolor: alpha('#000', 0.15),
          },
        },
        '& .MuiOutlinedInput-root:hover': {
          '& > fieldset': {
            border: 'none',
            bgcolor: alpha('#000', 0.1),
          },
        },
        ...sx,
      }}
      {...props}
    />
  );
}

import { api } from '@/apis';
import { highlights } from '@/utils/config';
import { useQuery } from '@tanstack/react-query';
import { startOfDay, subDays } from 'date-fns';

const {
  objectiveCompliance: { lookbackDays },
} = highlights;

const toDate = startOfDay(new Date());
const fromDate = subDays(toDate, lookbackDays);

//skims objective compliances data to gather all actual values for the relative groups
function listGroupData(data, groups) {
  let groupsData = new Set();

  if (groups) {
    groups.forEach((element) => {
      let groupData = new Set();
      Object.entries(data || []).map((objective) => {
        var values = Object.values(objective[1].groups[element] ?? []);
        values.map((value) => {
          groupData.add(value);
        });
      });
      groupsData.add({ group: element, values: [...groupData].sort() });
    });
  }
  return [...groupsData];
}

//list the groups keys for the LOV items
function listGroups(data) {
  var groups = new Set();
  if (data) {
    Object.entries(data || []).map((objective) => {
      var keys = Object.keys(objective[1].groups);
      keys.map((key) => {
        groups.add(key);
      });
    });
  }

  return [...groups];
}

export function useObjectiveCompliance(
  resourceType,
  groupType,
  groupId,
  maxCompliancePercent,
) {
  return useQuery({
    queryKey: [
      'objectiveCompliance',
      resourceType,
      groupType,
      groupId,
      maxCompliancePercent,
    ],
    queryFn: async () => {
      //left guid return as json otherwise it needs to have the double quotes removed

      const data = await api
        .post('pipeline/objectives', {
          json: pipeline(
            resourceType === 'vehicles' //collection
              ? 'vehicleObjectiveAttendances'
              : 'personObjectiveAttendances',
            resourceType, //objective.resourceType
            resourceType === 'vehicles' //node parent for bcu filter
              ? 'vehicle'
              : 'person',
            groupType,
            groupId,
            maxCompliancePercent,
          ),
        })
        .json();

      const groups = listGroups(data);

      const groupData = listGroupData(data, groups);

      return { options: { groups, groupData }, data };
    },
    placeholderData: {},
    staleTime: 5 * (60 * 1000), // only refetch every 5 minutes
    cacheTime: 6 * (60 * 1000), // keep data in cache for 6 minutes
  });
}

function pipeline(
  attendancesCollection,
  resourceType,
  resourceNode,
  groupType,
  groupId,
  maxCompliancePercent,
) {
  return [
    {
      $match: {
        startTime: {
          $lt: toDate,
        },
        endTime: {
          $gte: fromDate,
        },
        $or: [
          {
            slots: {
              $exists: true,
            },
          },
          {
            requiredFrequency: 'total',
          },
        ],
        resourceType: resourceType,
      },
    },
    {
      $lookup: {
        from: attendancesCollection,
        localField: 'identifier',
        foreignField: 'objective.identifier',
        let: {
          startTime: '$startTime',
          endTime: '$endTime',
          requiredFrequency: '$requiredFrequency',
          requiredVisits: '$requiredVisits',
          frequencyUnit: {
            $cond: [
              {
                $eq: ['$requiredFrequency', 'hourly'],
              },
              'hour',
              'day',
            ],
          },
          frequencyDivisor: {
            $cond: [
              {
                $eq: ['$requiredFrequency', 'hourly'],
              },
              3600,
              86400,
            ],
          },
        },
        pipeline: [
          {
            $match: {
              compliant: true,
              $expr: {
                $and: [
                  {
                    $gte: [
                      '$endTime',
                      {
                        $max: ['$$startTime', fromDate],
                      },
                    ],
                  },
                  {
                    $lt: [
                      '$startTime',
                      {
                        $min: ['$$endTime', '$$NOW'],
                      },
                    ],
                  },
                  {
                    $in: [
                      groupId,
                      { $ifNull: [`$${resourceNode}.groups.${groupType}`, []] },
                    ],
                  },
                ],
              },
            },
          },
          {
            $set: {
              hourOrDay: {
                $map: {
                  input: {
                    $range: [
                      {
                        $divide: [
                          {
                            $toLong: {
                              $dateTrunc: {
                                date: '$startTime',
                                unit: '$$frequencyUnit',
                              },
                            },
                          },
                          1000,
                        ],
                      },
                      {
                        $divide: [
                          {
                            $toLong: {
                              $dateTrunc: {
                                date: '$endTime',
                                unit: 'second',
                              },
                            },
                          },
                          1000,
                        ],
                      },
                      '$$frequencyDivisor',
                    ],
                  },
                  as: 'timestamp',
                  in: {
                    $toDate: {
                      $multiply: ['$$timestamp', 1000],
                    },
                  },
                },
              },
            },
          },
          {
            $unwind: '$hourOrDay',
          },
          {
            $group: {
              _id: {
                identifier: '$objective.identifier',
                hourOrDay: '$hourOrDay',
                wards: '$wards',
              },
              identifiers: {
                $push: '$identifier',
              },
            },
          },
          {
            $project: {
              _id: false,
              identifier: '$_id.identifier',
              slot: '$_id.hourOrDay',
              identifiers: true,
              identifierCount: {
                $size: '$identifiers',
              },
            },
          },
          {
            $match: {
              $or: [
                {
                  $expr: {
                    $gte: [
                      '$identifierCount',
                      {
                        $toInt: {
                          $ifNull: ['$$requiredVisits', 1],
                        },
                      },
                    ],
                  },
                },
                {
                  $expr: {
                    $eq: ['$$requiredFrequency', 'total'],
                  },
                },
              ],
            },
          },
          {
            $sort: {
              slot: 1,
            },
          },
          {
            $group: {
              _id: '$identifier',
              totalCount: {
                $sum: '$identifierCount',
              },
              countOld: {
                $sum: 1,
              },
              count: {
                // to support total and daily/hourly
                $sum: {
                  $cond: [
                    {
                      $eq: ['$$requiredFrequency', 'total'],
                    },
                    '$identifierCount',
                    1,
                  ],
                },
              },
            },
          },
        ],
        as: 'compliantSlots',
      },
    },
    {
      $project: {
        requiredVisits: true,
        requiredFrequency: true,
        compliantSlots: true,
        startTime: true,
        endTime: true,
        wards: true,
        expectedSlots: {
          $filter: {
            input: {
              $ifNull: [
                '$slots',
                [
                  {
                    $max: [new Date('2000-05-01'), '$startTime'],
                  },
                ],
              ],
            },
            cond: {
              $and: [
                {
                  $gte: ['$$slot', new Date('2000-05-01')],
                },
                {
                  $lt: ['$$slot', '$$NOW'],
                },
              ],
            },
            as: 'slot',
          },
        },
        compliance: {
          $multiply: [
            {
              $divide: [
                {
                  //count is total visits for "total" frequency
                  //and number of compliant slots for hourly and daily
                  $first: '$compliantSlots.count',
                },
                {
                  $cond: [
                    {
                      $eq: ['$requiredFrequency', 'total'],
                    },
                    {
                      $max: [{ $toInt: '$requiredVisits' }, 1],
                    },
                    {
                      $size: {
                        $filter: {
                          input: {
                            $ifNull: ['$slots', [new Date('2000-05-01')]],
                          },
                          cond: {
                            $and: [
                              {
                                $gte: ['$$slot', new Date('2000-05-01')],
                              },
                              {
                                $lt: ['$$slot', '$$NOW'],
                              },
                            ],
                          },
                          as: 'slot',
                        },
                      },
                    },
                  ],
                },
              ],
            },
            100,
          ],
        },
      },
    },
    {
      $lookup: {
        from: 'locations',
        localField: 'wards',
        foreignField: '_id',
        let: {
          objectiveId: '$_id',
        },
        pipeline: [
          {
            $group: {
              _id: '$$objectiveId',
              groups: { $push: '$groups' },
            },
          },
          {
            $unwind: '$groups',
          },
          {
            $set: {
              groups: {
                $objectToArray: '$groups',
              },
            },
          },
          {
            $unwind: '$groups',
          },
          {
            $project: {
              key: '$groups.k',
              value: '$groups.v',
            },
          },
          {
            $group: {
              _id: '$key',
              values: {
                $push: '$value',
              },
            },
          },
          {
            $project: {
              _id: false,
              k: '$_id',
              v: {
                $setUnion: [
                  {
                    $reduce: {
                      input: '$values',
                      initialValue: [],
                      in: {
                        $concatArrays: ['$$value', '$$this'],
                      },
                    },
                  },
                ],
              },
            },
          },
        ],
        as: 'wardsData',
      },
    },
    {
      $addFields: {
        groups: { $arrayToObject: '$wardsData' },
      },
    },
    {
      $addFields: {
        groups: { ward: '$wards' },
      },
    },
    {
      $sort: {
        compliance: -1,
      },
    },
    {
      $replaceRoot: {
        newRoot: {
          objective: '$_id',
          startTime: {
            $dateToString: {
              format: '%Y-%m-%d',
              date: '$startTime',
            },
          },
          endTime: {
            $dateToString: {
              format: '%Y-%m-%d',
              date: '$endTime',
            },
          },
          requiredFrequency: '$requiredFrequency',
          requiredVisits: '$requiredVisits',
          expectedSlots: {
            $size: '$expectedSlots',
          },
          compliantSlots: {
            $first: '$compliantSlots.count',
          },
          compliancePercent: {
            $cond: [
              { $gte: ['$compliance', 0] },
              { $min: ['$compliance', maxCompliancePercent] },
              0,
            ],
          },
          groups: '$groups',
        },
      },
    },
  ];
}

import '@fontsource/roboto/100-italic.css';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300-italic.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400-italic.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500-italic.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700-italic.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900-italic.css';
import '@fontsource/roboto/900.css';
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { BrowserTracing, init } from '@sentry/react';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import enGB from 'date-fns/locale/en-GB';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './css/index.css';
import { routes } from './routing';
import store from './store';
import { theme } from './theme';
import { sentry } from './utils/config';
import { createIDBPersister } from './utils/createIDBPersister';
import { populateDescendants } from './utils';

const router = createBrowserRouter(routes);

// connect app to the Sentry project
if (sentry.enable) {
  init({
    dsn: sentry.dsn,
    integrations: [new BrowserTracing()],
    environment: import.meta.env.MODE,
    tracesSampleRate: sentry.tracesSampleRate,
    release: `v${import.meta.env.VITE_REACT_APP_VERSION}`,
  });
}
// MUI pickers dateFormats docs: https://mui.com/x/api/date-pickers/localization-provider/
// date-fns parse docs https://date-fns.org/docs/parse
const dateFormats = {
  year: 'yyyy',
  fullDateTime24h: 'dd/MM/yyyy HH:mm',
  hours24h: 'HH',
  fullDate: 'dd/MM/yyyy',
};

const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: async (error) => {
      const response = await error?.response?.json();
      enqueueSnackbar(response?.message ?? error?.message, {
        variant: 'error',
      });
    },
  }),
  queryCache: new QueryCache({
    onError: async (error) => {
      const response = await error?.response?.json();
      enqueueSnackbar(response?.message ?? error?.message, {
        variant: 'error',
      });
    },
  }),
});
const persister = createIDBPersister();

populateDescendants();

ReactDOM.createRoot(document.getElementById('root')).render(
  <StrictMode>
    <ReduxProvider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={enGB}
            dateFormats={dateFormats}
          >
            <CssBaseline />
            <SnackbarProvider>
              <PersistQueryClientProvider
                client={queryClient}
                persistOptions={{ persister }}
              >
                <ReactQueryDevtools
                  initialIsOpen={false}
                  buttonPosition="top-right"
                />
                <RouterProvider router={router} />
              </PersistQueryClientProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ReduxProvider>
  </StrictMode>,
);

import { usePrevious } from '@/hooks';
import { noop, startCase } from '@/utils';
import {
  Check as CompleteIcon,
  Remove as SkippedIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import { dequal } from 'dequal';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLatestPoll } from './useLatestPoll';
import { sentenceCase } from './utilities';
import { verifySteps } from './verifySteps';

// this is a generic control for keeping track of a sequence of steps to
// check when verifying a box, e.g. checking inputs, driver id, CAN bus
// or a sequence of steps to do something like set driver id
// each step should look like this:
//
// const step = {
//   label: "Rear red lights",
//   async: false,
//   checkFunction: (currPoll) => {
//     return isCorrect ? true : false;
//   },
//   errorFunction: (currPoll) => {
//     if (...)
//       return "Reset inputs first"
//     else
//       return false;
//   },
//   thenFunction: () => { apiCall(x); },
// }
function CustomStepIcon({ completed, skipped, active, text }) {
  return (
    <Avatar
      sx={{
        bgcolor: completed || skipped || active ? 'primary.main' : 'grey.500',
        color: 'common.white',
        height: 24,
        width: 24,
        fontSize: '0.75rem',
      }}
    >
      {(completed && <CompleteIcon fontSize="small" />) ||
        (skipped && <SkippedIcon fontSize="small" />) ||
        text}
    </Avatar>
  );
}

export function StepsCheckList({
  sequenceToVerify,
  stepsStartTime,
  onCompleteChanged = noop,
  onVerifiedChanged = noop,
  allowOverride = true,
  ignoreSequenceChanges = false,
  completeMessage = 'All steps complete',
  imei,
  manualConfirmMode,
  lookBackMinutes = 5,
}) {
  const latestPoll = useLatestPoll(imei);
  const expectedSequence = sequenceToVerify;
  const [verifiedSteps, setVerifiedSteps] = useState({});
  const [overriddenSteps, setOverriddenSteps] = useState({});
  const currPollRef = useRef(undefined);
  const prevPollRef = useRef(latestPoll);
  const sortedPollsRef = useRef(undefined);
  const [complete, setComplete] = useState(false);
  const [activeInputStep, setActiveInputStep] = useState(0);

  const [skipReason, setSkipReason] = useState('');
  const [skipReasonOther, setSkipReasonOther] = useState(undefined);
  const showOtherSkipReason = skipReason === 'Other';
  const [showSkipDialog, setShowSkipDialog] = useState(false);
  const [outOfSequence, setOutOfSequence] = useState(false);

  // just used to refresh ui
  const [tick, setTick] = useState(false);

  const polls = useSelector(
    (state) => state.telematicsBoxes.boxesByImei[imei]?.polls,
  );

  const currentStepKey = Object.keys(expectedSequence).find(
    (k) => !verifiedSteps[k] && !overriddenSteps[k],
  );

  // if the start changes wipe the verified inputs
  const prevStart = usePrevious(stepsStartTime);
  useEffect(() => {
    if (prevStart !== stepsStartTime) {
      setVerifiedSteps({});
      setComplete(false);
    }
  }, [prevStart, stepsStartTime, onVerifiedChanged]);

  const prevExpectedSequence = usePrevious(expectedSequence);
  useEffect(() => {
    if (
      !ignoreSequenceChanges &&
      prevExpectedSequence &&
      prevExpectedSequence !== expectedSequence
    ) {
      // remove any from the verified inputs
      Object.keys(prevExpectedSequence)
        .filter((k) => !expectedSequence[k])
        .forEach((k) => delete verifiedSteps[k]);

      setVerifiedSteps({ ...verifiedSteps });

      // if one was added it's no longer complete
      const allVerified = (e) => {
        return Object.keys(e).every(
          (k) => typeof verifiedSteps[k] !== 'undefined',
        );
      };

      const prevAllVerified = allVerified(prevExpectedSequence);
      const currAllVerified = allVerified(expectedSequence);
      if (prevAllVerified && !currAllVerified) {
        onCompleteChanged(false, verifiedSteps);
      }
    }
  }, [
    ignoreSequenceChanges,
    prevExpectedSequence,
    expectedSequence,
    verifiedSteps,
    onCompleteChanged,
  ]);

  // whenever the verified steps changes check if they are all complete
  const prevVerifiedSteps = usePrevious(verifiedSteps);
  const prevOverriddenSteps = usePrevious(overriddenSteps);
  useEffect(() => {
    if (
      prevVerifiedSteps !== verifiedSteps ||
      prevOverriddenSteps !== overriddenSteps
    ) {
      const completedSteps = {
        ...verifiedSteps,
        ...overriddenSteps,
      };

      onVerifiedChanged && onVerifiedChanged(completedSteps);
      if (showSkipDialog) {
        setShowSkipDialog(false);
      }

      setActiveInputStep(Object.keys(completedSteps).length);

      if (
        Object.keys(expectedSequence).every(
          (k) => typeof completedSteps[k] !== 'undefined',
        )
      ) {
        if (!complete) {
          onCompleteChanged(true, completedSteps);
          setComplete(true);
        }
      } else if (complete) {
        onCompleteChanged(false, completedSteps);
        setComplete(false);
      }
    }
  }, [
    expectedSequence,
    onCompleteChanged,
    prevVerifiedSteps,
    verifiedSteps,
    complete,
    showSkipDialog,
    onVerifiedChanged,
    prevOverriddenSteps,
    overriddenSteps,
  ]);

  // when the polls change, check if the input is the expected one
  const prevPolls = usePrevious(polls);
  useEffect(() => {
    function checkNextInSequence() {
      if (stepsStartTime && !manualConfirmMode) {
        const currentlyVerified = verifySteps({
          steps: expectedSequence,
          polls,
          startTime: stepsStartTime,
          overridden: overriddenSteps,
          lookBackMinutes,
        });

        // if the currently verified steps aren't equal to what we have
        // then a new step could be verified or even an out of sequence
        // poll has invalidated a previously verified step
        if (!dequal(currentlyVerified, verifiedSteps)) {
          setVerifiedSteps(currentlyVerified);
          return true;
        }
      }
    }

    if (prevPolls !== polls) {
      const sortPollsNewToOld = (polls) => {
        return polls
          ?.sort((a, b) => b.time.localeCompare(a.time))
          ?.filter((p) => !stepsStartTime || p.time > stepsStartTime);
      };

      const sortedPolls = sortPollsNewToOld(polls);
      const mostRecentPoll = sortedPolls?.[0];
      if (mostRecentPoll && mostRecentPoll !== currPollRef.current) {
        prevPollRef.current = sortedPolls?.[1] || currPollRef.current;
        currPollRef.current = mostRecentPoll;
        sortedPollsRef.current = sortedPolls;
      }

      checkNextInSequence();

      // check for any out of sequence polls
      const sortedPrevPolls = sortPollsNewToOld(prevPolls);
      let foundMatch = false;
      let foundOutOfSequence = false;
      if (sortedPolls && sortedPrevPolls) {
        let i = 0;
        for (; i < sortedPolls.length; i++) {
          if (sortedPolls[i].time === sortedPrevPolls[0]?.time) {
            foundMatch = true;
            break;
          }
        }

        if (foundMatch) {
          for (
            let j = 0;
            i < sortedPolls.length && j < sortedPrevPolls.length;
            i++, j++
          ) {
            if (sortedPolls[i].time !== sortedPrevPolls[j].time) {
              foundOutOfSequence = true;
              break;
            }
          }
        }
      }

      if (foundOutOfSequence !== outOfSequence) {
        // console.log(foundOutOfSequence ? '>>> OOS found!' : '### reset');
        setOutOfSequence(foundOutOfSequence);
      }
    }

    const interval = setInterval(() => {
      checkNextInSequence() || setTick(!tick);
    }, 300);

    return () => clearInterval(interval);
  }, [
    prevPolls,
    polls,
    stepsStartTime,
    verifiedSteps,
    expectedSequence,
    complete,
    onCompleteChanged,
    overriddenSteps,
    outOfSequence,
    tick,
    manualConfirmMode,
    lookBackMinutes,
  ]);

  function overrideStep(stepKey, reason) {
    setOverriddenSteps({
      ...overriddenSteps,
      [stepKey]: {
        label: 'OVERRIDDEN',
        evidence: new Date().toISOString(),
        overridden: true,
        reason,
      },
    });
  }

  function handleSkipReasonChange(event) {
    setSkipReason(event.target.value);
  }

  function handleSkipReasonOtherChange(event) {
    setSkipReasonOther(event.target.value);
  }

  function handleManualConfirm() {
    setVerifiedSteps({
      ...verifiedSteps,
      [currentStepKey]: {
        label: 'CONFIRMED',
        evidence: {
          current: currPollRef.current,
          previous: prevPollRef.current,
        },
        time: new Date().toISOString(),
      },
    });
  }

  function closeSkipDialog() {
    setShowSkipDialog(false);
    setSkipReasonOther(undefined);
    setSkipReason('');
  }

  function confirmSkip() {
    overrideStep(
      currentStepKey,
      showOtherSkipReason ? skipReasonOther : skipReason,
    );
    closeSkipDialog();
  }

  const step = expectedSequence[currentStepKey];
  const label = !currentStepKey
    ? completeMessage
    : step?.label || sentenceCase(currentStepKey);
  // const showOverride = stepsStartTime && (current || (step.async && !verifiedSteps[k]));
  const currPollIsCorrect =
    step &&
    (step.checkFunction?.(
      currPollRef.current,
      prevPollRef.current,
      sortedPollsRef.current,
    ) ||
      Object.keys(step).every((k) => step[k] === currPollRef.current?.[k]));
  const errorMessage = step?.errorFunction?.(
    currPollRef.current,
    prevPollRef.current,
    sortedPollsRef.current,
  );
  const warnMessage = step?.warnFunction?.(
    currPollRef.current,
    prevPollRef.current,
    sortedPollsRef.current,
  );
  const statusMessage = step?.statusFunction?.(
    currPollRef.current,
    prevPollRef.current,
    sortedPollsRef.current,
  );
  const message = errorMessage || warnMessage || statusMessage;
  // const verifiedCount = Object.keys(verifiedSteps).length;
  const totalSteps = Object.keys(sequenceToVerify).length;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Stepper
        sx={{
          '& .MuiStepConnector-lineVertical': {
            minHeight: 12,
          },
          flexGrow: 1,
        }}
        orientation="vertical"
        activeStep={activeInputStep}
      >
        {Object.keys(expectedSequence).map((key, i) => {
          const step = expectedSequence[key];
          const label = step?.label || startCase(key);

          return (
            <Step key={key}>
              <StepLabel
                icon={
                  <CustomStepIcon
                    skipped={overriddenSteps[key]}
                    completed={verifiedSteps[key]}
                    active={key === currentStepKey}
                    text={String.fromCharCode(i + 65)}
                  />
                }
              >
                {label}
              </StepLabel>
              <StepContent>
                {key === currentStepKey && (
                  <div>
                    <div>
                      {outOfSequence && (
                        <Typography
                          variant="caption"
                          sx={{
                            color: 'warning.main',
                          }}
                        >
                          An out-of-sequence message was detected, previous
                          steps may be rolled back
                        </Typography>
                      )}
                    </div>
                    <div>
                      <Typography
                        variant="caption"
                        sx={{
                          color: errorMessage
                            ? 'error.main'
                            : warnMessage
                            ? 'warning.main'
                            : undefined,
                        }}
                      >
                        {message}&nbsp;
                      </Typography>
                    </div>
                  </div>
                )}
                {manualConfirmMode && (
                  <Button
                    size="small"
                    disabled={!currPollIsCorrect}
                    onClick={handleManualConfirm}
                  >
                    Next
                  </Button>
                )}
                {allowOverride && (
                  <Button
                    sx={allowOverride ? undefined : { opacity: 0 }}
                    size="small"
                    disabled={activeInputStep === totalSteps || !allowOverride}
                    onClick={() => setShowSkipDialog(true)}
                  >
                    Skip
                  </Button>
                )}
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
      <Dialog open={showSkipDialog} onClose={closeSkipDialog} fullWidth>
        <DialogTitle>{`Skip "${label}" step?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Note: if this step succeeds in the background this dialog will close
          </DialogContentText>
          <div>
            <TextField
              size="small"
              sx={{ width: 200, ml: 0.5, mt: 2 }}
              select
              label={'Reason for skipping'}
              error={false}
              value={skipReason}
              onChange={(event) => handleSkipReasonChange(event)}
            >
              {['Not applicable', 'Unable to test', 'Other'].map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
            {showOtherSkipReason && (
              <TextField
                size="small"
                sx={{ width: 200, ml: 1, mb: 1, mt: 2 }}
                helperText={!skipReasonOther && 'Required'}
                error={
                  !skipReasonOther && typeof skipReasonOther !== 'undefined'
                }
                value={skipReasonOther || ''}
                label={'Specify reason'}
                onChange={(event) => handleSkipReasonOtherChange(event)}
              />
            )}
          </div>

          <DialogActions>
            <Button
              onClick={closeSkipDialog}
              color="primary"
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            >
              Cancel
            </Button>
            <Button
              onClick={confirmSkip}
              color="primary"
              disabled={
                !skipReason || (showOtherSkipReason && !skipReasonOther)
              }
            >
              Skip
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

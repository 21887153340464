import { encodeParams, getAuthorization, reviveDate } from '@/utils';
import { briefingApiRootUrl } from '@/utils/config';
import { baseURL } from '@/utils/constants';
import ky from 'ky';
import { ajax } from 'rxjs/ajax';

export const test = ky.create({
  prefixUrl: 'http://localhost:4000',
});

export const police = ky.create({
  prefixUrl: 'https://data.police.uk/api',
});

export function fromAjax(path, { method = 'GET', body, headers, params }) {
  const encodedParams = encodeParams(params);
  return ajax({
    url: `${baseURL}${path}${encodedParams ? `?${encodedParams}` : ''}`,
    method: method,
    body: body ? JSON.stringify(body) : undefined,
    headers,
  });
}

export const api = ky.create({
  prefixUrl: baseURL,
  timeout: false,
  hooks: {
    beforeRequest: [
      async (request) => {
        const authorization = await getAuthorization();
        request.headers.set('authorization', authorization);
      },
    ],
  },
  parseJson: (json) => JSON.parse(json, reviveDate),
});

export const briefingApi = ky.create({
  prefixUrl: briefingApiRootUrl,
  timeout: false,
  hooks: {
    beforeRequest: [
      async (request) => {
        const authorization = await getAuthorization();
        request.headers.set('authorization', authorization);
      },
    ],
  },
  parseJson: (json) => JSON.parse(json, reviveDate),
});

import { atom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { personGroups, styles } from './utils/config';

export const highlightsStateAtom = atomWithStorage('highlightsSettings', {
  basicCommandUnit: personGroups?.bcu?.values[0].value,
  sector: personGroups?.sector?.values[0].value,
  team: personGroups?.team?.values[0].value,
});

export const mapStyleAtom = atomWithStorage('mapStyle', styles[0].path);

export const vehiclesStateAtom = atom({
  searchText: '',
  sortBy: 'registrationNumber',
  sortDesc: false,
  filter: {
    imei: '',
    vin: '',
    disposed: false,
    stale: '',
    ancestor: null,
  },
  showSettings: false,
});

export const peopleStateAtom = atom({
  searchText: '',
  sortBy: 'surname',
  sortDesc: false,
  filter: {
    rfid: '',
    radio: '',
    leaver: false,
    ancestor: null,
  },
  showSettings: false,
});

export const locationsStateAtom = atom({
  searchText: '',
  sortBy: 'name',
  sortDesc: false,
  filter: {
    ancestor: null,
  },
  showSettings: false,
});

export const objectivesStateAtom = atom({
  searchText: '',
  sortBy: 'title',
  sortDesc: false,
  filter: {
    createdBy: '',
    ward: '',
    status: '',
    boundaryType: '',
  },
  showSettings: false,
  showCopyButtons: false,
});

export const groupsStateAtom = atom({
  searchText: '',
  sortBy: 'name',
  sortDesc: false,
  filter: {
    ancestor: null,
    type: '',
    parentCount: '',
  },
  showSettings: false,
  showCopyButtons: false,
});

const storage = createJSONStorage(() => sessionStorage);
export const alertsAtom = atomWithStorage('alerts', [], storage);

export const acceptedAtom = atomWithStorage('accepted', false);

export const sideNavOpenAtom = atom(false);

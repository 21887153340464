import { TableCell, TableRow, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useAssociatedOfficers } from '@/hooks';
import { CrimeOfficer } from './CrimeOfficer';

export default function CrimeOfficers({ occurrenceCode }) {
  const { data: officers } = useAssociatedOfficers(occurrenceCode);

  let associatedOfficers = [];

  if (officers?.associatedOfficers) {
    const { oic, other } = officers.associatedOfficers;
    associatedOfficers = [...oic, ...other];
  }

  return (
    <Fragment>
      {associatedOfficers.map((officer, i) => (
        <TableRow key={i}>
          <TableCell>
            <Typography variant="caption" color="textSecondary">
              Officer {i + 1}:
            </Typography>
          </TableCell>
          <TableCell>
            <CrimeOfficer officer={officer} />
          </TableCell>
        </TableRow>
      ))}
    </Fragment>
  );
}

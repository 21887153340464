import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useNotice(code, defaultValue) {
  return useQuery({
    queryKey: ['notices', code],
    queryFn: () => {
      if (code === 'new') {
        return defaultValue;
      }

      return briefingApi.get(`notices/${code}`).json();
    },
    enabled: !!code,
  });
}

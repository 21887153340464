import { getStatusColor } from '@/utils';
import {
  Person as PersonIcon,
  GpsFixed as GpsFixedIcon,
} from '@mui/icons-material';
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { RadioHandheld as RadioHandheldIcon } from 'mdi-material-ui';
// import { Link } from 'react-router-dom';

export function PersonListItem({
  item: { size, start },
  feature,
  selected,
  ...props
}) {
  const primary = feature.properties.person?.code
    ? `${feature.properties.person?.forenames} ${feature.properties.person?.surname} [${feature.properties.person?.collarNumber}]`
    : feature.properties.ssi;
  const secondary = feature.properties?.person?.code ?? 'Unassigned radio';

  return (
    <ListItem
      {...props}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: `${size}px`,
        transform: `translateY(${start}px)`,
      }}
      // secondaryAction={
      //   <Tooltip title="Follow">
      //     <IconButton
      //       // component={Link}
      //       // to={`${featureCollections[type][index].code}?copy=true`}
      //       state={{ copy: true }}
      //       size="small"
      //       edge="end"
      //     >
      //       <GpsFixedIcon fontSize="inherit" />
      //     </IconButton>
      //   </Tooltip>
      // }
      disablePadding
    >
      <ListItemButton
        dense
        // component={Link}
        // to={'.'}
        selected={selected}
      >
        <ListItemAvatar>
          <Avatar
            sx={{
              bgcolor: getStatusColor('default'),
            }}
          >
            {feature.properties?.person?.code ? (
              <PersonIcon />
            ) : (
              <RadioHandheldIcon />
            )}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={primary}
          primaryTypographyProps={{ noWrap: true }}
          secondary={secondary}
          secondaryTypographyProps={{ noWrap: true }}
        />
      </ListItemButton>
    </ListItem>
  );
}

import { startCase } from '@/utils';
import { Check as CheckIcon } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Checkbox,
  Paper,
  Popover,
  Slider,
  Tooltip,
} from '@mui/material';
import * as colors from '@mui/material/colors';
import _ from 'lodash';
import { Fragment, useState } from 'react';

// Object.keys(colors) is ordered by name in production so slice doesn't work
//const hues = Object.keys(colors).slice(1);
// this works but the order of the colors is odd, so I'll just be explicit
//const hues = Object.keys(colors).filter((hue) => hue !== 'common');
const hues = [
  'red',
  'pink',
  'purple',
  'deepPurple',
  'indigo',
  'blue',
  'lightBlue',
  'cyan',
  'teal',
  'green',
  'lightGreen',
  'lime',
  'yellow',
  'amber',
  'orange',
  'deepOrange',
  'brown',
  'grey',
  'blueGrey',
];
const shades = [
  '900',
  '800',
  '700',
  '600',
  '500',
  '400',
  '300',
  '200',
  '100',
  '50',
  'A700',
  'A400',
  'A200',
  'A100',
];
const hueAndShade = Object.assign(
  {},
  ...[].concat(
    ...Object.entries(colors)
      .filter(([color]) => color !== 'common')
      .map((hue) =>
        Object.entries(hue[1]).map((shade) => ({
          [shade[1]]: { hue: hue[0], shade: shade[0] },
        })),
      ),
  ),
);
const grey = colors.grey[500];

export function ColorPicker({ value, anchorEl, onClose, onChange }) {
  const [shade, setShade] = useState(hueAndShade[value || grey].shade);

  function handleChangeHue(event) {
    const {
      target: { value: hue },
    } = event;

    const color = colors[hue][shade];

    onChange(color);
  }

  function handleChangeShade(event, shadeIndex) {
    setShade(shades[shadeIndex]);
  }

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
    >
      <Paper sx={{ width: 144, padding: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Shade">
            <Slider
              value={shades.indexOf(shade)}
              min={0}
              max={13}
              step={1}
              onChange={handleChangeShade}
              onMouseDown={(e) => e.preventDefault()}
            />
          </Tooltip>
        </Box>
        <Box>
          {hues.map((hue) => {
            const bgcolor = colors[hue][shade];

            return (
              <Tooltip title={startCase(hue)} key={hue}>
                <Checkbox
                  sx={{ p: 0.5 }}
                  checked={value === bgcolor}
                  onChange={handleChangeHue}
                  onMouseDown={(e) => e.preventDefault()}
                  value={hue}
                  icon={
                    <Avatar sx={{ width: 24, height: 24, bgcolor }}>
                      <Fragment />
                    </Avatar>
                  }
                  checkedIcon={
                    <Avatar sx={{ width: 24, height: 24, bgcolor }}>
                      <CheckIcon
                        sx={(theme) => ({
                          color: theme.palette.getContrastText(bgcolor),
                          fontSize: 16,
                        })}
                      />
                    </Avatar>
                  }
                />
              </Tooltip>
            );
          })}
        </Box>
      </Paper>
    </Popover>
  );
}

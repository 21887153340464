import { ExportPicker, RetrospectiveTypeIcon } from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import {
  getPersonPolls,
  radioPollHeaders,
  round,
  shortPersonHeaders,
  startCase,
} from '@/utils';
import {
  Flag as FlagIcon,
  OutlinedFlag as OutlinedFlagIcon,
  PlayArrow as PlayArrowIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import humanizeDuration from 'humanize-duration';
import { useNavigate } from 'react-router-dom';

export function PersonTrail({
  color,
  background,
  identifier,
  person,
  startTime,
  endTime,
  distanceKilometres,
}) {
  useDocumentTitle(
    `IR3 | Trail | ${`${person.forenames || ''}  ${person.surname || ''}`}`,
  );
  const navigate = useNavigate();

  function handleViewClick() {
    navigate(`/eventreplay/personTrails/${identifier}`);
  }

  const personTrailHeaders = [...shortPersonHeaders, ...radioPollHeaders];

  async function fetchDataToConvert() {
    const polls = await getPersonPolls(person.radioSsi, startTime, endTime);

    const data = polls.map((poll) => ({
      ...poll,
      name: person.forenames + ' ' + person.surname,
      personRole: person.role,
      collarNumber: person.collarNumber,
      radioSsi: person.radioSsi,
      time: new Date(poll.time),
      longitude: poll.position ? poll.position.coordinates[0] : 0,
      latitude: poll.position ? poll.position.coordinates[1] : 0,
    }));

    return data;
  }

  const cardHeaderButtons = (
    <Box>
      <Tooltip title="View Replay">
        <IconButton onClick={handleViewClick} size="large">
          <PlayArrowIcon />
        </IconButton>
      </Tooltip>
      <ExportPicker
        fetchDataToConvert={fetchDataToConvert}
        filename="Person Trail"
        headers={personTrailHeaders}
      />
    </Box>
  );

  return (
    <Card sx={{ m: [1, 0.5, 1, 1] }}>
      <CardHeader
        avatar={
          <Tooltip title="Person Trail">
            <Avatar
              style={{
                background,
                color,
              }}
            >
              <RetrospectiveTypeIcon type="personTrails" />
            </Avatar>
          </Tooltip>
        }
        title={`${person.forenames || ''}  ${person.surname || ''}`}
        subheader={person.collarNumber}
        action={cardHeaderButtons}
      />
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary">
          Person
        </Typography>
        <Table size="small" sx={{ mt: 1, mb: 2 }}>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Role
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">{person.role}</Typography>
              </TableCell>
            </TableRow>
            {person.homeStation && (
              <TableRow>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    Home Station
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">
                    {person.homeStation}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {Object.entries(person.groups ?? {}).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    {startCase(key)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">
                    {(value ?? []).join(', ')}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Typography variant="subtitle2" color="textSecondary">
          Timeline
        </Typography>
        <Stepper orientation="vertical" sx={{ mt: 2, ml: 2 }}>
          <Step active>
            <StepLabel
              icon={
                <Avatar sx={{ width: 30, height: 30 }}>
                  <FlagIcon fontSize="inherit" />
                </Avatar>
              }
              optional={
                <Typography variant="caption">
                  {format(new Date(startTime), 'dd/MM/yyyy HH:mm:ss')}
                </Typography>
              }
            >
              Start
            </StepLabel>
            <StepContent />
          </Step>
          <Step active>
            <StepLabel
              icon={
                <Avatar sx={{ width: 30, height: 30 }}>
                  <OutlinedFlagIcon fontSize="inherit" />
                </Avatar>
              }
              optional={
                <Typography variant="caption">
                  {format(new Date(endTime), 'dd/MM/yyyy HH:mm:ss')}
                </Typography>
              }
            >
              End
            </StepLabel>
            <StepContent />
          </Step>
        </Stepper>
        <Typography variant="subtitle2" color="textSecondary">
          Metrics
        </Typography>
        <Table size="small" sx={{ mt: 1, mb: 2 }}>
          <TableBody>
            {distanceKilometres && (
              <TableRow>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    Distance
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">
                    {`${round(distanceKilometres * 0.62137119, 2)} miles`}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Duration
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {humanizeDuration(new Date(endTime) - new Date(startTime))}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

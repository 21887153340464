import { api } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpdateOptionValues() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ name, value, values }) => {
      return api
        .patch(`options/${name}-${value}`, {
          json: { values },
          headers: {
            'content-type': 'application/merge-patch+json',
          },
        })
        .json();
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['options'] }),
  });
}

import {
  Avatar,
  Badge,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useDocumentTitle } from '@/hooks';
import { RetrospectiveTypeIcon } from '@/components/controls';
import CrimePersons from './CrimePersons';
import CrimeOfficers from './CrimeOfficers';
import { AssociatedVehicle } from './AssociatedVehicle';

export function Crime({
  id,
  Occurrence__Label,
  Occurrence__OccurrenceType,
  Occurrence__UCRClearanceStatus,
  Occurrence__UCRClearanceStatusG,
  GOccIvPA__ClassificationG,
  Occurrence__GOccIvPA_cache,
  Occurrence__Summary,
  Occurrence__ReportedTimeTZV2G,
  Occurrence__StartTimeTZV2G,
  Occurrence__EndTimeTZV2G,
  background,
  color,
}) {
  return (
    <Card sx={{ m: [1, 0.5, 1, 1] }}>
      {useDocumentTitle('IR3 | Crime')}
      <CardHeader
        avatar={
          <Badge>
            <Avatar
              title={Occurrence__Label}
              style={{
                background,
                color,
              }}
            >
              <RetrospectiveTypeIcon type="crimes" />
            </Avatar>
          </Badge>
        }
        title={Occurrence__OccurrenceType}
      />
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary">
          Crime
        </Typography>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Occurrence number
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">{id}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Occurrence type
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {Occurrence__OccurrenceType}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Incident grade
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {Occurrence__UCRClearanceStatus}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Incident status
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {Occurrence__UCRClearanceStatusG}
                </Typography>
              </TableCell>
            </TableRow>
            {GOccIvPA__ClassificationG && (
              <TableRow>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    {GOccIvPA__ClassificationG || 'Address'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">
                    {Occurrence__GOccIvPA_cache}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Summary
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">{Occurrence__Summary}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Reported Time
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {Occurrence__ReportedTimeTZV2G}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Occurred between
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {Occurrence__StartTimeTZV2G} - {Occurrence__EndTimeTZV2G}
                </Typography>
              </TableCell>
            </TableRow>
            <CrimePersons occurrenceCode={id} />
            <CrimeOfficers occurrenceCode={id} />
            <AssociatedVehicle occurrenceCode={id} />
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

import { useIncidentCountByGrade } from '@/hooks';
import { useTheme } from '@emotion/react';
import {
  Box,
  Card,
  CardContent,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import {
  Bar,
  BarChart,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CustomTooltip } from '../controls';

const timePeriods = {
  oneMonth: '1 month',
  sixMonths: '6 months',
  oneYear: '1 year',
};

export function IncidentsByGradeCard({ groupType, groupId }) {
  const { data } = useIncidentCountByGrade(groupType, groupId);
  const [timePeriod, setTimePeriod] = useState(Object.keys(timePeriods)[0]);
  const theme = useTheme();

  function handleChange(event, value) {
    setTimePeriod(value);
  }

  return (
    <Card variant="outlined" sx={{ flexGrow: 1 }}>
      <CardContent>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            Incidents by Grade
          </Typography>
          {/* <Typography variant="caption" color="text.secondary">
            {`Average in station time, per vehicle, per day, over the last ${lookbackDays} days`}
          </Typography> */}
        </Stack>
        <Box sx={{ pt: 1, fontSize: 12 }}>
          <ResponsiveContainer width="99%" height={520}>
            <BarChart
              data={data}
              margin={{ right: 16, bottom: 32, top: 8, left: 16 }}
            >
              <XAxis dataKey="grade">
                <Label value="Grade" position="bottom" />
              </XAxis>
              <YAxis>
                <Label value="Count" angle={-90} position="left" />
              </YAxis>
              <Tooltip
                wrapperStyle={{ outline: 'none' }} // TODO: This is temporary due to a bug in recharts https://github.com/recharts/recharts/issues/2920
                cursor={false}
                content={
                  <CustomTooltip
                    unit="incidents"
                    variant="outlined"
                    labelFormatter={(value) => `Grade ${value}`}
                  />
                }
              />
              <Bar
                dataKey={timePeriod}
                fill={theme.palette.primary.main}
                name={timePeriods[timePeriod]}
              />
            </BarChart>
          </ResponsiveContainer>
        </Box>
        <Stack
          spacing={2}
          direction="row"
          sx={{ w: 1, display: 'flex', justifyContent: 'center' }}
        >
          <ToggleButtonGroup
            value={timePeriod}
            onChange={handleChange}
            color="primary"
            size="small"
            exclusive
          >
            {Object.entries(timePeriods).map(([value, label]) => (
              <ToggleButton key={value} value={value}>
                {label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Stack>
      </CardContent>
    </Card>
  );
}

import { api } from '@/apis';
import { encodeParams, log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useOfficer(collarNumber) {
  return useQuery({
    queryKey: ['officer', collarNumber],
    queryFn: () => {
      const searchParams = encodeParams({
        pipeline: [
          { $match: { collarNumber: collarNumber } },
          {
            $graphLookup: {
              from: 'groups',
              startWith: '$groupCodes',
              connectFromField: 'parentCodes',
              connectToField: 'code',
              as: 'groups',
              depthField: 'depth',
            },
          },
          {
            $project: {
              code: true,
              forenames: true,
              surname: true,
              collarNumber: true,
              team: {
                $first: {
                  $map: {
                    input: {
                      $sortArray: {
                        input: {
                          $filter: {
                            input: '$groups',
                            cond: {
                              $eq: ['$$group.type', 'TEAM'],
                            },
                            as: 'group',
                          },
                        },
                        sortBy: {
                          depth: 1,
                        },
                      },
                    },
                    as: 'group',
                    in: {
                      name: '$$group.name',
                      type: '$$group.type',
                    },
                  },
                },
              },
            },
          },
        ],
      });

      log('Read', 'Officer', { collarNumber });

      return api.get('people', { searchParams }).json();
    },
    enabled: !!collarNumber,
  });
}

import {
  FETCH_VEHICLE_HOURLY_UTILISATION,
  FETCH_VEHICLE_HOURLY_UTILISATION_CANCELLED,
  FILTER_VEHICLE_HOURLY_UTILISATION,
  UPDATE_VEHICLE_HOURLY_UTILISATION_PARAMETERS,
} from '@/actions';
import {
  CustomTooltip,
  Parameters,
  SelectMultiple,
  ToggleList,
} from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import { downloadCSV, getFilenameForDownload } from '@/utils';
import { homeOtherSplit, vehicleGroups } from '@/utils/config';
import {
  BarChart as BarChartIcon,
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  CardContent,
  Collapse,
  IconButton,
  LinearProgress,
  ListSubheader,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  green,
  lightBlue,
  orange,
  purple,
  red,
  teal,
} from '@mui/material/colors';
import { dequal } from 'dequal';
import { enqueueSnackbar } from 'notistack';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Bar,
  BarChart,
  Brush,
  Tooltip as ChartTooltip,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

const hours = [...Array(24).keys()];
const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

const classificationSettings = {
  activity: {
    bars: [
      ...(homeOtherSplit
        ? [
            { name: 'Stopped @ Home Base', colour: red[800] },
            { name: 'Stopped @ Other Base', colour: red[500] },
            { name: 'Idle @ Home Base', colour: purple[700] },
            { name: 'Idle @ Other Base', colour: purple[300] },
          ]
        : [
            { name: 'Stopped @ Base', colour: red[800] },
            { name: 'Idle @ Base', colour: red[500] },
          ]),
      { name: 'Stopped @ Workshop', colour: orange[800] },
      { name: 'Idle @ Workshop', colour: orange[500] },
      { name: 'Stopped Elsewhere', colour: teal[800] },
      { name: 'Idle Elsewhere', colour: teal[500] },
      { name: 'Moving', colour: green[500] },
      { name: 'Unaccounted', colour: lightBlue[400] },
    ],
  },
  status: {
    bars: [
      { name: 'Unused', colour: red[800] },
      { name: 'Unavailable', colour: orange[800] },
      { name: 'Used', colour: green[500] },
      { name: 'Unaccounted', colour: lightBlue[400] },
    ],
  },
};

const chartTypes = ['minutes', 'percentage'];
const labels = new Map([
  ['minutes', 'Minutes'],
  ['percentage', 'Percentage'],
  ['activity', 'Activity'],
  ['status', 'Status'],
]);

export function HourlyUtilisation() {
  useDocumentTitle('IR3 | Hourly Utilisation');
  const dispatch = useDispatch();
  const data = useSelector((state) => state.utilisation.hourly.data, dequal);
  const parameters = useSelector(
    (state) => state.utilisation.hourly.parameters,
    dequal,
  );
  const [viewMenuAnchor, setViewMenuAnchor] = useState(null);
  const classifyBy = useSelector(
    (state) => state.utilisation.hourly.classifyBy,
  );
  const chartType = useSelector((state) => state.utilisation.hourly.chartType);
  const query = useSelector((state) => state.utilisation.hourly.query, dequal);
  const isLoading = useSelector((state) => state.utilisation.hourly.isLoading);
  const isFiltering = useSelector(
    (state) => state.utilisation.hourly.isFiltering,
  );
  const error = useSelector((state) => state.utilisation.hourly.error);
  const filter = useSelector(
    (state) => state.utilisation.hourly.filter,
    dequal,
  );
  const filterValues = useSelector(
    (state) => state.utilisation.hourly.filterValues,
    dequal,
  );
  const [showFilter, setShowFilter] = useState(false);
  const [hiddenBars, setHiddenBars] = useState([]);

  const { bars } = classificationSettings[classifyBy];

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error]);

  function handleFilterToggle() {
    setShowFilter(!showFilter);
  }

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_VEHICLE_HOURLY_UTILISATION,
      payload: {
        query,
        filter,
        classifyBy,
        chartType: chartType,
      },
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_VEHICLE_HOURLY_UTILISATION_CANCELLED,
    });
  }

  const handleClassificationClick = (value) => () => {
    setViewMenuAnchor(null);
    updateTransformOptions({ classifyBy: value });
  };

  const handleFilterFieldChanged = (name) => (values) => {
    const newFilter =
      name in filter
        ? {
            ...filter,
            [name]: values || [],
          }
        : {
            ...filter,
            groups: {
              ...filter.groups,
              [name]: values || [],
            },
          };

    updateTransformOptions({ filter: newFilter });
  };

  const handlePercentOrHoursClick = (value) => () => {
    setViewMenuAnchor(null);
    updateTransformOptions({ chartType: value });
  };

  function updateTransformOptions(optionChanges) {
    dispatch({
      type: FILTER_VEHICLE_HOURLY_UTILISATION,
      payload: {
        query,
        filter,
        classifyBy,
        chartType,
        ...optionChanges,
      },
    });
  }

  const handleLegendClick = (selectedBar) => () => {
    const index = hiddenBars.indexOf(selectedBar);

    if (index === -1) {
      setHiddenBars(hiddenBars.concat(selectedBar));
    } else {
      setHiddenBars(
        hiddenBars.slice(0, index).concat(hiddenBars.slice(index + 1)),
      );
    }
  };

  function handleViewMenuOpen(event) {
    setViewMenuAnchor(event.target);
  }

  function handleViewMenuClose() {
    setViewMenuAnchor(null);
  }

  function handleParametersChange(parameters) {
    dispatch({
      type: UPDATE_VEHICLE_HOURLY_UTILISATION_PARAMETERS,
      payload: parameters,
    });
  }

  async function handleDownloadClick() {
    const filename = getFilenameForDownload(
      'Vehicle Hourly Utilisation',
      'csv',
      parameters?.startTime,
      parameters?.endTime,
    );

    downloadCSV(data, filename);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 48px)',
        overflow: 'hidden',
        backgroundColor: 'background.default',
      }}
    >
      {isFiltering && (
        <LinearProgress
          sx={{ position: 'absolute', width: 1, height: 8 }}
          color="secondary"
        />
      )}
      <Parameters
        collection="vehicleDailySummaries"
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFiltering={isLoading || isFiltering}
        value={parameters}
        onChange={handleParametersChange}
        sx={{ mt: 1, width: 264 }}
        pointEvent
        dateOnly
        vehicle
      />
      <Box
        sx={{
          width: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography variant="subtitle1">Hourly Utilisation</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title={showFilter ? 'Hide filter' : 'Show filter'}>
            <IconButton onClick={handleFilterToggle}>
              <FilterListIcon color={showFilter ? 'primary' : 'inherit'} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Change view">
            <IconButton
              aria-owns={viewMenuAnchor ? 'date-menu' : undefined}
              aria-haspopup="true"
              onClick={handleViewMenuOpen}
            >
              <BarChartIcon />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={viewMenuAnchor}
            open={Boolean(viewMenuAnchor)}
            onClose={handleViewMenuClose}
          >
            <ListSubheader disableSticky>View as</ListSubheader>
            {chartTypes.map((value) => (
              <MenuItem
                key={value}
                value={value}
                selected={value === chartType}
                onClick={handlePercentOrHoursClick(value)}
              >
                {labels.get(value)}
              </MenuItem>
            ))}
            <ListSubheader disableSticky>Classify by</ListSubheader>
            {Object.keys(classificationSettings).map((value) => (
              <MenuItem
                key={value}
                value={value}
                selected={value === classifyBy}
                onClick={handleClassificationClick(value)}
              >
                {labels.get(value)}
              </MenuItem>
            ))}
          </Menu>
          <Tooltip title="Download data">
            <Box component="span">
              <IconButton
                disabled={data.length === 0}
                onClick={handleDownloadClick}
              >
                <GetAppIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </Toolbar>
        <Collapse in={showFilter} timeout="auto">
          <Stack spacing={1} sx={{ flex: 1, p: 1 }}>
            <SelectMultiple
              disabled={isFiltering}
              label="Registration Number"
              placeholder="Select..."
              value={filter.registrationNumber}
              labelValue
              onChange={handleFilterFieldChanged('registrationNumber')}
              suggestions={filterValues.registrationNumber.map((value) => ({
                label: value,
                value,
              }))}
            />
            <SelectMultiple
              disabled={isFiltering}
              label="Fleet Number"
              placeholder="Select..."
              value={filter.fleetNumber}
              labelValue
              onChange={handleFilterFieldChanged('fleetNumber')}
              suggestions={filterValues.fleetNumber.map((value) => ({
                label: value,
                value,
              }))}
            />
            <SelectMultiple
              disabled={isFiltering}
              label="Role"
              placeholder="Select..."
              value={filter.role}
              labelValue
              onChange={handleFilterFieldChanged('role')}
              suggestions={filterValues.role.map((value) => ({
                label: value,
                value,
              }))}
            />
            {Object.entries(vehicleGroups).map(([key, { label, values }]) => {
              return (
                <SelectMultiple
                  key={key}
                  label={label}
                  placeholder="Select..."
                  value={filter.groups[key] || []}
                  labelValue
                  onChange={handleFilterFieldChanged(key)}
                  suggestions={values}
                />
              );
            })}
            <ToggleList
              disabled={isFiltering}
              value={filter.hour}
              onChange={handleFilterFieldChanged('hour')}
              values={hours}
              label={'Hours'}
            />
            <ToggleList
              disabled={isFiltering}
              value={filter.day}
              onChange={handleFilterFieldChanged('day')}
              values={days}
              label={'Days'}
            />
          </Stack>
        </Collapse>
        {data.length > 0 && (
          <Paper sx={{ m: [0, 1], minWidth: 240, fontSize: 12 }}>
            <CardContent sx={{ p: 0, pt: 0.5 }}>
              <Box
                sx={{
                  pl: 8,
                  pr: 2,
                  pb: 1,
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                {bars.map((bar) => (
                  <Box
                    key={bar.name}
                    sx={{
                      p: 0.5,
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={handleLegendClick(bar.name)}
                  >
                    <Avatar
                      sx={{
                        width: 12,
                        height: 12,
                        mr: 0.5,
                        bgcolor: !hiddenBars.includes(bar.name) && bar.colour,
                      }}
                    >
                      <Fragment />
                    </Avatar>
                    <Typography variant="caption">{bar.name}</Typography>
                  </Box>
                ))}
              </Box>
              <ResponsiveContainer width="99%" height={600}>
                <BarChart
                  data={data}
                  margin={{ top: 0, right: 16, left: 0, bottom: 32 }}
                  barCategoryGap={4}
                >
                  <XAxis dataKey="Hour">
                    <Label value="Hour" offset={36} position="bottom" />
                  </XAxis>
                  <YAxis>
                    <Label
                      value={labels.get(chartType)}
                      angle={-90}
                      position="left"
                      offset={-24}
                    />
                  </YAxis>
                  <ChartTooltip
                    cursor={false}
                    content={
                      <CustomTooltip
                        unit={chartType === 'percentage' ? '%' : 'minutes'}
                      />
                    }
                  />
                  {data.length > 0 && <Brush dataKey="Hour" height={24} />}
                  {bars.map((bar) => (
                    <Bar
                      key={bar.name}
                      dataKey={bar.name}
                      stackId="a"
                      fill={bar.colour}
                      hide={hiddenBars.includes(bar.name)}
                    />
                  ))}
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Paper>
        )}
      </Box>
    </Box>
  );
}

import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useSections() {
  return useQuery({
    queryKey: ['sections'],
    queryFn: async () => {
      const response = await api.get('settings/sections').json();

      return response.value;
    },
    staleTime: 1000 * 60 * 60 * 24,
  });
}

import { api } from '@/apis';
import { encodeParams } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import { addHours } from 'date-fns';

export function useShifts(date, groups) {
  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['shifts', date, groups],
    queryFn: () => {
      const searchParams = encodeParams({
        pipeline: [
          {
            $match: {
              startTime: { $gte: addHours(date, 4), $lt: addHours(date, 28) },
            },
          },
          {
            $lookup: {
              from: 'groups',
              localField: 'teamName',
              foreignField: 'aliases.GLOBAL_ROSTERING_SYSTEM',
              as: 'groups',
            },
          },
          {
            $set: {
              groupCode: { $first: '$groups.code' },
              groupSubtype: { $first: '$groups.subtype' },
            },
          },
          {
            $graphLookup: {
              from: 'groups',
              startWith: '$groupCode',
              connectFromField: 'parentCodes',
              connectToField: 'code',
              as: 'ancestorCodes',
            },
          },
          {
            $lookup: {
              from: 'options',
              localField: 'shiftName',
              foreignField: 'values',
              pipeline: [{ $match: { name: 'shiftType' } }],
              as: 'types',
            },
          },
          {
            $project: {
              startTime: true,
              start: true,
              end: true,
              length: true,
              shiftName: true,
              forceNumber: true,
              name: true,
              callSign: true,
              remarks: true,
              teamName: true,
              type: { $ifNull: [{ $first: '$types.value' }, ''] },
              groupCode: true,
              groupSubtype: true,
              ancestorCodes: {
                $map: {
                  input: '$ancestorCodes',
                  as: 'group',
                  in: '$$group.code',
                },
              },
              period: {
                $switch: {
                  branches: [
                    {
                      case: {
                        $eq: ['$length', '00:00'],
                      },
                      then: 'Off',
                    },
                    {
                      case: {
                        $and: [
                          { $gte: ['$startTime', addHours(date, 4)] },
                          { $lt: ['$startTime', addHours(date, 11)] },
                        ],
                      },
                      then: 'Day',
                    },
                    {
                      case: {
                        $and: [
                          { $gte: ['$startTime', addHours(date, 11)] },
                          { $lt: ['$startTime', addHours(date, 19)] },
                        ],
                      },
                      then: 'Late',
                    },
                    {
                      case: {
                        $and: [
                          { $gte: ['$startTime', addHours(date, 19)] },
                          { $lt: ['$startTime', addHours(date, 28)] },
                        ],
                      },
                      then: 'Late',
                    },
                  ],
                  default: '-',
                },
              },
            },
          },
          ...(groups?.length > 0
            ? [{ $match: { ancestorCodes: { $in: groups } } }]
            : []),
          { $sort: { startTime: 1, shiftName: 1 } },
        ],
      });

      return api.get('shifts', { searchParams }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60 * 30,
  });
}

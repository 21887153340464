import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Fragment } from 'react';

export function PastDaysFilter({ days, onFilterChange }) {
  return (
    <Fragment>
      <Box sx={{ flexGrow: 1 }} />
      <FormControl sx={{ m: 1, width: 300 }} size="small">
        <InputLabel>Past Days</InputLabel>
        <Select
          value={days}
          label="Past Days"
          onChange={(e) => onFilterChange(e.target.value)}
          size="small"
        >
          <MenuItem value={120}>Past 5 days</MenuItem>
          <MenuItem value={96}>Past 4 days</MenuItem>
          <MenuItem value={72}>Past 3 days</MenuItem>
          <MenuItem value={55413}>Past 2 days</MenuItem>{' '}
          {/* for testing and replace with 48 */}
          <MenuItem value={24}>24 hours </MenuItem>
        </Select>
      </FormControl>
    </Fragment>
  );
}

import { api } from '@/apis';
import { encodeParams, log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useHospitals() {
  return useQuery({
    queryKey: ['Hospitals'],
    queryFn: async () => {
      const searchParams = encodeParams({
        pipeline: [
          { $match: { type: 'Hospital' } },
          {
            $graphLookup: {
              from: 'groups',
              startWith: '$groupCodes',
              connectFromField: 'parentCodes',
              connectToField: 'code',
              as: 'groupAncestorCodes',
            },
          },
          {
            $project: {
              _id: false,
              type: true,
              code: true,
              name: true,
              groupCodes: true,
              groupAncestorCodes: {
                $map: {
                  input: '$groupAncestorCodes',
                  as: 'group',
                  in: '$$group.code',
                },
              },
            },
          },
        ],
      });

      log('Read', 'Hospitals');

      return await api.get(`locations`, { searchParams }).json();
    },
    placeholderData: [],
    staleTime: 1380 * (60 * 1000), // only refetch every 23 hour
    cacheTime: 1440 * (60 * 1000), // keep data in cache for 24 hours
  });
}

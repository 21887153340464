import { DataGrid } from '@/components/controls';
import { useObjectiveAttendeeCounts } from '@/hooks';
import { getKeyLabel, shortHumanizer } from '@/utils';
import { Typography } from '@mui/material';
import { Fragment } from 'react';

const columns = [
  {
    accessorKey: 'collarNumber',
    header: 'Collar Number',
  },
  {
    accessorKey: 'attendances',
    header: 'Attendances',
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
  },
  {
    accessorKey: 'durationSeconds',
    header: 'Time',
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
    Cell: (props) => shortHumanizer(props.cell.getValue() * 1000),
  },
];

export function AttendeesTable({ objectiveId, groupType, groupId }) {
  const { data } = useObjectiveAttendeeCounts(objectiveId, groupType, groupId);

  return (
    data.length > 0 && (
      <Fragment>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          {`${getKeyLabel(groupType)} Attendees`}
        </Typography>
        <DataGrid
          enableSorting={true}
          enableBottomToolbar={true}
          enablePagination={true}
          columns={columns}
          data={data}
        />
      </Fragment>
    )
  );
}

import {
  CREATE_RFID_CARD,
  CREATE_RFID_CARD_FAILURE,
  CREATE_RFID_CARD_SUCCESS,
  DELETE_RFID_CARD,
  DELETE_RFID_CARD_FAILURE,
  DELETE_RFID_CARD_SUCCESS,
  FETCH_RFID_CARD,
  FETCH_RFID_CARDS,
  FETCH_RFID_CARDS_FAILURE,
  FETCH_RFID_CARDS_SUCCESS,
  FETCH_RFID_CARD_FAILURE,
  FETCH_RFID_CARD_SUCCESS,
  REMOVE_RFID_CARD,
  REMOVE_RFID_CARD_FAILURE,
  REMOVE_RFID_CARD_SUCCESS,
  UPDATE_RFID_CARD,
  UPDATE_RFID_CARD_FAILURE,
  UPDATE_RFID_CARD_SUCCESS,
} from '@/actions';
import { fromAjax } from '@/apis';
import { getHeaders, log } from '@/utils';
import { useDallasKeys } from '@/utils/config';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

export function fetchRfidCardsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_RFID_CARDS),
    mergeMap(() => from(getHeaders())),
    mergeMap((headers) =>
      fromAjax('/rfidCards', {
        params: {
          pipeline: [
            {
              $project: {
                reference: true,
                category: true,
                type: true,
                label: true,
                time: true,
              },
            },
            {
              $lookup: {
                from: 'people',
                localField: '_id',
                foreignField: 'rfidCards.reference',
                as: 'people',
              },
            },
            {
              $set: {
                assignmentCount: {
                  $size: {
                    $filter: {
                      input: '$people',
                      as: 'person',
                      cond: { $ne: ['$$person.deleted', true] },
                    },
                  },
                },
              },
            },
            { $unset: 'people' },
          ],
        },
        headers,
      }).pipe(
        map(({ response: payload }) => {
          log('Read', useDallasKeys ? 'Dallas Keys' : 'RFID Cards');

          return {
            type: FETCH_RFID_CARDS_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_RFID_CARDS_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function fetchRfidCardEpic(action$) {
  return action$.pipe(
    ofType(FETCH_RFID_CARD),
    mergeMap(({ payload }) =>
      from(getHeaders().then((headers) => ({ payload, headers }))),
    ),
    mergeMap(({ payload: id, headers }) =>
      fromAjax('/rfidCards', {
        params: {
          pipeline: [
            {
              $match: {
                reference: id,
              },
            },
            {
              $project: {
                reference: true,
                category: true,
                type: true,
                label: true,
                time: true,
              },
            },
            {
              $lookup: {
                from: 'people',
                localField: 'reference',
                foreignField: 'rfidCards.reference',
                as: 'people',
              },
            },
            {
              $set: {
                people: {
                  $filter: {
                    input: '$people',
                    as: 'person',
                    cond: { $ne: ['$$person.deleted', true] },
                  },
                },
              },
            },
          ],
        },
        headers,
      }).pipe(
        map(({ response: [payload] }) => {
          log('Read', useDallasKeys ? 'Dallas Key' : 'RFID Card', { id });

          return {
            type: FETCH_RFID_CARD_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_RFID_CARD_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function createRfidCardEpic(action$) {
  return action$.pipe(
    ofType(CREATE_RFID_CARD),
    mergeMap(({ payload, navigate }) =>
      from(getHeaders().then((headers) => ({ payload, navigate, headers }))),
    ),
    mergeMap(({ payload: body, navigate, headers }) =>
      fromAjax('/rfidCards', {
        body,
        method: 'POST',
        headers: { ...headers, 'content-type': 'application/json' },
      }).pipe(
        map(({ response: payload }) => {
          log('Create', useDallasKeys ? 'Dallas Key' : 'RFID Card', payload);

          navigate(`../${payload.reference}`, {
            replace: true,
            state: { created: true },
          });

          return {
            type: CREATE_RFID_CARD_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: CREATE_RFID_CARD_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function updateRfidCardEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_RFID_CARD),
    mergeMap(({ payload }) =>
      from(getHeaders().then((headers) => ({ payload, headers }))),
    ),
    mergeMap(({ payload: { people, time, ...body }, headers }) =>
      fromAjax(`/rfidCards/${body.reference}`, {
        body,
        method: 'PATCH',
        headers: {
          ...headers,
          'content-type': 'application/merge-patch+json',
        },
      }).pipe(
        map(({ response: payload }) => {
          log('Update', 'RfidCard', payload);

          return {
            type: UPDATE_RFID_CARD_SUCCESS,
            payload: { people, time, ...payload },
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_RFID_CARD_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function deleteRfidCardEpic(action$) {
  return action$.pipe(
    ofType(DELETE_RFID_CARD),
    mergeMap(({ payload, navigate }) =>
      from(getHeaders().then((headers) => ({ payload, navigate, headers }))),
    ),
    mergeMap(({ payload: id, navigate, headers }) =>
      fromAjax(`/rfidCards/${id}`, {
        method: 'DELETE',
        headers,
      }).pipe(
        map(({ response }) => {
          log('Delete', 'RfidCard', { id });

          navigate('..', { replace: true });

          return {
            type: DELETE_RFID_CARD_SUCCESS,
            payload: response.reference,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_RFID_CARD_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function removeRfidCardEpic(action$) {
  return action$.pipe(
    ofType(REMOVE_RFID_CARD),
    mergeMap(({ payload }) =>
      from(getHeaders().then((headers) => ({ payload, headers }))),
    ),
    mergeMap(({ payload: { id, body }, headers }) =>
      fromAjax(`/people/${body.code}`, {
        body,
        method: 'PATCH',
        headers: {
          ...headers,
          'content-type': 'application/merge-patch+json',
        },
      }).pipe(
        map(({ response: { code } }) => {
          log('Update', 'Person', code);

          return {
            type: REMOVE_RFID_CARD_SUCCESS,
            payload: { id, code },
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: REMOVE_RFID_CARD_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

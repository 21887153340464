import { startCase } from '@/utils';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { pluralToSingularTypeMap } from './constants';
import { LiveIcon } from './LiveIcon';

export function SearchSummary({ searchTexts, hideEmpty = true }) {
  let searchTypesToShow = Object.keys(searchTexts);

  if (hideEmpty) {
    const nonEmptySearchTexts = searchTypesToShow.filter(
      (text) => !!searchTexts[text]?.trim(),
    );

    if (nonEmptySearchTexts.length === 0) {
      return (
        <List>
          <ListItem>
            <Typography>Empty</Typography>
          </ListItem>
        </List>
      );
    }

    searchTypesToShow = nonEmptySearchTexts;
  }

  return (
    <List dense>
      {searchTypesToShow.map((type) => {
        return (
          <ListItem key={type}>
            <ListItemIcon>
              <LiveIcon type={pluralToSingularTypeMap[type] || type} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={`${startCase(type)}: "${searchTexts[type]}"`}
            />
          </ListItem>
        );
      })}
    </List>
  );
}

import { Parameters } from '@/components/controls';
import { useAggregate, useDocumentTitle } from '@/hooks';
import { isEmpty, shortHumanizer } from '@/utils';
import { events } from '@/utils/config';
import { Box, Stack } from '@mui/material';
import { format } from 'date-fns';
import { atom, useAtom } from 'jotai';
import { MaterialReactTable } from 'material-react-table';
import { useMemo } from 'react';

const {
  eventFilters: { incidentResponses: eventFilters },
} = events;

const stateAtom = atom({
  sorting: [{ id: 'startTime', desc: false }],
  pagination: {
    pageIndex: 0,
    pageSize: 5,
  },
  query: {},
  parameters: {},
});

export function IncidentResponses() {
  useDocumentTitle('IR3 | Incident Responses');
  const [{ sorting, pagination, query, parameters }, setState] =
    useAtom(stateAtom);
  const collection = 'personIncidentResponses';
  const pipeline = useMemo(
    () => [
      {
        $project: {
          _id: false,
          identifier: true,
          incident: true,
          person: true,
          attended: true,
          startTime: true,
          attendedTime: true,
          endTime: true,
          durationSeconds: true,
          responseSeconds: {
            $round: [
              {
                $divide: [{ $subtract: ['$attendedTime', '$startTime'] }, 1000],
              },
              0,
            ],
          },
          attendedSeconds: {
            $round: [
              { $divide: [{ $subtract: ['$endTime', '$attendedTime'] }, 1000] },
              0,
            ],
          },
        },
      },
      { $match: query },
      ...(sorting.length > 0
        ? [
            {
              $sort: sorting.reduce(
                (acc, { id, desc }) => ({ ...acc, [id]: desc ? -1 : 1 }),
                {},
              ),
            },
          ]
        : []),
      { $skip: pagination.pageIndex * pagination.pageSize },
      { $limit: pagination.pageSize },
    ],
    [query, sorting, pagination],
  );
  const totalsPipeline = useMemo(
    () => [
      {
        $project: {
          _id: false,
          identifier: true,
          incident: true,
          person: true,
          attended: true,
          startTime: true,
          attendedTime: true,
          endTime: true,
          durationSeconds: true,
          responseSeconds: {
            $round: [
              {
                $divide: [{ $subtract: ['$attendedTime', '$startTime'] }, 1000],
              },
              0,
            ],
          },
          attendedSeconds: {
            $round: [
              { $divide: [{ $subtract: ['$endTime', '$attendedTime'] }, 1000] },
              0,
            ],
          },
        },
      },
      { $match: query },
      {
        $group: {
          _id: null,
          total: { $sum: 1 },
          durationSeconds: { $sum: '$durationSeconds' },
          responseSeconds: { $sum: '$responseSeconds' },
          attendedSeconds: { $sum: '$attendedSeconds' },
        },
      },
    ],
    [query],
  );
  const { data, isError, isFetching, isLoading, isRefetching, cancel } =
    useAggregate(collection, pipeline, !isEmpty(query));
  const {
    data: aggregated,
    isFetching: totalsFetching,
    cancel: cancelTotals,
  } = useAggregate(collection, totalsPipeline, !isEmpty(query), false);
  const columns = useMemo(
    () => [
      {
        accessorKey: 'incident.reference',
        header: 'Incident',
      },
      // {
      //   accessorKey: 'person',
      //   header: 'Person',
      // },
      {
        id: 'attended',
        key: 'attended',
        header: 'Attended',
        accessorFn: ({ attended }) => (attended ? 'Yes' : 'No'),
      },
      {
        id: 'startTime',
        key: 'startTime',
        header: 'Assigned Time',
        accessorFn: ({ startTime }) => format(startTime, 'dd/MM/yyyy HH:mm:ss'),
      },
      {
        id: 'attendedTime',
        key: 'attendedTime',
        header: 'Attended Time',
        accessorFn: ({ attendedTime }) =>
          attendedTime ? format(attendedTime, 'dd/MM/yyyy HH:mm:ss') : '',
      },
      {
        id: 'endTime',
        key: 'endTime',
        header: 'Unassigned Time',
        accessorFn: ({ endTime }) => format(endTime, 'dd/MM/yyyy HH:mm:ss'),
      },
      {
        id: 'durationSeconds',
        key: 'durationSeconds',
        header: 'Duration',
        accessorFn: ({ durationSeconds }) =>
          shortHumanizer(durationSeconds * 1000),
        footer: shortHumanizer(aggregated[0]?.durationSeconds * 1000),
      },
      {
        id: 'responseSeconds',
        key: 'responseSeconds',
        header: 'Response Duration',
        accessorFn: ({ responseSeconds }) =>
          responseSeconds ? shortHumanizer(responseSeconds * 1000) : '',
        footer: shortHumanizer(aggregated[0]?.responseSeconds * 1000),
      },
      {
        id: 'attendedSeconds',
        key: 'attendedSeconds',
        header: 'Attended Duration',
        accessorFn: ({ attendedSeconds }) =>
          attendedSeconds ? shortHumanizer(attendedSeconds * 1000) : '',
        footer: shortHumanizer(aggregated[0]?.attendedSeconds * 1000),
      },
    ],
    [aggregated],
  );

  function handleCancel() {
    cancel();
    cancelTotals();
  }

  const handleStateChange = (name) => (value) => {
    setState((state) => ({ ...state, [name]: value }));
  };

  const handleStateChangeWithEvent = (name) => (_, value) => {
    setState((state) => ({ ...state, [name]: value }));
  };

  const handleTableStateChange = (name) => (changeFn) => {
    setState((state) => ({ ...state, [name]: changeFn(state[name]) }));
  };

  return (
    <Stack direction="row">
      <Box
        sx={{
          width: 280,
          height: 'calc(100vh - 48px)',
          borderRight: 1,
          borderColor: 'divider',
        }}
      >
        <Parameters
          collection={collection}
          onFetch={handleStateChangeWithEvent('query')}
          onCancel={handleCancel}
          isFetching={isFetching || totalsFetching}
          value={parameters}
          onChange={handleStateChange('parameters')}
          sx={{ pt: 1, height: 1 }}
          incident
          person
          eventFilters={eventFilters}
        />
      </Box>
      {Object.keys(query).length === 0 ? (
        <Box />
      ) : (
        <MaterialReactTable
          enableStickyHeader
          enableStickyFooter
          enableColumnResizing
          enableTopToolbar
          enableSorting
          enableBottomToolbar
          enablePagination
          enableColumnActions={false}
          enableColumnFilters={false}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          manualFiltering
          manualPagination
          manualSorting
          data={data}
          columns={columns}
          onPaginationChange={handleTableStateChange('pagination')}
          onSortingChange={handleTableStateChange('sorting')}
          muiTablePaperProps={{ elevation: 0 }}
          muiSearchTextFieldProps={{ variant: 'outlined', size: 'small' }}
          muiTableContainerProps={{
            sx: {
              width: 'calc(100vw - 280px)',
              height: 'calc(100vh - 160px)', // 48 app header + 56 top bar + 56 bottom bar
            },
          }}
          rowCount={aggregated[0]?.total ?? 0}
          state={{
            density: 'compact',
            sorting,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            isLoading,
          }}
        />
      )}
    </Stack>
  );
}

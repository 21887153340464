import {
  Business as BusinessIcon,
  DirectionsCar as DirectionsCarIcon,
  DirectionsWalk as DirectionsWalkIcon,
  DirectionsRun as DirectionsRunIcon,
  Feedback as FeedbackIcon,
  Gesture as GestureIcon,
  PanTool as PanToolIcon,
  Person as PersonIcon,
  Place as PlaceIcon,
  Snooze as SnoozeIcon,
  Terrain as TerrainIcon,
} from '@mui/icons-material';
import {
  AxisArrow as AxisArrowIcon,
  CarBrakeParking as CarBrakeParkingIcon,
  CarSide as CarSideIcon,
  CarTractionControl as CarTractionControlIcon,
  MapMarkerMultiple as ClusterIcon,
  Speedometer as SpeedometerIcon,
} from 'mdi-material-ui';

export function RetrospectiveTypeIcon({ type, className }) {
  switch (type) {
    case 'speedInfractions':
      return <SpeedometerIcon className={className} />;
    case 'vehicleTrips':
      return <CarTractionControlIcon className={className} />;
    case 'vehicleStops':
      return <CarBrakeParkingIcon className={className} />;
    case 'vehicleStopCount':
      return <CarBrakeParkingIcon className={className} />;
    case 'vehicleIdles':
      return <SnoozeIcon className={className} />;
    case 'vehicleIdleCount':
      return <SnoozeIcon className={className} />;
    case 'vehiclePolls':
      return <DirectionsCarIcon className={className} />;
    case 'vehicleCustomVisits':
    case 'vehicleVisits':
    case 'vehicleTime':
    case 'vehicleVisitCount':
      return <CarSideIcon className={className} />;
    case 'incidents':
      return <FeedbackIcon className={className} />;
    case 'incidentCount':
      return <FeedbackIcon className={className} />;
    case 'personTrails':
      return <GestureIcon className={className} />;
    case 'personVisits':
    case 'personCustomVisits':
    case 'personTime':
    case 'personVisitCount':
      return <DirectionsWalkIcon className={className} />;
    case 'personPolls':
      return <PersonIcon className={className} />;
    case 'areas':
    case 'groups':
      return <TerrainIcon className={className} />;
    case 'locations':
      return <BusinessIcon className={className} />;
    case 'crimes':
      return <DirectionsRunIcon className={className} />;
    case 'stopChecks':
      return <PanToolIcon className={className} />;
    case 'accelerometerEvents':
    case 'accelerometerAlerts':
    case 'accelerometerAlertCount':
      return <AxisArrowIcon className={className} />;
    case 'clusters':
      return <ClusterIcon className={className} />;
    default:
      return <PlaceIcon className={className} />;
  }
}

import { LiveListItem } from './LiveListItem';

export function RadioLiveListItem({ item: radio, ...props }) {
  radio.primary = radio.label || radio.ssi;
  return (
    <LiveListItem
      type="radios"
      primaryPath="primary"
      secondaryPath="type"
      item={radio}
      hideFollow={!radio.lastPosition}
      {...props}
    />
  );
}

import { api } from '@/apis';
import { encodeParams } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import { projection } from '.';

export function useNotifications(enabled) {
  return useQuery({
    queryKey: ['notifications'],
    queryFn: async () => {
      const searchParams = encodeParams({
        projection,
        sort: { time: -1 },
      });

      return api.get('userNotifications', { searchParams }).json();
    },
    placeholderData: [],
    enabled,
  });
}

import {
  FETCH_VEHICLE_ODOMETERS,
  UPDATE_VEHICLE_ODOMETERS_FILTER,
} from '@/actions';
import {
  DatePicker,
  FilterPicker,
  Table,
  TablePagination,
} from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import { downloadCSV, filterLocally } from '@/utils';
import { odometerErrorExplanations, rowsPerPageOptions } from '@/utils/config';
import {
  GetApp as GetAppIcon,
  InfoOutlined as InfoIcon,
} from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { dequal } from 'dequal';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export function Mileage() {
  useDocumentTitle('IR3 | Mileage');
  const dispatch = useDispatch();
  const data = useSelector((state) => state.reports.mileage.data, dequal);
  const filter = useSelector((state) => state.reports.mileage.filter, dequal);
  const isLoading = useSelector((state) => state.reports.mileage.isLoading);
  const error = useSelector((state) => state.reports.mileage.error);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error]);

  useEffect(() => {
    dispatch({
      type: FETCH_VEHICLE_ODOMETERS,
      payload: filter.date,
    });
  }, [dispatch, filter.date]);

  function updateFilter(update) {
    onFilterChange({
      ...filter,
      ...update,
      fields: {
        ...filter.fields,
      },
    });
  }

  function onFilterChange(payload) {
    dispatch({
      type: UPDATE_VEHICLE_ODOMETERS_FILTER,
      payload,
    });
  }

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    updateFilter({
      orderBy,
      order: 'asc',
    });
  }

  function handleDateChange(date) {
    updateFilter({ date });
  }

  function formatDate(date) {
    return date ? format(new Date(date), 'dd/MM/yyyy HH:mm:ss') : '-';
  }

  function getErrorExplanation(errorCode) {
    return odometerErrorExplanations[errorCode] || {};
  }

  async function handleDownloadClick() {
    const filename = 'Mileage.csv';
    const formattedData = filteredReadings.map((reading) => ({
      ...reading,
      readingTime: formatDate(reading.readingTime),
      latestPollTime: formatDate(reading.latestPollTime),
      pollAfterReadingTime: formatDate(reading.pollAfterReadingTime),
      reasonCode: getErrorExplanation(reading.reasonCode).short,
    }));

    downloadCSV(formattedData, filename, headers);
  }

  const filteredReadings = filterLocally(filter, data);

  const mappedReadings = filteredReadings.map((reading) => ({
    ...reading,
    pollAfterReadingTime: formatDate(reading.pollAfterReadingTime),
    latestPollTime: formatDate(reading.latestPollTime),
  }));

  function ErrorExplanation({ entry }) {
    const { short, long } = getErrorExplanation(entry.reasonCode);
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flex: 3, marginRight: 3 }}>{short}</Box>
        {entry.reasonCode !== '' && (
          <Tooltip
            sx={{ flex: 1 }}
            title={
              <Box component="span" sx={{ fontSize: 14 }}>
                {long}
              </Box>
            }
          >
            <InfoIcon color="error" />
          </Tooltip>
        )}
      </Box>
    );
  }

  const headers = [
    {
      key: 'registrationNumber',
      type: 'text',
      label: 'Registration',
      filter: true,
    },
    {
      key: 'fleetNumber',
      type: 'text',
      label: 'Fleet Number',
      filter: true,
    },
    {
      key: 'readingTime',
      type: 'date',
      label: 'Reading Date & Time',
      filter: false,
    },
    {
      key: 'readingMiles',
      type: 'number',
      label: 'Reading Miles',
      filter: false,
    },
    {
      key: 'pollAfterReadingTime',
      type: 'text',
      label: 'Next Poll Date & Time',
      filter: false,
    },
    {
      key: 'pollAfterReadingMiles',
      type: 'number',
      label: 'Next Poll Miles',
      filter: false,
    },
    {
      key: 'pollAfterGapHours',
      type: 'number',
      label: 'Next Poll Gap Hours',
      filter: false,
    },
    {
      key: 'latestPollTime',
      type: 'text',
      label: 'Latest Poll Date & Time',
      filter: false,
    },
    {
      key: 'latestPollMiles',
      type: 'number',
      label: 'Latest Poll Miles',
      filter: false,
    },
    {
      key: 'calculatedMiles',
      type: 'number',
      label: 'Calculated Miles',
      filter: false,
    },
    {
      key: 'reasonCode',
      type: 'component',
      component: ErrorExplanation,
      label: 'Error Explanation',
      filter: false,
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 48px)',
        overflow: 'hidden',
        backgroundColor: 'background.default',
      }}
    >
      <Box
        sx={{
          width: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Box sx={{ p: 1, height: 'calc(100% - 48px)' }}>
          <Toolbar variant="dense" disableGutters sx={{ px: 1, pb: 1 }}>
            <Typography
              sx={{ flexGrow: 1 }}
              variant="subtitle1"
              // component="div"
            >
              Mileage
            </Typography>
            {isLoading && (
              <CircularProgress sx={{ m: 1 }} size={16} thickness={6} />
            )}
            <DatePicker
              value={filter.date}
              onChange={handleDateChange}
              sx={{ width: 180, pr: 1 }}
            />
            <FilterPicker
              headers={headers}
              data={data}
              filter={filter}
              onFilterChange={onFilterChange}
            />
            <Tooltip title="Download">
              <Box component="span">
                <IconButton
                  disabled={filteredReadings.length === 0}
                  onClick={handleDownloadClick}
                  sx={{ pl: 1 }}
                >
                  <GetAppIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Toolbar>
          <Paper sx={{ width: 1, height: 1, overflow: 'none' }}>
            <Table
              styles={{
                tableContainer: {
                  height: 'calc(100% - 116px)',
                  overflowY: 'scroll',
                },
                table: {
                  minWidth: 750,
                },
              }}
              data={mappedReadings}
              headers={headers}
              rowsPerPage={filter.rowsPerPage}
              page={filter.page}
              order={filter.order}
              orderBy={filter.orderBy}
              onOrderChange={handleOrderChange}
              onOrderByChange={handleOrderByChange}
            />
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={mappedReadings.length}
              rowsPerPage={filter.rowsPerPage}
              page={filter.page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}

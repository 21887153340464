import { Box, InputAdornment, TextField, Tooltip } from '@mui/material';
import { Adornment } from '..';

const conditions = [
  { label: '=', value: 'equal', title: 'Equal to' },
  { label: '≠', value: 'notEqual', title: 'Not equal to' },
  { label: '>', value: 'greater', title: 'Greater than' },
  { label: '≥', value: 'greaterOrEqual', title: 'Greater than or equal to' },
  { label: '<', value: 'less', title: 'Less than' },
  { label: '≤', value: 'lessOrEqual', title: 'Less than or equal to' },
];

export function NumericFilterField({
  value,
  onChange,
  modifiers,
  unit,
  exact,
  ...props
}) {
  const { condition, measure, modifier } = value ?? {
    condition: 'equal',
    measure: '',
    modifier: modifiers?.[0].value ?? 1,
  };

  function handleConditionChange(condition) {
    onChange({ condition, measure, modifier });
  }

  function handleMeasureChange(event) {
    onChange({ condition, measure: event.target.value, modifier });
  }

  function handleModifierChange(modifier) {
    onChange({ condition, measure, modifier });
  }

  return (
    <TextField
      value={measure}
      onChange={handleMeasureChange}
      type="number"
      InputProps={{
        startAdornment: exact ? undefined : (
          <Adornment
            value={condition}
            values={conditions}
            onChange={handleConditionChange}
            position="start"
          />
        ),
        endAdornment: modifiers ? (
          <Adornment
            value={modifier}
            values={modifiers}
            onChange={handleModifierChange}
            position="end"
          />
        ) : (
          <Tooltip title={unit.title} placement="right">
            <InputAdornment position="end">
              {unit.title ? (
                <Box component="span" sx={{ color: 'text.disabled' }}>
                  {unit.label}
                </Box>
              ) : (
                <Box component="span" sx={{ color: 'text.disabled' }}>
                  {unit.label || unit}
                </Box>
              )}
            </InputAdornment>
          </Tooltip>
        ),
      }}
      {...props}
    />
  );
}

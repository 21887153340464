import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function usePrisonRecalls(options) {
  const searchParams = JSON.parse(JSON.stringify(options ?? {}));
  return useQuery({
    queryKey: ['prisonRecalls', searchParams],
    queryFn: () => {
      return briefingApi.get('niche/prisonRecalls', { searchParams }).json();
    },
    enabled: searchParams.codes?.length > 0,
    placeholderData: [],
    keepPreviousData: true,
    staleTime: 55 * (60 * 1000),
    cacheTime: 60 * (60 * 1000),
  });
}

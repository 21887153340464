import { api } from '@/apis';
import { encodeParams, log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useObjectives() {
  return useQuery({
    queryKey: ['objectives'],
    queryFn: async () => {
      const searchParams = encodeParams({
        projection: {
          identifier: true,
          title: true,
          startTime: true,
          endTime: true,
          wards: true,
          created: true,
          boundaryType: true,
        },
      });

      log('Read', 'Objectives');

      return api.get('objectives', { searchParams }).json();
    },
    placeholderData: [],
  });
}

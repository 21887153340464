import { DirectionsCar, Person } from '@mui/icons-material';
import { Chip } from '@mui/material';

export function TotalTypesInLocation({
  typesInLocation,
  notShowEmpty = false,
}) {
  const ChipIconComponent = ({ length, IconComponent, type }) => {
    return (
      <Chip
        icon={<IconComponent />}
        label={length}
        size="small"
        sx={{ ml: 1 }}
        key={type}
      />
    );
  };

  return Object.keys(typesInLocation).map((type) => {
    const typeIcon = {
      vehicles: DirectionsCar,
      people: Person,
    };
    const IconComponent = typeIcon[type];
    const typeExists = typesInLocation[type].length > 0;

    if (notShowEmpty) {
      if (typeExists) {
        return (
          <ChipIconComponent
            length={typesInLocation[type].length}
            IconComponent={IconComponent}
            type={type}
            key={type}
          />
        );
      }
      return undefined;
    }
    return (
      <ChipIconComponent
        length={typesInLocation[type].length}
        IconComponent={IconComponent}
        type={type}
        key={type}
      />
    );
  });
}

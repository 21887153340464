import { MenuItem, TextField } from '@mui/material';
import { useMemo } from 'react';
import { useController } from 'react-hook-form';

export function TextFieldController({
  name,
  label,
  control,
  disabled,
  rules,
  valueAsNumber,
  parse,
  format,
  fullWidth,
  placeholder,
  multiline,
  minRows,
  type,
  options = [],
  sx,
  onChange: callback,
  defaultValue = null,
  helperText,
}) {
  const {
    field: { value, disabled: isDisabled, onChange, onBlur, ref },
    fieldState: { error, invalid },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
    disabled,
    valueAsNumber,
  });
  const valuesNotInOptions = useMemo(
    () =>
      value
        ? [value]
            .flat()
            .filter(
              (value) =>
                !options.find(
                  (option) =>
                    (option.value ?? option) === (value.value ?? value),
                ),
            )
        : [],

    [value, options],
  );

  function handleChange(event) {
    onChange(format ? format(event.target.value) : event.target.value);
    if (callback) {
      callback(event);
    }
  }

  return (
    <TextField
      inputRef={ref}
      value={parse ? parse(value) : value ?? ''}
      onChange={handleChange}
      disabled={isDisabled}
      onBlur={onBlur}
      inputProps={{
        maxLength: rules?.maxLength,
        type: valueAsNumber ? 'number' : type,
        min: valueAsNumber ? 0 : undefined,
      }}
      size="small"
      label={label}
      select={options.length > 0}
      fullWidth={fullWidth}
      placeholder={placeholder}
      multiline={multiline}
      minRows={minRows}
      error={invalid}
      helperText={error?.message ?? helperText}
      sx={{ minWidth: 240, ...sx }}
    >
      {options.length > 0 &&
        valuesNotInOptions.map((option) => (
          <MenuItem key={option.value ?? option} value={option.value ?? option}>
            {option.label ?? option}
          </MenuItem>
        ))}
      {options.length > 0 &&
        options.map((option) => (
          <MenuItem key={option.value ?? option} value={option.value ?? option}>
            {option.label ?? option}
          </MenuItem>
        ))}
    </TextField>
  );
}

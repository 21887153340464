import { shortHumanizer } from '@/utils';
import { events } from '@/utils/config';

const {
  eventFilters: { vehicleEquipmentActivations: eventFilters },
} = events;

export const pipelineFn = (orderBy, order, page, rowsPerPage, query) => [
  { $match: query },
  {
    $project: {
      identifier: true,
      startTime: true,
      endTime: true,
      durationSeconds: true,
      eventSubtype: true,
      vehicle: true,
    },
  },
  { $sort: { [orderBy]: order === 'desc' ? -1 : 1 } },
  { $skip: page * rowsPerPage },
  { $limit: rowsPerPage },
];

export const downloadPipelineFn = (query) => [
  { $match: query },
  {
    $project: {
      identifier: true,
      startTime: true,
      endTime: true,
      durationSeconds: { $divide: ['$durationSeconds', 86400] },
      eventSubtype: true,
      vehicle: true,
    },
  },
];

export const headers = [
  {
    label: 'Registration',
    key: 'vehicle.registrationNumber',
    type: 'text',
    filter: true,
  },
  {
    label: 'Fleet Number',
    key: 'vehicle.fleetNumber',
    type: 'text',
    filter: true,
  },
  {
    label: 'Vehicle Role',
    key: 'vehicle.role',
    type: 'text',
    filter: true,
  },
  {
    label: 'Equipment Type',
    key: 'eventSubtype',
    format: (value) =>
      eventFilters[0].options.find((e) => e.value === value)?.label ?? value,
    type: 'text',
  },
  { label: 'Start Time', key: 'startTime', type: 'date' },
  { label: 'End Time', key: 'endTime', type: 'date' },
  {
    label: 'Duration',
    key: 'durationSeconds',
    format: (value) => shortHumanizer(value * 1000),
    type: 'text',
    align: 'right',
    filter: false,
  },
];

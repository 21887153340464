import { DateTimePicker, Table, TablePagination } from '@/components/controls';
import { useAuditLogEntries, useDocumentTitle, useUserIds } from '@/hooks';
import { downloadCSV, getFilenameForDownload } from '@/utils';
import { rowsPerPageOptions } from '@/utils/config';
import {
  Autorenew as AutorenewIcon,
  Close as CloseIcon,
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
  Remove as RemoveIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Popover,
  TextField,
  Toolbar,
  Tooltip,
} from '@mui/material';
import { format } from 'date-fns';
import { atom, useAtom } from 'jotai';
import _ from 'lodash';
import { useState } from 'react';
import { Link } from './Link';
import { Parameters } from './Parameters';

const headers = [
  { label: 'User ID', key: 'userId', type: 'text' },
  { label: 'Time', key: 'time', type: 'date' },
  { label: 'Data Type', key: 'dataType', type: 'text' },
  { label: 'Action', key: 'action', type: 'text' },
  { label: 'Parameters', key: 'parameters', type: 'text' },
];

const tableHeaders = [
  { label: 'User ID', key: 'userId', type: 'text' },
  { label: 'Time', key: 'time', type: 'date' },
  { label: 'Data Type', key: 'dataType', type: 'text' },
  { label: 'Action', key: 'action', type: 'text' },
  {
    label: 'Parameters',
    key: 'parameters',
    type: 'component',
    component: Parameters,
  },
  { label: 'Link', key: 'link', type: 'component', component: Link },
];

const filterAtom = atom({
  startTime: null,
  endTime: null,
  page: 0,
  rowsPerPage: rowsPerPageOptions[0],
  order: 'asc',
  orderBy: 'time',
  userId: null,
  dataType: null,
  action: null,
  searchText: '',
});

export function Audit() {
  useDocumentTitle('IR3 | Audit');
  const [
    {
      startTime,
      endTime,
      page,
      rowsPerPage,
      order,
      orderBy,
      userId,
      dataType,
      action,
      searchText,
    },
    setFilter,
  ] = useAtom(filterAtom);
  const {
    data: entries,
    isLoading,
    refetch,
  } = useAuditLogEntries(startTime, endTime, userId);
  const { data: userIds } = useUserIds();
  const [anchorEl, setAnchorEl] = useState(null);

  function filterEntries(entry) {
    if (userId && userId !== entry.userId) {
      return false;
    }

    if (dataType && dataType !== entry.dataType) {
      return false;
    }

    if (action && action !== entry.action) {
      return false;
    }

    const json = entry.parameters && JSON.stringify(entry.parameters);
    if (
      searchText &&
      json &&
      !json.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return false;
    }

    return true;
  }

  function handleRefreshClick() {
    refetch();
  }

  function handleFilterOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleFilterClose() {
    setAnchorEl(null);
  }

  function updateFilter(update) {
    setFilter((filter) => ({
      ...filter,
      ...update,
    }));
  }

  const handleFilterChange = (name) => (event, value) => {
    updateFilter({ [name]: value });
  };

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    updateFilter({
      orderBy,
      order: 'asc',
    });
  }

  function handleStartTimeChange(startTime) {
    updateFilter({ startTime });
  }

  function handleEndTimeChange(endTime) {
    updateFilter({ endTime });
  }

  function handleUserIdChange(event, userId) {
    updateFilter({ userId });
  }

  function handleDownloadClick() {
    const data = filteredEntries.map((entry) => ({
      userId: entry.userId,
      time: format(new Date(entry.time), 'yyyy-MM-dd HH:mm:ss'),
      application: entry.application,
      dataType: entry.dataType,
      action: entry.action,
      parameters: entry.parameters
        ? JSON.stringify(entry.parameters)
        : //.replace(/"/g,`""`)
          '',
    }));
    const filename = getFilenameForDownload(
      'Audits Log Entries',
      'csv',
      startTime,
      endTime,
    );
    downloadCSV(data, filename, headers);
  }

  const filteredEntries = entries.filter(filterEntries);
  if (filteredEntries.length < page * rowsPerPage) {
    updateFilter({ page: ~~(filteredEntries.length / rowsPerPage) });
  }

  return (
    <Box
      sx={{
        flex: 1,
        height: 'calc(100vh - 48px)',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Toolbar variant="dense" disableGutters sx={{ p: 2, pb: 0 }}>
        <Box sx={{ flexGrow: 1 }} />
        {isLoading && (
          <CircularProgress sx={{ m: 1 }} size={16} thickness={6} />
        )}
        <Autocomplete
          size="small"
          value={userId}
          options={Object.values(userIds)}
          onChange={handleUserIdChange}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="User ID"
              sx={{ width: 320, px: 1 }}
            />
          )}
        />
        <DateTimePicker
          value={startTime}
          onChange={handleStartTimeChange}
          clearable
          sx={{ width: 240 }}
          maxDate={new Date(endTime || '2100-01-01')}
        />
        <RemoveIcon sx={{ px: 0.5 }} />
        <DateTimePicker
          value={endTime}
          onChange={handleEndTimeChange}
          clearable
          sx={{ width: 240, pr: 1 }}
          minDate={new Date(startTime || '1900-01-01')}
          disableFuture
        />
        <Tooltip title={isLoading ? 'Cancel' : 'Fetch'}>
          <IconButton onClick={handleRefreshClick}>
            {isLoading ? <CloseIcon color="error" /> : <AutorenewIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Filter">
          <IconButton
            aria-owns={anchorEl ? 'filter-popover' : null}
            aria-haspopup="true"
            onClick={handleFilterOpen}
          >
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <Popover
          id="filter-popover"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleFilterClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Box sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
            <Autocomplete
              size="small"
              value={userId}
              options={_.uniq(
                entries
                  .map((entry) => entry.userId)
                  .filter((value) => value)
                  .sort(),
              )}
              onChange={handleFilterChange('userId')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ m: 1, width: 200 }}
                  label="User ID"
                />
              )}
            />
            <Autocomplete
              size="small"
              value={dataType}
              options={_.uniq(
                entries
                  .map((entry) => entry.dataType)
                  .filter((value) => value)
                  .sort(),
              )}
              onChange={handleFilterChange('dataType')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ m: 1, width: 200 }}
                  label="Data Type"
                />
              )}
            />
            <Autocomplete
              size="small"
              value={action}
              options={_.uniq(
                entries
                  .map((entry) => entry.action)
                  .filter((value) => value)
                  .sort(),
              )}
              onChange={handleFilterChange('action')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ m: 1, width: 200 }}
                  label="Action"
                />
              )}
            />
            <TextField
              sx={{ m: 1, width: 200 }}
              value={searchText || ''}
              onChange={(e) => updateFilter({ searchText: e.target.value })}
              label="Search"
            />
          </Box>
        </Popover>
        <Tooltip title="Download">
          <Box component="span">
            <IconButton
              disabled={filteredEntries.length === 0}
              onClick={handleDownloadClick}
            >
              <GetAppIcon />
            </IconButton>
          </Box>
        </Tooltip>
      </Toolbar>
      <Paper sx={{ m: [0, 1, 1], minWidth: 240 }}>
        <Table
          styles={{
            tableContainer: {
              height: 'calc(100vh - 172px)',
              overflowY: 'scroll',
            },
            table: {
              minWidth: 750,
            },
          }}
          data={filteredEntries}
          headers={tableHeaders}
          rowsPerPage={rowsPerPage}
          page={page}
          order={order}
          orderBy={orderBy}
          onOrderChange={handleOrderChange}
          onOrderByChange={handleOrderByChange}
        />
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={filteredEntries.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>
    </Box>
  );
}

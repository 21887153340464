import { Box, Tooltip } from '@mui/material';
import { useState } from 'react';

export function OverflowTip({ title, children }) {
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  const handleShouldShow = ({ currentTarget }) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true);
    }
  };

  return (
    <Tooltip
      title={title}
      open={tooltipEnabled}
      onClose={() => setTooltipEnabled(false)}
    >
      <Box
        onMouseEnter={handleShouldShow}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </Box>
    </Tooltip>
  );
}

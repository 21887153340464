import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';
import { startOfWeek } from 'date-fns';

export function useCurrentObjectiveCounts(groupType, groupId) {
  return useQuery({
    queryKey: ['currentObjectiveCounts', groupType, groupId],
    queryFn: () => {
      const weekStart = import.meta.env.DEV
        ? new Date('2000-01-01')
        : startOfWeek(new Date());
      const json = [
        { $match: { endTime: { $gt: weekStart } } },
        {
          $group: {
            _id: {
              identifier: '$objective.identifier',
              title: '$objective.title',
            },
            totalAttendances: { $sum: 1 },
            groupAttendances: {
              $sum: {
                $cond: [
                  {
                    $in: [
                      groupId,
                      { $ifNull: [`$person.groups.${groupType}`, []] },
                    ],
                  },
                  1,
                  0,
                ],
              },
            },
          },
        },
        { $match: { groupAttendances: { $gt: 0 } } },
        {
          $project: {
            _id: false,
            identifier: '$_id.identifier',
            title: '$_id.title',
            totalAttendances: true,
            groupAttendances: true,
          },
        },
        { $sort: { title: 1 } },
      ];

      return api.post('pipeline/personObjectiveAttendances', { json }).json();
    },
    placeholderData: [],
  });
}

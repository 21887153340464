import { getStatusColor } from '@/utils';
import { CarCrash as CarCrashIcon } from '@mui/icons-material';
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { format } from 'date-fns';
import { dateTimeSecondsFormat } from '..';
// import { Link } from 'react-router-dom';

export function AccelerometerEventListItem({
  item: { size, start },
  feature,
  ...props
}) {
  const primary =
    feature.properties.vehicle?.registrationNumber ?? feature.properties.imei;
  const secondary = format(feature.properties.startTime, dateTimeSecondsFormat);

  return (
    <ListItemButton
      {...props}
      dense
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: `${size}px`,
        transform: `translateY(${start}px)`,
      }}
      // component={Link}
      // to={'.'}
    >
      <ListItemAvatar>
        <Avatar
          sx={{
            bgcolor: getStatusColor('default'),
          }}
        >
          <CarCrashIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={primary}
        primaryTypographyProps={{ noWrap: true }}
        secondary={secondary}
        secondaryTypographyProps={{ noWrap: true }}
      />
    </ListItemButton>
  );
}

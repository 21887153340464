import { getRefreshedAccessToken } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useIsAuthenticated() {
  return useQuery({
    queryKey: ['isAuthenticated'],
    queryFn: async () => {
      const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
      const expiresIn = expiresAt - new Date().getTime();

      if (expiresIn > 0) {
        return true;
      } else if (expiresAt) {
        const authResult = await getRefreshedAccessToken();
        const expiresAt = JSON.stringify(
          authResult.expires_in * 1000 + new Date().getTime(),
        );
        localStorage.setItem('access_token', authResult.access_token);
        if (authResult.refresh_token) {
          localStorage.setItem('refresh_token', authResult.refresh_token);
        }
        localStorage.setItem('expires_at', expiresAt);

        return true;
      }

      return false;
    },
    placeholderData: (previousData) => previousData ?? false,
  });
}

import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useOccurrenceOic(code) {
  return useQuery({
    queryKey: ['occurrenceWithOic', code],
    queryFn: () => {
      return briefingApi
        .get(`niche/occurrences/${code}?entity=oic&includeOel=true`, {
          timeout: false,
        })
        .json();
    },
    enabled: !!code,
    staleTime: 30 * 60 * 1000,
  });
}

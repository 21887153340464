import { api } from '@/apis';
import { log } from '@/utils';
import { locationTypes } from '@/utils/config';
import { useQuery } from '@tanstack/react-query';

export function useLocations(type) {
  return useQuery({
    queryKey: ['locations', type],
    queryFn: async () => {
      const json = [
        type !== 'All' && {
          $match: {
            type:
              type === 'homeStation'
                ? {
                    $in: Object.values(locationTypes)
                      .filter((type) => type.isHomeStation)
                      .map((type) => type.value),
                  }
                : type,
          },
        },
        {
          $graphLookup: {
            from: 'groups',
            startWith: '$groupCodes',
            connectFromField: 'parentCodes',
            connectToField: 'code',
            as: 'groupAncestorCodes',
          },
        },
        {
          $project: {
            code: true,
            name: true,
            type: true,
            picture: true,
            groupAncestorCodes: {
              $map: {
                input: '$groupAncestorCodes',
                as: 'group',
                in: '$$group.code',
              },
            },
            searchString: {
              $toLower: {
                $concat: ['$name', '+', '$code'],
              },
            },
          },
        },
        { $sort: { name: 1 } },
      ].filter(Boolean);

      log('Read', 'Locations', { type });

      return api.post('pipeline/locations', { json }).json();
    },
    placeholderData: [],
    staleTime: 60 * 60 * 1000,
  });
}

import { DataGrid } from '@/components/controls';
import { useNonPollingVehicles } from '@/hooks';
import {
  Box,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';

const summaryColumns = [
  {
    accessorKey: 'twoWeeks',
    header: '2 Weeks - 1 Month',
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
    size: 0,
  },
  {
    accessorKey: 'oneMonth',
    header: '1 - 6 Months',
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
    size: 0,
  },
  {
    accessorKey: 'sixMonths',
    header: '> 6 Months',
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
    size: 0,
  },
];

const columns = [
  {
    accessorKey: 'registrationNumber',
    header: 'Registration',
    size: 0,
    Cell: ({ row: { original } }) => (
      <Tooltip
        title={
          <table>
            <tbody>
              <tr>
                <td>Fleet Number</td>
                <td>{original.fleetNumber}</td>
              </tr>
              <tr>
                <td>VIN</td>
                <td>{original.identificationNumber}</td>
              </tr>
            </tbody>
          </table>
        }
        placement="right"
      >
        <Box component="span">{original.registrationNumber}</Box>
      </Tooltip>
    ),
  },
  {
    accessorKey: 'homeStation',
    header: 'Home Station',
    size: 0,
  },
  {
    accessorKey: 'lastPollTime',
    header: 'Last Poll Time',
    size: 0,
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
];

export function NonPollingVehiclesCard() {
  const {
    data: { twoWeeks, oneMonth, sixMonths, vehicles },
  } = useNonPollingVehicles();

  return (
    <Card variant="outlined" sx={{ maxWidth: 560 }}>
      <CardContent>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          Non-polling Vehicles
        </Typography>
        <Stack spacing={2}>
          <DataGrid
            data={[{ twoWeeks, oneMonth, sixMonths }]}
            columns={summaryColumns}
            muiTableBodyRowProps={{
              sx: { '&:last-child td, &:last-child th': { border: 0 } },
            }}
          />
          <DataGrid
            enableSorting={true}
            enableBottomToolbar={true}
            enablePagination={true}
            data={vehicles}
            columns={columns}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}

import { get } from '@/utils';
import { FilterList as FilterListIcon } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  IconButton,
  Popover,
  TextField,
  Tooltip,
} from '@mui/material';
import _ from 'lodash';
import { Fragment, useState } from 'react';

export function FilterPicker({
  headers,
  data,
  filter,
  onFilterChange,
  filterValues,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleFilterClose() {
    setAnchorEl(null);
  }

  function handleFilterOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  return (
    <Fragment>
      <Tooltip title="Filter">
        <IconButton
          aria-owns={anchorEl ? 'filter-popover' : null}
          aria-haspopup="true"
          onClick={handleFilterOpen}
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id="filter-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
          <FilterPopover
            headers={headers}
            data={data}
            filter={filter}
            filterValues={filterValues}
            onFilterChange={onFilterChange}
          />
        </Box>
      </Popover>
    </Fragment>
  );
}

function FilterPopover({
  headers,
  data,
  filter,
  filterValues,
  onFilterChange,
}) {
  const filterArray = headers.filter(({ filter }) => filter !== false);

  return filterArray.map((filterItem, index) => {
    function createOptions() {
      return _.uniq(
        data
          .map((dataItem) => get(dataItem, filterItem.key))
          .flatMap((item) => item)
          .filter((value) => value !== undefined)
          .sort(),
      );
    }

    function updateFilter(update) {
      onFilterChange({
        ...filter,
        fields: {
          ...filter.fields,
          ...update,
        },
      });
    }

    const handleFilterChange = (name) => (_, value) => {
      let key = name;
      if (Array.isArray(key)) {
        key = key.join('.');
      }
      updateFilter({ [key]: value });
    };

    const path = Array.isArray(filterItem.key)
      ? filterItem.key.join('.')
      : filterItem.key;
    const fieldValue = filter.fields[path];

    return (
      <Autocomplete
        size="small"
        key={index}
        value={
          fieldValue === null || typeof fieldValue === 'undefined'
            ? null
            : fieldValue
        }
        options={filterValues?.[path]?.sort() ?? createOptions()}
        getOptionLabel={(option) =>
          typeof option === 'boolean' ? (option ? 'Yes' : 'No') : option
        }
        onChange={handleFilterChange(filterItem.key)}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ m: 0.5, width: 200 }}
            label={filterItem.label}
          />
        )}
      />
    );
  });
}

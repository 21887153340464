import { alpha, Avatar, Box, IconButton, Tooltip } from '@mui/material';

export function MapButton({
  children,
  disabled,
  title,
  color,
  bgcolor,
  ...props
}) {
  return (
    <Tooltip title={title} placement="right">
      <Box component="span">
        <IconButton size="small" disabled={disabled} {...props}>
          <Avatar
            sx={(theme) => ({
              bgcolor: alpha(bgcolor ?? theme.palette.background.paper, 0.85),
              color: disabled ? 'text.disabled' : color ?? 'inherit',
            })}
          >
            {children}
          </Avatar>
        </IconButton>
      </Box>
    </Tooltip>
  );
}

import {
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
} from '@mui/icons-material';
import { Chip } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { dateTimeSecondsFormat } from '.';

function formatLabel(value) {
  return format(new Date(value), dateTimeSecondsFormat);
}

function ChipField({
  time,
  toggleOpen,
  isPlaying,
  togglePlaying,
  speed,
  onSpeedClick,
  InputProps: { ref } = {},
}) {
  function handleSpeedClick(event) {
    event.stopPropagation();
    onSpeedClick();
  }

  return (
    <Chip
      ref={ref}
      color="primary"
      label={formatLabel(time)}
      onClick={toggleOpen}
      onDelete={togglePlaying}
      deleteIcon={isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      icon={
        <Chip
          color="secondary"
          label={speed}
          size="small"
          onClick={handleSpeedClick}
        />
      }
    />
  );
}

export function ReplayButton({
  time,
  onTimeChange,
  isPlaying,
  togglePlaying,
  speed,
  onSpeedClick,
}) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(time);

  useEffect(() => {
    setValue(time);
  }, [time]);

  function toggleOpen() {
    setOpen((prev) => !prev);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  return (
    <DateTimePicker
      slots={{ field: ChipField }}
      slotProps={{
        field: {
          time,
          toggleOpen,
          isPlaying,
          togglePlaying,
          speed,
          onSpeedClick,
        },
      }}
      value={value}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      onAccept={onTimeChange}
      ampm={false}
      views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
    />
  );
}

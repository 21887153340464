import {
  useAggregate,
  useDocumentTitle,
  usePerson,
  useTrip,
  useVehicle,
} from '@/hooks';
import {
  Flag as FlagIcon,
  VolumeUp as VolumeUpIcon,
} from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { grey, indigo } from '@mui/material/colors';
import { format } from 'date-fns';
import {
  AlarmLight as AlarmLightIcon,
  CarLightHigh as CarLightHighIcon,
  FlagCheckered as FlagCheckeredIcon,
} from 'mdi-material-ui';
import { useParams } from 'react-router-dom';
import { TimelinePanel } from '../../panels';

const equipmentLabels = {
  BEACONSON: { label: 'Beacons', Icon: AlarmLightIcon },
  HEADLIGHTSFLASHON: { label: 'Headlights Flash', Icon: CarLightHighIcon },
  SIRENSON: { label: 'Sirens', Icon: VolumeUpIcon },
};

export function Trip() {
  useDocumentTitle('IR3 | Trip');
  const { id } = useParams();
  const { data: trip } = useTrip(id);
  const { data: vehicle } = useVehicle(trip?.vehicle?.identificationNumber);
  const { data: driver } = usePerson(trip?.driver?.code);
  const { data: equipmentActivations } = useAggregate(
    'vehicleEquipmentActivations',
    [
      { $match: { 'parentEvent.identifier': id } },
      {
        $project: {
          _id: false,
          startTime: true,
          endTime: true,
          eventSubtype: true,
        },
      },
    ],
    !!id,
  );

  const tripEntries = trip
    ? [
        {
          label: 'Ignition On',
          time: trip.startTime,
          bgcolor: grey[900],
          Icon: FlagIcon,
        },
        {
          label: 'Ignition Off',
          time: trip.endTime,
          bgcolor: grey[400],
          Icon: FlagCheckeredIcon,
        },
      ]
    : [];

  const entries = [
    ...tripEntries,
    ...equipmentActivations
      .map((equipmentActivation) => [
        {
          label: `${
            equipmentLabels[equipmentActivation.eventSubtype].label
          } On`,
          time: equipmentActivation.startTime,
          bgcolor: indigo[700],
          Icon: equipmentLabels[equipmentActivation.eventSubtype].Icon,
        },
        {
          label: `${
            equipmentLabels[equipmentActivation.eventSubtype].label
          } Off`,
          time: equipmentActivation.endTime,
          bgcolor: indigo[200],
          Icon: equipmentLabels[equipmentActivation.eventSubtype].Icon,
        },
      ])
      .flat(),
  ].sort((a, b) => a.time - b.time);

  return (
    trip && (
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            width: 200,
            height: 'calc(100vh - 48px)',
            borderRight: 1,
            borderColor: 'divider',
            flexShrink: 0,
          }}
        >
          <Stack sx={{ p: 1, height: 1 }}>
            <Typography
              variant="subtitle2"
              sx={{ color: 'text.secondary', pb: 1 }}
            >
              Timeline
            </Typography>
            <TimelinePanel entries={entries} />
          </Stack>
        </Box>
        <Box sx={{ flexGrow: 1, p: 1 }}>
          <Stack direction="row" spacing={1}>
            <Typography variant="h6">{`${format(
              trip.startTime,
              'dd/MM/yyyy HH:mm:ss',
            )} - ${format(trip.endTime, 'dd/MM/yyyy HH:mm:ss')}`}</Typography>
            <Typography
              variant="h6"
              sx={{ color: 'text.disabled' }}
            >{`${trip.distanceMiles} miles`}</Typography>
          </Stack>
          <p>{vehicle?.registrationNumber}</p>
          <p>{driver?.forenames}</p>
        </Box>
      </Box>
    )
  );
}

import { LiveListItem } from './LiveListItem';

export function CallSignLiveListItem(props) {
  const callSign = props?.item ?? {};
  callSign.tertiary = [callSign.status, callSign.incident?.number]
    .filter(Boolean)
    .join(' ');

  return (
    <LiveListItem
      type="callSigns"
      primaryPath="code"
      // secondaryPath="secondary"
      {...props}
      tertiaryPath="tertiary"
      hideFollow={true}
      // shownPaths={['status', 'incident.number']}
    />
  );
}

import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function usePrisoner(code) {
  return useQuery({
    queryKey: ['prisoners', code],
    queryFn: () => {
      if (code === 'new') {
        return {
          name: '',
          custodyNumber: '',
          occurrenceCode: '',
          arrestingOfficerCode: '',
          investigatingOfficerCode: '',
          officerInChargeCode: '',
          arrivalTime: null,
          custodyUnit: '',
          observationLevel: '',
        };
      }

      return briefingApi.get(`prisoners/${code}`).json();
    },
    enabled: !!code,
  });
}

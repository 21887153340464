import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useBrief(code) {
  return useQuery({
    queryKey: ['briefs', code],
    queryFn: () => {
      if (code === 'new') {
        return {
          title: '',
          groupCodes: [],
          excludedSections: [],
        };
      }

      return briefingApi.get(`briefs/${code}`).json();
    },
    enabled: !!code,
  });
}

import { IconButton } from '@mui/material';

export function IconButtonField({
  input: { onChange, value, ...input },
  trueIcon: TrueIcon,
  falseIcon: FalseIcon,
  ...props
}) {
  return (
    <IconButton
      {...input}
      {...props}
      onClick={() => onChange(!value)}
      size="large"
    >
      {value ? <TrueIcon /> : <FalseIcon />}
    </IconButton>
  );
}

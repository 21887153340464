import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useGroup(code, copy) {
  return useQuery({
    queryKey: ['groups', code, copy],
    queryFn: async () => {
      if (code === 'new') {
        return {
          name: '',
        };
      }

      const json = [
        { $match: { code } },
        {
          $graphLookup: {
            from: 'groups',
            startWith: '$parentCodes',
            connectFromField: 'parentCodes',
            connectToField: 'code',
            as: 'ancestors',
            depthField: 'depth',
          },
        },
        {
          $set: {
            ancestors: {
              $map: {
                input: {
                  $sortArray: {
                    input: {
                      $filter: {
                        input: '$ancestors',
                        cond: {
                          $not: [{ $in: ['$$ancestor.code', '$parentCodes'] }],
                        },
                        as: 'ancestor',
                      },
                    },
                    sortBy: { depth: -1, type: 1, name: 1 },
                  },
                },
                as: 'group',
                in: {
                  code: '$$group.code',
                  name: '$$group.name',
                  type: '$$group.type',
                },
              },
            },
          },
        },
        copy && { $project: { _id: false, code: false } },
      ].filter(Boolean);

      const groups = await api.post('pipeline/groups', { json }).json();

      log('Read', 'Group', { code });

      return groups[0] ?? null;
    },
    staleTime: 30 * 60 * 1000,
  });
}

import { EventsSource } from './EventsSource';
import { IncidentsSource } from './IncidentsSource';
import { LocationsSource } from './LocationsSource';
import { ObjectivesSource } from './ObjectiveSource';
import { PeopleSource } from './PeopleSource';
import { VehiclesSource } from './VehiclesSource';

export function Source(props) {
  switch (props.id) {
    case 'vehicles':
      return <VehiclesSource {...props} />;
    case 'people':
      return <PeopleSource {...props} />;
    case 'locations':
      return <LocationsSource {...props} />;
    case 'incidents':
      return <IncidentsSource {...props} />;
    case 'objectives':
      return <ObjectivesSource {...props} />;
    case 'events':
      return <EventsSource {...props} />;
    default:
      return null;
  }
}

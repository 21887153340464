import {
  AutocompleteController,
  GroupCodesController,
} from '@/components/controls';
import { usePeople, useVehicles } from '@/hooks';
import { useMemo } from 'react';

export function ApplicableToCodesController({
  control,
  resourceType,
  isDisabled,
}) {
  const { data: people } = usePeople();
  const { data: vehicles } = useVehicles();
  const peopleCodes = useMemo(
    () =>
      people
        .sort(
          (a, b) =>
            (a.surname ?? '').localeCompare(b.surname ?? '') ||
            (a.forenames ?? '').localeCompare(b.forenames ?? ''),
        )
        .map((person) => ({
          label:
            `${person.forenames ?? ''} ${person.surname ?? ''}`.trim() ||
            person.code,
          value: person.code,
        })),
    [people],
  );
  const vehicleIdentificationNumbers = useMemo(
    () =>
      vehicles
        .sort((a, b) =>
          (a.registrationNumber ?? a.identificationNumber ?? '').localeCompare(
            b.registrationNumber ?? b.identificationNumber ?? '',
          ),
        )
        .map((vehicle) => ({
          label: vehicle.registrationNumber ?? vehicle.identificationNumber,
          value: vehicle.identificationNumber,
        })),
    [vehicles],
  );

  switch (resourceType) {
    case 'people':
      return (
        <AutocompleteController
          name="personCodes"
          control={control}
          fullWidth
          options={peopleCodes}
          disabled={isDisabled('personCodes')}
        />
      );
    case 'vehicles':
      return (
        <AutocompleteController
          name="vehicleIdentificationNumbers"
          control={control}
          fullWidth
          options={vehicleIdentificationNumbers}
          disabled={isDisabled('vehicleIdentificationNumbers')}
        />
      );
    case 'personGroups':
      return <GroupCodesController name="personGroupCodes" control={control} />;
    case 'vehicleGroups':
      return (
        <GroupCodesController name="vehicleGroupCodes" control={control} />
      );
    default:
      return null;
  }
}

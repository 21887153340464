import { FormControl, InputLabel, Slider } from '@mui/material';
import { useEffect, useState } from 'react';

export function SliderField({
  input: { onChange, value, ...input },
  fallbackValue,
  fullWidth,
  className,
  label,
  ...props
}) {
  // react final form spy workaround
  useEffect(() => {
    onChange(value === undefined || value === '' ? fallbackValue ?? 0 : value);
  }, [value, onChange, fallbackValue]);

  // make sure value is an int
  const parsed = parseInt(value);
  value = isNaN(parsed) ? fallbackValue || 0 : parsed;

  const [tick, setTick] = useState(value);

  return (
    <FormControl fullWidth={fullWidth} className={className}>
      <InputLabel shrink={true} htmlFor="slider">
        {label}
      </InputLabel>
      <div
        style={{ paddingTop: label ? 17 : 6, paddingLeft: 6, paddingRight: 6 }}
      >
        <Slider
          id="slider"
          {...input}
          {...props}
          onChange={(e, value) => {
            setTick(value);
          }}
          onChangeCommitted={(e, value) => {
            onChange(value);
          }}
          value={tick}
        />
      </div>
    </FormControl>
  );
}

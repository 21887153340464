import { CANCEL_TRACKING, UPDATE_TRACKING } from '@/actions';
import { log } from '@/utils';
import { useAuth } from '@/hooks';
import { GpsFixed as TrackingIcon } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

export function TrackingStatus({
  imei,
  onCancel,
  onEnable,
  onDisable,
  pendingMessage,
}) {
  const box = useSelector((state) => state.telematicsBoxes.boxesByImei[imei]);
  const dispatch = useDispatch();
  const auth = useAuth();

  const canEdit = auth.getAuthorisation()?.commissioning?.write;

  const {
    trackingEnabled: currentlyEnabled,
    trackingWillBeEnabled: willBeEnabled,
    trackingRequest,
  } = box || {};
  let text = '<unknown>';
  let pendingText = '';
  let allowCancel = true;
  let undefinedTracking = false;

  if (typeof currentlyEnabled !== 'undefined') {
    if (currentlyEnabled) {
      text = 'Enabled';
    } else {
      text = 'Disabled';
    }
  } else {
    undefinedTracking = true;
  }

  if (typeof willBeEnabled !== 'undefined') {
    pendingText =
      `${willBeEnabled ? 'En' : 'Dis'}able pending, ` +
      (pendingMessage || `action will complete on next ignition off...`);
  } else {
    allowCancel = false;
  }

  if (trackingRequest) {
    pendingText = 'Requesting...';

    allowCancel = false;
  }

  function enableTracking() {
    log('Update', 'Telematics Tracking', { imei, request: 'Enable' });

    onEnable && onEnable();

    dispatch({
      type: UPDATE_TRACKING,
      payload: {
        imei,
        enabled: true,
      },
    });
  }

  function disableTracking() {
    log('Update', 'Telematics Tracking', { imei, request: 'Disable' });

    onDisable && onDisable();

    dispatch({
      type: UPDATE_TRACKING,
      payload: {
        imei,
        enabled: false,
      },
    });
  }

  function cancelTracking() {
    log('Update', 'Telematics Tracking', { imei, request: 'Cancel' });

    onCancel && onCancel();

    dispatch({
      type: CANCEL_TRACKING,
      payload: {
        imei,
      },
    });
  }

  return (
    <Box>
      {undefinedTracking ? (
        <List>
          <ListItem disableGutters sx={{ height: 56 }}>
            <ListItemAvatar>
              <Avatar>
                {<Skeleton variant="circular" width="100%" height="100%" />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`Unknown state`}>
              <Skeleton height={20} width="100%" />
            </ListItemText>
          </ListItem>
        </List>
      ) : (
        <List>
          <ListItem disableGutters sx={{ height: 56 }}>
            <ListItemAvatar>
              <Avatar
                sx={{
                  bgcolor: currentlyEnabled
                    ? 'primary'
                    : 'action.disabledBackground',
                }}
              >
                <TrackingIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${text}`} secondary={pendingText} />
          </ListItem>
        </List>
      )}
      {canEdit && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            maxWidth: 600,
            minWidth: 466,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{ width: 150 }}
            // disabled={!allowEnable} // should always be enabled in case reapply needed
            onClick={() => enableTracking()}
          >
            Enable
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: 150 }}
            // disabled={!allowDisable} // should always be enabled in case reapply needed
            onClick={() => disableTracking()}
          >
            Disable
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{ width: 150 }}
            disabled={!allowCancel}
            onClick={() => cancelTracking()}
          >
            Cancel
          </Button>
        </Box>
      )}
    </Box>
  );
}

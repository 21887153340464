import {
  AccountBalance as AccountBalanceIcon,
  Build as BuildIcon,
  Business as BusinessIcon,
  GroupWork as CallSignIcon,
  DataUsage,
  DirectionsCar as DirectionsCarIcon,
  Feedback as FeedbackIcon,
  LocationSearching as FollowIcon,
  LocalHospital as LocalHospitalIcon,
  TwoWheeler as MotorcycleIcon,
  Person as PersonIcon,
  Router as RouterIcon,
  School as SchoolIcon,
  LocalOffer as TagIcon,
} from '@mui/icons-material';
import {
  AxisArrow as AxisArrowIcon,
  RadioHandheld as RadioHandheldIcon,
} from 'mdi-material-ui';

export function LiveIcon({ type, subtype }) {
  const liveIconsByTypeAndSubtype = {
    incident: { default: <FeedbackIcon /> },
    vehicle: { default: <DirectionsCarIcon />, MOTORCYCLE: <MotorcycleIcon /> },
    person: { default: <PersonIcon /> },
    event: { default: <AxisArrowIcon /> },
    location: {
      default: <BusinessIcon />,
      Workshop: <BuildIcon />,
      Hospital: <LocalHospitalIcon />,
      Court: <AccountBalanceIcon />,
      School: <SchoolIcon />,
    },
    objective: { default: <DataUsage /> },
    tag: {
      default: <TagIcon />,
      follow: <FollowIcon />,
    },
    callSign: { default: <CallSignIcon /> },
    radio: { default: <RadioHandheldIcon /> },
    telematicsBox: { default: <RouterIcon /> },
    default: { default: '?' },
  };

  type = type in liveIconsByTypeAndSubtype ? type : 'default';
  subtype = subtype in liveIconsByTypeAndSubtype[type] ? subtype : 'default';

  return liveIconsByTypeAndSubtype[type][subtype];
}

import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useUncheckedVehicles(groupType, groupId) {
  return useQuery({
    queryKey: ['uncheckedVehicles', groupType, groupId],
    queryFn: () => {
      const json = [
        {
          $match: {
            $or: [
              { disposalDate: { $exists: false } },
              { disposalDate: { $gte: '$$NOW' } },
            ],
            lastPollTime: { $exists: true },
          },
        },
        {
          $lookup: {
            from: 'locations',
            localField: 'homeStation',
            foreignField: 'code',
            as: 'homeStation',
          },
        },
        { $unwind: '$homeStation' },
        groupType &&
          groupId && { $match: { [`homeStation.${groupType}`]: groupId } },
        {
          $lookup: {
            from: 'vehicleStates',
            let: { identificationNumber: '$identificationNumber' },
            pipeline: [
              {
                $match: {
                  $and: [
                    { type: 'Check' },
                    {
                      $expr: {
                        $eq: [
                          '$identificationNumber',
                          '$$identificationNumber',
                        ],
                      },
                    },
                  ],
                },
              },
              { $sort: { time: -1 } },
              { $limit: 1 },
              {
                $project: {
                  _id: false,
                  time: true,
                  distanceKilometres: true,
                },
              },
            ],
            as: 'lastCheck',
          },
        },
        { $unwind: { path: '$lastCheck', preserveNullAndEmptyArrays: true } },
        {
          $set: {
            daysWithoutCheck: {
              $dateDiff: {
                startDate: '$lastCheck.time',
                endDate: '$lastPollTime',
                unit: 'day',
              },
            },
          },
        },
        { $match: { daysWithoutCheck: { $gt: 7 } } },
        { $sort: { daysWithoutCheck: -1 } },
        {
          $project: {
            _id: false,
            identificationNumber: true,
            registrationNumber: true,
            fleetNumber: true,
            homeStation: {
              code: true,
              groups: true,
            },
            lastPollTime: true,
            lastCheck: true,
          },
        },
      ].filter(Boolean);

      return api.post('pipeline/vehicles', { json }).json();
    },
    placeholderData: [],
  });
}

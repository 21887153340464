import { format, isValid } from 'date-fns';

export function required(value) {
  // was: return value ? undefined : 'Required';
  // 0 could be a valid value
  if (typeof value === 'undefined' || value === '' || value === null) {
    return 'Required';
  }

  return undefined;
}

export function arrayValueRequired(value) {
  if (value && value.length > 0) {
    return undefined;
  }

  return 'Required';
}

export function scheduleRequired(value) {
  if (value.some((day) => day.some((hour) => hour))) {
    return undefined;
  }

  return 'Required';
}

export function notNegative(value) {
  return value > 0 ? undefined : 'Must be > 0.';
}

export function validateIdentifier(value) {
  if (value === 'new') {
    return 'Invalid';
  }
  if (!value.match(/^[a-zA-Z0-9]+$/)) {
    return 'Only text and numbers are allowed';
  }

  return undefined;
}

function isDateValid(date) {
  return isValid(new Date(date));
}

export function isDateValidOrEmpty(date) {
  return !date || isValid(new Date(date))
    ? undefined
    : 'Date or time is not valid';
}

export function requiredValidDate(date) {
  return isValid(new Date(date)) ? undefined : 'Date or time is not valid';
}

const before = (endDate) => (value) =>
  !isDateValid(endDate) || new Date(value) <= new Date(endDate)
    ? undefined
    : 'Must be before ' + format(new Date(endDate), 'dd/MM/yyyy HH:mm');

const after = (startDate) => (value) => {
  return !isDateValid(startDate) || new Date(value) >= new Date(startDate)
    ? undefined
    : 'Must be after ' + format(new Date(startDate), 'dd/MM/yyyy HH:mm');
};

const beforeInclusiveDate = (endDate) => (value) =>
  !isDateValid(endDate) || new Date(value) <= new Date(endDate)
    ? undefined
    : 'Must be before ' + format(endDate, 'dd/MM/yyyy');

const afterInclusiveDate = (startDate) => (value) => {
  return !isDateValid(startDate) || new Date(value) >= new Date(startDate)
    ? undefined
    : 'Must be after ' + format(new Date(startDate), 'dd/MM/yyyy');
};

const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined,
    );

export const requiredNotNegative = composeValidators(required, notNegative);
export const requiredDateBefore = (endDate) =>
  composeValidators(required, requiredValidDate, before(endDate));
export const requiredDateAfter = (startDate) =>
  composeValidators(required, requiredValidDate, after(startDate));

export const requiredInclusiveDateBefore = (endDate) =>
  composeValidators(required, requiredValidDate, beforeInclusiveDate(endDate));
export const requiredInclusiveDateAfter = (startDate) =>
  composeValidators(required, requiredValidDate, afterInclusiveDate(startDate));

import {
  Avatar,
  Badge,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useDocumentTitle } from '@/hooks';
import _ from 'lodash';
import { format } from 'date-fns';
import { RetrospectiveTypeIcon } from '@/components/controls';
import { AssociatedVehicle } from './AssociatedVehicle';

export function StopCheck({
  SCOccurrence__Label,
  SCOccurrence__OccurrenceFileNoG,
  SCOccurrence__Type1G,
  SCOccurrence__CreTime,
  SCOccurrence__Summary,
  PhysicalAddress__LabelAddressOnly,
  person,
}) {
  const isTrafficOffenceReport = SCOccurrence__Summary === 'TOR MobUpdate';
  return (
    <Card sx={{ m: [1, 0.5, 1, 1] }}>
      {useDocumentTitle('IR3 | Stop Checks')}
      <CardHeader
        avatar={
          <Badge>
            <Avatar title={SCOccurrence__Label}>
              <RetrospectiveTypeIcon type="stopChecks" />
            </Avatar>
          </Badge>
        }
        title={SCOccurrence__OccurrenceFileNoG}
        subheader={SCOccurrence__Type1G}
      />
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary">
          Stop Checks
        </Typography>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  {isTrafficOffenceReport ? 'TOR no' : 'Stop check no'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {SCOccurrence__OccurrenceFileNoG}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Type
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {SCOccurrence__Type1G}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Created Time
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {format(new Date(SCOccurrence__CreTime), 'dd-MM-yyy hh:mm')}
                </Typography>
              </TableCell>
            </TableRow>
            {!_.isEmpty(person) && (
              <>
                <TableRow>
                  <TableCell>
                    <Typography variant="caption" color="textSecondary">
                      Person Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">
                      {person.GPerson__LabelName}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="caption" color="textSecondary">
                      Person Gender
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">
                      {person.GPerson__Gender_cacheG}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="caption" color="textSecondary">
                      Person Date of Birth
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">
                      {person.GPerson__DateOfBirth_cacheG}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="caption" color="textSecondary">
                      Person Address
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">
                      {person.GPerson__PhysicalAddress_cache}
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            )}
            <AssociatedVehicle
              occurrenceCode={SCOccurrence__OccurrenceFileNoG}
            />
            {isTrafficOffenceReport && PhysicalAddress__LabelAddressOnly && (
              <TableRow>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    Stop location
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">
                    {PhysicalAddress__LabelAddressOnly}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!isTrafficOffenceReport && (
              <TableRow>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    Summary
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">
                    {SCOccurrence__Summary}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

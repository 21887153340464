import { mapStyleAtom } from '@/atoms';
import { getStatusColor } from '@/utils';
import { useAtom } from 'jotai';
import { Layer, Source } from 'react-map-gl/maplibre';

export function IncidentsSource({ id, data, visibility }) {
  const [style] = useAtom(mapStyleAtom);

  return (
    <Source id={id} type="geojson" data={data}>
      <Layer
        id={`${id}-shape-layer`}
        type="symbol"
        layout={{
          'icon-image': 'square',
          'icon-allow-overlap': true,
          'icon-size': ['interpolate', ['linear'], ['zoom'], 5, 0.125, 12, 1],
          visibility,
        }}
        paint={{
          'icon-color': [
            'match',
            ['get', 'status'],
            'opened',
            getStatusColor('opened'),
            'assigned',
            getStatusColor('assigned'),
            'attended',
            getStatusColor('attended'),
            'closed',
            getStatusColor('closed'),
            getStatusColor('default'),
          ],
          'icon-halo-color': '#fff',
          'icon-halo-width': 1,
        }}
      />
      <Layer
        id={`${id}-symbol-layer`}
        type="symbol"
        layout={{
          'text-field': ['get', 'grade'],
          'text-font':
            style === '/map-styles/maptiler.json' ||
            style === '/map-styles/protomaps.json'
              ? ['Roboto Bold']
              : ['Arial Bold'],
          'text-size': ['interpolate', ['linear'], ['zoom'], 5, 1.5, 12, 12],
          'text-allow-overlap': true,
          'text-offset': [0, 0.15],
          visibility,
        }}
        paint={{
          'text-color': '#fff',
        }}
      />
    </Source>
  );
}

import {
  FETCH_PERSON_HOURLY_ACTIVITY,
  FETCH_PERSON_HOURLY_ACTIVITY_CANCELLED,
  LOAD_PERSON_HOURLY_ACTIVITY,
  UPDATE_PERSON_HOURLY_ACTIVITY_PARAMETERS,
} from '@/actions';
import {
  CustomTooltip,
  Parameters,
  SelectMultiple,
} from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import { downloadCSV } from '@/utils';
import { personGroups } from '@/utils/config';
import {
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  CardContent,
  Collapse,
  IconButton,
  Paper,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  brown,
  cyan,
  green,
  orange,
  purple,
  red,
  teal,
} from '@mui/material/colors';
import { dequal } from 'dequal';
import { enqueueSnackbar } from 'notistack';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Bar,
  BarChart,
  Brush,
  Tooltip as ChartTooltip,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

const bars = [
  { name: 'On Radio', colour: green[500] },
  { name: 'Driving', colour: purple[500] },
  { name: 'In Base', colour: brown[500] },
  { name: 'In Home Ward', colour: orange[500] },
  { name: 'Responding to Incidents', colour: red[500] },
  { name: 'Attending Objectives', colour: teal[500] },
  { name: 'Double Crewing', colour: cyan[500] },
];

export function HourlyActivity() {
  useDocumentTitle('IR3 | Hourly Activity');
  const dispatch = useDispatch();
  const data = useSelector((state) => state.activity.hourly.data, dequal);
  const parameters = useSelector(
    (state) => state.activity.hourly.parameters,
    dequal,
  );
  const isLoading = useSelector((state) => state.activity.hourly.isLoading);
  const error = useSelector((state) => state.activity.hourly.error);
  const filter = useSelector((state) => state.activity.hourly.filter, dequal);
  const filterValues = useSelector(
    (state) => state.activity.hourly.filterValues,
    dequal,
  );
  const [showFilter, setShowFilter] = useState(false);
  const [hiddenBars, setHiddenBars] = useState([]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error]);

  useEffect(() => {
    dispatch({
      type: LOAD_PERSON_HOURLY_ACTIVITY,
      payload: filter,
    });
  }, [filter, dispatch]);

  function handleFilterToggle() {
    setShowFilter(!showFilter);
  }

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_PERSON_HOURLY_ACTIVITY,
      payload: { query, filter },
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_PERSON_HOURLY_ACTIVITY_CANCELLED,
    });
  }

  const handleFilterFieldChanged = (name) => (value) => {
    const newFilter =
      name in filter
        ? {
            ...filter,
            [name]: value || [],
          }
        : {
            ...filter,
            groups: {
              ...filter.groups,
              [name]: value || [],
            },
          };

    dispatch({
      type: LOAD_PERSON_HOURLY_ACTIVITY,
      payload: newFilter,
    });
  };

  const handleLegendClick = (selectedBar) => () => {
    const index = hiddenBars.indexOf(selectedBar);

    if (index === -1) {
      setHiddenBars(hiddenBars.concat(selectedBar));
    } else {
      setHiddenBars(
        hiddenBars.slice(0, index).concat(hiddenBars.slice(index + 1)),
      );
    }
  };

  function handleParametersChange(parameters) {
    dispatch({
      type: UPDATE_PERSON_HOURLY_ACTIVITY_PARAMETERS,
      payload: parameters,
    });
  }

  async function handleDownloadClick() {
    const filename = 'Person Hourly Activity.csv';

    downloadCSV(data, filename);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 48px)',
        overflow: 'hidden',
        backgroundColor: 'background.default',
      }}
    >
      <Parameters
        collection="personDailySummaries"
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        value={parameters}
        onChange={handleParametersChange}
        sx={{ mt: 1, width: 264 }}
        pointEvent
        dateOnly
        person
      />
      <Box
        sx={{
          width: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            Average Hourly Activity by Hour
          </Typography>
          <Tooltip title={showFilter ? 'Hide filter' : 'Show filter'}>
            <IconButton onClick={handleFilterToggle}>
              <FilterListIcon color={showFilter ? 'primary' : 'inherit'} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download data">
            <Box component="span">
              <IconButton
                disabled={data.length === 0}
                onClick={handleDownloadClick}
              >
                <GetAppIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </Toolbar>
        <Collapse in={showFilter} timeout="auto">
          <Stack spacing={1} sx={{ flex: 1, p: 1 }}>
            <SelectMultiple
              label="Name"
              placeholder="Select..."
              value={filter.name}
              labelValue
              onChange={handleFilterFieldChanged('name')}
              suggestions={filterValues.name.map((value) => ({
                label: value,
                value,
              }))}
            />
            <SelectMultiple
              label="Role"
              placeholder="Select..."
              value={filter.role}
              labelValue
              onChange={handleFilterFieldChanged('role')}
              suggestions={filterValues.role.map((value) => ({
                label: value,
                value,
              }))}
            />
            {Object.entries(personGroups).map(([key, { label, values }]) => {
              return (
                <SelectMultiple
                  key={key}
                  label={label}
                  placeholder="Select..."
                  value={filter.groups[key] || []}
                  labelValue
                  onChange={handleFilterFieldChanged(key)}
                  suggestions={values}
                />
              );
            })}
          </Stack>
        </Collapse>
        {data.length > 0 && (
          <Paper
            sx={{
              m: [0, 1, 1],
              minWidth: 240,
              fontSize: 12,
            }}
          >
            <CardContent sx={{ p: 0, pt: 4 }}>
              <Box
                sx={{
                  pl: 8,
                  pr: 2,
                  pb: 1,
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                {bars.map((bar) => (
                  <Box
                    key={bar.name}
                    sx={{
                      p: 0.5,
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={handleLegendClick(bar.name)}
                  >
                    <Avatar
                      sx={{
                        width: 12,
                        height: 12,
                        mr: 0.5,
                        bgcolor: !hiddenBars.includes(bar.name) && bar.colour,
                      }}
                    >
                      <Fragment />
                    </Avatar>
                    <Typography variant="caption">{bar.name}</Typography>
                  </Box>
                ))}
              </Box>
              <ResponsiveContainer width="99%" height={600}>
                <BarChart
                  data={data}
                  margin={{ top: 0, right: 16, left: 0, bottom: 32 }}
                  barGap={0}
                  barCategoryGap={4}
                >
                  <XAxis dataKey="Hour">
                    <Label value="Hour" offset={36} position="bottom" />
                  </XAxis>
                  <YAxis>
                    <Label
                      value="Minutes"
                      angle={-90}
                      position="left"
                      offset={-24}
                    />
                  </YAxis>
                  <ChartTooltip
                    cursor={data.length > 0}
                    content={<CustomTooltip unit="minutes" />}
                  />
                  {data.length > 0 && <Brush dataKey="Hour" height={24} />}
                  {bars.map((bar) => (
                    <Bar
                      key={bar.name}
                      dataKey={bar.name}
                      fill={bar.colour}
                      hide={hiddenBars.includes(bar.name)}
                    />
                  ))}
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Paper>
        )}
      </Box>
    </Box>
  );
}

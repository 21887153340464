import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useAssociatedOfficers(occurrenceCode) {
  return useQuery({
    queryKey: ['associatedOfficers', occurrenceCode],
    queryFn: () => {
      return briefingApi
        .get(`niche/occurrences/${occurrenceCode}?entity=officers`, {
          timeout: false,
        })
        .json();
    },
    enabled: !!occurrenceCode,
    staleTime: 30 * (60 * 1000),
    cacheTime: 35 * (60 * 1000),
  });
}

import { DateTimePicker } from '@/components/controls';
import { AddCircle as AddIcon } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';

const operators = {
  $eq: 'Equals',
  $ne: 'Does not equal',
  $gt: 'Greater than',
  $lt: 'Less than',
};

const typeOperators = {
  default: Object.entries(operators),
  time: Object.entries(operators).slice(2),
  text: Object.entries(operators).slice(0, 2),
  number: Object.entries(operators),
};

function getOperatorCharacter(operator) {
  switch (operator) {
    case '$eq': {
      return '=';
    }
    case '$ne': {
      return '≠';
    }
    case '$gt': {
      return '>';
    }
    case '$lt': {
      return '<';
    }
    default: {
      return '';
    }
  }
}

export function ParameterField({ input, label, values }) {
  const [operator, setOperator] = useState('');
  const [value, setValue] = useState('');
  const [helperText, setHelperText] = useState('');

  function handleOperatorChange(event) {
    setOperator(event.target.value);
  }

  function handleValueChange(event) {
    setValue(event.target.value);
    setHelperText('');
  }

  function handleAddClick() {
    input.onChange([...(input.value || []), { operator, value }]);
    setOperator('');
    setValue('');
    setHelperText('');
  }

  function renderValueField() {
    switch (input.type) {
      case 'time':
        return (
          <DateTimePicker
            value={value}
            onChange={handleValueChange}
            ampm={false}
            label="Value"
          />
        );
      case 'text':
      case 'number':
        return values ? (
          <TextField
            select
            sx={{ width: 200, ml: 1, mb: 1 }}
            label="Value"
            value={value}
            onChange={handleValueChange}
          >
            {values.map((value, index) => (
              <MenuItem
                key={index}
                value={Array.isArray(value) ? value[0] : value}
              >
                {Array.isArray(value) ? value[1] : value}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <TextField
            label="Value"
            type={input.type}
            value={value}
            onChange={handleValueChange}
            sx={{ width: 200, ml: 1, mb: 1 }}
            helperText={helperText}
            error={helperText !== ''}
          />
        );
      default:
        return '';
    }
  }

  return (
    <Box>
      <Typography variant="caption" sx={{ ml: 1, mb: 1 }}>
        {label}
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {(input.value || []).map((filter, index) => (
          <Chip
            key={index}
            sx={{ ml: 1, mb: 1 }}
            avatar={
              <Avatar style={{ fontSize: 20, fontWeight: 500 }}>
                {getOperatorCharacter(filter.operator)}
              </Avatar>
            }
            label={
              input.type === 'time'
                ? format(new Date(filter.value), 'dd/MM/yyyy HH:mm')
                : filter.value
            }
            onDelete={() =>
              input.onChange(
                input.value
                  .slice(0, index)
                  .concat(input.value.slice(index + 1)),
              )
            }
          />
        ))}
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <TextField
          select
          sx={{ width: 200, ml: 1, mb: 1 }}
          label="Operator"
          value={operator}
          onChange={handleOperatorChange}
        >
          {typeOperators[input.type].map((operator, index) => (
            <MenuItem key={index} value={operator[0]}>
              {operator[1]}
            </MenuItem>
          ))}
        </TextField>
        {renderValueField()}
        <IconButton
          sx={{ mt: 1 }}
          aria-label="Add"
          disabled={!operator || !value}
          onClick={handleAddClick}
          size="large"
        >
          <AddIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

import { shortHumanizer } from '@/utils';

export const pipelineFn = (orderBy, order, page, rowsPerPage, query) => [
  { $match: query },
  {
    $project: {
      identifier: true,
      startTime: true,
      endTime: true,
      durationSeconds: true,
      people: true,
    },
  },
  { $sort: { [orderBy]: order === 'desc' ? -1 : 1 } },
  { $skip: page * rowsPerPage },
  { $limit: rowsPerPage },
];

export const downloadPipelineFn = (query) => [
  { $match: query },
  {
    $project: {
      identifier: true,
      startTime: true,
      endTime: true,
      durationSeconds: { $divide: ['$durationSeconds', 86400] },
      people: true,
    },
  },
];

export const headers = [
  {
    label: 'Primary Staff ID',
    key: 'people.0.code',
    type: 'text',
    filter: true,
  },
  {
    label: 'Primary Forenames',
    key: 'people.0.forenames',
    type: 'text',
    filter: true,
  },
  {
    label: 'Primary Surname',
    key: 'people.0.surname',
    type: 'text',
    filter: true,
  },
  {
    label: 'Primary Collar Number',
    key: 'people.0.collarNumber',
    type: 'text',
    filter: true,
  },
  {
    label: 'Primary Role',
    key: 'people.0.role',
    type: 'text',
    filter: true,
  },
  {
    label: 'Secondary Staff ID',
    key: 'people.1.code',
    type: 'text',
    filter: true,
  },
  {
    label: 'Secondary Forenames',
    key: 'people.1.forenames',
    type: 'text',
    filter: true,
  },
  {
    label: 'Secondary Surname',
    key: 'people.1.surname',
    type: 'text',
    filter: true,
  },
  {
    label: 'Secondary Collar Number',
    key: 'people.1.collarNumber',
    type: 'text',
    filter: true,
  },
  {
    label: 'Secondary Role',
    key: 'people.1.role',
    type: 'text',
    filter: true,
  },
  { label: 'Start Time', key: 'startTime', type: 'date', filter: false },
  { label: 'End Time', key: 'endTime', type: 'date', filter: false },
  {
    label: 'Duration',
    key: 'durationSeconds',
    format: (value) => shortHumanizer(value * 1000),
    type: 'text',
    align: 'right',
    filter: false,
  },
];

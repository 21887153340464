import { shortHumanizer } from '@/utils';

export const pipelineFn = (orderBy, order, page, rowsPerPage, query) => [
  { $match: query },
  {
    $project: {
      identifier: true,
      startTime: true,
      endTime: true,
      durationSeconds: true,
      point: true,
      vehicle: true,
      locations: {
        $ifNull: ['$locations', [{ name: 'Elsewhere', type: 'None' }]],
      },
    },
  },
  { $sort: { [orderBy]: order === 'desc' ? -1 : 1 } },
  { $skip: page * rowsPerPage },
  { $limit: rowsPerPage },
];

export const downloadPipelineFn = (query) => [
  { $match: query },
  {
    $project: {
      identifier: true,
      startTime: true,
      endTime: true,
      durationSeconds: { $divide: ['$durationSeconds', 86400] },
      vehicle: true,
      locations: {
        $ifNull: ['$locations', [{ name: 'Elsewhere', type: 'None' }]],
      },
    },
  },
];

export const headers = [
  {
    label: 'Registration',
    key: 'vehicle.registrationNumber',
    type: 'text',
    filter: true,
  },
  {
    label: 'Fleet Number',
    key: 'vehicle.fleetNumber',
    type: 'text',
    filter: true,
  },
  {
    label: 'Role',
    key: 'vehicle.role',
    type: 'text',
    filter: true,
  },
  {
    label: 'Division',
    key: 'vehicle.groups.division',
    type: 'text',
    filter: true,
  },
  { label: 'Type', key: 'vehicle.type', type: 'text', filter: true },
  {
    label: 'Start Time',
    key: 'startTime',
    type: 'date',
    filter: false,
  },
  {
    label: 'End Time',
    key: 'endTime',
    type: 'date',
    filter: false,
  },
  {
    label: 'Duration',
    key: 'durationSeconds',
    format: (value) => shortHumanizer(value * 1000),
    type: 'text',
    filter: false,
    align: 'right',
  },
  {
    label: 'Home Location',
    key: 'vehicle.homeStation',
    type: 'text',
    filter: true,
  },
  {
    label: 'Location Name',
    key: 'locations.0.name',
    type: 'text',
    filter: true,
  },
  {
    label: 'Location Type',
    key: 'locations.0.type',
    type: 'text',
    filter: true,
  },
];

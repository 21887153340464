import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useCustodyRecords() {
  return useQuery({
    queryKey: ['custodyRecords'],
    queryFn: () => {
      return briefingApi.get('niche/custody/all', { timeout: false }).json();
    },
    staleTime: 55 * (60 * 1000),
    cacheTime: 60 * (60 * 1000),
  });
}

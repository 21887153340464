import { dioStates } from '@/utils/config';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import { format } from 'date-fns';
import _ from 'lodash';
import { DynamicTableCell } from './DynamicTableCell';
import { OnOffIcon, inputsStringToBinary } from './utilities';

export function DioTable({
  polls,
  pollsToShow, // how many to show sorted by date
  includeNonInputChanges, // don't hide polls with a different diagnostic code to 7
  useInputsBinary, // use the binary in deviceProperties instead of object property name
  excludedInputs = [], // don't label these inputs, use (1) etc. instead
  showTime = true,
}) {
  const isMedium = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const dioLabels = () => {
    return (
      <TableRow>
        {showTime && <DynamicTableCell>Time</DynamicTableCell>}
        <DynamicTableCell>Ignition</DynamicTableCell>
        {Object.keys(dioStates)
          .filter((k) => !(isXSmall && k.startsWith('unused')))
          .map((k, index) => (
            <DynamicTableCell key={k} style={{ width: '12%' }}>
              {excludedInputs.includes(k) ? `(${index + 1})` : dioStates[k]}
            </DynamicTableCell>
          ))}
        <DynamicTableCell>Driver</DynamicTableCell>
      </TableRow>
    );
  };

  const rawInputOn = (inputsBin, i) => {
    return inputsBin[7 - i] === '1';
  };

  const dioValuesFromConfig = (poll) => {
    const inputsBin = inputsStringToBinary(poll?.deviceProperties?.inputs || 0);

    return Object.keys(dioStates)
      .filter((k) => !(isXSmall && k.startsWith('unused')))
      .map((k, i) => {
        const inputBitOn = rawInputOn(inputsBin, i);

        return (
          <DynamicTableCell key={k} style={{ width: '12%' }}>
            <OnOffIcon
              style={{ margin: '0 auto' }}
              on={
                k.startsWith('unused') || useInputsBinary ? inputBitOn : poll[k]
              }
            />
          </DynamicTableCell>
        );
      });
  };

  const dioRows = (polls) => {
    const yearFormat = isMedium ? 'dd/MM/yy ' : 'dd/MM/yyyy, ';

    return _.filter(
      polls,
      (p) =>
        includeNonInputChanges ||
        p.diagnosticCode === '7' || // input change
        p.diagnosticCode === '3' || // ignition off to on
        p.diagnosticCode === '4' || // ignition on to off
        p.driver,
    )
      .slice(0, pollsToShow || polls.length)
      .map((poll) => {
        const time = new Date(poll.time);
        return (
          <TableRow key={poll.identifier}>
            {showTime && (
              <DynamicTableCell>
                {format(time, `${yearFormat}HH:mm:ss`)}
              </DynamicTableCell>
            )}
            <DynamicTableCell>
              <OnOffIcon style={{ margin: '0 auto' }} on={poll.ignitionOn} />
            </DynamicTableCell>
            {dioValuesFromConfig(poll)}
            <DynamicTableCell>
              {poll.driver ? poll.driver.identificationReference : ''}
            </DynamicTableCell>
          </TableRow>
        );
      });
  };

  return (
    <Table>
      <TableHead>{dioLabels()}</TableHead>
      <TableBody>{dioRows(polls)}</TableBody>
    </Table>
  );
}

import { RetrospectiveTypeIcon, SearchBox } from '@/components/controls';
import { SortField } from '@/components/fields';
import { useDebounce, useDocumentTitle } from '@/hooks';
import {
  downloadCSV,
  getFilenameForDownload,
  round,
  shortHumanizer,
  shortPersonHeaders,
  startCase,
} from '@/utils';
import {
  GetApp as GetAppIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Box,
  Collapse,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';

import { useDallasKeys } from '@/utils/config';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Link, useParams } from 'react-router-dom';
import { SourceFilters } from './SourceFilters';
import {
  getClientFilters,
  getPrimaryText,
  getSecondaryText,
  orderAndFilterFeatures,
} from './constants';

const vehicleHeaders = [
  { label: 'Registration', key: 'registrationNumber' },
  { label: 'Fleet Number', key: 'fleetNumber' },
  { label: 'Role', key: 'role' },
];

const driverHeaders = [
  { label: 'Driver Name', key: 'name' },
  { label: 'Collar Number', key: 'collarNumber' },
  { label: 'Driver Role', key: 'personRole' },
  { label: useDallasKeys ? 'Dallas Key' : 'RFID Card', key: 'rfid' },
];

const locationHeaders = [
  { label: 'Location Name', key: 'locationName' },
  { label: 'Location Type', key: 'locationType' },
];

const startEndHeaders = [
  { label: 'Start Time', key: 'startTime' },
  { label: 'End Time', key: 'endTime' },
];

const timeHeaders = [
  ...startEndHeaders,
  { label: 'Duration (minutes)', key: 'durationMinutes' },
];

const incidentHeaders = [
  // { label: 'Number', key: 'number' },
  { label: 'Date', key: 'date' },
  { label: 'Number', key: 'reference' },
  { label: 'Description', key: 'description' },
  { label: 'Type', key: 'type' },
  { label: 'Category', key: 'category' },
  { label: 'Response Category', key: 'responseCategory' },
  { label: 'Grade', key: 'grade' },
  { label: 'Closing Codes', key: 'closingCodes' },
  { label: 'Opened Time', key: 'openedTime' },
];

const distanceHeader = { label: 'Distance (miles)', key: 'distanceMiles' };
const maxSpeedHeader = {
  label: 'Max Speed (mph)',
  key: 'maxSpeedMilesPerHour',
};
const timeHeader = { label: 'Time', key: 'time' };

const areaHeaders = [
  { label: 'Location Name', key: 'locationName' },
  { label: 'Measure', key: 'measure' },
  { label: 'Count', key: 'count' },
  { label: 'Percentile', key: 'quantile' },
];

const clusterHeaders = [
  { label: 'Location', key: 'id' },
  { label: 'Count', key: 'count' },
];

const imeiHeader = { label: 'IMEI', key: 'imei' };

const positionHeader = [
  { label: 'Longitude', key: 'longitude' },
  { label: 'Latitude', key: 'latitude' },
];

const maxForceHeaders = [
  { label: 'Maximum Horizontal Force (g)', key: 'maximumHorizontal' },
  { label: 'Maximum Vertical Force (g)', key: 'maximumVertical' },
  { label: 'Maximum Lateral Force (g)', key: 'maximumLateral' },
];

const crimeHeaders = [
  { label: 'Occurrence Number', key: 'id' },
  { label: 'Type', key: 'Occurrence__OccurrenceType' },
  { label: 'Classification', key: 'Occurrence__ClassificationG' },
  { label: 'Dispatch Type', key: 'Occurrence__DispatchOccType' },
  { label: 'End Time', key: 'Occurrence__EndTimeTZV2G' },
  { label: 'Start Time', key: 'Occurrence__StartTimeTZV2G' },
  { label: 'Modified Time', key: 'Occurrence__ModTime' },
  { label: 'Incident Grade', key: 'Occurrence__UCRClearanceStatus' },
  { label: 'Occurrence Address', key: 'Occurrence__GOccIvPA_cache' },
  { label: 'Status', key: 'Occurrence__UCRClearanceStatusG' },
  { label: 'Address Types', key: 'GOccIvPA__ClassificationG' },
  { label: 'Ward', key: 'Occurrence__ESAreaLevel1' },
  { label: 'Beat', key: 'Occurrence__ESAreaLevel2' },
  { label: 'Sector', key: 'Occurrence__ESAreaLevel3' },
  { label: 'Section', key: 'Occurrence__ESAreaLevel4' },
  { label: 'BCU', key: 'Occurrence__ESAreaLevel5' },
  { label: 'Force', key: 'Occurrence__ESAreaLevel6' },
  { label: 'Local Authority', key: 'Occurrence__ESAreaLevel7' },
  { label: 'Summary', key: 'Occurrence__Summary' },
];

const stopCheckHeaders = [
  { label: 'Occurrence Number', key: 'SCOccurrence__OccurrenceFileNoG' },
  { label: 'Type', key: 'SCOccurrence__Type1G' },
  { label: 'Classification', key: 'SCOccurrence__ClassificationG' },
  { label: 'End Time', key: 'SCOccurrence__EndTimeTZV2G' },
  { label: 'Start Time', key: 'SCOccurrence__StartTimeTZV2G' },
  { label: 'Created Time', key: 'SCOccurrence__CreTime' },
  { label: 'Address', key: 'PhysicalAddress__LabelAddressOnly' },
  { label: 'Incident Grade', key: 'SCOccurrence__UCRClearanceStatus' },
  { label: 'Status', key: 'SCOccurrence__UCRClearanceStatusG' },
  { label: 'Summary', key: 'SCOccurrence__Summary' },
];

const ssiHeader = { label: 'SSI', key: 'ssi' };

const headers = {
  speedInfractions: [
    ...vehicleHeaders,
    ...driverHeaders,
    ...timeHeaders,
    distanceHeader,
    maxSpeedHeader,
  ],
  vehicleTrips: [
    ...vehicleHeaders,
    ...driverHeaders,
    ...timeHeaders,
    distanceHeader,
    maxSpeedHeader,
  ],
  vehicleStops: [...vehicleHeaders, ...timeHeaders],
  vehicleVisits: [
    ...vehicleHeaders,
    ...driverHeaders,
    ...locationHeaders,
    ...timeHeaders,
    distanceHeader,
  ],
  vehicleCustomVisits: [
    ...vehicleHeaders,
    imeiHeader,
    ...driverHeaders,
    ...locationHeaders,
    ...timeHeaders,
    distanceHeader,
    maxSpeedHeader,
  ],
  vehicleIdles: [...vehicleHeaders, ...driverHeaders, ...timeHeaders],
  vehiclePolls: [timeHeader, ...vehicleHeaders, imeiHeader, ...positionHeader],
  accelerometerAlerts: [
    timeHeader,
    ...vehicleHeaders,
    imeiHeader,
    ...positionHeader,
  ],
  accelerometerEvents: [
    ...startEndHeaders,
    ...vehicleHeaders,
    imeiHeader,
    ...maxForceHeaders,
  ],
  incidents: incidentHeaders,
  personTrails: [...shortPersonHeaders, ...timeHeaders],
  personVisits: [...shortPersonHeaders, ...locationHeaders, ...timeHeaders],
  personCustomVisits: [
    ...shortPersonHeaders,
    ssiHeader,
    ...locationHeaders,
    ...timeHeaders,
  ],
  personPolls: [timeHeader, ssiHeader, ...positionHeader],
  areas: areaHeaders,
  locations: [...locationHeaders],
  clusters: [...clusterHeaders],
  crimes: [...crimeHeaders],
  stopChecks: [...stopCheckHeaders],
};

export function ItemList({
  layer: {
    featureCollection: { features },
    colors,
    source,
    label,
    searchText,
    clientFilters,
    sort,
    type,
    primaryItemKey,
    secondaryItemKey,
    startTime,
    endTime,
  },
  hoveredItemIndex,
  onHover,
  onSearchTextChange,
  clearValue,
}) {
  useDocumentTitle(`IR3 | ${label ? `Layer | ${label}` : 'Layer'}`);
  const [showSettings, setShowSettings] = useState(false);
  const [searchValue, setSearchValue] = useState(searchText || '');
  const { id, layerIndex } = useParams();
  const theme = useTheme();
  const parentRef = useRef();
  const filteredFeatures = useMemo(() => {
    return orderAndFilterFeatures({
      clientFilters,
      searchText,
      sort,
      featureCollection: { features },
    });
  }, [searchText, clientFilters, features, sort]);

  const rowVirtualizer = useVirtualizer({
    count: filteredFeatures.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 56,
    overscan: 10,
  });

  const filters = getClientFilters(features);
  const debouncedSearchValue = useDebounce(searchValue, 1000);

  // if the features are aggregates, the normal source filters aren't appropriate
  const isAggregated = features[0]?.properties?.count !== undefined;
  if (isAggregated) {
    source = 'aggregates';
  }

  useEffect(() => {
    // if (debouncedSearchValue !== undefined) {
    onSearchTextChange(debouncedSearchValue);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  function handleFilterToggle() {
    setShowSettings(!showSettings);
  }

  function handleFilterChange(event) {
    setSearchValue(event.target.value);
  }

  function getVehicleProperties({
    identificationNumber,
    fleetNumber,
    registrationNumber,
    type,
    role,
    telematicsBoxImei,
    unassociated,
  }) {
    if (unassociated) {
      return {
        identificationNumber: telematicsBoxImei,
        fleetNumber: 'Unassociated IMEI',
        registrationNumber: '',
        type: '',
        role: '',
        imei: telematicsBoxImei,
      };
    } else {
      return {
        identificationNumber,
        fleetNumber,
        registrationNumber,
        type,
        role,
        imei: telematicsBoxImei,
      };
    }
  }

  function getDriverProperties(driver) {
    return {
      ...getPersonProperties(driver),
      rfid: driver.identificationReference,
    };
  }

  function getPersonProperties({
    code: personCode,
    forenames,
    surname,
    collarNumber,
    role: personRole,
    radioSsi,
    unassociated,
  }) {
    if (unassociated) {
      return {
        personCode,
        staffId: radioSsi,
        name: `Unassociated SSI`,
        collarNumber: '',
        personRole,
        ssi: radioSsi,
      };
    } else {
      return {
        personCode,
        staffId: personCode,
        name: `${forenames || ''} ${surname || ''}`,
        collarNumber,
        personRole,
        ssi: radioSsi,
      };
    }
  }

  function getLocationProperties({ type, name }) {
    return { locationType: type, locationName: name };
  }

  function getTimeProperties({ startTime, endTime, durationSeconds }) {
    return {
      startTime: format(new Date(startTime), 'yyyy-MM-dd HH:mm:ss'),
      endTime: format(new Date(endTime), 'yyyy-MM-dd HH:mm:ss'),
      durationMinutes: round(durationSeconds / 60 || 0.0, 2),
    };
  }

  function flattenForCsv({ properties, geometry }) {
    switch (properties.source) {
      case 'speedInfractions':
        return {
          ...getVehicleProperties(properties.vehicle || {}),
          ...getDriverProperties(properties.driver || {}),
          ...getTimeProperties(properties),
          distanceMiles: round(
            (properties.distanceKilometres || 0.0) * 0.62137119,
            2,
          ),
          maxSpeedMilesPerHour: round(
            (properties.maxSpeedKilometresPerHour || 0.0) * 0.62137119,
            2,
          ),
        };
      case 'vehicleTrips':
        return {
          ...getVehicleProperties(properties.vehicle || {}),
          ...getDriverProperties(properties.driver || {}),
          ...getTimeProperties(properties),
          distanceMiles: round(
            (properties.distanceKilometres || 0.0) * 0.62137119,
            2,
          ),
          maxSpeedMilesPerHour: round(
            (properties.maxSpeedKilometresPerHour || 0.0) * 0.62137119,
            2,
          ),
        };
      case 'vehicleStops':
        return {
          ...getVehicleProperties(properties.vehicle || {}),
          ...getTimeProperties(properties),
        };
      case 'vehicleCustomVisits':
      case 'vehicleVisits':
        return {
          ...getDriverProperties(properties.driver ?? properties.person ?? {}),
          ...getVehicleProperties(properties.vehicle || {}),
          ...getLocationProperties(properties.location || {}),
          ...getTimeProperties(properties),
          distanceMiles: round(
            (properties.distanceKilometres || 0.0) * 0.62137119,
            2,
          ),
          maxSpeedMilesPerHour: round(
            (properties.maxSpeedKilometresPerHour || 0.0) * 0.62137119,
            2,
          ),
        };
      case 'vehicleIdles':
        return {
          ...getVehicleProperties(properties.vehicle || {}),
          ...getDriverProperties(properties.driver || {}),
          ...getTimeProperties(properties),
        };
      case 'accelerometerEvents':
        return {
          ...getVehicleProperties(properties.vehicle || {}),
          ...getTimeProperties(properties),
          imei: properties.imei || '',
          maximumHorizontal: properties.maximumForces?.horizontal,
          maximumVertical: properties.maximumForces?.vertical,
          maximumLateral: properties.maximumForces?.lateral,
        };
      case 'accelerometerAlerts':
      case 'vehiclePolls':
        return {
          ...getVehicleProperties(properties.vehicle || {}),
          imei: properties.imei || '',
          longitude: geometry.coordinates[0] || 0,
          latitude: geometry.coordinates[1] || 0,
          time: format(new Date(properties.time), 'yyyy-MM-dd HH:mm:ss'),
        };
      case 'incidents':
        return {
          // number: properties.id,
          reference: properties.reference,
          date: properties.date,
          description: properties.description,
          type: properties.type?.name || '',
          category: properties.category?.name || '',
          responseCategory: properties.responseCategory.name,
          grade: properties.grade,
          closingCodes: (properties.closingCodes || [])
            .map((item) => item.name)
            .join(', '),
          openedTime: format(
            new Date(properties.openedTime),
            'yyyy-MM-dd HH:mm:ss',
          ),
        };
      case 'personTrails':
        return {
          ...getPersonProperties(properties.person || {}),
          ...getTimeProperties(properties),
        };
      case 'personCustomVisits':
      case 'personVisits':
        return {
          ...getPersonProperties(properties.person || {}),
          ...getLocationProperties(properties.location || {}),
          ...getTimeProperties(properties),
        };
      case 'personPolls':
        return {
          ssi: properties.ssi,
          longitude: geometry.coordinates[0] || 0,
          latitude: geometry.coordinates[1] || 0,
          time: format(new Date(properties.time), 'yyyy-MM-dd HH:mm:ss'),
        };
      case 'areas':
      case 'groups':
        return {
          ...getLocationProperties(properties),
          measure: startCase(properties.measure),
          count: properties.measure.includes('Time')
            ? shortHumanizer(properties.count)
            : properties.count,
          quantile: round(properties.quantile * 100 || 0.0, 0),
        };
      case 'locations':
        return getLocationProperties(properties);
      case 'clusters':
        return properties;
      case 'crimes':
        return properties;
      case 'stopChecks':
        return properties;
      default:
        return {};
    }
  }

  function handleDownload() {
    const source = filteredFeatures[0].properties.source;
    const filename = getFilenameForDownload(
      label || startCase(source),
      'csv',
      startTime,
      endTime,
    );
    const data = filteredFeatures.map(flattenForCsv);

    downloadCSV(data, filename, headers[source]);
  }

  function percentileString(quantile) {
    const nth = function (d) {
      if (d > 3 && d < 21) {
        return 'th';
      }

      switch (d % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    };

    const percentile = round(quantile * 100, 0);

    return `${percentile}${nth(percentile)}`;
  }

  const QuantitativeBadge = ({ quantile, count, children }) =>
    !isNaN(quantile) || !isNaN(count) ? (
      <Tooltip title={quantile ? 'Percentile' : 'Count'}>
        <Badge
          color="primary"
          badgeContent={quantile ? `${percentileString(quantile)}` : count}
          max={10000}
        >
          {children}
        </Badge>
      </Tooltip>
    ) : (
      children
    );

  return (
    <Fragment>
      <Box>
        <Stack
          direction="row"
          sx={{ p: 1, pr: 0.5 }}
          spacing={0.5}
          alignItems="center"
        >
          <SearchBox
            value={searchValue}
            onChange={handleFilterChange}
            sx={{ flexGrow: 1 }}
            count={`${filteredFeatures.length}/${features.length}`}
          />
          <Tooltip title={showSettings ? 'Hide settings' : 'Show settings'}>
            <IconButton onClick={handleFilterToggle} size="small">
              <SettingsIcon
                fontSize="inherit"
                color={showSettings ? 'primary' : 'inherit'}
              />
            </IconButton>
          </Tooltip>

          {type !== 'file' && (
            <Tooltip title="Download">
              <IconButton onClick={handleDownload} size="small">
                <GetAppIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
        <Collapse in={showSettings} timeout="auto" unmountOnExit>
          <Stack sx={{ p: 1 }} spacing={1}>
            <SourceFilters
              id="clientFilters"
              type={source}
              name={`layers[${layerIndex}].clientFilters`}
              filters={filters}
            />
            {type !== 'file' && (
              <Fragment>
                <Divider style={{ marginTop: 16, marginBottom: 8 }} />
                <FieldArray
                  id="sort"
                  label="Item Sort"
                  name={`layers[${layerIndex}].sort`}
                  type={source}
                  filters={filters}
                  component={SortField}
                  clearValue={clearValue}
                />
              </Fragment>
            )}
          </Stack>
          <Divider />
        </Collapse>
      </Box>
      {filteredFeatures.length > 0 && (
        <Box ref={parentRef} sx={{ overflow: 'auto', height: 1 }}>
          <Box
            sx={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: '100%',
              position: 'relative',
            }}
          >
            {rowVirtualizer.getVirtualItems().map(({ index, size, start }) => {
              const feature = filteredFeatures[index];

              const background =
                colors.length > 1
                  ? `linear-gradient(${colors.join()})`
                  : colors[0] || theme.palette.grey[500];
              const color = theme.palette.getContrastText(
                colors[Math.floor(colors.length / 2)] ||
                  theme.palette.grey[500],
              );

              return (
                <ListItemButton
                  dense
                  key={index}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: `${size}px`,
                    transform: `translateY(${start}px)`,
                    bgcolor:
                      parseInt(layerIndex) === hoveredItemIndex.layerIndex &&
                      index === hoveredItemIndex.itemIndex
                        ? 'action.hover'
                        : undefined,
                  }}
                  component={Link}
                  to={`/retrospective/${
                    id || 'untitled'
                  }/${layerIndex}/${index}`}
                  onMouseEnter={() => {
                    // because ie11 is a pos and this event fires every time the mouse moves inside an element
                    if (hoveredItemIndex.itemIndex !== index) {
                      onHover({
                        layerIndex: parseInt(layerIndex),
                        itemIndex: index,
                      });
                    }
                  }}
                  onMouseLeave={() => onHover({})}
                >
                  <ListItemAvatar>
                    <QuantitativeBadge
                      quantile={feature.properties.quantile}
                      count={feature.properties.count}
                    >
                      <Avatar
                        style={{
                          background,
                          color,
                        }}
                      >
                        <RetrospectiveTypeIcon
                          type={feature.properties.source}
                        />
                      </Avatar>
                    </QuantitativeBadge>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      primaryItemKey
                        ? feature.properties.fileProperties[primaryItemKey]
                        : getPrimaryText(feature)
                    }
                    secondary={
                      secondaryItemKey
                        ? feature.properties.fileProperties[secondaryItemKey]
                        : getSecondaryText(feature)
                    }
                  />
                </ListItemButton>
              );
            })}
          </Box>
        </Box>
      )}
    </Fragment>
  );
}

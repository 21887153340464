import {
  FETCH_AGGREGATED_DRIVING_SCORES,
  FETCH_AGGREGATED_DRIVING_SCORES_CANCELLED,
  FETCH_AGGREGATED_DRIVING_SCORES_FAILURE,
  FETCH_AGGREGATED_DRIVING_SCORES_SUCCESS,
  FETCH_AUDIT_LOG_ENTRIES,
  FETCH_AUDIT_LOG_ENTRIES_CANCELLED,
  FETCH_AUDIT_LOG_ENTRIES_FAILURE,
  FETCH_AUDIT_LOG_ENTRIES_SUCCESS,
  FETCH_DRIVER_TRIPS_BY_DRIVER_CODE,
  FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_CANCELLED,
  FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_FAILURE,
  FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_SUCCESS,
  FETCH_DRIVING_SCORES,
  FETCH_DRIVING_SCORES_CANCELLED,
  FETCH_DRIVING_SCORES_FAILURE,
  FETCH_DRIVING_SCORES_SUCCESS,
  FETCH_PERSON_AVAILABILITY,
  FETCH_PERSON_AVAILABILITY_CANCELLED,
  FETCH_PERSON_AVAILABILITY_FAILURE,
  FETCH_PERSON_AVAILABILITY_SUCCESS,
  FETCH_VEHICLES_IN_LOCATIONS,
  FETCH_VEHICLES_IN_LOCATIONS_CANCELLED,
  FETCH_VEHICLES_IN_LOCATIONS_FAILURE,
  FETCH_VEHICLES_IN_LOCATIONS_SUCCESS,
  FETCH_VEHICLE_AVAILABILITY,
  FETCH_VEHICLE_AVAILABILITY_CANCELLED,
  FETCH_VEHICLE_AVAILABILITY_FAILURE,
  FETCH_VEHICLE_AVAILABILITY_SUCCESS,
  FETCH_VEHICLE_IN_BASE_TIME,
  FETCH_VEHICLE_IN_BASE_TIME_CANCELLED,
  FETCH_VEHICLE_IN_BASE_TIME_FAILURE,
  FETCH_VEHICLE_IN_BASE_TIME_SUCCESS,
  FETCH_VEHICLE_ODOMETERS,
  FETCH_VEHICLE_ODOMETERS_FAILURE,
  FETCH_VEHICLE_ODOMETERS_SUCCESS,
  LOAD_AGGREGATED_DRIVING_SCORES,
  LOAD_AGGREGATED_DRIVING_SCORES_FAILURE,
  LOAD_AGGREGATED_DRIVING_SCORES_SUCCESS,
  LOAD_VEHICLES_IN_LOCATIONS,
  LOAD_VEHICLES_IN_LOCATIONS_FAILURE,
  LOAD_VEHICLES_IN_LOCATIONS_SUCCESS,
  LOAD_VEHICLE_IN_BASE_TIME,
  LOAD_VEHICLE_IN_BASE_TIME_FAILURE,
  LOAD_VEHICLE_IN_BASE_TIME_SUCCESS,
} from '@/actions';
import { api, fromAjax } from '@/apis';
import { db, fetchCachedData } from '@/data';
import {
  NormalDistribution,
  encodeParams,
  epochHoursToHistogram,
  getFilterOptionsFromData,
  getHeaders,
  groupsFilter,
  log,
  range,
  round,
} from '@/utils';
import {
  baseType,
  minimumSpeedInfractionSeconds,
  tripClassifications,
} from '@/utils/config';
import {
  addHours,
  differenceInDays,
  differenceInHours,
  differenceInSeconds,
  format,
  getHours,
  getUnixTime,
  startOfDay,
  startOfHour,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import { dequal } from 'dequal';
import _ from 'lodash';
import * as math from 'mathjs';
import { ofType } from 'redux-observable';
import { forkJoin, from, of } from 'rxjs';
import { catchError, map, mergeMap, takeUntil, tap } from 'rxjs/operators';

const exemptTripClassifications = tripClassifications
  .filter(({ exempt }) => exempt)
  .map(({ value }) => value);

const controller = new AbortController();
const { signal } = controller;

async function fetchVehicleAvailabilityRequest(
  startTime,
  endTime,
  filter,
  homeOnly,
  customConfidence,
) {
  // '$match': {
  //   'startTime': {
  //     $gte: '2020-07-01T00:00:00.000Z',// startTime.toISOString(),
  //     $lt: '2020-07-31T00:00:00.000Z'// endTime.toISOString(),
  //   },
  const grouping = 'role';

  // using mongo aggregation we can match the stops we want (by dates)
  // then group by location and role
  const pipeline = [
    {
      $match: {
        startTime: {
          $lt: endTime,
        },
        endTime: {
          $gte: startTime,
        },
        'locations.type': baseType.label,
        ...(homeOnly
          ? {
              $expr: {
                $or: [
                  {
                    $in: [
                      '$vehicle.homeStation',
                      '$locations.tranmanIdentifier',
                    ],
                  },
                  { $in: ['$vehicle.homeStation', '$locations.code'] },
                  { $in: ['$vehicle.homeStation', '$locations.name'] },
                ],
              },
            }
          : {}),
        // $expr: {
        //   $gte: [
        //     '$endTime',
        //     {
        //       $add: ['$startTime', 3600000],
        //     },
        //   ],
        // },
      },
    },
    {
      $project: {
        baseLocation: {
          $arrayElemAt: [
            {
              $filter: {
                input: '$locations',
                as: 'location',
                cond: { $eq: ['$$location.type', baseType.label] },
              },
            },
            0,
          ],
        },
        startTime: true,
        endTime: true,
        vehicle: true,
      },
    },
    {
      $group: {
        //null,
        _id: {
          locationCode: '$baseLocation.code',
          grouping: { $ifNull: [`$vehicle.${grouping}`, ''] },
        },
        // totalHoursStopped: { $sum: 1 },
        hours: {
          $push: {
            $map: {
              input: {
                $range: [
                  {
                    $floor: {
                      $divide: [
                        {
                          $subtract: [
                            '$startTime',
                            { $toDate: new Date('1970-01-01T00:00:00.000Z') },
                          ],
                        },
                        1000,
                      ],
                    },
                  },
                  {
                    $floor: {
                      $divide: [
                        {
                          $subtract: [
                            '$endTime',
                            { $toDate: new Date('1970-01-01T00:00:00.000Z') },
                          ],
                        },
                        1000,
                      ],
                    },
                  },
                  3600,
                ],
              },
              as: 'time',
              in: {
                $floor: {
                  $divide: [
                    {
                      $toDecimal: '$$time',
                    },
                    3600,
                  ],
                },
              },
            },
          },
        },
      },
    },
  ];

  const [locationsResult, vehiclesResult, telematicsResult, stopsResult] =
    await Promise.all([
      api
        .get('locations', {
          searchParams: encodeParams({
            projection: { code: true, name: true, type: true },
          }),
        })
        .json(),
      api
        .get('vehicles', {
          searchParams: encodeParams({
            projection: { telematicsBoxImei: true, role: true, type: true },
          }),
        })
        .json(),
      api
        .get('telematicsBoxes', {
          searchParams: encodeParams({
            query: { events: { $exists: true } },
            projection: {
              imei: true,
              events: true,
              'mostRecentPoll.time': true,
            },
          }),
        })
        .json(),
      api
        .get('stops', {
          searchParams: encodeParams({
            pipeline,
          }),
          signal,
        })
        .json(),
    ]);

  const locationsByCode = _.keyBy(locationsResult, 'code');
  const vehiclesByImei = _.keyBy(vehiclesResult, 'telematicsBoxImei');

  // an epochHour is the number of hours since 1/1/1970
  function dateToEpochHour(date) {
    if (date) {
      return Math.floor(getUnixTime(date) / 3600);
    }

    return null;
  }
  const startEpochHour = dateToEpochHour(startTime);
  const endEpochHour = dateToEpochHour(endTime);

  // STOPs are only created when a vehicle starts again, so there could be vehicles
  // that are currently at the location with no STOP. The most recent stop event of
  // the telematics box has the current location and from the start time of that event
  // we can work out how long it has been at the location (so far)
  const currentStops = telematicsResult
    .filter(
      (t) =>
        t.events &&
        t.events.some(
          (e) =>
            e.eventType === 'STOP' &&
            e.startTime < endTime &&
            e.locations?.length > 0,
        ),
    )
    .map((t) => {
      const stopEvent = t.events.find((e) => e.eventType === 'STOP');
      const startEpochHour = dateToEpochHour(
        // start from the later of when it arrived at location or the start of the query
        stopEvent.startTime > startTime ? stopEvent.startTime : startTime,
      );
      return {
        locationCode: stopEvent.locations[0].code,
        grouping: vehiclesByImei[t.imei]?.[grouping] || '',
        hours: range(startEpochHour, endEpochHour, 1),
      };
    });

  const stops = stopsResult;

  function getStopKey(stop) {
    return stop.locationCode + stop.grouping;
  }

  const currentStopsByKey = _.groupBy(currentStops, getStopKey);

  let statsPerLocationAndGrouping = {};
  stops.forEach(({ _id: { locationCode, grouping }, hours }) => {
    const location = locationsByCode[locationCode];
    const stopKey = getStopKey({ locationCode, grouping });

    // add all the current stops for this location
    if (currentStopsByKey[stopKey]) {
      currentStopsByKey[stopKey].forEach((currentStop) => {
        hours.push(currentStop.hours);
      });
    }

    const [availabilities, histogram] = epochHoursToHistogram(
      startEpochHour,
      endEpochHour,
      hours,
    );
    const instanceArray = Object.values(histogram).map((h) =>
      new Array(h.hours).fill(h.count),
    );
    const std = math.std(instanceArray);
    const mean = math.mean(instanceArray);

    const normalDistribution = new NormalDistribution(mean, std);
    function invp(p) {
      const result = normalDistribution.invCumulativeProbability(1 - p);
      return result > 0 ? result : 0;
    }

    statsPerLocationAndGrouping[stopKey] = {
      stopKey,
      location: location?.name || locationCode,
      // locationType: location?.type || 'Unknown',
      grouping,
      // p95: inv95 * std,
      // p975: 2.5 * std, //inv975 * std,
      // p99: inv99 * std,
      pCustom: invp(customConfidence / 100),
      p95: invp(0.95),
      p975: invp(0.975),
      p99: invp(0.99),
      std,
      mean,
      availabilities,
      histogram,
    };
  });

  const data = Object.values(statsPerLocationAndGrouping);

  // TODOJL!
  const filterOptions = {
    location: _.uniq(data.map((l) => l.location)).sort(),
    // locationType: _.uniq(data.map((l) => l.locationType)).sort(),
    grouping: _.uniq(data.map((l) => l.grouping)).sort(),
  };

  const filteredData = data.filter((record) =>
    Object.keys(filter).every(
      (key) =>
        (filter[key]?.length || 0) === 0 || filter[key].includes(record[key]),
    ),
  );

  const results = {
    filter,
    homeOnly,
    filterOptions,
    // ...getVehicleUtilisationFilterAndGroupByValues(data, filter),
    filteredData,
    data, //: getVehicleDailyUtilisation(filteredData, groupBy),
    startTime,
    endTime,
  };

  log('Read', 'Vehicle Availability', {
    startTime,
    endTime,
  });

  return results;
}

export function fetchVehicleAvailabilityEpic(action$) {
  return action$.pipe(
    ofType(FETCH_VEHICLE_AVAILABILITY),
    mergeMap(
      ({
        payload: { startTime, endTime, filter, homeOnly, customConfidence },
      }) =>
        from(
          fetchVehicleAvailabilityRequest(
            startTime,
            endTime,
            filter,
            homeOnly,
            customConfidence,
          ),
        ).pipe(
          map((payload) => ({
            type: FETCH_VEHICLE_AVAILABILITY_SUCCESS,
            payload,
          })),
          takeUntil(
            action$.pipe(
              ofType(FETCH_VEHICLE_AVAILABILITY_CANCELLED),
              tap(() => controller.abort()),
            ),
          ),
          catchError(({ message: payload }) =>
            of({
              type: FETCH_VEHICLE_AVAILABILITY_FAILURE,
              payload,
            }),
          ),
        ),
    ),
  );
}

async function fetchPeopleAvailabilityRequest(
  startTime,
  endTime,
  filter,
  customConfidence,
) {
  const isoStart = startTime.toISOString();
  const isoEnd = endTime.toISOString();
  const grouping = 'role';

  let pipeline = [
    {
      $match: {
        startTime: {
          $lt: isoEnd,
        },
        endTime: {
          $gte: isoStart,
        },
        'value.category': 'Available', //{ $exists: true }
        $expr: {
          // more than half an hour
          $gt: [{ $subtract: ['$endTime', '$startTime'] }, 30 * 60 * 1000],
        },
      },
    },
    {
      $project: {
        code: true,
        startTime: true,
        endTime: true,
        // category: "$value.category",
      },
    },
    {
      $group: {
        //null,
        _id: {
          code: '$code',
          // category: '$category'
        },
        hours: {
          $push: {
            $map: {
              input: {
                $range: [
                  {
                    $floor: {
                      $divide: [
                        {
                          $subtract: [
                            '$startTime',
                            { $toDate: '1970-01-01T00:00:00.000Z' },
                          ],
                        },
                        1000,
                      ],
                    },
                  },
                  {
                    $floor: {
                      $divide: [
                        {
                          $subtract: [
                            '$endTime',
                            { $toDate: '1970-01-01T00:00:00.000Z' },
                          ],
                        },
                        1000,
                      ],
                    },
                  },
                  3600,
                ],
              },
              as: 'time',
              in: {
                $floor: {
                  $divide: [
                    {
                      $toDecimal: '$$time',
                    },
                    3600,
                  ],
                },
              },
            },
          },
        },
      },
    },
    // { $sort: { _id: 1 } },
  ];

  const [locationsResult, peopleResult, attributionChangesResult] =
    await Promise.all([
      api
        .get('locations', {
          searchParams: encodeParams({
            projection: { code: true, name: true, type: true },
          }),
        })
        .json(),
      api
        .get('people', {
          searchParams: encodeParams({
            projection: { code: true, homeStation: true, [grouping]: true },
          }),
        })
        .json(),
      api
        .get('personAttributions', {
          searchParams: encodeParams({
            pipeline,
          }),
          signal,
        })
        .json(),
    ]);

  const locationsByCode = _.keyBy(locationsResult, 'code');
  const peopleByCode = _.keyBy(peopleResult, 'code');

  // an epochHour is the number of hours since 1/1/1970
  function isoDateToEpochHour(isoDate) {
    if (isoDate) {
      return Math.floor(getUnixTime(new Date(isoDate)) / 3600);
    }

    return null;
  }
  const startEpochHour = isoDateToEpochHour(isoStart);
  const endEpochHour = isoDateToEpochHour(isoEnd);

  function getKey(stop) {
    return stop.homeStation + stop.grouping;
  }

  const attributionHoursByKey = {};
  attributionChangesResult.forEach((a) => {
    const person = peopleByCode[a._id?.code];

    if (person) {
      const key = getKey({
        homeStation: person.homeStation,
        grouping: person[grouping],
      });

      if (!attributionHoursByKey[key]) {
        attributionHoursByKey[key] = {
          key,
          homeStation: person.homeStation,
          grouping: person[grouping],
          hours: [],
        };
      }

      Array.prototype.push.apply(attributionHoursByKey[key].hours, a.hours);
    }
  });

  let statsPerHomeStationAndGrouping = {};
  Object.values(attributionHoursByKey).forEach(
    ({ key, homeStation, grouping, hours }) => {
      const location = locationsByCode[homeStation];

      const [availabilities, histogram] = epochHoursToHistogram(
        startEpochHour,
        endEpochHour,
        hours,
      );
      const instanceArray = Object.values(histogram).map((h) =>
        new Array(h.hours).fill(h.count),
      );
      const std = math.std(instanceArray);
      const mean = math.mean(instanceArray);

      const normalDistribution = new NormalDistribution(mean, std);
      function invp(p) {
        const result = normalDistribution.invCumulativeProbability(1 - p);
        return result > 0 ? result : 0;
      }

      statsPerHomeStationAndGrouping[key] = {
        stopKey: key,
        homeStation: location?.name || homeStation,
        // locationType: location?.type || 'Unknown',
        grouping,
        // p95: inv95 * std,
        // p975: 2.5 * std, //inv975 * std,
        // p99: inv99 * std,
        pCustom: invp(customConfidence / 100),
        p95: invp(0.95),
        p975: invp(0.975),
        p99: invp(0.99),
        std,
        mean,
        availabilities,
        histogram,
      };
    },
  );

  const data = Object.values(statsPerHomeStationAndGrouping);

  // TODOJL!
  const filterOptions = {
    homeStation: _.uniq(data.map((l) => l.homeStation)).sort(),
    // locationType: _.uniq(data.map((l) => l.locationType)).sort(),
    grouping: _.uniq(data.map((l) => l.grouping)).sort(),
  };

  const filteredData = data.filter((record) =>
    Object.keys(filter).every(
      (key) =>
        (filter[key]?.length || 0) === 0 || filter[key].includes(record[key]),
    ),
  );

  const results = {
    filter,
    filterOptions,
    // ...getPeopleUtilisationFilterAndGroupByValues(data, filter),
    filteredData,
    data, //: getPeopleDailyUtilisation(filteredData, groupBy),
    startTime,
    endTime,
  };

  log('Read', 'People Availability', {
    startTime,
    endTime,
  });

  return results;
}

export function fetchPeopleAvailabilityEpic(action$) {
  return action$.pipe(
    ofType(FETCH_PERSON_AVAILABILITY),
    mergeMap(
      ({
        payload: { startTime, endTime, filter, homeOnly, customConfidence },
      }) =>
        from(
          fetchPeopleAvailabilityRequest(
            startTime,
            endTime,
            filter,
            homeOnly,
            customConfidence,
          ),
        ).pipe(
          map((payload) => ({
            type: FETCH_PERSON_AVAILABILITY_SUCCESS,
            payload,
          })),
          takeUntil(
            action$.pipe(
              ofType(FETCH_PERSON_AVAILABILITY_CANCELLED),
              tap(() => controller.abort()),
            ),
          ),
          catchError(({ message: payload }) =>
            of({
              type: FETCH_PERSON_AVAILABILITY_FAILURE,
              payload,
            }),
          ),
        ),
    ),
  );
}

async function fetchAuditLogEntriesRequest(startTime, endTime, userId) {
  const excludeList = [
    'Vehicles',
    'People',
    'Locations',
    'Features',
    'Objectives',
    'Telematics Boxes',
    'Retrospectives',
    'Telematics Box Polls',
  ];

  const response = await api
    .get('audits', {
      searchParams: encodeParams({
        query: {
          time: { $gte: startTime, $lt: endTime },
          user: userId ? userId : undefined,
        },
        projection: {
          user: true,
          dataType: true,
          time: true,
          action: true,
          parameters: true,
        },
      }),
      signal,
    })
    .json();

  const data = response
    .filter((entry) => !excludeList.includes(entry.dataType))
    .map(({ user, ...entry }) => {
      const parameters = entry.parameters || {};

      return {
        ...entry,
        userId: user,
        itemId:
          parameters.id ||
          parameters.identifier ||
          parameters.code ||
          parameters.identificationNumber ||
          null,
        startTime:
          parameters.startTime || parameters.startTime
            ? new Date(parameters.startTime || parameters.startTime)
            : null,
        endTime:
          parameters.endTime || parameters.endTime
            ? new Date(parameters.endTime || parameters.endTime)
            : null,
      };
    });

  log('Read', 'Audit Log Entries', {
    startTime,
    endTime,
    userId,
  });

  return _.orderBy(data, ['time'], ['desc']);
}

export function fetchAuditLogEntriesEpic(action$) {
  return action$.pipe(
    ofType(FETCH_AUDIT_LOG_ENTRIES),
    mergeMap(({ payload: { startTime, endTime, userId } }) =>
      from(fetchAuditLogEntriesRequest(startTime, endTime, userId)).pipe(
        map((payload) => ({
          type: FETCH_AUDIT_LOG_ENTRIES_SUCCESS,
          payload,
        })),
        takeUntil(
          action$.pipe(
            ofType(FETCH_AUDIT_LOG_ENTRIES_CANCELLED),
            tap(() => controller.abort()),
          ),
        ),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_AUDIT_LOG_ENTRIES_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

function vehicleInBaseFilter(record, filter) {
  if (
    filter.registrationNumber.length !== 0 &&
    !filter.registrationNumber.includes(record.registrationNumber)
  ) {
    return false;
  }

  if (
    filter.fleetNumber.length !== 0 &&
    !filter.fleetNumber.includes(record.fleetNumber)
  ) {
    return false;
  }

  if (filter.role.length !== 0 && !filter.role.includes(record.role)) {
    return false;
  }

  if (filter.type.length !== 0 && !filter.type.includes(record.type)) {
    return false;
  }

  if (
    filter.locationName.length !== 0 &&
    !filter.locationName.includes(record.locationName)
  ) {
    return false;
  }

  if (
    filter.locationType.length !== 0 &&
    !filter.locationType.includes(record.locationType)
  ) {
    return false;
  }

  return groupsFilter(record, filter);
}

function getEmptyByHourByBase(locationNames) {
  const hours = Array(24)
    .fill()
    .map((_, index) => index);

  const byHourByBase = {};
  for (let hour of hours) {
    byHourByBase[hour] = { Hour: format(new Date(0, 0, 0, hour, 0), 'HH:mm') };
    for (let locationName of locationNames.sort()) {
      byHourByBase[hour][locationName] = 0;
    }
  }
  return byHourByBase;
}

function getVehicleInBaseFilterValues(data, filter) {
  const { groups: _, ...fields } = filter;
  const result = { groups: {} };
  const groups = Array.from(
    new Set([].concat(...data.map((record) => Object.keys(record.groups)))),
  );

  for (const key in fields) {
    const keyFilter = { ...filter, [key]: [] };
    result[key] = Array.from(
      new Set(
        data
          .filter((record) => vehicleInBaseFilter(record, keyFilter))
          .map((record) => record[key]),
      ),
    )
      .filter((value) => value !== undefined)
      .sort();
  }

  for (const group of groups) {
    const keyFilter = { ...filter, groups: { ...filter.groups, [group]: [] } };
    result.groups[group] = Array.from(
      new Set(
        data
          .filter((record) => vehicleInBaseFilter(record, keyFilter))
          .map((record) => record.groups[group])
          .flatMap((value) => value),
      ),
    )
      .filter((value) => value !== undefined)
      .sort();
  }

  return result;
}

function getVehicleInBaseTime(data) {
  const locationsNames = Array.from(
    new Set(data.map((record) => record.locationName)),
  );
  const dates = data.map((record) => record.hour);
  const maxDate = new Date(Math.max.apply(null, dates));
  const minDate = new Date(Math.min.apply(null, dates));
  const count = differenceInDays(startOfDay(maxDate), startOfDay(minDate)) + 1;

  const byHourByBase = getEmptyByHourByBase(locationsNames);

  for (let record of data) {
    byHourByBase[getHours(new Date(record.hour))][record.locationName] +=
      record.durationSeconds / 3600;
  }

  for (let hour in byHourByBase) {
    for (let locationName in byHourByBase[hour]) {
      if (locationName !== 'Hour') {
        byHourByBase[hour][locationName] = round(
          byHourByBase[hour][locationName] / count,
          2,
        );
      }
    }
  }

  return byHourByBase;
}

async function fetchVehicleInBaseTimeRequest(query, filter) {
  const response = await api
    .get('intersections', {
      searchParams: encodeParams({
        query,
        projection: {
          identifier: true,
          vehicle: true,
          startTime: true,
          endTime: true,
          durationSeconds: true,
          location: true,
        },
      }),
      signal,
    })
    .json();

  const data = []
    .concat(
      ...response.map(
        ({
          startTime,
          endTime,
          vehicle: {
            identificationNumber,
            registrationNumber,
            fleetNumber,
            role,
            type,
            groups,
          },
          location: { name: locationName, type: locationType },
        }) => {
          const count = differenceInHours(
            addHours(startOfHour(new Date(endTime)), 1),
            startOfHour(new Date(startTime)),
          );

          if (count === 1) {
            return [
              {
                identificationNumber,
                registrationNumber,
                fleetNumber,
                role,
                type,
                groups,
                locationName,
                locationType,
                hour: startOfHour(new Date(startTime)),
                durationSeconds: differenceInSeconds(
                  new Date(endTime),
                  new Date(startTime),
                ),
              },
            ];
          } else if (count === 2) {
            return [
              {
                identificationNumber,
                registrationNumber,
                fleetNumber,
                role,
                type,
                groups,
                locationName,
                locationType,
                hour: startOfHour(new Date(startTime)),
                durationSeconds: differenceInSeconds(
                  startOfHour(addHours(new Date(startTime), 1)),
                  new Date(startTime),
                ),
              },
              {
                identificationNumber,
                registrationNumber,
                fleetNumber,
                role,
                type,
                groups,
                locationName,
                locationType,
                hour: startOfHour(new Date(endTime)),
                durationSeconds: differenceInSeconds(
                  new Date(endTime),
                  startOfHour(new Date(endTime)),
                ),
              },
            ];
          } else {
            return [
              {
                identificationNumber,
                registrationNumber,
                fleetNumber,
                role,
                type,
                groups,
                locationName,
                locationType,
                hour: startOfHour(new Date(startTime)),
                durationSeconds: differenceInSeconds(
                  startOfHour(addHours(new Date(startTime), 1)),
                  new Date(startTime),
                ),
              },
              ...Array(count - 2)
                .fill()
                .map((_, index) => ({
                  identificationNumber,
                  registrationNumber,
                  fleetNumber,
                  role,
                  type,
                  groups,
                  locationName,
                  locationType,
                  hour: addHours(startOfHour(new Date(startTime)), index + 1),
                  durationSeconds: 3600,
                })),
              {
                identificationNumber,
                registrationNumber,
                fleetNumber,
                role,
                type,
                groups,
                locationName,
                locationType,
                hour: startOfHour(new Date(endTime)),
                durationSeconds: differenceInSeconds(
                  new Date(endTime),
                  startOfHour(new Date(endTime)),
                ),
              },
            ];
          }
        },
      ),
    )
    .filter(
      (record) =>
        new Date(record.hour) >= new Date(query.endTime.$gte) &&
        new Date(record.hour) <= new Date(query.startTime.$lt),
    );

  await db.vehicleInBaseTime.clear();
  await db.vehicleInBaseTime.add(data);
  await db.parameters.put({ store: 'vehicleInBaseTime', query });

  const filteredData = data.filter((record) =>
    vehicleInBaseFilter(record, filter),
  );

  const results = {
    query,
    filter,
    filterValues: getVehicleInBaseFilterValues(data, filter),
    data: getVehicleInBaseTime(filteredData),
  };

  log('Read', 'Vehicle In Base Time', query);

  return results;
}

export function fetchVehicleInBaseTimeEpic(action$) {
  return action$.pipe(
    ofType(FETCH_VEHICLE_IN_BASE_TIME),
    mergeMap(({ payload: { query, filter } }) =>
      from(fetchVehicleInBaseTimeRequest(query, filter)).pipe(
        map((payload) => ({
          type: FETCH_VEHICLE_IN_BASE_TIME_SUCCESS,
          payload,
        })),
        takeUntil(
          action$.pipe(
            ofType(FETCH_VEHICLE_IN_BASE_TIME_CANCELLED),
            tap(() => controller.abort()),
          ),
        ),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_VEHICLE_IN_BASE_TIME_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

async function loadVehicleInBaseTimeRequest(filter) {
  const reportName = 'vehicleInBaseTime';
  const data = await fetchCachedData(reportName);
  const parameters = await db.parameters.get(reportName);

  const filteredData = data.filter((record) =>
    vehicleInBaseFilter(record, filter),
  );

  const results = {
    filter,
    filterValues: getVehicleInBaseFilterValues(data, filter),
    data: getVehicleInBaseTime(filteredData),
    parameters: {
      startTime: parameters?.query?.endTime?.$gte,
      endTime: parameters?.query?.startTime?.$lt,
    },
  };

  log('Load', 'Vehicle In Base Time', parameters);

  return results;
}

export function loadVehicleInBaseTimeEpic(action$) {
  return action$.pipe(
    ofType(LOAD_VEHICLE_IN_BASE_TIME),
    mergeMap(({ payload: filter }) =>
      from(loadVehicleInBaseTimeRequest(filter)).pipe(
        map((payload) => ({
          type: LOAD_VEHICLE_IN_BASE_TIME_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: LOAD_VEHICLE_IN_BASE_TIME_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

function drivingScoresFilter(record, filter) {
  if (filter.code.length !== 0 && !filter.code.includes(record.code)) {
    return false;
  }

  if (filter.name.length !== 0 && !filter.name.includes(record.name)) {
    return false;
  }

  if (
    filter.collarNumber.length !== 0 &&
    !filter.collarNumber.includes(record.collarNumber)
  ) {
    return false;
  }

  if (filter.role.length !== 0 && !filter.role.includes(record.role)) {
    return false;
  }

  return groupsFilter(record, filter);
}

function calculateScore(record) {
  const drivingSeconds =
    record.drivingSeconds || record.tripsDurationSeconds || record.seconds || 0;
  const accelerationSeconds =
    record.harshAccelerationSeconds ||
    record.excessAccelerationDurationSeconds ||
    record.excessAccelSeconds ||
    0;
  const brakingSeconds =
    record.harshBrakingSeconds ||
    record.excessBrakingDurationSeconds ||
    record.excessBrakeSeconds ||
    0;
  const corneringSeconds =
    record.harshCorneringSeconds ||
    record.excessCorneringDurationSeconds ||
    record.excessCorneringSeconds ||
    0;
  const speedingSeconds =
    record.speedingSeconds || record.speedInfractionDurationSeconds || 0;

  return !drivingSeconds || drivingSeconds === 0
    ? 0
    : // JL perf: this is expensive: round(
      ((drivingSeconds -
        speedingSeconds -
        accelerationSeconds -
        brakingSeconds -
        corneringSeconds) /
        drivingSeconds) *
        100;
  //,2);
}

function getAggregatedDrivingScores(
  rawData,
  timeAggregation = 'days',
  tripsOnly = false,
) {
  // date-fns.format too slow!
  // const timeAggregationFormat = {
  //   days: 'dd/MM/yyyy',
  //   months: 'MMM',
  //   years: 'yyyy'
  // }[timeAggregation];

  // TODOJL should I be getting UTCYear... UTC stuff in general
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const timeAggregationFormatFunction = {
    days: (d) => `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`,
    months: (d) => monthNames[d.getMonth()],
    years: (d) => d.getFullYear(),
  }[timeAggregation];
  const dateKeyFunction = (d) =>
    d.getFullYear() * 10000 + d.getMonth() * 100 + d.getDate();

  // the ... operator is expensive
  // const totalTemplate = {
  //   drivingSeconds: 0,
  //   harshAccelerationSeconds: 0,
  //   harshBrakingSeconds: 0,
  //   harshCorneringSeconds: 0,
  //   speedInfractions: 0,
  //   speedInfractionDurationSeconds: 0,
  //   lowestScore: 100,
  //   highestScore: 0
  // };

  const subTotalKeys = [
    'statsAll',
    'statsWithEmergencyEquipment',
    'statsWithoutEmergencyEquipment',
  ];

  function addRecordToAccumulatorEntry(accEntry, record) {
    accEntry.drivingSeconds += record.tripsDurationSeconds;
    accEntry.harshAccelerationSeconds +=
      record.excessAccelerationDurationSeconds;
    accEntry.harshBrakingSeconds += record.excessBrakingDurationSeconds;
    accEntry.harshCorneringSeconds += record.excessCorneringDurationSeconds;
    accEntry.speedInfractions += record.speedInfractions;
    accEntry.speedInfractionDurationSeconds +=
      record.speedInfractionDurationSeconds;
    accEntry.lowestScore = Math.min(accEntry.lowestScore, record.score);
    accEntry.highestScore = Math.max(accEntry.highestScore, record.score);
  }

  function addProportionsToRecord(record) {
    record.excessAccelerationRatio =
      record.excessAccelerationDurationSeconds / record.tripsDurationSeconds;
    record.excessBrakingRatio =
      record.excessBrakingDurationSeconds / record.tripsDurationSeconds;
    record.excessCorneringRatio =
      record.excessCorneringDurationSeconds / record.tripsDurationSeconds;
    record.speedInfractionRatio =
      record.speedInfractionDurationSeconds / record.tripsDurationSeconds;
  }

  const data = {};

  subTotalKeys.forEach((key) => {
    if (tripsOnly) {
      data[key] = {
        trips: rawData
          .filter((d) => d[key].trips > 0)
          .map(
            ({
              startTime,
              endTime,
              code,
              collarNumber,
              fleetNumber,
              registrationNumber,
              identificationNumber,
              name,
              ...record
            }) => ({
              startTime,
              endTime,
              name,
              code,
              collarNumber,
              fleetNumber,
              registrationNumber,
              identificationNumber,
              score: record[key].score,
              durationSeconds: record[key].tripsDurationSeconds,
              mileage: record[key].tripsDistanceMileage,
              excessAcceleration:
                record[key].excessAccelerationDurationSeconds /
                record[key].tripsDurationSeconds,
              excessBraking:
                record[key].excessBrakingDurationSeconds /
                record[key].tripsDurationSeconds,
              excessCornering:
                record[key].excessCorneringDurationSeconds /
                record[key].tripsDurationSeconds,
              speedInfraction:
                record[key].speedInfractionDurationSeconds /
                record[key].tripsDurationSeconds,
            }),
          )
          .sort((a, b) => a.startTime - b.startTime),
      };
    } else {
      data[key] = rawData.reduce(
        (accumulator, record) => {
          // use date-fns instead
          const startTime = new Date(record.time);

          record[key].score = calculateScore(record[key]);
          record[key].startTime = startTime;
          // record[key].endTime = endTime;

          // record[key].label = format(startTime, timeAggregationFormat);
          record[key].label = timeAggregationFormatFunction(startTime);
          addProportionsToRecord(record[key]);

          // skip ones that have a score of 0 - no trips for example...
          if (record[key].score === 0) {
            return accumulator;
          }

          addRecordToAccumulatorEntry(accumulator.totals, record[key]);

          // trend: records by date essentially
          // const dateKey = format(startTime, 'yyyyMMdd');
          const dateKey = dateKeyFunction(startTime);

          let currentTrendItem = accumulator.trend[dateKey];
          if (!currentTrendItem) {
            currentTrendItem = {
              label: record[key].label, // JL perf: format is kinda expensive (see "Date")
              date: startTime,

              drivingSeconds: 0,
              harshAccelerationSeconds: 0,
              harshBrakingSeconds: 0,
              harshCorneringSeconds: 0,
              speedInfractions: 0,
              speedInfractionDurationSeconds: 0,
              lowestScore: 100,
              highestScore: 0,
            };
            accumulator.trend[dateKey] = currentTrendItem;
          }
          addRecordToAccumulatorEntry(currentTrendItem, record[key]);

          // driver: records by driver
          const driverKey = record.code || 'Unknown';
          if (driverKey) {
            let currentDriver = accumulator.drivers[driverKey];
            if (!currentDriver) {
              currentDriver = {
                code: record.code,
                name: record.name,
                collarNumber: record.collarNumber,
                role: record.role,
                groups: record.groups,
                mileage: 0,
                aggregatedTrips: [],

                drivingSeconds: 0,
                harshAccelerationSeconds: 0,
                harshBrakingSeconds: 0,
                harshCorneringSeconds: 0,
                speedInfractions: 0,
                speedInfractionDurationSeconds: 0,
                lowestScore: 100,
                highestScore: 0,
              };
              accumulator.drivers[driverKey] = currentDriver;
            }
            currentDriver.aggregatedTrips.push(record[key]);
            addRecordToAccumulatorEntry(currentDriver, record[key]);
          }
          return accumulator;
        },
        {
          totals: {
            drivingSeconds: 0,
            harshAccelerationSeconds: 0,
            harshBrakingSeconds: 0,
            harshCorneringSeconds: 0,
            speedInfractions: 0,
            speedInfractionDurationSeconds: 0,
            lowestScore: 100,
            highestScore: 0,
          },
          drivers: {},
          trend: [],
        },
      );

      data[key].totals.averageScore = calculateScore(data[key].totals);

      data[key].trend = Object.keys(data[key].trend)
        .sort()
        .map((dateKey) => {
          const {
            label,
            date,
            highestScore,
            lowestScore,
            drivingSeconds,
            harshAccelerationSeconds,
            harshBrakingSeconds,
            harshCorneringSeconds,
            speedInfractionDurationSeconds,
          } = data[key].trend[dateKey];

          return {
            date,
            label,
            Lowest: lowestScore,
            Highest: highestScore,
            Average: calculateScore({
              drivingSeconds,
              harshAccelerationSeconds,
              harshBrakingSeconds,
              harshCorneringSeconds,
              speedInfractionDurationSeconds,
            }),
          };
        });

      Object.values(data[key].drivers).forEach((driver) => {
        driver.averageScore = calculateScore(driver);
      });
    }
  });

  return data;
}

const accelFields = {
  trips: true,
  tripsDurationSeconds: true,
  tripsDistanceKilometres: true,

  tripsWithEmergencyEquipment: true,
  tripsWithEmergencyEquipmentDurationSeconds: true,
  tripsWithEmergencyEquipmentDistanceKilometres: true,

  speedInfractions: true,
  speedInfractionsDurationSeconds: true,

  speedInfractionsWithEmergencyEquipment: true,
  speedInfractionsWithEmergencyEquipmentDurationSeconds: true,

  idlingDurationSeconds: true,
  idlingWithEmergencyEquipmentDurationSeconds: true,
};

const renamedAccelFields = {
  trips: '$accelTrips',
  tripsDurationSeconds: '$accelTripsDurationSeconds',
  tripsDistanceKilometres: '$accelTripsDistanceKilometres',

  tripsWithEmergencyEquipment: '$accelTripsWithEmergencyEquipment',
  tripsWithEmergencyEquipmentDurationSeconds:
    '$accelTripsWithEmergencyEquipmentDurationSeconds',
  tripsWithEmergencyEquipmentDistanceKilometres:
    '$accelTripsWithEmergencyEquipmentDistanceKilometres',

  speedInfractions: '$accelSpeedInfractions',
  speedInfractionsDurationSeconds: '$accelSpeedInfractionsDurationSeconds',

  speedInfractionsWithEmergencyEquipment:
    '$accelSpeedInfractionsWithEmergencyEquipment',
  speedInfractionsWithEmergencyEquipmentDurationSeconds:
    '$accelSpeedInfractionsWithEmergencyEquipmentDurationSeconds',

  idlingDurationSeconds: '$accelIdlingDurationSeconds',
  idlingWithEmergencyEquipmentDurationSeconds:
    '$accelIdlingWithEmergencyEquipmentDurationSeconds',
};

async function fetchAggregatedDrivingScores(
  startTime,
  endTime,
  filter,
  timeAggregation = null,
  driverCode = null,
  tripsOnly = false,
) {
  timeAggregation = timeAggregation?.toLowerCase();

  const timeAggregationUrls = {
    days: 'personDailySummaries',
    months: 'personMonthlySummaries',
    years: 'personYearlySummaries',
  };

  const startOfAggregationFunctions = {
    days: startOfDay,
    months: startOfMonth,
    years: startOfYear,
  };

  let url, startOfFunction, fields;
  if (timeAggregationUrls[timeAggregation]) {
    url = timeAggregationUrls[timeAggregation];
    startOfFunction = startOfAggregationFunctions[timeAggregation];
    fields = renamedAccelFields;
  } else {
    url = 'vehicleTripAccelerationSummaries';
    startOfFunction = startOfDay;
    fields = accelFields;
  }

  startTime = startOfFunction(startTime);
  endTime = startOfFunction(endTime);

  const response = await api
    .get(url, {
      searchParams: encodeParams({
        query: tripsOnly
          ? {
              'driver.code': driverCode ?? null,
              startTime: { $lt: endTime },
              endTime: { $gte: startTime },
            }
          : {
              time: { $gte: startTime, $lt: endTime },
            },
        projection: {
          identifier: true,
          time: true,
          startTime: true,
          endTime: true,
          fleetNumber: '$vehicle.fleetNumber',
          registrationNumber: '$vehicle.registrationNumber',
          identificationNumber: '$vehicle.identificationNumber',
          person: true,
          driver: true,
          // loggedInSeconds: true,
          // inBaseSeconds: true,
          // attendingObjectiveSeconds: true,
          // inHomeWardSeconds: true,
          // doubleCrewingSeconds: true,
          // baseVisits: true,

          // respondingToIncidentSeconds: true,

          ...fields,

          excessAccelerationDurationSeconds: true,
          excessBrakingDurationSeconds: true,
          excessCorneringDurationSeconds: true,

          excessAccelerationWithEmergencyEquipmentDurationSeconds: true,
          excessBrakingWithEmergencyEquipmentDurationSeconds: true,
          excessCorneringWithEmergencyEquipmentDurationSeconds: true,
        },
      }),
      signal,
    })
    .json();

  const MILES_PER_KM = 0.62137119;
  const data = response.map((record) => {
    record.person = record.person ?? record.driver;

    const { forenames, surname } = record.person;
    const hasName = !!forenames || !!surname;
    const name = hasName
      ? `${forenames} ${surname}`
      : record.person.identificationReference ?? 'Unknown';

    // api has WithEmergencyEquipment separate, let's put them in objects
    // that have similar keys so don't have too much repetition down the line
    const statsWithoutEmergencyEquipment = {
      trips: record.trips,
      tripsDurationSeconds: record.tripsDurationSeconds,
      tripsDistanceMileage: record.tripsDistanceKilometres * MILES_PER_KM,
      speedInfractions: record.speedInfractions,
      speedInfractionDurationSeconds: record.speedInfractionsDurationSeconds,
      excessAccelerationDurationSeconds:
        record.excessAccelerationDurationSeconds,
      excessBrakingDurationSeconds: record.excessBrakingDurationSeconds,
      excessCorneringDurationSeconds: record.excessCorneringDurationSeconds,
    };

    const statsWithEmergencyEquipment = {
      trips: record.tripsWithEmergencyEquipment,
      tripsDurationSeconds: record.tripsWithEmergencyEquipmentDurationSeconds,
      tripsDistanceMileage:
        record.tripsWithEmergencyEquipmentDistanceKilometres * MILES_PER_KM,
      speedInfractions: record.speedInfractionsWithEmergencyEquipment,
      speedInfractionDurationSeconds:
        record.speedInfractionsWithEmergencyEquipmentDurationSeconds,
      excessAccelerationDurationSeconds:
        record.excessAccelerationWithEmergencyEquipmentDurationSeconds,
      excessBrakingDurationSeconds:
        record.excessBrakingWithEmergencyEquipmentDurationSeconds,
      excessCorneringDurationSeconds:
        record.excessCorneringWithEmergencyEquipmentDurationSeconds,
    };

    const statsAll = {
      trips: record.trips + record.tripsWithEmergencyEquipment,
      tripsDurationSeconds:
        record.tripsDurationSeconds +
        record.tripsWithEmergencyEquipmentDurationSeconds,
      tripsDistanceMileage:
        (record.tripsDistanceKilometres +
          record.tripsWithEmergencyEquipmentDistanceKilometres) *
        MILES_PER_KM,
      speedInfractions:
        record.speedInfractions + record.speedInfractionsWithEmergencyEquipment,
      speedInfractionDurationSeconds:
        record.speedInfractionsDurationSeconds +
        record.speedInfractionsWithEmergencyEquipmentDurationSeconds,
      excessAccelerationDurationSeconds:
        record.excessAccelerationDurationSeconds +
        record.excessAccelerationWithEmergencyEquipmentDurationSeconds,
      excessBrakingDurationSeconds:
        record.excessBrakingDurationSeconds +
        record.excessBrakingWithEmergencyEquipmentDurationSeconds,
      excessCorneringDurationSeconds:
        record.excessCorneringDurationSeconds +
        record.excessCorneringWithEmergencyEquipmentDurationSeconds,
    };

    // calculate scores for each
    [
      statsAll,
      statsWithEmergencyEquipment,
      statsWithoutEmergencyEquipment,
    ].forEach((stats) => (stats.score = calculateScore(stats)));

    return {
      code: record.person.code,
      name,
      collarNumber: record.person?.collarNumber || 'Unknown',
      role: record.person?.role || 'Unknown',
      groups: record.person.groups,
      time: record.time,
      startTime: timeAggregation ? null : new Date(record.startTime),
      endTime: timeAggregation ? null : new Date(record.endTime),
      fleetNumber: timeAggregation ? null : record.fleetNumber,
      registrationNumber: timeAggregation ? null : record.registrationNumber,
      identificationNumber: timeAggregation
        ? null
        : record.identificationNumber,
      statsAll,
      statsWithEmergencyEquipment,
      statsWithoutEmergencyEquipment,
    };
  });

  const filteredData = data.filter((record) =>
    drivingScoresFilter(record, filter),
  );

  const results = {
    filter,
    //filterValues: getDrivingScoresFilterValues(data, filter),
    filterValues: getFilterOptionsFromData(data, filter, drivingScoresFilter),
    data: getAggregatedDrivingScores(filteredData, timeAggregation, tripsOnly),
    unfilteredData: data,
    startTime,
    endTime,
    timeAggregation,
  };

  log('Read', 'Aggregated Driving Scores', {
    startTime: startTime,
    endTime: endTime,
  });

  return results;
}

export function fetchAggregatedDrivingScoresEpic(action$) {
  return action$.pipe(
    ofType(FETCH_AGGREGATED_DRIVING_SCORES),
    mergeMap(({ payload: { startTime, endTime, filter, timeAggregation } }) =>
      from(
        fetchAggregatedDrivingScores(
          startTime,
          endTime,
          filter,
          timeAggregation,
        ),
      ).pipe(
        map((payload) => ({
          type: FETCH_AGGREGATED_DRIVING_SCORES_SUCCESS,
          payload,
        })),
        takeUntil(
          action$.pipe(
            ofType(FETCH_AGGREGATED_DRIVING_SCORES_CANCELLED),
            tap(() => controller.abort()),
          ),
        ),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_AGGREGATED_DRIVING_SCORES_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

async function loadAggregatedDrivingScoresRequest({
  unfilteredData = [],
  startTime,
  endTime,
  timeAggregation,
  filter,
}) {
  const filteredData = unfilteredData.filter((record) =>
    drivingScoresFilter(record, filter),
  );

  const results = {
    filter,
    filterValues: getFilterOptionsFromData(
      unfilteredData,
      filter,
      drivingScoresFilter,
    ),
    data: getAggregatedDrivingScores(filteredData, timeAggregation),
    unfilteredData,
    startTime,
    endTime,
    timeAggregation,
  };

  log('Load', 'Aggregated Driving Scores', {
    startTime: startTime,
    endTime: endTime,
  });

  return results;
}

export function loadAggregatedDrivingScoresEpic(action$, state$) {
  return action$.pipe(
    ofType(LOAD_AGGREGATED_DRIVING_SCORES),
    mergeMap(({ payload: filter }) =>
      from(
        loadAggregatedDrivingScoresRequest({
          ...state$.value.reports.aggregatedDrivingScores,
          filter,
        }),
      ).pipe(
        map((payload) => ({
          type: LOAD_AGGREGATED_DRIVING_SCORES_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: LOAD_AGGREGATED_DRIVING_SCORES_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function fetchDrivingScoresEpic(action$) {
  return action$.pipe(
    ofType(FETCH_DRIVING_SCORES),
    mergeMap(
      (
        {
          payload: {
            startTime,
            endTime, //collarNumber, //emergencyEquipmentUsed,
            filter,
            driverCode,
            timeAggregation,
            tripsOnly = true,
          },
        }, //addDayToEndTime,
      ) =>
        from(
          fetchAggregatedDrivingScores(
            startTime,
            endTime,
            filter,
            timeAggregation,
            driverCode,
            tripsOnly,
          ),
        ).pipe(
          map((payload) => ({
            type: FETCH_DRIVING_SCORES_SUCCESS,
            payload,
          })),
          takeUntil(
            action$.pipe(
              ofType(FETCH_DRIVING_SCORES_CANCELLED),
              tap(() => controller.abort()),
            ),
          ),
          catchError(({ message: payload }) =>
            of({
              type: FETCH_DRIVING_SCORES_FAILURE,
              payload,
            }),
          ),
        ),
    ),
  );
}

export function fetchDriverTripsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_DRIVER_TRIPS_BY_DRIVER_CODE),
    mergeMap(({ payload }) =>
      from(getHeaders().then((headers) => ({ payload, headers }))),
    ),
    mergeMap(
      ({
        payload: { driverCode, startTime, endTime, excludeExempt },
        headers,
      }) =>
        forkJoin({
          infractions: fromAjax('/speedInfractions', {
            params: {
              query: {
                startTime: { $lt: endTime },
                endTime: { $gte: startTime },
                'driver.code': driverCode ?? null,
                durationSeconds: { $gte: minimumSpeedInfractionSeconds || 45 },
              },
              projection: {
                identifier: true,
                durationSeconds: true,
                distanceKilometres: true,
                parentEvent: true,
                equipmentActivations: true,
              },
            },
            headers,
          }),
          trips: fromAjax('/trips', {
            params: {
              query: {
                startTime: { $lt: endTime },
                endTime: { $gte: startTime },
                'driver.code': driverCode ?? null,
                ...(excludeExempt
                  ? { classification: { $nin: exemptTripClassifications } }
                  : {}),
              },
              projection: {
                identifier: true,
                startTime: true,
                endTime: true,
                classification: true,
                distanceKilometres: true,
                driver: true,
                fleetNumber: '$vehicle.fleetNumber',
                registrationNumber: '$vehicle.registrationNumber',
                identificationNumber: '$vehicle.identificationNumber',
                'vehicle.telematicsBoxImei': true,
                durationSeconds: true,
                drivingSeconds: '$durationSeconds',
                equipmentActivations: true,
              },
            },
            headers,
          }),
        }).pipe(
          map(
            ({
              infractions: { response: infractionsResponse },
              trips: { response: tripResponse },
            }) => {
              const groupedSpeedInfractions = _.groupBy(
                infractionsResponse,
                'parentEvent',
              );

              const trips = (tripResponse || []).map((trip) => {
                const emergencyEquipmentUsed =
                  trip.equipmentActivations.emergencyOn;

                const infractions =
                  groupedSpeedInfractions[trip.identifier] || [];
                let speeding = {
                  speedInfractions: infractions.length,
                  speedInfractionsDurationSeconds: 0,
                  speedInfractionsWithEmergencyEquipment: 0,
                  speedInfractionsWithEmergencyEquipmentDurationSeconds: 0,
                };

                infractions.forEach((infraction) => {
                  speeding.speedInfractionsDurationSeconds +=
                    infraction.durationSeconds;

                  if (speeding.equipmentActivations?.emergencyEquipmentOn) {
                    speeding.speedInfractionsWithEmergencyEquipment += 1;
                    speeding.speedInfractionsWithEmergencyEquipmentDurationSeconds +=
                      infraction.durationSeconds;
                  }
                });

                return {
                  ...trip,
                  emergencyEquipmentUsed,
                  ...speeding,
                  mileage: trip.distanceKilometres * 0.62137119,
                };
              });

              log('Read', 'Driver Trips', { driverCode, startTime, endTime });

              return {
                type: FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_SUCCESS,
                payload: { driverCode, trips },
              };
            },
          ),

          // from(
          //   fetchDriverTripsByDriverCode(
          //     driverCode,
          //     startTime,
          //     endTime,
          //     excludeExempt
          //   )
          // ).pipe(
          //   map((payload) => ({
          //     type: FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_SUCCESS,
          //     payload,
          //   })),
          takeUntil(
            action$.pipe(
              ofType(FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_CANCELLED),
              tap(() => controller.abort()),
            ),
          ),
          catchError(({ message: payload }) =>
            of({
              type: FETCH_DRIVER_TRIPS_BY_DRIVER_CODE_FAILURE,
              payload,
            }),
          ),
        ),
    ),
  );
}

function vehiclesInLocationFilter(record, filter) {
  if (
    filter.registrationNumber.length !== 0 &&
    !filter.registrationNumber.includes(record.registrationNumber)
  ) {
    return false;
  }

  if (
    filter.fleetNumber.length !== 0 &&
    !filter.fleetNumber.includes(record.fleetNumber)
  ) {
    return false;
  }

  if (filter.role.length !== 0 && !filter.role.includes(record.role)) {
    return false;
  }

  if (filter.type.length !== 0 && !filter.type.includes(record.type)) {
    return false;
  }

  if (
    filter.locationName.length !== 0 &&
    !filter.locationName.includes(record.locationName)
  ) {
    return false;
  }

  if (
    filter.locationType.length !== 0 &&
    !filter.locationType.includes(record.locationType)
  ) {
    return false;
  }

  return groupsFilter(record, filter);
}

function getVehiclesInLocationFilterValues(data, filter) {
  return getFilterOptionsFromData(data, filter, vehiclesInLocationFilter);
}

function getVehiclesInLocations(data, startTime, endTime) {
  if (!startTime || !endTime || (data || []).length === 0) {
    return [];
  }

  const locationCountChanges = data.reduce((accumulator, record) => {
    if (!(record.locationName in accumulator)) {
      accumulator[record.locationName] = {};
    }

    // quick way to change the Date() to milliseconds timestamp, this
    // is necessary so it can be used as a key, otherwise using Date()
    // as a key will be converted to a string & won't work for sorting
    // i.e. "Friday ... " < x && x < "Tuesday ... "
    const timeKey = +record.time;
    const minuteKey = timeKey - (timeKey % 60000);

    if (!(minuteKey in accumulator[record.locationName])) {
      accumulator[record.locationName][minuteKey] = {
        residentVehicles: 0,
        visitorVehicles: 0,
      };
    }

    accumulator[record.locationName][minuteKey][
      record.atHome ? 'residentVehicles' : 'visitorVehicles'
    ] += record.change;

    return accumulator;
  }, {});

  const startEpoch = +startTime; // shorthand to change Date() to epoch time
  const endEpoch = +endTime;
  const locationTimelines = Object.entries(locationCountChanges).map(
    (record) => {
      const entries = Object.entries(record[1]);

      let residentTally = 0;
      let visitorTally = 0;
      let locationTally = [];

      entries.forEach(([time, { residentVehicles, visitorVehicles }]) => {
        residentTally += residentVehicles;
        visitorTally += visitorVehicles;

        locationTally.push({
          time, //: new Date(date).getTime(),
          residentCount: residentTally,
          visitorCount: visitorTally,
        });
      });

      const values = _.sortBy(locationTally, ['time']).filter(
        ({ time }) => startEpoch <= time && time < endEpoch,
      );

      return {
        name: record[0],
        values,
      };
    },
  );

  return locationTimelines;
}

async function fetchVehiclesInLocationsRequest(query, filter) {
  const reportName = 'vehiclesInLocations';
  const parameters = await db.parameters.get(reportName);
  let data;

  if (dequal(parameters?.query, query)) {
    data = await fetchCachedData(reportName);
  } else {
    const response = await api
      .get('intersections', {
        searchParams: encodeParams({
          query,
          projection: {
            identifier: true,
            vehicle: true,
            startTime: true,
            endTime: true,
            durationSeconds: true,
            location: true,
          },
        }),
        signal,
      })
      .json();

    // + is shorthand to change it to epoch time (int seconds)
    const start = +new Date(query.endTime.$gte);
    const end = +new Date(query.startTime.$lt);

    const mapped = response
      .map(
        ({
          startTime: eventStartTime,
          endTime: eventEndTime,
          vehicle: {
            registrationNumber,
            fleetNumber,
            role,
            type,
            groups,
            homeStation,
          },
          location: {
            name: locationName,
            type: locationType,
            code: locationCode,
          },
        }) => {
          const atHome =
            homeStation === locationName || homeStation === locationCode;

          return [
            {
              registrationNumber,
              fleetNumber,
              role,
              type,
              groups,
              locationName,
              locationType,
              time: start,
              change: 0,
              atHome,
            },
            {
              registrationNumber,
              fleetNumber,
              role,
              type,
              groups,
              locationName,
              locationType,
              time: +new Date(eventStartTime),
              change: 1,
              atHome,
            },
            {
              registrationNumber,
              fleetNumber,
              role,
              type,
              groups,
              locationName,
              locationType,
              time: +new Date(eventEndTime),
              change: -1,
              atHome,
            },
            {
              registrationNumber,
              fleetNumber,
              role,
              type,
              groups,
              locationName,
              locationType,
              time: end,
              change: 0,
              atHome,
            },
          ];
        },
      )
      .flatMap((x) => x);

    data = _.sortBy(mapped, ['time']);

    await db.vehiclesInLocations.clear();
    await db.vehiclesInLocations.add(data);
    await db.parameters.put({
      store: 'vehiclesInLocations',
      query,
    });
  }

  const filteredData = data.filter((record) =>
    vehiclesInLocationFilter(record, filter),
  );

  const results = {
    filter,
    filterValues: getVehiclesInLocationFilterValues(data, filter),
    data: getVehiclesInLocations(
      filteredData,
      query.endTime.$gte,
      query.startTime.$lt,
    ),
    query,
    parameters: {
      startTime: parameters?.query?.endTime?.$gte,
      endTime: parameters?.query?.startTime?.$lt,
    },
  };

  log('Read', 'Vehicle In Locations', query);

  return results;
}

export function fetchVehiclesInLocationsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_VEHICLES_IN_LOCATIONS),
    mergeMap(({ payload: { query, filter } }) =>
      from(fetchVehiclesInLocationsRequest(query, filter)).pipe(
        map((payload) => ({
          type: FETCH_VEHICLES_IN_LOCATIONS_SUCCESS,
          payload,
        })),
        takeUntil(
          action$.pipe(
            ofType(FETCH_VEHICLES_IN_LOCATIONS_CANCELLED),
            tap(() => controller.abort()),
          ),
        ),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_VEHICLES_IN_LOCATIONS_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

async function loadVehiclesInLocationsRequest(filter) {
  const reportName = 'vehiclesInLocations';
  const data = await fetchCachedData(reportName);
  const parameters = await db.parameters.get(reportName);

  const filteredData = data.filter((record) =>
    vehiclesInLocationFilter(record, filter),
  );

  const results = {
    filter,
    filterValues: getVehiclesInLocationFilterValues(data, filter),
    data: getVehiclesInLocations(
      filteredData,
      parameters ? new Date(parameters.query.endTime.$gte) : null,
      parameters ? new Date(parameters.query.startTime.$lt) : null,
    ),
    query: parameters.query,
    parameters: {
      startTime: parameters?.query?.endTime?.$gte,
      endTime: parameters?.query?.startTime?.$lt,
    },
  };

  log('Load', 'Vehicle In Locations', parameters);

  return results;
}

export function loadVehiclesInLocationsEpic(action$) {
  return action$.pipe(
    ofType(LOAD_VEHICLES_IN_LOCATIONS),
    mergeMap(({ payload: filter }) =>
      from(loadVehiclesInLocationsRequest(filter)).pipe(
        map((payload) => ({
          type: LOAD_VEHICLES_IN_LOCATIONS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: LOAD_VEHICLES_IN_LOCATIONS_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

async function fetchVehicleOdometers(date) {
  const response = await api
    .get('vehicleOdometers', {
      searchParams: encodeParams({
        time: date,
      }),
    })
    .json();

  const readings = (response || []).map(
    ({
      latestPoll,
      lastReadingPoll,
      lastOdometerReading,
      calculatedOdometerReading,
      ...reading
    }) => {
      return {
        ...reading,
        readingTime: lastOdometerReading
          ? new Date(lastOdometerReading.time)
          : null,
        latestPollTime: latestPoll ? new Date(latestPoll.time) : null,
        pollAfterGapHours: lastReadingPoll
          ? round(lastReadingPoll.odometerReadingDifferenceSeconds / 3600, 2)
          : null,
        pollAfterReadingTime: lastReadingPoll
          ? new Date(lastReadingPoll.time)
          : null,
        readingMiles: lastOdometerReading
          ? round(lastOdometerReading.distanceKilometres * 0.62137119, 2)
          : null,
        pollAfterReadingMiles: lastReadingPoll
          ? round(lastReadingPoll.distanceKilometres * 0.62137119, 2)
          : null,
        latestPollMiles: latestPoll
          ? round(latestPoll.distanceKilometres * 0.62137119, 2)
          : null,
        calculatedMiles: calculatedOdometerReading
          ? round(calculatedOdometerReading.distanceKilometres * 0.62137119, 2)
          : null,
      };
    },
  );

  log('Read', 'Vehicle Mileage', {
    date,
  });

  return readings;
}

export function fetchVehicleOdometersEpic(action$) {
  return action$.pipe(
    ofType(FETCH_VEHICLE_ODOMETERS),
    mergeMap(({ payload: date }) =>
      from(fetchVehicleOdometers(date)).pipe(
        map((payload) => ({
          type: FETCH_VEHICLE_ODOMETERS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_VEHICLE_ODOMETERS_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

import {
  useDeleteGroup,
  useDocumentTitle,
  useGroup,
  useOptionValues,
  useUpsertGroup,
} from '@/hooks';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  AliasPickerController,
  BoundaryEditorController,
  GroupAncestors,
  GroupCodesController,
  TextFieldController,
} from '../controls';
import { ConfirmationDialog } from '../dialogs';

export function Group() {
  const { code } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { data: group } = useGroup(code, searchParams.has('copy'));
  useDocumentTitle(
    `IR3 | Groups & Areas | ${group?._id ? group.name : 'New Group'}`,
  );
  const { data: typeOptions } = useOptionValues('groupType');
  const { mutate: upsertGroup } = useUpsertGroup();
  const { mutate: deleteGroup } = useDeleteGroup();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const {
    control,
    formState: { isDirty, isSubmitting },
    getValues,
    handleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues: group,
  });

  useEffect(() => {
    reset(group);
  }, [reset, group]);

  function handleCancel() {
    reset(group);
  }

  function toggleDeleteDialog() {
    setDeleteOpen((prev) => !prev);
  }

  function handleDelete() {
    deleteGroup(code, {
      onSuccess: () => {
        navigate('..', { replace: true });
      },
    });
  }

  function submitCallback(values) {
    upsertGroup(values, {
      onSuccess: (data) => {
        navigate(`../${data.code}`, { replace: true });
      },
    });
  }

  return (
    <Box sx={{ flexGrow: 1, p: 1 }}>
      <form id="group-form" onSubmit={handleSubmit(submitCallback)}>
        <Stack spacing={1}>
          <Typography variant="h6" sx={{ p: 1 }}>
            {group?._id ? group.name : 'New Group'}
          </Typography>
          <Stack spacing={1} direction="row" flexWrap="wrap">
            <TextFieldController
              name="name"
              label="Name"
              control={control}
              rules={{ required: 'Required', maxLength: 50 }}
            />
            <TextFieldController
              name="type"
              label="Type"
              options={Object.entries(typeOptions).map(
                ([value, { label }]) => ({ label, value }),
              )}
              control={control}
              rules={{ required: 'Required' }}
            />
            {typeOptions[watch('type')]?.values && (
              <TextFieldController
                name="subtype"
                label="Subtype"
                options={[
                  ...(typeOptions[getValues('type')]?.values ?? []),
                ].sort()}
                control={control}
              />
            )}
          </Stack>
          <GroupCodesController
            name="parentCodes"
            control={control}
            label="Groups"
          />
          {group?.ancestors?.length > 0 && (
            <Fragment>
              <Divider>
                <Typography variant="caption" color="textSecondary">
                  Ancestors
                </Typography>
              </Divider>
              <GroupAncestors
                groupAncestors={group?.ancestors}
                typeOptions={typeOptions}
              />
            </Fragment>
          )}
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Aliases
            </Typography>
          </Divider>
          <AliasPickerController name="aliases" control={control} />
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Boundary
            </Typography>
          </Divider>
          <BoundaryEditorController name="boundary" control={control} />
          <Stack spacing={1} direction="row">
            <Button
              color="primary"
              type="submit"
              disabled={!isDirty || isSubmitting}
            >
              Save
            </Button>
            <Button
              color="primary"
              disabled={!isDirty || isSubmitting}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              color="error"
              onClick={toggleDeleteDialog}
              disabled={!watch('_id')}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </form>
      <ConfirmationDialog
        action="Delete"
        open={deleteOpen}
        itemId={group?.name || group?.code}
        onOk={handleDelete}
        onCancel={toggleDeleteDialog}
      />
    </Box>
  );
}

import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useVehicles() {
  return useQuery({
    queryKey: ['vehicles'],
    queryFn: async () => {
      const json = [
        {
          $graphLookup: {
            from: 'groups',
            startWith: '$groupCodes',
            connectFromField: 'parentCodes',
            connectToField: 'code',
            as: 'groupAncestorCodes',
          },
        },
        {
          $project: {
            identificationNumber: true,
            registrationNumber: true,
            fleetNumber: true,
            telematicsBoxImei: true,
            lastPollTime: true,
            disposalDate: true,
            picture: true,
            role: true,
            homeStation: true,
            driverIdLocation: true,
            installLocation: true,
            assignments: true,
            attributes: true,
            groupCodes: true,
            groupAncestorCodes: {
              $map: {
                input: '$groupAncestorCodes',
                as: 'group',
                in: '$$group.code',
              },
            },
            searchString: {
              $toLower: {
                $concat: [
                  '$identificationNumber',
                  '+',
                  '$registrationNumber',
                  '+',
                  '$fleetNumber',
                ],
              },
            },
          },
        },
      ];

      log('Read', 'Vehicles');

      return await api.post('pipeline/vehicles', { json }).json();
    },
    placeholderData: [],
  });
}

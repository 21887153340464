import { combineReducers } from 'redux';
import activity from './activityReducer';
import events from './eventsReducer';
import filters from './filtersReducer';
import gazetteer from './gazetteerReducer';
import live from './liveReducer';
import locations from './locationsReducer';
import objectives from './objectivesReducer';
import people from './peopleReducer';
import radios from './radiosReducer';
import reports from './reportsReducer';
import retrospectives from './retrospectivesReducer';
import rfidCards from './rfidCardsReducer';
import tags from './tagsReducer';
import telematicsBoxes from './telematicsBoxesReducer';
import utilisation from './utilisationReducer';
import vehicles from './vehiclesReducer';

const rootReducer = combineReducers({
  vehicles,
  people,
  locations,
  objectives,
  telematicsBoxes,
  filters,
  retrospectives,
  reports,
  live,
  utilisation,
  activity,
  events,
  tags,
  gazetteer,
  radios,
  rfidCards,
});

export default rootReducer;

import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useUrgentActions(options) {
  const searchParams = JSON.parse(JSON.stringify(options ?? {}));

  return useQuery({
    queryKey: ['urgentActions', searchParams],
    queryFn: () => {
      return briefingApi.get('urgentActions', { searchParams }).json();
    },
    placeholderData: [],
  });
}

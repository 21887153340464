const environmentConfig = await import(
  /* @vite-ignore */ `/config/${import.meta.env.MODE}.js?url`
);

export const {
  wsRootUrl = 'ws://localhost:3030/socket',
  apiRootUrl = 'http://localhost:3030',
  apiPath = '/api',
  authenticationScheme = 'oauth2',
  authenticationRootUrl = 'https://login.microsoftonline.com',
  tenantId = 'dccbcc32-f7c8-4317-9ca2-eff8cca82344',
  clientId = 'ae4bb8f7-7432-48d2-9672-077650223cf7',
  resource = 'ae4bb8f7-7432-48d2-9672-077650223cf7',
  mapExtent = [-10.85, 49.82, 2.02, 59.48],
  showEstimatedTimeOfArrival = true,
  etaRequestDelay = 60,
  simplificationTolerance = 0.001,
  useDallasKeys = false,
  autoLogin = false,
  occurrenceNumberOnObjectives = true,
  maxUploadSize = 5242880,
  isFleet = false,
  showIntelCrime = false,
  showTranmanIdentifier = true,
  useRfidCardCategory = true,
  useRestricted = true,
  requireAdLogin = false,
  showAdGroups = true,
  sentry = {
    enable: false,
    dsn: 'https://5c9622f257014741ba8cec15f06ae93f@o230179.ingest.sentry.io/6172890',
    tracesSampleRate: 1,
  },
  defaultRouteLeavingHeader = 'Leave without saving?',
  defaultRouteLeavingText = 'Unsaved changes will be lost. Are you sure you want to leave without saving?',
  promptText = 'This is a one time prompt that the user accepts. It can be GDPR related. Once the user accepts then it will not be shown again',
  liveCanEditViewsGroup,
  liveCanEditViewsGroups = [
    'IR3 Everything',
    'Test Engineers',
    'Test Admins',
    'Test Users',
    'Test FCC Users',
    'Test Fleet Analysts',
    'Test Supervisors',
  ],
  liveCanEditGlobalViewsGroup,
  liveCanEditGlobalViewsGroups = [
    'IR3 Everything',
    'Test Admins',
    'Test Supervisors',
  ],
  authorizationOverrides = {},
  allowDriverChange = true,
  rowsPerPageOptions = [10, 25, 50],
  lastContactDefaultDays = 14,
  dioStates = {
    headlightsFlashOn: 'Headlights Flash',
    sirensOn: 'Sirens',
    beaconsOn: 'Beacons PWE/Spare',
    unused4: '(4)',
    unused5: '(5)',
    unused6: '(6)',
  },
  dioOptions = {
    showDriverId: true,
    showTracking: false,
    hideLonLat: true,
    hideMap: false,
    manualPolls: true,
  },
  emergencyEquipmentEventTypes = ['BEACONS', 'SIRENS', 'AMBERLIGHTS'],
  vehicleEmergencyEquipment = [
    'amberLightsOn',
    'beaconsOn',
    'sirensOn',
    'rearRedLightsOn',
    'rearBlueLightsOn',
  ],
  speedRuleLabels = {
    '10PCTPLUS2': '10% + 2',
    LIGHTSONPLUS25: 'Lights on + 25',
    LIGHTSON30INA20: 'Lights on 30 in a 20',
    LIGHTSONDOUBLE: 'Lights on double',
    LIGHTSONEXCESSIVE100: 'Lights on > 100',
    LIGHTSONEXCESSIVE120: 'Lights on > 120',
  },
  minimumSpeedInfractionSeconds = 45,
  minimumDoubleCrewSeconds = 45,
  baseType = { label: 'Base', value: 'base' },
  commonRegEx = {
    urlInvalidCharactersRegEx: /[\^%?/\\]+/,
    noSpecialCharsRegEx: /^[a-z0-9 ]*$/i,
    noSpecialOrSpaceCharsRegEx: /^[a-z0-9]*$/i,
  },
  tagForm = {
    identifier: {
      editable: false,
      componentProps: {
        maxLength: 50,
        validatorRegEx: 'noSpecialCharsRegEx',
      },
    },
  },
  vehicleForm = {
    identificationNumber: {
      editable: false,
      componentProps: {
        maxLength: 30,
        validatorRegEx: 'noSpecialOrSpaceCharsRegEx',
        upperCase: true,
      },
    },
    registrationNumber: {
      editable: true,
      componentProps: {
        maxLength: 20,
        validatorRegEx: 'noSpecialOrSpaceCharsRegEx',
        upperCase: true,
      },
    },
    fleetNumber: { editable: true, componentProps: { maxLength: 50 } },
    role: { editable: true },
    type: { editable: true },
    make: { editable: true },
    model: { editable: true },
    colour: { editable: true },
    fuelType: { editable: true },
    marked: { editable: true },
    homeStation: { editable: true },
    equipment: { editable: true },
    telematicsBoxImei: { editable: true },
    lastOdometerReading: { editable: true },
    disposalDate: { editable: true },
    picture: { editable: true },
    visibleTo: { editable: true },
    groups: { editable: true },
    groupCodes: { editable: true },
    callSign: { editable: true },
    installLocation: { editable: true },
    driverIdLocation: { editable: true },
    commissionDate: { editable: true },
    notes: { editable: true },
  },
  personForm = {
    code: {
      editable: false,
      componentProps: {
        maxLength: 30,
        validatorRegEx: 'noSpecialCharsRegEx',
      },
    },
    forenames: { editable: true, componentProps: { maxLength: 50 } },
    surname: { editable: true, componentProps: { maxLength: 50 } },
    collarNumber: {
      editable: true,
      componentProps: {
        maxLength: 30,
        validatorRegEx: 'noSpecialCharsRegEx',
        upperCase: true,
      },
    },
    rank: { editable: true },
    role: { editable: true },
    emailAddress: { editable: true },
    mobileNumber: { editable: true },
    supervisorCode: { editable: true },
    homeStation: { editable: true },
    wards: { editable: true },
    skills: { editable: true },
    radioSsi: { editable: true },
    rfidCards: { editable: true },
    picture: { editable: true },
    visibleTo: { editable: true },
    leavingDate: { editable: true },
    groups: { editable: true },
    groupCodes: { editable: true },
    callSign: { editable: true },
  },
  locationForm = {
    code: {
      editable: false,
      componentProps: {
        maxLength: 30,
        validatorRegEx: 'noSpecialCharsRegEx',
      },
    },
    name: { editable: true, componentProps: { maxLength: 50 } },
    tranmanIdentifier: { editable: true },
    subtype: { editable: true },
    district: { editable: true },
    startTime: { editable: true },
    endTime: { editable: true },
    boundary: { editable: true },
    picture: { editable: true },
    groups: { editable: true },
    groupCodes: { editable: true },
  },
  objectiveForm = {
    identifier: {
      editable: false,
      componentProps: {
        maxLength: 30,
        validatorRegEx: 'noSpecialOrSpaceCharsRegEx',
      },
    },
    title: { editable: true, componentProps: { maxLength: 50 } },
    occurenceNumber: { editable: true },
    description: { editable: true },
    startTime: { editable: false },
    endTime: { editable: true },
    schedule: { editable: false },
    requiredVisits: { editable: false },
    requiredFrequency: { editable: false },
    complianceSeconds: { editable: false },
    visibleTo: { editable: true },
    applicableTo: { editable: false },
    boundary: { editable: false },
    resourceType: { editable: false },
  },
  liveOptions = {
    mapLayerToggleOnList: false,
    mapClusterDistance: 2,
    mapFollowBorder: true,
    mapFollowBorderSize: 1,
    mapFollowBorderSuperSize: false,
    mapFollowOverridesOutlineAndGlyph: false,
    mapFollowLabel: true,
    mapFocusLabel: true,
    vehicleFeedThreshold: 5,
    personFeedThreshold: 15,
    stalePersonThreshold: 60,
    staleVehicleIgnitionOnThreshold: 60,
    staleVehicleIgnitionOffThreshold: 10080,
    showFeedStatus: false,
    incidentLookbackMinutes: 525600,
    incidentLookbackExemptGrades: [1, 2],
    radioLookbackMinutes: 120,
    accelAlertLookbackMinutes: 120,
    dummyMovingVehicle: 10,
    offDutyNames: ['REST DAY', 'OFF DUTY'],
    disableRotatedIconsOnMobile: false,
    overriddenFilterLabels: { bcu: 'BCU' },
    vehiclesHaveCallsigns: false,
    showCallsignsOnLegend: false,
    excludedFilters: {
      vehicles: [
        'colour',
        'type',
        'callSign',
        'incident',
        'equipmentArray',
        'fleetNumber',
        'callSign',
        'incident',
        'status',
      ],
      locations: ['subtype'],
      people: [
        'code',
        'driverKeys',
        'reducedAreas.sector',
        'reducedAreas.operation',
      ],
      incidents: ['category', 'closingCodes', 'group', 'type'],
    },
    includedFilters: {},
    excludedSorts: {},
    includedSorts: {},
    callSignStatusCategory: {
      'At Court': 'Unavailable',
      Assigned: 'En Route To Incident',
      'At Scene': 'At Scene',
      Available: 'Available',
      Committed: 'Available',
      'De-Assigned': 'Available',
      Emergency: 'Emergency',
      'En Route To Incident': 'En Route To Incident',
      'In Custody': 'Unavailable',
      'In Office': 'Available',
      'Not Available': 'Unavailable',
      'Not Deployable': 'Unavailable',
      'Off Duty': 'Unavailable',
      'On Duty': 'Available',
      'Prisoner Escort': 'Unavailable',
      Refreshments: 'Unavailable',
      'Restricted Duty': 'Unavailable',
      'Resuming Patrol': 'Available',
      Unknown: 'Unclassified',
    },
    statusColours: {
      vehicles: {
        default: ['rgb(255,255,255)', 'rgb(0,0,0)', 'rgb(255,255,255)'],
      },
      callSigns: {
        'En Route To Incident': [
          'rgb(255,255,255)',
          'rgb(244,67,54)',
          'rgb(255,255,255)',
        ],
        Assigned: ['rgb(255,255,255)', 'rgba(255,152,0)', 'rgb(255,255,255)'],
        'At Scene': [
          'rgb(255,255,255)',
          'rgb(72, 176, 86)',
          'rgb(255,255,255)',
        ],
        Available: ['rgb(255,255,255)', 'rgb(0,0,0)', 'rgb(255,255,255)'],
        Unclassified: [
          'rgb(255,255,255)',
          'rgba(158,158,158)',
          'rgb(255,255,255)',
        ],
        Unavailable: [
          'rgba(255,255,255,1.0)',
          'rgba(156,39,176,1.0)',
          'rgba(255,255,255,1.0)',
        ],
      },
    },
    showSortFieldInListItems: true,
    showOverriddenLabelInListItems: true,
    labelOverridePaths: { vehicles: 'assignments.callSign.code' },
    leftOfFirstLineOfListItemsPaths: {},
    rightOfFirstLineOfListItemsPaths: {},
    leftOfSecondLineOfListItemsPaths: { callSigns: 'names' },
    rightOfSecondLineOfListItemsPaths: {
      people: 'assignments.callSign.code',
    },
    filterOptionsOverrides: {},
    mapPolygonIconsByTypeAndSubtype: {
      incident: 4,
      event: 3,
      location: 8,
      objective: 6,
    },
    incidentDetailGroupByCallsign: false,
  },
  driverBehaviourOptions = {
    goodTripScoreThreshold: 95,
    okTripScoreThreshold: 85,
  },
  options = {
    vehicle: [
      { label: 'VIN', name: 'identificationNumber' },
      { label: 'Registration', name: 'registrationNumber' },
      { label: 'Fleet Number', name: 'fleetNumber' },
      { label: 'Role', name: 'role' },
      { label: 'Type', name: 'type' },
      { label: 'Home Station', name: 'homeStation' },
      { label: 'Fuel Type', name: 'fuelType' },
      { label: 'Division', name: 'groups.division' },
    ],
    person: [
      { label: 'Staff ID', name: 'code' },
      { label: 'Username', name: 'emailAddress' },
      { label: 'Mobile Number', name: 'mobileNumber' },
      { label: 'Collar Number', name: 'collarNumber' },
      { label: 'Radio SSI', name: 'radioSsi' },
      { label: 'Supervisor ID', name: 'supervisorCode' },
      { label: 'Home Station', name: 'homeStation' },
      { label: 'Rank', name: 'rank.code' },
      { label: 'Role', name: 'role' },
      { label: 'Skills', name: 'skills.name' },
      { label: 'Wards', name: 'wards' },
      { label: 'BCU', name: 'groups.bcu' },
    ],
    location: [
      { label: 'Code', name: 'code' },
      { label: 'Name', name: 'name' },
      { label: 'Type', name: 'type' },
      { label: 'Subtype', name: 'subtype' },
      { label: 'Force', name: 'groups.force' },
    ],
    objective: [
      { label: 'Title', name: 'title' },
      { label: 'Occurrence Number', name: 'occurrenceNumber' },
      { label: 'Microbeat', name: 'microbeats' },
    ],
    rfidCard: [
      { label: 'Reference', name: 'reference' },
      { label: 'Category', name: 'category' },
    ],
    // incident: [
    //   { label: 'Grade', name: 'grade' },
    //   { label: 'Status', name: 'status' },
    //   { label: 'Type', name: 'type.code' },
    //   { label: 'Ward', name: 'ward.code' },
    //   { label: 'Sector', name: 'ward.groups.sector' },
    // ],
  },
  homeOtherSplit = false,
  reports = {
    vehicles: {
      daily: {
        defaultUsedUnusedUnavailableClassification: false,
        defaultChartType: 'hours',
      },
      hourly: {
        defaultUsedUnusedUnavailableClassification: false,
        defaultChartType: 'minutes',
      },
    },
  },
  odometerErrorExplanations = {
    R01: {
      short: 'Never received',
      long: 'There is no telematics box record for the linked IMEI. This occurs when the live IR3 system has never received a poll from that IMEI',
    },
    R02: {
      short: 'No timely poll',
      long: 'The next poll after the last odometer reading must be close in time to limit any untracked mileage which would throw the total out',
    },
    R03: {
      short: 'No previous poll',
      long: 'No poll found before odometer report date',
    },
    R04: {
      short: 'Latest poll before reading',
      long: 'The most recent poll for the box is before the poll found for the last odometer reading. This would typically happen when running the report in the past',
    },
  },
  retrospective = {
    estimation: { sampleSize: 100, sampleDays: 1, clusterConstant: 3 },
    limits: {
      default: {
        items: 3000000,
        data: 50000000,
        mapFeaturesSize: 100000,
        clusters: 150000,
      },
    },
    virtualizeAt: { mapFeaturesSize: 20000 },
    visitLocationTypes: ['Base', 'Ward', 'Workshop'],
    boundaryAreaOverlapThreshold: 0.05,
  },
  events = {
    showTripOccupants: true,
    tripOccupantsMinKilometresThreshold: 0.2,
    tripOccupantsMinPercentageThreshold: 10,
    itemDownloadLimit: 100000,
    eventFilters: {
      trips: [
        {
          name: 'classification',
          label: 'Classification',
          type: 'select',
          multiple: true,
          options: [
            { label: 'Business', value: 'Business' },
            { label: 'Personal', value: 'Personal' },
            { label: 'Commute', value: 'Commute' },
          ],
        },
        {
          name: 'distanceKilometres',
          label: 'Distance',
          type: 'number',
          modifiers: [
            { label: 'm', value: 1.60934, title: 'Mile(s)' },
            { label: 'km', value: 1, title: 'Kilometre(s)' },
          ],
        },
        {
          name: 'durationSeconds',
          label: 'Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
        {
          name: 'maxSpeedKilometresPerHour',
          label: 'Max Speed',
          type: 'number',
          modifiers: [
            { label: 'mph', value: 1.60934, title: 'Mile(s) per hour' },
            { label: 'km/h', value: 1, title: 'Kilometre(s) per hour' },
          ],
        },
        {
          name: 'equipmentActivations.emergencyOn',
          label: 'Warning Equipment Used',
          type: 'select',
          multiple: false,
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
        },
      ],
      stops: [
        {
          name: 'durationSeconds',
          label: 'Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
      ],
      idles: [
        {
          name: 'durationSeconds',
          label: 'Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
      ],
      speedInfractions: [
        {
          name: 'distanceKilometres',
          label: 'Distance',
          type: 'number',
          modifiers: [
            { label: 'm', value: 1.60934, title: 'Mile(s)' },
            { label: 'km', value: 1, title: 'Kilometre(s)' },
          ],
        },
        {
          name: 'durationSeconds',
          label: 'Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
        {
          name: 'maxSpeedKilometresPerHour',
          label: 'Max Speed',
          type: 'number',
          modifiers: [
            { label: 'mph', value: 1.60934, title: 'Mile(s) per hour' },
            { label: 'km/h', value: 1, title: 'Kilometre(s) per hour' },
          ],
        },
      ],
      accelerometerEvents: [
        {
          name: 'maximumForces.absolute',
          label: 'Max Force',
          type: 'number',
          unit: { label: 'G', title: 'Gravity' },
        },
      ],
      vehicleLocationVisits: [
        {
          name: 'distanceKilometres',
          label: 'Distance',
          type: 'number',
          modifiers: [
            { label: 'm', value: 1.60934, title: 'Mile(s)' },
            { label: 'km', value: 1, title: 'Kilometre(s)' },
          ],
        },
        {
          name: 'durationSeconds',
          label: 'Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
        {
          name: 'maxSpeedKilometresPerHour',
          label: 'Max Speed',
          type: 'number',
          modifiers: [
            { label: 'mph', value: 1.60934, title: 'Mile(s) per hour' },
            { label: 'km/h', value: 1, title: 'Kilometre(s) per hour' },
          ],
        },
      ],
      personTrails: [
        {
          name: 'durationSeconds',
          label: 'Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
      ],
      personLocationVisits: [
        {
          name: 'durationSeconds',
          label: 'Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
      ],
      attendances: [
        {
          name: 'compliant',
          label: 'Compliant',
          type: 'select',
          multiple: false,
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
        },
        {
          name: 'durationSeconds',
          label: 'Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
      ],
      vehicleEquipmentActivations: [
        {
          label: 'Equipment Type',
          name: 'eventSubtype',
          type: 'select',
          multiple: true,
          options: [
            { label: 'Beacons', value: 'BEACONSON' },
            { label: 'Headlights Flash', value: 'HEADLIGHTSFLASHON' },
            { label: 'Sirens', value: 'SIRENSON' },
          ],
        },
        {
          name: 'durationSeconds',
          label: 'Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
      ],
      personDoubleCrews: [
        {
          name: 'durationSeconds',
          label: 'Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
      ],
      incidentResponses: [
        {
          name: 'durationSeconds',
          label: 'Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
        {
          name: 'responseSeconds',
          label: 'Response Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
        {
          name: 'attended',
          label: 'Attended',
          type: 'select',
          multiple: false,
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
        },
        {
          name: 'attendedSeconds',
          label: 'Attended Duration',
          type: 'number',
          modifiers: [
            { label: 's', value: 1, title: 'Second(s)' },
            { label: 'm', value: 60, title: 'Minute(s)' },
            { label: 'h', value: 3600, title: 'Hour(s)' },
            { label: 'd', value: 86400, title: 'Day(s)' },
          ],
        },
      ],
    },
  },
  vehicleFieldsToShow = [
    { label: 'Make', name: 'make' },
    { label: 'Model', name: 'model' },
    { label: 'Role', name: 'role' },
    { label: 'Home Station', name: 'homeStation' },
    { label: 'Groups', name: 'groups' },
  ],
  mapLayers = [
    {
      title: 'Ordnance Survey Road',
      visible: true,
      sourceType: 'xyz',
      sourceOptions: {
        url: 'https://api.os.uk/maps/raster/v1/zxy/Road_3857/{z}/{x}/{y}.png?key=ATYMknYTBGj8kBTErHmHIuQ5mYMAU5tu',
      },
    },
    {
      title: 'Ordnance Survey Light',
      visible: false,
      sourceType: 'xyz',
      sourceOptions: {
        url: 'https://api.os.uk/maps/raster/v1/zxy/Light_3857/{z}/{x}/{y}.png?key=ATYMknYTBGj8kBTErHmHIuQ5mYMAU5tu',
      },
    },
    {
      title: 'Ordnance Survey Outdoor',
      visible: false,
      sourceType: 'xyz',
      sourceOptions: {
        url: 'https://api.os.uk/maps/raster/v1/zxy/Outdoor_3857/{z}/{x}/{y}.png?key=ATYMknYTBGj8kBTErHmHIuQ5mYMAU5tu',
      },
    },
    {
      title: 'Bing Road',
      visible: false,
      preload: null,
      sourceType: 'bing',
      sourceOptions: {
        key: 'AvVDNJYVo_13bnhapK-XtzCe9jL6vDG-G9EzGG9raMxWnToU2qQ26rKGz4SlAa2m',
        imagerySet: 'RoadOnDemand',
      },
    },
    {
      title: 'Bing Aerial (with labels)',
      visible: false,
      preload: null,
      sourceType: 'bing',
      sourceOptions: {
        key: 'AvVDNJYVo_13bnhapK-XtzCe9jL6vDG-G9EzGG9raMxWnToU2qQ26rKGz4SlAa2m',
        imagerySet: 'AerialWithLabelsOnDemand',
      },
    },
    {
      title: 'MapTiler Basic',
      visible: false,
      sourceType: 'tileJSON',
      sourceOptions: {
        url: 'https://api.maptiler.com/maps/basic/256/tiles.json?key=9m7RiHJttyiigsADWaEe',
        crossOrigin: 'anonymous',
      },
    },
    {
      title: 'MapTiler Hybrid',
      visible: false,
      sourceType: 'tileJSON',
      sourceOptions: {
        url: 'https://api.maptiler.com/maps/hybrid/256/tiles.json?key=9m7RiHJttyiigsADWaEe',
        crossOrigin: 'anonymous',
      },
    },
    {
      title: 'ESRI World Street Map',
      visible: false,
      sourceType: 'xyz',
      sourceOptions: {
        url: 'https://services.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
        attributions: [
          'Tiles © <a href="https://services.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer">ArcGIS</a>',
        ],
        attributionsCollapsible: false,
      },
    },
    {
      title: 'OpenStreetMap',
      visible: false,
      sourceType: 'xyz',
      sourceOptions: {
        url: 'https://osm.ir3.co.uk/osm/{z}/{x}/{y}.png',
        attributions: [
          '<a href="www.openstreetmap.org/copyright www.openstreetmap.org/copyright">© OpenStreetMap</a>',
        ],
        attributionsCollapsible: false,
      },
    },
    {
      title: 'Airbus Satellite',
      visible: false,
      sourceType: 'wmts',
      projection: 'EPSG:27700',
      sourceOptions: {
        url: 'https://ogc.apps.midgard.airbusds-cint.com/apgb/wmts?GUID=cf7e2094-7545-4977-b253-6007969c4f20&',
        layer: 'apgb:AP-25CM-GB-LATEST',
        matrixSet: 'BNG2',
        format: 'image/png',
        style: '',
        wrapX: false,
      },
      tileGridOptions: {
        origins: [
          [0, 1300000],
          [0, 1300000],
          [0, 1300000],
          [0, 1300000],
          [0, 1300000],
          [0, 1300000],
          [0, 1300000],
          [0, 1300000],
          [0, 1300000],
          [0, 1300000],
          [0, 1300000],
          [0, 1300000],
          [0, 1300000],
        ],
        resolutions: [
          2048, 1024, 512, 256, 100, 50, 25, 10, 5, 2.5, 1, 0.5, 0.25,
        ],
        matrixIds: [
          'BNG1:0',
          'BNG1:1',
          'BNG1:2',
          'BNG1:3',
          'BNG1:4',
          'BNG1:5',
          'BNG1:6',
          'BNG1:7',
          'BNG1:8',
          'BNG1:9',
          'BNG1:10',
          'BNG1:11',
          'BNG1:12',
        ],
      },
    },
  ],
  mapView = { center: [0, 0], zoom: 2, minZoom: 7, maxZoom: 20 },
  minZoom = 6,
  maxZoom = 18,
  maxBounds = [-10.76418, 49.528423, 1.9134116, 61.331151],
  ordnanceSurveyKey = 'ATYMknYTBGj8kBTErHmHIuQ5mYMAU5tu',
  styles = [
    {
      label: 'Ordnance Survey: Road',
      path: 'https://raw.githubusercontent.com/OrdnanceSurvey/OS-Vector-Tile-API-Stylesheets/master/OS_VTS_3857_Road.json',
      source: 'ORDNANCE_SURVEY',
    },
    {
      label: 'Ordnance Survey: Greyscale',
      path: 'https://raw.githubusercontent.com/OrdnanceSurvey/OS-Vector-Tile-API-Stylesheets/master/OS_VTS_3857_Greyscale.json',
      source: 'ORDNANCE_SURVEY',
    },
    {
      label: 'Ordnance Survey: Light',
      path: 'https://raw.githubusercontent.com/OrdnanceSurvey/OS-Vector-Tile-API-Stylesheets/master/OS_VTS_3857_Light.json',
      source: 'ORDNANCE_SURVEY',
    },
    {
      label: 'Ordnance Survey: Dark',
      path: 'https://raw.githubusercontent.com/OrdnanceSurvey/OS-Vector-Tile-API-Stylesheets/master/OS_VTS_3857_Dark.json',
      source: 'ORDNANCE_SURVEY',
    },
    {
      label: 'Protomaps',
      path: '/map-styles/protomaps.json',
      source: 'PROTOMAPS',
    },
    {
      label: 'MapTiler',
      path: '/map-styles/maptiler.json',
      source: 'MAPTILER',
    },
    {
      label: 'OpenStreetMap',
      path: '/map-styles/openstreetmap.json',
      source: 'OPENSTREETMAP',
    },
  ],
  tripClassifications = [
    {
      label: 'Business',
      value: 'Business',
      applicableSpeedRules: ['EXCESS'],
    },
    {
      label: 'Personal',
      value: 'Personal',
      applicableSpeedRules: [],
      exempt: true,
    },
    { label: 'Commute', value: 'Commute' },
  ],
  areaTypes = [
    { label: 'Base', value: 'Base' },
    { label: 'Boundary', value: 'Boundary' },
    { label: 'Region', value: 'Region' },
    { label: 'Sector', value: 'Sector' },
    { label: 'Ward', value: 'Ward' },
    { label: 'Workshop', value: 'Workshop' },
  ],
  areaTypeGroupOptions = {
    Ward: [
      { label: 'Sector', value: 'SECTOR' },
      { label: 'BCU', value: 'BUSINESS_COMMAND_UNIT' },
    ],
  },
  locationTypes = {
    policestations: {
      label: 'Police Station',
      header: 'Police Stations',
      value: 'Police Station',
      isBoundary: false,
      isHomeStation: true,
      subtypes: [
        { label: 'Headquarters', value: 'Headquarters' },
        { label: 'Custody Suite', value: 'Custody Suite' },
        { label: 'Police Station', value: 'Police Station' },
      ],
      d: 'M18,15H16V17H18M18,11H16V13H18M20,19H12V17H14V15H12V13H14V11H12V9H20M10,7H8V5H10M10,11H8V9H10M10,15H8V13H10M10,19H8V17H10M6,7H4V5H6M6,11H4V9H6M6,15H4V13H6M6,19H4V17H6M12,7V3H2V21H22V7H12Z',
    },
    bases: {
      label: 'Base',
      header: 'Bases',
      value: 'Base',
      isBoundary: false,
      isHomeStation: true,
      subtypes: [
        { label: 'Base', value: 'Base' },
        { label: 'Outstation', value: 'Outstation' },
      ],
      d: 'M18,15H16V17H18M18,11H16V13H18M20,19H12V17H14V15H12V13H14V11H12V9H20M10,7H8V5H10M10,11H8V9H10M10,15H8V13H10M10,19H8V17H10M6,7H4V5H6M6,11H4V9H6M6,15H4V13H6M6,19H4V17H6M12,7V3H2V21H22V7H12Z',
    },
    workshops: {
      label: 'Workshop',
      header: 'Workshops',
      value: 'Workshop',
      isBoundary: false,
      isHomeStation: false,
      subtypes: [
        { label: 'Force', value: 'Force' },
        { label: 'Supplier', value: 'Supplier' },
      ],
      d: 'M22.7,19L13.6,9.9C14.5,7.6 14,4.9 12.1,3C10.1,1 7.1,0.6 4.7,1.7L9,6L6,9L1.6,4.7C0.4,7.1 0.9,10.1 2.9,12.1C4.8,14 7.5,14.5 9.8,13.6L18.9,22.7C19.3,23.1 19.9,23.1 20.3,22.7L22.6,20.4C23.1,20 23.1,19.3 22.7,19Z',
    },
    hospitals: {
      label: 'Hospital',
      header: 'Hospitals',
      value: 'Hospital',
      isBoundary: false,
      isHomeStation: false,
      subtypes: [
        { label: 'Public', value: 'Public' },
        { label: 'Private', value: 'Private' },
      ],
      d: 'M18,14H14V18H10V14H6V10H10V6H14V10H18M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z',
    },
    courts: {
      label: 'Court',
      header: 'Courts',
      value: 'Court',
      isBoundary: false,
      isHomeStation: false,
      subtypes: [
        { label: 'County', value: 'County' },
        { label: 'Family', value: 'Family' },
        { label: 'Magistrates', value: 'Magistrates' },
        { label: 'Crown', value: 'Crown' },
        { label: 'High', value: 'High' },
        { label: 'Appeal', value: 'Appeal' },
        { label: 'Supreme', value: 'Supreme' },
      ],
      d: 'M11.5,1L2,6V8H21V6M16,10V17H19V10M2,22H21V19H2M10,10V17H13V10M4,10V17H7V10H4Z',
    },
    schools: {
      label: 'School',
      header: 'Schools',
      value: 'School',
      isBoundary: false,
      isHomeStation: false,
      subtypes: [
        { label: 'Primary', value: 'Primary' },
        { label: 'Secondary', value: 'Secondary' },
      ],
      d: 'M12,3L1,9L12,15L21,10.09V17H23V9M5,13.18V17.18L12,21L19,17.18V13.18L12,17L5,13.18Z',
    },
    wards: {
      label: 'Ward',
      header: 'Wards',
      value: 'Ward',
      isBoundary: true,
      isHomeStation: false,
      subtypes: [
        { label: 'Urban', value: 'Urban' },
        { label: 'Rural', value: 'Rural' },
      ],
      d: 'M14,6L10.25,11L13.1,14.8L11.5,16C9.81,13.75 7,10 7,10L1,18H23L14,6Z',
    },
    localAuthorities: {
      label: 'Local Authority',
      header: 'Local Authorities',
      value: 'Local Authority',
      isBoundary: true,
      isHomeStation: false,
      d: 'M14,6L10.25,11L13.1,14.8L11.5,16C9.81,13.75 7,10 7,10L1,18H23L14,6Z',
    },
    bcus: {
      label: 'BCU/LPA',
      header: 'BCUs/LPAs',
      value: 'BCU',
      isBoundary: true,
      isHomeStation: false,
      d: 'M14,6L10.25,11L13.1,14.8L11.5,16C9.81,13.75 7,10 7,10L1,18H23L14,6Z',
    },
    forceBoundaries: {
      label: 'Force Boundary',
      header: 'Force Boundaries',
      value: 'Force Boundary',
      isBoundary: true,
      isHomeStation: false,
      d: 'M14,6L10.25,11L13.1,14.8L11.5,16C9.81,13.75 7,10 7,10L1,18H23L14,6Z',
    },
  },
  personGroups = {
    force: {
      label: 'Force',
      values: [
        { label: 'Force 001', value: 'Force 001' },
        { label: 'Force 002', value: 'Force 002' },
        { label: 'Police Force 1', value: 'f1' },
      ],
    },
    bcu: {
      label: 'BCU',
      values: [
        { label: 'BCU 001', value: 'Bcu 001' },
        { label: 'BCU 002', value: 'Bcu 002' },
        { label: 'BCU 003', value: 'Bcu 003' },
        { label: 'BCU 004', value: 'Bcu 004' },
      ],
    },
    lpu: {
      label: 'LPU',
      values: [
        { label: 'City', value: 'city' },
        { label: 'Suburbs', value: 'suburbs' },
        { label: 'Rural', value: 'rural' },
      ],
    },
    area: {
      label: 'Area',
      values: [
        { label: 'Area 1', value: 'a1' },
        { label: 'Area 2', value: 'a2' },
        { label: 'Area 3', value: 'a3' },
        { label: 'Area 4', value: 'a4' },
        { label: '51', value: '51' },
        { label: 'Area 001', value: 'Area 001' },
      ],
    },
    district: {
      label: 'District',
      values: [
        { label: 'District 1', value: 'd1' },
        { label: 'District 2', value: 'd2' },
        { label: 'District 3', value: 'd3' },
        { label: 'District 4', value: 'd4' },
        { label: 'District 9', value: 'd9' },
      ],
    },
    sector: {
      label: 'Sector',
      values: [
        { label: 'Sector 001', value: 'Sector 001' },
        { label: 'Sector 002', value: 'Sector 002' },
        { label: 'SECTOR002', value: 'SECTOR002' },
        { label: 'SECTOR010', value: 'SECTOR010' },
        { label: 'SECTOR012', value: 'SECTOR012' },
      ],
    },
    operation: {
      label: 'Operation',
      values: [
        { label: 'Condor', value: 'condor' },
        { label: 'Chromite', value: 'chromite' },
        { label: 'Petticoat', value: 'petticoat' },
        { label: 'Finale', value: 'finale' },
        { label: 'Daybreak', value: 'daybreak' },
        { label: 'Operation 001', value: 'Operation 001' },
        { label: 'Operation 002', value: 'Operation 002' },
      ],
    },
    team: {
      label: 'Team',
      values: [
        { label: 'Team 1', value: 'Team 001' },
        { label: 'Team 2', value: 'Team 002' },
        { label: 'Team 3', value: 'Team 003' },
        { label: 'Team 4', value: 'Team 004' },
      ],
    },
    specialist: {
      label: 'Specialist',
      values: [
        { label: 'Specialist 1', value: 'Specialist 001' },
        { label: 'Specialist 2', value: 'Specialist 002' },
        { label: 'Specialist 8', value: 'Specialist 008' },
        { label: 'Specialist 11', value: 'Specialist 011' },
        { label: 'Specialist 12', value: 'Specialist 012' },
        { label: 'Specialist 13', value: 'Specialist 013' },
      ],
    },
    directorate: {
      label: 'Directorate',
      values: [
        { label: 'Directorate 1', value: 'd1' },
        { label: 'Directorate 2', value: 'd2' },
        { label: 'Directorate 3', value: 'd3' },
        { label: 'Directorate 4', value: 'd4' },
        { label: 'Directorate 5', value: 'd5' },
      ],
    },
    unit: {
      label: 'Unit',
      values: [{ label: 'Special Victims', value: 'svu' }],
    },
    shift: {
      label: 'Shift',
      values: [
        { label: 'Morning', value: 'm' },
        { label: 'Evening', value: 'e' },
        { label: 'Night', value: 'n' },
      ],
    },
    department: {
      label: 'Department',
      values: [
        { label: 'Department 1', value: 'dp1' },
        { label: 'Department 2', value: 'dp2' },
        { label: 'Department 3', value: 'dp3' },
        { label: 'Department 4', value: 'dp4' },
        { label: 'Department 5', value: 'dp5' },
      ],
    },
  },
  vehicleGroups = {
    force: {
      label: 'Force',
      values: [{ label: 'Police Force 1', value: 'f1' }],
    },
    division: {
      label: 'Division',
      values: [
        { label: 'Premier', value: 'prem' },
        { label: 'First', value: '1st' },
        { label: 'Second', value: '2nd' },
        { label: 'Third', value: '3rd' },
        { label: 'Fourth', value: '4th' },
        { label: 'Conference', value: 'conf' },
        { label: 'Division 1', value: 'Division 001' },
        { label: 'Division 2', value: 'Division 002' },
        { label: 'Division 3', value: 'Division 003' },
        { label: 'Division 4', value: 'Division 004' },
        { label: 'Division 5', value: 'Division 005' },
      ],
    },
  },
  locationGroups = {
    force: {
      label: 'Force',
      values: [
        { label: 'Force 001', value: 'Force 001' },
        { label: 'Force 002', value: 'Force 002' },
      ],
    },
  },
  incidentGroups = {
    force: {
      label: 'Force',
      values: [
        { label: 'Force 001', value: 'Force 001' },
        { label: 'Force 002', value: 'Force 002' },
      ],
    },
  },
  personSkills = {
    skill: {
      label: 'Skill',
      values: [
        { label: 'Skill 1', value: 'skill1' },
        { label: 'Skill 2', value: 'skill2' },
        { label: 'Skill 3', value: 'skill3' },
        { label: 'Skill 4', value: 'skill4' },
        { label: 'Skill 5', value: 'skill5' },
      ],
    },
  },
  vehicleEquipment = [
    { label: 'Equipment 1', value: 'equipment1' },
    { label: 'Equipment 2', value: 'equipment2' },
    { label: 'Equipment 3', value: 'equipment3' },
    { label: 'Equipment 4', value: 'equipment4' },
    { label: 'Equipment 5', value: 'equipment5' },
  ],
  personRoles = [
    { label: 'Person Role 1', value: 'Role 001' },
    { label: 'Person Role 2', value: 'Role 002' },
    { label: 'Person Role 3', value: 'Role 003' },
    { label: 'Person Role 4', value: 'Role 004' },
    { label: 'Person Role 5', value: 'Role 005' },
  ],
  vehicleRoles = [
    { label: 'Vehicle Role 22', value: 'Role 022' },
    { label: 'Vehicle Role 30', value: 'Role 030' },
    { label: 'Vehicle Role 46', value: 'Role 046' },
    { label: 'Vehicle Role 50', value: 'Role 050' },
    { label: 'Vehicle Role 57', value: 'Role 057' },
    { label: 'Vehicle Role 60', value: 'Role 060' },
    { label: 'Vehicle Role 64', value: 'Role 064' },
  ],
  vehicleTypes = [
    { label: 'Car', value: 'Car' },
    { label: 'Van', value: 'Van' },
    { label: '4x4', value: '4x4' },
    { label: 'Bus', value: 'Bus' },
    { label: 'Motorcycle', value: 'Motorcycle' },
  ],
  vehicleFuelTypes = [
    { label: 'Petrol', value: 'Petrol' },
    { label: 'Diesel', value: 'Diesel' },
    { label: 'Electric', value: 'Electric' },
  ],
  personRanks = [
    { level: 1, code: 'STAFF', name: 'Staff Member' },
    { level: 1, code: 'PC', name: 'Constable' },
    { level: 2, code: 'SGT', name: 'Sergeant' },
    { level: 3, code: 'INSP', name: 'Inspector' },
    { level: 4, code: 'CINSP', name: 'Chief Inspector' },
    { level: 5, code: 'SUPT', name: 'Superintendent' },
    { level: 6, code: 'CSUPT', name: 'Chief Superintendent' },
    { level: 7, code: 'ACC', name: 'Assistant Chief Constable' },
    { level: 8, code: 'DCC', name: 'Deputy Chief Constable' },
    { level: 9, code: 'CC', name: 'Chief Constable' },
  ],
  incidentGrades = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
  ],
  radioTypes = [
    { label: 'Personal Issue', value: 'Personal Issue' },
    { label: 'Pool', value: 'Pool' },
    { label: 'Vehicle Fitted', value: 'Vehicle Fitted' },
  ],
  rfidCardTypes = [
    { label: 'Personal Issue', value: 'Personal Issue' },
    { label: 'Pool', value: 'Pool' },
  ],
  activeDirectoryGroups = [
    { label: 'AD1', value: 'AD1' },
    { label: 'AD2', value: 'AD2' },
    { label: 'IR3 Admin', value: 'IR3 Admin' },
  ],
  hiddenRoutes = [],
  highlights = {
    vehicleExcessiveSummariesChart: {
      ignitionOnPeriodDays: { default: 28, min: 1, max: 50 },
      excessPercentageThreshold: 50,
    },
    objectiveCompliance: {
      defaultGroupBy: 'sector',
      lookbackDays: 28,
      defaultResourceType: 'people',
      defaultMaxCompliancePercent: 100,
    },
    averageInStationTime: { highlightSize: 5, lookbackDays: 28 },
    subgroupActivityAverages: { lookbackDays: 28 },
    totalIncidents: { lookbackDays: 28 },
    responseTimeByHour: { lookbackDays: 28 },
    totalAttendedIncidents: { lookbackDays: 28 },
  },
  announcement,
  maintenance,
  nominatimRootUrl = 'https://nominatim.ir3.dev',
  eventNotifications = {},
  briefingApiRootUrl = 'http://localhost:3000',
  interCrimeFilters = {},
  vehicleAttributes = [],
  personAttributes = [],
  locationAttributes = [],
  showClearCacheButton = false,
  suspectUpdateGroups = [],
} = environmentConfig ?? {};

import { api } from '@/apis';
import { getNewIdentifier, log } from '@/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertGroup() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ _id, ancestors: _, ...json }) => {
      if (_id) {
        log('Update', 'Group', json);

        return api.put(`groups/${json.code}`, { json }).json();
      } else {
        const code = await getNewIdentifier(`${json.type}_`);

        log('Create', 'Group', { code, ...json });

        return api.post('groups', { json: { code, ...json } }).json();
      }
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['groups'] }),
  });
}

import {
  Avatar,
  FormControlLabel,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  LocationOn as LocationOnIcon,
  KeyboardDoubleArrowUp as KeyboardDoubleArrowUpIcon,
} from '@mui/icons-material';
import { format, formatDistanceToNow } from 'date-fns';
import {
  ArrowDown as ArrowDownIcon,
  ArrowUp as ArrowUpIcon,
} from 'mdi-material-ui';
import defaultPersonImage from '@/img/defaultPhotoImage.jpeg';
import { useAuth, useOccurrenceOic, useOfficer, useUser } from '@/hooks';
import { Fragment } from 'react';
import { personFlagTypes } from '@/utils/constants';
import { getOfficerTeam } from '@/utils';
import { suspectUpdateGroups } from '@/utils/config';

export function Suspect({
  suspect,
  onPriorityClick,
  priority,
  onShowLocationClick,
  onCirculateFieldClick,
}) {
  function handlePriorityClick() {
    onPriorityClick(suspect.person?.nominalId, priority);
  }

  function handleCirculateFieldClick(e) {
    const highPrioritySuspect = { ...suspect };
    delete highPrioritySuspect.occurrence;
    delete highPrioritySuspect.person;
    onCirculateFieldClick(highPrioritySuspect, e.target.name);
  }

  const { data: user } = useUser(suspect?.edited?.userCode);
  const { data: oicAndOel } = useOccurrenceOic(suspect.occurrence.number);
  const { data: officer } = useOfficer(oicAndOel?.oic);

  const auth = useAuth();
  const userGroups = auth.getGroupNames();
  const canDownGrade = suspectUpdateGroups.some((r) => userGroups.includes(r));

  return (
    <Card variant="outlined" sx={{ minWidth: 240 }}>
      <CardHeader
        avatar={
          <PersonFlagTypeAvatar
            personFlagType={suspect.person?.personFlagType}
          />
        }
        action={
          <Fragment>
            <Tooltip title="Suspect home address">
              <IconButton onClick={onShowLocationClick}>
                <LocationOnIcon />
              </IconButton>
            </Tooltip>
            {canDownGrade && (
              <Tooltip
                title={
                  priority === 'high'
                    ? 'Remove from high priority'
                    : 'Add to high priority'
                }
              >
                <IconButton onClick={handlePriorityClick}>
                  {priority === 'high' ? <ArrowDownIcon /> : <ArrowUpIcon />}
                </IconButton>
              </Tooltip>
            )}
          </Fragment>
        }
        subheader={priority === 'high' ? suspect.occurrence?.sector : ''}
      />
      {suspect.person.image ? (
        <CardMedia
          component="img"
          // height="140"
          image={suspect.person.image}
          alt={suspect.person.firstName}
        />
      ) : (
        <CardMedia
          component="img"
          sx={{ maxWidth: 240 }}
          src={defaultPersonImage}
        />
      )}
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {suspect.person.firstName} {suspect.person.surname}
        </Typography>
        <Stack direction="row" spacing={0} justifyContent="space-between">
          <Stack direction="row" spacing={0.1} flexDirection="column">
            <Tooltip title="Occurrence Number" placement="left-start">
              <Typography
                variant="body3"
                color="text.secondary"
                gutterBottom
                sx={{ flexGrow: 1 }}
              >
                {suspect.occurrence?.number}
              </Typography>
            </Tooltip>
            <Tooltip title="Occurrence Date" placement="left-start">
              <Typography variant="caption" sx={{ mt: -1, mb: 0.5 }}>
                {suspect.occurrence?.startTime}
              </Typography>
            </Tooltip>
            {priority === 'high' && (
              <Typography variant="caption" sx={{ mt: -1, mb: 0.5 }}>
                Apprehended:{' '}
                {suspect.person?.classification.includes('ARR') ? 'Yes' : 'No'}
              </Typography>
            )}
          </Stack>
          {oicAndOel?.oic && (
            <Stack direction="column">
              <Tooltip
                title={getOfficerTeam(officer, oicAndOel?.owningTeam)}
                placement="left-start"
              >
                <KeyboardDoubleArrowUpIcon fontSize="small" />
                <Typography
                  variant="caption"
                  color="text.secondary"
                  gutterBottom
                >
                  {oicAndOel?.oic}
                </Typography>
              </Tooltip>
            </Stack>
          )}
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          sx={{ mt: 1 }}
          justifyContent="space-between"
        >
          <Typography variant="body1" gutterBottom sx={{ flexGrow: 1 }}>
            {suspect.occurrence.classificationDescription}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Typography
            variant="caption"
            color="text.secondary"
            gutterBottom
            sx={{ flexGrow: 1 }}
          >
            Last OEL entry:{' '}
            {oicAndOel?.lastOelEntry
              ? format(new Date(oicAndOel?.lastOelEntry), 'dd-MM-yyyy HH:mm:ss')
              : ''}
          </Typography>
          <Stack direction="row">
            <Typography variant="caption" color="text.secondary" gutterBottom>
              Last victim update:{' '}
              {suspect.occurrence.victimPoliceReportNotifiedTime}
            </Typography>
          </Stack>
        </Stack>
        {priority === 'high' && (
          <Stack direction="column">
            <FormControlLabel
              componentsProps={{ typography: { variant: 'caption' } }}
              sx={{ padding: -10 }}
              control={
                <Checkbox
                  checked={suspect?.pncCirculated}
                  onChange={handleCirculateFieldClick}
                  name="pncCirculated"
                  size="small"
                />
              }
              label="PNC Circulated"
            />
            <FormControlLabel
              componentsProps={{ typography: { variant: 'caption' } }}
              control={
                <Checkbox
                  checked={suspect?.fileReady}
                  onChange={handleCirculateFieldClick}
                  name="fileReady"
                  size="small"
                />
              }
              label="File Ready"
            />
            <FormControlLabel
              componentsProps={{ typography: { variant: 'caption' } }}
              control={
                <Checkbox
                  checked={suspect?.socialMediaCirculated}
                  onChange={handleCirculateFieldClick}
                  name="socialMediaCirculated"
                  size="small"
                />
              }
              label="Social Media Circulated"
            />
            <Typography variant="caption" color="text.secondary" gutterBottom>
              {user
                ? `${user?.name} - ${formatDistanceToNow(
                    new Date(suspect?.edited?.time),
                  )} ago`
                : '-'}
            </Typography>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}

function getType(type) {
  switch (type) {
    case 'PRC':
      return 0;
    default:
      return 1;
  }
}

function PersonFlagTypeAvatar({ personFlagType }) {
  const typeIndex = getType(personFlagType);
  const { label, color, Icon } = personFlagTypes[typeIndex];

  return (
    <Tooltip title={`${label}`}>
      <Avatar
        sx={{
          bgcolor: color,
          color: (theme) => theme.palette.getContrastText(color),
        }}
      >
        <Icon />
      </Avatar>
    </Tooltip>
  );
}

import { api } from '@/apis';
import { highlights } from '@/utils/config';
import { useQuery } from '@tanstack/react-query';
import { startOfDay, subDays } from 'date-fns';

const {
  vehicleExcessiveSummariesChart: { excessPercentageThreshold },
} = highlights;

export function useExcessiveAccelerometerReadings(ignitionOnPeriodDays) {
  const toDate = startOfDay(new Date());
  const fromDate = subDays(toDate, ignitionOnPeriodDays);

  return useQuery({
    queryKey: ['excessiveAccelerometerReadingsSummary', fromDate, toDate],
    queryFn: () => {
      const json = [
        { $match: { endTime: { $gte: fromDate, $lt: toDate } } },
        {
          $group: {
            _id: {
              identificationNumber: '$vehicle.identificationNumber',
              type: '$vehicle.type',
            },
            excessAccelerationDurationSeconds: {
              $sum: {
                $add: [
                  '$excessAccelerationDurationSeconds',
                  '$excessAccelerationWithEmergencyEquipmentDurationSeconds',
                ],
              },
            },
            excessBrakingDurationSeconds: {
              $sum: {
                $add: [
                  '$excessBrakingDurationSeconds',
                  '$excessBrakingWithEmergencyEquipmentDurationSeconds',
                ],
              },
            },
            excessCorneringDurationSeconds: {
              $sum: {
                $add: [
                  '$excessCorneringDurationSeconds',
                  '$excessCorneringWithEmergencyEquipmentDurationSeconds',
                ],
              },
            },
            durationSeconds: { $sum: '$durationSeconds' },
          },
        },
        {
          $addFields: {
            totalExcessSeconds: {
              $add: [
                '$excessAccelerationDurationSeconds',
                '$excessBrakingDurationSeconds',
                '$excessCorneringDurationSeconds',
              ],
            },
          },
        },
        {
          $addFields: {
            durationHours: { $divide: ['$durationSeconds', 3600] },
            accelerationPercentageInExcess: {
              $multiply: [
                100,
                {
                  $divide: [
                    '$excessAccelerationDurationSeconds',
                    '$durationSeconds',
                  ],
                },
              ],
            },
            brakingPercentageInExcess: {
              $multiply: [
                100,
                {
                  $divide: [
                    '$excessBrakingDurationSeconds',
                    '$durationSeconds',
                  ],
                },
              ],
            },
            corneringPercentageInExcess: {
              $multiply: [
                100,
                {
                  $divide: [
                    '$excessCorneringDurationSeconds',
                    '$durationSeconds',
                  ],
                },
              ],
            },
            totalPercentageInExcess: {
              $multiply: [
                100,
                { $divide: ['$totalExcessSeconds', '$durationSeconds'] },
              ],
            },
          },
        },
        {
          $match: {
            //is a threshold to limit the results by the configuartion in default.js is returned
            totalPercentageInExcess: { $gte: excessPercentageThreshold },
          },
        },
        {
          $project: {
            _id: false,
            identificationNumber: '$_id.identificationNumber',
            type: '$_id.type',
            durationHours: true,
            percentageInExcess: {
              total: '$totalPercentageInExcess',
              acceleration: '$accelerationPercentageInExcess',
              braking: '$brakingPercentageInExcess',
              cornering: '$corneringPercentageInExcess',
            },
          },
        },
        { $sort: { identificationNumber: 1 } },
      ];

      return api
        .post('pipeline/vehicleTripAccelerationSummaries', { json })
        .json();
    },
    placeholderData: [],
    // staleTime: 25 * (60 * 1000), // only refetch every 25 minutes
    // cacheTime: 30 * (60 * 1000), // keep data in cache for 30 minutes
  });
}

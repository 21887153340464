import { db } from '@/data';
import _ from 'lodash';
import { Point } from 'ol/geom';
import GeometryCollection from 'ol/geom/GeometryCollection';
import { Tile as TileLayer } from 'ol/layer';
import { BingMaps, TileJSON, WMTS, XYZ } from 'ol/source';
import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
import resourceShape from '../img/resource.png';
import resourceShapeNoDir from '../img/resource_no_dir.png';
import {
  mapGlyphsByTypeAndSubtype,
  mapPolygonIconsByTypeAndSubtype,
  positionGlyph,
  replayTypeGlyphs,
  statusIconColoursByType,
} from './constants';
// import { transformExtent } from 'ol/proj';
import { dequal } from 'dequal';
import { View } from 'ol';
import { get as getProjection } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import proj4 from 'proj4';
import { liveOptions, mapView } from './config';

const followColour = liveOptions.followColor || '#1992F0BB'; // || 'rgb(0,0,255)';

let mergedCache = {};

export function getMapView() {
  return new View(mapView);
}

const colorAndScaleImage = (source, color, scale = 1) => {
  return new Promise((resolve, reject) => {
    // given an image source, colour and scale it in a promise returning an Image object
    const canvas = window.document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (source.startsWith('poly')) {
      const sides = parseInt(source.substring(4));
      let angle = (2 * Math.PI) / sides;

      canvas.width = 64;
      canvas.height = 64;

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // some tweaks so the shapes line up with the icons
      const startAngle = sides % 2 === 0 ? angle / 2 : Math.PI * 1.5 - angle;
      let shapeScale = sides < 5 ? 1.2 : 1;
      const translate = sides === 3 ? 4 : 0;

      ctx.fillStyle = color;
      for (let i = 0; i < sides; i++) {
        const a = startAngle + angle * i;
        ctx.arc(32, 32 + translate, 28 * scale * shapeScale, a, a);
      }
      ctx.fill();

      resolve(canvas);
    } else {
      const img = new Image();

      img.onerror = (e) => {
        return reject(new Error('Could not load image', e));
      };
      img.onload = () => {
        canvas.width = img.width * scale;
        canvas.height = img.height * scale;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        ctx.fillStyle = color;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // fill it with desired colour then only set the non-transparent pixels
        // in the following drawImage, taken from
        // https://stackoverflow.com/questions/45706829/change-color-image-in-canvas
        ctx.globalCompositeOperation = 'destination-in';

        ctx.scale(scale, scale);
        ctx.drawImage(img, 0, 0);

        resolve(canvas);
      };

      // this will kick off img.onload to color and scale
      img.src = source;
    }
  });
};

// the smaller this is, the more icons there will be,
// for an accuracy of 2 degrees there will be 360/2 === 180 icons
// other e.g.s accuracy of 1: 360 icons, accuracy of 6: 60 icons, accuracy of 4: 90 icons
const rotationAccuracy = 6;
const roundRotation = (degrees) =>
  (Math.round(degrees / rotationAccuracy) * rotationAccuracy) % 360;

// this merges images together into an icon
// e.g. rotate a background pointer 60 degrees & merge a (non-rotated) vehicle icon on top
const mergeImages = (
  id,
  sources = [],
  colors = [],
  scales = [],
  rotationDegrees = null,
  immediate = true,
) => {
  if (sources.length !== colors.length || colors.length !== scales.length) {
    throw new Error('Mismatched image source, colour and scale arrays');
  }

  if (id in mergedCache) {
    if (rotationDegrees !== null && mergedCache[id] !== null) {
      rotationDegrees = roundRotation(rotationDegrees);
      if (rotationDegrees in mergedCache[id]) {
        return mergedCache[id][rotationDegrees];
      } else {
        return mergedCache[id];
      }
    } else {
      return mergedCache[id];
    }
  }

  // don't let other requests spawn a new merge
  mergedCache[id] = null;

  const promise = new Promise((resolve) => {
    const imagePromises = [];
    for (var i = 0; i < sources.length; i++) {
      const promise = colorAndScaleImage(sources[i], colors[i], scales[i]);
      imagePromises.push(promise);
    }

    // get canvas context
    const canvas = window.document.createElement('canvas');
    canvas.width = 64;
    canvas.height = 64;
    const ctx = canvas.getContext('2d');
    ctx.globalCompositeOperation = 'source-over';

    const centreImage = (image) =>
      ctx.drawImage(
        image,
        (canvas.width - image.width) / 2,
        (canvas.height - image.height) / 2,
      );

    // when all images have loaded
    resolve(
      Promise.all(imagePromises).then((images) => {
        let imgData = null;
        if (rotationDegrees === null) {
          // write each image sequentially to the canvas (background then foreground typically)
          images.forEach((image) => {
            centreImage(image);
          });

          // use the merged images as the source for a map icon
          imgData = canvas.toDataURL('image/png');

          // store it in our own cache
          mergedCache[id] = new Icon({
            opacity: 1,
            src: imgData,
          });
        } else {
          let rotMap = {};
          // same as above but generate and cache 360/rotationAccuracy versions
          // to cover all our rotation needs.
          for (let r = 0; r < 360; r += rotationAccuracy) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            for (let i = 0; i < images.length; i++) {
              let image = images[i];
              // rotate the background images not the foreground glyph
              if (i !== images.length - 1) {
                ctx.save();

                ctx.translate(canvas.width / 2, canvas.height / 2);
                ctx.rotate((r * Math.PI) / 180);
                ctx.translate(-canvas.width / 2, -canvas.height / 2);

                centreImage(image);
                ctx.restore();
              } else {
                centreImage(image);
              }
            }

            imgData = canvas.toDataURL('image/png');
            rotMap[r] = new Icon({
              opacity: 1,
              src: imgData,
            });
          }

          // this cache element is a dictionary of all icons
          mergedCache[id] = rotMap;
        }

        return imgData;
      }),
    );
  });

  if (immediate) {
    // kick off the promise, it will cache the result for another render
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    promise.then(() => {}); //console.log('completed ' + img));

    // but we don't have anything right at this moment
    return null;
  } else {
    return promise;
  }
};

export const boundaryStyle = new Style({
  fill: new Fill({
    color: 'rgba(255,255,255,0.5)',
  }),
  stroke: new Stroke({
    color: '#ffffff',
    width: 2,
  }),
  zIndex: 0,
});

export const positionStyle = [
  new Style({
    image: new Circle({
      radius: 8,
      fill: new Fill({
        color: 'rgb(118,255,3)',
      }),
    }),
  }),
  new Style({
    image: new Icon({
      src: positionGlyph,
      color: 'rgb(0,0,0)',
      scale: 0.5,
    }),
  }),
];

export const confidenceRadiusStyle = new Style({
  fill: new Fill({
    color: 'rgba(118,255,3,0.5)',
  }),
  stroke: new Stroke({
    color: 'rgb(118,255,3)',
    width: 2,
  }),
});

export function getStyle(layer, feature, showLabels) {
  if (showLabels) {
    staticStyle
      .getText()
      .setText(feature.get('number') || feature.get('identifier'));
  } else {
    staticStyle.getText().setText('');
  }

  if (feature.getGeometry() && feature.getGeometry().getType() === 'Point') {
    staticStyle.getText().setTextAlign('left');
    staticStyle.getText().setOffsetX(12);
  } else {
    staticStyle.getText().setTextAlign('center');
    staticStyle.getText().setOffsetX(0);
  }

  switch (feature.getGeometry() && feature.getGeometry().getType()) {
    case 'Polygon':
      staticStyle.setZIndex(1);
      break;
    case 'Linestring':
      staticStyle.setZIndex(2);
      break;
    case 'Point':
      staticStyle.setZIndex(3);
      break;
    default:
      staticStyle.setZIndex(0);
  }

  return [staticStyle];
}

// blank one used while mergeImages is working through the backlog
var blankIcon = new Icon({
  opacity: 1,
  src: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
});

function addTransparency(hexColour, hexTransparency) {
  if (hexColour.startsWith('rgb')) {
    const parts = hexColour.split(/[\s,()]+/).filter(Boolean);
    return `rgba(${parts.splice(1, 3).toString()}, ${
      parseInt(hexTransparency, 16) / 256
    })`;
  }

  // change #abc to #aabbcc
  if (hexColour.length === 4) {
    hexColour = hexColour
      .split('')
      .map((c, i) => (i > 0 ? c + c : c))
      .join('');
  }

  if (hexColour.length === 7 && hexTransparency.length === 2) {
    return hexColour + hexTransparency;
  } else {
    throw new Error('Invalid hex colour in mapStyles icon colour config');
  }
}

const statusFillColoursByType = Object.fromEntries(
  Object.keys(statusIconColoursByType).map((type) => [
    type,
    Object.fromEntries(
      Object.keys(statusIconColoursByType[type]).map((status) => [
        status,
        [
          statusIconColoursByType[type][status][0],
          addTransparency(statusIconColoursByType[type][status][1], '40'),
        ],
      ]),
    ),
  ]),
);

function mostDetailed(byTypeStatus, type, status) {
  return (
    byTypeStatus[type]?.[status] ?? // vehicle.emergency
    byTypeStatus.default[status] ?? // hover/select
    byTypeStatus[type]?.default ?? // vehicle
    byTypeStatus.default.default // default
  );
}

export function getStatusIconColours(type, status) {
  return mostDetailed(statusIconColoursByType, type, status);
}

function getStatusFillColours(type, status) {
  return mostDetailed(statusFillColoursByType, type, status);
}

const layerColours = {
  default: {
    background: 'rgb(0,0,0)',
    icon: 'rgb(255,255,255)',
    fill: 'rgba(0,0,0,0.5)',
    scale: 1,
  },
  select: {
    background: 'rgb(33,150,243)',
    //inactiveBackground: '#01579b',
    //emergencyBackground: '#ffbf00',
    icon: 'rgb(255,255,255)',
    fill: 'rgba(33,150,243,0.5)',
    scale: 1,
  },
  hover: {
    background: 'rgb(255,235,59)',
    //inactiveBackground: 'rgb(255,235,59)',
    //emergencyBackground: '#ffbf00',
    icon: 'rgb(0,0,0)',
    fill: 'rgba(255,235,59,0.5)',
    scale: 1,
  },
  path: {
    background: 'rgba(0,0,0,0.25)',
  },
};

const mergeImagesWrapper = (
  type,
  subtype,
  status,
  filteredIn,
  followed,
  rotation = null,
  immediate = true,
) => {
  // outline should be glyphColour
  let [outlineColour, backgroundColour, glyphColour] = getStatusIconColours(
    type,
    status,
  );

  if (liveOptions.mapFollowOverridesOutlineAndGlyph && followed) {
    glyphColour = followColour;
  }

  const selectionAsBorder = false; // liveOptions && liveOptions.mapSelectionAsBorder;
  const selectionColour = undefined; // no selection as border, selection overwrites status
  const borderOutline = liveOptions.mapFollowBorder && followed;
  // !!liveOptions?.mapIconOutline &&
  // selectionAsBorder &&
  // selectionColour &&
  // filteredIn;
  const outline = true; //liveOptions && liveOptions.mapIconOutline && filteredIn;

  const numberOfBackgrounds =
    (borderOutline ? 1 : 0) +
    (selectionAsBorder && selectionColour ? 1 : 0) +
    (outline ? 1 : 0) +
    1; // need at least one for background to icon/glyph

  // the resource and resource_no_dir images are about 600px so scale to 64px
  let savedImageScale = 3 / 25;

  const backgroundPoly =
    mapPolygonIconsByTypeAndSubtype[type]?.[subtype] ||
    mapPolygonIconsByTypeAndSubtype[type]?.default ||
    mapPolygonIconsByTypeAndSubtype[type];
  let background;
  if (backgroundPoly > 2) {
    background = 'poly' + backgroundPoly;
    savedImageScale = 1;
  } else if (rotation !== null) {
    background = resourceShape;
  } else {
    background = resourceShapeNoDir;
  }

  const outlineRatio = (1 / 12) * savedImageScale;
  const borderRatio =
    (1 / 12) * (liveOptions.mapFollowBorderSize || 1) * savedImageScale;
  const selectionBorderRatio = (2 / 12) * savedImageScale;

  // base background scale will be built up as outline, border and borderOutline added
  let backgroundScale =
    1 * savedImageScale -
    (outline ? outlineRatio : 0) -
    (borderOutline ? borderRatio : 0) -
    (selectionAsBorder ? selectionBorderRatio : 0);

  subtype = subtype || 'default'; // to makes sure preload and getStyle are consistent

  const baseGlyph = mapGlyphsByTypeAndSubtype[type];
  const scale = liveOptions.mapFollowBorderSuperSize ? 0.5 : 1;

  return mergeImages(
    // type + filteredIn + statusColour + selectionColour, // id
    type + subtype + status + !!filteredIn + !!followed, // id
    [
      // img sources for back and foreground
      ...Array(numberOfBackgrounds).fill(background),
      // replayTypeGlyphs[type]],
      baseGlyph && (baseGlyph[subtype] || baseGlyph['default']),
    ], // sources
    [
      ...[
        borderOutline && followColour, //glyphColour,
        selectionAsBorder && selectionColour,
        // maybe outline && (followed ? 'rgba(33,150,243,1.0)' : glyphColour),
        outline && outlineColour,
        selectionAsBorder
          ? backgroundColour
          : selectionColour || backgroundColour,
      ].filter(Boolean),
      selectionAsBorder ? 'rgba(255,255,255,1.0)' : glyphColour,
    ], // colours for back and foreground
    // this is backwards so we can build it up from the inside out
    [
      1 * scale, // the icon scale
      backgroundScale * scale,
      // maybe outline && (backgroundScale += followed ? 0.04 : 0.01),
      outline && (backgroundScale += outlineRatio) * scale,
      selectionAsBorder &&
        selectionColour &&
        (backgroundScale += selectionBorderRatio) * scale,
      borderOutline &&
        (backgroundScale += borderRatio) *
          scale *
          (liveOptions.mapFollowBorderSuperSize ? 1.4 : 1),
    ]
      .filter(Boolean)
      .reverse(),
    rotation,
    immediate,
  );
};

// a helper to transform the db/ol cache types
function transform({ source, hasRotationsTest, action }) {
  let result = {};

  Object.keys(source).forEach((key) => {
    if (source[key]) {
      // for items with rotations they have a dictionary for each rotation
      // e.g. { 0: Icon ..., 6: Icon ..., ... }
      if (hasRotationsTest(source[key])) {
        result[key] = {};

        // if it does have rotations we'll have to recreate the dictionary
        // performing the transform action on each one
        Object.keys(source[key]).forEach((rotation) => {
          result[key][rotation] = action(source[key][rotation]);
        });
      } else {
        result[key] = action(source[key]);
      }
    } else {
      result[key] = null;
    }
  });

  return result;
}

// it helps to load all the possible images before rendering
// otherwise a refresh with a new icon will be held up while mergeImages is generating
// the new Icon(s)
var rotatingTypes = ['vehicle'];
// var inactiveTypes = ['vehicle'];
// var inactiveKeys = { vehicle: 'ignitionOn' };
// var statusTypes = {
//   'vehicle': {
//     'active': 'background',
//     'inactive': 'inactiveBackground',
//     'emergency': 'emergencyBackground'
//   }
// }
const CACHED_OPTIONS_DATATYPE = 'cachedOptions';
const MAP_ICONS_DATATYPE = 'mapIcons';
export const preloadLiveIcons = async ({
  authorisedTypes,
  allowRotations = true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  loadingFromCacheCallback = () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  regeneratingCallback = () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  regenerationCompleteCallback = () => {},
}) => {
  // is it in the IndexedDB and is it relevant?
  const cachedOptions = await db.live?.get(CACHED_OPTIONS_DATATYPE);
  if (cachedOptions && dequal(cachedOptions.options, liveOptions)) {
    loadingFromCacheCallback();
    const saved = await db.live.get(MAP_ICONS_DATATYPE);

    // need to translate the dataUrls to icons
    mergedCache = transform({
      source: saved.icons,
      hasRotationsTest: (i) => typeof i !== 'string',
      action: (src) => new Icon({ src }),
    });
  } else {
    // otherwise generate it and save
    regeneratingCallback();

    let possibleTypes = Object.keys(mapGlyphsByTypeAndSubtype).filter(
      (type) => !authorisedTypes || authorisedTypes.includes(type),
    );
    let possibleFiltered = [true, false];
    let possibleFollowed = [true, false];
    let promises = [];

    for (let t = 0; t < possibleTypes.length; t++) {
      let type = possibleTypes[t];

      for (let f = 0; f < possibleFiltered.length; f++) {
        for (let l = 0; l < possibleFollowed.length; l++) {
          let possibleStates =
            type in statusIconColoursByType
              ? Object.keys(statusIconColoursByType[type])
              : [];
          ['default', 'hover', 'select'].forEach((commonState) => {
            if (!possibleStates.includes(commonState)) {
              possibleStates.push(commonState);
            }
          });
          let possibleSubtypes = Object.keys(mapGlyphsByTypeAndSubtype[type]);
          for (let s = 0; s < possibleStates.length; s++) {
            for (let b = 0; b < possibleSubtypes.length; b++) {
              let subtype = possibleSubtypes[b];
              let filtered = possibleFiltered[f];
              let followed = possibleFollowed[l];
              let status = possibleStates[s];
              let needsRotation =
                allowRotations && rotatingTypes.includes(type);

              promises.push(
                mergeImagesWrapper(
                  type,
                  subtype,
                  status,
                  filtered,
                  followed,
                  needsRotation ? 0 : null,
                  false, // I don't need it immediately, the promise will do fine
                ),
              );
            }
          }
        }
      }
    }

    await Promise.all(promises);

    // Dexie can't serialise <img> so get the source instead
    const srcCache = transform({
      source: mergedCache,
      hasRotationsTest: (i) => i[0], // rotation dictionaries have { 0: ..., 6: ... }
      action: (icon) => icon.getSrc(),
    });

    db.live.put({ dataType: CACHED_OPTIONS_DATATYPE, options: liveOptions });
    db.live.put({ dataType: MAP_ICONS_DATATYPE, icons: srcCache });

    regenerationCompleteCallback();
  }
};

function getPointOfGeometryCollection(geometries, fprops) {
  for (let i = 0; i < geometries.length; i++) {
    if (geometries[i].getType() === 'Point') {
      return geometries[i];
    }
  }

  return new Point(fprops['clusterPoint']);
}

function excludePointOfGeometryCollection(geometries) {
  const nonPoints = [];
  for (let i = 0; i < geometries.length; i++) {
    if (geometries[i].getType() !== 'Point') {
      nonPoints.push(geometries[i]);
    }
  }

  return new GeometryCollection(nonPoints);
}

export const getLiveStyle = ({
  layer,
  feature,
  showLabels,
  // zoom,
  iconsOnly,
  polygonsOnly,
  polygonIsSelected,
  allowRotations,
  showStale,
}) => {
  const cluster = feature.get('features');
  if (cluster) {
    feature = _.maxBy(cluster, (f) => f.getProperties().zIndex || 0);
  }

  const fprops = feature.getProperties();
  const status = fprops.status;

  if (!showStale && status === 'stale') {
    return null;
  }

  const hoverLayer = layer === 'hover';
  const selectLayer = layer === 'select';
  const hoverOrSelectLayer = hoverLayer || selectLayer;

  let geometry = feature.getGeometry();
  if (geometry && geometry.getType() === 'GeometryCollection') {
    if (iconsOnly || hoverOrSelectLayer) {
      geometry = getPointOfGeometryCollection(geometry.getGeometries(), fprops);
    } else if (polygonsOnly) {
      geometry = excludePointOfGeometryCollection(geometry.getGeometries());
    }
  }

  // always true now filtering is done in separate layer
  const { filteredIn, followed, focused } = fprops;

  const type = 'type' in fprops ? fprops.type : 'default';

  const typeLabels = {
    vehicle: 'registrationNumber',
    person: 'collarNumber',
    location: 'name',
    event: 'code',
    incident: 'number',
    //objective: 'id'
  };

  var offsetX = ['vehicle', 'person'].includes(type) ? 16 : 12;

  // if (type === 'user') {
  //   const userStyle = positionStyle;
  //   userStyle[0].setZIndex(4);
  //   userStyle[1].setZIndex(5);
  //   return userStyle;
  // }

  const heading =
    allowRotations && 'headingDegrees' in fprops
      ? fprops.headingDegrees || 0
      : null;
  var mergedIcon = mergeImagesWrapper(
    type,
    fprops['subtype'],
    layer === 'default' ? status || layer : layer, // layer overwrites status colour
    filteredIn,
    followed,
    heading,
  );

  let z = fprops['zIndex'] || 10;
  var icon = mergedIcon || blankIcon;
  showLabels =
    showLabels ||
    hoverOrSelectLayer ||
    (liveOptions.mapFollowLabel && followed) ||
    (liveOptions.mapFocusLabel && focused);

  const opacity = fprops['opacity'];
  if (opacity) {
    icon.setOpacity(opacity);
  }

  // * 0.5 because icons are 64x64 to give 32x32 normally and allow scaling up
  // to max of 64x64 e.g. in hover or select style layerColours scale is 2x
  // the (0.3 + 0.7*zoom/20) bit shrinks the icons as you zoom out min of
  // 0.3 ish to max of 1.1 ish (23 zoom levels)
  let scale =
    layerColours[layer].scale *
    (liveOptions.mapFollowBorderSuperSize ? 1 : 0.5); // * (0.3 + (0.7 * zoom) / 20);
  if (type === 'person') {
    scale *= 0.9; // officers are supposed to be smaller than vehicles
  }
  icon.setScale(scale);

  let [strokeColour, fillColour] = getStatusFillColours(
    type,
    status || fprops['subtype'],
  );

  if (polygonIsSelected) {
    strokeColour = 'rgb(255,255,255)';
    fillColour = 'rgba(255,255,255,.4)';
  }

  return new Style({
    image: icon,
    stroke: new Stroke({
      color: strokeColour,
      width: 2,
    }),
    fill: new Fill({
      color: fillColour,
    }),
    text: new Text({
      font: '12px Roboto,sans-serif',
      fill: new Fill({
        // color: false,
        color: liveOptions.mapFollowLabel && followed ? followColour : false,
        // color: (followed ? 'rgba(255,255,255,1.0)' : false),
      }),
      stroke: new Stroke({
        color: 'rgba(255,255,255,1.0)',
        // color: (followed ? 'rgba(255,255,255,1.0)' : false),
        // color: (followed ? 'rgba(0,0,255,1.0)' : 'rgba(255,255,255,1.0)'),
        width: 3,
      }),
      text: showLabels
        ? fprops['label'] || fprops[typeLabels[type]] || fprops.id
        : '',
      textAlign: 'left',
      offsetX: offsetX,
    }),
    zIndex: z,
    geometry,
  });
};

export function getReplayStyle(layer, feature, showLabels) {
  const type =
    typeof feature.getProperties().type === 'string'
      ? feature.getProperties().type
      : 'default';
  const layerColours = {
    default: {
      background: 'rgb(0,0,0)',
      icon: 'rgb(255,255,255)',
      fill: 'rgba(0,0,0,0.5)',
    },
    select: {
      background: 'rgb(33,150,243)',
      icon: 'rgb(255,255,255)',
      fill: 'rgba(33,150,243,0.5)',
    },
    hover: {
      background: 'rgb(255,235,59)',
      icon: 'rgb(0,0,0)',
      fill: 'rgba(255,235,59,0.5)',
    },
    path: {
      background: 'rgba(0,0,0,0.75)',
    },
  };
  const typeLabels = {
    vehicle: 'registrationNumber',
    person: 'collarNumber',
    location: 'name',
    event: 'code',
    incident: 'number',
  };

  switch (type) {
    case 'user': {
      const userStyle = positionStyle;
      userStyle[0].setZIndex(4);
      userStyle[1].setZIndex(5);
      return userStyle;
    }
    case 'vehicle': {
      const heading =
        typeof feature.getProperties().type === 'string'
          ? feature.getProperties().headingDegrees
          : 0;

      return [
        new Style({
          image: new Icon({
            opacity: 1,
            src: resourceShape,
            scale: 0.05,
            color: layerColours[layer].background,
            rotation: (heading * Math.PI) / 180.0,
          }),
          text: new Text({
            font: '12px Roboto,sans-serif',
            fill: new Fill({
              color: '#00000',
            }),
            stroke: new Stroke({
              color: '#ffffff',
              width: 3,
            }),
            text: showLabels ? feature.getProperties()[typeLabels[type]] : '',
            textAlign: 'left',
            offsetX: 16,
          }),
        }),
        new Style({
          image: new Icon({
            src: replayTypeGlyphs[type],
            color: layerColours[layer].icon,
            scale: 0.5,
          }),
        }),
      ];
    }
    case 'person':
      return [
        new Style({
          image: new Circle({
            radius: 10,
            fill: new Fill({
              color: layerColours[layer].background,
            }),
          }),
          text: new Text({
            font: '12px Roboto,sans-serif',
            fill: new Fill({
              color: '#00000',
            }),
            stroke: new Stroke({
              color: '#ffffff',
              width: 3,
            }),
            text: showLabels ? feature.getProperties()[typeLabels[type]] : '',
            textAlign: 'left',
            offsetX: 16,
          }),
        }),
        new Style({
          image: new Icon({
            src: replayTypeGlyphs[type],
            color: layerColours[layer].icon,
            scale: 0.5,
          }),
        }),
      ];
    case 'incident':
    case 'event':
      return [
        new Style({
          image: new Circle({
            radius: 10,
            fill: new Fill({
              color: layerColours[layer].background,
            }),
          }),
          text: new Text({
            font: '12px Roboto,sans-serif',
            fill: new Fill({
              color: '#00000',
            }),
            stroke: new Stroke({
              color: '#ffffff',
              width: 3,
            }),
            text: showLabels ? feature.getProperties()[typeLabels[type]] : '',
            textAlign: 'left',
            offsetX: 12,
          }),
        }),
        new Style({
          image: new Icon({
            src: replayTypeGlyphs[type],
            color: layerColours[layer].icon,
            scale: 0.5,
          }),
        }),
      ];
    case 'location':
      return [
        new Style({
          fill: new Fill({
            color: layerColours[layer].fill,
          }),
          stroke: new Stroke({
            color: layerColours[layer].background,
            width: 2,
          }),
        }),
        new Style({
          image: new Circle({
            radius: 10,
            fill: new Fill({
              color: layerColours[layer].background,
            }),
          }),
          text: new Text({
            font: '12px Roboto,sans-serif',
            fill: new Fill({
              color: '#00000',
            }),
            stroke: new Stroke({
              color: '#ffffff',
              width: 3,
            }),
            text: showLabels ? feature.getProperties()[typeLabels[type]] : '',
            textAlign: 'left',
            offsetX: 12,
          }),
          geometry: (feature) => feature.getGeometry().getInteriorPoint(),
        }),
        new Style({
          image: new Icon({
            src: replayTypeGlyphs[type],
            color: layerColours[layer].icon,
            scale: 0.5,
          }),
          geometry: (feature) => feature.getGeometry().getInteriorPoint(),
        }),
      ];
    default:
      return [
        new Style({
          fill: new Fill({
            color: layerColours[layer].fill,
          }),
          stroke: new Stroke({
            color: layerColours[layer].background,
            width: 2,
          }),
          image: new Circle({
            radius: 10,
            fill: new Fill({
              color: layerColours[layer].background,
            }),
          }),
          text: new Text({
            font: '12px Roboto,sans-serif',
            fill: new Fill({
              color: '#00000',
            }),
            stroke: new Stroke({
              color: '#ffffff',
              width: 3,
            }),
            text: showLabels ? feature.getProperties()[typeLabels[type]] : '',
            textAlign: 'left',
            offsetX: 12,
          }),
        }),
      ];
  }
}

const staticStyle = new Style({
  text: new Text({
    font: '12px Roboto,sans-serif',
    fill: new Fill({
      color: '#00000',
    }),
    stroke: new Stroke({
      color: '#ffffff',
      width: 3,
    }),
  }),
});

function getSource(type, options, projectionCode, tileGridOptions) {
  switch (type) {
    case 'xyz': {
      return new XYZ(options);
    }
    case 'bing': {
      return new BingMaps(options);
    }
    case 'tileJSON': {
      return new TileJSON(options);
    }
    case 'wmts': {
      const projection = getProjection(projectionCode);
      projection.setExtent([0, -32, 700032, 1300000]);

      return new WMTS({
        projection,
        tileGrid: new WMTSTileGrid(tileGridOptions),
        ...options,
      });
    }
    default: {
      return null;
    }
  }
}

export function getBaseLayers(baseLayers) {
  proj4.defs(
    'EPSG:27700',
    '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs',
  );
  register(proj4);

  return baseLayers.map(
    ({ sourceType, sourceOptions, projection, tileGridOptions, ...layer }) =>
      new TileLayer({
        ...layer,
        source: getSource(
          sourceType,
          sourceOptions,
          projection,
          tileGridOptions,
        ),
      }),
  );
}

import { NumericFilterField } from './NumericFilterField';
import { SelectFilterField } from './SelectFilterField';

export function Field({ type, ...props }) {
  switch (type) {
    case 'number': {
      return <NumericFilterField {...props} />;
    }
    case 'select': {
      return <SelectFilterField {...props} />;
    }
    default: {
      return <div />;
    }
  }
}

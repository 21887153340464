import { Stack, Typography } from '@mui/material';
import { Popup } from 'react-map-gl/maplibre';
import { Status } from './Status';

export function ObjectivePopup({
  hoverInfo: {
    longitude,
    latitude,
    feature: { properties },
  },
}) {
  return (
    <Popup
      longitude={longitude}
      latitude={latitude}
      offset={[0, -16]}
      closeButton={false}
    >
      <Stack spacing={1}>
        <Typography variant="subtitle2">{properties.title}</Typography>
        <Status status={properties.status} />
      </Stack>
    </Popup>
  );
}

import {
  AddCircle as AddIcon,
  RemoveCircle as RemoveIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useRef, useState } from 'react';

export function ManageTypeValuesDialog({ open, name, initialValues, onClose }) {
  const [values, setValues] = useState(initialValues);
  const parentRef = useRef();
  const rowVirtualizer = useVirtualizer({
    count: values.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 48,
    overscan: 5,
  });

  function handleCancel() {
    onClose();
  }

  function handleSave() {
    onClose(values.filter((value) => value !== ''));
  }

  const handleChange = (index) => (event) => {
    setValues((values) => {
      const newValues = [...values];
      newValues[index] = event.target.value;
      return newValues;
    });
  };

  const handleDeleteClick = (index) => () => {
    setValues((values) => {
      const newValues = [...values];
      newValues.splice(index, 1);
      return newValues;
    });
  };

  function handleAddClick() {
    setValues((values) => [...values, '']);
  }

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>{`Manage ${name} aliases`}</DialogTitle>
      <DialogContent>
        <Box ref={parentRef} sx={{ overflow: 'auto', maxHeight: 240 }}>
          <Box
            sx={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: '100%',
              position: 'relative',
            }}
          >
            {rowVirtualizer.getVirtualItems().map(({ index, size, start }) => (
              <Stack
                key={index}
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: `${size}px`,
                  transform: `translateY(${start}px)`,
                }}
              >
                <TextField
                  value={values[index]}
                  onChange={handleChange(index)}
                  size="small"
                  fullWidth
                />
                <Tooltip title="Remove" placement="right">
                  <IconButton onClick={handleDeleteClick(index)} size="small">
                    <RemoveIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            ))}
          </Box>
        </Box>
        <Stack direction="row" spacing={1} alignItems="center">
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Add" placement="right">
            <IconButton onClick={handleAddClick} size="small">
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}

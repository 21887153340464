import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useCustodyUnits() {
  return useQuery({
    queryKey: ['custodyUnits'],
    queryFn: () => {
      return briefingApi.get('niche/custody/units', { timeout: false }).json();
    },
    staleTime: 1380 * (60 * 1000), // only refetch every 23 hour
    cacheTime: 1440 * (60 * 1000), // keep data in cache for 24 hours
  });
}

import {
  FETCH_VEHICLE_DAILY_UTILISATION,
  FETCH_VEHICLE_DAILY_UTILISATION_CANCELLED,
  FETCH_VEHICLE_DAILY_UTILISATION_FAILURE,
  FETCH_VEHICLE_DAILY_UTILISATION_SUCCESS,
  FETCH_VEHICLE_HOURLY_UTILISATION,
  FETCH_VEHICLE_HOURLY_UTILISATION_CANCELLED,
  FETCH_VEHICLE_HOURLY_UTILISATION_FAILURE,
  FETCH_VEHICLE_HOURLY_UTILISATION_SUCCESS,
  FILTER_VEHICLE_DAILY_UTILISATION,
  FILTER_VEHICLE_HOURLY_UTILISATION,
  LOAD_VEHICLE_DAILY_UTILISATION_QUERY,
  LOAD_VEHICLE_DAILY_UTILISATION_QUERY_FAILURE,
  LOAD_VEHICLE_DAILY_UTILISATION_QUERY_SUCCESS,
  LOAD_VEHICLE_HOURLY_UTILISATION_QUERY,
  LOAD_VEHICLE_HOURLY_UTILISATION_QUERY_FAILURE,
  LOAD_VEHICLE_HOURLY_UTILISATION_QUERY_SUCCESS,
} from '@/actions';
import { api } from '@/apis';
import { db, fetchCachedData } from '@/data';
import { encodeParams, getGroupKey, groupsFilter, log, round } from '@/utils';
import { homeOtherSplit } from '@/utils/config';
import { parse } from 'date-fns';
import { dequal } from 'dequal';
import _ from 'lodash';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

const controller = new AbortController();
const { signal } = controller;

function dayOfWeekStartingMonday(time) {
  const dayOfWeekStartingSunday = new Date(time).getDay();

  return (dayOfWeekStartingSunday + 6) % 7;
}

function vehicleUtilisationFilter(record, filter) {
  if (
    filter.registrationNumber.length !== 0 &&
    !filter.registrationNumber.includes(record.registrationNumber)
  ) {
    return false;
  }

  if (
    filter.fleetNumber.length !== 0 &&
    !filter.fleetNumber.includes(record.fleetNumber)
  ) {
    return false;
  }

  if (filter.role.length !== 0 && !filter.role.includes(record.role)) {
    return false;
  }

  if (filter.type.length !== 0 && !filter.type.includes(record.type)) {
    return false;
  }

  if (filter.hour.length !== 24) {
    // can't reject the entire record but can filter a copy of hourly
    record.filteredHourly = _.pick(record.hourly, filter.hour);
  } else {
    delete record.filteredHourly;
  }

  if (filter.day.length !== 7 && !filter.day.includes(record.day)) {
    return false;
  }

  return groupsFilter(record, filter);
}

function getVehicleUtilisationFilterAndGroupByValues(data, filter) {
  const { groups: _, ...fields } = filter;
  let result = { groups: {} };

  const groups = Array.from(
    new Set(
      [].concat(...data.map((record) => Object.keys(record.groups || {}))),
    ),
  );

  for (const key in fields) {
    const keyFilter = { ...filter, [key]: [] };
    result[key] = Array.from(
      new Set(
        data
          .filter((record) => vehicleUtilisationFilter(record, keyFilter))
          .map((record) => record[key]),
      ),
    )
      .filter((value) => value !== undefined)
      .sort();
  }

  // don't filter hour and day by returned results
  result.hour = [...Array(24).keys()];
  result.day = [...Array(7).keys()];

  for (const group of groups) {
    const keyFilter = { ...filter, groups: { ...filter.groups, [group]: [] } };
    result.groups[group] = Array.from(
      new Set(
        data
          .filter(
            (record) =>
              vehicleUtilisationFilter(record, keyFilter) &&
              record.groups &&
              group in record.groups,
          )
          .map((record) => record.groups[group])
          .flatMap((value) => value),
      ),
    )
      .filter((value) => value !== undefined && value !== false)
      .sort();
  }

  return {
    filterValues: result,
    groupByValues: [
      'all',
      'date',
      'month',
      'registrationNumber',
      'fleetNumber',
      'role',
      'type',
      'homeStation',
      ...groups.map((group) => `groups.${group}`),
    ],
  };
}

function getObjectKeys() {
  const homeSplitOptions = homeOtherSplit
    ? [
        'stoppedHomeBaseSeconds',
        'stoppedOtherBaseSeconds',
        'idleHomeBaseSeconds',
        'idleOtherBaseSeconds',
      ]
    : ['stoppedBaseSeconds', 'idleBaseSeconds'];

  const keys = [
    ...homeSplitOptions,
    'stoppedWorkshopSeconds',
    'idleWorkshopSeconds',
    'stoppedElsewhereSeconds',
    'idleElsewhereSeconds',
    'movingSeconds',
    'unaccountableSeconds',
  ];
  return keys;
}

function getVehicleDailyUtilisation(
  rawData,
  groupBy,
  orderBy,
  order,
  classifyBy,
  chartType,
) {
  const groupedData =
    rawData.length === 0
      ? new Map()
      : rawData.reduce(
          (accumulator, record) =>
            getGroupKey(groupBy, record).reduce((accumulator, groupKey) => {
              let current = accumulator.get(groupKey);

              if (!current) {
                current = {
                  group: groupKey,
                  movingSeconds: [],
                  stoppedBaseSeconds: [],
                  stoppedHomeBaseSeconds: [],
                  stoppedOtherBaseSeconds: [],
                  stoppedWorkshopSeconds: [],
                  stoppedElsewhereSeconds: [],
                  idleBaseSeconds: [],
                  idleHomeBaseSeconds: [],
                  idleOtherBaseSeconds: [],
                  idleWorkshopSeconds: [],
                  idleElsewhereSeconds: [],
                  unaccountableSeconds: [],
                  tripStarts: [],
                  movingKilometres: [],
                  identifiers: [],
                };
                accumulator.set(groupKey, current);
              }

              let proxy = record;
              // if some hourly filtering was happening, we need to get
              // the properties by summing up the hourly ones
              if (record.filteredHourly) {
                proxy.movingSeconds = 0;
                proxy.stoppedBaseSeconds = 0;
                proxy.stoppedHomeBaseSeconds = 0;
                proxy.stoppedOtherBaseSeconds = 0;
                proxy.stoppedWorkshopSeconds = 0;
                proxy.stoppedElsewhereSeconds = 0;
                proxy.idleBaseSeconds = 0;
                proxy.idleHomeBaseSeconds = 0;
                proxy.idleOtherBaseSeconds = 0;
                proxy.idleWorkshopSeconds = 0;
                proxy.idleElsewhereSeconds = 0;
                proxy.unaccountableSeconds = 0;
                proxy.tripStarts = 0;
                proxy.movingKilometres = 0;

                Object.keys(record.filteredHourly).forEach((hour) => {
                  Object.keys(record.filteredHourly[hour] ?? {}).forEach(
                    (key) => {
                      proxy[key] += record.filteredHourly[hour][key];
                    },
                  );
                });
              }

              const idleBase = homeOtherSplit
                ? [proxy.idleHomeBaseSeconds + proxy.idleOtherBaseSeconds]
                : proxy.idleBaseSeconds;

              current.movingSeconds.push(
                proxy.movingSeconds -
                  idleBase -
                  proxy.idleWorkshopSeconds -
                  proxy.idleElsewhereSeconds,
              );
              current.stoppedBaseSeconds?.push(proxy.stoppedBaseSeconds);
              current.stoppedHomeBaseSeconds?.push(
                proxy.stoppedHomeBaseSeconds,
              );
              current.stoppedOtherBaseSeconds?.push(
                proxy.stoppedOtherBaseSeconds,
              );
              current.stoppedWorkshopSeconds.push(proxy.stoppedWorkshopSeconds);
              current.stoppedElsewhereSeconds.push(
                proxy.stoppedElsewhereSeconds,
              );
              current.idleBaseSeconds?.push(proxy.idleBaseSeconds);
              current.idleHomeBaseSeconds?.push(proxy.idleHomeBaseSeconds);
              current.idleOtherBaseSeconds?.push(proxy.idleOtherBaseSeconds);
              current.idleWorkshopSeconds.push(proxy.idleWorkshopSeconds);
              current.idleElsewhereSeconds.push(proxy.idleElsewhereSeconds);
              current.unaccountableSeconds.push(proxy.unaccountableSeconds);
              current.tripStarts.push(proxy.tripStarts);
              current.movingKilometres.push(proxy.movingKilometres);

              if (!current.identifiers.includes(record.registrationNumber)) {
                current.identifiers.push(record.registrationNumber);
              }

              return accumulator;
            }, accumulator),
          new Map(),
        );

  function roundedAverage(groupedValues) {
    return round(
      groupedValues.reduce((a, b) => a + b, 0) / groupedValues.length / 3600,
      2,
    );
  }

  // get daily group total to calculate percentage
  function getGroupTotal() {
    const group = Array.from(groupedData.values()).map((group) => group);
    let dailyValues = [];
    Object.keys(group[0]).forEach((key) => {
      if (getObjectKeys().includes(key)) {
        dailyValues = [...dailyValues, ...group[0][key]];
      }
    });

    const total = dailyValues.reduce((a, b) => a + b, 0);
    return total;
  }

  function roundedAverageAsPercentage(groupedValues) {
    const groupTotal = getGroupTotal();
    return round(
      (groupedValues.reduce((a, b) => a + b, 0) / groupTotal) * 100,
      2,
    );
  }

  const averageFunction =
    chartType === 'percentage' ? roundedAverageAsPercentage : roundedAverage;

  let data = Array.from(groupedData.values()).map((group) => ({
    group:
      groupBy === 'date' || groupBy === 'month'
        ? new Date(group.group)
        : group.group,
    count: group.identifiers.length,
    moving: averageFunction(group.movingSeconds),
    stoppedBase: averageFunction(group.stoppedBaseSeconds),
    stoppedHomeBase: averageFunction(group.stoppedHomeBaseSeconds),
    stoppedOtherBase: averageFunction(group.stoppedOtherBaseSeconds),
    stoppedWorkshop: averageFunction(group.stoppedWorkshopSeconds),
    stoppedElsewhere: averageFunction(group.stoppedElsewhereSeconds),
    idleBase: averageFunction(group.idleBaseSeconds),
    idleHomeBase: averageFunction(group.idleHomeBaseSeconds),
    idleOtherBase: averageFunction(group.idleOtherBaseSeconds),
    idleWorkshop: averageFunction(group.idleWorkshopSeconds),
    idleElsewhere: averageFunction(group.idleElsewhereSeconds),
    unaccountable: averageFunction(group.unaccountableSeconds),
    totalMileage: round(
      group.movingKilometres.reduce((a, b) => a + b, 0) * 0.62137119,
      2,
    ),
    averageMileage: round(
      (group.movingKilometres.reduce((a, b) => a + b, 0) * 0.62137119) /
        group.identifiers.length,
      2,
    ),
    dailyMileage: round(
      (group.movingKilometres.reduce((a, b) => a + b, 0) * 0.62137119) /
        group.movingKilometres.length,
      2,
    ),
    totalTrips: round(
      group.tripStarts.reduce((a, b) => a + b, 0),
      2,
    ),
    averageTrips: round(
      group.tripStarts.reduce((a, b) => a + b, 0) / group.identifiers.length,
      2,
    ),
    dailyTrips: round(
      group.tripStarts.reduce((a, b) => a + b, 0) / group.tripStarts.length,
      2,
    ),
  }));

  if (classifyBy === 'status') {
    data = data.map(
      ({
        idleBase = 0,
        idleHomeBase = 0,
        idleOtherBase = 0,
        idleElsewhere = 0,
        idleWorkshop = 0,
        moving = 0,
        stoppedBase = 0,
        stoppedHomeBase = 0,
        stoppedOtherBase = 0,
        stoppedElsewhere = 0,
        stoppedWorkshop = 0,
        unaccountable = 0,
        ...item
      }) => ({
        used: round(moving + stoppedElsewhere + idleElsewhere, 2),
        unused: round(
          homeOtherSplit
            ? stoppedHomeBase + stoppedOtherBase + idleHomeBase + idleOtherBase
            : stoppedBase + idleBase,
          2,
        ),
        unavailable: round(stoppedWorkshop + idleWorkshop, 2),
        unaccountable: round(unaccountable, 2),
        ...item,
      }),
    );
  }

  if (orderBy === 'date' || orderBy === 'month') {
    data.sort(
      (a, b) => parse(a.group, 'dd/MM/yyyy') - parse(b.group, 'dd/MM/yyyy'),
    );

    return order === 'asc' ? data : data.reverse();
  } else {
    return _.orderBy(data, orderBy, order);
  }
}

async function fetchVehicleDailyUtilisationRequest(
  query,
  filter,
  groupBy,
  orderBy,
  order,
  classifyBy,
  chartType,
) {
  const reportName = 'vehicleDailyUtilisation';
  const cachedParameters = await db.parameters.get(reportName);

  if (
    !_.isEmpty(cachedParameters?.query) &&
    dequal(cachedParameters?.query, query)
  ) {
    return getVehicleDailyUtilisationCachedData(
      reportName,
      query,
      filter,
      groupBy,
      orderBy,
      order,
      classifyBy,
      chartType,
    );
  } else if (!_.isEmpty(query)) {
    return fetchVehicleDailyUtilisationData(
      reportName,
      query,
      filter,
      groupBy,
      orderBy,
      order,
      classifyBy,
      chartType,
    );
  }
}

async function fetchVehicleDailyUtilisationData(
  reportName,
  query,
  filter,
  groupBy,
  orderBy,
  order,
  classifyBy,
  chartType,
) {
  const response = await api
    .get('vehicleDailySummaries', {
      searchParams: encodeParams({
        query,
        projection: {
          time: true,
          vehicle: true,
          hourly: true,
          movingKilometres: true,
          movingSeconds: true,
          stoppedBaseSeconds: true,
          stoppedHomeBaseSeconds: true,
          stoppedOtherBaseSeconds: true,
          stoppedElsewhereSeconds: true,
          stoppedWorkshopSeconds: true,
          idleBaseSeconds: true,
          idleHomeBaseSeconds: true,
          idleOtherBaseSeconds: true,
          idleElsewhereSeconds: true,
          idleWorkshopSeconds: true,
          tripStarts: true,
          accelerometerAlerts: true,
          accelerometerEvents: true,
          respondingToIncidents: true,
          respondingToIncidentSeconds: true,
          unaccountableSeconds: true,
        },
      }),
      signal,
    })
    .json();

  const data = response.map(({ vehicle, time, ...record }) => ({
    ...record,
    ...vehicle,
    time,
    day: dayOfWeekStartingMonday(time),
  }));

  await db.vehicleDailyUtilisation.clear();
  await db.vehicleDailyUtilisation.bulkAdd(data);
  await db.parameters.put({
    store: reportName,
    query,
  });

  const filteredData = data.filter((record) =>
    vehicleUtilisationFilter(record, filter),
  );

  const results = {
    query,
    filter,
    groupBy,
    orderBy,
    order,
    classifyBy,
    chartType,
    ...getVehicleUtilisationFilterAndGroupByValues(data, filter),
    data: getVehicleDailyUtilisation(
      filteredData,
      groupBy,
      orderBy,
      order,
      classifyBy,
      chartType,
      filter.hour?.length ?? 24,
    ),
  };

  log('Read', 'Vehicle Daily Utilisation', query);

  return results;
}

async function getVehicleDailyUtilisationCachedData(
  reportName,
  query,
  filter,
  groupBy,
  orderBy,
  order,
  classifyBy,
  chartType,
) {
  const data = await fetchCachedData(reportName);
  const filteredData = data.filter((record) =>
    vehicleUtilisationFilter(record, filter),
  );

  const results = {
    query,
    filter,
    groupBy,
    orderBy,
    order,
    classifyBy,
    chartType,
    ...getVehicleUtilisationFilterAndGroupByValues(data, filter),
    data: getVehicleDailyUtilisation(
      filteredData,
      groupBy,
      orderBy,
      order,
      classifyBy,
      chartType,
      filter.hour?.length ?? 24,
    ),
  };

  log('Load', 'Vehicle Daily Utilisation', query);

  return results;
}

export function fetchVehicleDailyUtilisationEpic(action$) {
  return action$.pipe(
    ofType(FETCH_VEHICLE_DAILY_UTILISATION, FILTER_VEHICLE_DAILY_UTILISATION),
    mergeMap(
      ({
        payload: {
          query,
          filter,
          groupBy,
          orderBy,
          order,
          classifyBy,
          chartType,
        },
      }) =>
        from(
          fetchVehicleDailyUtilisationRequest(
            query,
            filter,
            groupBy,
            orderBy,
            order,
            classifyBy,
            chartType,
          ),
        ).pipe(
          map((payload) => ({
            type: FETCH_VEHICLE_DAILY_UTILISATION_SUCCESS,
            payload,
          })),
          takeUntil(
            action$.pipe(
              ofType(FETCH_VEHICLE_DAILY_UTILISATION_CANCELLED),
              tap(() => controller.abort()),
            ),
          ),
          catchError(({ message: payload }) =>
            of({
              type: FETCH_VEHICLE_DAILY_UTILISATION_FAILURE,
              payload,
            }),
          ),
        ),
    ),
  );
}

async function loadVehicleDailyUtilisationRequest() {
  const reportName = 'vehicleDailyUtilisation';
  const parameters = await db.parameters.get(reportName);

  const results = {
    query: parameters?.query || {},
  };

  log('Load', 'Vehicle Daily Utilisation', parameters);

  return results;
}

export function loadVehicleDailyUtilisationEpic(action$) {
  return action$.pipe(
    ofType(LOAD_VEHICLE_DAILY_UTILISATION_QUERY),
    mergeMap(() =>
      from(loadVehicleDailyUtilisationRequest()).pipe(
        map((payload) => ({
          type: LOAD_VEHICLE_DAILY_UTILISATION_QUERY_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: LOAD_VEHICLE_DAILY_UTILISATION_QUERY_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

function getVehicleHourlyUtilisation(rawData, classifyBy, chartType) {
  const MS = 'movingSeconds';
  const SBS = 'stoppedBaseSeconds';
  const SHBS = 'stoppedHomeBaseSeconds';
  const SOBS = 'stoppedOtherBaseSeconds';
  const SES = 'stoppedElsewhereSeconds';
  const SWS = 'stoppedWorkshopSeconds';
  const IBS = 'idleBaseSeconds';
  const IHBS = 'idleHomeBaseSeconds';
  const IOBS = 'idleOtherBaseSeconds';
  const IES = 'idleElsewhereSeconds';
  const IWS = 'idleWorkshopSeconds';
  const UAS = 'unaccountableSeconds';

  const totalSecondsForEachHour = {};

  // translate mongo result
  const mapResponseToResult = (response) => {
    const transformedResults = [];
    response.forEach((data) => {
      const hourlyObject = convertHourlyObject(
        data.filteredHourly ?? data.hourly,
      );
      transformedResults.push({ ...hourlyObject });
    });
    return transformedResults;
  };

  // convert hourly object
  const convertHourlyObject = (data) => {
    const result = {};
    for (let i = 0; i < 24; i++) {
      const key = `${i.toString().padStart(2, '0')}:00`;
      result[key] = data[i];
    }
    return result;
  };

  // build hourly object
  const buildHouryObject = (data) => {
    const hourlyObject = {};

    for (let i = 0; i < 24; i++) {
      const key = `${i.toString().padStart(2, '0')}:00`;
      hourlyObject[key] = [];
    }

    data.forEach((data) => {
      const hourKeys = Object.keys(data);
      hourKeys.forEach((key) => {
        const hourData = data[key] ? data[key] : 0; // There are missing hours between 0 and 23, add 0 for them
        const hours = hourlyObject[key];
        if (Array.isArray(hours)) {
          hours.push(hourData);
          hourlyObject[key] = [...hours];
        }
      });
    });
    return hourlyObject;
  };

  // calculate the value for each hour by converting seconds to minutes
  const calculateForHour = (seconds) => {
    const totalRecords = rawData.length;
    const value = round((60 * (seconds / 60)) / (totalRecords * 60), 2);
    return value;
  };

  // calculate for percentage
  const calculateForPercentage = (seconds, key, obj) => {
    const totalForHour = totalSecondsForEachHour[obj.hour];
    const value = key === 'movingSeconds' ? obj[key] : seconds;
    let result = 0;

    if (getObjectKeys().includes(key)) {
      result = round((value / totalForHour) * 100, 2);
    }
    return result;
  };

  // change field name and calculate values
  const buildObjectForReport = (obj, fn) => {
    const result = {};
    const keys = Object.keys(obj);
    keys.forEach((key) => {
      switch (key) {
        case 'hour':
          result['Hour'] = obj[key];
          break;
        case MS:
          result['Moving'] = fn(
            homeOtherSplit
              ? obj[key] - obj[IHBS] - obj[IOBS] - obj[IES] - obj[IWS]
              : obj[key] - obj[IBS] - obj[IES] - obj[IWS],
            key,
            obj,
          );
          break;
        case SBS:
          result['Stopped @ Base'] = fn(obj[key], key, obj);
          break;
        case SHBS:
          result['Stopped @ Home Base'] = fn(obj[key], key, obj);
          break;
        case SOBS:
          result['Stopped @ Other Base'] = fn(obj[key], key, obj);
          break;
        case SES:
          result['Stopped Elsewhere'] = fn(obj[key], key, obj);
          break;
        case SWS:
          result['Stopped @ Workshop'] = fn(obj[key], key, obj);
          break;
        case IBS:
          result['Idle @ Base'] = fn(obj[key], key, obj);
          break;
        case IHBS:
          result['Idle @ Home Base'] = fn(obj[key], key, obj);
          break;
        case IOBS:
          result['Idle @ Other Base'] = fn(obj[key], key, obj);
          break;
        case IES:
          result['Idle Elsewhere'] = fn(obj[key], key, obj);
          break;
        case IWS:
          result['Idle @ Workshop'] = fn(obj[key], key, obj);
          break;
        case UAS:
          result['Unaccounted'] = fn(obj[key], key, obj);
          break;
        default:
          result[key] = obj[key];
      }
    });

    return result;
  };

  // add the same hourly field for each hour
  const calculateHourlyValue = (data) => {
    const hourlyObjectKeys = Object.keys(data);
    const result = [];

    hourlyObjectKeys.forEach((key) => {
      if (data[key] && data[key].length > 0) {
        const obj = data[key].reduce(
          (sum, tuple) => {
            return {
              hour: key,
              [MS]: !isNaN(tuple[MS]) ? sum[MS] + tuple[MS] : sum[MS] + 0,
              [SBS]: !isNaN(tuple[SBS]) ? sum[SBS] + tuple[SBS] : sum[SBS] + 0,
              [SHBS]: !isNaN(tuple[SHBS])
                ? sum[SHBS] + tuple[SHBS]
                : sum[SHBS] + 0,
              [SOBS]: !isNaN(tuple[SOBS])
                ? sum[SOBS] + tuple[SOBS]
                : sum[SOBS] + 0,
              [SES]: !isNaN(tuple[SES]) ? sum[SES] + tuple[SES] : sum[SES] + 0,
              [SWS]: !isNaN(tuple[SWS]) ? sum[SWS] + tuple[SWS] : sum[SWS] + 0,
              [IBS]: !isNaN(tuple[IBS]) ? sum[IBS] + tuple[IBS] : sum[IBS] + 0,
              [IHBS]: !isNaN(tuple[IHBS])
                ? sum[IHBS] + tuple[IHBS]
                : sum[IHBS] + 0,
              [IOBS]: !isNaN(tuple[IOBS])
                ? sum[IOBS] + tuple[IOBS]
                : sum[IOBS] + 0,
              [IES]: !isNaN(tuple[IES]) ? sum[IES] + tuple[IES] : sum[IES] + 0,
              [IWS]: !isNaN(tuple[IWS]) ? sum[IWS] + tuple[IWS] : sum[IWS] + 0,
              [UAS]: !isNaN(tuple[UAS]) ? sum[UAS] + tuple[UAS] : sum[UAS] + 0,
            };
          },
          {
            [MS]: 0,
            [SBS]: 0,
            [SHBS]: 0,
            [SOBS]: 0,
            [SES]: 0,
            [SWS]: 0,
            [IBS]: 0,
            [IHBS]: 0,
            [IOBS]: 0,
            [IES]: 0,
            [IWS]: 0,
            [UAS]: 0,
          },
        );

        // calculate the total seconds to calculate percentage
        const keyValues = [];
        Object.keys(obj).forEach((key) => {
          if (getObjectKeys().includes(key)) {
            keyValues.push(obj[key]);
          }
        });
        totalSecondsForEachHour[obj.hour] = keyValues.reduce(
          (a, b) => a + b,
          0,
        );

        const calculatedObj =
          chartType === 'percentage'
            ? buildObjectForReport(obj, calculateForPercentage)
            : buildObjectForReport(obj, calculateForHour);
        result.push(calculatedObj);
      }
    });

    return result;
  };

  const mappedData = mapResponseToResult(rawData);
  const hourlyData = buildHouryObject(mappedData);
  let data = calculateHourlyValue(hourlyData);

  if (classifyBy === 'status') {
    data = hourlyToUsedUnusedAvailable(data);
  }

  return data;
}

async function fetchVehicleHourlyUtilisationRequest(
  query,
  filter,
  classifyBy,
  chartType,
) {
  const reportName = 'vehicleHourlyUtilisation';
  const cachedParameters = await db.parameters.get(reportName);

  if (
    !_.isEmpty(cachedParameters?.query) &&
    dequal(cachedParameters?.query, query)
  ) {
    return getVehicleHourlyUtilisationCachedData(
      reportName,
      query,
      filter,
      classifyBy,
      chartType,
    );
  } else if (!_.isEmpty(query)) {
    return fetchVehicleHourlyUtilisationData(
      reportName,
      query,
      filter,
      classifyBy,
      chartType,
    );
  }
}

async function getVehicleHourlyUtilisationCachedData(
  reportName,
  query,
  filter,
  classifyBy,
  chartType,
) {
  const data = await fetchCachedData(reportName);
  const filteredData = data
    .filter((record) => vehicleUtilisationFilter(record, filter))
    .filter((r) => 'groups' in r);

  const results = {
    filter,
    filterValues: getVehicleUtilisationFilterAndGroupByValues(data, filter)
      .filterValues,
    data: getVehicleHourlyUtilisation(filteredData, classifyBy, chartType),
    query,
    classifyBy,
    chartType,
  };

  log('Load', 'Vehicle Hourly Utilisation', query);

  return results;
}

async function fetchVehicleHourlyUtilisationData(
  reportName,
  query,
  filter,
  classifyBy,
  chartType,
) {
  const response = await api
    .get('vehicleDailySummaries', {
      searchParams: encodeParams({
        query,
        projection: {
          time: true,
          vehicle: true,
          hourly: true,
        },
      }),
      signal,
    })
    .json();

  const data = response.map(({ vehicle, time, ...record }) => ({
    ...record,
    ...vehicle,
    day: dayOfWeekStartingMonday(time),
  }));

  await db.vehicleHourlyUtilisation.clear();
  await db.vehicleHourlyUtilisation.add(data);
  await db.parameters.put({
    store: reportName,
    query,
  });

  const filteredData = data
    .filter((record) => vehicleUtilisationFilter(record, filter))
    .filter((r) => 'groups' in r);

  const results = {
    query,
    filter,
    filterValues: getVehicleUtilisationFilterAndGroupByValues(data, filter)
      .filterValues,
    data: getVehicleHourlyUtilisation(filteredData, classifyBy, chartType),
    classifyBy,
    chartType,
  };

  log('Read', 'Vehicle Hourly Utilisation', query);

  return results;
}

function hourlyToUsedUnusedAvailable(data) {
  return data.map(
    ({
      'Idle @ Base': idleBase = 0,
      'Idle @ Home Base': idleHomeBase = 0,
      'Idle @ Other Base': idleOtherBase = 0,
      'Idle Elsewhere': idleElsewhere = 0,
      'Idle @ Workshop': idleWorkshop = 0,
      Moving: moving = 0,
      'Stopped @ Base': stoppedBase = 0,
      'Stopped @ Home Base': stoppedHomeBase = 0,
      'Stopped @ Other Base': stoppedOtherBase = 0,
      'Stopped Elsewhere': stoppedElsewhere = 0,
      'Stopped @ Workshop': stoppedWorkshop = 0,
      ...item
    }) => ({
      Used: round(moving + stoppedElsewhere + idleElsewhere, 2),
      Unused: round(
        homeOtherSplit
          ? stoppedHomeBase + stoppedOtherBase + idleHomeBase + idleOtherBase
          : stoppedBase + idleBase,
        2,
      ),
      Unavailable: round(stoppedWorkshop + idleWorkshop, 2),
      ...item,
    }),
  );
}

export function fetchVehicleHourlyUtilisationEpic(action$) {
  return action$.pipe(
    ofType(FETCH_VEHICLE_HOURLY_UTILISATION, FILTER_VEHICLE_HOURLY_UTILISATION),
    switchMap(({ payload: { query, filter, classifyBy, chartType } }) =>
      from(
        fetchVehicleHourlyUtilisationRequest(
          query,
          filter,
          classifyBy,
          chartType,
        ),
      ).pipe(
        map((payload) => ({
          type: FETCH_VEHICLE_HOURLY_UTILISATION_SUCCESS,
          payload,
        })),
        takeUntil(
          action$.pipe(
            ofType(FETCH_VEHICLE_HOURLY_UTILISATION_CANCELLED),
            tap(() => controller.abort()),
          ),
        ),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_VEHICLE_HOURLY_UTILISATION_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

async function loadVehicleHourlyUtilisationRequest() {
  const reportName = 'vehicleHourlyUtilisation';
  const parameters = await db.parameters.get(reportName);

  const results = {
    query: parameters?.query || {},
  };

  log('Load', 'Vehicle Hourly Utilisation', parameters);

  return results;
}

export function loadVehicleHourlyUtilisationEpic(action$) {
  return action$.pipe(
    ofType(LOAD_VEHICLE_HOURLY_UTILISATION_QUERY),
    mergeMap(() =>
      from(loadVehicleHourlyUtilisationRequest()).pipe(
        map((payload) => ({
          type: LOAD_VEHICLE_HOURLY_UTILISATION_QUERY_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: LOAD_VEHICLE_HOURLY_UTILISATION_QUERY_FAILURE,
            payload,
          }),
        ),
        catchError(({ message: payload }) =>
          of({
            type: LOAD_VEHICLE_HOURLY_UTILISATION_QUERY_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useOccurrence(code) {
  return useQuery({
    queryKey: ['occurrences', code],
    queryFn: () => {
      return briefingApi.get(`occurrences/${code}`).json();
    },
    enabled: !!code,
  });
}

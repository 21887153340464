import {
  CREATE_VEHICLE,
  CREATE_VEHICLE_FAILURE,
  CREATE_VEHICLE_SUCCESS,
  DELETE_VEHICLE,
  DELETE_VEHICLE_FAILURE,
  DELETE_VEHICLE_SUCCESS,
  FETCH_VEHICLE,
  FETCH_VEHICLES,
  FETCH_VEHICLES_FAILURE,
  FETCH_VEHICLES_SUCCESS,
  FETCH_VEHICLE_FAILURE,
  FETCH_VEHICLE_SUCCESS,
  UPDATE_VEHICLE,
  UPDATE_VEHICLE_FAILURE,
  UPDATE_VEHICLE_SUCCESS,
} from '@/actions';
import { round } from '@/utils';
import _ from 'lodash';

const INITIAL_STATE = {
  vehicles: [],
  vehicle: null,
  isLoading: false,
  error: null,
};

export default function vehiclesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_VEHICLES:
      return { ...state, isLoading: true, error: null };
    case FETCH_VEHICLES_SUCCESS:
      return {
        ...state,
        vehicles: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_VEHICLES_FAILURE:
      return {
        ...state,
        vehicles: [],
        isLoading: false,
        error: action.payload,
      };
    case FETCH_VEHICLE:
      return { ...state, isLoading: true, error: null };
    case FETCH_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicle: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_VEHICLE_FAILURE:
      return {
        ...state,
        vehicle: null,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_VEHICLE:
      return { ...state, isLoading: true, error: null };
    case UPDATE_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicle: {
          ...action.payload,
          lastOdometerReading: {
            distanceMiles:
              action.payload.lastOdometerReading &&
              action.payload.lastOdometerReading.distanceKilometres
                ? round(
                    action.payload.lastOdometerReading.distanceKilometres *
                      0.62137119,
                    0,
                  )
                : null,
            time:
              action.payload.lastOdometerReading &&
              action.payload.lastOdometerReading.time
                ? action.payload.lastOdometerReading.time
                : null,
          },
        },
        vehicles: state.vehicles.map((vehicle) =>
          vehicle.identificationNumber === action.payload.identificationNumber
            ? {
                identificationNumber: action.payload.identificationNumber,
                registrationNumber: action.payload.registrationNumber,
                fleetNumber: action.payload.fleetNumber,
                picture: action.payload.picture,
                searchString: [
                  action.payload.identificationNumber,
                  action.payload.registrationNumber,
                  action.payload.fleetNumber,
                ]
                  .join('+')
                  .toLowerCase(),
              }
            : vehicle,
        ),
        isLoading: false,
        error: null,
      };
    case UPDATE_VEHICLE_FAILURE:
      return {
        ...state,
        vehicle: null,
        isLoading: false,
        error: action.payload,
      };
    case CREATE_VEHICLE:
      return { ...state, isLoading: true, error: null };
    case CREATE_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicle: action.payload,
        vehicles: state.vehicles.concat({
          identificationNumber: action.payload.identificationNumber,
          registrationNumber: action.payload.registrationNumber,
          fleetNumber: action.payload.fleetNumber,
          callSign: action.payload.callSign,
          picture: action.payload.picture,
          searchString: [
            action.payload.identificationNumber,
            action.payload.registrationNumber,
            action.payload.fleetNumber,
          ]
            .join('+')
            .toLowerCase(),
        }),
        isLoading: false,
        error: null,
      };
    case CREATE_VEHICLE_FAILURE:
      return {
        ...state,
        vehicle: null,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_VEHICLE:
      return { ...state, isLoading: true, error: null };
    case DELETE_VEHICLE_SUCCESS: {
      const index = state.vehicles.findIndex(
        (vehicle) => vehicle.identificationNumber === action.payload,
      );

      return {
        ...state,
        vehicle: null,
        vehicles: state.vehicles
          .slice(0, index)
          .concat(state.vehicles.slice(index + 1)),
        isLoading: false,
        error: null,
      };
    }
    case DELETE_VEHICLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

import { ArrowUpward as ArrowUpwardIcon } from '@mui/icons-material';
import {
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material';

export function SortField({
  sortBy,
  onSortByChange,
  sortDesc,
  onSortDescToggle,
  options,
  ...props
}) {
  return (
    <TextField
      {...props}
      size="small"
      select
      fullWidth
      label="Sort by"
      value={sortBy}
      onChange={onSortByChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <Tooltip title={sortDesc ? 'Descending' : 'Ascending'}>
              <IconButton
                sx={(theme) => ({
                  transform: sortDesc ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: theme.transitions.create('transform', {
                    duration: theme.transitions.duration.shortest,
                  }),
                })}
                onClick={onSortDescToggle}
                size="small"
              >
                <ArrowUpwardIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    >
      {options.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

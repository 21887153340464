import {
  CREATE_PERSON,
  CREATE_PERSON_FAILURE,
  CREATE_PERSON_SUCCESS,
  DELETE_PERSON,
  DELETE_PERSON_FAILURE,
  DELETE_PERSON_SUCCESS,
  FETCH_PEOPLE,
  FETCH_PEOPLE_FAILURE,
  FETCH_PEOPLE_SUCCESS,
  FETCH_PERSON,
  FETCH_PERSON_FAILURE,
  FETCH_PERSON_SUCCESS,
  FETCH_POST_HIERARCHY,
  FETCH_POST_HIERARCHY_FAILURE,
  FETCH_POST_HIERARCHY_SUCCESS,
  UPDATE_PERSON,
  UPDATE_PERSON_FAILURE,
  UPDATE_PERSON_SUCCESS,
} from '@/actions';
import _ from 'lodash';

const INITIAL_STATE = {
  people: [],
  person: null,
  postHierarchy: {
    code: '',
    name: 'Root',
    children: [],
  },
  isLoading: false,
  error: null,
};

export default function peopleReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PEOPLE:
      return { ...state, isLoading: true, error: null };
    case FETCH_PEOPLE_SUCCESS:
      return {
        ...state,
        people: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_PEOPLE_FAILURE:
      return {
        ...state,
        people: [],
        isLoading: false,
        error: action.payload,
      };
    case FETCH_PERSON:
      return { ...state, isLoading: true, error: null };
    case FETCH_PERSON_SUCCESS:
      return {
        ...state,
        person: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_PERSON_FAILURE:
      return {
        ...state,
        person: null,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_PERSON:
      return { ...state, isLoading: true, error: null };
    case UPDATE_PERSON_SUCCESS:
      return {
        ...state,
        person: action.payload,
        people: state.people.map((person) =>
          person.code === action.payload.code
            ? {
                code: action.payload.code,
                picture: action.payload.picture,
                forenames: action.payload.forenames,
                surname: action.payload.surname,
                collarNumber: action.payload.collarNumber,
                rfidCards: action.payload.rfidCards,
                leavingDate: action.payload.leavingDate,

                searchString: (
                  `${action.payload.forenames} ${action.payload.surname}+` +
                  `${_.join(
                    (action.payload.rfidCards || []).map(
                      (card) => card.reference,
                    ),
                    '+',
                  )}+` +
                  `${_.join(
                    _.values(
                      _.omit(action.payload, [
                        'forenames',
                        'surname',
                        'rfidCards',
                        'picture',
                      ]),
                    ),
                    '+',
                  )}`
                ).toLowerCase(),
              }
            : person,
        ),
        isLoading: false,
        error: null,
      };
    case UPDATE_PERSON_FAILURE:
      return {
        ...state,
        person: null,
        isLoading: false,
        error: action.payload,
      };
    case CREATE_PERSON:
      return { ...state, isLoading: true, error: null };
    case CREATE_PERSON_SUCCESS:
      return {
        ...state,
        person: action.payload,
        people: state.people.concat({
          code: action.payload.code,
          picture: action.payload.picture,
          forenames: action.payload.forenames,
          surname: action.payload.surname,
          collarNumber: action.payload.collarNumber,
          rfidCards: action.payload.rfidCards,
          callSign: action.payload.callSign,
          searchString: (
            `${action.payload.forenames} ${action.payload.surname}+` +
            `${_.join(
              (action.payload.rfidCards || []).map((card) => card.reference),
              '+',
            )}+` +
            `${_.join(
              _.values(
                _.omit(action.payload, [
                  'forenames',
                  'surname',
                  'rfidCards',
                  'picture',
                ]),
              ),
              '+',
            )}`
          ).toLowerCase(),
        }),
        isLoading: false,
        error: null,
      };
    case CREATE_PERSON_FAILURE:
      return {
        ...state,
        person: null,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_PERSON:
      return { ...state, isLoading: true, error: null };
    case DELETE_PERSON_SUCCESS:
      return {
        ...state,
        person: null,
        people: state.people.filter((person) => person.code !== action.payload),
        isLoading: false,
        error: null,
      };
    case DELETE_PERSON_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_POST_HIERARCHY:
      return { ...state, isLoading: true, error: null };
    case FETCH_POST_HIERARCHY_SUCCESS:
      return {
        ...state,
        postHierarchy: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_POST_HIERARCHY_FAILURE:
      return {
        ...state,
        postHierarchy: {
          code: '',
          name: 'Root',
          children: [],
        },
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

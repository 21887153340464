import {
  Business as BusinessIcon,
  GroupWork as CallSignIcon,
  ChromeReaderMode as ChromeReaderModeIcon,
  DataUsage as DataUsageIcon,
  DirectionsCar as DirectionsCarIcon,
  ExploreOff as ExploreOffIcon,
  Feedback as FeedbackIcon,
  Description as FileIcon,
  FilterList as FilterListIcon,
  LocationSearching as FollowIcon,
  History as HistoryIcon,
  InsertChart as InsertChartIcon,
  Layers as LayersIcon,
  People as PeopleIcon,
  Person as PersonIcon,
  PlayArrow as PlayArrowIcon,
  Reorder as ReorderIcon,
  Router as RouterIcon,
  ShowChart as ShowChartIcon,
  Star as StarIcon,
  LocalOffer as TagIcon,
  Timeline as TimelineIcon,
  ViewList as ViewListIcon,
} from '@mui/icons-material';
import {
  AccountQuestion as AccountQuestionIcon,
  AxisArrow as AxisArrowIcon,
  Counter as CounterIcon,
  Speedometer as SpeedometerIcon,
} from 'mdi-material-ui';

export function AuditTypeIcon({ type }) {
  switch (type) {
    case 'Vehicle':
      return <DirectionsCarIcon style={{ fontSize: 16 }} />;
    case 'Location':
      return <BusinessIcon style={{ fontSize: 16 }} />;
    case 'Person':
      return <PersonIcon style={{ fontSize: 16 }} />;
    case 'Objective':
      return <DataUsageIcon style={{ fontSize: 16 }} />;
    case 'Chart':
      return <InsertChartIcon style={{ fontSize: 16 }} />;
    case 'Contents':
      return <ViewListIcon style={{ fontSize: 16 }} />;
    case 'Table':
      return <ReorderIcon style={{ fontSize: 16 }} />;
    case 'Speed Infractions':
      return <SpeedometerIcon style={{ fontSize: 16 }} />;
    case 'Unknown Driver Trips':
      return <AccountQuestionIcon style={{ fontSize: 16 }} />;
    case 'Double Crews':
      return <PeopleIcon style={{ fontSize: 16 }} />;
    case 'Accelerometer Events':
      return <AxisArrowIcon style={{ fontSize: 16 }} />;
    case 'Telematics Box Outages':
      return <ExploreOffIcon style={{ fontSize: 16 }} />;
    case 'Driving Scores':
      return <StarIcon style={{ fontSize: 16 }} />;
    case 'Audit':
      return <ChromeReaderModeIcon style={{ fontSize: 16 }} />;
    case 'Vehicle In Base Time':
      return <TimelineIcon style={{ fontSize: 16 }} />;
    case 'Vehicle In Locations':
      return <ShowChartIcon style={{ fontSize: 16 }} />;
    case 'Telematics Box':
      return <RouterIcon style={{ fontSize: 16 }} />;
    case 'Retrospective':
      return <HistoryIcon style={{ fontSize: 16 }} />;
    case 'Layer':
      return <LayersIcon style={{ fontSize: 16 }} />;
    case 'Replay':
      return <PlayArrowIcon style={{ fontSize: 16 }} />;
    case 'Vehicle Mileage':
      return <CounterIcon style={{ fontSize: 16 }} />;
    case 'Incident':
      return <FeedbackIcon style={{ fontSize: 16 }} />;
    case 'Filter':
      return <FilterListIcon style={{ fontSize: 16 }} />;
    case 'Tag':
      return <TagIcon style={{ fontSize: 16 }} />;
    case 'Call Sign':
      return <CallSignIcon style={{ fontSize: 16 }} />;
    case 'Follow':
      return <FollowIcon style={{ fontSize: 16 }} />;
    case 'CSV':
    case 'JSON':
    case 'GeoJSON':
      return <FileIcon style={{ fontSize: 16 }} />;
    default:
      return '?';
  }
}

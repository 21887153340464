import {
  FETCH_RETROSPECTIVE_SUCCESS,
  UPDATE_FILTER_END_TIME,
  UPDATE_FILTER_START_TIME,
} from '@/actions';
import { getAuditItem, startCase } from '@/utils';
import { Avatar, CardHeader, Skeleton } from '@mui/material';
import { format } from 'date-fns';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AuditTypeIcon } from './AuditTypeIcon';

const liveTypeToAuditTypeMap = {
  vehicles: 'Vehicle',
  people: 'Person',
  locations: 'Location',
  objectives: 'Objective',
  incidents: 'Incident',
  tags: 'Tag',
  callSigns: 'Call Sign',
};

function Placeholder() {
  return (
    <CardHeader
      sx={{ p: 0, m: 0, cursor: 'pointer' }}
      avatar={<Skeleton variant="circular" width={30} height={30} />}
      title={<Skeleton height={6} width="60%" />}
      subheader={<Skeleton height={6} width="40%" />}
    />
  );
}

export function Link({ entry }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [item, setItem] = useState(null);
  // const [open, setOpen] = useState(false);

  useEffect(() => {
    let didCancel = false;

    async function getItem(entry) {
      let type = entry.dataType;
      let id = entry.itemId;

      if (type?.startsWith('Live')) {
        const liveItem = entry.parameters;
        type = liveTypeToAuditTypeMap[liveItem?.type];
        id = liveItem?.id;
      }

      if (!type || !id) {
        return;
      }

      try {
        const item = await getAuditItem(type, id);

        if (!didCancel) {
          setItem(item);
        }
      } catch (e) {
        console.debug(e);
      }
    }

    getItem(entry);

    return () => {
      didCancel = true;
    }; // remember if we start fetching something else
  }, [entry]);

  switch (entry.dataType) {
    case 'Replay':
      return (
        <CardHeader
          sx={{ p: 0, m: 0, cursor: 'pointer' }}
          onClick={() =>
            navigate(
              `/eventreplay/${entry.parameters[entry.action].type}/${
                entry.itemId
              }`,
            )
          }
          avatar={
            <Avatar sx={{ width: 30, height: 30 }}>
              <AuditTypeIcon type="Replay" />
            </Avatar>
          }
          title="Replay"
          subheader={entry.itemId}
        />
      );
    case 'Vehicle':
    case 'Vehicle Trips':
    case 'Vehicle Location Visits': {
      const words = entry.dataType.split(' ');
      const eventType =
        words.length > 1 ? `/${words[words.length - 1].toLowerCase()}` : '';

      return item ? (
        <CardHeader
          sx={{ p: 0, m: 0, cursor: 'pointer' }}
          onClick={() => {
            if (words.length > 1) {
              dispatch({
                type: UPDATE_FILTER_START_TIME,
                payload: entry.startTime,
              });
              dispatch({
                type: UPDATE_FILTER_END_TIME,
                payload: entry.endTime,
              });
            }

            navigate(`/resources/vehicles/${entry.itemId}${eventType}`);
          }}
          avatar={
            <Avatar sx={{ width: 30, height: 30 }}>
              <AuditTypeIcon type="Vehicle" />
            </Avatar>
          }
          title={`${item.registrationNumber} ${
            words.length > 1 ? words[words.length - 1] : ''
          }`}
          subheader={
            words.length > 1
              ? `${format(new Date(entry.startTime), 'dd/MM/yyyy')} - ${format(
                  new Date(entry.endTime),
                  'dd/MM/yyyy',
                )}`
              : item.fleetNumber
          }
        />
      ) : (
        <Placeholder />
      );
    }
    case 'Person':
    case 'Person Trails':
    case 'Person Location Visits':
    case 'Driver Trips': {
      const personWords = entry.dataType.split(' ');
      const personEventType =
        personWords.length > 1
          ? `/${personWords[personWords.length - 1].toLowerCase()}`
          : '';

      return item ? (
        <CardHeader
          sx={{ p: 0, m: 0, cursor: 'pointer' }}
          onClick={() => {
            if (personWords.length > 1) {
              dispatch({
                type: UPDATE_FILTER_START_TIME,
                payload: entry.startTime,
              });
              dispatch({
                type: UPDATE_FILTER_END_TIME,
                payload: entry.endTime,
              });
            }

            navigate(`/resources/people/${entry.itemId}${personEventType}`);
          }}
          avatar={
            <Avatar sx={{ width: 30, height: 30 }}>
              <AuditTypeIcon type="Person" />
            </Avatar>
          }
          title={`${item.forenames} ${item.surname} ${
            personWords.length > 1 ? personWords[personWords.length - 1] : ''
          }`}
          subheader={
            personWords.length > 1
              ? `${format(new Date(entry.startTime), 'dd/MM/yyyy')} - ${format(
                  new Date(entry.endTime || entry.endTime),
                  'dd/MM/yyyy',
                )}`
              : item.collarNumber
          }
        />
      ) : (
        <Placeholder />
      );
    }
    case 'Location':
    case 'Person Visits':
    case 'Vehicle Visits': {
      const locationEventType =
        entry.dataType === 'Location'
          ? ''
          : `/${entry.dataType.replace(' ', '').toLowerCase()}`;

      return item ? (
        <CardHeader
          sx={{ p: 0, m: 0, cursor: 'pointer' }}
          onClick={() => {
            if (entry.dataType !== 'Location') {
              dispatch({
                type: UPDATE_FILTER_START_TIME,
                payload: entry.startTime,
              });
              dispatch({
                type: UPDATE_FILTER_END_TIME,
                payload: entry.endTime,
              });
            }

            navigate(
              `/locations/${item.type.replace(/ /g, '').toLowerCase()}s/${
                entry.itemId
              }${locationEventType}`,
            );
          }}
          avatar={
            <Avatar sx={{ width: 30, height: 30 }}>
              <AuditTypeIcon type="Location" />
            </Avatar>
          }
          title={`${item.name} ${
            entry.dataType !== 'Location' ? entry.dataType : ''
          }`}
          subheader={
            entry.dataType !== 'Location'
              ? `${format(new Date(entry.startTime), 'dd/MM/yyyy')} - ${format(
                  new Date(entry.endTime),
                  'dd/MM/yyyy',
                )}`
              : item.code
          }
        />
      ) : (
        <Placeholder />
      );
    }
    case 'Objective':
    case 'Objective Attendances': {
      const isAttendances = entry.dataType === 'Objective Attendances';
      const attendancesPath = isAttendances ? '/attendances' : '';

      return item ? (
        <CardHeader
          sx={{ p: 0, m: 0, cursor: 'pointer' }}
          onClick={() => {
            if (isAttendances) {
              dispatch({
                type: UPDATE_FILTER_START_TIME,
                payload: entry.startTime,
              });
              dispatch({
                type: UPDATE_FILTER_END_TIME,
                payload: entry.endTime,
              });
            }

            navigate(
              `/${entry.dataType
                .split(' ')[0]
                .toLowerCase()}s/${item.type.toLowerCase()}s/${
                entry.itemId
              }${attendancesPath}`,
            );
          }}
          avatar={
            <Avatar sx={{ width: 30, height: 30 }}>
              <AuditTypeIcon type={entry.dataType.split(' ')[0]} />
            </Avatar>
          }
          title={`${item.title} ${isAttendances ? ' Attendances' : ''}`}
          subheader={
            isAttendances
              ? `${format(new Date(entry.startTime), 'dd/MM/yyyy')} - ${format(
                  new Date(entry.endTime),
                  'dd/MM/yyyy',
                )}`
              : item.identifier
          }
        />
      ) : (
        <Placeholder />
      );
    }
    case 'Vehicle Daily Utilisation':
      return (
        <CardHeader
          sx={{ p: 0, m: 0, cursor: 'pointer' }}
          onClick={() => {
            dispatch({
              type: UPDATE_FILTER_START_TIME,
              payload: entry.startTime,
            });
            dispatch({
              type: UPDATE_FILTER_END_TIME,
              payload: entry.endTime,
            });

            navigate('/reports/vehicles/dailyutilisation');
          }}
          avatar={
            <Avatar sx={{ width: 30, height: 30 }}>
              <AuditTypeIcon type="Chart" />
            </Avatar>
          }
          title={entry.dataType}
          subheader={
            entry.startTime &&
            `${format(new Date(entry.startTime), 'dd/MM/yyyy')} - ${format(
              new Date(entry.endTime || entry.endTime),
              'dd/MM/yyyy',
            )}`
          }
        />
      );
    case 'Person Daily Activity':
      return (
        <CardHeader
          sx={{ p: 0, m: 0, cursor: 'pointer' }}
          onClick={() => {
            dispatch({
              type: UPDATE_FILTER_START_TIME,
              payload: entry.startTime,
            });
            dispatch({
              type: UPDATE_FILTER_END_TIME,
              payload: entry.endTime,
            });

            navigate('/reports/people/dailyactivity');
          }}
          avatar={
            <Avatar sx={{ width: 30, height: 30 }}>
              <AuditTypeIcon type="Chart" />
            </Avatar>
          }
          title={entry.dataType}
          subheader={
            entry.startTime &&
            `${format(new Date(entry.startTime), 'dd/MM/yyyy')} - ${format(
              new Date(entry.endTime),
              'dd/MM/yyyy',
            )}`
          }
        />
      );
    case 'Vehicle Hourly Utilisation':
      return (
        <CardHeader
          sx={{ p: 0, m: 0, cursor: 'pointer' }}
          onClick={() => {
            dispatch({
              type: UPDATE_FILTER_START_TIME,
              payload: entry.startTime,
            });
            dispatch({
              type: UPDATE_FILTER_END_TIME,
              payload: entry.endTime,
            });

            navigate('/reports/vehicles/hourlyutilisation');
          }}
          avatar={
            <Avatar sx={{ width: 30, height: 30 }}>
              <AuditTypeIcon type="Chart" />
            </Avatar>
          }
          title={entry.dataType}
          subheader={
            entry.startTime &&
            `${format(new Date(entry.startTime), 'dd/MM/yyyy')} - ${format(
              new Date(entry.endTime),
              'dd/MM/yyyy',
            )}`
          }
        />
      );
    case 'Person Hourly Activity':
      return (
        <CardHeader
          sx={{ p: 0, m: 0, cursor: 'pointer' }}
          onClick={() => {
            dispatch({
              type: UPDATE_FILTER_START_TIME,
              payload: entry.startTime,
            });
            dispatch({
              type: UPDATE_FILTER_END_TIME,
              payload: entry.endTime,
            });

            navigate('/reports/people/hourlyactivity');
          }}
          avatar={
            <Avatar sx={{ width: 30, height: 30 }}>
              <AuditTypeIcon type="Chart" />
            </Avatar>
          }
          title={entry.dataType}
          subheader={
            entry.startTime &&
            `${format(new Date(entry.startTime), 'dd/MM/yyyy')} - ${format(
              new Date(entry.endTime),
              'dd/MM/yyyy',
            )}`
          }
        />
      );
    case 'Vehicle In Base Time':
      return (
        <CardHeader
          sx={{ p: 0, m: 0, cursor: 'pointer' }}
          onClick={() => {
            dispatch({
              type: UPDATE_FILTER_START_TIME,
              payload: entry.startTime,
            });
            dispatch({
              type: UPDATE_FILTER_END_TIME,
              payload: entry.endTime,
            });

            navigate('/reports/vehicles/hourlyinlocations');
          }}
          avatar={
            <Avatar sx={{ width: 30, height: 30 }}>
              <AuditTypeIcon type="Vehicle In Base Time" />
            </Avatar>
          }
          title={entry.dataType}
          subheader={
            entry.startTime &&
            `${format(new Date(entry.startTime), 'dd/MM/yyyy')} - ${format(
              new Date(entry.endTime),
              'dd/MM/yyyy',
            )}`
          }
        />
      );
    case 'Vehicle In Locations':
      return (
        <CardHeader
          sx={{ p: 0, m: 0, cursor: 'pointer' }}
          onClick={() => {
            dispatch({
              type: UPDATE_FILTER_START_TIME,
              payload: entry.startTime,
            });
            dispatch({
              type: UPDATE_FILTER_END_TIME,
              payload: entry.endTime,
            });

            navigate('/reports/vehicles/timelineinlocations');
          }}
          avatar={
            <Avatar sx={{ width: 30, height: 30 }}>
              <AuditTypeIcon type="Vehicle In Locations" />
            </Avatar>
          }
          title={entry.dataType}
          subheader={
            entry.startTime &&
            `${format(new Date(entry.startTime), 'dd/MM/yyyy')} - ${format(
              new Date(entry.endTime),
              'dd/MM/yyyy',
            )}`
          }
        />
      );
    case 'Unknown Driver Trips':
    case 'Speed Infractions':
    case 'Double Crews':
    case 'Accelerometer Events': {
      const start = entry.parameters?.startTime?.$lte ?? entry.startTime;
      const end = entry.parameters?.endTime?.$gte ?? entry.endTime;
      return (
        <CardHeader
          sx={{ p: 0, m: 0, cursor: 'pointer' }}
          onClick={() => {
            dispatch({
              type: UPDATE_FILTER_START_TIME,
              payload: start,
            });
            dispatch({
              type: UPDATE_FILTER_END_TIME,
              payload: end,
            });

            navigate(
              `/reports/driving/${entry.dataType
                .replace(/ /g, '')
                .toLowerCase()}`,
            );
          }}
          avatar={
            <Avatar sx={{ width: 30, height: 30 }}>
              <AuditTypeIcon type={entry.dataType} />
            </Avatar>
          }
          title={entry.dataType}
          subheader={`${format(new Date(start), 'dd/MM/yyyy')} - ${format(
            new Date(end),
            'dd/MM/yyyy',
          )}`}
        />
      );
    }
    case 'Driving Scores':
      return (
        <CardHeader
          sx={{ p: 0, m: 0, cursor: 'pointer' }}
          onClick={() => {
            dispatch({
              type: UPDATE_FILTER_START_TIME,
              payload: entry.startTime,
            });
            dispatch({
              type: UPDATE_FILTER_END_TIME,
              payload: entry.endTime,
            });

            navigate(`/reports/driving/score`);
          }}
          avatar={
            <Avatar sx={{ width: 30, height: 30 }}>
              <AuditTypeIcon type={entry.dataType} />
            </Avatar>
          }
          title={entry.dataType}
          subheader={
            entry.startTime
              ? `${format(new Date(entry.startTime), 'dd/MM/yyyy')} - ${format(
                  new Date(entry.endTime),
                  'dd/MM/yyyy',
                )}`
              : null
          }
        />
      );
    case 'Telematics Box Outages':
      return (
        <CardHeader
          sx={{ p: 0, m: 0, cursor: 'pointer' }}
          onClick={() => {
            dispatch({
              type: UPDATE_FILTER_START_TIME,
              payload: entry.startTime,
            });
            dispatch({
              type: UPDATE_FILTER_END_TIME,
              payload: entry.endTime,
            });

            navigate('/reports/telematicsboxes/outages');
          }}
          avatar={
            <Avatar sx={{ width: 30, height: 30 }}>
              <AuditTypeIcon type={entry.dataType} />
            </Avatar>
          }
          title={entry.dataType}
          subheader={`${format(
            new Date(entry.startTime),
            'dd/MM/yyyy',
          )} - ${format(new Date(entry.endTime), 'dd/MM/yyyy')}`}
        />
      );
    case 'Vehicle Mileage':
      return (
        <CardHeader
          sx={{ p: 0, m: 0, cursor: 'pointer' }}
          onClick={() => {
            navigate(`/reports/vehicles/mileage/${entry.date || ''}`);
          }}
          avatar={
            <Avatar sx={{ width: 30, height: 30 }}>
              <AuditTypeIcon type={entry.dataType} />
            </Avatar>
          }
          title={entry.dataType}
          subheader={
            entry.date ? format(new Date(entry.date), 'dd/MM/yyyy') : ''
          }
        />
      );
    case 'Audit Log Entries':
      return (
        <CardHeader
          sx={{ p: 0, m: 0, cursor: 'pointer' }}
          onClick={() => {
            dispatch({
              type: UPDATE_FILTER_START_TIME,
              payload: entry.startTime,
            });
            dispatch({
              type: UPDATE_FILTER_END_TIME,
              payload: entry.endTime,
            });

            window.scrollTo(0, 0);
          }}
          avatar={
            <Avatar sx={{ width: 30, height: 30 }}>
              <AuditTypeIcon type="Audit" />
            </Avatar>
          }
          title={entry.dataType}
          subheader={`${format(
            new Date(entry.startTime),
            'dd/MM/yyyy',
          )} - ${format(new Date(entry.endTime), 'dd/MM/yyyy')}`}
        />
      );
    case 'Telematics Box':
    case 'Telematics Box Vehicles':
      return (
        <CardHeader
          sx={{ p: 0, m: 0, cursor: 'pointer' }}
          onClick={() => {
            navigate(`/resources/telematicsboxes/${entry.itemId}`);
          }}
          avatar={
            <Avatar sx={{ width: 30, height: 30 }}>
              <AuditTypeIcon type="Telematics Box" />
            </Avatar>
          }
          title={entry.itemId}
        />
      );
    case 'Retrospective':
      return item ? (
        <CardHeader
          sx={{ p: 0, m: 0, cursor: 'pointer' }}
          onClick={() => {
            navigate(`/retrospective/${entry.itemId}`);
          }}
          avatar={
            <Avatar sx={{ width: 30, height: 30 }}>
              <AuditTypeIcon type="Retrospective" />
            </Avatar>
          }
          title={item.title}
          subheader={entry.itemId}
        />
      ) : (
        <Placeholder />
      );
    case 'Layer':
      return (
        <CardHeader
          sx={{ p: 0, m: 0, cursor: 'pointer' }}
          onClick={() => {
            const layer = entry.parameters.Read;

            if (layer.blur) {
              layer.blur = parseInt(layer.blur);
            }

            if (layer.radius) {
              layer.radius = parseInt(layer.radius);
            }

            if (layer.distance) {
              layer.distance = parseInt(layer.distance);
            }

            if (layer.growth) {
              layer.growth = parseFloat(layer.growth);
            }

            if (layer.scale) {
              layer.scale = parseFloat(layer.scale);
            }

            if (layer.boundaryGeometry) {
              layer.boundaryGeometry.coordinates = [
                _.chunk(
                  layer.boundaryGeometry.coordinates.map((number) =>
                    parseFloat(number),
                  ),
                  2,
                ),
              ];
            }

            if (typeof layer.colors === 'string') {
              layer.colors = [layer.colors];
            }

            if (layer.filters) {
              for (let key in layer.filters) {
                if (!Array.isArray(layer.filters[key])) {
                  layer.filters[key] = [layer.filters[key]];
                }
              }
            }

            dispatch({
              type: FETCH_RETROSPECTIVE_SUCCESS,
              payload: { title: '', layers: [layer] },
            });

            navigate('/retrospective');
          }}
          avatar={
            <Avatar sx={{ width: 30, height: 30 }}>
              <AuditTypeIcon type="Layer" />
            </Avatar>
          }
          title={startCase(entry.parameters.source)}
          subheader={`${format(
            new Date(entry.parameters.startTime),
            'dd/MM/yyyy HH:mm',
          )} - ${format(
            new Date(entry.parameters.endTime),
            'dd/MM/yyyy HH:mm',
          )}`}
        />
      );
    case 'Live Resource': {
      const { type, id } = entry.parameters; //[entry.action];
      const auditType = liveTypeToAuditTypeMap[type];
      let title = undefined;
      let subheader = '';

      switch (type) {
        case 'vehicles':
          title = item?.registrationNumber;
          subheader = item?.fleetNumber;
          break;
        case 'people':
          title = `${item?.forenames} ${item?.surname}`; // `
          subheader = item?.collarNumber;
          break;
        case 'locations':
          title = item?.name;
          subheader = item?.code;
          break;
        case 'objectives':
          title = item?.title;
          subheader = item?.identifier;
          break;
        case 'incidents':
          title = item?.type?.name;
          subheader = item?.number;
          break;
        case 'tags':
          title = id;
          break;
        case 'callSigns':
          title = id;
          break;
        default:
          title = type;
          subheader = id;
          break;
      }

      return title ? (
        <CardHeader
          sx={{ p: 0, m: 0, cursor: 'pointer' }}
          onClick={() => {
            navigate(['/live', type, id].filter(Boolean).join('/'));
          }}
          avatar={
            <Avatar sx={{ width: 30, height: 30 }}>
              <AuditTypeIcon type={auditType} />
            </Avatar>
          }
          title={title}
          subheader={subheader}
        />
      ) : (
        <Placeholder />
      );
    }
    case 'CSV':
    case 'JSON':
    case 'GeoJSON':
      return (
        <CardHeader
          sx={{ p: 0, m: 0, cursor: 'pointer' }}
          avatar={
            <Avatar sx={{ width: 30, height: 30 }}>
              <AuditTypeIcon type={entry.dataType} />
            </Avatar>
          }
          title={entry.dataType + ' Download'}
          subheader={[
            entry.parameters.filename,
            entry.parameters.startTime &&
              format(new Date(entry.parameters.startTime), 'dd/MM/yyyy'),
            entry.parameters.endTime &&
              format(new Date(entry.parameters.endTime), 'dd/MM/yyyy'),
            entry.parameters.id,
          ]
            .filter(Boolean)
            .join(' ')}
          // subheader={`${format(new Date(entry.startTime),
          //   'dd/MM/yyyy'
          // )} - ${format(new Date(entry.endTime), 'dd/MM/yyyy')}`}
        />
      );
    // case 'Live List':
    //   const { searchTexts = {}, advancedFilters = {} } = entry.parameters[entry.action];
    //   const icon = (
    //     <Avatar sx={{ width: 30, height: 30 }}>
    //       <AuditTypeIcon type={'Filter'} />
    //     </Avatar>
    //   );

    //   function SearchTextsList() {
    //     return (
    //       <div>
    //         {Object.keys(searchTexts).map(type => {
    //           return <div key={type}>{`${type}: ${searchTexts[type]}`}</div>
    //         })}
    //       </div>
    //     );
    //   }

    //   function AdvancedFilterList() {
    //     return (
    //       <div>
    //         {Object.keys(advancedFilters).map(type => {
    //           // TODO JL something odd here, it's always an array when I send it
    //           // how come it's sometimes an object instead of an array in audit?
    //           const arr = Array.isArray(advancedFilters[type])
    //             ? advancedFilters[type]
    //             : [advancedFilters[type]];
    //           const afs = arr.filter(af => af.value).map(af =>
    //             `${af.field} ${af.condition} ${af.value}`).join(',');
    //           return <div key={type}>{`${type}: ${afs}`}</div>;
    //         })}
    //       </div>
    //     );
    //   }

    //   return (
    //     <Fragment>
    //       <Button onClick={() => setOpen(true)} color="primary">
    //         {icon}
    //       </Button>
    //       <Dialog
    //         open={open}
    //         onClose={() => setOpen(false)}
    //       >
    //         <DialogTitle>
    //           Live filter settings
    //         </DialogTitle>
    //         <label htmlFor="contained-button-file">{icon}</label>
    //         {SearchTextsList()}
    //         {AdvancedFilterList()}
    //         <DialogActions>
    //           <Button onClick={() => setOpen(false)} color="primary">
    //             Close
    //           </Button>
    //         </DialogActions>
    //       </Dialog>
    //     </Fragment>
    //   );
    default:
      return entry.itemId;
  }
}

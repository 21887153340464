import { useNichePerson } from '@/hooks';
import {
  CardMedia,
  ImageListItem,
  ImageListItemBar,
  Skeleton,
} from '@mui/material';

export function ActionPerson({ code, title, actionIcon }) {
  const { data: nichePerson } = useNichePerson(code);

  return nichePerson && nichePerson.length > 0 ? (
    <ImageListItem>
      <ImageListItemBar title={title} position="top" actionIcon={actionIcon} />
      <CardMedia
        component="img"
        image={nichePerson[0].person?.image}
        alt={title}
        loading="lazy"
      />
      <ImageListItemBar
        title={nichePerson[0].person?.firstName}
        subtitle={nichePerson[0].person?.nominalId}
        position="bottom"
      />
    </ImageListItem>
  ) : (
    <Skeleton variant="rectangular" width={200} height={300} />
  );
}

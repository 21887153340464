import { alertsAtom } from '@/atoms';
import ir3Logo from '@/svg/ir3Logo.svg';
import { getAuthorization, notify } from '@/utils';
import { eventNotifications, wsRootUrl } from '@/utils/config';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import { projection } from '.';

export function useNotificationSocket(isAuthenticated) {
  const queryClient = useQueryClient();
  const [, setAlerts] = useAtom(alertsAtom);
  const navigate = useNavigate();

  return useWebSocket(
    wsRootUrl,
    {
      share: true,
      shouldReconnect: () => true,
      onOpen: async (event) => {
        const authorization = await getAuthorization();

        event.target.send(
          JSON.stringify({
            action: 'SUBSCRIBE',
            authorization,
            payload: { userNotifications: { projection, changesOnly: true } },
          }),
        );

        if (eventNotifications.accelerometerAlerts) {
          event.target.send(
            JSON.stringify({
              action: 'SUBSCRIBE',
              authorization,
              payload: {
                accelerometerAlerts: {
                  query: {
                    'vehicle.identificationNumber': { $exists: true },
                  },
                  projection: {
                    identifier: true,
                    driver: {
                      code: true,
                      forenames: true,
                      surname: true,
                      collarNumber: true,
                    },
                    vehicle: {
                      registrationNumber: true,
                      fleetNumber: true,
                    },
                    time: true,
                    claimedBy: true,
                  },
                  changesOnly: true,
                },
              },
            }),
          );
        }
      },
      onMessage: (event) => {
        const { payload } = JSON.parse(event.data);
        const userNotifications = Object.values(
          payload?.userNotifications ?? {},
        );

        if (userNotifications.length > 0) {
          userNotifications.forEach(({ title, message, time }) => {
            notify(title, {
              body: message,
              icon: ir3Logo,
              timestamp: Math.floor(new Date(time)),
            });
          });

          queryClient.invalidateQueries({ queryKey: ['notifications'] });
        }

        if (eventNotifications.accelerometerAlerts) {
          const accelerometerAlerts = Object.values(
            payload?.accelerometerAlerts ?? {},
          );

          if (accelerometerAlerts.length > 0) {
            for (const alert of accelerometerAlerts) {
              if (alert.claimedBy) {
                setAlerts((alerts) =>
                  alerts.filter((a) => a.identifier !== alert.identifier),
                );
              } else {
                notify(
                  'Accelerometer Alert',
                  {
                    tag: alert.identifier,
                    body: `${
                      alert.vehicle.driver
                        ? `${alert.driver.forenames} ${alert.driver.surname} (${alert.driver.collarNumber})`
                        : 'Unknown driver'
                    } - ${alert.vehicle.registrationNumber} (${
                      alert.vehicle.fleetNumber
                    })`,
                    icon: ir3Logo,
                    timestamp: Math.floor(new Date(alert.time)),
                  },
                  () => {
                    navigate(`/live/events/${alert.identifier}`);
                  },
                );

                setAlerts((alerts) => alerts.concat(alert));
              }
            }
          }
        }
      },
      onError: () => {
        enqueueSnackbar('Notification socket error', {
          variant: 'error',
        });
      },
    },
    isAuthenticated && Object.keys(eventNotifications).length > 0,
  );
}

import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useTelematicsBoxAssignmentCounts() {
  return useQuery({
    queryKey: ['telematicsBoxAssignmentCounts'],
    queryFn: () => {
      const json = [
        { $project: { _id: true } },
        {
          $lookup: {
            from: 'vehicles',
            localField: '_id',
            foreignField: 'telematicsBoxImei',
            as: 'vehicles',
          },
        },
        {
          $set: {
            assignmentCount: {
              $size: {
                $filter: {
                  input: '$vehicles',
                  as: 'vehicle',
                  cond: { $ne: ['$$vehicle.deleted', true] },
                },
              },
            },
          },
        },
        { $group: { _id: '$assignmentCount', boxes: { $sum: 1 } } },
        { $project: { _id: false, assignments: '$_id', boxes: true } },
        { $sort: { assignments: 1 } },
      ];

      return api.post('pipeline/telematicsBoxes', { json }).json();
    },
    placeholderData: [],
  });
}

import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useEnRoutePrisoners(options) {
  const searchParams = JSON.parse(JSON.stringify(options ?? {}));

  return useQuery({
    queryKey: ['enRoutePrisoners', searchParams],
    queryFn: () => {
      return briefingApi.get('prisoners', { searchParams }).json();
    },
    placeholderData: [],
    staleTime: 15 * (60 * 1000), // only refetch 15 mins
    cacheTime: 20 * (60 * 1000), // keep data in cache 20 mins
  });
}

import {
  END_TELEMATICS_BOX_POLLS_STREAM,
  FETCH_TELEMATICS_BOXES,
} from '@/actions';
import { NavLink, SearchBox, SortField } from '@/components/controls';
import { useDocumentTitle, usePrevious } from '@/hooks';
import {
  downloadCSV,
  downloadJSON,
  get,
  getFilenameForDownload,
  log,
} from '@/utils';
import {
  Router as RouterIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useVirtualizer } from '@tanstack/react-virtual';
import { format } from 'date-fns';
import { dequal } from 'dequal';
import _ from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import { CommissioningDialogProvider } from './CommissioningDialog';

export function TelematicsBoxes() {
  useDocumentTitle('IR3 | Telematics Boxes');
  const { id } = useParams();
  const prevId = usePrevious(id);
  const boxes = useSelector((state) => state.telematicsBoxes.boxesByImei);
  const [filterText, setFilterText] = useState('');
  const [searchText, setSearchText] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [sortBy, setSortBy] = useState('imei');
  const [sortDesc, setSortDesc] = useState(false);
  const parentRef = useRef();

  // log when a new id is navigated to
  useEffect(() => {
    if (id && id !== prevId) {
      log('Read', 'Telematics Box', { id });
    }
  }, [id, prevId]);

  // get the tbs when starting up
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: FETCH_TELEMATICS_BOXES });

    return () => {
      dispatch({ type: END_TELEMATICS_BOX_POLLS_STREAM });
    };
  }, [dispatch]);

  const error = useSelector((state) => state.telematicsBoxes.error, dequal);
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error]);

  const sortOptions = [
    { label: 'Registration', value: 'registrationNumber' },
    { label: 'Fleet Number', value: 'fleetNumber' },
    { label: 'Last Poll Time', value: 'mostRecentTime' },
    { label: 'VIN', value: 'identificationNumber' },
    { label: 'IMEI', value: 'imei' },
  ];

  const propsToSearch = Object.values(sortOptions)
    .map((o) => o.value)
    .filter((s) => s !== 'mostRecentTime');

  const filters = [
    { label: 'All', value: 'all' },
    { label: 'No vehicle', value: 'noVehicle' },
    { label: 'Never polled', value: 'neverPolled' },
    { label: 'Multiple assignments', value: 'multiAssign' },
  ];

  const filteredList = useMemo(() => {
    return _.orderBy(
      Object.values(boxes)
        .filter(
          (item) =>
            !searchText ||
            propsToSearch.some((prop) =>
              get(item, prop)
                ?.toLowerCase()
                ?.includes(searchText.toLowerCase()),
            ),
        )
        .filter((item) => {
          switch (filterText) {
            case 'noVehicle':
              return !item.identificationNumber;
            case 'neverPolled':
              return !item.mostRecentTime;
            case 'multiAssign':
              return item.isMultiAssigned;
            default:
              return true;
          }
        }),
      [sortBy],
      [sortDesc ? 'desc' : 'asc'],
    );
  }, [boxes, filterText, propsToSearch, searchText, sortBy, sortDesc]);

  const rowVirtualizer = useVirtualizer({
    count: filteredList.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 56,
    overscan: 10,
  });

  function handleSearchChange(event) {
    const term = event.target.value;

    setSearchText(term);
  }

  function handleFilterChange(event) {
    const term = event.target.value;

    setFilterText(term);
  }

  function handleSortByChange(event) {
    const prop = event.target.value;

    setSortBy(prop);
  }

  function handleSortToggle() {
    setSortDesc(!sortDesc);
  }

  function handleSettingsToggle() {
    setShowSettings(!showSettings);
  }

  async function handleCsvClick() {
    const filename = getFilenameForDownload('Telematics Boxes', 'csv');

    downloadCSV(
      Object.values(boxes).map((b) => ({
        ...b,
        //imei: `\t${b.imei}`,
        lastPollTime: b.mostRecentTime
          ? format(new Date(b.mostRecentTime), 'dd/MM/yyyy HH:mm:ss')
          : '',
      })),
      filename,
      [
        { label: 'IMEI', key: 'imei' },
        { label: 'Last poll time', key: 'lastPollTime' },
        { label: 'Fleet Number', key: 'fleetNumber' },
        { label: 'Registration', key: 'registrationNumber' },
        { label: 'VIN', key: 'identificationNumber' },
      ],
    );
  }

  async function handleJsonClick() {
    const filename = getFilenameForDownload('telematicsBoxes', 'json');
    downloadJSON(Object.values(boxes), filename);
  }

  return (
    <CommissioningDialogProvider>
      <Box sx={{ display: 'flex', height: 'calc(100vh - 48px)' }}>
        <Stack
          sx={{
            width: 280,
            borderRight: 1,
            borderColor: 'divider',
            flexShrink: 0,
            height: 1,
          }}
        >
          <Box>
            <Stack
              direction="row"
              spacing={1}
              sx={{ p: 1, alignItems: 'center' }}
            >
              <SearchBox
                value={searchText}
                onChange={handleSearchChange}
                count={`${filteredList.length}/${Object.keys(boxes).length}`}
                sx={{ flexGrow: 1 }}
              />
              <Tooltip title={showSettings ? 'Hide settings' : 'Show settings'}>
                <IconButton size="small" onClick={handleSettingsToggle}>
                  <SettingsIcon
                    fontSize="inherit"
                    color={showSettings ? 'primary' : 'inherit'}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
            <Collapse in={showSettings} unmountOnExit>
              <Stack sx={{ p: 1 }} spacing={1}>
                <SortField
                  sortBy={sortBy}
                  onSortByChange={handleSortByChange}
                  sortDesc={sortDesc}
                  onSortDescToggle={handleSortToggle}
                  options={sortOptions}
                />
              </Stack>
              <Stack sx={{ px: 1, pb: 1 }} spacing={1}>
                <TextField
                  size="small"
                  select
                  fullWidth
                  label="Filter"
                  value={filterText}
                  onChange={handleFilterChange}
                >
                  {filters.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
                <Divider>
                  <Typography variant="caption" color="textSecondary">
                    Export
                  </Typography>
                </Divider>
                <Stack
                  direction="row"
                  // sx={{ p: 1 }}
                  spacing={1}
                  justifyContent="center"
                >
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={handleCsvClick}
                  >
                    CSV
                  </Button>
                  <Button
                    fullWidth
                    color="secondary"
                    variant="contained"
                    disableElevation
                    onClick={handleJsonClick}
                  >
                    JSON
                  </Button>
                </Stack>
              </Stack>
            </Collapse>
          </Box>
          <Divider />
          <Box ref={parentRef} sx={{ overflow: 'auto', height: 1 }}>
            <Box
              sx={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: '100%',
                position: 'relative',
              }}
            >
              {rowVirtualizer
                .getVirtualItems()
                .map(({ index, size, start }) => {
                  const item = filteredList[index];

                  const badgeNumber = item.isMultiAssigned
                    ? item.multiAssignments.length
                    : item.identificationNumber
                    ? 1
                    : 0;
                  const badgeColor = item.isMultiAssigned ? 'error' : 'primary';

                  return (
                    <ListItemButton
                      dense
                      key={index}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: `${size}px`,
                        transform: `translateY(${start}px)`,
                      }}
                      component={NavLink}
                      to={`/resources/telematicsboxes/${encodeURIComponent(
                        item.imei,
                      )}`}
                      selected={id === item.imei}
                    >
                      <ListItemAvatar>
                        {badgeNumber > 0 ? (
                          <Badge badgeContent={badgeNumber} color={badgeColor}>
                            <Avatar>
                              <RouterIcon />
                            </Avatar>
                          </Badge>
                        ) : (
                          <Avatar>
                            <RouterIcon />
                          </Avatar>
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        primary={item.imei}
                        secondary={
                          sortBy === 'mostRecentTime'
                            ? !!item[sortBy] &&
                              format(
                                new Date(item[sortBy]),
                                'dd/MM/yyyy HH:mm:ss',
                              )
                            : sortBy === 'imei'
                            ? ''
                            : item[sortBy]
                        }
                      />
                    </ListItemButton>
                  );
                })}
            </Box>
          </Box>
        </Stack>
        <Box sx={{ overflow: 'auto', flex: 1 }}>
          <Outlet />
        </Box>
      </Box>
    </CommissioningDialogProvider>
  );
}

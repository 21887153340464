// import { useMemo } from 'react';
import { useMemo } from 'react';
import { useOptions } from '../option';
import { useGroups } from './useGroups';

export function useGroupOptions() {
  const { data } = useGroups();
  const { data: typeOptions } = useOptions('groupType');

  return useMemo(() => {
    return data
      .concat()
      .sort(
        (a, b) =>
          -(typeOptions[b.type] ?? '').localeCompare(typeOptions[a.type] ?? ''),
      )
      .reduce(
        (accumulator, { code, name, type }) => ({
          ...accumulator,
          [code]: { name, type: typeOptions[type] },
        }),
        {},
      );
  }, [data, typeOptions]);
}

import { tripClassifications } from '@/utils/config';
import {
  Dialog,
  DialogTitle,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';

export function ClassificationDialog({ onClose, open }) {
  function handleClose() {
    onClose(null);
  }

  const handleListItemClick = (value) => () => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="classification-dialog-title"
      open={open}
    >
      <DialogTitle id="classification-dialog-title">
        Select classification
      </DialogTitle>
      <List>
        {tripClassifications.map((classification) => (
          <ListItemButton
            onClick={handleListItemClick(classification.value)}
            key={classification.value}
          >
            <ListItemText primary={classification.label} />
          </ListItemButton>
        ))}
      </List>
    </Dialog>
  );
}

import { CustomTooltip } from '@/components/controls';
import { useSubgroupActivityAverages } from '@/hooks';
import {
  getKeyLabel,
  getTextWidth,
  humanizeHours,
  sortByKey,
  startCase,
} from '@/utils';
import { baseType, highlights } from '@/utils/config';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  brown,
  cyan,
  green,
  orange,
  purple,
  red,
  teal,
} from '@mui/material/colors';
import { Fragment, useMemo, useState } from 'react';
import {
  Bar,
  BarChart,
  Tooltip as ChartTooltip,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

const {
  subgroupActivityAverages: { lookbackDays },
} = highlights;

const bars = {
  loggedInHours: { label: 'On Radio', colour: green[500] },
  tripsDurationHours: { label: 'Driving', colour: purple[500] },
  inBaseHours: { label: `In ${baseType.label}`, colour: brown[500] },
  inHomeWardHours: { label: 'In Home Ward', colour: orange[500] },
  respondingToIncidentHours: {
    label: 'Responding to Incidents',
    colour: red[500],
  },
  attendingObjectiveHours: {
    label: 'Attending Objectives',
    colour: teal[500],
  },
  doubleCrewingHours: { label: 'Double Crewing', colour: cyan[500] },
};

export function DailyActivityCard({ groupType, groupId, subgroupType }) {
  const [sortBy, setSortBy] = useState('loggedInHours');
  const [hiddenBars, setHiddenBars] = useState([]);
  const { data } = useSubgroupActivityAverages(
    groupType,
    groupId,
    subgroupType,
    lookbackDays ?? 28,
  );

  const sortedData = useMemo(
    () => data.sort(sortByKey(sortBy)).slice(),
    [data, sortBy],
  );

  const xAxisHeight =
    data.length > 0
      ? Math.ceil(
          Math.max(
            ...data.map((item) =>
              getTextWidth(item.identifier ?? '', '12px Roboto'),
            ),
          ),
        )
      : 30;

  function handleLegendClick(selectedBar) {
    const index = hiddenBars.indexOf(selectedBar);

    if (index === -1) {
      setHiddenBars(hiddenBars.concat(selectedBar));
    } else {
      setHiddenBars(
        hiddenBars.slice(0, index).concat(hiddenBars.slice(index + 1)),
      );
    }
  }

  function handleSortByChange(event) {
    setSortBy(event.target.value);
  }

  return (
    <Card variant="outlined" sx={{ flexGrow: 1 }}>
      <CardContent>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            Daily Activity
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {`Average activity, per person, per day, over the last ${lookbackDays} days`}
          </Typography>
        </Stack>
        <Box sx={{ pt: 1, fontSize: 12 }}>
          <Box
            sx={{
              pl: 8,
              pr: 2,
              pb: 1,
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            {Object.entries(bars).map(([key, { label, colour }]) => (
              <Box
                key={key}
                sx={{
                  p: 0.5,
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => handleLegendClick(key)}
              >
                <Avatar
                  sx={{
                    width: 12,
                    height: 12,
                    mr: 0.5,
                    bgcolor: !hiddenBars.includes(key) && colour,
                  }}
                >
                  <Fragment />
                </Avatar>
                <Typography variant="caption">{label}</Typography>
              </Box>
            ))}
          </Box>
          <ResponsiveContainer width="99%" height={520}>
            <BarChart
              data={sortedData}
              margin={{ top: 0, right: 16, left: 0, bottom: 32 }}
              barGap={0}
              barCategoryGap={16}
            >
              <XAxis
                dataKey="identifier"
                tickFormatter={startCase}
                angle={-90}
                textAnchor="end"
                interval={0}
                height={xAxisHeight}
              >
                <Label
                  value={getKeyLabel(subgroupType)}
                  offset={16}
                  position="bottom"
                />
              </XAxis>
              <YAxis>
                <Label
                  value={'Hours'}
                  angle={-90}
                  position="left"
                  offset={-24}
                />
              </YAxis>
              <ChartTooltip
                wrapperStyle={{ outline: 'none' }} // TODO: This is temporary due to a bug in recharts https://github.com/recharts/recharts/issues/2920
                cursor={data.length > 0}
                content={
                  <CustomTooltip
                    labelFormatter={startCase}
                    valueFormatter={humanizeHours}
                  />
                }
              />
              {Object.entries(bars).map(([key, { label, colour }]) => (
                <Bar
                  key={key}
                  dataKey={key}
                  name={label}
                  fill={colour}
                  hide={hiddenBars.includes(key)}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </Box>
        <Stack
          spacing={2}
          direction="row"
          sx={{ w: 1, display: 'flex', justifyContent: 'center' }}
        >
          <TextField
            sx={{ width: 240 }}
            size="small"
            select
            label="Sort by"
            value={sortBy}
            onChange={handleSortByChange}
          >
            {Object.entries(bars).map(([key, { label }]) => (
              <MenuItem key={key} value={key}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </CardContent>
    </Card>
  );
}

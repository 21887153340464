import { LiveListItem } from './LiveListItem';

export function ObjectiveLiveListItem(props) {
  return (
    <LiveListItem
      type="objectives"
      primaryPath="title"
      secondaryPath="id"
      hideFollow={!props.item.boundary}
      {...props}
    />
  );
}

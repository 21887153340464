import { parseIfJSON } from '@/utils';
import { Stack, Typography } from '@mui/material';
import { Popup } from 'react-map-gl/maplibre';
import { Status } from './Status';

export function PersonPopup({
  hoverInfo: {
    longitude,
    latitude,
    feature: { properties },
  },
}) {
  const person = properties.person ? parseIfJSON(properties.person) : null;

  return (
    <Popup
      longitude={longitude}
      latitude={latitude}
      offset={[0, -16]}
      closeButton={false}
    >
      <Stack spacing={1}>
        <Typography variant="subtitle2">
          {person?.code ?? properties.ssi}
        </Typography>
        <Status status={properties.status} />
      </Stack>
    </Popup>
  );
}

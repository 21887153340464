import { END_LIVE_STREAM, START_LIVE_STREAM } from '@/actions';
import { LoginAvatar } from '@/components/controls';
import { useAuth, useDocumentTitle, usePrevious } from '@/hooks';
import { log, startCase } from '@/utils';
import {
  isFleet,
  liveCanEditViewsGroup,
  liveCanEditViewsGroups,
} from '@/utils/config';
import { AppBar, Box, Drawer, Toolbar, useMediaQuery } from '@mui/material';
import { dequal } from 'dequal';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LiveList } from './LiveList';
import { LiveMap } from './LiveMap';
import { LiveViews } from './LiveViews';
import { TypeSelectorBar } from './TypeSelectorBar';
import { liveSubscription } from './constants';

function mapType(type) {
  switch (type) {
    case 'accelerometerAlerts':
      return 'vehicleEvents';
    default:
      return type;
  }
}

//class Live extends Component {
export function Live() {
  // console.log('Live render');

  // const { auth } = props;
  const auth = useAuth();
  const [authorisation, setAuthorisation] = useState(null);
  const socketError = useSelector((state) => state.live.socketError);
  const generalError = useSelector((state) => state.live.generalError);
  const error = socketError || generalError;
  const status = useSelector((state) => state.live.status);
  const warning = useSelector((state) => state.live.socketWarning);
  const location = useLocation();

  function checkAuth(group) {
    if (Array.isArray(group)) {
      return group.some((g) => auth.getGroupNames()?.includes(g));
    }

    return !!group && auth.getGroupNames()?.includes(group);
  }
  const canEdit = checkAuth(liveCanEditViewsGroup ?? liveCanEditViewsGroups);

  useEffect(() => {
    const newAuthorisation = auth.getAuthorisation();

    setAuthorisation((authorisation) =>
      dequal(newAuthorisation, authorisation)
        ? authorisation
        : newAuthorisation,
    );
  }, [auth]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
      console.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (status) {
      enqueueSnackbar(status, { variant: 'info' });
    }
  }, [status]);

  useEffect(() => {
    if (warning) {
      enqueueSnackbar(warning, { variant: 'warning' });
      console.warn(warning);
    }
  }, [warning]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { type = 'vehicles', id } = useParams();

  const prevId = usePrevious(id);
  useEffect(() => {
    if (id && prevId !== id) {
      setDrawerOpen(true);
    }
  }, [prevId, id]);

  const [authorisedTypes, setAuthorisedTypes] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  // JLSETTINGS const [showSettings, setShowSettings] = useState(false);
  const [itemInteractions, setItemInteractions] = useState({
    hoveredItem: {},
    selectedItem: {},
    scrollToItem: {},
  });
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (authorisation) {
      // init authorised types once (instead of sub-components repeating it on refresh)
      let authTypes = Object.keys(liveSubscription).filter(
        (type) => authorisation[mapType(type)]?.changes,
      );
      // accelerometerEvents is often shortened to events so use that
      if (authTypes.includes('accelerometerAlerts')) {
        authTypes.push('accelerometerEvents');
        authTypes.push('events');
      }

      if (isFleet) {
        const fleetTypes = ['vehicles', 'locations'];
        authTypes = authTypes.filter((t) => fleetTypes.includes(t));
      }

      if (authTypes.includes('people')) {
        authTypes.push('callSigns');
      }

      // testing authTypes = ['locations'];
      authTypes.push('tags');
      authTypes.push('radios');
      authTypes.push('telematicsBoxes');
      setAuthorisedTypes(authTypes);
      // testing setAuthorisedTypes(authTypes.concat(['events']));
      // testing setAuthorisedTypes(['vehicles', 'people', 'incidents']);

      dispatch({
        type: START_LIVE_STREAM,
        payload: {
          //subscriptions: subscriptions.filter(s => authTypes.includes(s.type))
          subscriptionTypes: authTypes,
        },
      });

      return () => {
        dispatch({ type: END_LIVE_STREAM });
      };
    }
  }, [authorisation, dispatch]);

  // not currently used
  // const handleOpen = () => {
  //   setDrawerOpen(true);
  // };

  const handleClose = () => {
    setDrawerOpen(false);
  };

  // const login = () => {
  //   props.auth.login();
  //   props.handleMenuClose();
  // }

  // const logout = () => {
  //   props.auth.logout();
  //   props.handleMenuClose();
  // }

  // detail select will be handled by change in route e.g. /live/vehicles/1234
  // handleDetailSelect = item => {
  //   if (!item) {
  //     this.setState({ scrollToItem: {}, hoveredItem: {}, selectedItem: {} });
  //   } else {
  //     if (item.type) item.listName = item.type && liveTypes[item.type].listName;
  //     this.setState({
  //       scrollToItem: item,
  //       hoveredItem: {},
  //       selectedItem: item
  //     });
  //   }
  // };

  function handleItemSelect(item, scrollToItem) {
    if (!item || !item.id) {
      // if we were looking at an id, go back to the type
      if (id) {
        navigate(`/live/${type}`);
      }
      setItemInteractions({
        scrollToItem: {},
        hoveredItem: {},
        selectedItem: {},
      });
    } else {
      const newRoute =
        item.route || `/live/${item.type}/${encodeURIComponent(item.id)}`;
      if (newRoute !== location.pathname) {
        navigate(newRoute);
      }

      log('Read', 'Live Resource', item);

      setItemInteractions({
        scrollToItem,
        hoveredItem: item,
        selectedItem: item,
      });
    }
  }

  function handleSelectFromMap(item) {
    handleItemSelect(item, item);
  }

  function handleSelectFromList(item) {
    handleItemSelect(item, {});
  }

  const handleHover = (item) => {
    setItemInteractions((interactions) => ({
      ...interactions,
      hoveredItem: item,
    }));
  };

  const handleTypeChange = (type) => {
    setItemInteractions({
      scrollToItem: {},
      hoveredItem: {},
      selectedItem: {},
    });

    // JLSETTINGS setShowSettings(false);
    setDrawerOpen(true);

    // this will do a filter change
    navigate(`/live/${type}`);
  };

  const handleSettingsSelected = () => {
    // JLSETTINGS setShowSettings(true);
    setDrawerOpen(true);

    navigate('/live/settings');
  };

  const renderList = () => {
    return (
      <LiveList
        selectedItem={itemInteractions.selectedItem}
        hoveredItem={itemInteractions.hoveredItem}
        scrollToItem={itemInteractions.scrollToItem}
        auth={auth}
        onHover={handleHover}
        onSelect={handleSelectFromList}
      />
    );
  };

  // JLSETTINGS
  // const renderSettings = () => {
  //   return <Box style={{background: 'red', height: '100%'}}></Box>;
  // }

  const renderContent = () => {
    // JLSETTINGS return showSettings ? renderSettings() : renderList();
    return renderList();
  };

  const showList = id == null;
  useDocumentTitle(showList && `IR3 | Live | ${startCase(type)}`);

  return (
    <Box>
      <Box>
        <AppBar>
          <Toolbar
            variant="dense"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
            disableGutters
          >
            <Box
              sx={(theme) => ({
                width: 420,
                [theme.breakpoints.down('sm')]: {
                  width: 1,
                },
              })}
            >
              <TypeSelectorBar
                showBack={!showList}
                type={type}
                onTypeChange={handleTypeChange}
                onSettingsSelected={handleSettingsSelected}
                authorisedTypes={authorisedTypes}
              />
            </Box>
            {!isMobile && (
              <Box
                sx={{
                  display: 'flex',
                  pr: 1.5,
                  zIndex: 100,
                  minHeight: 48,
                  maxHeight: 48,
                  width: 400,
                  minWidth: 250,
                  maxWidth: 400,
                }}
              >
                <LiveViews authorisedTypes={authorisedTypes} />

                <Box sx={{ ml: canEdit ? undefined : 1.5, mt: 0.75 }}>
                  <LoginAvatar />
                </Box>
              </Box>
            )}
          </Toolbar>
        </AppBar>
        {isMobile ? (
          <Drawer anchor="bottom" open={drawerOpen} onClose={handleClose}>
            <Box sx={{ maxHeight: '80vh', overflow: 'scroll' }}>
              {renderContent()}
            </Box>
          </Drawer>
        ) : (
          <Box
            sx={(theme) => ({
              pt: 6,
              width: 420,
              [theme.breakpoints.down('sm')]: {
                paddingTop: 0,
                width: 1,
              },
              float: 'left',
              height: '100vh',
              overflowY: 'auto',
              overflowX: 'hidden',
            })}
          >
            {renderContent()}
          </Box>
        )}
        <Box
          sx={(theme) => ({
            height: 'calc(100vh - 48px)', // used for full app bar
            top: 48, // used for full app bar
            position: 'relative', // used for full app bar
            width: 'calc(100% - 420px)',
            [theme.breakpoints.down('sm')]: {
              pt: 6,
              width: 1,
              height: '100vh',
              top: 0,
            },
            float: 'left',
            overflowY: 'auto',
            overflowX: 'hidden',
          })}
        >
          {authorisedTypes.length > 0 && (
            <LiveMap
              authorisedTypes={authorisedTypes}
              selectedItem={itemInteractions.selectedItem}
              hoveredItem={itemInteractions.hoveredItem}
              onHover={handleHover}
              onSelect={handleSelectFromMap}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

import { useBrief, useOptions } from '@/hooks';
import { Chip, Stack, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useParams } from 'react-router-dom';

export function BriefHeader() {
  const { code } = useParams();
  const { data: brief } = useBrief(code);
  const { data: typeOptions } = useOptions('groupType');

  return (
    <Fragment>
      <Typography variant="h6">Briefs</Typography>
      <Typography variant="h6" sx={{ px: 0.5 }}>
        |
      </Typography>
      <Typography variant="h6" sx={{ flexGrow: 1 }} noWrap>
        {brief?.title}
      </Typography>
      <Stack spacing={1} direction="row" overflow="auto" sx={{ pr: 1 }}>
        {brief?.groups?.map((group) => (
          <Chip
            key={group.code}
            label={group.name}
            icon={
              <Chip
                color="primary"
                label={typeOptions[group.type]}
                size="small"
              />
            }
            sx={{ bgcolor: 'background.paper' }}
          />
        ))}
      </Stack>
    </Fragment>
  );
}

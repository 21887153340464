import { dioStates, liveOptions, locationTypes } from '@/utils/config';
import {
  GroupWork as CallSignIcon,
  DirectionsCar as CarIcon,
  FilterList as FilteredIcon,
  Feedback as IncidentIcon,
  Business as LocationIcon,
  TwoWheeler as MotorcycleIcon,
  NotInterested as NotInterestedIcon,
  DataUsage as ObjectivesIcon,
  Person as PersonIcon,
  Router as RouterIcon,
  AccessTime as StaleIcon,
  More as TagsIcon,
} from '@mui/icons-material';
import { SvgIcon } from '@mui/material';
import { subMinutes } from 'date-fns';
import {
  AxisArrow as EventIcon,
  RadioHandheld as RadioHandheldIcon,
} from 'mdi-material-ui';
import { CallSignDetail } from './LiveList/CallSignDetail';
import { CallSignLiveListItem } from './LiveList/CallSignLiveListItem';
import { EventLiveItem } from './LiveList/EventLiveItem';
import { EventLiveListItem } from './LiveList/EventLiveListItem';
import { IncidentLiveItem } from './LiveList/IncidentLiveItem';
import { IncidentLiveListItem } from './LiveList/IncidentLiveListItem';
import { LocationLiveItem } from './LiveList/LocationLiveItem';
import { LocationLiveListItem } from './LiveList/LocationLiveListItem';
import { ObjectiveLiveItem } from './LiveList/ObjectiveLiveItem';
import { ObjectiveLiveListItem } from './LiveList/ObjectiveLiveListItem';
import { PeopleLiveListItem } from './LiveList/PeopleLiveListItem';
import { PersonLiveItem } from './LiveList/PersonLiveItem';
import { RadioLiveItem } from './LiveList/RadioLiveItem';
import { RadioLiveListItem } from './LiveList/RadioLiveListItem';
import { TagDetail } from './LiveList/TagDetail';
import { TagListItem } from './LiveList/TagListItem';
import { TelematicsBoxLiveItem } from './LiveList/TelematicsBoxLiveItem';
import { TelematicsBoxLiveListItem } from './LiveList/TelematicsBoxLiveListItem';
import { VehicleLiveItem } from './LiveList/VehicleLiveItem';
import { VehicleLiveListItem } from './LiveList/VehicleLiveListItem';

const locationIcons = Object.values(locationTypes)
  .filter((type) => !type.isBoundary)
  .reduce((acc, { value, d }) => {
    acc[value] = () => (
      <SvgIcon>
        <path d={d} />
      </SvgIcon>
    );

    return acc;
  }, {});

export const typeIcons = {
  vehicles: CarIcon,
  MOTORCYCLE: MotorcycleIcon,
  telematicsBoxes: RouterIcon,
  locations: LocationIcon,
  people: PersonIcon,
  radios: RadioHandheldIcon,
  callSigns: CallSignIcon,
  events: EventIcon,
  incidents: IncidentIcon,
  objectives: ObjectivesIcon,
  Exclusion: NotInterestedIcon,
  stale: StaleIcon,
  filtered: FilteredIcon,
  tags: TagsIcon,
  ...locationIcons,
};

export const singularToPluralTypeMap = {
  vehicle: 'vehicles',
  location: 'locations',
  person: 'people',
  event: 'events',
  incident: 'incidents',
  objective: 'objectives',
  tag: 'tags',
  callSign: 'callSigns',
  radio: 'radios',
  telematicsBox: 'telematicsBoxes',
};

export const pluralToSingularTypeMap = {
  vehicles: 'vehicle',
  locations: 'location',
  people: 'person',
  events: 'event',
  incidents: 'incident',
  objectives: 'objective',
  tags: 'tag',
  callSigns: 'callSign',
  radios: 'radio',
  telematicsBoxes: 'telematicsBox',
};

const {
  incidentLookbackMinutes = 60 * 24 * 7,
  incidentLookbackExemptGrades = [],
  radioLookbackMinutes = 120,
  accelAlertLookbackMinutes = 120,
} = import.meta.env.DEV
  ? {
      incidentLookbackMinutes: 60 * 24 * 365 * 10,
      incidentLookbackExemptGrades: [1, 2],
      radioLookbackMinutes: 60 * 24 * 365 * 5,
      accelAlertLookbackMinutes: 60 * 24 * 365 * 5,
    }
  : liveOptions;

export const labelPaths = {
  vehicles: 'registrationNumber',
  locations: 'name',
  people: 'name',
  events: 'code',
  incidents: 'number',
  objectives: 'title',
  // tags: 'name', // doesn't have a map label
  // callSigns: 'name', // doesn't have a map label
  radios: 'ssi',
  telematicsBoxes: 'imei',
};

export const listComponentsByType = {
  vehicles: {
    label: 'Vehicles',
    listItemComponent: VehicleLiveListItem,
    itemComponent: VehicleLiveItem,
  },
  locations: {
    label: 'Locations',
    listItemComponent: LocationLiveListItem,
    itemComponent: LocationLiveItem,
  },
  people: {
    label: 'People',
    listItemComponent: PeopleLiveListItem,
    itemComponent: PersonLiveItem,
  },
  events: {
    label: 'Events',
    listItemComponent: EventLiveListItem,
    itemComponent: EventLiveItem,
  },
  incidents: {
    label: 'Incidents',
    listItemComponent: IncidentLiveListItem,
    itemComponent: IncidentLiveItem,
  },
  objectives: {
    label: 'Objectives',
    listItemComponent: ObjectiveLiveListItem,
    itemComponent: ObjectiveLiveItem,
  },
  tags: {
    label: 'Tags',
    listItemComponent: TagListItem,
    itemComponent: TagDetail,
  },
  callSigns: {
    label: 'Call Signs',
    listItemComponent: CallSignLiveListItem,
    itemComponent: CallSignDetail,
  },
  radios: {
    label: 'Radios',
    listItemComponent: RadioLiveListItem,
    itemComponent: RadioLiveItem,
  },
  telematicsBoxes: {
    label: 'Telematics Boxes',
    listItemComponent: TelematicsBoxLiveListItem,
    itemComponent: TelematicsBoxLiveItem,
  },
};

export const liveSubscription = {
  objectives: {
    query: {
      startTime: { $lte: new Date().toISOString() },
      endTime: { $gte: new Date().toISOString() },
    },
    projection: {
      identifier: true,
      title: true,
      description: true,
      startTime: true,
      endTime: true,
      complianceSeconds: true,
      requiredVisits: true,
      requiredFrequency: true,
      boundaryType: true,
      boundarySubtype: true,
      boundaryIdentifier: true,
      boundary: true,
      created: true,
      lastEdit: true,
      occurrenceNumber: true,
      schedule: true,
      applicableTo: true,
      visibleTo: true,
      microbeats: true,
    },
  },
  people: {
    query: {
      radioSsi: { $nin: [null, ''] },
      lastPollTime: {
        $gte: subMinutes(new Date(), radioLookbackMinutes).toISOString(),
      },
    },
    projection: {
      code: true,
      collarNumber: true,
      forenames: true,
      surname: true,
      category: true,
      duties: true,
      radioSsi: true,
      rfidCards: true,
      skills: true,
      position: true,
      lastPollTime: true,
      currentLocations: true,
      headingDegrees: true,
      speedKilometresPerHour: true,
      gpsAvailable: true,
      emergencyButtonOn: true,
      role: true,
      // tags: true,
      // tagChanged: true,
      assignments: true,
      homeStation: true,
      rank: true,
      groups: true,
      groupCodes: true,
    },
  },
  locations: {
    query: {
      startTime: { $lte: new Date().toISOString() },
      endTime: { $gte: new Date().toISOString() },
      type: {
        $in: Object.values(locationTypes)
          .filter((type) => !type.isBoundary)
          .map((type) => type.value),
      },
    },
    projection: {
      code: true,
      name: true,
      type: true,
      district: true,
      subtype: true,
      boundary: true,
      groups: true,
      groupCodes: true,
      // tags: true,
      // tagChanged: true,
    },
  },
  accelerometerAlerts: {
    query: {
      eventType: 'ACCELEROMETERALERT',
      time: {
        $gte: subMinutes(new Date(), accelAlertLookbackMinutes).toISOString(),
      },
    },
    projection: {
      identifier: true,
      eventType: true,
      vehicle: true,
      driver: true,
      locations: true,
      time: true,
      point: true,
      claimedBy: true,
      // tags: true,
      // tagChanged: true,
    },
  },
  accelerometerEvents: {
    query: {
      eventType: 'ACCELEROMETEREVENT',
      startTime: {
        $gte: subMinutes(new Date(), accelAlertLookbackMinutes).toISOString(),
      },
    },
    projection: {
      identifier: true,
      accelerometerData: true,
      deviceProperties: true,
      startTime: true,
      endTime: true,
      vehicle: true,
      maximumForces: true,
    },
  },
  incidents: {
    query: {
      $or: [
        {
          openedTime: {
            $gte: subMinutes(new Date(), incidentLookbackMinutes).toISOString(),
          },
        },
        { grade: { $in: incidentLookbackExemptGrades } },
      ],
      status: { $ne: 'closed' },
      grade: { $ne: 0 },
    },
    projection: {
      number: true,
      description: true,
      address: true,
      type: true,
      category: true,
      grade: true,
      point: true,
      openedTime: true,
      assignedTime: true,
      attendedTime: true,
      closedTime: true,
      status: true,
      closingCodes: true,
      // tags: true,
      // tagChanged: true,
      responseCategory: true,
      date: true,
      reference: true,
      locations: true,
      reverseGeocode: true,
      groups: true,
      ward: true,
    },
  },
  vehicles: {
    query: {
      telematicsBoxImei: { $nin: [null, ''] },
      lastPollTime: { $exists: true },
      $or: [
        { disposalDate: { $exists: false } },
        { disposalDate: { $gte: new Date() } },
      ],
    },
    projection: {
      identificationNumber: true,
      telematicsBoxImei: true,
      fleetNumber: true,
      registrationNumber: true,
      make: true,
      model: true,
      colour: true,
      role: true,
      homeStation: true,
      lastPollTime: true,
      position: true,
      headingDegrees: true,
      speedKilometresPerHour: true,
      malfunctionIndicatorLightOn: true,
      ignitionOn: true,
      assignedIncidentNumber: true,
      currentLocations: true,
      driver: true,
      lastDriver: true,
      type: true,
      // tags: true,
      // tagChanged: true,
      lastIgnitionOffTime: true,
      assignments: true,
      beaconsOn: true,
      sirensOn: true,
      rearRedLightsOn: true,
      rearBlueLightsOn: true,
      ...Object.fromEntries(Object.keys(dioStates).map((k) => [k, true])),
      equipment: true,
      reverseGeocode: true,
      groups: true,
      groupCodes: true,
      currentSpeedRules: true,
      rfidCard: true,
    },
  },
  radios: {
    query: {
      'lastRadioPoll.personCode': { $in: [null, ''] },
    },
    projection: {
      ssi: true,
      firstContactTime: true,
      lastPosition: true,
      label: true,
      type: true,
      'lastRadioPoll.time': true,
    },
  },
  telematicsBoxes: {
    query: {
      'mostRecentPoll.vehicleIdentificationNumber': { $in: [null, ''] },
      'mostRecentPoll.position': { $exists: true },
    },
    projection: {
      imei: true,
      type: true,
      firstContactTime: true,
      lastIgnitionOffTime: true,
      mostRecentPoll: {
        identifier: true,
        time: true,
        position: true,
        emergencyEquipmentOn: true,
        ignitionOn: true,
        beaconsOn: true,
        sirensOn: true,
        headingDegrees: true,
        headlightsFlashOn: true,
        speedKilometresPerHour: true,
        reverseGeocode: true,
      },
    },
  },
};

import {
  CANCEL_DRIVER_ID,
  CANCEL_TRACKING,
  CREATE_TELEMATICS_BOX,
  CREATE_TELEMATICS_BOX_FAILURE,
  CREATE_TELEMATICS_BOX_SUCCESS,
  CREATE_VEHICLE_SUCCESS,
  DELETE_TELEMATICS_BOX,
  DELETE_TELEMATICS_BOX_FAILURE,
  DELETE_TELEMATICS_BOX_SUCCESS,
  END_TELEMATICS_BOX_POLLS_STREAM,
  END_TELEMATICS_BOX_POLLS_STREAM_SUCCESS,
  FETCH_DRIVER_ID,
  FETCH_TELEMATICS_BOXES,
  FETCH_TELEMATICS_BOXES_FAILURE,
  FETCH_TELEMATICS_BOXES_SUCCESS,
  FETCH_TELEMATICS_BOX_POLLS,
  FETCH_TELEMATICS_BOX_POLLS_FAILURE,
  FETCH_TELEMATICS_BOX_POLLS_SUCCESS,
  FETCH_TRACKING,
  FETCH_VEHICLE,
  RECEIVE_TELEMATICS_BOX_POLL,
  START_TELEMATICS_BOX_POLLS_STREAM,
  START_TELEMATICS_BOX_POLLS_STREAM_FAILURE,
  START_TELEMATICS_BOX_POLLS_STREAM_SUCCESS,
  UPDATE_DRIVER_ID,
  UPDATE_TELEMATICS_BOX,
  UPDATE_TRACKING,
  // FETCH_TELEMATICS_BOX_AUDIT_LOG,
  // FETCH_TELEMATICS_BOX_AUDIT_LOG_SUCCESS,
  // FETCH_TELEMATICS_BOX_AUDIT_LOG_FAILURE,
  UPDATE_VEHICLE_IMEI,
  UPDATE_VEHICLE_IMEI_FAILURE,
  UPDATE_VEHICLE_IMEI_SUCCESS,
  UPDATE_VEHICLE_SUCCESS,
} from '@/actions';
import { api, fromAjax } from '@/apis';
import { encodeParams, getHeaders, log, startCase } from '@/utils';
import {
  dioOptions,
  dioStates,
  useDallasKeys,
  wsRootUrl,
} from '@/utils/config';
import _ from 'lodash';
import { ofType } from 'redux-observable';
import { Observable, from, of, timer } from 'rxjs';
import {
  catchError,
  endWith,
  map,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

const { hideMap } = dioOptions;

async function fetchTelematicsBoxesRequest(boxQuery, vehicleQuery) {
  const boxParams = {
    projection: {
      imei: true,
      mostRecentPoll: {
        identifier: true,
        time: true,
        diagnosticCode: true,
        deviceProperties: true,
        position: true,
        driver: true,
        ignitionOn: true,
        ...(hideMap ? {} : { locations: true }),
      },
      lastPosition: true,
      gpsValidPollCount: true,
      lastIgnitionOffTime: true,
      make: true,
      model: true,
      hardwareVersion: true,
      active: true,
    },
    query: boxQuery,
  };

  const vehicleParams = {
    projection: {
      identificationNumber: true,
      registrationNumber: true,
      fleetNumber: true,
      telematicsBoxImei: true,
      groups: true,
      role: true,
      disposalDate: true,
      type: true,
    },
    query: vehicleQuery,
  };

  const [boxes, vehicles] = await Promise.all([
    api
      .get('telematicsBoxes', { searchParams: encodeParams(boxParams) })
      .json(),
    api.get('vehicles', { searchParams: encodeParams(vehicleParams) }).json(),
  ]);

  const vehiclesByImei = _.mapKeys(vehicles, 'telematicsBoxImei');
  const multiAssignedImeis = _(vehicles)
    .groupBy((v) => v.telematicsBoxImei)
    .pickBy((x) => x.length > 1)
    .keys()
    .value();

  const boxesWithVehicles = boxes.map((box) => {
    const { mostRecentPoll: poll, active = true } = box;
    const {
      time: mostRecentTime,
      position: lastPosition,
      ignitionOn,
      locations = [],
      deviceProperties,
    } = poll || {}; // sometimes there's no poll
    const { batteryVoltage = '', deviceSignalStrength: signalStrength } =
      deviceProperties || {};
    const {
      identificationNumber,
      registrationNumber,
      fleetNumber,
      groups = {},
      role,
      disposalDate,
      type,
    } = box.imei in vehiclesByImei ? vehiclesByImei[box.imei] : {};
    const isMultiAssigned = multiAssignedImeis.includes(box.imei);
    const multiAssignments = vehicles.filter(
      (v) => v.telematicsBoxImei === box.imei,
    );

    return {
      ...box,
      active,
      batteryVoltage,
      signalStrength,
      mostRecentTime,
      lastPosition,
      registrationNumber,
      fleetNumber,
      identificationNumber,
      ignitionOn,
      locations,
      groups,
      isMultiAssigned,
      multiAssignments,
      role,
      disposalDate,
      type,
    };
  });

  return {
    boxesByImei: _.mapKeys(boxesWithVehicles, 'imei'),
    vehiclesById: _.mapKeys(vehicles, 'identificationNumber'),
    telematicsBoxes: boxes,
  };
}

async function fetchTelematicsBoxPollsRequest(imei, start, end) {
  const params = {
    query: {
      imei,
      time: {
        $gte: start.toISOString(),
        $lte: end.toISOString(),
      },
      // TODO date query not working
      // start: subMinutes(new Date(), 1),
      // end: addHours(new Date(), 24)
    },
    projection: {
      imei: true,
      time: true,
      diagnosticCode: true,
      deviceProperties: true,
      position: true,
      driver: true,
      ignitionOn: true,
      ...Object.fromEntries(Object.keys(dioStates).map((key) => [key, true])),
    },
  };

  const data = await api
    .get('telematicsBoxPolls', { searchParams: encodeParams(params) })
    .json();

  return data.map((telematicsBoxPoll) => {
    return {
      ...telematicsBoxPoll,
      searchString: `${telematicsBoxPoll.imei}`.toLowerCase(),
    };
  });
}

export function fetchTelematicsBoxPollsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_TELEMATICS_BOX_POLLS),
    mergeMap(({ payload: { imei, start, end } }) =>
      from(fetchTelematicsBoxPollsRequest(imei, start, end)).pipe(
        map((payload) => ({
          type: FETCH_TELEMATICS_BOX_POLLS_SUCCESS,
          payload: {
            imei,
            polls: payload,
          },
        })),
        catchError(({ message }) =>
          of({
            type: FETCH_TELEMATICS_BOX_POLLS_FAILURE,
            payload: {
              imei,
              message,
            },
          }),
        ),
      ),
    ),
  );
}

export function fetchTelematicsBoxesEpic(action$) {
  return action$.pipe(
    ofType(FETCH_TELEMATICS_BOXES),
    mergeMap(({ boxQuery, vehicleQuery }) =>
      from(fetchTelematicsBoxesRequest(boxQuery, vehicleQuery)).pipe(
        map((payload) => ({
          type: FETCH_TELEMATICS_BOXES_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_TELEMATICS_BOXES_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

// internal actions just used by socket & epic
const SOCKET_SUBSCRIBED = 'SOCKET_SUBSCRIBED';
const SOCKET_POLL_RECEIVED = 'SOCKET_POLL_RECEIVED';
function getTelematicsBoxPollsObservable(imei) {
  return new Observable((observer) => {
    const socket = new WebSocket(wsRootUrl);

    const inputParams = {
      action: 'SUBSCRIBE',
      authorization: 'Bearer ' + localStorage.getItem('access_token'),
      payload: {
        telematicsBoxes: {
          query: {
            imei: { $eq: imei },
          },
          projection: {
            imei: true,
            mostRecentPoll: {
              identifier: true,
              bufferCount: true,
              time: true,
              diagnosticCode: true,
              deviceProperties: true,
              position: true,
              driver: true,
              ignitionOn: true,
            },
            ...Object.fromEntries(
              Object.keys(dioStates).map((key) => [
                'mostRecentPoll.' + key,
                true,
              ]),
            ),
            cachedPolls: true,
          },
        },
      },
    };

    socket.onerror = (e) => observer.error(e);

    socket.onmessage = (poll) => {
      try {
        const data = JSON.parse(poll.data);

        if (data.action === 'ERROR') {
          observer.error({ message: data.payload });
        } else {
          if (data.payload.telematicsBoxes[imei]?.mostRecentPoll) {
            const mostRecent =
              data.payload.telematicsBoxes[imei].mostRecentPoll;
            const cached = data.payload.telematicsBoxes[imei].cachedPolls || [];
            const allPolls = _.orderBy(
              _.uniqBy([mostRecent, ...cached].filter(Boolean), 'identifier'),
              'identifier',
            );

            // if the imei was just created, it won't have a mostRecentPoll
            allPolls.forEach((poll) =>
              observer.next({
                type: SOCKET_POLL_RECEIVED,
                payload: poll,
              }),
            );
          }
        }
      } catch (e) {
        observer.error(e);
      }
    };

    socket.onopen = () => {
      socket.send(JSON.stringify(inputParams));
    };

    return () => {
      socket.close();
    };
  });
}

export function socketTelematicsBoxPollsEpic(action$, state$) {
  return action$.pipe(
    ofType(START_TELEMATICS_BOX_POLLS_STREAM),
    switchMap(({ payload: { imei } }) =>
      getTelematicsBoxPollsObservable(imei).pipe(
        map((message) => {
          switch (message.type) {
            case SOCKET_SUBSCRIBED:
              return { type: START_TELEMATICS_BOX_POLLS_STREAM_SUCCESS };
            case SOCKET_POLL_RECEIVED:
              return {
                type: RECEIVE_TELEMATICS_BOX_POLL,
                payload: {
                  imei,
                  isTemp: !state$.value.telematicsBoxes.boxesByImei[imei],
                  poll: message.payload,
                },
              };
            default:
              // shouldn't happen but warning if no default
              return {
                type: START_TELEMATICS_BOX_POLLS_STREAM_FAILURE,
                payload: 'Unknown message from socket',
              };
          }
        }),
        takeUntil(
          action$.pipe(
            ofType(
              START_TELEMATICS_BOX_POLLS_STREAM,
              END_TELEMATICS_BOX_POLLS_STREAM,
            ),
          ),
        ),
        catchError(({ message: payload }) =>
          of({
            type: START_TELEMATICS_BOX_POLLS_STREAM_FAILURE,
            payload,
          }),
        ),
        endWith({ type: END_TELEMATICS_BOX_POLLS_STREAM_SUCCESS }),
      ),
    ),
  );
}

// async function fetchTelematicsBoxAuditLogRequest() {
// const logs = await apiGet('/audits', {
// index: {
//   name: 'imeiTime',
//   values: [imei, { start, end }],
// },
// fields: [
//   'imei',
//   'time',
//   'diagnosticCode',
//   'deviceProperties',
//   'position',
//   'driver',
//   'ignitionOn',
//   ...Object.keys(dioStates),
// ],
// orderBy: { field: 'time', sort: 'desc' },
// maxResults: 200,
// }
// );

// return _.mapKeys(boxesWithVehicles, 'imei');
// }

// export function fetchTelematicsBoxAuditLog(action$) {
//   return action$.pipe(
//     ofType(FETCH_TELEMATICS_BOX_AUDIT_LOG),
//     mergeMap(({ payload: { imei, start, end } }) =>
//       from(fetchTelematicsBoxAuditLogRequest(imei)).pipe(
//         map((payload) => ({
//           type: FETCH_TELEMATICS_BOX_AUDIT_LOG_SUCCESS,
//           payload: {
//             imei,
//             polls: payload,
//           },
//         })),
//         catchError(({ message }) =>
//           of({
//             type: FETCH_TELEMATICS_BOX_AUDIT_LOG_FAILURE,
//             payload: {
//               imei,
//               message,
//             },
//           })
//         )
//       )
//     )
//   );
// }

async function updateVehicleRequest(
  { identificationNumber },
  telematicsBoxImei,
) {
  const vehicle = {
    identificationNumber,
    telematicsBoxImei,
  };

  await api.patch(`vehicles/${identificationNumber}`, {
    json: vehicle,
    headers: {
      'content-type': 'application/merge-patch+json',
    },
  });

  log('Update', 'Vehicle', vehicle);

  return vehicle;
}

export function updateVehicleEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_VEHICLE_IMEI),
    mergeMap(({ payload: { vehicle, telematicsBoxImei } }) =>
      from(updateVehicleRequest(vehicle, telematicsBoxImei)).pipe(
        mergeMap((payload) =>
          of(
            {
              type: UPDATE_VEHICLE_IMEI_SUCCESS,
              payload,
            },
            {
              type: FETCH_TELEMATICS_BOXES,
            },
            {
              type: FETCH_VEHICLE,
              payload: vehicle.identificationNumber,
            },
          ),
        ),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_VEHICLE_IMEI_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function createTelematicsBoxEpic(action$) {
  return action$.pipe(
    ofType(CREATE_TELEMATICS_BOX),
    mergeMap(({ payload: { redirect, ...body }, navigate }) =>
      fromAjax('/telematicsBoxes', {
        body,
        method: 'POST',
        headers: { ...getHeaders(), 'content-type': 'application/json' },
      }).pipe(
        map(({ response: payload }) => {
          log('Create', 'Telematics Box', payload);

          if (redirect) {
            navigate(`../${payload.imei}`, {
              replace: true,
              state: { created: true },
            });
          }

          return {
            type: CREATE_TELEMATICS_BOX_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: CREATE_TELEMATICS_BOX_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

async function updateTelematicsBoxRequest({ imei, ...box }) {
  await api.put(`telematicsBoxes/${imei}`, { json: box });
  log('Update', 'Telematics Box', box);
}

export function deleteTelematicsBoxEpic(action$) {
  return action$.pipe(
    ofType(DELETE_TELEMATICS_BOX),
    mergeMap(({ payload, navigate }) =>
      from(getHeaders().then((headers) => ({ payload, navigate, headers }))),
    ),
    mergeMap(({ payload: id, navigate, headers }) =>
      fromAjax(`/radios/${id}`, {
        method: 'DELETE',
        headers,
      }).pipe(
        map(({ response }) => {
          log('Delete', 'Telematics Box', { id });

          navigate('..', { replace: true });

          return {
            type: DELETE_TELEMATICS_BOX_SUCCESS,
            payload: response.ssi,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_TELEMATICS_BOX_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

function createEpic(TYPE, requestFunction, ...args) {
  function epic(action$) {
    return action$.pipe(
      ofType(TYPE),
      mergeMap(({ payload }) =>
        from(requestFunction(payload)).pipe(
          mergeMap((payload) =>
            of(
              {
                type: TYPE + '_SUCCESS',
                payload,
              },
              ...args.map((type) => ({
                type,
              })),
            ),
          ),
          catchError(({ message: payload }) =>
            of({
              type: TYPE + '_FAILURE',
              payload,
            }),
          ),
        ),
      ),
    );
  }

  return epic;
}

export const updateTelematicsBoxEpic = createEpic(
  UPDATE_TELEMATICS_BOX,
  updateTelematicsBoxRequest,
  FETCH_TELEMATICS_BOXES,
);

// export function createTelematicsBoxEpic(action$) {
//   return action$.pipe(
//     ofType(CREATE_TELEMATICS_BOX),
//     mergeMap(({ payload: box }) =>
//       from(createTelematicsBoxRequest(box)).pipe(
//         map((payload) => ({
//           type: CREATE_TELEMATICS_BOX_SUCCESS,
//           payload,
//         })),
//         catchError(({ message: payload }) =>
//           of({
//             type: CREATE_TELEMATICS_BOX_FAILURE,
//             payload,
//           })
//         )
//       )
//     )
//   );
// }

// When a telematics box is being subscribed to,
// get readings from the driver id grpc periodically
function createSubscribeTimerEpic(type) {
  function epic(action$) {
    return action$.pipe(
      ofType(START_TELEMATICS_BOX_POLLS_STREAM),
      mergeMap(({ payload }) =>
        timer(0, 3000).pipe(
          map(() => ({
            type,
            payload,
          })),
          takeUntil(
            action$.pipe(
              ofType(
                START_TELEMATICS_BOX_POLLS_STREAM,
                END_TELEMATICS_BOX_POLLS_STREAM,
              ),
            ),
          ),
        ),
      ),
    );
  }

  return epic;
}

// certain box parameters (currently driver id and tracking) will need to be polled,
// updated and potentially have the update cancelled. The form is the same for both
// so the following functions try to abstract this instead of copy n paste
function createFetchBoxParameter(type, enabledState) {
  return async function ({ imei }) {
    const response = await api.get(`telematicsBox/${imei}/${type}`).json(); //TODO: How could this have ever worked? The get brings back no fields

    const result = response;

    return {
      imei,
      currentlyEnabled: result
        ? result.CurrentState === enabledState
        : undefined,
      willBeEnabled: result?.IsPending
        ? result?.TargetState === enabledState
        : undefined,
    };
  };
}

function createUpdateBoxParameter(type, enabledState) {
  return async function f({ imei, enabled }) {
    const State = enabled
      ? enabledState
      : `${startCase(type).replace(/\s/g, '')}Disabled`;

    const response = await api
      .put(`telematicsBox/${imei}/${type}`, { json: { State } })
      .json();

    return response;
  };
}

function createCancelBoxParameter(type) {
  return async function f({ imei }) {
    const response = await api.delete(`telematicsBox/${imei}/${type}`).json();

    return response;
  };
}

// Update, cancel and poll regularly for driver id
const enabledDriverIdState = useDallasKeys ? 'DallasKey' : 'Rfid';
const fetchDriverId = createFetchBoxParameter('driverId', enabledDriverIdState);
const updateDriverId = createUpdateBoxParameter(
  'driverId',
  enabledDriverIdState,
);
const cancelDriverId = createCancelBoxParameter(
  'driverId',
  enabledDriverIdState,
);
export const fetchDriverIdEpic = createEpic(FETCH_DRIVER_ID, fetchDriverId);
export const updateDriverIdEpic = createEpic(UPDATE_DRIVER_ID, updateDriverId);
export const cancelDriverIdEpic = createEpic(CANCEL_DRIVER_ID, cancelDriverId);
export const subscribeToDriverIdEpic =
  createSubscribeTimerEpic(FETCH_DRIVER_ID);

// same for tracking state
const enabledTrackingState = 'TrackingEnabled';
const fetchTracking = createFetchBoxParameter('tracking', enabledTrackingState);
const updateTracking = createUpdateBoxParameter(
  'tracking',
  enabledTrackingState,
);
const cancelTracking = createCancelBoxParameter(
  'tracking',
  enabledTrackingState,
);
export const fetchTrackingEpic = createEpic(FETCH_TRACKING, fetchTracking);
export const updateTrackingEpic = createEpic(UPDATE_TRACKING, updateTracking);
export const cancelTrackingEpic = createEpic(CANCEL_TRACKING, cancelTracking);
export const subscribeToTrackingEpic = createSubscribeTimerEpic(FETCH_TRACKING);

export function reactToVehicleUpdatesEpic(action$) {
  return action$.pipe(
    ofType(CREATE_VEHICLE_SUCCESS, UPDATE_VEHICLE_SUCCESS),
    map(() => ({ type: FETCH_TELEMATICS_BOXES })),
  );
}

import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useHighPriority(options) {
  const searchParams = JSON.parse(JSON.stringify(options ?? {}));

  return useQuery({
    queryKey: ['highPriority', searchParams],
    queryFn: () => {
      return briefingApi.get('people', { searchParams }).json();
    },
    placeholderData: [],
    staleTime: 15 * (60 * 1000),
    cacheTime: 20 * (60 * 1000),
    keepPreviousData: true,
  });
}

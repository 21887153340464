import { LoadingDialog } from '@/components/controls';
import {
  useDeleteUrgentAction,
  useUpsertUrgentAction,
  useUrgentAction,
} from '@/hooks';
import { UrgentActionEditorDialog } from './UrgentActionEditorDialog';

export function UrgentActionEditor({ code, isOpen, onClose, groupCodes }) {
  const { data: urgentAction } = useUrgentAction(code, {
    groupCodes: groupCodes ?? [],
    occurrenceCode: '',
    description: '',
    updates: [],
    isComplete: false,
  });
  const { mutate: upsertUrgentAction } = useUpsertUrgentAction();
  const { mutate: deleteUrgentAction } = useDeleteUrgentAction();

  function handleClose(values) {
    if (values) {
      upsertUrgentAction(values);
    }

    onClose();
  }

  function handleDelete() {
    deleteUrgentAction(code);

    onClose();
  }

  return urgentAction ? (
    <UrgentActionEditorDialog
      urgentAction={urgentAction}
      isOpen={isOpen}
      onClose={handleClose}
      onDelete={handleDelete}
      groupCodes={groupCodes}
    />
  ) : (
    <LoadingDialog isOpen={isOpen} />
  );
}

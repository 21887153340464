import { combineEpics } from 'redux-observable';
import * as activityEpics from './activityEpics';
import * as eventEpics from './eventEpics';
import * as filterEpics from './filterEpics';
import * as gazetteerEpics from './gazetteerEpics';
import * as liveEpics from './liveEpics';
import * as locationEpics from './locationEpics';
import * as objectiveEpics from './objectiveEpics';
import * as personEpics from './personEpics';
import * as radioEpics from './radioEpics';
import * as reportEpics from './reportEpics';
import * as retrospectiveEpics from './retrospectiveEpics';
import * as rfidCardEpics from './rfidCardEpics';
import * as tagEpics from './tagEpics';
import * as telematicsBoxEpics from './telematicsBoxEpics';
import * as utilisationEpics from './utilisationEpics';
import * as vehicleEpics from './vehicleEpics';

const rootEpic = combineEpics(
  ...Object.values(tagEpics),
  ...Object.values(filterEpics),
  ...Object.values(locationEpics),
  ...Object.values(objectiveEpics),
  ...Object.values(personEpics),
  ...Object.values(reportEpics),
  ...Object.values(retrospectiveEpics),
  ...Object.values(vehicleEpics),
  ...Object.values(liveEpics),
  ...Object.values(telematicsBoxEpics),
  ...Object.values(utilisationEpics),
  ...Object.values(activityEpics),
  ...Object.values(eventEpics),
  ...Object.values(gazetteerEpics),
  ...Object.values(radioEpics),
  ...Object.values(rfidCardEpics),
);

export default rootEpic;

import { MapButton } from '@/components/controls';
import { styles } from '@/utils/config';
import {
  FullscreenExit as FullscreenExitIcon,
  Fullscreen as FullscreenIcon,
  Layers as LayersIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@mui/icons-material';
import { ListSubheader, Menu, MenuItem, MenuList, Stack } from '@mui/material';
import bbox from '@turf/bbox';
import 'maplibre-gl/dist/maplibre-gl.css';
import { Fragment, useState } from 'react';
import { useMap } from 'react-map-gl/maplibre';

export function MapControls({ style, onStyleChange, selectedSource }) {
  const { current: map } = useMap();
  const [styleMenuAnchorEl, setStyleMenuAnchorEl] = useState(null);

  function handleStyleClick(event) {
    setStyleMenuAnchorEl(event.currentTarget);
  }

  function handleStyleMenuClose() {
    setStyleMenuAnchorEl(null);
  }

  const handleStyleMenuClick = (path) => () => {
    onStyleChange(path);
    setStyleMenuAnchorEl(null);
  };

  function handleZoomInClick() {
    map.zoomIn();
  }

  function handleZoomOutClick() {
    map.zoomOut();
  }

  function handleFitClick() {
    const features = map
      .querySourceFeatures(selectedSource)
      .map(({ type, geometry, properties }) => ({
        type,
        geometry,
        properties,
      }));

    if (features.length > 0) {
      map.fitBounds(
        bbox({
          type: 'FeatureCollection',
          features,
        }),
        { padding: 80, duration: 1000 },
      );
    }
  }

  async function toggleFullScreen() {
    if (document.fullscreenElement) {
      await document.exitFullscreen();
    } else {
      await map.getMap().getContainer().requestFullscreen();
    }
  }

  return (
    <Fragment>
      <Stack sx={{ position: 'absolute', top: 8, left: 8 }}>
        <MapButton
          title="Zoom in"
          onClick={handleZoomInClick}
          disabled={map.getZoom() === map.getMaxZoom()}
        >
          <ZoomInIcon />
        </MapButton>
        <MapButton
          title="Zoom out"
          onClick={handleZoomOutClick}
          disabled={map.getZoom() === map.getMinZoom()}
        >
          <ZoomOutIcon />
        </MapButton>
        <MapButton title="Fit map" onClick={handleFitClick}>
          <ZoomOutMapIcon />
        </MapButton>
        <MapButton
          title={`${document.fullscreenElement ? 'Exit' : 'Go'} full screen`}
          onClick={toggleFullScreen}
        >
          {document.fullscreenElement ? (
            <FullscreenExitIcon />
          ) : (
            <FullscreenIcon />
          )}
        </MapButton>
        <MapButton title="Change map style" onClick={handleStyleClick}>
          <LayersIcon />
        </MapButton>
        <Menu
          anchorEl={styleMenuAnchorEl}
          open={Boolean(styleMenuAnchorEl)}
          onClose={handleStyleMenuClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <MenuList
            disablePadding
            dense
            subheader={<ListSubheader disableSticky>Type</ListSubheader>}
          >
            {styles.map((layer) => (
              <MenuItem
                key={layer.path}
                onClick={handleStyleMenuClick(layer.path)}
                selected={layer.path === style}
              >
                {layer.label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Stack>
    </Fragment>
  );
}

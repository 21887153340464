import { DataGrid } from '@/components/controls';
import { useCurrentObjectiveCounts } from '@/hooks';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import { AttendeesTable } from './AttendeesTable';
import { useMemo } from 'react';
import { getKeyLabel } from '@/utils';

export function CurrentObjectivesCard({ groupType, groupId }) {
  const { data } = useCurrentObjectiveCounts(groupType, groupId);
  const columns = useMemo(
    () => [
      { accessorKey: 'identifier', header: 'Identifier' },
      { accessorKey: 'title', header: 'Title' },
      {
        accessorKey: 'totalAttendances',
        header: 'Total',
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
      },
      {
        accessorKey: 'groupAttendances',
        header: getKeyLabel(groupType),
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
      },
    ],
    [groupType],
  );

  return (
    <Card variant="outlined" sx={{ flex: 1 }}>
      <CardContent>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            Current Objectives
          </Typography>
          <Typography variant="caption" color="text.secondary">
            with attendances this week
          </Typography>
        </Stack>
        <DataGrid
          enableSorting={true}
          enableBottomToolbar={true}
          enablePagination={true}
          columns={columns}
          data={data}
          initialState={{ columnVisibility: { identifier: false } }}
          renderDetailPanel={({ row }) => (
            <AttendeesTable
              objectiveId={row.original.identifier}
              groupType={groupType}
              groupId={groupId}
            />
          )}
        />
      </CardContent>
    </Card>
  );
}

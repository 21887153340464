import {
  Business as BusinessIcon,
  DataUsage,
  DirectionsCar as DirectionsCarIcon,
  Feedback as FeedbackIcon,
  Person as PersonIcon,
} from '@mui/icons-material';
import { AxisArrow as AxisArrowIcon } from 'mdi-material-ui';

export function EventReplayItemTypeIcon({ type }) {
  switch (type) {
    case 'vehicle':
      return <DirectionsCarIcon />;
    case 'location':
      return <BusinessIcon />;
    case 'event':
      return <AxisArrowIcon />;
    case 'person':
      return <PersonIcon />;
    case 'incident':
      return <FeedbackIcon />;
    case 'objective':
      return <DataUsage />;
  }
}

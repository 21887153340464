import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useUser(code) {
  return useQuery({
    queryKey: ['users', code],
    queryFn: () => {
      return briefingApi.get(`users/${code}`).json();
    },
    enabled: !!code,
    keepPreviousData: true,
    staleTime: 1000 * 60 * 60,
  });
}

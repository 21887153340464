export * from './useNotification';
export * from './useNotifications';
export * from './useDeleteNotifications';
export * from './useNotificationSocket';

export const projection = {
  identifier: true,
  time: true,
  user: true,
  type: true,
  title: true,
  message: true,
};

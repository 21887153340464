import {
  Build as BuildIcon,
  Business as BusinessIcon,
  Terrain as TerrainIcon,
} from '@mui/icons-material';

export function LocationTypeIcon({ type, className }) {
  switch (type) {
    case 'Base':
    case 'Police Station':
    case 'Outstation':
      return <BusinessIcon className={className} />;
    case 'Workshop':
      return <BuildIcon className={className} />;
    case 'Ward':
    default:
      return <TerrainIcon className={className} />;
  }
}

import { useSubgroupIncidentCounts } from '@/hooks';
import { startCase } from '@/utils';
import { highlights } from '@/utils/config';
import {
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
  darken,
  lighten,
  useTheme,
} from '@mui/material';
import {
  ArrowDown as ArrowDownIcon,
  ArrowRight as ArrowRightIcon,
} from 'mdi-material-ui';
import { useMemo } from 'react';
import { DataGrid } from '../controls';

const {
  totalIncidents: { lookbackDays },
} = highlights;

const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

export function TotalIncidentsCard({
  groupType,
  groupId,
  subgroupType,
  subgroupId,
}) {
  const {
    data: { grades, groups },
  } = useSubgroupIncidentCounts(groupType, groupId, subgroupType, lookbackDays);
  const theme = useTheme();

  const columns = useMemo(
    () => [
      {
        accessorKey: 'identifier',
        header: (
          <Box>
            {`${startCase(subgroupType)} `}
            {
              <ArrowDownIcon
                fontSize="inherit"
                sx={{ verticalAlign: 'middle' }}
              />
            }
            {` Grade `}
            {
              <ArrowRightIcon
                fontSize="inherit"
                sx={{ verticalAlign: 'middle' }}
              />
            }
          </Box>
        ),
        size: 0,
        muiTableBodyCellProps: {
          sx: { fontWeight: 'bold', color: theme.palette.text.secondary },
        },
        footer: 'Total',
      },
      ...Object.keys(grades).map((grade) => ({
        key: grade,
        accessorFn: (row) => row[grade] ?? 0,
        header: grade,
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        size: 0,
        footer: grades[grade],
      })),
      {
        header: 'All',
        size: 0,
        accessorFn: ({ identifier: _, ...row }) =>
          Object.values(row).reduce((a, b) => a + b, 0),
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
          sx: { fontWeight: 'bold', color: theme.palette.text.secondary },
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        footer: Object.values(grades).reduce((a, b) => a + b, 0),
      },
    ],
    [subgroupType, grades, theme.palette.text.secondary],
  );

  return (
    Object.keys(groups).length > 0 && (
      <Card variant="outlined">
        <CardContent>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Total Incidents
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {`in the last ${lookbackDays} days`}
            </Typography>
          </Stack>
          <DataGrid
            enableSorting={true}
            enableBottomToolbar={true}
            enablePagination={true}
            data={Object.entries(groups).map(([identifier, group]) => ({
              identifier,
              ...group,
            }))}
            columns={columns}
            muiTableBodyRowProps={({ row }) => ({
              hover: row.original.identifier !== subgroupId,
              sx:
                row.original.identifier === subgroupId
                  ? {
                      bgcolor: (theme) =>
                        getBackgroundColor(
                          theme.palette.primary.light,
                          theme.palette.mode,
                        ),
                      '&:hover': {
                        bgcolor: (theme) =>
                          getHoverBackgroundColor(
                            theme.palette.primary.light,
                            theme.palette.mode,
                          ),
                      },
                    }
                  : undefined,
            })}
          />
        </CardContent>
      </Card>
    )
  );
}

import { briefingApi } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertIr3PrisonRecall() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ _id, ...json }) => {
      if (_id) {
        return briefingApi.put(`prisonRecalls/${json.code}`, { json }).json();
      } else {
        return briefingApi.post('prisonRecalls', { json }).json();
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['ir3PrisonRecalls'] }),
  });
}

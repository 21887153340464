import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useVehicle(id) {
  return useQuery({
    queryKey: ['vehicles', id],
    queryFn: async () => {
      if (id === 'new') {
        return {
          identificationNumber: '',
        };
      }

      const json = [
        { $match: { identificationNumber: id } },
        {
          $graphLookup: {
            from: 'groups',
            startWith: '$groupCodes',
            connectFromField: 'parentCodes',
            connectToField: 'code',
            as: 'groupAncestors',
            depthField: 'depth',
          },
        },
        {
          $project: {
            identificationNumber: true,
            registrationNumber: true,
            fleetNumber: true,
            picture: true,
            role: true,
            type: true,
            make: true,
            model: true,
            colour: true,
            marked: true,
            homeStation: true,
            equipment: true,
            telematicsBoxImei: true,
            lastPollTime: true,
            lastOdometerReading: {
              time: true,
              distanceMiles: {
                $round: [
                  {
                    $multiply: [
                      '$lastOdometerReading.distanceKilometres',
                      0.62137119,
                    ],
                  },
                  2,
                ],
              },
            },
            fuelType: true,
            disposalDate: true,
            driverIdLocation: true,
            installLocation: true,
            commissionDate: true,
            notes: true,
            groups: true,
            assignments: true,
            visibleTo: true,
            attributes: true,
            groupCodes: true,
            groupAncestors: {
              $map: {
                input: {
                  $sortArray: {
                    input: {
                      $filter: {
                        input: '$groupAncestors',
                        cond: {
                          $not: [{ $in: ['$$ancestor.code', '$groupCodes'] }],
                        },
                        as: 'ancestor',
                      },
                    },
                    sortBy: { depth: -1, type: 1, name: 1 },
                  },
                },
                as: 'group',
                in: {
                  code: '$$group.code',
                  name: '$$group.name',
                  type: '$$group.type',
                },
              },
            },
          },
        },
      ];

      const vehicles = await api.post('pipeline/vehicles', { json }).json();

      log('Read', 'Vehicle', { id });

      return vehicles[0] ?? null;
    },
    staleTime: 30 * 60 * 1000,
    enabled: !!id,
  });
}

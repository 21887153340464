import { PersonPreview } from '@/components/controls';
import { useGroups, useUser } from '@/hooks';
import { listGroupLabels } from '@/utils';
import {
  Link as LinkIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  ImageListItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { formatDistanceToNow } from 'date-fns';

export function IntelligenceItem({ item, onSettingsClick }) {
  const { data: user } = useUser(item.edited.userCode);
  const { data: groups } = useGroups({ types: ['SECTOR', 'TEAM'] });

  return (
    <Card variant="outlined" sx={{ flexGrow: 1, display: 'flex' }}>
      <PersonPreview code={item.personCode} />
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <CardContent sx={{ flexGrow: 1 }}>
          <Stack>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Typography variant="caption" color="text.secondary" gutterBottom>
                {user
                  ? `${user.name} - ${formatDistanceToNow(
                      new Date(item.edited.time),
                    )} ago`
                  : '-'}
              </Typography>
              <Typography variant="caption" color="text.secondary" gutterBottom>
                {!!item && !!groups
                  ? `${listGroupLabels('Group', item.groupCodes, groups)}`
                  : ''}
              </Typography>
            </Stack>
            <Typography sx={{ whiteSpace: 'pre-wrap' }} gutterBottom>
              {item.message}
            </Typography>
          </Stack>
        </CardContent>
        <CardActions>
          {!!item.linkHref && (
            <Tooltip title="Link">
              <IconButton size="small" href={item.linkHref} target="_blank">
                <LinkIcon />
              </IconButton>
            </Tooltip>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Settings">
            <IconButton size="small" onClick={onSettingsClick}>
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Box>
      {!!item.imageSrc && (
        <ImageListItem>
          <CardMedia
            component="img"
            sx={{ maxWidth: 240 }}
            image={item.imageSrc}
          />
        </ImageListItem>
      )}
    </Card>
  );
}

import {
  BooleanSelectField,
  ColorsField,
  DateTimeField,
  DebouncedTextField,
  Field,
  required,
  SelectField,
  SliderField,
  StyledField,
  SwitchField,
} from '@/components/fields';
import { areaTypeGroupOptions, areaTypes } from '@/utils/config';
import { unitsOfTime } from '@/utils/constants';
import { Box, Divider, Stack, Typography } from '@mui/material';
import {
  Circle as LargeDistanceIcon,
  CircleMedium as SmallDistanceIcon,
} from 'mdi-material-ui';
import { Fragment } from 'react';
import { compareLabels } from './constants';

function RelativeTime({ name, value }) {
  return (
    <Stack direction="row" spacing={1} sx={{ mt: 0.5 }}>
      <Field
        name={`${name}.amountOfTime`}
        type="number"
        label="Amount"
        component={DebouncedTextField}
        inputProps={{ min: 1 }}
        sx={{ width: 96 }}
      />
      <Field
        name={`${name}.unitOfTime`}
        label="Unit"
        component={SelectField}
        defaultValue={'days'}
        values={unitsOfTime.map((type) => {
          return { value: type, label: type };
        })}
        sx={{ width: 96 }}
      />
      <StyledField
        name={`${name}.includeCurrentTime`}
        component={SwitchField}
        label={`Include current ${(value.unitOfTime ?? '').slice(0, -1)}`}
      />
    </Stack>
  );
}

function StartAndEnd({ name, value }) {
  return (
    <Stack direction="row" spacing={1} sx={{ mt: 0.5 }}>
      <Field
        label="Start"
        name={`${name}.startTime`}
        component={DateTimeField}
        maxDate={value.endTime}
      />
      <Field
        label="End"
        name={`${name}.endTime`}
        component={DateTimeField}
        minDate={value.startTime}
      />
    </Stack>
  );
}

function Time({ name, value }) {
  return (
    <Fragment>
      <Typography variant="subtitle2" color="textSecondary">
        Time Period
      </Typography>
      <Box>
        <Field
          fullWidth
          name={`${name}.isRelativeTimePeriod`}
          label="Type"
          component={BooleanSelectField}
          trueLabel="Relative"
          falseLabel="Absolute"
          sx={{ my: 1 }}
          defaultValue={false}
          // onChange={() => mutators.clearValue('timePeriod')}
        />
        {/* x ? y : z confuses RFF array & it puts end date value in unit */}
        {value.isRelativeTimePeriod && (
          <RelativeTime name={name} value={value} />
        )}
        {!value.isRelativeTimePeriod && (
          <StartAndEnd name={name} value={value} />
        )}
      </Box>
    </Fragment>
  );
}

export function TypeParameters({ type, name, value }) {
  switch (type) {
    case 'area':
      return (
        <Fragment>
          <Time name={name} value={value} />
          <Divider sx={{ mt: 2, mb: 1 }} />
          <Typography variant="subtitle2" color="textSecondary">
            Visualisation
          </Typography>
          <Field
            sx={{ mr: 4, ml: 1, mt: 1 }}
            label="Colour"
            name={`${name}.colors`}
            component={ColorsField}
            max={1}
          />
          <Divider sx={{ mt: 2, mb: 1 }} />
          <Typography variant="subtitle2" color="textSecondary">
            Area
          </Typography>
          <Stack direction="row" spacing={1}>
            <Field
              fullWidth
              sx={{ mt: 1, minWidth: 128 }}
              label="Type"
              name={`${name}.areaType`}
              component={SelectField}
              values={areaTypes.sort(compareLabels)}
              validate={required}
              datacy="areaType" // for testing
            />
            {areaTypeGroupOptions[value.areaType]?.length > 0 && (
              <Field
                fullWidth
                sx={{ mt: 1, minWidth: 128 }}
                label="Group By"
                name={`${name}.groupBy`}
                component={SelectField}
                values={[
                  { label: 'None', value: '' },
                  ...areaTypeGroupOptions[value.areaType],
                ]}
              />
            )}
          </Stack>
        </Fragment>
      );
    case 'bubble':
      return (
        <Fragment>
          <Time name={name} value={value} />
          <Divider sx={{ mt: 2, mb: 1 }} />
          <Typography variant="subtitle2" color="textSecondary">
            Visualisation
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Field
              sx={{ mr: 4, ml: 1, mt: 1 }}
              label="Colour"
              name={`${name}.colors`}
              component={ColorsField}
              max={1}
            />
            <Box>
              <Box sx={{ mt: 0.5 }}>
                <Typography variant="caption" color="textSecondary">
                  Distance
                </Typography>
              </Box>
              <Box
                id="distance"
                sx={{
                  mt: -1.5,
                  mx: 1,
                  display: 'flex',
                  alignItems: 'center',
                  color: 'text.secondary',
                }}
              >
                <SmallDistanceIcon />
                <Field
                  sx={{ pr: 1, ml: 1, width: 240, flexGrow: 1 }}
                  fallbackValue={7} // default or initial are taken
                  marks
                  min={4}
                  max={10}
                  step={1}
                  name={`${name}.distance`}
                  component={SliderField}
                  // label="Distance"
                  type="number"
                />
                <LargeDistanceIcon />
              </Box>
            </Box>
          </Box>
        </Fragment>
      );
    case 'heat':
      return (
        <Fragment>
          <Time name={name} value={value} />
          <Divider sx={{ mt: 2, mb: 1 }} />
          <Typography variant="subtitle2" color="textSecondary">
            Visualisation
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <Field
                sx={{ mr: 4, ml: 1, mt: 1 }}
                label="Colours"
                name={`${name}.colors`}
                component={ColorsField}
                min={2}
              />
              <Field
                sx={{ mt: 2, mr: 1, width: 80 }}
                name={`${name}.blur`}
                component={DebouncedTextField}
                label="Blur"
                type="number"
                inputProps={{ min: 1, max: 20, step: 1 }}
              />
              <Field
                sx={{ mt: 2, mr: 1, width: 80 }}
                name={`${name}.radius`}
                component={DebouncedTextField}
                label="Radius"
                type="number"
                inputProps={{ min: 1, max: 20, step: 1 }}
              />
            </Box>
          </Box>
        </Fragment>
      );
    case 'shape':
      return (
        <Fragment>
          <Time name={name} value={value} />
          <Divider sx={{ mt: 2, mb: 1 }} />
          <Typography variant="subtitle2" color="textSecondary">
            Visualisation
          </Typography>
          <Field
            sx={{ mr: 4, ml: 1, mt: 1 }}
            label="Colour"
            name={`${name}.colors`}
            component={ColorsField}
            max={1}
          />
        </Fragment>
      );
    case 'file':
      return (
        <Fragment>
          <Typography variant="subtitle2" color="textSecondary">
            Visualisation
          </Typography>
          <Field
            sx={{ mr: 4, ml: 1, mt: 1 }}
            label="Colour"
            name={`${name}.colors`}
            component={ColorsField}
            max={1}
          />
        </Fragment>
      );
    default:
      return '';
  }
}

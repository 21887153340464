import {
  FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT,
  FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_CANCELLED,
  UPDATE_EVENTS_PARAMETERS,
  UPDATE_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FILTER,
} from '@/actions';
import {
  FilterPicker,
  Parameters,
  Table,
  TablePagination,
} from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import { downloadCSV, filterLocally, getFilenameForDownload } from '@/utils';
import { rowsPerPageOptions } from '@/utils/config';
import { GetApp as GetAppIcon } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Paper,
  Popover,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { dequal } from 'dequal';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const headers = [
  {
    label: '',
    key: 'expand',
    type: 'expand',
    component: Diagnostics,
    filter: false,
  },
  { label: 'Registration', key: 'registrationNumber', type: 'text' },
  { label: 'Fleet Number', key: 'fleetNumber', type: 'text' },
  { label: 'Vehicle Role', key: 'role', type: 'text' },
  { label: 'Vehicle Type', key: 'type', type: 'text' },
  { label: 'Start Time', key: 'startTime', type: 'date' },
  { label: 'End Time', key: 'endTime', type: 'date' },
  {
    label: 'Activation Time',
    key: 'malfunctionIndicatorLightOnTime',
    type: 'date',
  },
];

const diagnosticHeaders = [
  {
    label: 'On-Board Diagnostics Time',
    key: 'time',
    type: 'date',
  },
  {
    label: 'Area',
    key: 'area',
    type: 'text',
  },
  {
    label: 'Class',
    key: 'class',
    type: 'text',
  },
  {
    label: 'Code',
    key: 'code',
    type: 'text',
  },
  {
    label: 'Confirmed',
    key: 'isConfirmed',
    type: 'text',
  },
];

function Diagnostics({ entry }) {
  if (entry.diagnostics.length > 0) {
    return (
      <Table
        data={entry.diagnostics}
        headers={diagnosticHeaders}
        rowsPerPage={entry.diagnostics.length}
        page={0}
        footer={[]}
        keyName="identifier"
      />
    );
  }
  return null;
}

export function MalfunctionIndicatorLight() {
  useDocumentTitle('IR3 | Malfunction Indicator Light');
  const dispatch = useDispatch();
  const trips = useSelector(
    (state) => state.events.malfunctionIndicatorLight.list,
    dequal,
  );
  const isLoading = useSelector(
    (state) => state.events.malfunctionIndicatorLight.isLoading,
  );
  const error = useSelector(
    (state) => state.events.malfunctionIndicatorLight.error,
  );
  const filter = useSelector(
    (state) => state.events.malfunctionIndicatorLight.filter,
    dequal,
  );
  const parameters = useSelector(
    (state) => state.events.malfunctionIndicatorLight.parameters,
    dequal,
  );
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error]);

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT,
      payload: query,
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_CANCELLED,
    });
  }

  function handleParametersChange(parameters) {
    dispatch({
      type: UPDATE_EVENTS_PARAMETERS,
      payload: {
        eventType: 'malfunctionIndicatorLight',
        parameters,
      },
    });
  }

  function handleFilterClose() {
    setAnchorEl(null);
  }

  function updateFilter(update) {
    onFilterChange({
      ...filter,
      ...update,
    });
  }

  function onFilterChange(payload) {
    dispatch({
      type: UPDATE_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FILTER,
      payload,
    });
  }

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    updateFilter({
      orderBy,
      order: 'asc',
    });
  }

  function handleDownloadClick() {
    const filename = getFilenameForDownload(
      'Malfunction Indicator Light',
      'csv',
      parameters?.startTime,
      parameters?.endTime,
    );
    const data = filteredTrips.map((trip) => ({
      ...trip,
    }));

    downloadCSV(data, filename, headers);
  }

  const filteredTrips = filterLocally(filter, trips);

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 48px)',
        overflow: 'hidden',
        backgroundColor: 'background.default',
      }}
    >
      <Parameters
        collection="malfunctionIndicatorLightAlerts"
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        value={parameters}
        onChange={handleParametersChange}
        sx={{ mt: 1, width: 264 }}
        vehicle
        pointEvent
      />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 48px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Toolbar variant="dense" disableGutters sx={{ p: 1, pb: 0 }}>
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            Malfunction Indicator Light
          </Typography>
          <FilterPicker
            headers={headers}
            data={trips}
            filter={filter}
            onFilterChange={onFilterChange}
          />
          <Tooltip title="Download">
            <Box component="span">
              <IconButton
                disabled={filteredTrips.length === 0}
                onClick={handleDownloadClick}
                size="large"
              >
                <GetAppIcon />
              </IconButton>
            </Box>
          </Tooltip>
          <Popover
            id="filter-popover"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleFilterClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          ></Popover>
        </Toolbar>
        <Paper sx={{ m: [0, 1, 1], minWidth: 240 }}>
          <Table
            styles={{
              tableContainer: {
                height: 'calc(100vh - 172px)',
                overflowY: 'scroll',
              },
              table: {
                minWidth: 750,
              },
            }}
            data={filteredTrips}
            headers={headers}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            order={filter.order}
            orderBy={filter.orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={filteredTrips.length}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </Box>
    </Box>
  );
}

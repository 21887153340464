import { briefingApi } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useDeleteHighPrioritySuspects() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (code) => {
      if (code) {
        return briefingApi.delete(`people/${code}`);
      }
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: ['highPriority'] }),
        queryClient.invalidateQueries({ queryKey: ['suspects'] }),
      ]),
  });
}

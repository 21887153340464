import { useGroupOptions } from '@/hooks';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { useController } from 'react-hook-form';

export function GroupCodesController({
  control,
  name,
  label,
  disabled,
  rules,
}) {
  const {
    field: { value, disabled: isDisabled, onChange, ref },
    fieldState: { error, invalid },
  } = useController({
    name,
    control,
    rules,
    defaultValue: [],
    disabled,
  });
  const groupOptions = useGroupOptions();
  const groupBy = (option) => groupOptions?.[option].type;
  const getOptionLabel = (option) => groupOptions?.[option]?.name ?? option;

  function handleChange(_, selections) {
    onChange(selections);
  }

  function renderInput(params) {
    return (
      <TextField
        inputRef={ref}
        label={label}
        error={invalid}
        helperText={error?.message}
        {...params}
      />
    );
  }

  function renderTags(value, getTagProps) {
    return value.map((option, index) => (
      <Chip
        key={option}
        label={groupOptions?.[option]?.name}
        icon={
          <Chip
            color="primary"
            label={groupOptions?.[option]?.type}
            size="small"
          />
        }
        {...getTagProps({ index })}
      />
    ));
  }

  return (
    <Autocomplete
      value={value}
      fullWidth
      size="small"
      disabled={isDisabled}
      multiple
      onChange={handleChange}
      options={Object.keys(groupOptions || {})}
      groupBy={groupBy}
      getOptionLabel={getOptionLabel}
      // isOptionEqualToValue={(option, value) =>
      //   value ? option.value === value : false
      // }
      sx={{ minWidth: 240 }}
      renderInput={renderInput}
      renderTags={renderTags}
    />
  );
}

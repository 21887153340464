import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useSexualOffences(options) {
  const searchParams = JSON.parse(JSON.stringify(options ?? {}));

  return useQuery({
    queryKey: ['sexualOffences', searchParams],
    queryFn: () => {
      return briefingApi
        .get('niche/occurrences/sexualOffences', {
          searchParams,
          timeout: false,
        })
        .json();
    },
    placeholderData: [],
    staleTime: 55 * (60 * 1000), // only refetch 55 mins
    cacheTime: 60 * (60 * 1000), // keep data in cache for an hour
  });
}

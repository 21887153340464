import {
  CREATE_OBJECTIVE,
  CREATE_OBJECTIVE_FAILURE,
  CREATE_OBJECTIVE_SUCCESS,
  DELETE_OBJECTIVE,
  DELETE_OBJECTIVE_FAILURE,
  DELETE_OBJECTIVE_SUCCESS,
  FETCH_OBJECTIVE,
  FETCH_OBJECTIVES,
  FETCH_OBJECTIVES_FAILURE,
  FETCH_OBJECTIVES_SUCCESS,
  FETCH_OBJECTIVE_FAILURE,
  FETCH_OBJECTIVE_SUCCESS,
  UPDATE_OBJECTIVE,
  UPDATE_OBJECTIVE_FAILURE,
  UPDATE_OBJECTIVE_SUCCESS,
} from '@/actions';
import { rowsPerPageOptions } from '@/utils/config';
import { startOfDay, subDays } from 'date-fns';

const INITIAL_STATE = {
  objectives: [],
  objective: null,
  attendances: [],
  objectiveAttendances: {
    filter: {
      startTime: subDays(startOfDay(new Date(), 'day'), 2),
      endTime: startOfDay(new Date(), 'day'),
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      order: 'asc',
      orderBy: 'collarNumber',
      fields: {
        code: null,
        name: null,
        collarNumber: null,
        role: null,
      },
    },
    isLoading: false,
    error: null,
  },
  searchTerms: {},
};

export default function objectivesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_OBJECTIVES:
      return { ...state, isLoading: true, error: null };
    case FETCH_OBJECTIVES_SUCCESS:
      return {
        ...state,
        objectives: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_OBJECTIVES_FAILURE:
      return {
        ...state,
        objectives: [],
        isLoading: false,
        error: action.payload,
      };
    case FETCH_OBJECTIVE:
      return { ...state, isLoading: true, error: null };
    case FETCH_OBJECTIVE_SUCCESS:
      return {
        ...state,
        objective: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_OBJECTIVE_FAILURE:
      return {
        ...state,
        objective: null,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_OBJECTIVE:
      return { ...state, isLoading: true, error: null };
    case UPDATE_OBJECTIVE_SUCCESS:
      return {
        ...state,
        objective: action.payload,
        objectives: state.objectives.map((objective) =>
          objective.identifier === action.payload.identifier
            ? action.payload
            : objective,
        ),
        isLoading: false,
        error: null,
      };
    case UPDATE_OBJECTIVE_FAILURE:
      return {
        ...state,
        objective: null,
        isLoading: false,
        error: action.payload,
      };
    case CREATE_OBJECTIVE:
      return { ...state, isLoading: true, error: null };
    case CREATE_OBJECTIVE_SUCCESS:
      return {
        ...state,
        objective: action.payload,
        objectives: state.objectives.concat(action.payload),
        isLoading: false,
        error: null,
      };
    case CREATE_OBJECTIVE_FAILURE:
      return {
        ...state,
        objective: null,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_OBJECTIVE:
      return { ...state, isLoading: true, error: null };
    case DELETE_OBJECTIVE_SUCCESS: {
      const index = state.objectives.findIndex(
        (objective) => objective.identifier === action.payload,
      );

      return {
        ...state,
        objective: null,
        objectives: state.objectives
          .slice(0, index)
          .concat(state.objectives.slice(index + 1)),
        isLoading: false,
        error: null,
      };
    }
    case DELETE_OBJECTIVE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

import { api } from '@/apis';
import { encodeParams, log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useScene(code, { defaultValue = null }) {
  return useQuery({
    queryKey: ['scenes', code, { defaultValue }],
    queryFn: async () => {
      if (!code || code === 'new') {
        return defaultValue;
      }

      const searchParams = encodeParams({
        pipeline: [
          { $match: { code } },
          {
            $graphLookup: {
              from: 'groups',
              startWith: '$groupCodes',
              connectFromField: 'parentCodes',
              connectToField: 'code',
              as: 'groupAncestors',
              depthField: 'depth',
            },
          },
          {
            $set: {
              groupAncestors: {
                $map: {
                  input: {
                    $sortArray: {
                      input: {
                        $filter: {
                          input: '$ancestors',
                          cond: {
                            $not: [
                              { $in: ['$$ancestor.code', '$parentCodes'] },
                            ],
                          },
                          as: 'ancestor',
                        },
                      },
                      sortBy: { depth: -1, type: 1, name: 1 },
                    },
                  },
                  as: 'group',
                  in: {
                    code: '$$group.code',
                    name: '$$group.name',
                    type: '$$group.type',
                  },
                },
              },
            },
          },
        ],
      });

      const scenes = await api.get('locations', { searchParams }).json();

      log('Read', 'Scene', { code });

      return scenes[0];
    },
    staleTime: 30 * 60 * 1000,
  });
}

import { TablePagination as MuiTablePagination } from '@mui/material';

export function TablePagination({
  rowsPerPageOptions,
  component,
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
}) {
  return (
    <MuiTablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component={component}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
    />
  );
}

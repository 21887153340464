import { applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import epics from './epics';
import reducers from './reducers';

const epicMiddleware = createEpicMiddleware();

const actionSanitizer = (action) =>
  (action.type === 'PUSH_RETROSPECTIVE_FORM' ||
    action.type === 'SYNC_RETROSPECTIVE_FORM') &&
  action.payload
    ? { ...action, payload: 'Form' }
    : action;

const reduxDevtoolsExtensionCompose =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers =
  (reduxDevtoolsExtensionCompose &&
    reduxDevtoolsExtensionCompose({
      actionSanitizer,
      // stateSanitizer: (state) => state.data ? { ...state, data: '<<LONG_BLOB>>' } : state
      actionsBlacklist: [
        'ADD_LIVE_RESOURCE',
        'DELETE_LIVE_RESOURCE',
        'UPDATE_LIVE_RESOURCE',
        'UPDATE_LIVE_RESOURCES',
        'PUSH_RETROSPECTIVE_FORM',
        'SYNC_RETROSPECTIVE_FORM',
        'FETCH_TRACKING',
        'FETCH_TRACKING_SUCCESS',
        'FETCH_DRIVER_ID',
        'FETCH_DRIVER_ID_SUCCESS',
      ],
    })) ||
  compose;

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(epicMiddleware)),
);

epicMiddleware.run(epics);

export default store;

import { DateTimePicker } from '@/components/controls';

export function DateTimeField({ input, meta, ...props }) {
  return (
    <DateTimePicker
      ampm={false}
      helperText={meta.error}
      error={meta.invalid}
      {...input}
      {...props}
    />
  );
}

import {
  useDeleteHighPrioritySuspects,
  useHighPriority,
  useSuspects,
  //useUpdateSuspectPriority,
  useUpsertHighPrioritySuspect,
} from '@/hooks';
import { Done as DoneIcon } from '@mui/icons-material';
import { Masonry } from '@mui/lab';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Fragment, useMemo, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { LocationMapDialog } from '@/components/controls';
import { getStringBeforeCharacter, extractPostCode } from '@/utils';
import { Suspect } from './Suspect';

export function Suspects({ groupCodes, onComplete }) {
  // get all supects from niche
  const { data: suspects } = useSuspects({ codes: groupCodes });

  // get all the high priority from local
  const { data: highPriorities } = useHighPriority();

  const highPriorityCodes = highPriorities
    .filter((suspect) => suspect.priority < 2)
    .map((highRisk) => highRisk.code);

  // get all the high priority suspects from niche
  const { data: nicheHighPrioritySuspects } = useSuspects({
    ids: highPriorityCodes,
  });

  /* const outStandingSuspects = useMemo(
		() =>
			suspects.filter((s) => !highPriorityCodes.includes(s.person.nominalId)),
		[suspects, highPriorities],
	); */

  const highPrioritySuspects = useMemo(
    () =>
      nicheHighPrioritySuspects.map((niche) => ({
        ...niche,
        ...highPriorities.find(
          (local) => local.code === niche.person?.nominalId,
        ),
      })),
    [nicheHighPrioritySuspects, highPriorities],
  );

  //const { mutate: updatePriority } = useUpdateSuspectPriority();
  const { mutate: deleteHighPriority } = useDeleteHighPrioritySuspects();
  const { mutate: upsertHighPriority } = useUpsertHighPrioritySuspect();

  const { width, ref } = useResizeDetector();
  const highPriorityColumns = useMemo(
    () => Math.floor(width / 328) || 1,
    [width],
  );
  const [selected, setSelected] = useState(null);

  function handleShowLocationClick(suspect) {
    return async function () {
      const suspectFrom = getStringBeforeCharacter(suspect.person.address, '(');
      const suspectPostcode = extractPostCode(suspectFrom);
      suspect.person.suspectFrom = suspectFrom;
      suspect.person.postCode = suspectPostcode;
      setSelected(suspect);
    };
  }

  function handleLocationMapClose() {
    setSelected(null);
  }

  const lowPriorityColumns = useMemo(
    () => Math.floor(width / 168) || 1,
    [width],
  );

  function handleCompleteClick() {
    onComplete();
  }

  function handlePriorityClick(code, priority) {
    if (priority === 'high') {
      deleteHighPriority(code);
    } else {
      upsertHighPriority({
        code,
        priority: priority < 2 ? 2 : 1,
        source: 'niche',
        type: 'people',
      });
    }
  }

  function handleCirculateFieldClick(suspect, name) {
    upsertHighPriority({
      ...suspect,
      [name]: !suspect[name],
    });
  }

  return (
    <Fragment>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{
          position: 'sticky',
          top: 48,
          backgroundColor: 'background.default',
          zIndex: 700,
          pl: 1,
          py: 1,
        }}
      >
        <Typography variant="h6">Suspects</Typography>
        <Box>
          <Tooltip title="Done" placement="right">
            <IconButton onClick={handleCompleteClick}>
              <DoneIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
      <Stack spacing={1} sx={{ pb: 1 }}>
        <Typography variant="subtitle1" sx={{ pl: 1 }}>
          High Priority
        </Typography>
        <Masonry spacing={1} ref={ref} columns={highPriorityColumns}>
          {highPrioritySuspects.map((suspect) => (
            <Suspect
              key={suspect.person.id}
              suspect={suspect}
              onPriorityClick={handlePriorityClick}
              onCirculateFieldClick={handleCirculateFieldClick}
              priority="high"
              onShowLocationClick={handleShowLocationClick(suspect)}
            />
          ))}
        </Masonry>
        <Typography variant="subtitle1" sx={{ pl: 1 }}>
          Outstanding
        </Typography>
        <Masonry spacing={1} columns={lowPriorityColumns}>
          {suspects.map((suspect) => (
            <Suspect
              key={suspect.person.id}
              suspect={suspect}
              onPriorityClick={handlePriorityClick}
              priority="low"
              onShowLocationClick={handleShowLocationClick(suspect)}
            />
          ))}
        </Masonry>
      </Stack>
      {selected && (
        <LocationMapDialog
          title={`${selected.person.firstName} ${selected.person.surname} Residence`}
          postCode={selected.person.postCode}
          address={selected.person.suspectFrom}
          isOpen={Boolean(selected)}
          onClose={handleLocationMapClose}
        />
      )}
    </Fragment>
  );
}

import { TextField as MuiTextField } from '@mui/material';

export function TextField({ input, meta, ...props }) {
  return (
    <MuiTextField
      size="small"
      helperText={meta.error ?? meta.submitError}
      error={meta.invalid}
      {...input}
      {...props}
    />
  );
}

import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { getTypesInLocation } from '../utilities';
import { LiveListItem } from './LiveListItem';
import { TotalTypesInLocation } from './TotalTypesInLocation';

export function LocationLiveListItem({ item: location, ...props }) {
  const { code } = location;
  const layerVisibilities = useSelector(
    (state) => state.live.layerVisibilities,
  );
  const state = useSelector((state) => state.live);
  const STALE = 'stale';
  const showStale = layerVisibilities[STALE];
  const resourcesInLocation = getTypesInLocation(
    state.people,
    code,
    'people',
    showStale,
  );
  const vehiclesInLocation = getTypesInLocation(
    state.vehicles,
    code,
    'vehicles',
    showStale,
  );

  const typesInLocation = { ...resourcesInLocation, ...vehiclesInLocation };

  const detailComponent = (
    <Fragment>
      <TotalTypesInLocation
        typesInLocation={typesInLocation}
        notShowEmpty="true"
      />
    </Fragment>
  );

  return (
    <LiveListItem
      type="locations"
      primaryPath="name"
      secondaryPath="code"
      detailComponent={detailComponent}
      item={location}
      hideFollow={!location.boundary}
      {...props}
    />
  );
}

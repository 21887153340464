import { api } from '@/apis';
import { useMutation } from '@tanstack/react-query';
import { useAuth } from './useAuth';

export function useClaimAccelerometerAlert() {
  const { getUsername } = useAuth();

  return useMutation({
    mutationFn: async (identifier) => {
      return api
        .patch(`accelerometerAlerts/${identifier}`, {
          json: {
            claimedBy: getUsername(),
          },
          headers: {
            'content-type': 'application/merge-patch+json',
          },
        })
        .json();
    },
  });
}

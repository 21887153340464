import { GroupCodesController } from '@/components/controls';
import { isImageUrl, isPersonCode, isValidIntelligenceID } from '@/utils';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { Fragment, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

export function IntelligenceItemEditorDialog({
  item,
  isOpen,
  onClose,
  onDelete,
  groupCodes,
}) {
  const { control, handleSubmit, reset } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: item,
  });

  useEffect(() => {
    reset(item);
  }, [reset, item, isOpen]);

  function handleClose() {
    onClose();
  }

  return (
    <form id="notice-editor-form" onSubmit={handleSubmit(onClose)}>
      <Dialog open={isOpen && !!item} onClose={handleClose}>
        <DialogTitle>{`${item.code ? 'Edit' : 'Add'} Item`}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText gutterBottom>
              Please fill in the form below to add a notice. If groups are not
              specified then the intelligence will be visible to all.
            </DialogContentText>
            <GroupCodesController
              label="Groups"
              control={control}
              name="groupCodes"
              types={['SECTOR', 'TEAM']}
              ancestors={groupCodes}
            />
            <Controller
              name="message"
              control={control}
              rules={{ required: 'Required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Message"
                  multiline
                  rows={8}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="intelligenceId"
              control={control}
              rules={{
                validate: (value) => !value || isValidIntelligenceID(value),
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder="XXXXXXXXX"
                  {...field}
                  label="Intelligence ID"
                  type="url"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="personCode"
              control={control}
              rules={{
                validate: async (value) =>
                  !value || (await isPersonCode(value)),
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Niche Person"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="imageSrc"
              control={control}
              rules={{
                validate: async (value) => !value || (await isImageUrl(value)),
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder="https://example.com/image.png"
                  {...field}
                  label="Image"
                  type="url"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          {!!item.code && (
            <Fragment>
              <Button color="error" onClick={onDelete}>
                Delete
              </Button>
              <Box sx={{ flexGrow: 1 }} />
            </Fragment>
          )}
          <Button onClick={handleClose}>Cancel</Button>
          <Button form="notice-editor-form" type="submit">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}

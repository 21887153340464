import { useDocumentTitle, useEventReplay } from '@/hooks';
import {
  ArrowBack as ArrowBackIcon,
  ViewList as ViewListIcon,
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  ListItem,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EventReplayControls } from './EventReplayControls';
import { EventReplayItemList } from './EventReplayItemList';
import { EventReplayMap } from './EventReplayMap';

export function EventReplay() {
  const navigate = useNavigate();
  const { id, type } = useParams();
  const {
    data: { frames, paths, frameBySecond },
    isLoading,
    isFetching,
  } = useEventReplay(type, id);
  const [selectedItemIndex, setSelectedItemIndex] = useState({});
  const [hoveredItemIndex, setHoveredItemIndex] = useState({});
  const [currentFrameIndex, setCurrentFrameIndex] = useState(0);
  const [followedItemIndexes, setFollowedItemIndexes] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isXs = useMediaQuery((theme) => theme.breakpoints.only('xs'));
  useDocumentTitle(`IR3 | Event Replay | ${id}`);

  useEffect(() => {
    if (Number.isInteger(selectedItemIndex)) {
      setDrawerOpen(true);
    }
  }, [selectedItemIndex]);

  function handleOpen() {
    setDrawerOpen(true);
  }

  function handleClose() {
    setDrawerOpen(false);
  }

  function handleBackClick() {
    navigate(-1);
  }

  function showLoader() {
    return (
      <Box sx={{ px: 1, py: 4, textAlign: 'center' }}>
        <CircularProgress size={80} thickness={6} />
        <Typography>Fetching replay...</Typography>
      </Box>
    );
  }

  function renderReplayMap() {
    return (
      <EventReplayMap
        paths={paths}
        featureCollections={
          frames.length > 0
            ? frames[currentFrameIndex].featureCollections
            : null
        }
        followedItemIndexes={followedItemIndexes}
        selectedItemIndex={selectedItemIndex}
        hoveredItemIndex={hoveredItemIndex}
        onSelectItem={setSelectedItemIndex}
        onHoverItem={setHoveredItemIndex}
      />
    );
  }

  function renderReplayControls() {
    return (
      <EventReplayControls
        // time={
        //   frames.length > 0
        //     ? frames[currentFrameIndex].time
        //     : new Date('1900-1-1')
        // }
        // frameCount={frames.length}
        // currentFrameIndex={currentFrameIndex}
        // onFrameChange={setCurrentFrameIndex}

        frames={frames}
        start={frames[0]?.time}
        end={frames[frames.length - 1]?.time}
        onTick={(seconds) => {
          setCurrentFrameIndex(frameBySecond[seconds] ?? frames.length - 1);
        }}
      />
    );
  }

  function renderReplayItemList() {
    return (
      <EventReplayItemList
        featureCollections={
          frames.length > 0
            ? frames[currentFrameIndex].featureCollections
            : null
        }
        followedItemIndexes={followedItemIndexes}
        selectedItemIndex={selectedItemIndex}
        hoveredItemIndex={hoveredItemIndex}
        onFollowedItemsChange={setFollowedItemIndexes}
        onSelectItem={setSelectedItemIndex}
        onHoverItem={setHoveredItemIndex}
      />
    );
  }

  function mapSection() {
    if (isLoading || isFetching) {
      return (
        <Paper
          sx={{
            height: 'calc(100vh - 125px)',
            m: 1,
            mt: 0,
            minWidth: 250,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {showLoader()}
        </Paper>
      );
    } else if (isXs) {
      return (
        <Fragment>
          {renderReplayControls()}
          <Box sx={{ height: 'calc(100% - 45px)' }}>{renderReplayMap()}</Box>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Paper
            sx={{
              height: 'calc(100vh - 125px)',
              m: 1,
              mt: 0,
              minWidth: 250,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {renderReplayMap()}
          </Paper>
          {renderReplayControls()}
        </Fragment>
      );
    }
  }

  return (
    <Fragment>
      <AppBar position="static">
        <Toolbar variant="dense">
          <ListItem sx={{ p: 0 }} component="div">
            <IconButton
              color="inherit"
              aria-label="Back"
              sx={{ ml: -1.5, mr: 1.25 }}
              onClick={handleBackClick}
              size="large"
            >
              <ArrowBackIcon />
            </IconButton>
            <ListItemText
              sx={{ p: 0 }}
              primaryTypographyProps={{ color: 'inherit', noWrap: true }}
              secondaryTypographyProps={{ color: 'inherit', noWrap: true }}
              primary={id || ''}
              // secondary={replay ? replay.identifier : ''}
            />
            {isXs && (
              <IconButton
                color="inherit"
                aria-label="Details"
                sx={{ mr: 0 }}
                onClick={handleOpen}
                size="large"
              >
                <ViewListIcon />
              </IconButton>
            )}
          </ListItem>
        </Toolbar>
      </AppBar>
      <Box sx={{ mt: 1, display: 'flex' }}>
        {!isXs && (
          <Box
            sx={{
              width: 300,
              height: 'calc(100vh - 48px)',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            {id ? renderReplayItemList() : showLoader()}
          </Box>
        )}
        <Box
          sx={(theme) => ({
            width: 'calc(100% - 300px)',
            [theme.breakpoints.down('sm')]: {
              width: 1,
            },
            height: 'calc(100vh - 48px)',
            overflowY: 'auto',
            overflowX: 'hidden',
          })}
        >
          {mapSection()}
        </Box>
      </Box>
      {isXs && (
        <Drawer anchor="bottom" open={drawerOpen} onClose={handleClose}>
          <Box sx={{ maxHeight: '80vh', overflow: 'scroll' }}>
            {id ? renderReplayItemList() : showLoader()}
          </Box>
        </Drawer>
      )}
    </Fragment>
  );
}

import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function usePrisoners(options) {
  const searchParams = JSON.parse(JSON.stringify(options ?? {}));

  return useQuery({
    queryKey: ['nichePrisoners', searchParams],
    queryFn: () => {
      return briefingApi
        .get('niche/custody', { searchParams, timeout: false })
        .json();
    },
    placeholderData: [],
    staleTime: 30 * (60 * 1000),
    cacheTime: 35 * (60 * 1000),
  });
}

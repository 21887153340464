import { GroupCodesController } from '@/components/controls';
import { useSections } from '@/hooks';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Fragment, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SectionSwitches } from './SectionSwitches';

export function BriefConfigurationDialog({ brief, isOpen, onClose, onDelete }) {
  const { data: sections } = useSections();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: brief,
  });

  useEffect(() => {
    reset(brief);
  }, [reset, brief, isOpen]);

  function handleClose() {
    onClose();
  }

  return (
    <form id="brief-configuration-form" onSubmit={handleSubmit(onClose)}>
      <Dialog open={isOpen && !!brief} onClose={handleClose}>
        <DialogTitle>{`${brief.code ? 'Edit' : 'Add'} Brief`}</DialogTitle>
        <DialogContent>
          <Stack spacing={1} sx={{ pt: 1, maxHeight: '60vh' }}>
            {/* <DialogContentText>
              Please choose the teams and sectors you&apos;d like this brief to
              cover, and the sections you wish to see.
            </DialogContentText> */}
            <Controller
              name="title"
              control={control}
              defaultValue=""
              rules={{ required: 'Required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Title"
                  size="small"
                  sx={{ flexGrow: 1 }}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <GroupCodesController
              label="Groups"
              control={control}
              name="groupCodes"
              types={['SECTOR', 'TEAM']}
            />
            <Typography type="subtitle2" color="textSecondary" gutterBottom>
              Sections
            </Typography>
            <Controller
              name="excludedSections"
              control={control}
              render={({ field: { value, onChange } }) => (
                <SectionSwitches
                  sections={sections}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          {!!brief.code && (
            <Fragment>
              <Button color="error" onClick={onDelete}>
                Delete
              </Button>
              <Box sx={{ flexGrow: 1 }} />
            </Fragment>
          )}
          <Button onClick={handleClose}>Cancel</Button>
          <Button form="brief-configuration-form" type="submit">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}

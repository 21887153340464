import { DataGrid } from '@/components/controls';
import { useTelematicsBoxAssignmentCounts } from '@/hooks';
import { getColor } from '@/utils';
import { Card, CardContent, Typography } from '@mui/material';

const columns = [
  {
    accessorKey: 'assignments',
    header: 'Assignments',
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
    size: 0,
  },
  {
    accessorKey: 'boxes',
    header: 'Boxes',
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
    size: 0,
  },
];

export function BoxAssignmentsCard() {
  const { data } = useTelematicsBoxAssignmentCounts();

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          Telematics Box Assignments
        </Typography>
        <DataGrid
          data={data}
          columns={columns}
          muiTableBodyRowProps={({ row }) => ({
            sx: getColor(row.getValue('assignments')),
            hover: false,
          })}
        />
      </CardContent>
    </Card>
  );
}

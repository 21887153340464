import {
  Avatar,
  FormControlLabel,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { LocationOn as LocationOnIcon } from '@mui/icons-material';
import { format, formatDistanceToNow } from 'date-fns';
import defaultPersonImage from '@/img/defaultPhotoImage.jpeg';
import { useOccurrenceOic, useUser } from '@/hooks';
import { Fragment } from 'react';

export function PrisonRecall({
  prisonRecall,
  onShowLocationClick,
  onCirculateFieldClick,
}) {
  function handleCirculateFieldClick(e) {
    const prisonRecallPerson = { ...prisonRecall };
    delete prisonRecallPerson.occurrence;
    delete prisonRecallPerson.person;
    onCirculateFieldClick(prisonRecallPerson, e.target.name);
  }

  const { data: user } = useUser(prisonRecall?.edited?.userCode);
  const { data: oicAndOel } = useOccurrenceOic(prisonRecall.occurrence.number);

  return (
    <Card variant="outlined" sx={{ minWidth: 240 }}>
      <CardHeader
        action={
          <Fragment>
            <Tooltip title="Suspect home address">
              <IconButton onClick={onShowLocationClick}>
                <LocationOnIcon />
              </IconButton>
            </Tooltip>
          </Fragment>
        }
        subheader={
          <Tooltip title="Outstanding for other matters">
            {prisonRecall.person?.classification.includes('SUS') ||
            prisonRecall.person.classification.includes('WAN')
              ? prisonRecall.person?.classificationDescription
              : ''}
          </Tooltip>
        }
      />
      {prisonRecall.person.image ? (
        <CardMedia
          component="img"
          // height="140"
          image={prisonRecall.person.image}
          alt={prisonRecall.person.firstName}
        />
      ) : (
        <CardMedia
          component="img"
          sx={{ maxWidth: 240 }}
          src={defaultPersonImage}
        />
      )}
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {prisonRecall.person.firstName} {prisonRecall.person.surname}
        </Typography>
        <Stack direction="row" spacing={0} justifyContent="space-between">
          <Stack direction="row" spacing={0.1} flexDirection="column">
            <Tooltip title="Occurrence Number" placement="left-start">
              <Typography
                variant="body3"
                color="text.secondary"
                gutterBottom
                sx={{ flexGrow: 1 }}
              >
                {prisonRecall.occurrence?.number}
              </Typography>
            </Tooltip>
            <Tooltip title="Occurrence Date" placement="left-start">
              <Typography variant="caption" sx={{ mt: -1, mb: 0.5 }}>
                {prisonRecall.occurrence?.startTime}
              </Typography>
            </Tooltip>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          sx={{ mt: 1 }}
          justifyContent="space-between"
        >
          <Typography variant="body1" gutterBottom sx={{ flexGrow: 1 }}>
            {prisonRecall.occurrence.classificationDescription}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Typography
            variant="caption"
            color="text.secondary"
            gutterBottom
            sx={{ flexGrow: 1 }}
          >
            Last OEL entry:{' '}
            {oicAndOel?.lastOelEntry
              ? format(new Date(oicAndOel?.lastOelEntry), 'dd-MM-yyyy HH:mm:ss')
              : ''}
          </Typography>
        </Stack>
        <Stack direction="column">
          <FormControlLabel
            componentsProps={{ typography: { variant: 'caption' } }}
            sx={{ padding: -10 }}
            control={
              <Checkbox
                checked={prisonRecall?.pncCirculated}
                onChange={handleCirculateFieldClick}
                name="pncCirculated"
                size="small"
              />
            }
            label="PNC Circulated"
          />
          <FormControlLabel
            componentsProps={{ typography: { variant: 'caption' } }}
            control={
              <Checkbox
                checked={prisonRecall?.socialMediaCirculated}
                onChange={handleCirculateFieldClick}
                name="socialMediaCirculated"
                size="small"
              />
            }
            label="Social Media Circulated"
          />
          <Typography variant="caption" color="text.secondary" gutterBottom>
            {user
              ? `${user?.name} - ${formatDistanceToNow(
                  new Date(prisonRecall?.edited?.time),
                )} ago`
              : '-'}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

function getType(type) {
  switch (type) {
    case 'PRC':
      return 0;
    default:
      return 1;
  }
}

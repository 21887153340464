import { api } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useDeleteNotifications() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (ids) => {
      if ((ids || []).length > 0) {
        const deletions = ids.map((id) =>
          api.delete(`userNotifications/${id}`),
        );

        return Promise.all(deletions);
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['notifications'] }),
  });
}

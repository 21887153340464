import {
  useAddOptionValue,
  useMembers,
  useOptions,
  useRemoveOptionValue,
  useShifts,
} from '@/hooks';
import { Done as DoneIcon } from '@mui/icons-material';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useQueryClient } from '@tanstack/react-query';
import { startOfToday } from 'date-fns';
import { MaterialReactTable } from 'material-react-table';
import { Fragment, useMemo, useState } from 'react';

const membersColumns = [
  { header: 'Status Category', accessorKey: 'statusCategory' },
  { header: 'Status', accessorKey: 'status' },
  { header: 'Incident', accessorKey: 'incidentNumber' },
  { header: 'Call Sign', accessorKey: 'callSign' },
  { header: 'Collar Number', accessorKey: 'collarNumber' },
  { header: 'Name', accessorKey: 'name' },
  { header: 'Groups', accessorKey: 'groups' },
];

export function Teams({ groupCodes, onComplete }) {
  const [date, setDate] = useState(startOfToday());
  const {
    data: shifts,
    isLoading: shiftsLoading,
    isFetching: shiftsFetching,
  } = useShifts(date, groupCodes);
  const {
    data,
    isLoading: membersLoading,
    isFetching: membersFetching,
  } = useMembers({ groups: groupCodes });
  const members = useMemo(
    () =>
      data.map((member) => ({
        collarNumber: member.collarNumber,
        name: `${member.forenames} ${member.surname}`,
        callSign: member.assignments?.callSign?.code,
        statusCategory: member.assignments?.callSign?.category,
        status: member.assignments?.callSign?.status,
        incidentNumber: member.assignments?.incident?.number,
        groups: (member.groupNames ?? []).join(', '),
      })),
    [data],
  );
  const { data: shiftTypes } = useOptions('shiftType');
  const { mutate: addShiftTypeValue } = useAddOptionValue();
  const { mutate: removeShiftTypeValue } = useRemoveOptionValue();
  const queryClient = useQueryClient();

  const handleFieldBlur = (row) => (event) => {
    if (event.target.value !== row.original.type) {
      if (row.original.type) {
        removeShiftTypeValue(
          {
            name: 'shiftType',
            value: row.original.type,
            entry: row.original.shiftName,
          },
          {
            onSuccess: () =>
              queryClient.invalidateQueries({ queryKey: ['shifts', date] }),
          },
        );
      }

      if (event.target.value) {
        addShiftTypeValue(
          {
            name: 'shiftType',
            value: event.target.value,
            entry: row.original.shiftName,
          },
          {
            onSuccess: () =>
              queryClient.invalidateQueries({ queryKey: ['shifts', date] }),
          },
        );
      }
    }
  };

  const shiftsColumns = useMemo(
    () => [
      { header: 'Period', accessorKey: 'period' },
      { header: 'Team', accessorKey: 'teamName' },
      { header: 'Type', accessorKey: 'groupSubtype' },
      { header: 'Shift', accessorKey: 'shiftName' },
      {
        header: 'Status',
        accessorKey: 'type',
        enableEditing: true,
        editVariant: 'select',
        editSelectOptions: [
          { value: '', text: '' },
          ...Object.entries(shiftTypes).map(([value, text]) => ({
            value,
            text,
          })),
        ],
        Cell: ({ cell }) => shiftTypes[cell.getValue()],
      },
      {
        header: 'Force Number',
        accessorKey: 'forceNumber',
        enableGrouping: false,
      },
      {
        header: 'Name',
        accessorKey: 'name',
        enableGrouping: false,
      },
      {
        header: 'Call Sign',
        accessorKey: 'callSign',
        enableGrouping: false,
      },
      {
        header: 'Phone',
        accessorKey: 'phone',
        enableGrouping: false,
      },
      {
        header: 'Remarks',
        accessorKey: 'remarks',
        enableGrouping: false,
      },
    ],
    [shiftTypes],
  );

  function handleCompleteClick() {
    onComplete();
  }

  return (
    <Fragment>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{
          position: 'sticky',
          top: 48,
          backgroundColor: 'background.default',
          zIndex: 700,
          pl: 1,
          py: 1,
        }}
      >
        <Typography variant="h6">Teams</Typography>
        <Box sx={{ flexGrow: 1 }}>
          <Tooltip title="Done" placement="right">
            <IconButton onClick={handleCompleteClick}>
              <DoneIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
        <DatePicker
          label="Date"
          format="dd/MM/yyyy"
          value={date}
          onChange={setDate}
          slotProps={{ textField: { size: 'small' } }}
        />
      </Stack>
      <Stack spacing={1} sx={{ pb: 1.5 }}>
        <Typography variant="subtitle1" sx={{ pl: 1 }}>
          Scheduled
        </Typography>
        <MaterialReactTable
          muiTablePaperProps={{ variant: 'outlined', elevation: 0 }}
          defaultColumn={{ size: 0, enableEditing: false }}
          columns={shiftsColumns}
          data={shifts}
          enableGrouping
          enableEditing
          editDisplayMode="cell"
          enableBottomToolbar={false}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enablePagination={false}
          state={{ isLoading: shiftsLoading || shiftsFetching }}
          initialState={{
            density: 'compact',
            expanded: true,
            grouping: ['period'],
            sorting: [{ id: 'period', desc: false }],
            columnVisibility: { remarks: false },
          }}
          muiTableBodyRowProps={{
            sx: { '&:last-child td, &:last-child th': { border: 0 } },
          }}
          muiSearchTextFieldProps={{ variant: 'outlined', size: 'small' }}
          muiEditTextFieldProps={({ row }) => ({
            variant: 'outlined',
            size: 'small',
            inputProps: { sx: { fontSize: 14 } },
            onBlur: handleFieldBlur(row),
          })}
        />
        <Typography variant="subtitle1" sx={{ pl: 1 }}>
          Active
        </Typography>
        <MaterialReactTable
          muiTablePaperProps={{ variant: 'outlined', elevation: 0 }}
          defaultColumn={{ size: 0 }}
          columns={membersColumns}
          data={members}
          enableGrouping
          enableBottomToolbar={false}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enablePagination={false}
          state={{ isLoading: membersLoading || membersFetching }}
          initialState={{
            density: 'compact',
            expanded: true,
            grouping: ['statusCategory'],
            sorting: [{ id: 'statusCategory', desc: false }],
          }}
          muiTableBodyRowProps={{
            sx: { '&:last-child td, &:last-child th': { border: 0 } },
          }}
          muiSearchTextFieldProps={{ variant: 'outlined', size: 'small' }}
        />
      </Stack>
    </Fragment>
  );
}

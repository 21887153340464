import { api } from '@/apis';
import { log, startCase } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useAggregate(
  collection,
  pipeline,
  enabled,
  staleTime,
  cacheTime,
) {
  const queryClient = useQueryClient();

  return {
    ...useQuery({
      queryKey: [collection, 'aggregate', pipeline],
      queryFn: ({ signal }) => {
        log('Read', startCase(collection), { pipeline });

        return api
          .post(`pipeline/${collection}`, { json: pipeline, signal })
          .json();
      },
      placeholderData: (previousData) => previousData ?? [],
      enabled: !!enabled,
      staleTime,
      cacheTime,
    }),
    cancel: () =>
      queryClient.cancelQueries({
        queryKey: [collection, 'tabulated', pipeline],
      }),
  };
}

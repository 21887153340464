import { VirtualisedListbox } from '@/components/controls';
import {
  Autocomplete,
  Chip,
  TextField,
  createFilterOptions,
  useTheme,
} from '@mui/material';
import _ from 'lodash';

const defaultValue = [];
const LISTBOX_PADDING = 8; // px
const labelValueFilter = createFilterOptions({
  stringify: (option) => option.value,
});

function getLabel(option) {
  let label = option?.label ?? option?.altLabel ?? option ?? '';

  if (label === '*Any' || label === '*None') {
    label = label.substring(1);
  }

  return label.toString();
}

function renderRow({ data, index, style, data: { styles = {} } }) {
  const [props, option] = data[index];

  return (
    <div
      {...props}
      style={{
        ...style,
        top: style.top + LISTBOX_PADDING,
        ...(styles[option?.className] ?? {}),
        whiteSpace: 'nowrap',
      }}
    >
      {getLabel(option)}
    </div>
  );

  // return cloneElement(<Component />, {
  //   style: {
  //     ...style,
  //     top: style.top + LISTBOX_PADDING,
  //     ...(styles[option?.className] ?? {}),
  //     whiteSpace: 'nowrap',
  //   },
  //   children: getLabel(option),
  // });
}

export function SelectMultiple({
  value,
  suggestions,
  onChange,
  disabled,
  typeIcons,
  className,
  styles = {},
  filterOptions,
  labelValue = false,
  anyOption = false,
  noneOption = false,
  meta = {},
  showNonExistantAsError = false,
  ...props
}) {
  const theme = useTheme();
  const divider = {
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider,
    borderBottomWidth: 1,
  };

  styles = {
    ...styles,
    divider,
  };

  if (!filterOptions && labelValue) {
    filterOptions = labelValueFilter;
  }

  // make sure it's just a list of strings as the change, so if the
  // changed item (last one) is an object get its value
  function handleChange(event, value) {
    const [selection, ...existingValues] = value.slice().reverse();

    if (typeof selection === 'object') {
      onChange([...existingValues.reverse(), selection.value]);
    } else {
      onChange(value);
    }
  }

  const safeValue =
    // !! disallows "" and defaultValue isn't a new [] every time
    value !== undefined && value !== ''
      ? Array.isArray(value)
        ? value
        : [value]
      : defaultValue;

  // don't show the none option if any is selected and vice versa
  noneOption = noneOption && !safeValue.includes('*Any');
  anyOption = anyOption && !safeValue.includes('*None');

  const newSuggestions = _.uniqBy(suggestions, 'value').filter(
    (s) => !['*Any', '*None'].includes(s.value),
  );

  // *Any and *None must always appear at the top, so any real-life
  // values that begin with e.g. #$%() don't get accidentally sorted
  // above Any/None because they have a lower ASCII value than '*'
  const options = [
    ...[
      anyOption && {
        value: '*Any',
        label: '*Any',
        className: noneOption ? undefined : 'divider',
      },
      noneOption && {
        value: '*None',
        label: '*None',
        className: 'divider',
      },
    ].filter(Boolean),
    ..._.orderBy(newSuggestions, ['type', 'label']),
  ];

  return (
    <Autocomplete
      size="small"
      fullWidth
      // {...props}
      multiple
      ListboxComponent={VirtualisedListbox}
      ListboxProps={{ styles, options, renderRow }}
      className={className}
      value={safeValue}
      disabled={disabled}
      onChange={handleChange}
      options={options}
      isOptionEqualToValue={(option, selected) => option.value === selected}
      // if custom filtering isn't applied autocomplete will attempt to do
      // it's own filtering which does option.toLowerCase and if option is
      // an object it will crash
      // getOptionLabel={!filterOptions ? getLabel : undefined}
      getOptionLabel={getLabel}
      renderOption={(props, option) => [props, option]}
      filterOptions={filterOptions}
      //{...optionRendering}
      renderInput={(params) => (
        <TextField
          size="small"
          {...params}
          {...props}
          helperText={meta.error ?? meta.submitError}
          error={meta.invalid}
        />
      )}
      renderTags={(value, getTagProps) =>
        safeValue.map((option, index) => {
          const suggestion = newSuggestions.find(
            (suggestion) => suggestion.value === option,
          );

          const exists =
            !showNonExistantAsError ||
            newSuggestions.find(
              (s) => (s.value ?? s) === (option.value ?? option),
            );

          let color = undefined;
          if (['*Any', '*None'].includes(option)) {
            color = 'primary';
          } else if (!exists) {
            color = 'error';
          }

          return (
            <Chip
              key={option}
              size="small"
              color={color}
              label={getLabel(suggestion || option)}
              {...getTagProps({ index })}
              icon={typeIcons && suggestion && typeIcons[suggestion.type]}
              style={styles[suggestion?.className]}
            />
          );
        })
      }
    />
  );
}

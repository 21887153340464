import { CircularProgress } from '@mui/material';
import { Fragment } from 'react';
import { useAssociatedPeople } from '@/hooks';
import { CrimePerson } from './CrimePerson';

export default function CrimePersons({ occurrenceCode }) {
  const {
    data: people,
    isLoading,
    isFetching,
  } = useAssociatedPeople(occurrenceCode);

  let associatedPeople = [];

  if (people?.associatedPeople) {
    const { victims, suspects, other } = people.associatedPeople;
    associatedPeople = [...victims, ...suspects, ...other];
  }

  if (isLoading || isFetching) {
    return <CircularProgress sx={{ m: 1 }} size={16} thickness={6} />;
  } else {
    return (
      <Fragment>
        {associatedPeople.map((person, i) => (
          <CrimePerson key={person.id} person={person} />
        ))}
      </Fragment>
    );
  }
}

import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useLocationSnapshot(time) {
  return useQuery({
    queryKey: ['locationSnapshot', time],
    queryFn: () => {
      return api.get(`geofencesSnapshot/${time.toISOString()}`).json();
    },
    placeholderData: (previousData) =>
      previousData ?? {
        locations: {
          type: 'FeatureCollection',
          features: [],
        },
        objectives: {
          type: 'FeatureCollection',
          features: [],
        },
      },
    staleTime: 1000 * 60 * 60,
  });
}

import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useGroups() {
  return useQuery({
    queryKey: ['groups'],
    queryFn: () => {
      const json = [
        {
          $graphLookup: {
            from: 'groups',
            startWith: '$parentCodes',
            connectFromField: 'parentCodes',
            connectToField: 'code',
            as: 'ancestorCodes',
          },
        },
        {
          $project: {
            _id: false,
            code: true,
            name: true,
            type: true,
            parentCodes: true,
            boundary: true,
            ancestorCodes: {
              $map: {
                input: '$ancestorCodes',
                as: 'group',
                in: '$$group.code',
              },
            },
          },
        },
      ];

      log('Read', 'Groups');

      return api.post('pipeline/groups', { json }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60 * 12,
  });
}

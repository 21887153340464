import {
  usePrisonRecalls,
  useIr3PrisonRecalls,
  useUpsertIr3PrisonRecall,
} from '@/hooks';
import { Done as DoneIcon } from '@mui/icons-material';
import { Masonry } from '@mui/lab';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Fragment, useMemo, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { LocationMapDialog } from '@/components/controls';
import { getStringBeforeCharacter, extractPostCode } from '@/utils';
import { PrisonRecall } from './PrisonRecall';

export function PrisonRecalls({ groupCodes, onComplete }) {
  //get all the prison recalls from IR3
  const { data: prisonRecallsFromIr3 } = useIr3PrisonRecalls();

  const prisonRecallIds = prisonRecallsFromIr3.map(
    (prisonRecall) => prisonRecall.code,
  );

  // get all prison recall from niche
  const { data: prisonRecallsFromNiche } = usePrisonRecalls({
    codes: groupCodes,
    ids: prisonRecallIds,
  });

  // combine ir3PrisonRecalls and Niche Prison recalls
  const prisonRecalls = useMemo(
    () =>
      prisonRecallsFromNiche.map((niche) => ({
        ...niche,
        ...prisonRecallsFromIr3.find(
          (ir3) => ir3.code === niche.person?.nominalId,
        ),
      })),
    [prisonRecallsFromNiche, prisonRecallsFromIr3],
  );

  const { mutate: upsertIr3PrisonRecall } = useUpsertIr3PrisonRecall();
  const [selected, setSelected] = useState(null);

  function handleShowLocationClick(prisonRecall) {
    return async function () {
      const prisonRecallFrom = getStringBeforeCharacter(
        prisonRecall.person.address,
        '(',
      );
      const prisonRecallPostcode = extractPostCode(prisonRecallFrom);
      prisonRecall.person.from = prisonRecallFrom;
      prisonRecall.person.postCode = prisonRecallPostcode;
      setSelected(prisonRecall);
    };
  }

  function handleLocationMapClose() {
    setSelected(null);
  }

  function handleCompleteClick() {
    onComplete();
  }

  const { width, ref } = useResizeDetector();
  const prisonRecallColumns = useMemo(
    () => Math.floor(width / 328) || 1,
    [width],
  );

  function handleCirculateFieldClick(prisonRecall, name) {
    upsertIr3PrisonRecall({
      ...prisonRecall,
      [name]: !prisonRecall[name],
    });
  }

  return (
    <Fragment>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{
          position: 'sticky',
          top: 48,
          backgroundColor: 'background.default',
          zIndex: 700,
          pl: 1,
          py: 1,
        }}
      >
        <Typography variant="h6">Prison Recalls</Typography>
        <Box>
          <Tooltip title="Done" placement="right">
            <IconButton onClick={handleCompleteClick}>
              <DoneIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
      <Stack spacing={1} sx={{ pb: 1 }}>
        <Masonry spacing={1} ref={ref} columns={prisonRecallColumns}>
          {prisonRecalls.map((prisonRecall) => (
            <PrisonRecall
              key={prisonRecall.person.id}
              prisonRecall={prisonRecall}
              onCirculateFieldClick={handleCirculateFieldClick}
              onShowLocationClick={handleShowLocationClick(prisonRecall)}
            />
          ))}
        </Masonry>
      </Stack>
      ,
      {selected && (
        <LocationMapDialog
          title={`${selected.person.firstName} ${selected.person.surname} Residence`}
          postCode={selected.person.postCode}
          address={selected.person.suspectFrom}
          isOpen={Boolean(selected)}
          onClose={handleLocationMapClose}
        />
      )}
    </Fragment>
  );
}

import { getStatusColor, kilometresToMiles, startCase } from '@/utils';
import {
  DirectionsCar as DirectionsCarIcon,
  GpsFixed as GpsFixedIcon,
  Router as RouterIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Chip,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material';
// import { Link } from 'react-router-dom';

export function VehicleListItem({
  item: { size, start },
  feature,
  selected,
  ...props
}) {
  const primary =
    feature.properties.vehicle?.registrationNumber ?? feature.properties.imei;
  const secondary =
    feature.properties?.vehicle?.fleetNumber ?? 'Unassigned box';

  return (
    <ListItem
      {...props}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: `${size}px`,
        transform: `translateY(${start}px)`,
      }}
      // secondaryAction={
      //   <Tooltip title="Follow">
      //     <IconButton
      //       // component={Link}
      //       // to={`${featureCollections[type][index].code}?copy=true`}
      //       state={{ copy: true }}
      //       size="small"
      //       edge="end"
      //     >
      //       <GpsFixedIcon fontSize="inherit" />
      //     </IconButton>
      //   </Tooltip>
      // }
      disablePadding
    >
      <ListItemButton
        dense
        // component={Link}
        // to={'.'}
        selected={selected}
      >
        <ListItemAvatar>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              feature.properties?.driver?.code && (
                <Tooltip
                  title={feature.properties?.driver?.code}
                  placement="right"
                >
                  <Avatar
                    sx={(theme) => ({
                      width: 22,
                      height: 22,
                      border: `2px solid ${theme.palette.background.paper}`,
                    })}
                  />
                </Tooltip>
              )
            }
          >
            <Tooltip
              title={startCase(feature.properties?.status)}
              placement="top"
            >
              <Avatar
                sx={{
                  bgcolor: getStatusColor(feature.properties?.status),
                }}
              >
                {feature.properties?.vehicle?.identificationNumber ? (
                  <DirectionsCarIcon />
                ) : (
                  <RouterIcon />
                )}
              </Avatar>
            </Tooltip>
          </Badge>
        </ListItemAvatar>
        <ListItemText
          primary={primary}
          primaryTypographyProps={{ noWrap: true }}
          secondary={secondary}
          secondaryTypographyProps={{ noWrap: true }}
        />
        <Chip
          size="small"
          label={`${kilometresToMiles(
            feature.properties?.speedKilometresPerHour,
          )} MPH`}
          sx={{ fontSize: '.6rem', height: 18 }}
        />
      </ListItemButton>
    </ListItem>
  );
}

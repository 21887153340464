import { usePrevious } from '@/hooks';
import { dioStates } from '@/utils/config';
import { Fragment, useEffect, useState } from 'react';
import { InputsStatus } from './InputsStatus';
import { StepsCheckList } from './StepsCheckList';
import { mapCanBusStatus, sentenceCase } from './utilities';

function offToOnStatus(poll, prevPoll, sortedPolls) {
  const isOn = poll ? poll.ignitionOn : prevPoll.ignitionOn;
  const turnedOn = sortedPolls?.some((poll) => poll?.diagnosticCode === '3');
  return isOn && !turnedOn
    ? 'Ignition already on, turn off first'
    : !poll
    ? 'This could take 30 seconds to update if ignition has been off for a while, please wait...'
    : undefined;
}

function checkOffToOn(poll, prevPoll, sortedPolls) {
  return sortedPolls?.some((poll) => poll?.diagnosticCode === '3');
}

const initialSequence = {
  // ignitionOn: { ignitionOn: true },
  // turnIgnitionOff: { diagnosticCode: '4' },
  turnIgnitionOffToOn: {
    label: 'Turn ignition off to on',
    // checkFunction: (poll) => poll?.diagnosticCode === '3',
    checkFunction: checkOffToOn,
    statusFunction: offToOnStatus,
  },
};

const CANBus = {
  label: 'CAN bus',
  async: true,
  checkFunction: (currPoll) =>
    mapCanBusStatus(currPoll?.deviceProperties?.canBusStatus) === 'Connected',
};

// const DriverID = {
//   label: 'Driver ID',
//   async: true,
//   checkFunction: (currPoll, prevPoll) => {
//     const currDriverIdTime = currPoll?.driver?.identificationTime;
//     const prevDriverIdTime = prevPoll?.driver?.identificationTime;

//     return currDriverIdTime && currDriverIdTime !== prevDriverIdTime;
//   },
// };

export function InputsSection({
  excludedInputs,
  onCompleteChanged,
  imei,
  testStartTime,
}) {
  const [commissioningStart, setCommissioningStart] = useState(
    testStartTime || new Date().toISOString(),
  );
  const [expectedSequence, setExpectedSequence] = useState({});
  // const [inputCheckComplete, setInputCheckComplete] = useState(false);

  const prevTestStartTime = usePrevious(testStartTime);
  useEffect(() => {
    if (prevTestStartTime !== testStartTime && testStartTime) {
      setCommissioningStart(testStartTime.toISOString());
    }
  }, [testStartTime, prevTestStartTime]);

  function handleInputCheckComplete(complete, verifiedInputs) {
    // setInputCheckComplete(complete);
    // setVerifiedInputs(verifiedInputs);

    onCompleteChanged && onCompleteChanged(complete, verifiedInputs);
  }

  useEffect(() => {
    function includedInputs() {
      return Object.keys(dioStates).filter((k) => !excludedInputs?.includes(k));
    }

    // the initial sequence expects ignition off then ignition on
    //    ignitionOff: { diagnosticCode: '4'},
    //    ignitionOn: { diagnosticCode: '3'}
    // then the rest of the inputs expect all inputs off, then
    // a diagnosticCode of '7' and themselves to be on e.g.
    //    allInputsOff: { sirensOn: false, beaconsOn: false ... }
    //    sirensOn: { diagnosticCode: '7', sirensOn: true}
    // the latter is generated dynamically below into expectedInputs
    let expectedInputs = {};
    let allInputsOff = {};
    Object.keys(dioStates)
      .filter((k) => !k.startsWith('unused'))
      .forEach((k) => (allInputsOff[k] = false));
    includedInputs().forEach((key, i) => {
      const targetBit = 2 ** i;
      const expected = {
        diagnosticCode: '7',
        [key]: true,
      };

      function otherInputsAreOn(poll) {
        const { inputs } = poll?.deviceProperties || 0;
        return inputs & (63 ^ targetBit);
      }

      function inputIsOn(poll) {
        return Object.keys(expected).every((k) => expected[k] === poll?.[k]);
      }

      if (!key.startsWith('unused')) {
        expectedInputs[key] = {
          label: sentenceCase(
            `Turn on ${dioStates[key] || ''} (input ${i + 1})`,
          ),
          async: false,
          checkFunction: (currPoll, prevPoll) => {
            return inputIsOn(currPoll) || inputIsOn(prevPoll);
          },
          statusFunction: (currPoll) => {
            if (otherInputsAreOn(currPoll)) {
              return 'Other inputs detected, if possible turn these off first';
            }
          },
          warnFunction: (currPoll, prevPoll) => {
            if (inputIsOn(currPoll)) {
              if (otherInputsAreOn(currPoll)) {
                return `Warning: expecting ${dioStates[
                  key
                ]?.toLowerCase()} to be on but multiple inputs detected`;
              }
            } else if (inputIsOn(prevPoll)) {
              let warning = `Warning: ${dioStates[
                key
              ]?.toLowerCase()} came on then went off again`;
              if (otherInputsAreOn(currPoll) || otherInputsAreOn(prevPoll)) {
                warning += ', with multiple inputs detected';
              }

              return warning;
            }

            return false;
          },
        };
      }
    });

    let expectedSequence = {
      ...initialSequence,
      ...expectedInputs,
      CANBus,
    };

    setExpectedSequence(expectedSequence);
  }, [excludedInputs]);

  // function restartInputTest() {
  //   setCommissioningStart(new Date());
  //   onCompleteChanged && onCompleteChanged(false, {});
  // }

  return (
    <Fragment>
      {/* <Button
        className={
          commissioningStart ? classes.negativeButton : classes.positiveButton
        }
        onClick={restartInputTest}
      >
        {commissioningStart ? 'Restart' : 'Start'}
      </Button> */}
      <InputsStatus imei={imei} />
      {Object.keys(expectedSequence).length > 0 && (
        <StepsCheckList
          sequenceToVerify={expectedSequence}
          stepsStartTime={commissioningStart}
          onCompleteChanged={handleInputCheckComplete}
          completeMessage={'Input test complete'}
          imei={imei}
          manualConfirmMode
        />
      )}
    </Fragment>
  );
}

import { briefingApi } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertHighPrioritySuspect() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ _id, ...json }) => {
      if (_id) {
        return briefingApi.put(`people/${json.code}`, { json }).json();
      } else {
        return briefingApi.post('people', { json }).json();
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['highPriority'] }),
  });
}

import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useNichePerson(code) {
  return useQuery({
    queryKey: ['people', code],
    queryFn: () => {
      return briefingApi.get(`niche/people/nominals/${code}`).json();
    },
    enabled: !!code,
    staleTime: 30 * 60 * 1000,
  });
}

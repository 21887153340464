import { round, shortHumanizer } from '@/utils';
import { events, tripClassifications, useDallasKeys } from '@/utils/config';
import { format } from 'date-fns';
import { Occupants } from './Occupants';

const { showTripOccupants = false } = events;

export const pipelineFn = (pagination, query, sorting) =>
  [
    { $match: query },
    {
      $project: {
        _id: false,
        identifier: true,
        equipmentActivations: { emergencyOn: true },
        driver: {
          code: true,
          forenames: true,
          surname: true,
          collarNumber: true,
          role: true,
        },
        vehicle: {
          registrationNumber: true,
          fleetNumber: true,
          role: true,
          groups: {
            division: {
              $reduce: {
                input: '$vehicle.groups.division',
                initialValue: '',
                in: {
                  $concat: [
                    '$$value',
                    { $cond: [{ $eq: ['$$value', ''] }, '', ', '] },
                    '$$this',
                  ],
                },
              },
            },
          },
          type: true,
          telematicsBoxImei: true,
        },
        rfidCard: {
          reference: true,
          type: true,
          label: true,
        },
        startTime: true,
        endTime: true,
        durationSeconds: true,
        distanceMiles: { $multiply: ['$distanceKilometres', 0.62137119] },
        maxSpeedMilesPerHour: {
          $multiply: ['$maxSpeedKilometresPerHour', 0.62137119],
        },
        startLocation: {
          $ifNull: [
            { $first: '$startLocations' },
            { name: 'Elsewhere', type: 'None' },
          ],
        },
        endLocation: {
          $ifNull: [
            { $first: '$endLocations' },
            { name: 'Elsewhere', type: 'None' },
          ],
        },
        classification: { $ifNull: ['$classification', 'None'] },
        occupants: true,
        occupantCount: {
          $cond: [{ $not: ['$occupants'] }, -1, { $size: '$occupants' }],
        },
        occupantsReasonCode: true,
      },
    },
    sorting.length > 0 && {
      $sort: sorting.reduce(
        (acc, { id, desc }) => ({ ...acc, [id]: desc ? -1 : 1 }),
        {},
      ),
    },
    { $skip: pagination.pageIndex * pagination.pageSize },
    { $limit: pagination.pageSize },
  ].filter(Boolean);

export const totalsPipelineFn = (query) => [
  { $match: query },
  {
    $group: {
      _id: null,
      total: { $sum: 1 },
      durationSeconds: { $sum: '$durationSeconds' },
      maxSpeedKilometresPerHour: { $max: '$maxSpeedKilometresPerHour' },
      distanceKilometres: { $sum: '$distanceKilometres' },
    },
  },
  {
    $set: {
      maxSpeedMilesPerHour: {
        $round: [
          {
            $multiply: ['$maxSpeedKilometresPerHour', 0.62137119],
          },
          2,
        ],
      },
      distanceMiles: {
        $round: [{ $multiply: ['$distanceKilometres', 0.62137119] }, 2],
      },
    },
  },
  { $unset: '_id' },
];

export const downloadPipelineFn = (query) => [
  { $match: query },
  {
    $project: {
      _id: false,
      identifier: true,
      equipmentActivations: {
        emergencyOn: {
          $cond: ['$equipmentActivations.emergencyOn', 'Yes', 'No'],
        },
      },
      driver: {
        code: true,
        forenames: true,
        surname: true,
        collarNumber: true,
        role: true,
      },
      vehicle: {
        registrationNumber: true,
        fleetNumber: true,
        role: true,
        groups: {
          division: {
            $reduce: {
              input: '$groups.division',
              initialValue: '',
              in: {
                $concat: [
                  '$$value',
                  { $cond: [{ $eq: ['$$value', ''] }, '', ', '] },
                  '$$this',
                ],
              },
            },
          },
        },
        type: true,
        telematicsBoxImei: true,
      },
      rfidCard: true,
      startTime: true,
      endTime: true,
      durationSeconds: { $divide: ['$durationSeconds', 86400] },
      distanceMiles: {
        $round: [{ $multiply: ['$distanceKilometres', 0.62137119] }, 2],
      },
      maxSpeedMilesPerHour: {
        $round: [{ $multiply: ['$maxSpeedKilometresPerHour', 0.62137119] }, 2],
      },
      startLocation: {
        $ifNull: [
          { $first: '$startLocations' },
          { name: 'Elsewhere', type: 'None' },
        ],
      },
      endLocation: {
        $ifNull: [
          { $first: '$endLocations' },
          { name: 'Elsewhere', type: 'None' },
        ],
      },
      classification: { $ifNull: ['$classification', 'None'] },
      occupantCount: {
        $map: {
          input: '$occupants',
          as: 'occupant',
          in: {
            $trim: {
              input: {
                $concat: [
                  '$$occupant.forenames',
                  ' ',
                  '$$occupant.surname',
                  ' [',
                  '$$occupant.collarNumber',
                  '] ',
                  {
                    $toString: {
                      $round: [{ $multiply: ['$$occupant.ratio', 100] }],
                    },
                  },
                  '% ',
                  {
                    $toString: {
                      $round: [
                        {
                          $multiply: [
                            '$$occupant.distanceKilometres',
                            0.62137119,
                          ],
                        },
                        2,
                      ],
                    },
                  },
                  ' mi',
                ],
              },
            },
          },
        },
      },
    },
  },
];

export const columnsFn = (footer, canEdit) =>
  [
    {
      header: 'Registration',
      id: 'vehicle.registrationNumber',
      key: 'vehicle.registrationNumber',
      accessorFn: ({ vehicle: { registrationNumber } = {} } = {}) =>
        registrationNumber ?? '',
    },
    {
      header: 'Fleet Number',
      id: 'vehicle.fleetNumber',
      key: 'vehicle.fleetNumber',
      accessorFn: ({ vehicle: { fleetNumber } = {} } = {}) => fleetNumber ?? '',
    },
    {
      header: 'Role',
      id: 'vehicle.role',
      key: 'vehicle.role',
      accessorFn: ({ vehicle: { role } = {} } = {}) => role ?? '',
    },
    {
      header: 'Division',
      id: 'vehicle.groups.division',
      key: 'vehicle.groups.division',
      accessorFn: ({ vehicle: { groups: { division } = {} } = {} } = {}) =>
        division ?? '',
    },
    {
      header: 'Type',
      id: 'vehicle.type',
      key: 'vehicle.type',
      accessorFn: ({ vehicle: { type } = {} } = {}) => type ?? '',
    },
    {
      header: 'Staff ID',
      id: 'driver.code',
      key: 'driver.code',
      accessorFn: ({ driver: { code } = {} } = {}) => code ?? '',
    },
    {
      header: 'Forenames',
      id: 'driver.forenames',
      key: 'driver.forenames',
      accessorFn: ({ driver: { forenames } = {} } = {}) => forenames ?? '',
    },
    {
      header: 'Surname',
      id: 'driver.surname',
      key: 'driver.surname',
      accessorFn: ({ driver: { surname } = {} } = {}) => surname ?? '',
    },
    {
      header: 'Collar Number',
      id: 'driver.collarNumber',
      key: 'driver.collarNumber',
      accessorFn: ({ driver: { collarNumber } = {} } = {}) =>
        collarNumber ?? '',
    },
    {
      header: 'Driver Role',
      id: 'driver.role',
      key: 'driver.role',
      accessorFn: ({ driver: { role } = {} } = {}) => role ?? '',
    },
    {
      header: 'IMEI',
      id: 'vehicle.telematicsBoxImei',
      key: 'vehicle.telematicsBoxImei',
      accessorFn: ({ vehicle: { telematicsBoxImei } = {} } = {}) =>
        telematicsBoxImei ?? '',
    },
    {
      header: 'Warning Equipment',
      id: 'equipmentActivations.emergencyOn',
      key: 'equipmentActivations.emergencyOn',
      accessorFn: ({ equipmentActivations: { emergencyOn } = {} } = {}) =>
        emergencyOn ? 'Yes' : 'No',
    },
    {
      header: useDallasKeys ? 'Dallas Key' : 'RFID Card',
      id: 'rfidCard.reference',
      key: 'rfidCard.reference',
      accessorFn: ({ rfidCard: { reference } = {} } = {}) => reference ?? '',
    },
    {
      header: `${useDallasKeys ? 'Dallas Key' : 'RFID Card'} Type`,
      id: 'rfidCard.type',
      key: 'rfidCard.type',
      accessorFn: ({ rfidCard: { type } = {} } = {}) => type ?? '',
    },
    {
      header: `${useDallasKeys ? 'Dallas Key' : 'RFID Card'} Label`,
      id: 'rfidCard.label',
      key: 'rfidCard.label',
      accessorFn: ({ rfidCard: { label } = {} } = {}) => label ?? '',
    },
    {
      header: 'Classification',
      accessorKey: 'classification',
      enableEditing: canEdit,
      editVariant: 'select',
      editSelectOptions: [
        { value: '', text: 'None' },
        ...tripClassifications.map(({ value, label }) => ({
          value,
          text: label,
        })),
      ],
    },
    {
      header: 'Start Location Type',
      id: 'startLocation.type',
      key: 'startLocation.type',
      accessorFn: ({ startLocation: { type } = {} } = {}) => type ?? '',
    },
    {
      header: 'Start Location Name',
      id: 'startLocation.name',
      key: 'startLocation.name',
      accessorFn: ({ startLocation: { name } = {} } = {}) => name ?? '',
    },
    {
      header: 'End Location Type',
      id: 'endLocation.type',
      key: 'endLocation.type',
      accessorFn: ({ endLocation: { type } = {} } = {}) => type ?? '',
    },
    {
      header: 'End Location Name',
      id: 'endLocation.name',
      key: 'endLocation.name',
      accessorFn: ({ endLocation: { name } = {} } = {}) => name ?? '',
    },
    {
      header: 'Start Time',
      id: 'startTime',
      key: 'startTime',
      accessorFn: ({ startTime }) =>
        startTime ? format(startTime, 'dd/MM/yyyy HH:mm:ss') : '',
    },
    {
      header: 'End Time',
      id: 'endTime',
      key: 'endTime',
      accessorFn: ({ endTime }) =>
        endTime ? format(endTime, 'dd/MM/yyyy HH:mm:ss') : '',
    },
    {
      header: 'Duration',
      id: 'durationSeconds',
      key: 'durationSeconds',
      accessorFn: ({ durationSeconds }) =>
        durationSeconds ? shortHumanizer(durationSeconds * 1000) : '',
      footer: footer.durationSeconds
        ? shortHumanizer(footer.durationSeconds * 1000)
        : '',
    },
    {
      header: 'Distance',
      id: 'distanceMiles',
      key: 'distanceMiles',
      accessorFn: ({ distanceMiles }) =>
        distanceMiles ? `${round(distanceMiles, 2)} mi` : '',
      footer: footer.distanceMiles
        ? `${round(footer.distanceMiles, 2)} mi`
        : '',
      muiTableHeadCellProps: { align: 'right' },
      muiTableBodyCellProps: { align: 'right' },
      muiTableFooterCellProps: { align: 'right' },
    },
    {
      header: 'Maximum Speed',
      id: 'maxSpeedMilesPerHour',
      key: 'maxSpeedMilesPerHour',
      accessorFn: ({ maxSpeedMilesPerHour }) =>
        maxSpeedMilesPerHour ? `${round(maxSpeedMilesPerHour, 2)} mph` : '',
      footer: footer.maxSpeedMilesPerHour
        ? `${round(footer.maxSpeedMilesPerHour, 2)} mph`
        : '',
      muiTableHeadCellProps: { align: 'right' },
      muiTableBodyCellProps: { align: 'right' },
      muiTableFooterCellProps: { align: 'right' },
    },
    showTripOccupants && {
      header: 'Detected Occupants',
      accessorKey: 'occupantCount',
      muiTableHeadCellProps: { align: 'right' },
      muiTableBodyCellProps: { align: 'right' },
      Cell: ({ row: { original } }) => <Occupants entry={original} />,
    },
    // {
    //   header: '',
    //   key: 'replay',
    //   type: 'component',
    //   component: ReplayLink,
    // },
    // {
    //   header: '',
    //   key: 'polls',
    //   type: 'component',
    //   component: DownloadPollsLink,
    // },
  ].filter(Boolean);

import {
  ADD_TAG_ITEM,
  CREATE_TAG,
  DELETE_TAG_ITEM,
  FETCH_TAGS,
} from '@/actions';
import { usePrevious } from '@/hooks';
import { doesIdExist } from '@/utils';
import {
  Autocomplete,
  Chip,
  TextField,
  Tooltip,
  createFilterOptions,
} from '@mui/material';
import { dequal } from 'dequal';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const filter = createFilterOptions();

export function TagControl({
  live,
  item,
  type,
  label,
  sx,
  disabled,
  regex = /[\^%/\\]+/,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUserTags = useSelector((state) => state.tags.tags);

  const itemTags = getItemTags();
  const prevItemTags = usePrevious(itemTags);
  const [savedTags, setSavedTags] = useState(itemTags);
  const [error, setError] = useState('');
  const tagExistsMessage = 'Tag already exists';
  const tagInvalidCharacterMessage = 'Tag contains invalid characters!';
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (!dequal(prevItemTags, itemTags)) {
      setSavedTags(getItemTags());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {
    dispatch({
      type: FETCH_TAGS,
    });
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error, setError]);

  const userTags = currentUserTags
    .map((tag) => tag['identifier'])
    .sort((a, b) => a.localeCompare(b));
  const tagsWithDescription = currentUserTags.map((tag) => {
    return { [tag.identifier]: tag.description?.blocks[0].text };
  });

  function getDescription(key) {
    let desc = '';
    tagsWithDescription.forEach((tag) => {
      if (tag[key]) {
        desc = tag[key];
      }
    });
    return desc;
  }

  function removeTagFromItem(tag) {
    dispatch({
      type: DELETE_TAG_ITEM,
      payload: {
        id: tag,
        item: `items.${type}`,
        value: item.id,
        type: type,
      },
    });

    const newTags = savedTags.filter((t) => t !== tag);
    setSavedTags(newTags);
    setError('');
  }

  function addTagToItem(tag) {
    const trimmedTag = tag.trim();
    if (trimmedTag) {
      dispatch({
        type: ADD_TAG_ITEM,
        payload: {
          id: trimmedTag,
          item: `items.${type}`,
          value: item.id,
          type,
        },
      });
      setSavedTags([...savedTags, tag]);
      setError('');
    }
  }

  async function addNewTagToItem(tag) {
    const trimmedTag = tag.trim();
    const tagAlreadyExists = await doesIdExist('tags', trimmedTag);
    const hasSpecialCharaters = regex.test(trimmedTag);

    if (tagAlreadyExists) {
      setError(tagExistsMessage);
      enqueueSnackbar(error, { variant: 'error' });
    }

    if (hasSpecialCharaters) {
      setError(tagInvalidCharacterMessage);
      enqueueSnackbar(error, { variant: 'error' });
    }

    if (!!trimmedTag && !tagAlreadyExists && !hasSpecialCharaters) {
      dispatch({
        type: CREATE_TAG,
        payload: {
          identifier: trimmedTag,
          items: { [type]: [item.id] },
        },
        navigate,
      });
      setSavedTags([...savedTags, tag]);
      setError('');
      setInputValue('');
    }
  }

  function handleOnChange(event, newTags) {
    // something changed, was something added or cut out?
    const added = newTags.filter((t) => !savedTags.includes(t)).pop();
    const deleted = savedTags.filter((t) => !newTags.includes(t)).pop();

    if (added) {
      const isNewTag = !userTags.includes(added);
      isNewTag ? addNewTagToItem(added) : addTagToItem(added);
    } else if (deleted) {
      removeTagFromItem(deleted);
    }
  }

  function getItemTags() {
    const selectedTags = [];
    currentUserTags.forEach((tag) => {
      if (tag.items) {
        tag.items[type]?.forEach((tagItem) => {
          if (tagItem === item.id) {
            selectedTags.push(tag.identifier);
          }
        });
      }
    });
    return [...new Set(selectedTags)];
  }

  function handleChipClick(tag) {
    navigate(`/${live ? 'live/' : ''}tags/${encodeURIComponent(tag)}`);
  }

  //Follow Tag rules
  function handleInputChange(event, value, reason) {
    if (reason === 'reset' && !regex.test(value) && value.length <= 50) {
      setInputValue(value);
    } else if (reason === 'input' && !regex.test(value) && value.length <= 50) {
      setInputValue(value);
    }
  }

  return (
    <Autocomplete
      disableClearable
      fullWidth
      freeSolo
      clearOnBlur={false}
      multiple
      value={savedTags}
      onChange={handleOnChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      options={userTags}
      sx={sx}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          variant="filled"
          placeholder="Add tags..."
          label={label}
          {...params}
          InputLabelProps={{ shrink: true }}
        />
      )}
      renderOption={(props, option) => {
        const isNew = !userTags.includes(option);
        return (
          <div {...props}>
            {isNew ? (
              `Create new tag "${option}"`
            ) : (
              <Tooltip title={getDescription(option)} placement="bottom">
                <div>{option}</div>
              </Tooltip>
            )}
          </div>
        );
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          return (
            <Chip
              key={option}
              color="secondary"
              onClick={() => handleChipClick(savedTags[index])}
              label={option}
              {...getTagProps({ index })}
            />
          );
        })
      }
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value if typed tag doesn't exist
        const typedOption = params.inputValue?.trim();
        if (typedOption && !userTags.includes(typedOption)) {
          filtered.push(params.inputValue.trim());
        }

        return filtered;
      }}
    ></Autocomplete>
  );
}

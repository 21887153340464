import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useTelematicsBoxes() {
  return useQuery({
    queryKey: ['telematicsBoxes'],
    queryFn: async () => {
      const json = [
        {
          $project: {
            imei: true,
            type: true,
            label: true,
            firstContactTime: true,
            lastPoll: '$mostRecentPoll',
          },
        },
        {
          $lookup: {
            from: 'vehicles',
            localField: '_id',
            foreignField: 'telematicsBoxImei',
            as: 'vehicles',
          },
        },
        {
          $set: {
            assignmentCount: {
              $size: {
                $filter: {
                  input: '$vehicles',
                  as: 'vehicle',
                  cond: { $ne: ['$$vehicle.deleted', true] },
                },
              },
            },
          },
        },
        { $unset: 'vehicles' },
      ];

      log('Read', 'Telematics Boxes');

      return api.post('pipeline/telematicsBoxes', { json }).json();
    },
    placeholderData: [],
    cacheTime: 60 * 60 * 1000,
  });
}

import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useResourceSnapshot(time) {
  return useQuery({
    queryKey: ['resourceSnapshot', time],
    queryFn: () => {
      return api.get(`resourcesSnapshot/${time.toISOString()}`).json();
    },
    placeholderData: (previousData) =>
      previousData ?? {
        vehicles: {
          type: 'FeatureCollection',
          features: [],
        },
        people: {
          type: 'FeatureCollection',
          features: [],
        },
        incidents: {
          type: 'FeatureCollection',
          features: [],
        },
        events: {
          type: 'FeatureCollection',
          features: [],
        },
        callSigns: {
          type: 'FeatureCollection',
          features: [],
        },
        tags: {
          type: 'FeatureCollection',
          features: [],
        },
      },
    staleTime: 1000 * 60 * 60,
  });
}

import { ContentEditor } from '@/components/controls';
import { FormHelperText } from '@mui/material';
import { Fragment } from 'react';

export function ContentField({ input: { value, onChange }, meta, ...props }) {
  return (
    <Fragment>
      <ContentEditor
        {...props}
        placeholder="Write something..."
        value={value}
        onChange={onChange}
        invalidState={meta.invalid}
      />
      {meta.invalid && (
        <FormHelperText sx={{ width: 1, mb: 1, ml: 2 }} error>
          {meta.error}
        </FormHelperText>
      )}
    </Fragment>
  );
}

import {
  AdminPanelSettings as AdminPanelSettingsIcon,
  HowToReg as AttendanceIcon,
  BarChart as BarChartIcon,
  ChromeReaderMode as ChromeReaderModeIcon,
  DataUsage as DataUsageIcon,
  DirectionsCar as DirectionsCarIcon,
  DirectionsWalk as DirectionsWalkIcon,
  EmojiTransportation as EmojiTransportationIcon,
  Event as EventIcon,
  ExploreOff as ExploreOffIcon,
  FiberManualRecord as FiberManualRecordIcon,
  Fluorescent as FluorescentIcon,
  Group as GroupIcon,
  Groups as GroupsIcon,
  History as HistoryIcon,
  Home as HomeIcon,
  Language as LanguageIcon,
  LocationCity as LocationCityIcon,
  More as MoreIcon,
  NewReleases as NewReleasesIcon,
  Nfc as NfcIcon,
  People as PeopleIcon,
  Person as PersonIcon,
  Replay as ReplayIcon,
  Reply as ReplyIcon,
  Router as RouterIcon,
  Schedule as ScheduleIcon,
  Snooze as SnoozeIcon,
  Star as StarIcon,
  Timeline as TimelineIcon,
  Timer as TimerIcon,
  VpnKey as VpnKeyIcon,
  Work as WorkIcon,
  Inventory as InventoryIcon,
} from '@mui/icons-material';
import { SvgIcon } from '@mui/material';
import {
  AlertCircle as AlertCircleIcon,
  AxisArrow as AxisArrowIcon,
  CarBrakeParking as CarBrakeParkingIcon,
  CarSide as CarSideIcon,
  CarTractionControl as CarTractionControlIcon,
  Counter as CounterIcon,
  EngineOutline as EngineOutlineIcon,
  Garage as GarageIcon,
  AccountCancel as PeopleAvailabilityIcon,
  RadioHandheld as RadioHandheldIcon,
  Speedometer as SpeedometerIcon,
  Steering as SteeringIcon,
} from 'mdi-material-ui';
import { Navigate } from 'react-router-dom';
import { App } from './components/App';
import { ErrorBoundary } from './components/ErrorBoundary';
import { EventReplay } from './components/EventReplay';
import {
  BasicCommandUnit,
  Fleet,
  Highlights,
  HighlightsToolbar,
  Sector,
  Team,
} from './components/Highlights';
import { Home } from './components/Home';
import { Replay } from './components/Replay';
import { SecureRoute } from './components/SecureRoute';
import { Audit, DataRetention } from './components/admin';
import { TextHeader } from './components/controls';
import {
  AccelerometerEvents,
  Attendances,
  DoubleCrews,
  Idles,
  IncidentResponses,
  MalfunctionIndicatorLight,
  OnBoardDiagnostics,
  Outages,
  PersonLocationVisits,
  SpeedInfractions,
  Stops,
  Trails,
  Trip,
  Trips,
  VehicleEquipmentActivations,
  VehicleLocationVisits,
} from './components/events';
import { Group, Groups } from './components/groups';
import { Live } from './components/live';
import { Location, Locations } from './components/locations';
import { Objective, Objectives } from './components/objectives';
import {
  DailyActivity,
  DailyUtilisation,
  DriverBehaviourDashboard,
  HourlyActivity,
  HourlyInLocations,
  HourlyUtilisation,
  LastContact,
  Mileage,
  PersonAvailability,
  TimelineInLocations,
  VehicleAvailability,
} from './components/reports';
import {
  People,
  Person,
  Radio,
  Radios,
  RfidCard,
  RfidCards,
  TelematicsBox,
  TelematicsBoxes,
  Vehicle,
  Vehicles,
} from './components/resources';
import { Retrospective } from './components/retrospective';
import { Tag, Tags } from './components/tags';
import { hiddenRoutes, locationTypes, useDallasKeys } from './utils/config';
import { Brief, BriefHeader, Briefs } from './components/briefing';

export const routes = [
  {
    path: '*',
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      { index: true, element: <Home /> },
      {
        path: 'live/:type?/:id?/:subId?',
        element: (
          <SecureRoute>
            <Live />
          </SecureRoute>
        ),
      },
      {
        path: 'retrospective/:id?/:layerIndex?/:itemIndex?/:subItemIndex?',
        element: (
          <SecureRoute>
            <Retrospective />
          </SecureRoute>
        ),
      },
      {
        path: 'highlights',
        element: (
          <SecureRoute>
            <Highlights />
          </SecureRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <Navigate replace={true} to="/highlights/basiccommandunit" />
            ),
          },
          { path: 'basiccommandunit', element: <BasicCommandUnit /> },
          { path: 'sector', element: <Sector /> },
          { path: 'team', element: <Team /> },
          { path: 'fleet', element: <Fleet /> },
        ],
      },
      {
        path: 'eventreplay/:type/:id',
        element: (
          <SecureRoute>
            <EventReplay />
          </SecureRoute>
        ),
      },
      {
        path: 'replay',
        element: (
          <SecureRoute>
            <Replay />
          </SecureRoute>
        ),
      },
      {
        path: 'tags',
        element: (
          <SecureRoute>
            <Tags />
          </SecureRoute>
        ),
        children: [{ path: ':id', element: <Tag /> }],
      },
      {
        path: 'objectives',
        element: (
          <SecureRoute>
            <Objectives />
          </SecureRoute>
        ),
        children: [{ path: ':id', element: <Objective /> }],
      },
      {
        path: 'groups',
        element: (
          <SecureRoute>
            <Groups />
          </SecureRoute>
        ),
        children: [{ path: ':code', element: <Group /> }],
      },
      {
        path: 'resources',
        children: [
          {
            path: 'vehicles',
            element: (
              <SecureRoute>
                <Vehicles />
              </SecureRoute>
            ),
            children: [{ path: ':id', element: <Vehicle /> }],
          },
          {
            path: 'people',
            element: (
              <SecureRoute>
                <People />
              </SecureRoute>
            ),
            children: [{ path: ':id', element: <Person /> }],
          },
          {
            path: 'telematicsboxes',
            element: (
              <SecureRoute>
                <TelematicsBoxes />
              </SecureRoute>
            ),
            children: [{ path: ':id', element: <TelematicsBox /> }],
          },
          {
            path: 'radios',
            element: (
              <SecureRoute>
                <Radios />
              </SecureRoute>
            ),
            children: [{ path: ':id', element: <Radio /> }],
          },
          {
            path: `${useDallasKeys ? 'dallaskeys' : 'rfidcards'}`,
            element: (
              <SecureRoute>
                <RfidCards />
              </SecureRoute>
            ),
            children: [{ path: ':id', element: <RfidCard /> }],
          },
        ],
      },
      {
        path: 'locations',
        children: [
          {
            path: ':locationType',
            element: (
              <SecureRoute>
                <Locations />
              </SecureRoute>
            ),
            children: [{ path: ':id', element: <Location /> }],
          },
        ],
      },
      {
        path: 'reports',
        children: [
          {
            path: 'vehicles',
            children: [
              {
                path: 'dailyutilisation',
                element: (
                  <SecureRoute>
                    <DailyUtilisation />
                  </SecureRoute>
                ),
              },
              {
                path: 'hourlyutilisation',
                element: (
                  <SecureRoute>
                    <HourlyUtilisation />
                  </SecureRoute>
                ),
              },
              {
                path: 'hourlyinlocations',
                element: (
                  <SecureRoute>
                    <HourlyInLocations />
                  </SecureRoute>
                ),
              },
              {
                path: 'timelineinlocations',
                element: (
                  <SecureRoute>
                    <TimelineInLocations />
                  </SecureRoute>
                ),
              },
              {
                path: 'mileage',
                element: (
                  <SecureRoute>
                    <Mileage />
                  </SecureRoute>
                ),
              },
              {
                path: 'availability',
                element: (
                  <SecureRoute>
                    <VehicleAvailability />
                  </SecureRoute>
                ),
              },
            ],
          },
          {
            path: 'people',
            children: [
              {
                path: 'dailyactivity',
                element: (
                  <SecureRoute>
                    <DailyActivity />
                  </SecureRoute>
                ),
              },
              {
                path: 'hourlyactivity',
                element: (
                  <SecureRoute>
                    <HourlyActivity />
                  </SecureRoute>
                ),
              },
              {
                path: 'availability',
                element: (
                  <SecureRoute>
                    <PersonAvailability />
                  </SecureRoute>
                ),
              },
              {
                path: 'driverbehaviour',
                element: (
                  <SecureRoute>
                    <DriverBehaviourDashboard />
                  </SecureRoute>
                ),
              },
            ],
          },
          {
            path: 'telematicsboxes',
            children: [
              {
                path: 'lastcontact',
                element: (
                  <SecureRoute>
                    <LastContact />
                  </SecureRoute>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'events',
        children: [
          {
            path: 'vehicles',
            children: [
              {
                path: 'trips',
                children: [
                  {
                    index: true,
                    element: (
                      <SecureRoute>
                        <Trips />
                      </SecureRoute>
                    ),
                  },
                  {
                    path: ':id',
                    element: (
                      <SecureRoute>
                        <Trip />
                      </SecureRoute>
                    ),
                  },
                ],
              },
              {
                path: 'stops',
                element: (
                  <SecureRoute>
                    <Stops />
                  </SecureRoute>
                ),
              },
              {
                path: 'idles',
                element: (
                  <SecureRoute>
                    <Idles />
                  </SecureRoute>
                ),
              },
              {
                path: 'speedinfractions',
                element: (
                  <SecureRoute>
                    <SpeedInfractions />
                  </SecureRoute>
                ),
              },
              {
                path: 'accelerometerevents',
                element: (
                  <SecureRoute>
                    <AccelerometerEvents />
                  </SecureRoute>
                ),
              },
              {
                path: 'locationvisits',
                element: (
                  <SecureRoute>
                    <VehicleLocationVisits />
                  </SecureRoute>
                ),
              },
              {
                path: 'equipmentActivations',
                element: (
                  <SecureRoute>
                    <VehicleEquipmentActivations />
                  </SecureRoute>
                ),
              },
              {
                path: 'malfunctionindicatorlight',
                element: (
                  <SecureRoute>
                    <MalfunctionIndicatorLight />
                  </SecureRoute>
                ),
              },
              {
                path: 'onboarddiagnostics',
                element: (
                  <SecureRoute>
                    <OnBoardDiagnostics />
                  </SecureRoute>
                ),
              },
            ],
          },
          {
            path: 'people',
            children: [
              {
                path: 'trails',
                element: (
                  <SecureRoute>
                    <Trails />
                  </SecureRoute>
                ),
              },
              {
                path: 'locationvisits',
                element: (
                  <SecureRoute>
                    <PersonLocationVisits />
                  </SecureRoute>
                ),
              },
              {
                path: 'doublecrews',
                element: (
                  <SecureRoute>
                    <DoubleCrews />
                  </SecureRoute>
                ),
              },
              {
                path: 'attendances',
                element: (
                  <SecureRoute>
                    <Attendances />
                  </SecureRoute>
                ),
              },
              {
                path: 'responses',
                element: (
                  <SecureRoute>
                    <IncidentResponses />
                  </SecureRoute>
                ),
              },
            ],
          },
          {
            path: 'telematicsboxes',
            children: [
              {
                path: 'outages',
                element: (
                  <SecureRoute>
                    <Outages />
                  </SecureRoute>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'admin',
        children: [
          {
            path: 'audit',
            element: (
              <SecureRoute>
                <Audit />
              </SecureRoute>
            ),
          },
          {
            path: 'retention',
            element: (
              <SecureRoute>
                <DataRetention />
              </SecureRoute>
            ),
          },
        ],
      },
      {
        path: 'briefs',
        children: [
          {
            index: true,
            element: (
              <SecureRoute>
                <Briefs />
              </SecureRoute>
            ),
          },
          {
            path: ':code',
            element: (
              <SecureRoute>
                <Brief />
              </SecureRoute>
            ),
          },
        ],
      },
    ],
  },
];

// NOTE: The path value should start with '/' to support to build authorised siteMap. Please do not remove '/' from start of the path value.
export const siteMap = [
  { label: 'Home', Icon: HomeIcon, path: '/' },
  {
    label: 'Highlights',
    Icon: StarIcon,
    path: '/highlights',
  },
  { label: 'Live', Icon: LanguageIcon, path: '/live' },
  { label: 'Briefs', Icon: WorkIcon, path: '/briefs' },
  {
    label: 'Retrospective',
    Icon: HistoryIcon,
    path: '/retrospective',
  },
  {
    label: 'Replay (beta)',
    Icon: ReplayIcon,
    path: '/replay',
  },
  { label: 'Tags', Icon: MoreIcon, path: '/tags' },
  {
    label: 'Objectives',
    Icon: DataUsageIcon,
    path: '/objectives',
  },
  { label: 'Groups & Areas', Icon: GroupsIcon, path: '/groups' },
  {
    label: 'Resources',
    Icon: GroupIcon,
    group: '/resources',
    children: [
      {
        label: 'Vehicles',
        Icon: DirectionsCarIcon,
        path: '/resources/vehicles',
      },
      {
        label: 'People',
        Icon: PersonIcon,
        path: '/resources/people',
      },
      {
        label: 'Telematics Boxes',
        Icon: RouterIcon,
        path: '/resources/telematicsboxes',
      },
      {
        label: 'Radios',
        Icon: RadioHandheldIcon,
        path: '/resources/radios',
      },
      {
        label: useDallasKeys ? 'Dallas Keys' : 'RFID Cards',
        Icon: useDallasKeys ? VpnKeyIcon : NfcIcon,
        path: `/resources/${useDallasKeys ? 'dallaskeys' : 'rfidcards'}`,
      },
    ],
  },
  {
    label: 'Locations',
    Icon: LocationCityIcon,
    group: '/locations',
    children: Object.entries(locationTypes).map(([type, { header, d }]) => ({
      label: header,
      Icon: () => (
        <SvgIcon>
          <path d={d} />
        </SvgIcon>
      ),
      path: `/locations/${type}`,
    })),
  },
  {
    label: 'Reports',
    Icon: BarChartIcon,
    group: '/reports',
    children: [
      {
        label: 'Vehicles',
        Icon: DirectionsCarIcon,
        group: '/reports/vehicles',
        children: [
          {
            label: 'Daily Utilisation',
            Icon: EventIcon,
            path: '/reports/vehicles/dailyutilisation',
          },
          {
            label: 'Hourly Utilisation',
            Icon: ScheduleIcon,
            path: '/reports/vehicles/hourlyutilisation',
          },
          {
            label: 'Hourly in Location',
            Icon: GarageIcon,
            path: '/reports/vehicles/hourlyinlocations',
          },
          {
            label: 'Timeline in Location',
            Icon: TimelineIcon,
            path: '/reports/vehicles/timelineinlocations',
          },
          {
            label: 'Mileage',
            Icon: CounterIcon,
            path: '/reports/vehicles/mileage',
          },
          {
            label: 'Availability',
            Icon: EmojiTransportationIcon,
            path: '/reports/vehicles/availability',
          },
        ],
      },
      {
        label: 'People',
        Icon: PersonIcon,
        group: '/reports/people',
        children: [
          {
            label: 'Driver Behaviour',
            Icon: SteeringIcon,
            path: '/reports/people/driverbehaviour',
          },
          {
            label: 'Daily Activity',
            Icon: EventIcon,
            path: '/reports/people/dailyactivity',
          },
          {
            label: 'Hourly Activity',
            Icon: ScheduleIcon,
            path: '/reports/people/hourlyactivity',
          },
          {
            label: 'Availability',
            Icon: PeopleAvailabilityIcon,
            path: '/reports/people/availability',
          },
        ],
      },
      {
        label: 'Telematics Boxes',
        Icon: RouterIcon,
        group: '/reports/telematicsboxes',
        children: [
          {
            key: 'lastContact',
            label: 'Last Contact',
            Icon: TimerIcon,
            path: '/reports/telematicsboxes/lastcontact',
          },
        ],
      },
    ],
  },
  {
    label: 'Events',
    Icon: NewReleasesIcon,
    group: '/events',
    children: [
      {
        label: 'Vehicles',
        Icon: DirectionsCarIcon,
        group: '/events/vehicles',
        children: [
          {
            label: 'Trips',
            Icon: CarTractionControlIcon,
            path: '/events/vehicles/trips',
          },
          {
            label: 'Stops',
            Icon: CarBrakeParkingIcon,
            path: '/events/vehicles/stops',
          },
          {
            label: 'Idles',
            Icon: SnoozeIcon,
            path: '/events/vehicles/idles',
          },
          {
            label: 'Speed Infractions',
            Icon: SpeedometerIcon,
            path: '/events/vehicles/speedinfractions',
          },
          {
            label: 'Accelerometer Events',
            Icon: AxisArrowIcon,
            path: '/events/vehicles/accelerometerevents',
          },
          {
            label: 'Location Visits',
            Icon: CarSideIcon,
            path: '/events/vehicles/locationvisits',
          },
          {
            label: 'Equipment Activations',
            Icon: FluorescentIcon,
            path: '/events/vehicles/equipmentActivations',
          },
          {
            label: 'Malfunction Indicator',
            Icon: AlertCircleIcon,
            path: '/events/vehicles/malfunctionindicatorlight',
          },
          {
            label: 'On-Board Diagnostics',
            Icon: EngineOutlineIcon,
            path: '/events/vehicles/onboarddiagnostics',
          },
        ],
      },
      {
        label: 'People',
        Icon: PersonIcon,
        group: '/events/people',
        children: [
          {
            label: 'Trails',
            Icon: FiberManualRecordIcon,
            path: '/events/people/trails',
          },
          {
            label: 'Location Visits',
            Icon: DirectionsWalkIcon,
            path: '/events/people/locationvisits',
          },
          {
            label: 'Double Crews',
            Icon: PeopleIcon,
            path: '/events/people/doublecrews',
          },
          {
            label: 'Objective Attendances',
            Icon: AttendanceIcon,
            path: '/events/people/attendances',
          },
          {
            label: 'Incident Responses',
            Icon: ReplyIcon,
            path: '/events/people/responses',
          },
        ],
      },
      {
        label: 'Telematics Boxes',
        Icon: RouterIcon,
        group: '/events/telematicsboxes',
        children: [
          {
            label: 'Outages',
            Icon: ExploreOffIcon,
            path: '/events/telematicsboxes/outages',
          },
        ],
      },
    ],
  },
  {
    label: 'Admin',
    Icon: AdminPanelSettingsIcon,
    group: '/admin',
    children: [
      {
        label: 'Audit',
        Icon: ChromeReaderModeIcon,
        path: '/admin/audit',
      },
      {
        label: 'Data Retention',
        Icon: InventoryIcon,
        path: '/admin/retention',
      },
    ],
  },
];

export function isAuthorised(path, auth) {
  if (!auth || !auth.isAuthenticated()) {
    return false;
  }

  const { routes, hiddenRoutes: serverSideHiddenRoutes } =
    auth.getAuthorisation();
  const allHiddenRoutes = [
    ...(hiddenRoutes || []),
    ...(serverSideHiddenRoutes || []),
  ];

  return (
    ((routes || []).some((route) => route.startsWith(path)) ||
      (routes || []).some((route) => path.startsWith(route))) &&
    (allHiddenRoutes || []).every((route) => !path.startsWith(route))
  );
}

export const titleRoutes = [
  { path: '/', element: <TextHeader title="Home" /> },
  { path: 'highlights/*', element: <TextHeader title="Highlights" /> },
  { path: 'replay', element: <TextHeader title="Replay" /> },
  { path: 'tags/*', element: <TextHeader title="Tags" /> },
  { path: 'objectives/*', element: <TextHeader title="Objectives" /> },
  { path: 'groups/*', element: <TextHeader title="Groups & Areas" /> },
  {
    path: 'resources',
    children: [
      { path: 'vehicles/*', element: <TextHeader title="Vehicles" /> },
      { path: 'people/*', element: <TextHeader title="People" /> },
      {
        path: 'telematicsboxes/*',
        element: <TextHeader title="Telematics Boxes" />,
      },
      { path: 'radios/*', element: <TextHeader title="Radios" /> },
      {
        path: `${useDallasKeys ? 'dallaskeys' : 'rfidcards'}/*`,
        element: (
          <TextHeader title={useDallasKeys ? 'Dallas Keys' : 'RFID Cards'} />
        ),
      },
    ],
  },
  {
    path: 'locations',
    children: Object.entries(locationTypes).map(([type, { header }]) => ({
      path: `${type}/*`,
      element: <TextHeader title={header} />,
    })),
  },
  {
    path: 'reports',
    children: [
      {
        path: 'vehicles',
        children: [
          {
            path: 'dailyutilisation',
            element: <TextHeader title="Daily Utilisation" />,
          },
          {
            path: 'hourlyutilisation',
            element: <TextHeader title="Hourly Utilisation" />,
          },
          {
            path: 'hourlyinlocations',
            element: <TextHeader title="Hourly in Location" />,
          },
          {
            path: 'timelineinlocations',
            element: <TextHeader title="Timeline in Location" />,
          },
          { path: 'mileage', element: <TextHeader title="Mileage" /> },
          {
            path: 'availability',
            element: <TextHeader title="Vehicle Availability" />,
          },
        ],
      },
      {
        path: 'people',
        children: [
          {
            path: 'dailyactivity',
            element: <TextHeader title="Daily Activity" />,
          },
          {
            path: 'hourlyactivity',
            element: <TextHeader title="Hourly Activity" />,
          },
          {
            path: 'availability',
            element: <TextHeader title="People Availability" />,
          },
          {
            path: 'driverbehaviour',
            element: <TextHeader title="Driver Behaviour" />,
          },
        ],
      },
      {
        path: 'telematicsboxes',
        children: [
          { path: 'lastcontact', element: <TextHeader title="Last Contact" /> },
        ],
      },
    ],
  },
  {
    path: 'events',
    children: [
      {
        path: 'vehicles',
        children: [
          {
            path: 'trips',
            children: [
              { index: true, element: <TextHeader title="Trips" /> },
              { path: ':id', element: <TextHeader title="Trip" /> },
            ],
          },
          { path: 'stops', element: <TextHeader title="Stops" /> },
          { path: 'idles', element: <TextHeader title="Idles" /> },
          {
            path: 'speedinfractions',
            element: <TextHeader title="Speed Infractions" />,
          },
          {
            path: 'accelerometerevents',
            element: <TextHeader title="Accelerometer Events" />,
          },
          {
            path: 'locationvisits',
            element: <TextHeader title="Vehicle Location Visits" />,
          },
          {
            path: 'equipmentActivations',
            element: <TextHeader title="Vehicle Equipment Activations" />,
          },
          {
            path: 'malfunctionindicatorlight',
            element: <TextHeader title="Malfunction Indicator Light" />,
          },
          {
            path: 'onboarddiagnostics',
            element: <TextHeader title="On-Board Diagnostics" />,
          },
        ],
      },
      {
        path: 'people',
        children: [
          { path: 'trails', element: <TextHeader title="Trails" /> },
          {
            path: 'locationvisits',
            element: <TextHeader title="Person Location Visits" />,
          },
          {
            path: 'doublecrews',
            element: <TextHeader title="Double Crews" />,
          },
          {
            path: 'attendances',
            element: <TextHeader title="Objective Attendances" />,
          },
          {
            path: 'responses',
            element: <TextHeader title="Incident Responses" />,
          },
        ],
      },
      {
        path: 'telematicsboxes',
        children: [
          { path: 'outages', element: <TextHeader title="Outages" /> },
        ],
      },
    ],
  },
  {
    path: 'admin',
    children: [
      { path: 'audit', element: <TextHeader title="Audit" /> },
      { path: 'retention', element: <TextHeader title="Data Retention" /> },
    ],
  },
  {
    path: 'briefs',
    children: [
      { index: true, element: <TextHeader title="Briefs" /> },
      { path: ':code', element: <BriefHeader /> },
    ],
  },
];

export const toolbarRoutes = [
  {
    path: 'highlights/*',
    element: <HighlightsToolbar />,
  },
];

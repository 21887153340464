import { useController } from 'react-hook-form';
import { AliasPicker } from '..';

export function AliasPickerController({ name, control, disabled }) {
  const {
    field: { value, disabled: isDisabled, onChange },
  } = useController({
    name,
    control,
    disabled,
    defaultValue: {},
  });

  return (
    <AliasPicker value={value} onChange={onChange} disabled={isDisabled} />
  );
}

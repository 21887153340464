import { mapStyleAtom } from '@/atoms';
import { Attribution } from '@/components/controls';
import { transformRequest } from '@/utils';
import { maxBounds, maxZoom, minZoom } from '@/utils/config';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import bbox from '@turf/bbox';
import { useAtom } from 'jotai';
import 'maplibre-gl/dist/maplibre-gl.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Map as ReactMapGL, ScaleControl } from 'react-map-gl/maplibre';
import './Map.css';
import { MapControls } from './MapControls';
import { MapImage } from './MapImage';

let hoverTimeout;

export function Map({
  sx,
  viewState: initialViewState,
  interactiveLayerIds,
  onHover,
  listHoverInfo,
  selectedSource,
  children,
}) {
  const [viewState, setViewState] = useState({
    minZoom,
    maxZoom,
    maxBounds,
    ...initialViewState,
  });
  const [style, setStyle] = useAtom(mapStyleAtom);
  const [isLoaded, setIsLoaded] = useState(false);
  const [cursor, setCursor] = useState('auto');
  const mapRef = useRef();

  useEffect(() => {
    if (listHoverInfo) {
      clearTimeout(hoverTimeout);
      hoverTimeout = setTimeout(() => {
        const [minLng, minLat, maxLng, maxLat] = bbox(listHoverInfo.feature);

        mapRef.current.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          { padding: 40, duration: 1000, maxZoom: 16 },
        );
      }, 2000);
    } else {
      clearTimeout(hoverTimeout);
    }
  }, [listHoverInfo]);

  function handleLoad() {
    setIsLoaded(true);
  }

  function handleMove(event) {
    setViewState(event.viewState);
  }

  function handleStyleChange(path) {
    setStyle(path);
  }

  const handleMouseEnter = useCallback(() => setCursor('pointer'), []);
  const handleMouseLeave = useCallback(() => setCursor('auto'), []);

  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <ReactMapGL
        {...viewState}
        ref={mapRef}
        mapStyle={style}
        onMove={handleMove}
        transformRequest={transformRequest(style)}
        interactiveLayerIds={interactiveLayerIds}
        onMouseMove={onHover}
        onHover={onHover}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        cursor={cursor}
        onLoad={handleLoad}
        dragRotate={false}
      >
        <MapImage sdf name="teardrop" url="/sdf/teardrop.png" />
        <MapImage sdf name="circle" url="/sdf/circle.png" />
        <MapImage sdf name="square" url="/sdf/square.png" />
        <MapImage sdf name="hexagon" url="/sdf/hexagon.png" />
        <MapImage sdf name="house" url="/sdf/house.png" />
        <MapImage sdf name="triangle" url="/sdf/triangle.png" />
        <MapImage sdf name="car" url="/sdf/car.png" />
        <MapImage sdf name="person" url="/sdf/person.png" />
        <MapImage sdf name="police-station" url="/sdf/police-station.png" />
        <MapImage sdf name="base" url="/sdf/base.png" />
        <MapImage sdf name="workshop" url="/sdf/workshop.png" />
        <MapImage sdf name="hospital" url="/sdf/hospital.png" />
        <MapImage sdf name="school" url="/sdf/school.png" />
        <MapImage sdf name="court" url="/sdf/court.png" />
        <MapImage sdf name="event" url="/sdf/event.png" />
        <MapImage sdf name="box" url="/sdf/box.png" />
        <MapImage sdf name="radio" url="/sdf/radio.png" />
        <MapImage sdf name="accelerometer" url="/sdf/accelerometer.png" />
        <MapImage sdf name="objective" url="/sdf/objective.png" />
        <ScaleControl />
        <MapControls
          style={style}
          onStyleChange={handleStyleChange}
          selectedSource={selectedSource}
        />
        <Attribution style={style} />
        {children}
      </ReactMapGL>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: 'absolute',
        }}
        open={!isLoaded}
      >
        <CircularProgress />
      </Backdrop>
    </Box>
  );
}

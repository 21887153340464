import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';
import { startOfDay, subDays } from 'date-fns';

export function useResponseTimesByHour(groupType, groupId, subgroupType, days) {
  return useQuery({
    queryKey: ['responseTimesByHour', groupType, groupId, subgroupType, days],
    queryFn: async () => {
      const start = subDays(startOfDay(new Date()), days);
      const json = [
        {
          $match: {
            openedTime: { $gt: start },
            ...(import.meta.env.DEV
              ? {}
              : { [`ward.groups.${groupType}`]: groupId }),
            attendedTime: { $exists: true },
          },
        },
        {
          $facet: {
            all: [
              {
                $group: {
                  _id: {
                    grade: '$grade',
                    hour: { $hour: { date: '$openedTime', timezone: 'GB' } },
                  },
                  averageResponseTimeInHours: {
                    $avg: {
                      $divide: [
                        {
                          $dateDiff: {
                            startDate: '$openedTime',
                            endDate: '$attendedTime',
                            unit: 'second',
                          },
                        },
                        3600,
                      ],
                    },
                  },
                },
              },
              {
                $project: {
                  _id: false,
                  identifier: 'All',
                  grade: '$_id.grade',
                  hour: '$_id.hour',
                  averageResponseTimeInHours: true,
                },
              },
              { $sort: { grade: 1, hour: 1 } },
            ],
            groups: [
              {
                $unwind: {
                  path: `$ward.groups.${subgroupType}`,
                  preserveNullAndEmptyArrays: true,
                },
              },
              {
                $group: {
                  _id: {
                    grade: '$grade',
                    identifier: {
                      $ifNull: [`$ward.groups.${subgroupType}`, 'Unknown'],
                    },
                    hour: { $hour: { date: '$openedTime', timezone: 'GB' } },
                  },
                  averageResponseTimeInHours: {
                    $avg: {
                      $dateDiff: {
                        startDate: '$openedTime',
                        endDate: '$attendedTime',
                        unit: 'hour',
                      },
                    },
                  },
                },
              },
              {
                $project: {
                  _id: false,
                  grade: '$_id.grade',
                  identifier: '$_id.identifier',
                  hour: '$_id.hour',
                  averageResponseTimeInHours: true,
                },
              },
              { $sort: { identifier: 1, grade: 1, hour: 1 } },
            ],
          },
        },
      ];

      const data = await api.post('pipeline/incidents', { json }).json();

      return {
        groups: [
          'All',
          ...new Set(data[0].groups.map((group) => group.identifier)),
        ],
        grades: [...new Set(data[0].all.map((group) => group.grade))],
        grouped: data[0].all
          .concat(data[0].groups)
          .reduce(
            (
              accumlator,
              { grade, identifier, hour, averageResponseTimeInHours },
            ) => {
              if (!accumlator[grade]) {
                accumlator[grade] = {};
              }
              if (!accumlator[grade][hour]) {
                accumlator[grade][hour] = {};
              }

              accumlator[grade][hour][identifier] = averageResponseTimeInHours;

              return accumlator;
            },
            {},
          ),
      };
    },
    placeholderData: { grouped: {}, groups: [], grades: [] },
  });
}

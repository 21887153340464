/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  ArrowDropDown as ArrowDropDownIcon,
  Code as CodeIcon,
  // FormatAlignLeft as FormatAlignLeftIcon,
  // FormatAlignCenter as FormatAlignCenterIcon,
  // FormatAlignRight as FormatAlignRightIcon,
  // FormatAlignJustify as FormatAlignJustifyIcon,
  FormatBold as FormatBoldIcon,
  FormatColorText as FormatColorTextIcon,
  FormatItalic as FormatItalicIcon,
  FormatListBulleted as FormatListBulletedIcon,
  FormatListNumbered as FormatListNumberedIcon,
  FormatQuote as FormatQuoteIcon,
  FormatUnderlined as FormatUnderlinedIcon,
  Image as ImageIcon,
  Link as LinkIcon,
  Redo as RedoIcon,
  Undo as UndoIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Menu,
  MenuItem,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import * as colors from '@mui/material/colors';
import { dequal } from 'dequal';
import {
  AtomicBlockUtils,
  CompositeDecorator,
  ContentState,
  Editor,
  EditorState,
  RichUtils,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
  getDefaultKeyBinding,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import {
  CodeNotEqualVariant as CodeNotEqualVariantIcon,
  Marker as MarkerIcon,
} from 'mdi-material-ui';
import { Fragment, useEffect, useRef, useState } from 'react';
import { ColorPicker } from './ColorPicker';

const headerTypes = [
  { label: 'Normal', value: 'unstyled' },
  { label: 'Paragraph', value: 'paragraph' },
  { label: 'Heading 1', value: 'header-one' },
  { label: 'Heading 2', value: 'header-two' },
  { label: 'Heading 3', value: 'header-three' },
  { label: 'Heading 4', value: 'header-four' },
  { label: 'Heading 5', value: 'header-five' },
  { label: 'Heading 6', value: 'header-six' },
];

const blockTypes = [
  {
    title: 'Bulleted List',
    style: 'unordered-list-item',
    Icon: FormatListBulletedIcon,
  },
  {
    title: 'Numbered List',
    style: 'ordered-list-item',
    Icon: FormatListNumberedIcon,
  },
  { title: 'Quote Block', style: 'blockquote', Icon: FormatQuoteIcon },
  { title: 'Code Block', style: 'code-block', Icon: CodeNotEqualVariantIcon },
];

const inlineStyles = [
  { title: 'Bold', style: 'BOLD', Icon: FormatBoldIcon },
  { title: 'Italic', style: 'ITALIC', Icon: FormatItalicIcon },
  { title: 'Underline', style: 'UNDERLINE', Icon: FormatUnderlinedIcon },
  { title: 'Monospace', style: 'CODE', Icon: CodeIcon },
];

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
}

function Link({ contentState, entityKey, children }) {
  const { url } = contentState.getEntity(entityKey).getData();

  return <a href={url}>{children}</a>;
}

function findImageEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'IMAGE'
    );
  }, callback);
}

function Image({ contentState, entityKey }) {
  const [open, setOpen] = useState(false);
  const { src } = contentState.getEntity(entityKey).getData();
  const style = {
    maxWidth: '100%',
    height: 'auto',
    cursor: 'pointer',
  };

  return (
    <Fragment>
      <img src={src} style={style} alt="" onClick={() => setOpen(true)} />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <img src={src} style={style} alt="" onClick={() => setOpen(false)} />
      </Dialog>
    </Fragment>
  );
}

const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
  {
    strategy: findImageEntities,
    component: Image,
  },
]);

const colorStyleMap = Object.assign(
  {},
  ...[].concat(
    ...Object.entries(colors)
      .slice(1)
      .map((hue) =>
        Object.entries(hue[1]).map((shade) => ({
          [`color-${shade[1]}`]: { color: shade[1] },
          [`backgroundColor-${shade[1]}`]: { backgroundColor: shade[1] },
        })),
      ),
  ),
);

const styleMap = {
  ...colorStyleMap,
};

export function ContentEditor({
  placeholder,
  value,
  onChange,
  className,
  sx,
  disabled,
  invalidState,
}) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  const [headerAnchorEl, setHeaderAnchorEl] = useState(null);
  const [colorAnchorEl, setColorAnchorEl] = useState(null);
  const [colorType, setColorType] = useState('color');
  const [url, setUrl] = useState('');
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);
  const editor = useRef(null);
  const currentStyle = editorState.getCurrentInlineStyle();
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  const focus = () => editor.current.focus();
  const reader = new FileReader();

  reader.onabort = () => console.log('file reading was aborted');
  reader.onerror = () => console.log('file reading has failed');
  reader.onload = () => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'IMAGE',
      'IMMUTABLE',
      { src: reader.result },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });

    setEditorState(
      AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '),
    );

    setTimeout(() => focus(), 0);
  };

  // console.log(currentStyle.toJSON());
  const colorKey = currentStyle.find((styleKey) =>
    styleKey.startsWith('color-'),
  );
  const backgroundColorKey = currentStyle.find((styleKey) =>
    styleKey.startsWith('backgroundColor-'),
  );
  const currentColors = {
    color: colorKey ? colorStyleMap[colorKey].color : null,
    backgroundColor: backgroundColorKey
      ? colorStyleMap[backgroundColorKey].backgroundColor
      : null,
  };

  useEffect(() => {
    if (typeof value === 'string') {
      const blocksFromHTML = convertFromHTML(value);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );

      setEditorState(EditorState.createWithContent(contentState, decorator));
    } else {
      // this does not work but might be worth revisiting
      // const newState = EditorState.createWithContent(
      //   convertFromRaw(value),
      //   decorator
      // );
      // if (newState !== editorState) {
      //   setEditorState(newState);
      // }

      if (!dequal(value, convertToRaw(editorState.getCurrentContent()))) {
        setEditorState(
          EditorState.createWithContent(convertFromRaw(value), decorator),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  function handleChange(state) {
    setEditorState(state);

    if (!disabled) {
      onChange(convertToRaw(state.getCurrentContent()));
    }
  }

  function preventDefault(event) {
    event.preventDefault();
  }

  // function handlePastedText(text, html, editorState) {
  //   console.log(text, html);
  // }

  function handlePastedFiles(files) {
    reader.readAsDataURL(files[0]);
  }

  function handleDroppedFiles(selection, files) {
    reader.readAsDataURL(files[0]);
  }

  function handleKeyCommand(command, state) {
    const newState = RichUtils.handleKeyCommand(state, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  function mapKeyToEditorCommand(event) {
    if (event.keyCode === 9 /* TAB */) {
      const newState = RichUtils.onTab(event, editorState, 4 /* maxDepth */);
      if (newState !== editorState) {
        setEditorState(newState);
      }
      return;
    }
    return getDefaultKeyBinding(event);
  }

  function handleBlockTypeClick(event) {
    setEditorState(
      RichUtils.toggleBlockType(editorState, event.currentTarget.value),
    );
  }

  function handleInlineStyleClick(event) {
    setEditorState(
      RichUtils.toggleInlineStyle(editorState, event.currentTarget.value),
    );

    // setTimeout(() => focus(), 0);
  }

  function handleUndoClick() {
    setEditorState(EditorState.undo(editorState));

    // setTimeout(() => focus(), 0);
  }

  function handleRedoClick() {
    setEditorState(EditorState.redo(editorState));

    // setTimeout(() => focus(), 0);
  }

  function handleHeaderClick(event) {
    setHeaderAnchorEl(event.currentTarget);
  }

  function handleHeaderClose() {
    setHeaderAnchorEl(null);
  }

  const handleHeaderMenuItemClick = (value) => () => {
    setEditorState(RichUtils.toggleBlockType(editorState, value));
    setHeaderAnchorEl(null);

    setTimeout(() => focus(), 0);
  };

  const handleColorClick = (colorType) => (event) => {
    setColorType(colorType);
    setColorAnchorEl(event.currentTarget);
  };

  //TODO: This still needs some work as it can get into a muddle with more than one colour being applied at a time thus making it hard to remove
  function handleColorChange(color) {
    // console.log(color);

    setEditorState(
      RichUtils.toggleInlineStyle(
        currentColors[colorType] !== null && currentColors[colorType] !== color
          ? RichUtils.toggleInlineStyle(
              editorState,
              `${colorType}-${currentColors[colorType]}`,
            )
          : editorState,
        `${colorType}-${color}`,
      ),
    );
    setColorAnchorEl(null);
  }

  function handleColorClose() {
    setColorAnchorEl(null);

    setTimeout(() => focus(), 0);
  }

  function handleLinkClick() {
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

      let linkUrl = '';
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        linkUrl = linkInstance.getData().url;
      }

      setUrl(linkUrl);
      setLinkDialogOpen(true);
    }
  }

  const handleLinkDialogClose = (ok) => () => {
    if (ok) {
      let prefixedUrl = url;
      if (!/^https?:\/\//i.test(url)) {
        prefixedUrl = 'http://' + url;
      }
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        'LINK',
        'MUTABLE',
        { url: prefixedUrl },
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity,
      });
      setEditorState(
        RichUtils.toggleLink(
          newEditorState,
          newEditorState.getSelection(),
          entityKey,
        ),
      );
    }

    setUrl('');
    setLinkDialogOpen(false);

    setTimeout(() => focus(), 0);
  };

  function handleUrlChange(event) {
    setUrl(event.target.value);
  }

  function handleFileChanged(event) {
    reader.readAsDataURL(event.target.files[0]);
  }

  return (
    <Box className={className} sx={sx}>
      <Box
        sx={(theme) => ({
          width: 1,
          height: 1,
          borderStyle: 'solid',
          borderWidth: selection.getHasFocus() ? 2 : 1,
          borderColor: selection.getHasFocus()
            ? theme.palette.primary.main
            : invalidState
            ? theme.palette.error.main
            : theme.palette.divider,
          borderRadius: 1,
        })}
      >
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <Tooltip title="Undo">
            <Box component="span">
              <ToggleButton
                sx={{
                  '&.Mui-disabled': {
                    border: 0,
                  },
                  border: 'none',
                  m: 0.5,
                  textTransform: 'none',
                }}
                value="undo"
                size="small"
                disabled={editorState.getUndoStack().size === 0}
                onMouseDown={preventDefault}
                onClick={handleUndoClick}
              >
                <UndoIcon />
              </ToggleButton>
            </Box>
          </Tooltip>
          <Tooltip title="Redo">
            <Box component="span">
              <ToggleButton
                sx={{
                  '&.Mui-disabled': {
                    border: 0,
                  },
                  border: 'none',
                  m: 0.5,
                  textTransform: 'none',
                }}
                value="redo"
                size="small"
                disabled={editorState.getRedoStack().size === 0}
                onMouseDown={preventDefault}
                onClick={handleRedoClick}
              >
                <RedoIcon />
              </ToggleButton>
            </Box>
          </Tooltip>
          <Divider flexItem orientation="vertical" sx={{ m: [1, 0.5] }} />
          <Tooltip title="Style">
            <ToggleButton
              value="header"
              sx={{ border: 'none', m: 0.5, textTransform: 'none' }}
              size="small"
              onClick={handleHeaderClick}
            >
              <Typography variant="subtitle2" sx={{ width: 66 }}>
                {
                  (
                    headerTypes.find((type) => type.value === blockType) ||
                    headerTypes[0]
                  ).label
                }
              </Typography>
              <ArrowDropDownIcon />
            </ToggleButton>
          </Tooltip>
          <Menu
            anchorEl={headerAnchorEl}
            keepMounted
            open={Boolean(headerAnchorEl)}
            onClose={handleHeaderClose}
          >
            {headerTypes.map(({ label, value }) => (
              <MenuItem key={value} onClick={handleHeaderMenuItemClick(value)}>
                {label}
              </MenuItem>
            ))}
          </Menu>
          <Divider flexItem orientation="vertical" sx={{ m: [1, 0.5] }} />
          <Tooltip title="Colour">
            <ToggleButton
              value="color"
              sx={{ border: 'none', m: 0.5, textTransform: 'none' }}
              size="small"
              onMouseDown={preventDefault}
              onClick={handleColorClick('color')}
            >
              <FormatColorTextIcon />
              <ArrowDropDownIcon />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Highlight">
            <ToggleButton
              value="highlight"
              sx={{ border: 'none', m: 0.5, textTransform: 'none' }}
              size="small"
              onMouseDown={preventDefault}
              onClick={handleColorClick('backgroundColor')}
            >
              <MarkerIcon />
              <ArrowDropDownIcon />
            </ToggleButton>
          </Tooltip>
          <ColorPicker
            value={currentColors[colorType]}
            anchorEl={colorAnchorEl}
            onChange={handleColorChange}
            onClose={handleColorClose}
          />
          {/* <Divider flexItem orientation="vertical" sx={{ m: [1, 0.5] }} />
          <StyledToggleButtonGroup
            size="small"
            // value={alignment}
            exclusive
            // onChange={handleAlignment}
          >
            <Tooltip title="Left Aligned">
              <ToggleButton value="left">
                <FormatAlignLeftIcon />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Centered">
              <ToggleButton value="center">
                <FormatAlignCenterIcon />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Right Aligned">
              <ToggleButton value="right">
                <FormatAlignRightIcon />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Justified">
              <ToggleButton value="justify">
                <FormatAlignJustifyIcon />
              </ToggleButton>
            </Tooltip>
          </StyledToggleButtonGroup> */}
          <Divider flexItem orientation="vertical" sx={{ m: [1, 0.5] }} />
          <StyledToggleButtonGroup size="small">
            {inlineStyles.map(({ style, title, Icon }) => (
              <Tooltip title={title} key={style}>
                <ToggleButton
                  value={style}
                  selected={currentStyle.has(style)}
                  onMouseDown={preventDefault}
                  onClick={handleInlineStyleClick}
                >
                  <Icon />
                </ToggleButton>
              </Tooltip>
            ))}
          </StyledToggleButtonGroup>
          <Divider flexItem orientation="vertical" sx={{ m: [1, 0.5] }} />
          <StyledToggleButtonGroup size="small">
            {blockTypes.map(({ style, title, Icon }) => (
              <Tooltip title={title} key={style}>
                <ToggleButton
                  value={style}
                  selected={blockType === style}
                  onMouseDown={preventDefault}
                  onClick={handleBlockTypeClick}
                >
                  <Icon />
                </ToggleButton>
              </Tooltip>
            ))}
          </StyledToggleButtonGroup>
          <Divider flexItem orientation="vertical" sx={{ m: [1, 0.5] }} />
          <Tooltip title="Link">
            <Box component="span">
              <ToggleButton
                sx={{
                  '&.Mui-disabled': {
                    border: 0,
                  },
                  border: 'none',
                  m: 0.5,
                  textTransform: 'none',
                }}
                value="link"
                size="small"
                onClick={handleLinkClick}
                disabled={selection.isCollapsed()}
              >
                <LinkIcon />
              </ToggleButton>
            </Box>
          </Tooltip>
          <Dialog open={linkDialogOpen} onClose={handleLinkDialogClose(false)}>
            <DialogTitle>Insert Link</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please enter the URL in the field below
              </DialogContentText>
              <TextField
                autoFocus
                value={url}
                margin="dense"
                id="url"
                label="URL"
                type="url"
                onChange={handleUrlChange}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleLinkDialogClose(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleLinkDialogClose(true)} color="primary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="contained-button-file"
            type="file"
            onChange={handleFileChanged}
          />
          <label htmlFor="contained-button-file">
            <Tooltip title="Image">
              <ToggleButton
                value="image"
                sx={{ border: 'none', m: 0.5, textTransform: 'none' }}
                size="small"
                component="span"
                // onClick={handleImageClick}
              >
                <ImageIcon />
              </ToggleButton>
            </Tooltip>
          </label>
        </Box>
        <Divider />
        <Box sx={{ p: 2, width: 1, height: 1 }} onClick={focus}>
          <Editor
            ref={editor}
            placeholder={placeholder}
            spellCheck={true}
            editorState={editorState}
            onChange={handleChange}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            handlePastedFiles={handlePastedFiles}
            handleDroppedFiles={handleDroppedFiles}
            // handlePastedText={handlePastedText}
            customStyleMap={styleMap}
            readOnly={disabled}
          />
        </Box>
      </Box>
    </Box>
  );
}

export function ContentViewer({ content = '' }) {
  let contentState;
  if (typeof content === 'string') {
    const blocksFromHTML = convertFromHTML(content);
    contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
  } else {
    contentState = convertFromRaw(content);
  }

  return (
    <Editor
      readOnly={true}
      editorState={EditorState.createWithContent(contentState, decorator)}
      customStyleMap={styleMap}
    />
  );
}

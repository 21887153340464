import { highlightsStateAtom } from '@/atoms';
import { useDocumentTitle } from '@/hooks';
import { Stack } from '@mui/material';
import { useAtom } from 'jotai';
import { CurrentObjectivesCard } from './CurrentObjectivesCard';
import { DailyActivityCard } from './DailyActivityCard';
import { IncidentsByGradeCard } from './IncidentsByGradeCard';
import { ResponseTimeByHourCard } from './ResponseTimeByHourCard';
import { TotalIncidentsCard } from './TotalIncidentsCard';
import { UncheckedVehiclesCard } from './UncheckedVehiclesCard';

export function Sector() {
  useDocumentTitle('IR3 | Highlights | Sector');
  const [{ basicCommandUnit, sector }] = useAtom(highlightsStateAtom);

  return (
    <Stack spacing={1} sx={{ p: 1 }} direction="row" alignItems="baseline">
      <Stack spacing={1}>
        <CurrentObjectivesCard groupType="sector" groupId={sector} />
        <TotalIncidentsCard
          groupType="bcu"
          groupId={basicCommandUnit}
          subgroupType="sector"
          subgroupId={sector}
        />
        <UncheckedVehiclesCard groupType="sector" groupId={sector} />
      </Stack>
      <Stack spacing={1} sx={{ flexGrow: 1 }}>
        <DailyActivityCard
          groupType="sector"
          groupId={sector}
          subgroupType="sector"
        />
        <IncidentsByGradeCard groupType="sector" groupId={sector} />
        <ResponseTimeByHourCard
          groupType="bcu"
          groupId={basicCommandUnit}
          subgroupType="sector"
        />
      </Stack>
    </Stack>
  );
}

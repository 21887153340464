import { highlightsStateAtom } from '@/atoms';
import { useDocumentTitle } from '@/hooks';
import { Stack } from '@mui/material';
import { useAtom } from 'jotai';
import { CurrentObjectivesCard } from './CurrentObjectivesCard';
import { DailyActivityCard } from './DailyActivityCard';
import { IncidentsByGradeCard } from './IncidentsByGradeCard';
import { ObjectiveComplianceCard } from './ObjectiveComplianceCard';
import { ResponseTimeByHourCard } from './ResponseTimeByHourCard';
import { TotalIncidentsCard } from './TotalIncidentsCard';
import { UncheckedVehiclesCard } from './UncheckedVehiclesCard';

export function BasicCommandUnit() {
  useDocumentTitle('IR3 | Highlights | BCU');
  const [{ basicCommandUnit }] = useAtom(highlightsStateAtom);

  return (
    <Stack spacing={1} sx={{ p: 1 }} direction="row" alignItems="baseline">
      <Stack spacing={1}>
        <CurrentObjectivesCard groupType="bcu" groupId={basicCommandUnit} />
        <TotalIncidentsCard
          groupType="bcu"
          groupId={basicCommandUnit}
          subgroupType="sector"
        />
        <ObjectiveComplianceCard groupType="bcu" groupId={basicCommandUnit} />
        <UncheckedVehiclesCard groupType="bcu" groupId={basicCommandUnit} />
      </Stack>
      <Stack spacing={1} sx={{ flexGrow: 1 }}>
        <DailyActivityCard
          groupType="bcu"
          groupId={basicCommandUnit}
          subgroupType="sector"
        />
        <IncidentsByGradeCard groupType="bcu" groupId={basicCommandUnit} />
        <ResponseTimeByHourCard
          groupType="bcu"
          groupId={basicCommandUnit}
          subgroupType="sector"
        />
      </Stack>
    </Stack>
  );
}

import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useObjective(id) {
  return useQuery({
    queryKey: ['objectives', id],
    queryFn: async () => {
      if (id === 'new') {
        return {
          title: '',
        };
      }

      const json = [
        { $match: { identifier: id } },
        {
          $project: {
            identifier: true,
            title: true,
            description: true,
            startTime: true,
            endTime: true,
            complianceSeconds: true,
            requiredVisits: true,
            requiredFrequency: true,
            boundaryType: true,
            boundarySubtype: true,
            boundaryIdentifier: true,
            boundary: true,
            created: true,
            lastEdit: true,
            occurrenceNumber: true,
            schedule: true,
            applicableTo: true,
            visibleTo: true,
            notifications: true,
            microbeats: true,
            resourceType: true,
          },
        },
      ];

      const objectives = await api.post('pipeline/objectives', { json }).json();

      log('Read', 'Objective', { id });

      return objectives[0] ?? null;
    },
    staleTime: 30 * 60 * 1000,
  });
}

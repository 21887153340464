import { format } from 'date-fns';
import { LiveListItem } from './LiveListItem';

export function EventLiveListItem(props) {
  return (
    <LiveListItem
      type="events"
      primaryPath={'vehicle.registrationNumber'}
      secondaryComponent={
        <div>{format(new Date(props.item.time), 'dd/MM/yyyy hh:mm')}</div>
      }
      {...props}
    />
  );
}

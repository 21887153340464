import { featureSortFn } from '@/utils';
import { startOfHour } from 'date-fns';
import { useMemo } from 'react';
import { useLocationSnapshot } from './useLocationSnapshot';
import { useResourceSnapshot } from './useResourceSnapshot';

export function useFilteredSnapshot(time, search, sort) {
  const { data: locationSnapshot, isFetching: fetchingLocations } =
    useLocationSnapshot(startOfHour(time));
  const { data: resourceSnapshot, isFetching: fetchingResources } =
    useResourceSnapshot(time);

  const data = useMemo(
    () =>
      Object.entries({
        ...locationSnapshot,
        ...resourceSnapshot,
      }).reduce(
        (accumulator, [key, featureCollection]) => ({
          ...accumulator,
          [key]: {
            ...featureCollection,
            features: featureCollection.features
              .filter(({ properties }) =>
                JSON.stringify(properties)
                  .toLowerCase()
                  .includes(search[key].toLowerCase()),
              )
              .sort(featureSortFn(sort[key])),
            total: featureCollection.features.length,
          },
        }),
        {},
      ),
    [resourceSnapshot, locationSnapshot, search, sort],
  );

  return {
    data,
    isFetching: fetchingLocations || fetchingResources,
  };
}

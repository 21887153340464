import { DebouncedTextField, Field } from '@/components/fields';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Toolbar,
  Typography,
} from '@mui/material';

export function SaveDialog({ onClose, onSave, ...other }) {
  return (
    <Dialog onClose={onClose} {...other}>
      <AppBar position="static">
        <Toolbar variant="dense" sx={{ pl: 2 }}>
          <Typography variant="h6" color="inherit">
            Save As
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ pt: 2 }}>
        <DialogContentText>
          Please give this retrospective a name
        </DialogContentText>
        <Field
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          margin="dense"
          fullWidth
          label="Title"
          name="title"
          component={DebouncedTextField}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { useLazyLoadIncident, incidentTitle } from './incidentUtility';
import { LiveListItem } from './LiveListItem';

export function IncidentLiveListItem(props) {
  const { item } = props;
  const incident = useLazyLoadIncident(item.id);

  incident.primary = incidentTitle(incident);
  incident.secondary = `${incident?.type?.code ?? ''} ${
    incident?.type?.name ?? ''
  }`;

  return (
    <LiveListItem
      type="incidents"
      primaryPath="primary"
      secondaryPath="secondary"
      {...props}
      item={incident}
      hideFollow={!incident.point}
    />
  );
}

import { MenuItem, TextField } from '@mui/material';

export function SelectField({
  input: { onChange, ...input },
  onChange: callback,
  meta,
  values,
  styles = {},
  ...props
}) {
  if (
    input.value &&
    !(values ?? []).map((item) => item.value ?? item).includes(input.value)
  ) {
    values = [{ label: input.value, value: input.value }, ...(values ?? [])];
  }

  function handleChange(event) {
    onChange(event);
    if (callback) {
      callback(event);
    }
  }

  return (
    <TextField
      size="small"
      select
      helperText={meta.error}
      error={meta.invalid}
      {...input}
      {...props}
      onChange={handleChange}
    >
      {(values || []).map((item) => {
        return (
          <MenuItem
            key={item.value ?? item}
            value={item.value ?? item}
            style={styles[item?.className]}
          >
            {item.label ?? item}
          </MenuItem>
        );
      })}
    </TextField>
  );
}

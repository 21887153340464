import {
  useGroups,
  useOccurrence,
  useSetUrgentActionComplete,
  useUser,
} from '@/hooks';
import { listGroupLabels } from '@/utils';
import {
  Create as CreateIcon,
  Done as DoneIcon,
  LocationOn as LocationOnIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  List,
  ListSubheader,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { ActionPerson } from './ActionPerson';
import { OfficerInCharge } from './OfficerInCharge';
import { UrgentActionUpdate } from './UrgentActionUpdate';

export function UrgentAction({
  urgentAction,
  onSettingsClick,
  onAddUpdateClick,
  onShowLocationClick,
}) {
  const { data: user } = useUser(urgentAction.edited.userCode);
  const { data: groups } = useGroups({ types: ['SECTOR', 'TEAM'] });
  const { data: occurrence } = useOccurrence(urgentAction.occurrenceCode);
  const { mutate: setUrgentActionComplete } = useSetUrgentActionComplete();

  function handleClick() {
    setUrgentActionComplete({
      code: urgentAction.code,
      isComplete: !urgentAction.isComplete,
    });
  }

  return (
    <Card variant="outlined" sx={{ minWidth: 400 }}>
      <Stack direction="row">
        <ActionPerson title="Suspect" code={occurrence?.suspectCode} />
        <ActionPerson
          title="Victim"
          code={occurrence?.victimCode}
          actionIcon={
            <Tooltip title="Show Location">
              <IconButton
                sx={{ color: 'common.white' }}
                onClick={onShowLocationClick(occurrence)}
              >
                <LocationOnIcon />
              </IconButton>
            </Tooltip>
          }
        />
      </Stack>
      <CardContent>
        <Stack>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Tooltip title="Occurrence Number" placement="left-start">
              <Typography
                variant="h6"
                color="text.secondary"
                gutterBottom
                sx={{ flexGrow: 1 }}
              >
                {occurrence?.code}
              </Typography>
            </Tooltip>
            <OfficerInCharge code={occurrence?.officerCode} />
          </Stack>
          <Typography variant="caption" color="text.secondary" gutterBottom>
            {user
              ? `${user.name} - ${formatDistanceToNow(
                  new Date(urgentAction.edited.time),
                )} ago`
              : '-'}
          </Typography>
          <Typography sx={{ whiteSpace: 'pre-wrap' }} gutterBottom>
            {urgentAction.message}
          </Typography>
        </Stack>
        <Typography gutterBottom sx={{ whiteSpace: 'pre-wrap' }}>
          {urgentAction.description}
        </Typography>
        <Divider sx={{ paddingTop: 1 }} />
        <List
          sx={{
            maxHeight: 200,
            overflow: 'auto',
          }}
          // subheader={<li />}
          disablePadding
        >
          <ListSubheader disableGutters>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ flexGrow: 1 }}>Updates</Box>
              <Box>
                <Tooltip title="Add Update">
                  <IconButton
                    size="small"
                    data-code={urgentAction.code}
                    onClick={onAddUpdateClick}
                  >
                    <CreateIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </ListSubheader>
          {urgentAction.updates.map((update) => (
            <UrgentActionUpdate key={update.time} update={update} />
          ))}
        </List>
      </CardContent>
      <CardActions>
        <Box>
          <Button
            variant="outlined"
            size="small"
            color={urgentAction.isComplete ? 'success' : 'warning'}
            onClick={handleClick}
            endIcon={
              urgentAction.isComplete ? (
                <DoneIcon />
              ) : (
                <RadioButtonUncheckedIcon />
              )
            }
          >
            {urgentAction.isComplete ? 'Completed' : 'Open'}
          </Button>
        </Box>
        <Typography
          sx={{
            paddingLeft: 1,
            '&:hover': {
              textOverflow: 'clip',
              whiteSpace: 'normal',
              wordBreak: 'break-all',
            },
            flexGrow: 1,
          }}
          noWrap
          variant="caption"
          color="text.secondary"
        >
          {!!urgentAction && !!groups
            ? `${listGroupLabels('Group', urgentAction.groupCodes, groups)}`
            : ''}
        </Typography>
        <Box>
          <Tooltip title="Settings">
            <IconButton size="small" onClick={onSettingsClick}>
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </CardActions>
    </Card>
  );
}

import { LoadingDialog } from '@/components/controls';
import { useDeleteScene, useScene, useUpsertScene } from '@/hooks';
import { SceneEditorDialog } from './SceneEditorDialog';

export function SceneEditor({ code, isOpen, onClose, groupCodes }) {
  const { data: scene } = useScene(code, {
    defaultValue: {
      name: '',
      type: 'SCENE',
      boundary: null,
      groupCodes,
      attributes: {
        incidentCode: '',
        occurrenceCode: '',
        requiredAttendance: 0,
      },
    },
  });
  const { mutate: upsertSite } = useUpsertScene();
  const { mutate: deleteSite } = useDeleteScene();

  function handleClose(values) {
    if (values) {
      upsertSite(values);
    }

    onClose();
  }

  function handleDelete() {
    deleteSite(code);

    onClose();
  }

  return scene ? (
    <SceneEditorDialog
      scene={scene}
      isOpen={isOpen}
      onClose={handleClose}
      onDelete={handleDelete}
      groupCodes={groupCodes}
    />
  ) : (
    <LoadingDialog isOpen={isOpen} />
  );
}

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
} from '@mui/material';

export function SwitchField({
  input,
  meta,
  label,
  disabled,
  onChange: callback,
  color,
}) {
  function handleChange(event) {
    input.onChange(event.target.checked);
    if (callback) {
      callback(event);
    }
  }

  return (
    <FormControl error={meta.invalid}>
      <FormControlLabel
        control={
          <Switch
            checked={Boolean(input.value)}
            onChange={handleChange}
            disabled={Boolean(disabled)}
            color={color}
          />
        }
        label={label}
      />
      <FormHelperText>{meta.error}</FormHelperText>
    </FormControl>
  );
}

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
} from '@mui/material';
import { useController } from 'react-hook-form';

export function SwitchController({
  name,
  label,
  control,
  disabled,
  rules,
  parse,
  format,
  sx,
}) {
  const {
    field: { value, disabled: isDisabled, onChange },
    fieldState: { error, invalid },
  } = useController({
    name,
    control,
    rules,
    defaultValue: false,
    disabled,
  });

  return (
    <FormControl error={invalid} sx={sx}>
      <FormControlLabel
        control={
          <Switch
            checked={parse ? parse(value) : value}
            onChange={(event) =>
              onChange(
                format ? format(event.target.checked) : event.target.checked,
              )
            }
            disabled={isDisabled}
          />
        }
        label={label}
      />
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
}

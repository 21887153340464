import { RetrospectiveTypeIcon } from '@/components/controls';
import {
  AccelerometerChart,
  DownloadAccelerometerEventPollsLink,
} from '@/components/events';
import { useDocumentTitle } from '@/hooks';
import { round, startCase } from '@/utils';
import {
  Person as PersonIcon,
  PlayArrow as PlayArrowIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

export function AccelerometerEvent({
  color,
  background,
  vehicle,
  startTime,
  endTime,
  time,
  accelerometerData,
  maximumForces,
  driver,
  parentEvent,
  deviceProperties,
}) {
  useDocumentTitle(
    `IR3 | Accelerometer Event | ${
      vehicle.registrationNumber || vehicle.telematicsBoxImei
    }`,
  );
  const navigate = useNavigate();

  vehicle = vehicle || {};

  accelerometerData?.forEach(
    (a) =>
      a.time.includes('.000') &&
      (a.speedMilesPerHour = round(a.speedKilometresPerHour * 0.62137119, 2)),
  );

  const eventDetails = {
    Start: startTime && format(new Date(startTime), 'dd/MM/yyyy HH:mm:ss'),
    'Event time': time && format(new Date(time), 'dd/MM/yyyy HH:mm:ss'),
    End: endTime && format(new Date(endTime), 'dd/MM/yyyy HH:mm:ss'),
    'Max Horizontal Force (g)': maximumForces?.horizontal,
    'Max Vertical Force (g)': maximumForces?.vertical,
    'Max Lateral Force (g)': maximumForces?.lateral,
    'Has Data': !accelerometerData && 'No',
  };

  function handleViewClick() {
    navigate(`/eventreplay/trips/${parentEvent?.identifier}`);
  }

  const cardHeaderButtons = (
    <Box>
      {parentEvent?.identifier && (
        <Tooltip title="View Replay">
          <IconButton onClick={handleViewClick} size="large">
            <PlayArrowIcon />
          </IconButton>
        </Tooltip>
      )}
      <DownloadAccelerometerEventPollsLink
        entry={{
          hasData: !accelerometerData && 'No',
          data: accelerometerData,
        }}
      />
    </Box>
  );

  return (
    <Card sx={{ m: [1, 0.5, 1, 1] }}>
      <CardHeader
        avatar={
          <Tooltip title="Vehicle Poll">
            <Avatar
              style={{
                background,
                color,
              }}
            >
              <RetrospectiveTypeIcon type="accelerometerAlerts" />
            </Avatar>
          </Tooltip>
        }
        title={vehicle.registrationNumber || vehicle.telematicsBoxImei}
        subheader={vehicle.fleetNumber}
        action={cardHeaderButtons}
      />
      <CardContent>
        {vehicle && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Vehicle
            </Typography>
            <Table size="small" sx={{ mt: 1, mb: 2 }}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography variant="caption" color="textSecondary">
                      Role
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">{vehicle.role}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="caption" color="textSecondary">
                      Home Station
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">
                      {vehicle.homeStation}
                    </Typography>
                  </TableCell>
                </TableRow>
                {Object.entries(vehicle.groups ?? {}).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        {startCase(key)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">
                        {(value ?? []).join(', ')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Fragment>
        )}
        {driver && (
          <Fragment>
            <Typography variant="subtitle2" color="textSecondary">
              Driver
            </Typography>
            <ListItem component="div">
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${driver.forenames || ''}  ${driver.surname || ''}`}
                secondary={
                  driver.collarNumber
                    ? driver.collarNumber
                    : driver.identificationReference
                }
              />
            </ListItem>
          </Fragment>
        )}
        <Typography variant="subtitle2" color="textSecondary">
          Event
        </Typography>
        <Table size="small" sx={{ mt: 1, mb: 2 }}>
          <TableBody>
            {Object.keys(eventDetails)
              .filter((title) => eventDetails[title])
              .map((title) => (
                <TableRow key={title}>
                  <TableCell>
                    <Typography variant="caption" color="textSecondary">
                      {title}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">
                      {eventDetails[title]}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {accelerometerData && (
          <AccelerometerChart
            data={accelerometerData}
            triggerPoint={deviceProperties.triggerPoint}
          />
        )}
      </CardContent>
    </Card>
  );
}

import { Stack, Typography } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useSexualOffences, useOfficers } from '@/hooks';
import {
  filterByPastDays,
  getOfficerTeamFromPeople,
  getStringBeforeCharacter,
} from '@/utils';
import { PastDaysFilter } from '@/components/controls';

const columns = [
  { header: 'Occurrence Number', accessorKey: 'number' },
  { header: 'Occurrence Date', accessorKey: 'startTime' },
  { header: 'Aggrieved', accessorKey: 'aggrieved' },
  { header: 'Subject', accessorKey: 'subject' },
  { header: 'OIC', accessorKey: 'oic' },
  { header: 'OIC Team', accessorKey: 'owningTeam' },
  { header: 'Location', accessorKey: 'address' },
  { header: 'Summary', accessorKey: 'summary' },
];

export function SexualOffences({ groupCodes }) {
  const [pastDays, setPastDays] = useState(120);
  const { data, isLoading, isFetching } = useSexualOffences({
    codes: groupCodes,
  });

  const { data: officers } = useOfficers();

  const sexualOffences = useMemo(
    () =>
      data.map((offence) => ({
        ...offence,
        aggrieved: offence?.personClassification.includes('VIC')
          ? `${offence?.firstName} ${offence?.surname}`
          : '',
        subject: offence?.personClassification.includes('SUB')
          ? `${offence?.firstName} ${offence?.surname}`
          : '',
        owningTeam: getOfficerTeamFromPeople(
          officers,
          offence?.oic,
          offence?.owningTeam,
        ),
        address: getStringBeforeCharacter(offence?.address, '('),
      })),
    [data],
  );

  const [filteredData, setFilteredData] = useState(sexualOffences);
  useEffect(() => {
    const result = filterByPastDays(pastDays, sexualOffences);
    setFilteredData(result);
  }, [pastDays, sexualOffences]);

  function onFilterChange(value) {
    setPastDays(value);
  }

  return (
    <Fragment>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{
          position: 'sticky',
          top: 48,
          backgroundColor: 'background.default',
          zIndex: 700,
          pl: 1,
          py: 1,
        }}
      >
        <Typography variant="h6">Sexual Offences</Typography>
        <PastDaysFilter days={pastDays} onFilterChange={onFilterChange} />
      </Stack>
      <Stack spacing={1} sx={{ pb: 1.5 }}>
        <MaterialReactTable
          muiTablePaperProps={{ variant: 'outlined', elevation: 0 }}
          defaultColumn={{ size: 0 }}
          columns={columns}
          data={filteredData}
          enableGrouping
          enableBottomToolbar={false}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enablePagination={false}
          state={{ isLoading: isLoading || isFetching }}
          initialState={{
            density: 'compact',
            expanded: true,
            sorting: [{ id: 'number', desc: false }],
          }}
          muiTableBodyRowProps={{
            sx: { '&:last-child td, &:last-child th': { border: 0 } },
          }}
          muiSearchTextFieldProps={{ variant: 'outlined', size: 'small' }}
        />
      </Stack>
    </Fragment>
  );
}

import { getStatusColor, startCase } from '@/utils';
import { Box, Stack, Typography } from '@mui/material';

export function Status({ status }) {
  if (!status) return null;

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Box
        sx={{
          width: 12,
          height: 12,
          bgcolor: getStatusColor(status),
          borderRadius: 6,
        }}
      />
      <Typography variant="caption">{startCase(status)}</Typography>
    </Stack>
  );
}

export const pipelineFn = (orderBy, order, page, rowsPerPage, query) => [
  { $match: query },
  {
    $project: {
      identifier: true,
      vehicle: {
        identificationNumber: true,
        registrationNumber: true,
        fleetNumber: true,
        role: true,
        type: true,
      },
      maximumForces: true,
      accelerometerData: {
        $map: {
          input: '$accelerometerData',
          as: 'data',
          in: {
            position: '$$data.position',
            headingDegrees: '$$data.headingDegrees',
            time: '$$data.time',
            horizontalForce: '$$data.horizontalForce',
            verticalForce: '$$data.verticalForce',
            lateralForce: '$$data.lateralForce',
            speedMilesPerHour: {
              $cond: [
                {
                  $eq: [
                    {
                      $millisecond: {
                        $dateFromString: {
                          dateString: '$$data.time',
                        },
                      },
                    },
                    0,
                  ],
                },
                {
                  $round: [
                    {
                      $multiply: ['$$data.speedKilometresPerHour', 0.62137119],
                    },
                    2,
                  ],
                },
                null,
              ],
            },
          },
        },
      },
      deviceProperties: { triggerPoint: true, time: true },
      durationSeconds: true,
      path: true,
    },
  },
  { $sort: { [orderBy]: order === 'desc' ? -1 : 1 } },
  { $skip: page * rowsPerPage },
  { $limit: rowsPerPage },
];

export const downloadPipelineFn = (query) => [
  { $match: query },
  {
    $project: {
      vehicle: {
        identificationNumber: true,
        registrationNumber: true,
        fleetNumber: true,
        role: true,
        type: true,
      },
      maximumForces: true,
      deviceProperties: {
        time: {
          $dateFromString: {
            dateString: '$deviceProperties.time',
          },
        },
      },
    },
  },
];

export const headers = [
  {
    label: 'Registration',
    key: 'vehicle.registrationNumber',
    type: 'text',
  },
  {
    label: 'Fleet Number',
    key: 'vehicle.fleetNumber',
    type: 'text',
  },
  {
    label: 'Vehicle Type',
    key: 'vehicle.type',
    type: 'text',
  },
  {
    label: 'Vehicle Role',
    key: 'vehicle.role',
    type: 'text',
  },
  { label: 'Time', key: 'deviceProperties.time', type: 'date' },
  {
    label: 'Maximum Horizontal Force (g)',
    key: 'maximumForces.horizontal',
    type: 'number',
  },
  {
    label: 'Maximum Vertical Force (g)',
    key: 'maximumForces.vertical',
    type: 'number',
  },
  {
    label: 'Maximum Lateral Force (g)',
    key: 'maximumForces.lateral',
    type: 'number',
  },
];

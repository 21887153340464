import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useNichePersons(options) {
  const searchParams = JSON.parse(JSON.stringify(options ?? {}));
  return useQuery({
    queryKey: ['nichePersons', searchParams],
    queryFn: () => {
      return briefingApi.get(`niche/people/nominals`, { searchParams }).json();
    },
    enabled: searchParams.codes?.length > 0,
    staleTime: 30 * 60 * 1000,
  });
}

import { briefingApi } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertPrisoner() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ _id, groupAncestors: _, ...json }) => {
      if (_id) {
        return briefingApi.put(`prisoners/${json.code}`, { json }).json();
      } else {
        return briefingApi.post('prisoners', { json }).json();
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['enRoutePrisoners'] }),
  });
}

import { usePrisoners, useCustodyUnits } from '@/hooks';
import { Done as DoneIcon } from '@mui/icons-material';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { Fragment } from 'react';

const columns = [
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: 'Custody Number',
    accessorKey: 'custodyNumber',
  },
  {
    header: 'Occurrence',
    accessorKey: 'occurrenceCode',
  },
  {
    header: 'Time in custody',
    accessorKey: 'totalCustodyTime',
  },
  {
    header: 'Offence',
    accessorKey: 'offence',
  },
  {
    header: 'Arresting Officer',
    accessorKey: 'arrestingOfficerCode',
  },
  {
    header: 'Investigating Officer',
    accessorKey: 'investigatingOfficerCode',
  },
  {
    header: 'OIC',
    accessorKey: 'investigatingOfficerCode',
  },
  {
    header: 'OIC Team',
    accessorKey: 'owningTeam',
  },
  {
    header: 'Arrival Time',
    accessorKey: 'arrivalTime',
  },
  {
    header: 'Custody Unit',
    accessorKey: 'custodyUnit',
  },
  {
    header: 'Observation Level',
    accessorKey: 'observationLevel',
  },
];

export function Prisoners({ groupCodes, onComplete }) {
  const {
    data: prisoners,
    isLoading,
    isFetching,
  } = usePrisoners({ codes: groupCodes });

  const { data: custodyUnits } = useCustodyUnits();

  const prisonersWithCustodyUnits = prisoners.map((data) => {
    if (custodyUnits) {
      const unit = custodyUnits[data.custodyUnit];
      if (unit) {
        data.custodyUnit = unit;
      }
    }

    return data;
  });

  function handleCompleteClick() {
    onComplete();
  }
  return (
    <Fragment>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{
          position: 'sticky',
          top: 48,
          backgroundColor: 'background.default',
          zIndex: 700,
          pl: 1,
          py: 1,
        }}
      >
        <Typography variant="h6">Prisoners</Typography>
        <Box>
          <Tooltip title="Done" placement="right">
            <IconButton onClick={handleCompleteClick}>
              <DoneIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
      <Stack spacing={1} sx={{ pb: 1.5 }}>
        <MaterialReactTable
          muiTablePaperProps={{ variant: 'outlined', elevation: 0 }}
          defaultColumn={{ size: 0 }}
          columns={columns}
          data={prisonersWithCustodyUnits}
          enableGrouping
          enableBottomToolbar={false}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enablePagination={false}
          state={{ isLoading: isLoading || isFetching }}
          initialState={{
            density: 'compact',
            expanded: true,
            grouping: ['custodyUnit'],
            sorting: [{ id: 'custodyUnit', desc: false }],
          }}
          muiTableBodyRowProps={{
            sx: { '&:last-child td, &:last-child th': { border: 0 } },
          }}
          muiSearchTextFieldProps={{ variant: 'outlined', size: 'small' }}
        />
      </Stack>
    </Fragment>
  );
}

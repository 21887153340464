import { LocationMapDialog } from '@/components/controls';
import { useMissingPersons } from '@/hooks';
import { Done as DoneIcon } from '@mui/icons-material';
import { Masonry } from '@mui/lab';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Fragment, useMemo, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { getStringBeforeCharacter, extractPostCode } from '@/utils';
import { MissingPerson } from './MissingPerson';

export function MissingPersons({ groupCodes, onComplete }) {
  const { data: missingPersons } = useMissingPersons({ codes: groupCodes });
  const { data: highRiskMissingPersons } = useMissingPersons({
    codes: groupCodes,
    risk: 'hi',
  });

  const lowRiskMissingPersons = useMemo(
    () =>
      missingPersons.filter(
        (s) =>
          !s.person.riskLevel ||
          s.person.riskLevel === 'Low' ||
          s.person.riskLevel === 'Mid',
      ),
    [missingPersons],
  );

  const { width, ref } = useResizeDetector();
  const highRiskColumns = useMemo(() => Math.floor(width / 328) || 1, [width]);
  const lowRiskColumns = useMemo(() => Math.floor(width / 248) || 1, [width]);
  const [selected, setSelected] = useState(null);

  function handleShowLocationClick(missingPerson) {
    return async function () {
      const missingFrom = getStringBeforeCharacter(
        missingPerson.occurrence.address,
        '(',
      );
      const missingFromPostcode = extractPostCode(missingFrom);
      missingPerson.missingFrom = missingFrom;
      missingPerson.postCode = missingFromPostcode;
      setSelected(missingPerson);
    };
  }

  function handleLocationMapClose() {
    setSelected(null);
  }

  function handleCompleteClick() {
    onComplete();
  }

  return (
    <Fragment>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{
          position: 'sticky',
          top: 48,
          backgroundColor: 'background.default',
          zIndex: 700,
          pl: 1,
          py: 1,
        }}
      >
        <Typography variant="h6">Missing Persons</Typography>
        <Box>
          <Tooltip title="Done" placement="right">
            <IconButton onClick={handleCompleteClick}>
              <DoneIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
      <Stack spacing={1} sx={{ pb: 1 }}>
        <Typography variant="subtitle1" sx={{ pl: 1 }}>
          High Risk
        </Typography>
        {!highRiskMissingPersons ||
          (highRiskMissingPersons.length == 0 && (
            <Typography variant="caption" color="red" sx={{ pl: 1 }}>
              No high risk missing person(s).
            </Typography>
          ))}
        <Masonry spacing={1} ref={ref} columns={highRiskColumns}>
          {highRiskMissingPersons.map((missingPerson) => (
            <MissingPerson
              key={missingPerson.person.id}
              missingPerson={missingPerson}
              onShowLocationClick={handleShowLocationClick(missingPerson)}
            />
          ))}
        </Masonry>
        <Typography variant="subtitle1" sx={{ pl: 1 }}>
          Local
        </Typography>
        <Masonry spacing={1} columns={lowRiskColumns}>
          {lowRiskMissingPersons.map((missingPerson) => (
            <MissingPerson
              key={missingPerson.person.id}
              missingPerson={missingPerson}
              onShowLocationClick={handleShowLocationClick(missingPerson)}
            />
          ))}
        </Masonry>
      </Stack>
      {selected && (
        <LocationMapDialog
          title={`${selected.person.firstName} ${selected.person.surname} missing from`}
          postCode={selected.postCode}
          address={selected.missingFrom}
          isOpen={Boolean(selected)}
          onClose={handleLocationMapClose}
        />
      )}
    </Fragment>
  );
}

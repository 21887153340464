import { api } from '@/apis';
import { encodeParams, log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useMembers({ groups }) {
  return useQuery({
    queryKey: ['members', groups],
    queryFn: () => {
      const searchParams = encodeParams({
        pipeline: [
          {
            $match: {
              deleted: { $ne: true },
              $or: [
                { leavingDate: { $exists: false } },
                { leavingDate: { $gte: new Date() } },
              ],
            },
          },
          {
            $graphLookup: {
              from: 'groups',
              startWith: '$groupCodes',
              connectFromField: 'parentCodes',
              connectToField: 'code',
              as: 'groupAncestorCodes',
              depthField: 'depth',
            },
          },
          {
            $project: {
              code: true,
              hasPicture: { $gt: ['$picture', null] },
              forenames: true,
              surname: true,
              collarNumber: true,
              rank: true,
              role: true,
              radioSsi: true,
              rfidCards: true,
              lastPollTime: true,
              assignments: true,
              emailAddress: true,
              mobileNumber: true,
              groupNames: {
                $map: {
                  input: {
                    $filter: {
                      input: '$groupAncestorCodes',
                      as: 'group',
                      cond: { $eq: ['$$group.depth', 0] },
                    },
                  },
                  as: 'group',
                  in: '$$group.name',
                },
              },
              groupAncestorCodes: {
                $map: {
                  input: '$groupAncestorCodes',
                  as: 'group',
                  in: '$$group.code',
                },
              },
            },
          },
          ...(groups
            ? [{ $match: { groupAncestorCodes: { $in: groups } } }]
            : []),
          { $sort: { code: 1 } },
        ],
      });

      log('Read', 'Members');

      return api.get('people', { searchParams }).json();
    },
    placeholderData: [],
    staleTime: 25 * (60 * 1000),
    cacheTime: 30 * (60 * 1000),
  });
}

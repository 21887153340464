import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useAssociatedPerson(id) {
  return useQuery({
    queryKey: ['associatedPerson', id],
    queryFn: () => {
      return briefingApi
        .get(`niche/people/${id}`, {
          timeout: false,
        })
        .json();
    },
    enabled: !!id,
    staleTime: 50 * (60 * 1000),
    cacheTime: 60 * (60 * 1000),
  });
}

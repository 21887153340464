import { api } from '@/apis';
import { encodeParams, log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useMember(code) {
  return useQuery({
    queryKey: ['members', code],
    queryFn: () => {
      const searchParams = encodeParams({
        pipeline: [
          { $match: { code } },
          {
            $graphLookup: {
              from: 'groups',
              startWith: '$groupCodes',
              connectFromField: 'parentCodes',
              connectToField: 'code',
              as: 'groupAncestors',
              depthField: 'depth',
            },
          },
          {
            $project: {
              code: true,
              picture: true,
              forenames: true,
              surname: true,
              collarNumber: true,
              emailAddress: true,
              mobileNumber: true,
              supervisorCode: true,
              postNumber: true,
              rank: true,
              role: true,
              homeStation: true,
              wards: true,
              skills: true,
              radioSsi: true,
              lastPollTime: true,
              rfidCards: true,
              ward: true,
              supervisorCodes: true,
              leavingDate: true,
              groups: true,
              assignments: true,
              visibleTo: true,
              attributes: true,
              groupCodes: true,
              groupAncestors: {
                $map: {
                  input: {
                    $sortArray: {
                      input: {
                        $filter: {
                          input: '$groupAncestors',
                          cond: {
                            $not: [{ $in: ['$$ancestor.code', '$groupCodes'] }],
                          },
                          as: 'ancestor',
                        },
                      },
                      sortBy: { depth: -1, type: 1, name: 1 },
                    },
                  },
                  as: 'group',
                  in: {
                    code: '$$group.code',
                    name: '$$group.name',
                    type: '$$group.type',
                  },
                },
              },
            },
          },
        ],
      });

      log('Read', 'Member', { code });

      return api.get('people', { searchParams }).json();
    },
    enabled: !!code,
  });
}

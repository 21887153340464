import { briefingApi } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useSuspects(options) {
  const searchParams = JSON.parse(JSON.stringify(options ?? {}));
  return useQuery({
    queryKey: ['suspects', searchParams],
    queryFn: () => {
      return briefingApi.get('niche/suspects', { searchParams }).json();
    },
    enabled: searchParams.ids?.length > 0 || searchParams.codes?.length > 0,
    keepPreviousData: true,
    refetchIntervalInBackground: true,
    placeholderData: [],
    staleTime: 55 * (60 * 1000),
    cacheTime: 60 * (60 * 1000),
  });
}

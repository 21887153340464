import { ExportPicker } from '@/components/controls';
import {
  getVehiclePolls,
  longPersonVehicleHeaders,
  telematicsBoxPollHeaders,
} from '@/utils';

const vehiclePollsHeaders = [
  ...longPersonVehicleHeaders,
  ...telematicsBoxPollHeaders,
];

export function DownloadPollsLink({ entry }) {
  async function fetchDataToConvert() {
    const polls = await getVehiclePolls(
      entry.vehicle.telematicsBoxImei,
      entry.startTime,
      entry.endTime,
    );

    const data = polls.map((poll) => ({
      ...poll,
      name: entry.driver.forenames + ' ' + entry.driver.surname,
      collarNumber: entry.driver.collarNumber,
      personRole: entry.driver.role,
      type: entry.vehicle.type,
      identificationNumber: entry.vehicle.identificationNumber,
      registrationNumber: entry.vehicle.registrationNumber,
      fleetNumber: entry.vehicle.fleetNumber,
      vehicleRole: entry.vehicle.role,
      time: new Date(poll.time),
      classification: entry.classification,
      rfidCards: entry.driver.identificationReference,
      imei: entry.vehicle.telematicsBoxImei,
    }));

    return data;
  }

  return (
    <ExportPicker
      fetchDataToConvert={fetchDataToConvert}
      filename="Trip Polls"
      headers={vehiclePollsHeaders}
    />
  );
}

import { highlightsStateAtom } from '@/atoms';
import { DataGrid } from '@/components/controls';
import { useObjectiveCompliance } from '@/hooks';
import { highlights, locationGroups } from '@/utils/config';
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { ObjectivesTable } from './ObjectivesTable';

const {
  objectiveCompliance: {
    defaultGroupBy,
    defaultResourceType,
    defaultMaxCompliancePercent,
    lookbackDays,
  },
} = highlights;

//returns display data based on the objectiveCompliance data, the groupsData and the selected grouping key chosen on front end
function groupObjectiveCompliances(data, groupData, key) {
  let groupedByResponse = [];

  groupData
    ?.find((element) => element.group === key)
    ?.values?.map((element) => {
      const objectives = data.filter((objective) =>
        objective.groups[key]?.includes(element),
      );
      if (objectives.length) {
        groupedByResponse.push({
          key,
          value: element,
          numberOfObjectives: objectives.length,
          groupCompliancePercent:
            objectives.reduce((total, element) => {
              return total + element.compliancePercent;
            }, 0) / objectives.length,
          objectives,
        });
      }
    });

  return groupedByResponse;
}

export function ObjectiveComplianceCard({ groupType, groupId }) {
  const [{ resourceType, maxCompliancePercent, groupBy }, setState] =
    useAtom(highlightsStateAtom);

  const { data } = useObjectiveCompliance(
    resourceType ?? defaultResourceType,
    groupType,
    groupId,
    maxCompliancePercent ?? defaultMaxCompliancePercent,
  );

  const filteredData = useMemo(() => {
    return groupObjectiveCompliances(
      data?.data,
      data?.options?.groupData,
      groupBy ?? defaultGroupBy,
    );
  }, [data, groupBy]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'value',
        header: locationGroups[groupBy]?.label || groupBy,
        muiTableHeadCellProps: {
          sx: {
            textTransform: 'capitalize',
          },
        },
      },
      {
        accessorKey: 'numberOfObjectives',
        header: 'Objectives',
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
      },
      {
        accessorKey: 'groupCompliancePercent',
        header: 'Compliance',
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => cell.getValue().toFixed(2) + '%',
      },
    ],
    [groupBy],
  );

  function handleGroupByChange(event) {
    setState((state) => ({ ...state, ['groupBy']: event.target.value }));
  }

  function handleResourceTypeChange(event) {
    if (event.target.value) {
      // only change the state if the toggle is "ON"
      setState((state) => ({ ...state, ['resourceType']: event.target.value }));
    }
  }

  function handleMaxCompliancePercentChange(event) {
    if (event.target.checked) {
      //use Checkbox state to determine max percentage displayable
      setState((state) => ({ ...state, ['maxCompliancePercent']: 9999 }));
    } else {
      setState((state) => ({ ...state, ['maxCompliancePercent']: 100 }));
    }
  }

  return (
    filteredData && (
      <Card variant="outlined" sx={{ flex: 1 }}>
        <CardContent>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Objective Compliance
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {`in the last ${lookbackDays} days`}
            </Typography>
          </Stack>
          <DataGrid
            enableSorting={true}
            enableBottomToolbar={true}
            enablePagination={true}
            columns={columns}
            data={filteredData}
            initialState={{ columnVisibility: { identifier: false } }}
            renderDetailPanel={({ row }) => (
              <ObjectivesTable
                data={row?.original?.objectives}
                groupByKey={row?.original?.value}
              />
            )}
          />
          <div>
            <Stack mt={2} direction="row" justifyContent="center" spacing={2}>
              <ToggleButtonGroup
                value={resourceType ?? defaultResourceType}
                onChange={handleResourceTypeChange}
                color="primary"
                size="small"
                exclusive
              >
                <ToggleButton key="people" value="people">
                  People
                </ToggleButton>
                <ToggleButton key="vehicles" value="vehicles">
                  Vehicles
                </ToggleButton>
              </ToggleButtonGroup>
              <TextField
                sx={{ textTransform: 'capitalize', width: 240 }}
                size="small"
                select
                label="Group By"
                value={
                  data?.options?.groups?.length
                    ? groupBy
                      ? groupBy
                      : defaultGroupBy
                    : ''
                }
                onChange={handleGroupByChange}
              >
                {data?.options?.groups?.length
                  ? Object.entries(data?.options?.groups || []).map(
                      ([key, value]) => (
                        <MenuItem
                          sx={{ textTransform: 'capitalize' }}
                          key={key}
                          value={value}
                        >
                          {locationGroups[value]?.label || value}
                        </MenuItem>
                      ),
                    )
                  : []}
              </TextField>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      maxCompliancePercent
                        ? maxCompliancePercent === 100
                          ? false
                          : true
                        : defaultMaxCompliancePercent === 100
                        ? false
                        : true
                    }
                    onChange={handleMaxCompliancePercentChange}
                  />
                }
                label="> 100%"
              />
            </Stack>
          </div>
        </CardContent>
      </Card>
    )
  );
}

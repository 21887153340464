import { LiveListItem } from './LiveListItem';

export function PeopleLiveListItem(props) {
  return (
    <LiveListItem
      type="people"
      primaryPath="name"
      secondaryPath="role"
      hideFollow={!props.item.position}
      {...props}
    />
  );
}

import { FETCH_TAGS } from '@/actions';
import { NavLink, SearchBox } from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import { Add as AddIcon, More as MoreIcon } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
} from '@mui/material';
import { dequal } from 'dequal';
import { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useParams } from 'react-router-dom';

export function Tags() {
  useDocumentTitle('IR3 | Tags');
  const { id } = useParams();
  const dispatch = useDispatch();
  const tags = useSelector((state) => state.tags.tags, dequal);
  const [filterText, setFilterText] = useState('');
  const sortedNames = useMemo(
    () =>
      tags
        .filter(
          (tag) =>
            `${tag.identifier}`
              .toLowerCase()
              .indexOf(filterText.toLowerCase()) > -1 || filterText === '',
        )
        .sort((a, b) => `${a.identifier}`.localeCompare(`${b.identifier}`)),
    [tags, filterText],
  );

  useEffect(() => {
    dispatch({
      type: FETCH_TAGS,
    });
  }, [dispatch]);

  function handleFilterChange(event) {
    setFilterText(event.target.value);
  }

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 48px)' }}>
      <Stack
        sx={{
          width: 280,
          borderRight: 1,
          borderColor: 'divider',
          flexShrink: 0,
          height: 1,
        }}
      >
        <Box>
          <Stack
            direction="row"
            spacing={1}
            sx={{ p: 1, alignItems: 'center' }}
          >
            <SearchBox
              value={filterText}
              onChange={handleFilterChange}
              sx={{ flexGrow: 1 }}
              count={`${sortedNames.length}/${tags.length}`}
            />
            <Tooltip title="Add new">
              <IconButton component={Link} to={'/tags/new'} size="small">
                <Avatar
                  sx={{
                    color: 'secondary.contrastText',
                    backgroundColor: 'secondary.main',
                    width: 24,
                    height: 24,
                    fontSize: 16,
                  }}
                >
                  <AddIcon fontSize="inherit" />
                </Avatar>
              </IconButton>
            </Tooltip>
          </Stack>
        </Box>
        <Divider />
        <List
          dense
          disablePadding
          sx={{
            height: 1,
            overflowY: 'auto',
          }}
        >
          {sortedNames.map((tag) => (
            <ListItemButton
              dense
              key={tag.identifier}
              to={`/tags/${encodeURIComponent(tag.identifier)}`}
              component={NavLink}
              selected={id === tag.identifier}
            >
              <ListItemAvatar>
                <Avatar>
                  <MoreIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={tag.identifier} />
            </ListItemButton>
          ))}
        </List>
      </Stack>
      <Box sx={{ overflow: 'auto', flex: 1 }}>
        <Outlet />
      </Box>
    </Box>
  );
}

import { Box } from '@mui/material';
import { createContext, forwardRef, useContext } from 'react';
import { FixedSizeList } from 'react-window';

// mostly https://material-ui.com/components/autocomplete/#virtualization
const LISTBOX_PADDING = 8; // px

const OuterElementContext = createContext({});

const OuterElementType = forwardRef(function OuterElementType(props, ref) {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

export const VirtualisedListbox = forwardRef(
  function VirtualisedListbox(props, ref) {
    const { children, styles, renderRow, ...other } = props;
    const itemData = children; //Children.toArray(children);
    const itemCount = itemData.length;
    const itemSize = 40;
    const height = Math.min(10, itemCount) * itemSize;

    return (
      <Box ref={ref} sx={{ '& ul': { m: 0 } }}>
        <OuterElementContext.Provider value={other}>
          <FixedSizeList
            // itemData={{ ...itemData, styles, options }}
            itemData={{ ...itemData, styles }}
            height={height + 2 * LISTBOX_PADDING}
            width="100%"
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={itemSize}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </FixedSizeList>
        </OuterElementContext.Provider>
      </Box>
    );
  },
);

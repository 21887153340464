import { api } from '@/apis';
import { encodeParams, log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useScenes({ groups }) {
  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['scenes', { groups }],
    queryFn: () => {
      const searchParams = encodeParams({
        pipeline: [
          { $match: { type: 'SCENE' } },
          {
            $graphLookup: {
              from: 'groups',
              startWith: '$groupCodes',
              connectFromField: 'parentCodes',
              connectToField: 'code',
              as: 'groupAncestorCodes',
              depthField: 'depth',
            },
          },
          {
            $lookup: {
              from: 'people',
              localField: 'code',
              foreignField: 'currentLocations.code',
              as: 'currentAttendanceCount',
            },
          },
          {
            $set: {
              groupAncestorCodes: {
                $map: {
                  input: '$groupAncestorCodes',
                  as: 'group',
                  in: '$$group.code',
                },
              },
              groups: {
                $map: {
                  input: {
                    $filter: {
                      input: '$groupAncestorCodes',
                      cond: { $eq: ['$$group.depth', 0] },
                      as: 'group',
                    },
                  },
                  as: 'group',
                  in: '$$group.name',
                },
              },
              currentAttendanceCount: { $size: '$currentAttendanceCount' },
            },
          },
          ...(groups?.length > 0
            ? [{ $match: { groupAncestorCodes: { $in: groups } } }]
            : []),
          { $sort: { name: 1 } },
        ],
      });

      log('Read', 'Scenes');

      return api.get('locations', { searchParams }).json();
    },
    placeholderData: [],
    staleTime: 30 * 60 * 1000,
  });
}

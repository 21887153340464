import { api } from '@/apis';
import { encodeParams } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useOptions(name) {
  return useQuery({
    queryKey: ['options', name],
    queryFn: async () => {
      const searchParams = encodeParams({
        query: { name },
        projection: { value: true, label: true },
      });
      const options = await api.get('options', { searchParams }).json();

      return options.reduce((accumulator, { value, label }) => {
        accumulator[value] = label;

        return accumulator;
      }, {});
    },
    placeholderData: {},
    // staleTime: 60 * 60 * 1000,
  });
}
